/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './EditWarningWrapper.scss';
import InputOptions from '../../components/ProofSetup/components/InputOptions';
import { Option, ConfirmActionOption, Separator } from '../../components/PopupMenu';
import { Translation } from '../../components/Text';

export function EditWarningWrapper({ value, warningMessage, children }) {
  const [hasConfirmed, setHasConfirmed] = useState(!warningMessage);

  if (hasConfirmed) {
    return children;
  }

  return (
    <div className={css.WarningWrapper}>
      {value}
      <InputOptions
        absolute
        options={[
          <Option
            className={css.WarningWrapper__message}
            label={warningMessage}
            disabled
          />,
          <Separator />,
          <ConfirmActionOption
            onClick={() => setHasConfirmed(true)}
            iconSrc="img/icons/pen.svg"
            label={<Translation value="option.edit" />}
            confirmLabel={<Translation value="option.edit.confirm" />}
          />,
        ]}
      />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  EditWarningWrapper.propTypes = {
    value: PropTypes.node,
    warningMessage: PropTypes.node,
    children: PropTypes.node.isRequired,
  };
}

export default EditWarningWrapper;
