/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { InlineSVG } from '@jmshal/react-inline-svg';
import PropTypes from 'prop-types';
import classname from 'classname';
import { sdk } from '../../util/sdk';
import FormatDate from '../Date/FormatDate';
import Fraction from '../Fraction/Fraction';
import ProgressCircle from '../ProgressCircle';
import Popover from '../Popover';
import { Option, OptionList } from '../PopupMenu';
import { Translation } from '../Text';
import css from './WorkflowProgress.scss';

const WorkflowProgress = ({
  currentStepName,
  currentStepDueDate,
  totalStepCount,
  visibleStepCount,
  visibleReviewerPercentage,
  isProofOverdue,
  onClick,
  workflowId,
  refreshDecisionSummary,
  canSkipAll,
  size,
  labelPosition,
}) => {
  const isOverdue = currentStepDueDate
    ? currentStepDueDate < Date.now()
    : isProofOverdue;

  const onSkipAll = () => {
    sdk.workflows.skipAll(workflowId)
      .then(() => refreshDecisionSummary());
  };

  return (
    <Popover
      variant="light"
      center
      down
      arrow
      offset={24}
      content={(
        <OptionList variant="light">
          <Popover
            right
            middle
            down
            offset={-6}
            disabled={!canSkipAll}
            content={popover => (
              <OptionList>
                <Option
                  onClick={() => {
                    onSkipAll();
                    popover.hide();
                  }}
                  label={<Translation value="workflow-progress.skip-all" />}
                />
              </OptionList>
            )}
          >
            <Option
              variant="light"
              checked={canSkipAll}
              checkedIcon={
                <InlineSVG
                  className={css.WorkflowProgress__actionsIcon}
                  width={24}
                  height={24}
                  src="img/content/proof/icons/metadata/more.svg"
                />
              }
              readOnly={!canSkipAll}
              label={
                <Translation
                  value="workflow-progress.step-of"
                  params={{ visible: visibleStepCount, total: totalStepCount }}
                  className={css.WorkflowProgress__stepOf}
                />
              }
            />
          </Popover>
          {currentStepName && (
            <Option
              readOnly
              label={
                <Translation
                  value="workflow-progress.step-name"
                  params={{ name: currentStepName }}
                />
              }
            />
          )}
          {currentStepDueDate && (
            <Option
              readOnly
              label={
                <Translation
                  value="workflow-progress.current-step-due-date"
                  params={{
                    currentStepDate: (
                      <FormatDate
                        date={currentStepDueDate}
                        formatTo="h:mma, Do MMMM YYYY"
                      />
                    ),
                  }}
                  className={classname(css.WorkflowProgress__dueDate, {
                    [css['WorkflowProgress__dueDate--overdue']]: isOverdue,
                  })}
                />
              }
            />
          )}
          <Option
            readOnly
            label={
              <Fragment>
                <Translation
                  value="workflow-progress.percentage-of-reviewers-invited"
                  params={{ percentage: visibleReviewerPercentage }}
                />
              </Fragment>
            }
          />
        </OptionList>
      )}
    >
      <button
        type="button"
        onClick={onClick}
        className={classname(css.WorkflowProgress, {
          [css[`WorkflowProgress--${labelPosition}`]]: true,
        })}
      >
        <span className={css.WorkflowProgress__fraction}>
          <Fraction
            numerator={visibleStepCount}
            denominator={totalStepCount}
            isHorizontal={labelPosition === 'vertical'}
            size={size}
          />
        </span>
        <ProgressCircle
          className={css.WorkflowProgress__circle}
          size={size}
          width={3}
          color={isOverdue ? '#e51c23' : '#138b3b'}
          trackColor="#b8b8b8"
          value={Math.max(2, visibleReviewerPercentage)} // start at 2%
        />
      </button>
    </Popover>

  );
};

WorkflowProgress.propTypes = {
  currentStepName: PropTypes.string,
  currentStepDueDate: PropTypes.instanceOf(Date),
  totalStepCount: PropTypes.number.isRequired,
  visibleStepCount: PropTypes.number.isRequired,
  visibleReviewerPercentage: PropTypes.number.isRequired,
};

export default WorkflowProgress;
