/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import { InlineSVG } from '@jmshal/react-inline-svg';
import Popover from '../Popover';
import { Option, OptionList, Separator } from '../PopupMenu';
import Translation from '../Text/Translation';
import { InlineSpinner } from '../Spinner';
import css from './UserStatusMenu.scss';
import { IS_VALENTINES_DAY, IS_CHRISTMAS, IS_ST_PATRICKS_DAY, IS_INTERNATIONAL_WOMENS_DAY, IS_CHRISTCHURCH_NATIONAL_REMEMBRANCE_DAY, IS_PRIDE_MONTH, IS_MAORI_LANGUAGE_WEEK, IS_HALLOWEEN, IS_EARTH_DAY, IS_FOURTH_OF_JULY, IS_NEW_YEAR, IS_CHINESE_NEW_YEAR, IS_EASTER, IS_CORONATION_DAY } from '../../util/special-days';
import Emoji from '../Emoji';
import Tooltip from '../Tooltip';

function UserStatusPreset({
  emoji,
  message,
  // away,
}) {
  return (
    <div className={css.UserStatusPreset}>
      <span className={css.UserStatusPreset__emoji}>
        <Emoji text={emoji} />
      </span>
      <span className={css.UserStatusPreset__message}>{message}</span>
    </div>
  );
}

const presets = [
  {
    id: 'on-vacation',
    emoji: '🌴',
    messageI18n: 'user-status.preset.on-vacation',
    away: true,
  },
  {
    id: 'in-a-meeting',
    emoji: '📅',
    messageI18n: 'user-status.preset.in-a-meeting',
    away: true,
  },
  {
    id: 'out-sick',
    emoji: '🤒',
    messageI18n: 'user-status.preset.out-sick',
    away: true,
  },
  {
    id: 'working-remotely',
    emoji: '🏡',
    messageI18n: 'user-status.preset.working-remotely',
    away: true,
  },
  IS_VALENTINES_DAY && {
    id: 'happy-valentines',
    emoji: '❤️',
    messageI18n: 'user-status.preset.special.happy-valentines',
    away: true,
  },
  IS_CHRISTMAS && {
    id: 'merry-christmas',
    emoji: '❄️',
    messageI18n: 'user-status.preset.special.merry-christmas',
    away: true,
  },
  IS_ST_PATRICKS_DAY && {
    id: 'st-patricks-day',
    emoji: '🍀',
    messageI18n: 'user-status.preset.special.st-patricks-day',
    away: true,
  },
  IS_INTERNATIONAL_WOMENS_DAY && {
    id: 'international-womens-day',
    emoji: '💜',
    messageI18n: 'user-status.preset.special.international-womens-day',
    away: true,
  },
  IS_CHRISTCHURCH_NATIONAL_REMEMBRANCE_DAY && {
    id: 'christchurch-national-remembrance-day',
    emoji: '🇳🇿',
    messageI18n: 'user-status.preset.special.christchurch-national-remembrance-day',
    away: true,
  },
  IS_PRIDE_MONTH && {
    id: 'pride-month',
    emoji: '🏳️‍🌈',
    messageI18n: 'user-status.preset.special.pride-month',
    away: true,
  },
  IS_MAORI_LANGUAGE_WEEK && {
    id: 'maori-language-week',
    emoji: 'maori-language-week-2019',
    messageI18n: 'user-status.preset.special.maori-language-week',
    away: true,
  },
  IS_HALLOWEEN && {
    id: 'halloween',
    emoji: '🎃',
    messageI18n: 'user-status.preset.special.happy-halloween',
    away: true,
  },
  IS_EARTH_DAY && {
    id: 'earth-day',
    emoji: '💙',
    messageI18n: 'user-status.preset.special.earth-day',
    away: true,
  },
  IS_FOURTH_OF_JULY && {
    id: 'fourth-july',
    emoji: '🇺🇸',
    messageI18n: 'user-status.preset.special.fourth-july',
    away: true,
  },
  IS_NEW_YEAR && {
    id: 'new-year',
    emoji: '🎉',
    messageI18n: 'user-status.preset.special.happy-new-year',
    away: true,
  },
  IS_CHINESE_NEW_YEAR && {
    id: 'chinese-new-year',
    emoji: '🐉',
    messageI18n: 'user-status.preset.special.happy-chinese-new-year',
    away: true,
  },
  IS_EASTER && {
    id: 'easter',
    emoji: '🐰',
    messageI18n: 'user-status.preset.special.happy-easter',
    away: true,
  },
  IS_CORONATION_DAY && {
    id: 'coronation',
    emoji: '👑',
    messageI18n: 'user-status.preset.special.coronation-day',
    away: true,
  },
].filter(Boolean);

function LoadingIcon() {
  return <InlineSpinner color="#f8f8f8" size={20} />;
}

function UserStatusMenu({
  customPresets = [],
  loading,
  status,
  onSetStatus,
  onClearStatus,
}) {
  const [isPopupMenuOpened, setIsPopupMenuOpened] = useState(false);
  const allPresets = [].concat(presets).concat(customPresets);
  const hasStatus = status && (status.emoji || status.message);
  const selectedStatus = hasStatus
    ? (
      allPresets.filter(presetStatus => (
        presetStatus.emoji === status.emoji &&
        (presetStatus.message || Translation.text(presetStatus.messageI18n)) === status.message && // compare with the translated message
        presetStatus.away === status.away
      ))[0]
    )
    : null;
  const hasCustomStatus = hasStatus && !selectedStatus;
  return (
    <Popover
      onShow={() => setIsPopupMenuOpened(true)}
      onHide={() => setIsPopupMenuOpened(false)}
      content={
        <div>
          <OptionList wrap={false}>
            <Option
              label={<Translation value="user-status.status" />}
              disabled
            />
            {allPresets.map(presetStatus => (
              <Option
                key={presetStatus.id}
                label={
                  <UserStatusPreset
                    emoji={presetStatus.emoji}
                    message={presetStatus.message || <Translation value={presetStatus.messageI18n} />}
                  />
                }
                checked={presetStatus === selectedStatus}
                checkedIcon={loading ? <LoadingIcon /> : null}
                onClick={() => {
                  if (presetStatus !== selectedStatus) {
                    onSetStatus({
                      emoji: presetStatus.emoji,
                      message: presetStatus.message || Translation.text(presetStatus.messageI18n),
                      away: presetStatus.away,
                    });
                  }
                }}
              />
            ))}
            {hasCustomStatus &&
              <Option
                label={
                  <UserStatusPreset
                    emoji={status.emoji}
                    message={status.message}
                  />
                }
                checked
                checkedIcon={loading ? <LoadingIcon /> : null}
              />
            }
            <Separator />
            {hasStatus &&
              <Option
                label={<Translation value="user-status.clear-status" />}
                onClick={onClearStatus}
              />
            }
          </OptionList>
        </div>
      }
      down
      center
      arrow
      offset={15}
    >
      <div>
        <Tooltip
          maxWidth={150}
          down
          center
          title={<Translation value="dashboard.status" />}
          disabled={isPopupMenuOpened}
        >
          <button
            type="button"
            className={css.UserStatusMenu__button}
            aria-label="Status"
          >
            {status
              ? (
                <div className={css.UserStatusMenu__button__emoji}>
                  <Emoji text={status.emoji} />
                </div>
              )
              : (
                <InlineSVG
                  src="img/icons/user-status.svg"
                  width="24"
                  height="24"
                  fill="#8c8c8c"
                />
              )
            }
          </button>
        </Tooltip>
      </div>
    </Popover>
  );
}

export default UserStatusMenu;
