import React from 'react';

let currentUrl = null;

function setCurrentUrl() {
  currentUrl = window.__pageproof_bridge__.$location.path();
  invokeSubscribers();
}

const subscribers = [];

function invokeSubscribers() {
  subscribers.forEach(subscriber => subscriber(currentUrl));
}

class URLSubscription extends React.Component {
  constructor(props) {
    super(props);

    if (!currentUrl) {
      setCurrentUrl();
      window.__pageproof_bridge__.$rootScope.$on('$locationChangeSuccess', setCurrentUrl);
    }

    this.state = {
      url: currentUrl,
    };
  }

  componentDidMount() {
    subscribers.push(this.onChange);
  }

  componentWillUnmount() {
    subscribers.splice(subscribers.indexOf(this.onChange), 1);
  }

  onChange = (url) => {
    this.setState({url});
  }

  render() {
    return this.props.children({
      path: this.state.url,
    });
  }
}

export default URLSubscription;
