import { useState, useEffect } from 'react';

const getLogo = appCtrl => (
  appCtrl.logos
    ? (
      appCtrl.logos.proof ||
      appCtrl.logos.user ||
      appCtrl.logos.login ||
      null
    )
    : null
);

function useCustomLogo() {
  const [customLogo, setCustomLogo] = useState(() => (
    getLogo(window.__pageproof_ctrl__.appCtrl)
  ));

  useEffect(() => (
    window.__pageproof_ctrl__.appCtrl.$watch(getLogo, (logo) => {
      setCustomLogo(logo);
    })
  ), []);

  return customLogo;
}

export default useCustomLogo;
