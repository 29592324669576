/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import Pill from '../Pill';
import WrapConditionally from '../WrapConditionally';
import Tooltip from '../Tooltip';

function OptionLabel({ title, aside, description, asideTooltip, showAside }) {
  return (
    <div>
      <div
        className={classname('DropdownOption__label__title', {
          'DropdownOption__label__title--flex': aside,
        })}
      >
        <strong className={classname({ 'DropdownOption__label__title--truncated': aside })}>
          {title}
        </strong>
        {/* TODO: Remove the css display:none and control with showAside */}
        {aside && (
          <WrapConditionally
            condition={!!asideTooltip}
            wrapper={(
              <Tooltip
                title={asideTooltip}
                up
                center
                maxWidth={200}
                offset={15}
                arrow
              />
            )}
          >
            <span className={showAside ? 'DropdownOption__label__show_aside' : 'DropdownOption__label__aside'}>
              <Pill label={aside} />
            </span>
          </WrapConditionally>
        )}
      </div>
      <div
        className="show-options"
        style={{ opacity: 0.5 }}
      >
        {description}
      </div>
    </div>
  );
}

export default OptionLabel;
