/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './UnstyledButton.scss';

function UnstyledButton(props) {
  return (
    <button
      {...props}
      className={classname(css.UnstyledButton, props.className)}
      aria-label={props.ariaLabel}
    />
  );
}

export default UnstyledButton;
