/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import NudgeMessageModal from '../containers/NudgeMessageModal';
import { openModal } from '../util/modal';
import { sdk } from '../util/sdk';

export function nudgeUser(workflowId, userId) {
  return new Promise((resolve, reject) => {
    const { destroy: close } = openModal(
      <NudgeMessageModal
        workflowId={workflowId}
        userId={userId}
        onCancel={() => close()}
        onSubmit={(nudgeMessage) => {
          resolve(sdk.workflows.nudgeUser(workflowId, userId, nudgeMessage));
          close();
        }}
      />,
      () => reject(new Error('User dismissed the nudge message dialog.')),
    );
  });
}

export function nudgeAll(workflowId) {
  return new Promise((resolve, reject) => {
    const { destroy: close } = openModal(
      <NudgeMessageModal
        workflowId={workflowId}
        onCancel={() => close()}
        onSubmit={(nudgeMessage) => {
          resolve(sdk.workflows.nudgeAll(workflowId, nudgeMessage));
          close();
        }}
      />,
      () => reject(new Error('User dismissed the nudge all message dialog.')),
    );
  });
}
