/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './PromptMFA.scss';
import OtpInputField from '../../OtpInputField';
import Message from './Message';

const PromptMFA = ({ email, onTOTP, loading }) => {
  const [totp, setTotp] = useState('');

  const onCodeInput = (input) => {
    setTotp(input);
    if (input.length === 6) {
      onTOTP(email, input)
        .then(() => setTotp(''));
    }
  };

  return (
    <div className={css.PromptMFA}>
      <Message translation="login.mfa" />
      <OtpInputField
        // key is required for force re-render to autofocus input field upon initial load
        key={loading}
        autoFocus
        value={totp}
        onChange={onCodeInput}
        isDisabled={loading}
      />
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  PromptMFA.propTypes = {
    email: PropTypes.string.isRequired,
    onTOTP: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };
}

export default PromptMFA;
