/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './EmailBrandingPreviewEmail.scss';
import colorShade from '../../util/colorShade';

const DEFAULT_TITLE_TEXT_COLOR = '#138b3b';
const DEFAULT_LINK_TEXT_COLOR = '#138b3b';
const DEFAULT_BUTTON_BACKGROUND_COLOR = '#138b3b';
const DEFAULT_BUTTON_TEXT_COLOR = '#ffffff';

const EmailBrandingPreviewEmail = ({
  titleTextColor,
  linkTextColor,
  buttonBackgroundColor,
  buttonTextColor,
  emailFooter,
}) => (
  <div
    className={css.EmailBrandingPreviewEmail}
    style={{
      '--titleTextColor': titleTextColor || DEFAULT_TITLE_TEXT_COLOR,
      '--linkTextColor': linkTextColor || DEFAULT_LINK_TEXT_COLOR,
      '--linkTextHoverColor': colorShade(linkTextColor || DEFAULT_LINK_TEXT_COLOR, -50),
      '--buttonBackgroundColor': buttonBackgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR,
      '--buttonBackgroundHoverColor': colorShade(buttonBackgroundColor || DEFAULT_BUTTON_BACKGROUND_COLOR, -50),
      '--buttonTextColor': buttonTextColor || DEFAULT_BUTTON_TEXT_COLOR,
    }}
  >
    <div className={css.EmailBrandingPreviewEmail__body}>
      <div className={css.EmailBrandingPreviewEmail__body__title}>
        Your invitation to proof
      </div>
      <p>Hi Luke</p>
      <p>You have been asked to review a proof:</p>
      <span>Star Wars proof</span>
      <div>Version 1</div>
      <div>
        Due on
        <u>&nbsp;Friday, May 4th, 2022 5:00pm</u>
      </div>
      <p>
        You&rsquo;re the&nbsp;
        <b>approver</b>
        &nbsp;on the proof.
      </p>
      <div className={css.EmailBrandingPreviewEmail__body__button}>
        VIEW PROOF
      </div>
      <div className={css.EmailBrandingPreviewEmail__body__note}>
        Click the button to start reviewing. You&rsquo;ll find the comment tool is on the left, and this&nbsp;
        <span>quick guide</span>
        &nbsp;and&nbsp;
        <span>short video</span>
        &nbsp;will help you learn more.
      </div>
    </div>
    {emailFooter && (
      <div className={css.EmailBrandingPreviewEmail__footer}>
        <img
          src="/img/email/footer-logo.png"
          alt="PageProof logo"
        />
        <div className={css.EmailBrandingPreviewEmail__footer__text}>
          <span>ABOUT PAGEPROOF</span>
          <span>TERMS AND PRIVACY</span>
        </div>
      </div>
    )}
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  EmailBrandingPreviewEmail.propTypes = {
    titleTextColor: PropTypes.string,
    linkTextColor: PropTypes.string,
    buttonBackgroundColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    emailFooter: PropTypes.bool,
  };
}

export default EmailBrandingPreviewEmail;
