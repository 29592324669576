/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import gql from 'graphql-tag';

export default gql`
  query ws_Web_getUserPreferences {
    userPreferences {
      appLanguage {
        value
        defaultValue
      }
      completedWalkthroughs {
        value
      }
      messageToReviewers {
        value
      }
    }
  }
`;
