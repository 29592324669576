/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './OAuthInput.scss';
import { Button } from '../../../components/Button';
import InputOptions from '../../../components/ProofSetup/components/InputOptions';
import { ConfirmDeleteOption } from '../../../components/PopupMenu';
import { Translation } from '../../../components/Text';

export function OAuthInput({ integrationLabel, integrationsClient, onConfigurationOptionInput, integrationKey, option }) {
  const [retrievingUrl, setRetrievingUrl] = useState(false);

  if (!option.value) {
    const openAuthWindow = () => {
      if (retrievingUrl) {
        return;
      }
      // todo: open window with spinner
      setRetrievingUrl(true);
      integrationsClient.current.getSetupUrl(integrationKey)
        .then(({ url, windowFeatures }) => {
          window.open(url, 'IntegrationAuth', windowFeatures);
          setRetrievingUrl(false);
        });
    };

    return (
      <div className={css.OAuthUser}>
        <Button
          className={css.OAuthUser__signInButton}
          label={
            <Translation
              value="domain-admin.integrations.sign-in-to"
              params={{ integrationLabel }}
            />
          }
          onClick={openAuthWindow}
        />
      </div>
    );
  }

  const externalUser = !!option.value.externalUser.name;

  return (
    <div className={classname(css.OAuthUser, css['OAuthUser--withOptions'])}>
      {externalUser && (
        <a href={`mailto:${option.value.externalUser.email}`}>{option.value.externalUser.name}</a>
      )}
      {externalUser
        ? (
          <span className={css.OAuthUser__configuredBy}>
            (set up by&nbsp;
            <a href={`mailto:${option.value.pageproofUser.email}`}>{option.value.pageproofUser.name}</a>
            )
          </span>
        )
        : (
          <span className={classname(css.OAuthUser__configuredBy, css['OAuthUser__configuredBy--withoutExternal'])}>
            Set up by&nbsp;
            <a href={`mailto:${option.value.pageproofUser.email}`}>{option.value.pageproofUser.name}</a>
          </span>
        )
      }

      <InputOptions
        absolute
        options={[
          <ConfirmDeleteOption
            variant="remove"
            onClick={() => onConfigurationOptionInput(option.key, null)}
          />,
        ]}
      />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  OAuthInput.propTypes = {
    integrationLabel: PropTypes.string.isRequired,
    integrationsClient: PropTypes.objectOf(PropTypes.any).isRequired,
    onConfigurationOptionInput: PropTypes.func.isRequired,
    integrationKey: PropTypes.string.isRequired,
    option: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}

export default OAuthInput;
