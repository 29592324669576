/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { PopupMenu, Option } from '../PopupMenu';
import css from './VersionsButton.scss';

function VersionsButton({
  direction,
  options,
  arrow,
  variant,
  buttonLabel,
}) {
  return (
    <PopupMenu
      up={direction === 'up'}
      down={direction === 'down'}
      options={options}
      disabled={!options || options.length === 0}
      variant={variant}
      maxHeight="calc(100vh - 100px)"
    >
      <div className={css.VersionsButton}>
        {buttonLabel}
        {arrow && <div className={css.VersionsButton__arrow} />}
      </div>
    </PopupMenu>
  );
}

VersionsButton.Option = Option;

if (process.env !== 'production') {
  VersionsButton.propTypes = {
    direction: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    arrow: PropTypes.bool,
    variant: PropTypes.string,
    buttonLabel: PropTypes.node,
  };
}

export default VersionsButton;
