/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Fragment } from 'react';
import classname from 'classname';
import Avatar from '../Avatar/Avatar';
import Input from '../Input';
import FormControl from '../FormControl';
import Button from '../Button/Button';
import ButtonGroup from '../ButtonGroup';
import Spinner from '../Spinner/Spinner';
import Overlay from '../Flyout/Overlay';
import Reveal from '../Reveal';
import SideBySide from '../Transitions/SideBySide';
import css from './UnifiedLogin.scss';
import Translation from '../Text/Translation';
import Muted from '../Text/Muted';
import Delay from '../Delay';
import Message from './components/Message';
import PromptActivate from './components/PromptActivate';
import NewPasswordConfirmationFieldGroup from '../NewPasswordConfirmationFieldGroup';
import PromptMFA from './components/PromptMFA';

// TODO remove eslint-disable
/* eslint-disable */

function FormLabel({children}) {
  return (
    <div className={css['UnifiedLogin__formLabel']}>
      {children}
    </div>
  );
}

class PromptEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
    };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.props.email && this.state.email === this.props.email) {
      this.setState({ email: nextProps.email });
    }
  }

  onChangeEmail = (email) => {
    this.setState({ email });
  }

  canContinue() {
    return this.state.email && (this.state.email.length >= 3);
  }

  render () {
    return (
      <Fragment>
        <div className={css.UnifiedLogin__welcome}>
          <Translation value="login.message.welcome" />
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (this.canContinue()) {
              this.props.onEmail(this.state.email);
            }
          }}
        >
          <FormControl
            compact
            label={
              <FormLabel>
                <Translation value="label.email-address" />
              </FormLabel>
            }
          >
            <Input
              variant="unified"
              type="email"
              name="email"
              id="email"
              value={this.state.email}
              // onInput is used because Chrome for iOS does not fire onChange when autofill is used
              onInput={(event) => this.onChangeEmail(event.target.value)}
              disabled={this.props.loading}
              required
              autoFocus
              autoComplete="email"
              onFocus={(event) => {
                event.target.select();
              }}
            />
          </FormControl>
          <div style={{height: 10}} />
          <ButtonGroup align="center">
            <Button
              type="submit"
              variant="primary"
              label={<Translation value="button.next" />}
              disabled={!this.canContinue() || this.props.loading}
            />
          </ButtonGroup>
        </form>
      </Fragment>
    );
  }
}

class PromptPassword extends Component {
  onChangePassword = (input) => {
    this.props.setPassword(input);
  }

  canLogin() {
    return this.props.password && (this.props.password.length >= 6);
  }

  render() {
    return (
      <Fragment>
        <div className={css.UnifiedLogin__welcome}>
          <Translation value="login.message.hello" />, {this.props.name}
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            if (this.canLogin()) {
              this.props.onPassword(this.props.password);
            }
          }}
        >
          <input
            type="hidden"
            name="email"
            value={this.props.email}
          />
          <FormControl
            compact
            label={
              <Fragment>
                <a
                  href="#"
                  className={css['UnifiedLogin__rightLabel']}
                  onClick={(event) => {
                    event.preventDefault();
                    this.props.onForgot();
                  }}
                >
                  <Translation value="login.message.forgot-password" />
                </a>
                <FormLabel>
                  <Translation value="login.label.password" />
                </FormLabel>
              </Fragment>
            }
          >
            <Input
              variant="unified"
              type="password"
              name="password"
              id="password"
              value={this.props.password}
              // onInput is used because Chrome for iOS does not fire onChange when autofill is used
              onInput={(event) => this.onChangePassword(event.target.value)}
              disabled={this.props.loading}
              required
              minLength="8"
              autoFocus
              autoComplete="current-password"
            />
          </FormControl>
          <div style={{height: 10}} />
          <ButtonGroup align="center">
            <Button
              type="button"
              variant="text"
              label={<Translation value="button.back" />}
              autoWidth
              onClick={this.props.onBack}
            />
            <Button
              type="submit"
              variant="primary"
              label={<Translation value="login.button.login" />}
              disabled={!this.canLogin() || this.props.loading}
            />
          </ButtonGroup>
        </form>
      </Fragment>
    );
  }
}

function OnlyBack({onBack}) {
  return (
    <ButtonGroup align="center">
      <Button
        type="button"
        variant="primary"
        label={<Translation value="button.back" />}
        onClick={onBack}
      />
    </ButtonGroup>
  );
}

function AccountDisabled(props) {
  return (
    <Fragment>
      <Message translation="login.message.account-disabled" minimal />
      <br />
      <OnlyBack {...props} />
    </Fragment>
  );
}

class CreatePassword extends Component {
  state = {
    password: '',
    enableSubmitButton: false,
  };

  onPasswordChange = (password, isValid) => {
    this.setState({
      password: password,
      enableSubmitButton: isValid,
    });
  };

  render() {
    return (
      <form
        onSubmit={(event) => {
          event.preventDefault();

          if (this.state.enableSubmitButton) {
            this.props.onPassword(this.state.password);
          }
        }}
      >
        <div className={css.UnifiedLogin__welcome}>
          <Translation value="login.message.your-password" />
        </div>
        <NewPasswordConfirmationFieldGroup
          loading={!!this.props.loading}
          onChange={this.onPasswordChange}
          autoFocus
          showRequirementsWhenBlurredAndEmpty
        />
        {this.props.mode !== 'reset-password' && (
          <div className={css.UnifiedLogin__termsAndConditions}>
            <Muted>
              <Translation value="create-account.agree" />
            </Muted>
          </div>
        )}
        <div className={css.UnifiedLogin__confirmPasswordButtonGroup}>
          <ButtonGroup align="center" >
            {this.props.mode === 'create-password' && (
              <Button
                type="button"
                variant="text"
                label={<Translation value="button.back" />}
                autoWidth
                onClick={this.props.onBack}
              />
            )}
            <Button
              type="submit"
              variant="primary"
              label={
                <Translation
                  value={() => {
                    switch (this.props.mode) {
                      case 'setup': return 'login.button.set-password';
                      case 'reset-password': return 'reset-password.update.button.reset-password';
                      default: return 'login.button.create-account';
                    }
                  }}
                />
              }
              disabled={!this.state.enableSubmitButton || this.props.loading}
            />
          </ButtonGroup>
        </div>
      </form>
    );
  }
}

function MultipleOptions({
  options,
  onOption,
  onBack,
}) {
  return (
    <div className={css.UnifiedLogin__options}>
      <ButtonGroup align="justify">
        {options.map((option) => {
          return (
            <Button
              key={option.type + (option.name || '')}
              variant="primary"
              onClick={() => onOption(option)}
              label={
                <Fragment>
                  {option.button &&
                    <img
                      src={option.button.logo}
                      width={20}
                      height={20}
                      alt={option.name}
                      style={{
                        marginRight: 14,
                        marginBottom: -4,
                      }}
                    />
                  }
                  <Translation
                    value={`login.multiple-options.option.${option.type}`}
                    params={option}
                  />
                </Fragment>
              }
              style={option.button ?
                {
                  backgroundColor: option.button.backgroundColor,
                  color: option.button.textColor,
                }
                : null
              }
            />
          );
        })}
        <Button
          variant="text"
          label={<Translation value="button.back" />}
          onClick={onBack}
        />
      </ButtonGroup>
    </div>
  );
}

function RedirectingToSSOLogin({url}) {
  return (
    <Fragment>
      <Message
        translation="login.message.redirecting-to-sso-login"
        minimal
      />
      <Delay ms={5000}>
        {complete => (
          <Reveal
            visible={complete}
            align="bottom"
          >
            <div style={{overflow: 'hidden'}}>
              <div style={{height: 30}} />
              <ButtonGroup align="center">
                <Button
                  component="a"
                  variant="primary"
                  label={<Translation value="login.message.redirecting-to-sso-login.try-again" />}
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </ButtonGroup>
            </div>
          </Reveal>
        )}
      </Delay>
    </Fragment>
  );
}

function ForgotMessage({}) {
  return (
    <div>
      Forgot Message
    </div>
  );
}

function Error({error}) {
  return (
    <div>
      Error: {error}
    </div>
  );
}

function Spacer({height}) {
  return (
    <div style={{height: height || 100}} />
  );
}

// The order matters here, as it is used to figure out whether the screen should animate backwards
// or forwards (automatically).
const modes = {
  ':before': Spacer,
  ':custom': (props) => <span>{props.children}</span>,
  'prompt-email': PromptEmail,
  'multiple-options': MultipleOptions,
  'prompt-password': PromptPassword,
  'prompt-mfa': PromptMFA,
  'create-password': CreatePassword,
  'reset-password': CreatePassword,
  'setup': CreatePassword,
  'forgot-message': ForgotMessage,
  'redirecting-to-sso-login': RedirectingToSSOLogin,
  'account-disabled': AccountDisabled,
  'prompt-activate': PromptActivate,
  'only-back': OnlyBack,
  'error': Error,
  'message': () => <span />,
  ':after': Spacer,
};
const modeNames = Object.keys(modes);

class UnifiedLogin extends Component {
  state = {
    reverse: false,
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const previousModeIndex = modeNames.indexOf(this.props.mode);
    const nextModeIndex = modeNames.indexOf(nextProps.mode);
    this.setState({
      reverse: nextModeIndex < previousModeIndex,
    });
  }

  render() {
    const {
      mode,
      avatar,
      ...props
    } = this.props;
    const {
      message,
      loading,
    } = this.props;
    const RouteComponent = modes[mode] || (() => <Error error="unknown-mode" />);
    const content = <RouteComponent mode={mode} {...props} />;
    return (
      <div className={css.UnifiedLogin}>
        <div className={css.UnifiedLogin__avatar}>
          <SideBySide
            id={avatar}
            reverse={this.state.reverse}
          >
            {avatar
              ? <Avatar url={avatar} size={100} />
              : <span />
            }
          </SideBySide>
        </div>
        {loading &&
          <Delay ms={typeof loading === 'string' ? 0 : 1500}>
            <Delay ms={1}>
              {complete => (
                <Overlay
                  visible={complete}
                  style={{
                    backgroundColor: typeof loading === 'string'
                      ? 'rgba(255, 255, 255, .9)'
                      : 'rgba(255, 255, 255, .5)',
                    borderRadius: 8,
                  }}
                >
                  <div className={css.UnifiedLogin__spinner}>
                    <Spinner />
                    {typeof loading === 'string' &&
                      <div className={css.UnifiedLogin__spinner__message}>
                        <Translation value={loading} />
                      </div>
                    }
                  </div>
                </Overlay>
              )}
            </Delay>
          </Delay>
        }
        <Reveal
          align="bottom"
          duration={300}
          visible={!!message}
        >
          <div>
            <Message translation={message} params={props} />
            <div style={{height: mode === 'message' ? 10 : 40}} />
          </div>
        </Reveal>
        <div className={css.UnifiedLogin__content}>
          <SideBySide
            id={mode}
            reverse={this.state.reverse}
          >
            <div
              key={mode}
              className={classname(css.UnifiedLogin__inner, {
                [css['UnifiedLogin__inner--disabled']]: loading,
              })}
            >
              {content}
            </div>
          </SideBySide>
        </div>
      </div>
    );
  }
}

export default UnifiedLogin;
