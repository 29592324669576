/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import getProof from './getProof';
import getShared from './getShared';

function serialiseReminders(reminders) {
  if (!reminders || reminders.length === 0) {
    return '';
  }
  return reminders.map(r => r.id).sort().join(',');
}

export default function getProofEditedFields(_proof, _shared, parameters, userPreferences) {
  const proof = getProof(_proof, _shared, parameters, userPreferences);
  const shared = getShared(_shared, parameters, userPreferences);

  // TODO i18n
  const fields = [];
  if (proof.reference !== shared.reference || JSON.stringify(shared.integrationReferences) !== JSON.stringify(proof.integrationReferences)) {
    fields.push('Reference');
  }
  if (!shared.tags.every(tag => proof.tags.indexOf(tag) !== -1)) {
    fields.push('Tags');
  }
  if (proof.messageToReviewers !== shared.messageToReviewers) {
    fields.push('Message to reviewers');
  }
  if (proof.dueDate !== shared.dueDate) {
    fields.push('Due date');
  }
  if (proof.canDownload !== shared.canDownload) {
    fields.push('Can download');
  }
  if (proof.isPublic !== shared.isPublic) {
    fields.push('Can share link');
  }
  if (serialiseReminders(proof.reminders) !== serialiseReminders(shared.reminders)) {
    fields.push('Reminders');
  }
  if (proof.commentVisibility !== shared.commentVisibility) {
    fields.push('Invisible reviewer comments');
  }

  return fields;
}
