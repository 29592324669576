/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { useLocalStorage } from '../../components/LocalStorage';
import AudioBookmarkButton from '../../components/AudioBookmarkButton';

function AudioBookmarkContainer({ proofId, currentTime, scrub }) {
  const [bookMarkTime, _setBookmarkTime] = useLocalStorage('pageproof.app.audioBookmark', null);

  const getTimeStamp = () => {
    setBookmarkTime(currentTime);
  };

  const setBookmarkTime = (time) => {
    if (!bookMarkTime || !bookMarkTime[proofId]) {
      const newBookmarkTimes = Object.assign({}, bookMarkTime);
      newBookmarkTimes[proofId] = time;
      _setBookmarkTime(newBookmarkTimes);
    }
  };

  const removeBookmarkTime = () => {
    if (bookMarkTime && (bookMarkTime[proofId] || bookMarkTime[proofId] === 0)) {
      const newBookmarkTimes = Object.assign({}, bookMarkTime);
      delete newBookmarkTimes[proofId];
      _setBookmarkTime(newBookmarkTimes);
    }
  };

  const scrubToTime = () => {
    scrub(bookMarkTime[proofId]);
  };
  // eslint-disable-next-line no-prototype-builtins
  const isProofBookmarked = bookMarkTime && bookMarkTime.hasOwnProperty(proofId);

  return (
    <AudioBookmarkButton
      getTimeStamp={getTimeStamp}
      isProofBookmarked={isProofBookmarked}
      currentTime={currentTime}
      bookMarkTime={bookMarkTime ? bookMarkTime[proofId] : null}
      removeBookmarkTime={removeBookmarkTime}
      scrubToTime={scrubToTime}
    />
  );
}

export default AudioBookmarkContainer;
