/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown, {
  Option as DropdownOption,
  OptionLabel as DropdownOptionLabel,
} from '../Dropdown';
import FormControl from '../FormControl';

const parseOption = (option) => {
  if (typeof option === 'string') {
    return {
      value: option,
      label: option,
    };
  } else {
    return option;
  }
};

function ProfileDropdown({
  label,
  options,
  selected,
  placeholder,
  onClick,
}) {
  const regex = /[^\w]/g;
  const optionsMap = {};
  options.forEach((option) => {
    const parsed = parseOption(option);
    optionsMap[parsed.value] = parsed;
  });
  return (
    <div className="ProfileDropdown">
      <FormControl label={label}>
        <Dropdown
          selected={selected}
          variant="light"
          placeholder={
            <DropdownOption
              label={
                (optionsMap[selected] && optionsMap[selected].label) ||
                placeholder
              }
            />
          }
          keyDownScroll
          regex={regex}
        >
          {Object.keys(optionsMap).map((value) => {
            const _label = optionsMap[value].label;
            return (
              <DropdownOption
                key={value}
                onClick={() => onClick(value)}
                selected={selected === value}
                name={_label.replace(regex, '')}
                label={<DropdownOptionLabel title={_label} />}
              />
            );
          })}
        </Dropdown>
      </FormControl>
    </div>
  );
}

ProfileDropdown.propTypes = {
  label: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.node.isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func,
};

export default ProfileDropdown;
