/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Translation from '../../components/Text/Translation';
import css from './NoProofMessageStyles.scss';
import PillsListWithDiscard from '../../components/PillsListWithDiscard';
import FilterLabel from '../../components/DateFilterLabel/FilterLabel';

const NoProofMessage = ({
  tab,
  from,
  isStaticTab,
  isCollectionTab,
  noProofs,
  noProofsWithFilter,
  isLoading,
  canUpload,
  searchProps,
}) => {
  const tabName = from === 'team-dashboard' ? from + '.' + tab : tab;
  const { selectedDateFilters, selectedFilters, toggleDateFilter, toggleFilter } = searchProps;

  const filters = selectedFilters.concat(selectedDateFilters)
    .map((filter) => {
      if (filter.startDate) {
        return ({
          id: filter.type,
          type: <FilterLabel
            label={`dashboard.filters.${filter.type}`}
            startDate={filter.startDate}
            endDate={filter.endDate}
            isCentered
          />,
          onRemove: () => toggleDateFilter(filter.type, null, null),
        });
      }

      return {
        id: filter,
        type: <Translation value={`dashboard.filters.${filter}`} />,
        onRemove: () => toggleFilter(filter),
      };
    });

  if (noProofs && isCollectionTab) {
    return null;
  }

  return (
    <div className={css.NoProofMessage}>
      {(selectedFilters.length > 0 || selectedDateFilters.length > 0) &&
        (
          <PillsListWithDiscard list={filters} />
        )}

      {noProofs && !isLoading && !isCollectionTab && (
        isStaticTab
          ? <Translation value={`dashboard.tab.empty.${tabName}${!canUpload ? '.no-uploader' : ''}`} />
          : <Translation value="dashboard.search.no-results" />
      )}

      {!noProofs && noProofsWithFilter && !isLoading && (
        isCollectionTab
          ? <Translation value="dashboard.collection.no-result" />
          : (
            <Translation
              value={filters.length > 1
                ? 'dashboard.filter.no-result.multiple'
                : 'dashboard.filter.no-result'}
            />
          )
      )}
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  NoProofMessage.propTypes = {
    tab: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    from: PropTypes.string.isRequired,
    isStaticTab: PropTypes.bool.isRequired,
    isCollectionTab: PropTypes.bool.isRequired,
    noProofs: PropTypes.bool.isRequired,
    noProofsWithFilter: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    canUpload: PropTypes.bool,
    searchProps: PropTypes.objectOf(PropTypes.any),
  };
}

export default NoProofMessage;
