/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import env from '../../../../shared/env';
import { getSharedClientComponents } from './getSharedClientComponents';
import { validateUserSession } from './validateUserSession';
import { getAuthHeaders } from './getAuthHeaders';

let client;

export function getGraphiteClient() {
  if (!client) {
    client = createGraphiteClient(env.graphite_query_url, env.graphite_subscription_url);
  }

  return client;
}

function createGraphiteClient(queryUri, subscriptionUri) {
  validateUserSession();

  const wsLink = new WebSocketLink({
    uri: subscriptionUri,
    options: {
      reconnect: true,
      lazy: false,
      // Use connection params for 'headers' because websockets don't support custom headers.
      connectionParams: () => ({
        headers: {
          ...getAuthHeaders('GET'),
        },
      }),
    },
  });

  const httpLink = new HttpLink({
    uri: queryUri,
  });


  // We use the context link to set the auth headers every query request to make sure the hmac is as recent as possible
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...getAuthHeaders('POST'),
    },
  }));

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') ||
        (definition.name && definition.name.value.startsWith('ws_'))
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const { cache, defaultOptions } = getSharedClientComponents();

  return new ApolloClient({
    link,
    cache,
    defaultOptions,
  });
}
