/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import classname from 'classname';
import { util } from '@pageproof/sdk';
import css from './CreateReply.scss';
import { hasValue } from '../Editor/serialization';
import CreateReply from '.';
import { KeepVirtualListItemMounted } from '../../../containers/CommentsPane/components/KeepVirtualListItemMounted';

class CreateReplyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValue,
      attachments: null,
      replyEditorVisible: false,
      isPrivate: this.getIsPrivate(),
      mentionsCount: util.comments.mentions(this.props.initialValue).length,
    };
  }

  getCommentVisibilityDefault = () => {
    const { UserService: { userData } } = window.__pageproof_bridge__;
    const userId = userData && userData.userId;
    const rawPreferences = window.localStorage.getItem(`pageproof.cache.user-preferences.${userId}`);
    const userPreferences = rawPreferences ? JSON.parse(rawPreferences) : null;
    return userPreferences ? userPreferences.find(pref => pref.Name === 'commentVisibilityDefault') : null;
  }

  getIsPrivate = () => {
    const commentVisibilityDefault = this.getCommentVisibilityDefault();
    const isPrivate = this.props.canTogglePrivate && commentVisibilityDefault ? commentVisibilityDefault.Value === 'private' : this.props.defaultIsPrivate;
    return isPrivate;
  }

  onChange = (value) => {
    this.setState(() => ({
      value,
      mentionsCount: util.comments.mentions(value).length,
    }));
  }

  onAttach = (files) => {
    const { attachments } = this.state;
    this.setState({
      attachments: attachments ? [...attachments, ...files] : files,
    });
  }

  onAttachmentDelete = (fileName) => {
    const { attachments } = this.state;
    this.setState({
      attachments: attachments.filter(attachment => attachment.name !== fileName),
    });
  }

  getAttachmentProps = attachments => attachments.map(attachment => ({
    name: attachment.name,
    canDelete: true,
    onDelete: () => this.onAttachmentDelete(attachment.name),
  }));

  onToggleReplyEditor = () => {
    this.setState(prevState => ({
      replyEditorVisible: !prevState.replyEditorVisible,
    }));
  };

  onCreate = () => {
    if (hasValue(this.state.value)) {
      const success = this.props.onCreate({
        value: this.state.value,
        attachments: this.state.attachments,
        isPrivate: this.state.isPrivate,
      });

      if (success) {
        this.setState({
          value: this.props.initialValue,
          attachments: null,
          replyEditorVisible: false,
          isPrivate: this.getIsPrivate(),
        });
        this.editor.setValue('');
      }
    }
  }

  onTogglePrivate = () => {
    this.setState(prevState => ({
      isPrivate: !prevState.isPrivate,
    }));
  }

  render() {
    const { attachments, replyEditorVisible, value } = this.state;
    return (
      <div
        className={classname(
          css.CreateReply,
          replyEditorVisible ? 'CreateReply--out' : 'CreateReply--in',
          !replyEditorVisible ? css['CreateReply--no-overflow'] : css['CreateReply--overflow']
        )}
      >
        {replyEditorVisible && <KeepVirtualListItemMounted />}
        <CreateReply
          {...this.props}
          {...this.state}
          editorRef={ref => (this.editor = ref)}
          attachments={attachments && this.getAttachmentProps(attachments)}
          onToggleReplyEditor={this.onToggleReplyEditor}
          canSave={hasValue(value) && (!this.state.isPrivate || !this.state.mentionsCount)}
          onChange={this.onChange}
          onSave={this.onCreate}
          onAttach={this.onAttach}
          onTogglePrivate={this.onTogglePrivate}
          canTogglePrivate={this.props.canTogglePrivate && !this.state.mentionsCount}
          togglePrivateDisabledMessage={this.state.mentionsCount > 0 && this.props.canTogglePrivate ? 'Comments with mentions cannot be private' : null}
        />
      </div>
    );
  }
}

export default CreateReplyContainer;
