/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, Component } from 'react';
import classname from 'classname';
import { Option, OptionList } from '../PopupMenu';
import SideBySide from '../Transitions/SideBySide';
import insertBetweenItems from '../../util/insert-between-items';
import Translation from '../Text/Translation';
import css from './OptionChanger.scss';

class OptionChanger extends Component {
  state = {
    changing: this.props.open,
  };

  toggle(changing) {
    this.setState({ changing },
      () => {
        if (this.props.onUpdateChanger) {
          this.props.onUpdateChanger(changing);
        }
      });
  }

  render() {
    const {
      label,
      display,
      options,
      selected,
      multiple,
      keepOpen,
      onClick,
      insertBetweenChar,
      arrowWithOptionLabel,
      selectedOptionIcon,
    } = this.props;
    const {
      changing,
    } = this.state;
    let defaultOption = null;
    const selectedValues = multiple ? selected : [selected];
    const selectedOptions = options.filter((option) => {
      if (option.default) {
        defaultOption = option;
      }
      return !!(selectedValues && selectedValues.find(selectedValue => ((typeof selectedValue === 'string')
        ? selectedValue === option.value
        : selectedValue.type === option.value)));
    });
    const placeholder = defaultOption
      ? defaultOption.label
      : (this.props.placeholder || <Translation value="option-changer.placeholder.nothing" />);
    return (
      <Fragment>
        {label &&
          <div
            className={changing ? css.OptionChanger__link : null}
            onClick={() => this.toggle(false)}
          >
            <Option
              label={
                <div className={css.OptionChanger__back}>
                  <SideBySide
                    reverse={!changing}
                    id={changing}
                  >
                    {changing
                      ? <span>&#x276e;&nbsp;&nbsp;</span>
                      : <div style={{ width: 1 }}>&nbsp;</div>
                    }
                  </SideBySide>
                  <span className={css.OptionChanger__back__label}>
                    {label}
                  </span>
                </div>
              }
              disabled
            />
          </div>
        }
        <SideBySide
          reverse={!changing}
          id={changing}
        >
          {changing
            ? (
              <OptionList
                naked
                onClick={(option) => {
                  if (!multiple && !keepOpen) {
                    this.toggle(false);
                  }
                  onClick(option.value);
                }}
              >
                {options.map(option => (
                  <Option
                    key={option.value}
                    label={
                      <Fragment>
                        {option.label}
                        {option.default && <Translation value="option-changer.label.default" />}
                      </Fragment>
                    }
                    checked={
                      selectedOptions.length
                        ? selectedOptions.indexOf(option) !== -1
                        : option.default
                    }
                    onClick={() => option}
                    className={option.className}
                  />
                ))}
              </OptionList>
            )
            : (
              <Option
                label={
                  <div className={css['OptionChanger__option-label']}>
                    <div className={css['OptionChanger__option-label__label']}>
                      {display || insertBetweenItems(
                        selectedOptions.length
                          ? selectedOptions.map(option => (
                            <Option
                              label={option.label}
                              checked={!arrowWithOptionLabel}
                              checkedIcon={selectedOptionIcon}
                              className={classname(option.className, css['OptionChanger__option-label__selected'])}
                            />
                          ))
                          : [placeholder],
                        insertBetweenChar
                      )}
                    </div>
                    {arrowWithOptionLabel &&
                      <span className={css['OptionChanger__option-label__arrow']}>
                        &#x276e;
                      </span>
                    }
                  </div>
                }
                onClick={() => this.toggle(true)}
              />
            )
          }
        </SideBySide>
      </Fragment>
    );
  }
}

OptionChanger.defaultProps = {
  insertBetweenChar: ', ',
};

OptionChanger.Arrow = () => (
  <span className={css['OptionChanger__option-label__arrow']}>
    &#x276e;
  </span>
);

export default OptionChanger;
