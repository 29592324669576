/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Translation from '../Text/Translation';
import DrawingControlsOption from './DrawingControlsOption';
import css from './DrawingControls.scss';

function DrawingControls({
  canUndoDrawing,
  canRedoDrawing,
  onUndoDrawing,
  onRedoDrawing,
  onDeleteDrawing,
}) {
  return (
    <div className={css.DrawingControls}>
      <DrawingControlsOption
        label={<Translation value="proof.tools.undo" />}
        iconUrl="img/icons/undo.svg"
        disabled={!canUndoDrawing}
        keyboardShortcut={['command+z', 'ctrl+z']}
        onClick={onUndoDrawing}
      />
      <DrawingControlsOption
        label={<Translation value="proof.tools.redo" />}
        iconUrl="img/icons/redo.svg"
        disabled={!canRedoDrawing}
        keyboardShortcut={['command+shift+z', 'ctrl+y']}
        onClick={onRedoDrawing}
      />
      <DrawingControlsOption
        label={<Translation value="proof.tools.delete" />}
        iconUrl="img/icons/delete-all.svg"
        onClick={onDeleteDrawing}
      />
    </div>
  );
}

DrawingControls.propTypes = {
  canUndoDrawing: PropTypes.bool.isRequired,
  canRedoDrawing: PropTypes.bool.isRequired,
  onUndoDrawing: PropTypes.func.isRequired,
  onRedoDrawing: PropTypes.func.isRequired,
  onDeleteDrawing: PropTypes.func.isRequired,
};

export default DrawingControls;
