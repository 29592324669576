import { watch, unwatch } from '../../LocalStorage/watcher';

export default function watchUploadProgress(uploadId, callback) {
  return new Promise((resolve, reject) => {
    const localStorageKey = 'pageproof.app.upload-progress.' + uploadId;

    const watcherCallback = ({ newValue }) => {
      if (newValue) {
        try {
          const { percent } = JSON.parse(newValue);
          callback(percent);
        } catch (err) {
          reject(err);
        }
      } else {
        unwatch(localStorageKey, watcherCallback);
        resolve();
      }
    };

    watch(localStorageKey, watcherCallback);
  });
}
