/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable consistent-return */

import React, { useEffect, useState } from 'react';
import { sdk } from '../../util/sdk';
import { loadImage } from './utils';

function downloadAndDecrypt(colorSeparations, pageNumber, plateName) {
  return sdk.proofs.assets.colorSeparations.download(colorSeparations, pageNumber, plateName);
}

export const EncryptedColorSeparation = ({ proofId, colorSeparations, pageNumber, plateName, fallback = null, imageProps = {} }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const { promise, destroy } = loadEncryptedColorSeparation({ proofId, colorSeparations, pageNumber, plateName });
    promise.then(setImageUrl);
    return destroy;
  }, [colorSeparations, pageNumber, plateName]);

  if (!imageUrl) {
    return fallback;
  }

  return (
    <img
      src={imageUrl}
      alt=""
      {...imageProps}
    />
  );
};

export function loadEncryptedColorSeparation({ proofId, colorSeparations, pageNumber, plateName }) {
  const cacheKey = `color separation proof:${proofId} page:${pageNumber} plate name:${plateName}`;

  let destroy;

  const promise = new Promise((resolve, reject) => {
    destroy = loadImage(cacheKey, () => (
      downloadAndDecrypt(colorSeparations, pageNumber, plateName)
        .catch((err) => {
          reject(err);
          throw err;
        })
    ), resolve, null);
  });

  return {
    promise,
    destroy,
  };
}
