/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { Button } from '../Button';
import ButtonGroup from '../ButtonGroup';
import ProgressBar from '../ProgressBar';
import BrandingUploadFile from '../../containers/BrandingSettings/BrandingUploadFile';
import css from './ImportPdfComments.scss';
import { ModalHeading } from './components/ModalHeading';
import { ImportPdfCommentsSummary } from './importPdfComments/ImportPdfCommentsSummary';
import { ImportPdfCommentsOption } from './importPdfComments/ImportPdfCommentsOption';
import DiscardButtonOnHover from '../DiscardButtonOnHover';
import Alert from '../Alert';
import { Translation } from '../Text';

const ImportPdfComments = (props) => {
  const {
    importTask,
    onClose,
    options,
    onUpdateOption,
    importStatus,
    file,
    onSetFile,
    onStartImport,
    progress,
    canCreateAsPrivate,
    canIncludeStatuses,
    isAdminOnProof,
    acceptedMimeTypes,
    validation,
    onDiscardFile,
    canStartImport,
  } = props;

  const { isPrivate, includeStatuses, skipPreviouslyImported } = options;

  if (
    importStatus === 'completed' ||
    importStatus === 'failed'
  ) {
    const summary = importTask && importTask.summary;
    return (
      <Fragment>
        <ModalHeading
          heading={<Translation value="proof.import-comments.summary.heading" />}
          subheading={summary && summary.importedComments.total > 0
            ? <Translation value="proof.import-comments.summary.subheading" />
            : <Translation value="proof.import-comments.summary.subheading.no-comments" />
          }
        />
        {importStatus === 'failed' && (
          <Alert
            variant="warning"
            size="small"
          >
            <Translation value="proof.import-comments.summary.error" />
          </Alert>
        )}
        {summary && (
          <ImportPdfCommentsSummary
            importedComments={summary.importedComments}
            unsupportedAnnotationsImported={summary.unsupportedAnnotationsImported}
            showCommentStatusCounts={includeStatuses && !isPrivate}
            skippedComments={summary.skippedComments}
            skippedReplies={summary.skippedReplies}
          />
        )}
        <div className={css.ImportPdfComments__buttonGroup}>
          <ButtonGroup>
            <Button
              variant="primary"
              label={<Translation value="proof.import-comments.summary.button" />}
              onClick={onClose}
            />
          </ButtonGroup>
        </div>
      </Fragment>
    );
  }

  if (importStatus === 'importing') {
    return (
      <Fragment>
        <ModalHeading
          heading={<Translation value="proof.import-comments.status.importing" />}
        />
        <ProgressBar progress={progress} />
        {importTask
          ? (importTask.progressMessage || importTask.statusMessage || <Translation value="proof.import-comments.status.preparing" />)
          : <Translation value="proof.import-comments.status.uploading" />
        }
      </Fragment>
    );
  }


  return (
    <Fragment>
      <ModalHeading
        heading={<Translation value="proof.import-comments.setup.heading" />}
        subheading={<Translation value="proof.import-comments.setup.subheading" />}
      />
      {validation.errorMessages.length > 0 && (
        <Alert
          variant="negative"
          size="small"
        >
          {validation.errorMessages.map(message => (<div>{message}</div>))}
        </Alert>
      )}

      {validation.warningMessages.length > 0 && (
        <Alert
          variant="warning"
          size="small"
        >
          {validation.warningMessages.map(message => (<div>{message}</div>))}
        </Alert>
      )}

      {file
        ? (
          <div className={css.ImportPdfComments__selectedFile}>
            <DiscardButtonOnHover onDiscard={onDiscardFile}>
              <div className={css.ImportPdfComments__selectedFile__pill}>
                {file.name}
              </div>
            </DiscardButtonOnHover>
          </div>
        )
        : (
          <div className={css.ImportPdfComments__selectFileWrapper}>
            <BrandingUploadFile
              title={<Translation value="proof.import-comments.setup.drag-and-drop" />}
              onSelect={onSetFile}
              accept={acceptedMimeTypes}
            />
          </div>
        )
      }

      <ImportPdfCommentsOption
        value={skipPreviouslyImported}
        onChange={() => onUpdateOption('toggleSkipPreviouslyImported')}
        label="Skip previously imported comments"
        helpBubbleMessage="If we have identified comments from this PDF have already been imported, they will not be imported again."
      />

      <div>
        {canIncludeStatuses && (
          <ImportPdfCommentsOption
            value={includeStatuses && !isPrivate}
            onChange={() => onUpdateOption('toggleIncludeStatuses')}
            label={<Translation value="proof.import-comments.option.status.label" />}
            isDisabled={isPrivate}
            helpBubbleMessage={(
              <Fragment>
                <Translation value="proof.import-comments.option.status.tooltip.heading" />
                <ul>
                  <li>
                    <Translation value="proof.import-comments.option.status.tooltip.unmarked" />
                  </li>
                  <li>
                    <Translation value="proof.import-comments.option.status.tooltip.to-do" />
                  </li>
                  <li>
                    <Translation value="proof.import-comments.option.status.tooltip.done" />
                  </li>
                </ul>
              </Fragment>
            )}
          />
        )}

        {(canCreateAsPrivate || isAdminOnProof) && (
          <ImportPdfCommentsOption
            value={isPrivate}
            onChange={() => onUpdateOption('toggleIsPrivate')}
            label={<Translation value="proof.import-comments.option.private.label" />}
            isDisabled={!canCreateAsPrivate}
            helpBubbleMessage={!canCreateAsPrivate && isAdminOnProof && <Translation value="proof.import-comments.option.private.tooltip" />}
          />
        )}
      </div>

      <div className={css.ImportPdfComments__buttonGroup}>
        <ButtonGroup>
          <Button
            variant="text"
            label={<Translation value="proof.import-comments.setup.button.cancel" />}
            onClick={onClose}
          />
          <Button
            disabled={!canStartImport}
            variant="primary"
            label={<Translation value="proof.import-comments.setup.button.import" />}
            onClick={onStartImport}
          />
        </ButtonGroup>
      </div>
    </Fragment>
  );
};

export default ImportPdfComments;
