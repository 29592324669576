/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {createOrUsedCachedClient} from '../../../shared/sdk-client';
import {removeSavedSession} from '../../../shared/session';

const sdk = createOrUsedCachedClient(true);

function redirectToLogin() {
  const bridge = window.__pageproof_bridge__;
  const {$location, $rootScope} = bridge;
  $location.backward().path('login');
  $rootScope.$apply();
}

sdk.on('error.unauthorized', () => {
  if (sdk.session && !sdk.session.user.hasActivated) {
    return;
  }
  removeSavedSession();
  redirectToLogin();
});

export default () => sdk;
export {sdk};
