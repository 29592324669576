/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

class DashboardFilters {
  label = null;

  code = null;

  constructor(label, code) {
    this.label = label;
    this.code = code;
  }

  static filters = [
    'approved',
    'archived',
    'in proofing',
    'to-dos requested',
    'new version required',
    'with editor',
  ];

  static everything = new DashboardFilters('Everything', 'all');

  static approved = new DashboardFilters('Approved', 70);

  static archived = new DashboardFilters('Archived', 100);

  static ['in proofing'] = new DashboardFilters('In Proofing', '10,30');

  static ['to-dos requested'] = new DashboardFilters('To-dos Requested', 50);

  static ['new version required'] = new DashboardFilters('New Version Required', 60);

  static ['with editor'] = new DashboardFilters('With Editor', 55);
}

export default DashboardFilters;
