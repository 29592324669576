/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';
import ProfileAddressBook from '../../components/ProfileAddressBook';

const ProfileAddressBookContainer = () => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    $addressBook: addressBook,
    addressBookRepositoryService: addressBookRepository,
    sdk,
  } = window.__pageproof_bridge__;

  const { userId } = sdk.session;

  useEffect(() => {
    addressBook.load((emailsArr) => {
      setIsLoading(false);
      setEmails(emailsArr);
    });

    const offRemove = addressBookRepository.cache.on('remove', userId, () => {
      addressBook.load(setEmails);
    });
    const offUpdate = addressBookRepository.cache.on('update', userId, () => {
      addressBook.load(setEmails);
    });

    return () => {
      offRemove();
      offUpdate();
    };
  }, []);

  return (
    <ProfileAddressBook
      emails={emails}
      onRemove={addressBook.delete}
      isLoading={isLoading}
    />
  );
};

export default ProfileAddressBookContainer;
