/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function getQueryParams(location = window.location) {
  if (window.URLSearchParams) {
    const entries = Array.from(new URLSearchParams(location.search));
    return entries.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
  }
  return {};
}

export default getQueryParams;
