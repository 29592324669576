/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChecklistTemplateDropdown } from '../../components/ProofSetup/components/ChecklistTemplateDropdown';
import ChecklistTemplatePreview from '../../components/ChecklistTemplatePreview';
import { Button } from '../../components/Button';
import css from './AddChecklistManagementOption.scss';
import { Translation } from '../../components/Text';
import FormControl from '../../components/FormControl';
import HelpBubble from '../../components/HelpBubble';
import Switch from '../../components/Switch';

const AddChecklistManagementOption = ({
  onUpdate,
}) => {
  const [checklist, setChecklist] = useState(null);

  const updateSetting = (key, value) => {
    setChecklist(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <div className={css.AddChecklistManagementOption}>
      <p className={css.AddChecklistManagementOption__subheading}>
        <Translation value="proof-manage.button.add-checklist.message" />
      </p>
      <ChecklistTemplateDropdown
        onSelect={(checklistTemplate) => {
          setChecklist(() => {
            if (!checklistTemplate) {
              return null;
            }
            return {
              templateId: checklistTemplate.id,
              name: checklistTemplate.name,
              shouldOpenOnProofLoad: checklistTemplate.defaultShouldOpenOnProofLoad,
              isRequiredForFinalApproval: checklistTemplate.defaultIsRequiredForFinalApproval,
            };
          });
        }}
        currentChecklist={checklist}
      />
      {checklist && (
        <div className={css.AddChecklistManagementOption__settings}>
          <FormControl
            compact
            label={
              <div className={css.AddChecklistManagementOption__settings__label}>
                <Translation value="proof-manage.checklist.required-for-final-approval" />
                <HelpBubble
                  size="small"
                  message={<Translation value="proof-manage.checklist.required-for-final-approval.help" />}
                />
              </div>
            }
          >
            <Switch
              value={checklist.isRequiredForFinalApproval}
              onChange={value => updateSetting('isRequiredForFinalApproval', value)}
            />
          </FormControl>

          <FormControl
            compact
            label={<Translation value="proof-manage.checklist.open-on-proof-load.label" />}
          >
            <Switch
              value={checklist.shouldOpenOnProofLoad}
              onChange={value => updateSetting('shouldOpenOnProofLoad', value)}
            />
          </FormControl>
          <div className={css.AddChecklistManagementOption__preview}>
            <ChecklistTemplatePreview checklistTemplateId={checklist.templateId} />
          </div>

        </div>
      )}
      <Button
        className={css.AddChecklistManagementOption__button}
        onClick={() => onUpdate(checklist)}
        label={<Translation value="proof-manage.button.add-checklist" />}
      />
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  AddChecklistManagementOption.propTypes = {
    onUpdate: PropTypes.func.isRequired,
  };
}

export default AddChecklistManagementOption;
