/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import css from './CollectionCard.scss';
import ProofThumbnailNavigator from '../ProofThumbnailNavigator';

const CollectionCard = ({
  title,
  prevProofThumbnail,
  currentProofThumbnail,
  nextProofThumbnail,
  onPreviousProof,
  onNextProof,
  currentProofPosition,
  amountOfProofs,
}) => (
  <div className={css.CollectionCard}>
    <div className={css.CollectionCard__title}>
      {title}
      <div className={css.CollectionCard__title__subtitle}>
        <Translation
          value="collection-card.subtitle"
          params={{
            current: currentProofPosition,
            amount: amountOfProofs,
          }}
        />
      </div>
    </div>
    <div className={css.CollectionCard__proofs}>
      <ProofThumbnailNavigator
        prevProofThumbnail={prevProofThumbnail}
        currentProofThumbnail={currentProofThumbnail}
        nextProofThumbnail={nextProofThumbnail}
        onPreviousProof={onPreviousProof}
        onNextProof={onNextProof}
      />
    </div>
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  CollectionCard.propTypes = {
    title: PropTypes.string.isRequired,
    prevProofThumbnail: PropTypes.string,
    currentProofThumbnail: PropTypes.string,
    nextProofThumbnail: PropTypes.string,
    onPreviousProof: PropTypes.func.isRequired,
    onNextProof: PropTypes.func.isRequired,
    currentProofPosition: PropTypes.number.isRequired,
    amountOfProofs: PropTypes.number.isRequired,
  };
}

export default CollectionCard;
