import * as PropTypes from 'prop-types';
import { useKeepVirtualListItemMounted } from '../hooks/useKeepVirtualListItemMounted';

export function KeepVirtualListItemMounted({ itemKey }) {
  useKeepVirtualListItemMounted(true, itemKey);
  return null;
}

if (process.env.NODE_ENV !== 'production') {
  KeepVirtualListItemMounted.propTypes = {
    // Usually this prop can be omitted because the useKeyVirtualListItemMounted hook will use context to determine the
    // current virtual item's key. This prop should really only ever be provided if you're wanting to keep a _different_
    // virtual item mounted.
    itemKey: PropTypes.string,
  };
}
