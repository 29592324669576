/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {Children} from 'react';

function NeverEmpty({children}) {
  if (Children.only(children) && !Children.count(Children.toArray(children.props.children.filter(Boolean)))) {
    return null;
  }
  return children;
}

export default NeverEmpty;
