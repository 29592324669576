/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect } from 'react';
import { sdk } from '../../util/sdk';
import requestProofSetup from '../../components/ProofSetup/utils/requestProofSetup';

export default function ProofSetupByProofId({ initialRouteParams }) {
  useEffect(() => {
    const { $location } = window.__pageproof_bridge__;

    sdk.proofs.load(initialRouteParams.proofId)
      .then((proof) => {
        const isAwaitingEmail = proof.source === 'email' && proof.fileStatus === 'Created';

        requestProofSetup({
          parameters: {
            updateProof: {
              id: proof.id,
            },
          },
          initialize: {
            proof: {
              id: proof.id,
              name: proof.name,
              tags: proof.tags,
              reference: proof.reference,
              integrationReferences: proof.integrationReferences,
              messageToReviewers: proof.messageToReviewers,
              dueDate: proof.dueDate,
              canDownload: proof.canDownload,
              isPublic: proof.isPublic,
              canBePublic: proof.canBePublic,
              commentVisibility: proof.commentVisibility,
              canAddChecklist: proof.canAddChecklist,
              availableWorkflowRoles: proof.availableWorkflowRoles,
              canHaveCommentVisibility: proof.canHaveCommentVisibility,
              isAwaitingEmail: isAwaitingEmail || undefined,
              file: proof.fileId
                ? {
                  id: proof.fileId,
                  name: proof.name + '.' + proof.fileExtension,
                  size: proof.fileSize,
                  status: 'processing',
                  type: isAwaitingEmail ? 'email' : 'local',
                  icon: isAwaitingEmail ? window.__pageproof_quark__.proofSetup.addFile.generateEmailIconForProof() : undefined,
                }
                : undefined,
            },
            owners: proof.owners.map(user => user.email),
            checklist: proof.checklist,
            workflow: proof.workflowId
              ? { id: proof.workflowId }
              : undefined,
          },
        }, () => {
          $location.url('/create');
        });
      });
  }, []);

  return null;
}
