/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import * as PageProof from '@pageproof/sdk';

// This really should be handled within the editor, so we don't need to parse & serialize the comment structure every time.
// See https://draftjs.org/docs/api-reference-modifier#inserttext

function setLabel(value, label) {
  const {tokens} = window.generalfunctions_parseCommentText(value);
  if (tokens && tokens.blocks.length) {
    const block = tokens.blocks[0];

    // Store the original value so we can use it at the end to fix the index of entities/styles.
    const originalValue = block.value;
    let modifiedValue = originalValue;

    if (modifiedValue.match(/(\w+):/i)) {
      const index = modifiedValue.indexOf(':');
      if (label) {
        // Replacing an existing label
        modifiedValue = label + modifiedValue.substring(index);
      } else {
        // Remove an existing label
        modifiedValue = modifiedValue.substring(index + 1); // including the colon
        modifiedValue = modifiedValue.replace(/^\s*/, ''); // trim off any whitespace left over at the beginning
      }
    } else if (label) {
      // Adding a new label
      modifiedValue = label + ': ' + modifiedValue;
    } else {
      // Removing non-existent label
    }
    block.value = modifiedValue;

    // Calculate how many characters we've offset the block by, and add/remove that number from each entity or style
    // so that the entity and styles aren't applied to the incorrect segment of text.
    const indexModifier = modifiedValue.length - originalValue.length;
    [...block.entities, ...block.styles].forEach((item) => {
      item.index += indexModifier; // eslint-disable-line
    });
  }
  return PageProof.util.comments.serialize(tokens);
}

export default setLabel;
