/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import style from './Mention.scss';

function Mention({
  id,
  label,
  className,
  ...props,
}) {
  return (
    <span
      {...props}
      className={classname(className, style.Mention, style[id])}
    >
      {label}
    </span>
  );
}

export default Mention;
