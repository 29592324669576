/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-env browser */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Portal from '../Portal';
import css from './Walkthrough.scss';
import { Translation } from '../Text';
import WalkthroughStep from '../WalkthroughStep';
import Tooltip from '../Tooltip';
import useWatchRect from '../../hooks/useWatchRect';

const getFirstVisibleElementByHook = (hook) => {
  const hookedElements = document.querySelectorAll(`[data-walkthrough-hook="${hook}"]`);

  return [...hookedElements].find((hookedElement) => {
    const { width, height } = hookedElement.getBoundingClientRect();
    return width && height;
  });
};

const getElementForCurrentSteps = (steps) => {
  let step;
  let element;

  steps.forEach((st) => {
    if (step) {
      return;
    }

    let el;
    st.hooks.forEach((hook) => {
      if (el) {
        return;
      }

      el = getFirstVisibleElementByHook(hook);
    });

    if (el) {
      step = st;
      element = el;
    }
  });

  return [element, step];
};

const areAllWalkthroughStepsVisible = ({ steps }) => steps.length >= 3 && steps.every(stepSteps => getElementForCurrentSteps(stepSteps)[0]);

const getAdditionalPaddingValue = (step, key) => (typeof step.additionalPadding === 'object' ? step.additionalPadding[key] : step.additionalPadding) || 0;

const getAdditionalPaddingForStep = step => ({
  top: getAdditionalPaddingValue(step, 'top'),
  right: getAdditionalPaddingValue(step, 'right'),
  bottom: getAdditionalPaddingValue(step, 'bottom'),
  left: getAdditionalPaddingValue(step, 'left'),
});

const getElementHighlighterPositionStyle = (element, step) => {
  const additionalPadding = getAdditionalPaddingForStep(step);
  const pos = element.getBoundingClientRect();
  return {
    top: `${pos.top - additionalPadding.top}px`,
    left: `${pos.left - additionalPadding.left}px`,
    height: `${pos.height + additionalPadding.top + additionalPadding.bottom}px`,
    width: `${pos.width + additionalPadding.left + additionalPadding.right}px`,
  };
};

export const Walkthrough = ({ currentWalkthrough, completeWalkthrough }) => {
  const [elementHighlighterStyle, setElementHighlighterStyle] = useState();
  const [walkthroughPosition, setWalkthroughPosition] = useState(0);
  const [currentStep, setCurrentStep] = useState();

  const highlightedElementRef = useRef();
  const highlightedElementRect = useWatchRect(highlightedElementRef);
  useEffect(() => {
    if (!currentStep) {
      return;
    }

    setElementHighlighterStyle(style => ({
      ...style,
      ...getElementHighlighterPositionStyle(highlightedElementRef.current, currentStep),
    }));
  }, [highlightedElementRect]);

  const showProgressBar = useMemo(() => areAllWalkthroughStepsVisible(currentWalkthrough), []);

  const updateHighlightedElement = (element, step) => {
    highlightedElementRef.current = element;

    const style = getElementHighlighterPositionStyle(element, step);

    if (step.styles) {
      Object.assign(style, step.styles);
    }

    if (!style.borderRadius) {
      style.borderRadius = getComputedStyle(element).borderRadius;
    }

    setElementHighlighterStyle(style);
  };

  useEffect(() => {
    setElementHighlighterStyle();

    if (currentWalkthrough.steps.length === walkthroughPosition) {
      completeWalkthrough();
      return;
    }

    const [element, step] = getElementForCurrentSteps(currentWalkthrough.steps[walkthroughPosition]);

    if (!element) {
      setWalkthroughPosition(walkthroughPosition + 1);
      return;
    }

    setCurrentStep(step);
    updateHighlightedElement(element, step);
  }, [walkthroughPosition]);

  const onCoverClick = () => {
    if (currentWalkthrough.steps.length === walkthroughPosition + 1) {
      completeWalkthrough();
    }
  };

  const buttonLabel = useMemo(() => {
    if (currentWalkthrough.steps.length === 1) {
      return <Translation value="button.ok" />;
    }

    if (walkthroughPosition < currentWalkthrough.steps.length - 1) {
      return <Translation value="button.next" />;
    }

    return <Translation value="button.done" />;
  }, [currentWalkthrough, walkthroughPosition]);

  if (walkthroughPosition < 0) {
    return null;
  }

  return (
    <Portal>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={onCoverClick} // For users that don't think of clicking the 'done' button to dismiss the walkthrough
        className={css.Walkthrough__cover}
      />
      {elementHighlighterStyle && (
        <Tooltip
          // Having a key here forces a re-render of the tooltip for animation / positioning reasons
          key={`walkthrough-step-${walkthroughPosition}`}
          delay={0}
          title={(
            <WalkthroughStep
              {...currentStep}
              progressBarProps={showProgressBar && {
                currentStep: walkthroughPosition + 1,
                numberOfSteps: currentWalkthrough.steps.length,
                onClickStep: position => setWalkthroughPosition(position - 1),
              }}
              buttonProps={{
                label: buttonLabel,
                onClick: () => setWalkthroughPosition(walkthroughPosition + 1),
              }}
            />
          )}
          visible
          arrow
          offset={20}
          variant="light"
          {...currentStep.tooltipDirections}
        >
          {currentStep.href
            ? (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className={css.Walkthrough__elementHighlighter}
                href={currentStep.href}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                style={{ ...elementHighlighterStyle, pointerEvents: 'all' }}
              />
            )
            : (
              <div
                className={css.Walkthrough__elementHighlighter}
                style={elementHighlighterStyle}
              />
            )
          }
        </Tooltip>
      )}
    </Portal>
  );
};

export default Walkthrough;
