/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { Enum } from '@pageproof/sdk';
import { nudgeAll, nudgeUser } from './nudge';
import DecisionSummary from '../components/Decisions/DecisionSummary';
import { Translation } from '../components/Text';

const THUMBS_UP_USERS = 'thumbsUpUsers';
const APPROVED_WITH_CHANGES_USERS = 'approvedWithChangesUsers';
const THUMBS_DOWN_USERS = 'thumbsDownUsers';
const NO_DECISION_USERS = 'noDecisionUsers';
const MINUTE_IN_MS = 1000 * 60;
const { APPROVED, APPROVED_WITH_CHANGES, SEND_CHANGES, NO_DECISION } = Enum.Decision;

export function isDateWithinNudgeCooldownPeriod(date) {
  if (date) {
    const nudgeCooldownPeriodInMinutes = window.__pageproof_bridge__.UserService.GetConfigSetting('NudgeWaitMinutes');
    return Date.now() < (+date + (nudgeCooldownPeriodInMinutes * MINUTE_IN_MS));
  } else {
    return false;
  }
}

function getDecisionSummaryKey(userDecision) {
  switch (userDecision) {
  case APPROVED:
    return THUMBS_UP_USERS;
  case APPROVED_WITH_CHANGES:
    return APPROVED_WITH_CHANGES_USERS;
  case SEND_CHANGES:
    return THUMBS_DOWN_USERS;
  case NO_DECISION:
  default:
    return NO_DECISION_USERS;
  }
}

export function getDecisionSummaryProps({
  workflowId,
  decisionSummary,
  availableActions = [],
  currentUserId,
  onActionCompleted,
}) {
  const allowNudgeUser = availableActions.indexOf('nudge-user') !== -1;
  const allowNudgeAll = availableActions.indexOf('nudge-all') !== -1;

  const decisionsUserData = {
    [THUMBS_UP_USERS]: [],
    [APPROVED_WITH_CHANGES_USERS]: [],
    [THUMBS_DOWN_USERS]: [],
    [NO_DECISION_USERS]: [],
  };

  const canUserBeNudged = (user, decision) => (
    decision === NO_DECISION && // users who haven't made a decision
    user.id !== currentUserId && // as long as it's not themselves
    user.role !== 'VIEW_ONLY' && // and they're not a view only reviewer
    !isDateWithinNudgeCooldownPeriod(user.nudgedDate || user.nudgedAt) // and they've not recently been nudged
  );

  decisionSummary.forEach(({ decision, users }) => {
    let usersThatCanBeNudgedCount = 0;
    decisionsUserData[getDecisionSummaryKey(decision)] = users.filter(user => decision !== NO_DECISION || user.role !== 'VIEW_ONLY').map((user) => {
      const record = {
        name: user.name || user.email,
        id: user.id,
      };

      if (isDateWithinNudgeCooldownPeriod(user.nudgedDate || user.nudgedAt)) {
        record.name = (
          <Fragment>
            {user.name || user.email}
            <DecisionSummary.Nudged />
          </Fragment>
        );
      } else if (allowNudgeUser && canUserBeNudged(user, decision)) {
        usersThatCanBeNudgedCount += 1;
        record.actions = [
          {
            name: <Translation value="decision-summary.action.nudge" />,
            onClick: () => {
              nudgeUser(workflowId, user.id)
                .then(() => {
                  users.forEach((_user) => {
                    if (user.id === _user.id) {
                      // Required so that the component can re-render with the knowledge of the user having just been nudged.
                      // eslint-disable-next-line no-param-reassign, no-multi-assign
                      user.nudgedDate = user.nudgedAt = new Date();
                    }
                  });
                  onActionCompleted('nudge-user', { userId: user.id });
                });
            },
          },
        ];
      }

      return record;
    });

    if (usersThatCanBeNudgedCount > 1 && allowNudgeAll) {
      decisionsUserData.noDecisionGroup = {
        actions: [{
          name: <Translation value="decision-summary.action.nudge-all" />,
          onClick: () => {
            nudgeAll(workflowId)
              .then(() => {
                users.forEach((user) => {
                  if (canUserBeNudged(user, decision)) {
                    // Required so that the component can re-render with the knowledge of the user having just been nudged.
                    // eslint-disable-next-line no-param-reassign, no-multi-assign
                    user.nudgedDate = user.nudgedAt = new Date();
                  }
                });
                onActionCompleted('nudge-all');
              });
          },
        }],
      };
    }
  });

  return decisionsUserData;
}

export function hasUsersThatCanBeNudged(decisionSummary, currentUserId) {
  const { users } = decisionSummary.find(({ decision }) => decision === NO_DECISION) || {};
  return !!users && users.filter(({ id, nudgedDate, nudgedAt }) => id !== currentUserId && !isDateWithinNudgeCooldownPeriod(nudgedDate || nudgedAt)).length > 0;
}
