/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Reminder from '../../components/Reminder';
import getSDKInstance from '../../util/sdk';

class ReminderContainer extends Component {
  constructor(props) {
    super(props);
    this.client = getSDKInstance();
    this.state = {
      reminders: (props.proof && props.proof.reminders) || [],
    };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.proof && !this.props.proof) || (nextProps.proof !== this.props.proof)) {
      this.setState({
        reminders: nextProps.proof.reminders || [],
      });
    }
  }

  addReminder = (hoursOption) => {
    const { reminders } = this.state;
    const chosenHoursOption = -(hoursOption * 60 * 60 * 1000);
    const proofId = (this.props.proof && this.props.proof.id) || null;

    const data = {
      action: 'nudge_all',
      relativeTo: 'due_date',
      relativeDate: chosenHoursOption,
      id: `ID-${hoursOption}`,
    };

    if (proofId) {
      this.client.proofs.reminders.add(proofId, data)
        .then((response) => {
          this.updateReminderState([...reminders, response[0]]);
        });
    } else {
      this.updateReminderState([...reminders, data]);
    }
  }

  updateReminderState = (reminders) => {
    this.setState({
      reminders,
    }, () => {
      if (this.props.inCreateProofProccess) {
        this.props.passReminderValue(this.state.reminders);
      }
    });
  }

  removeReminder = (reminderId) => {
    const oldReminders = this.state.reminders;
    const reminders = oldReminders.filter(reminder => reminder.id !== reminderId);

    if (this.props.inCreateProofProccess) {
      this.updateReminderState(reminders);
    } else {
      this.client.proofs.reminders.delete(reminderId)
        .then(() => this.updateReminderState(reminders));
    }
  }

  removeAllReminders = () => {
    const reminderIds = this.state.reminders.map(reminder => reminder.id);

    if (this.props.inCreateProofProccess) {
      this.updateReminderState([]);
    } else {
      this.client.proofs.reminders.delete(reminderIds)
        .then(() => this.updateReminderState([]));
    }
  }

  render() {
    const {
      isOwner,
      inCreateProofProccess,
      timeLeft,
    } = this.props;
    const { reminders } = this.state;

    return (
      <Reminder
        {...this.props}
        canShow={reminders.length || isOwner}
        reminders={reminders}
        canEdit={isOwner}
        canDelete={isOwner}
        onAdd={this.addReminder}
        onRemoveAll={this.removeAllReminders}
        onRemove={this.removeReminder}
        inCreateProofProccess={inCreateProofProccess}
        timeLeft={timeLeft}
      />
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  ReminderContainer.propTypes = {
    isOwner: PropTypes.bool,
    proof: PropTypes.objectOf(PropTypes.any),
    passReminderValue: PropTypes.func,
    inCreateProofProccess: PropTypes.bool,
    timeLeft: PropTypes.number,
  };
}

export default ReminderContainer;
