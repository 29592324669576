/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import css from './DropdownInput.scss';
import Dropdown from '../../../components/Dropdown/Dropdown';
import DropdownOption from '../../../components/Dropdown/Option';
import DropdownOptionLabel from '../../../components/Dropdown/OptionLabel';
import { EditWarningWrapper } from '../EditWarningWrapper';
import { Translation } from '../../../components/Text';

export function DropdownInput({ option, isUpdating, onConfigurationOptionInput }) {
  const selectedOptionLabel = option.value && option.value.label;

  return (
    <EditWarningWrapper
      value={selectedOptionLabel}
      warningMessage={selectedOptionLabel && option.warningMessage}
    >
      <Dropdown
        selected={option.value && option.value.id}
        variant="light"
        classNames={{
          dropdown: classname(css.Dropdown, {
            [css['Dropdown--disabled']]: isUpdating,
          }),
        }}
        placeholder={<DropdownOption label={option.placeholder || <Translation value="domain-admin.integrations.dropdown.placeholder" />} />}
      >
        {option.options.map(dropdownOption => (
          <DropdownOption
            key={dropdownOption.id}
            value={dropdownOption.id}
            onClick={() => onConfigurationOptionInput(option.key, dropdownOption)}
            label={<DropdownOptionLabel title={dropdownOption.label} />}
          />
        ))}
      </Dropdown>
    </EditWarningWrapper>
  );
}

if (process.env.NODE_ENV !== 'production') {
  DropdownInput.propTypes = {
    onConfigurationOptionInput: PropTypes.func.isRequired,
    option: PropTypes.objectOf(PropTypes.any).isRequired,
    isUpdating: PropTypes.bool,
  };
}

export default DropdownInput;
