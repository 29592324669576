/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Fragment } from 'react';
import Translation from '../../Text/Translation';
import { hasValue } from '../Editor/serialization';
import Comment from '.';
import { KeepVirtualListItemMounted } from '../../../containers/CommentsPane/components/KeepVirtualListItemMounted';

class CommentContainer extends Component {
  state = {
    isEditing: false,
    showMetadata: false,
    editingValue: null,
  };

  onEdit = (isEditing) => {
    this.setState(() => ({
      isEditing,
      editingValue: this.props.value,
    }));
  }

  onChange = (value) => {
    this.setState(() => ({
      editingValue: value,
    }));
  }

  onSave = (value) => {
    this.setState({
      isEditing: false,
    });
    if (this.props.value !== value) {
      this.props.onSave(value);
    }
  }

  onToggleMetadata = () => {
    this.setState(({ showMetadata }) => ({
      showMetadata: !showMetadata,
    }));
  }

  render() {
    const value = this.state.isEditing
      ? this.state.editingValue
      : this.props.value;
    return (
      <Fragment>
        {this.state.isEditing && <KeepVirtualListItemMounted />}
        <Comment
          {...this.props}
          {...this.state}
          value={value}
          placeholder={
            <Translation value="comment-create.placeholder" />
          }
          hasUnsavedChanges={
            this.state.isEditing &&
            this.state.editingValue !== this.props.value
          }
          canSave={hasValue(value)}
          onEdit={this.onEdit}
          onChange={this.onChange}
          onSave={this.onSave}
          onToggleMetadata={this.onToggleMetadata}
        />
      </Fragment>
    );
  }
}

export default CommentContainer;
