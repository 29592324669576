/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import Tabs from './Tabs';
import Tab from './Tab';

export {
  Tabs,
  Tab,
};
