/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import env from '../../../../shared/env';

const forbiddenTrailingCodepoints = [
  'fe0f',
];

/* eslint-disable */
function toCodePoint(unicodeSurrogates, sep) {
  var
    r = [],
    c = 0,
    p = 0,
    i = 0;
  while (i < unicodeSurrogates.length) {
    c = unicodeSurrogates.charCodeAt(i++);
    if (p) {
      r.push((0x10000 + ((p - 0xD800) << 10) + (c - 0xDC00)).toString(16));
      p = 0;
    } else if (0xD800 <= c && c <= 0xDBFF) {
      p = c;
    } else {
      r.push(c.toString(16));
    }
  }
  if (forbiddenTrailingCodepoints.includes(r[r.length - 1])) {
    r.pop();
  }
  return r.join(sep || '-');
}
/* eslint-enable */

const cdnUrl = env.emoji_cdn_url || null;

const customCodePoints = {
  '🏳️‍🌈': '1f3f3-1f308',
  '❤️': '2764',
  '❄️': '2744',
};

const customUrls = {
  'maori-language-week-2019': 'https://app.pageproof.com/emoji/custom/200x200/maori-language-week-2019.gif',
};

class Emoji extends Component {
  state = {
    fallback: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.text !== this.props.text) {
      this.setState({fallback: false});
    }
  }

  render() {
    const {text, size} = this.props;
    if (cdnUrl && !this.state.fallback) {
      const customUrl = customUrls[text];
      const codePoint = customCodePoints[text] || toCodePoint(text);
      return (
        <img
          src={customUrl || cdnUrl.replace('{code-point}', codePoint)}
          style={{
            height: '1em',
            width: '1em',
            margin: '0 .05em 0 .1em',
            verticalAlign: '-0.1em',
            fontSize: size,
          }}
          draggable="false"
          alt={text}
          className="emoji"
          onError={() => this.setState({
            fallback: true,
          })}
        />
      );
    }
    return (
      <span
        style={{
          fontSize: size,
          /* eslint-disable-next-line quotes */
          fontFamily: `'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
        }}
      >
        {text}
      </span>
    );
  }
}

export default Emoji;
