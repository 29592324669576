/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import Thumbnail from './Thumbnail';
import UserHeading from './UserHeading';
import ProofTile from './ProofTile';

export {
  Thumbnail,
  UserHeading,
  ProofTile,
};
