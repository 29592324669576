/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from './createIcon';

export default createIcon({
  displayName: 'Info',
  src: 'img/interface/info.svg',
  defaultSize: 24,
  activeColor: '#777',
  activeHoverColor: '#a1a1a1',
});
