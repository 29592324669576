import React, {Component, cloneElement} from 'react';
import {findDOMNode} from 'react-dom';
import classname from 'classname';
import {CSSTransitionGroup} from 'react-transition-group';
import css from './SideBySide.scss';

class SideBySide extends Component {
  state = {
    height: '',
    width: '',
    current: null,
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      const element = findDOMNode(this);
      const currentElement = element.children[0];
      this.setState({
        current: {
          height: currentElement.offsetHeight,
          width: currentElement.offsetWidth,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      const element = findDOMNode(this);
      const transitionDuration = this.props.transitionDuration;
      const enterElement = Array.from(element.children).filter((child) => { // eslint-disable-line
        return child.className.indexOf(css.Enter) !== -1;
      })[0];
      const previous = this.state.current;
      const current = {
        height: enterElement.offsetHeight,
        width: enterElement.offsetWidth,
      };
      const reset = {
        height: '',
        width: '',
      };
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(previous);
      setTimeout(() => this.setState(current));
      setTimeout(() => this.setState(reset), transitionDuration);
    }
  }

  render() {
    const {
      id,
      reverse,
      children,
      animateHeight,
      animateWidth,
      transitionDuration,
    } = this.props;
    const {
      height,
      width,
    } = this.state;
    return (
      <CSSTransitionGroup
        component="div"
        transitionName={{
          enter: css.Enter,
          enterActive: css['Enter--active'],
          leave: css.Leave,
          leaveActive: css['Leave--active'],
        }}
        transitionEnterTimeout={transitionDuration}
        transitionLeaveTimeout={transitionDuration}
        className={classname(
          css.SideBySide,
          css[reverse ? 'Back' : 'Forward']
        )}
        style={{
          transition: [
            (animateHeight ? `height ${transitionDuration}ms ease-in-out` : ''),
            (animateWidth ? `width ${transitionDuration}ms ease-in-out` : ''),
          ].join(', ') || undefined,
          height: animateHeight ? height : undefined,
          width: animateWidth ? width : undefined,
        }}
      >
        <div
          key={id}
          className={css.Inner}
        >
          {cloneElement(children)}
        </div>
      </CSSTransitionGroup>
    );
  }
}

SideBySide.defaultProps = {
  animateHeight: true,
  animateWidth: true,
  transitionDuration: 360,
};

export default SideBySide;
