/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './FloatingLabelDescription.scss';
import HelpBubble from '../../HelpBubble/HelpBubble';

const FloatingLabelDescription = ({ description, url }) => (
  <div className={css.FloatingLabelDescription}>
    <HelpBubble
      size="small"
      message={description}
      url={url}
    />
  </div>
);

export default FloatingLabelDescription;
