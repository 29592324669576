/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useI18n } from '../../hooks/useI18n';
import Translation from './Translation';

export default function useText() {
  const { translations } = useI18n();
  return (value, params) => Translation.text(value, params, translations);
}
