/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import React from 'react';
import css from './ValidationToast.scss';

const ValidationToast = ({
  message,
  onDismiss,
  shadow,
}) => (
  <div className={css.ValidationToast}>
    <button
      type="button"
      className={classname(css.ValidationToast__button, { [css[`ValidationToast__button--shadow-${shadow}`]]: shadow })}
      onClick={() => {
        onDismiss();
      }}
    >
      <InlineSVG
        src="/img/interface/menu-close-icon.svg"
        className={css.ValidationToast__closeIcon}
      />
      {message}
    </button>
  </div>
);

export default ValidationToast;
