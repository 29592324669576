/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useLocalStorage } from '../components/LocalStorage';

const useSkippedDialog = (dialogType) => {
  const { UserService: { userData } } = window.__pageproof_bridge__;
  const [dialogs, setDialogs] = useLocalStorage(`pageproof.app.proof.${userData.userId}.dialogs`, []);

  const shouldBeSkipped = dialogs.includes(dialogType);

  const setSkipped = (skip) => {
    if (skip) {
      const filteredDialogs = dialogs.filter(dialog => dialog !== dialogType);
      setDialogs(filteredDialogs);
    } else {
      setDialogs([...dialogs, dialogType]);
    }
  };

  return {
    shouldBeSkipped,
    setSkipped,
  };
};

export default useSkippedDialog;
