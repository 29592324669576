/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import { useCommentSpacing } from '../CommentSpacing';
import css from './Indent.scss';

function Indent({ children }) {
  const spacing = useCommentSpacing();

  return (
    <div className={classname(css.Indent, css[`Indent--${spacing}-spacing`])}>
      {children}
    </div>
  );
}

export default Indent;
