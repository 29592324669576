/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './ProgressBar.scss';

const ProgressBar = ({
  progress,
}) => (
  <div className={css.ProgressBar}>
    <div className={classname('app__loader', css.ProgressBar__loader)}>
      <div className="app__loader__progress">
        <div
          className="app__loader__progress__bar"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  </div>
);

export default ProgressBar;
