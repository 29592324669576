/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import Groups from './Groups';
import css from './AddressBook.scss';

const AddressBook = ({ emails, filteredEmails, removeEmail }) => {
  if (filteredEmails.length < 1) {
    return (
      <p className={css.AddressBook}>
        {emails.length < 1
          ? <Translation value="profile.address-book.no-contacts" />
          : <Translation value="profile.address-book.no-contacts.search" />
        }
      </p>
    );
  }

  return <Groups
    emails={filteredEmails}
    removeEmail={removeEmail}
  />;
};

if (process.env.NODE_ENV !== 'production') {
  AddressBook.propTypes = {
    emails: PropTypes.arrayOf(PropTypes.string).isRequired,
    filteredEmails: PropTypes.arrayOf(PropTypes.string),
    removeEmail: PropTypes.func.isRequired,
  };
}

export default AddressBook;
