/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const accountsStorageKey = 'pageproof.accounts';
const userDataStorageKey = 'pageproof.app.userData';
const proofSetupKey = 'pageproof.app.proof-setup';

// private
function getCurrentUserData() {
  try {
    const currentUserData = window.localStorage.getItem(userDataStorageKey);
    return JSON.parse(currentUserData);
  } catch (err) {
    return null;
  }
}

// private
function getCurrentProofSetup() {
  try {
    const currentProofSetup = window.localStorage.getItem(proofSetupKey);
    return JSON.parse(currentProofSetup);
  } catch (err) {
    return null;
  }
}

function getAccounts() {
  let accounts = [];

  try {
    const accountsJson = window.localStorage.getItem(accountsStorageKey);
    if (accountsJson) {
      accounts = JSON.parse(accountsJson);
    }
  } catch (err) {
    // Ignore.
  }

  // Ensure that the current user data is always in the accounts list.
  const currentUserData = getCurrentUserData();
  if (currentUserData) {
    accounts = [
      {
        userId: currentUserData.userId,
        userData: currentUserData,
        proofSetup: getCurrentProofSetup(),
      },
      ...accounts.filter(account => account.userId !== currentUserData.userId),
    ];
  }

  return accounts;
}

// private
function setAccounts(accounts) {
  try {
    window.localStorage.setItem(accountsStorageKey, JSON.stringify(accounts));
    return true;
  } catch (err) {
    return false;
  }
}

// private
function removeAccountFromAccountsList(userId) {
  const accounts = getAccounts();
  const updatedAccounts = accounts.filter(account => account.userId !== userId);
  setAccounts(updatedAccounts);
}

function switchAccount(userId) {
  const accounts = getAccounts();

  const accountByUserId = accounts.find(account => account.userId === userId);

  if (!accountByUserId) {
    throw new Error('Failed to switch accounts - user not found in local storage.');
  }

  const currentUserData = getCurrentUserData();

  if (currentUserData && currentUserData.userId === userId) {
    return;
  }

  if (currentUserData) {
    // Ensure the current user data is kept in the accounts list so it can be switched back to later.
    setAccounts([
      ...accounts.filter(account => account.userId !== currentUserData.userId),
      {
        userId: currentUserData.userId,
        userData: currentUserData,
        proofSetup: getCurrentProofSetup(),
      },
    ]);
  }

  const { userData, proofSetup } = accountByUserId;

  try {
    window.localStorage.removeItem(userDataStorageKey);
    window.localStorage.removeItem(proofSetupKey);
    window.$(window).trigger(window.$.Event('userlogout.pageproof'));

    window.localStorage.setItem(userDataStorageKey, JSON.stringify(userData));
    removeAccountFromAccountsList(userData.userId);
    window.$(window).trigger(window.$.Event('userlogin.pageproof', {
      user: userData,
    }));
    if (proofSetup) {
      window.localStorage.setItem(proofSetupKey, JSON.stringify(proofSetup));
    }
  } finally {
    // Ensure the page is reloaded so all the code that has kept a hold of the old user data is updated.
    window.location.reload();
  }
}

function addAccount() {
  setAccounts(getAccounts());

  const currentUserData = getCurrentUserData();

  try {
    if (currentUserData) {
      window.localStorage.removeItem(userDataStorageKey);
      window.localStorage.removeItem(proofSetupKey);
      window.$(window).trigger(window.$.Event('userlogout.pageproof'));
    }
  } finally {
    window.location.href = '/login';
  }
}

function onSessionSaved(userId) {
  removeAccountFromAccountsList(userId);
}

export {
  getAccounts,
  switchAccount,
  addAccount,
  onSessionSaved,
};
