const animals = [
  {
    name: 'Bear',
    url: '/img/icons/animals/1/icons8-bear-100.svg',
  },
  {
    name: 'Bee',
    url: '/img/icons/animals/1/icons8-bee-100.svg',
  },
  {
    name: 'Bird',
    url: '/img/icons/animals/1/icons8-bird-100.svg',
  },
  {
    name: 'Black jaguar',
    url: '/img/icons/animals/1/icons8-black-jaguar-100.svg',
  },
  {
    name: 'Bull',
    url: '/img/icons/animals/1/icons8-bull-100.svg',
  },
  {
    name: 'Butterfly',
    url: '/img/icons/animals/1/icons8-butterfly-100.svg',
  },
  {
    name: 'Cat',
    url: '/img/icons/animals/1/icons8-cat-100.svg',
  },
  {
    name: 'Chicken',
    url: '/img/icons/animals/1/icons8-chicken-100.svg',
  },
  {
    name: 'Cow',
    url: '/img/icons/animals/1/icons8-cow-100.svg',
  },
  {
    name: 'Deer',
    url: '/img/icons/animals/1/icons8-deer-100.svg',
  },
  {
    name: 'Dog',
    url: '/img/icons/animals/1/icons8-dog-100.svg',
  },
  {
    name: 'Elephant',
    url: '/img/icons/animals/1/icons8-elephant-100.svg',
  },
  {
    name: 'Fish',
    url: '/img/icons/animals/1/icons8-fish-100.svg',
  },
  {
    name: 'Fox',
    url: '/img/icons/animals/1/icons8-fox-100.svg',
  },
  {
    name: 'Frog',
    url: '/img/icons/animals/1/icons8-frog-100.svg',
  },
  {
    name: 'Giraffe',
    url: '/img/icons/animals/1/icons8-giraffe-100.svg',
  },
  {
    name: 'Gorilla',
    url: '/img/icons/animals/1/icons8-gorilla-100.svg',
  },
  {
    name: 'Hamster',
    url: '/img/icons/animals/1/icons8-hamster-100.svg',
  },
  {
    name: 'Hedgehog',
    url: '/img/icons/animals/1/icons8-hedgehog-100.svg',
  },
  {
    name: 'Hippopotamus',
    url: '/img/icons/animals/1/icons8-hippopotamus-100.svg',
  },
  {
    name: 'Horse',
    url: '/img/icons/animals/1/icons8-horse-100.svg',
  },
  {
    name: 'Ladybird',
    url: '/img/icons/animals/1/icons8-insect-100.svg',
  },
  {
    name: 'Jellyfish',
    url: '/img/icons/animals/1/icons8-jellyfish-100.svg',
  },
  {
    name: 'Lamb',
    url: '/img/icons/animals/1/icons8-lamb-100.svg',
  },
  {
    name: 'Lion',
    url: '/img/icons/animals/1/icons8-lion-100.svg',
  },
  {
    name: 'Lizard',
    url: '/img/icons/animals/1/icons8-lizard-100.svg',
  },
  {
    name: 'Llama',
    url: '/img/icons/animals/1/icons8-llama-100.svg',
  },
  {
    name: 'Mouse',
    url: '/img/icons/animals/1/icons8-mouse-animal-100.svg',
  },
  {
    name: 'Octopus',
    url: '/img/icons/animals/1/icons8-octopus-100.svg',
  },
  {
    name: 'Orangutan',
    url: '/img/icons/animals/1/icons8-orangutan-100.svg',
  },
  {
    name: 'Owl',
    url: '/img/icons/animals/1/icons8-owl-100.svg',
  },
  {
    name: 'Panda',
    url: '/img/icons/animals/1/icons8-panda-100.svg',
  },
  {
    name: 'Pig',
    url: '/img/icons/animals/1/icons8-pig-100.svg',
  },
  {
    name: 'Polar bear',
    url: '/img/icons/animals/1/icons8-polar-bear-100.svg',
  },
  {
    name: 'Rabbit',
    url: '/img/icons/animals/1/icons8-rabbit-100.svg',
  },
  {
    name: 'Rhinoceros',
    url: '/img/icons/animals/1/icons8-rhinoceros-100.svg',
  },
  {
    name: 'Salamander',
    url: '/img/icons/animals/1/icons8-salamander-100.svg',
  },
  {
    name: 'Shark',
    url: '/img/icons/animals/1/icons8-shark-100.svg',
  },
  {
    name: 'Snail',
    url: '/img/icons/animals/1/icons8-snail-100.svg',
  },
  {
    name: 'Snake',
    url: '/img/icons/animals/1/icons8-snake-100.svg',
  },
  {
    name: 'Squirrel',
    url: '/img/icons/animals/1/icons8-squirrel-100.svg',
  },
  {
    name: 'Starfish',
    url: '/img/icons/animals/1/icons8-starfish-100.svg',
  },
  {
    name: 'Stork',
    url: '/img/icons/animals/1/icons8-stork-100.svg',
  },
  {
    name: 'Turtle',
    url: '/img/icons/animals/1/icons8-turtle-100.svg',
  },
  {
    name: 'Unicorn',
    url: '/img/icons/animals/1/icons8-unicorn-100.svg',
  },
  {
    name: 'Walrus',
    url: '/img/icons/animals/1/icons8-walrus-100.svg',
  },
  {
    name: 'Whale',
    url: '/img/icons/animals/1/icons8-whale-100.svg',
  },
  {
    name: 'White jaguar',
    url: '/img/icons/animals/1/icons8-white-jaguar-100.svg',
  },
  {
    name: 'Wolf',
    url: '/img/icons/animals/1/icons8-wolf-100.svg',
  },
  {
    name: 'Zebra',
    url: '/img/icons/animals/1/icons8-zebra-100.svg',
  },
];

const generateEmailIcon = () => {
  const animalIndex = Math.floor(Math.random() * animals.length);
  const animal = animals[animalIndex];

  return {
    name: animal.name,
    url: animal.url,
  };
};

export default generateEmailIcon;
