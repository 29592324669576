/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useMemo } from 'react';
import redraft, { createStylesRenderer } from 'redraft';
import classname from 'classname';
import { linkStrategy } from '../Editor/Decorators/linkify';
import { hashtagStrategy } from '../Editor/Decorators/hashtag';
import { emojiRegex, emojiStrategy } from '../Editor/Decorators/emoji';
import { toRaw } from '../Editor/serialization';
import Mention from '../Mention';
import Link from '../Link';
import Hashtag from '../Hashtag';
import { CommentTextContext } from '../../../hoc/Context';
import useDarkMode from '../../../hooks/useDarkMode';
import Emoji from '../../Emoji';
import css from './Text.scss';
import { jumbojiStrategy } from '../Editor/Decorators/jumboji';

const styleMap = {
  BOLD: {
    fontWeight: 'bold',
  },
  ITALIC: {
    fontStyle: 'italic',
  },
  UNDERLINE: {
    textDecoration: 'underline',
  },
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
  SUPERSCRIPT: {
    fontSize: '0.8em',
    verticalAlign: 'super',
  },
  SUBSCRIPT: {
    fontSize: '0.8em',
    verticalAlign: 'sub',
  },
};

// This is a wrapper callback for the inline styles
// the style object contains all the relevant styles from the styleMap
// it needs a key as redraft returns arrays not Components
const InlineWrapper = ({ key, style, children }) => (
  <span
    key={key}
    style={style}
  >
    {children}
  </span>
);

const createListRenderer = ListComponent => (children, { keys }) => (
  <ListComponent>
    {children.map((child, index) => <li key={keys[index]}>{child}</li>)}
  </ListComponent>
);

const blockRenderMap = {
  unstyled: children => (
    children.map(child => (
      <div>
        {child}
        <br />
      </div>
    ))
  ),
  'unordered-list-item': createListRenderer('ul'),
  'ordered-list-item': createListRenderer('ol'),
};

const entityMap = {
  mention: (children, data, { key }) => (
    <Mention
      key={key}
      label={children}
      id={typeof data.mention.get === 'function'
        ? data.mention.get('link')
        : data.mention.link
      }
    />
  ),
};

const decorators = [
  {
    strategy: linkStrategy,
    component: function component({ children, decoratedText }) {
      let url = decoratedText;
      if (url.indexOf('://') === -1) {
        url = 'https://' + url;
      }
      return (
        <Link url={url}>
          {children}
        </Link>
      );
    },
  },
  {
    strategy: hashtagStrategy,
    component: function component({ children, decoratedText, ...props }) {
      let hashtag = Number(decoratedText.slice(1, decoratedText.length));
      let extraText = '';
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(hashtag)) { // for IE decorated text is not correct.
        hashtag = Number(decoratedText.substr(decoratedText.lastIndexOf('#') + 1));
        children = ['#' + hashtag]; // eslint-disable-line
        extraText = decoratedText.substr(0, decoratedText.lastIndexOf('#'));
      }

      return (
        <Fragment>
          {extraText}
          <CommentTextContext.Consumer data-offset-key={props.offsetKey}>
            {({ onHashtagClick, onHashtagHover }) => (
              <Hashtag
                onClick={() => onHashtagClick(hashtag)}
                tooltip={() => (onHashtagHover && onHashtagHover(hashtag))}
              >
                {children}
              </Hashtag>
            )}
          </CommentTextContext.Consumer>
        </Fragment>
      );
    },
  },
  {
    strategy: jumbojiStrategy,
    component: function component(props) {
      const children = [];
      props.decoratedText.replace(emojiRegex, (emoji) => {
        children.push(
          <Emoji
            key={children.length}
            text={emoji}
            size={42}
          />
        );
      });
      return (
        <Fragment>
          {children}
        </Fragment>
      );
    },
  },
  {
    strategy: emojiStrategy,
    component: function component({ decoratedText }) {
      return <Emoji text={decoratedText} />;
    },
  },
];

const renderers = {
  styles: createStylesRenderer(InlineWrapper, styleMap),
  blocks: blockRenderMap,
  entities: entityMap,
  decorators,
};

function getShortenTokens(truncate, tokens) {
  const { lines, characters } = truncate;
  const shortenBlocks = [];
  let shortText = '';
  let i = 0;
  while (i < lines && shortText.length < characters) {
    const block = tokens.blocks[i];
    const index = shortText.length;
    if (block) {
      shortText = shortText + '' + block.value;
      const newBlock = block;
      if (shortText.length > characters) {
        newBlock.value = shortText.substr(index, characters) + '...'; // eslint-disable-line
      }
      shortenBlocks.push(newBlock);
    }
    i++; // eslint-disable-line
  }
  return {
    version: 2,
    blocks: shortenBlocks,
  };
}

const Text = ({
  value,
  onHashtagClick,
  onHashtagHover,
  truncate,
  isNotDarkModeStyle,
  isPageProofGenerated,
}) => {
  const [isDarkMode] = useDarkMode();
  const renderError = 'Failed to render comment.';

  const rendered = useMemo(() => {
    const { tokens } = window.generalfunctions_parseCommentText(value);
    const raw = toRaw(truncate ? getShortenTokens(truncate, tokens) : tokens);
    return redraft(raw, renderers);
  }, [value, truncate]);

  return useMemo(() => (
    <CommentTextContext.Provider value={{ onHashtagClick, onHashtagHover }}>
      <span
        className={classname(css.Text, {
          [css.Text__darkMode]: isDarkMode && !isNotDarkModeStyle,
          [css['Text--pageproof-generated']]: isPageProofGenerated,
        })}
      >
        {rendered || renderError}
      </span>
    </CommentTextContext.Provider>
  ), [value, isDarkMode]);
};

export default Text;
