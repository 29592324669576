/* Disabling eslint for indent due to bad switch rule */
/* eslint-disable indent */
import env from '../../../shared/env';

const getIntegrationFileUrl = (category, integrationKey, modifiersList = []) => {
  const baseUrl = `${env.api_url}integrations/static`;
  const modifiers = modifiersList.length ? `-${modifiersList.slice().sort().join('-')}` : '';

  switch (category) {
    case 'logo-horizontal':
      return `${baseUrl}/logos/horizontal/${integrationKey}${modifiers}.svg`;
    case 'logo-compact':
      return `${baseUrl}/logos/compact/${integrationKey}${modifiers}.svg`;
    case 'icon':
      return `${baseUrl}/icons/${integrationKey}${modifiers}.svg`;
    default:
      return null;
  }
};

export default getIntegrationFileUrl;
