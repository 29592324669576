import React, { Component } from 'react';
import UserStatusMenu from '../../components/UserStatusMenu';
import css from './UserStatusMenuContainer.scss';
import { sdk } from '../../util/sdk';
import LocalStorage from '../../components/LocalStorage';
import getUserStatusSubscriptionProvider from '../../util/user-status-subscription-provider';
import MobileDashboard from '../../components/Helpers/MobileDashboard';
import { gql } from '../../util/gql/tag';

const cachedStatusStorageKey = 'pageproof.app.user-status.';

function setCachedStatus(userId, status) {
  window.localStorage.setItem(cachedStatusStorageKey + userId, JSON.stringify(status));
}

function getCachedStatus(userId) {
  const value = window.localStorage.getItem(cachedStatusStorageKey + userId);
  return value ? JSON.parse(value) : null;
}

const customPresetsStorageKey = 'pageproof.app.custom-user-status-presets';

const userStatusQuery = gql`
  query ppxapp_getMyUserStatus {
    me {
      status {
        message
        emoji
        isAway
      }
    }
  }`;

class UserStatusMenuContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      status: getCachedStatus(sdk.session.userId),
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    sdk.graphql(userStatusQuery.toString())
      .then(({ data }) => {
        const rawStatus = data.me.status;

        this.setState({
          status: rawStatus && {
            message: rawStatus.message,
            emoji: rawStatus.emoji,
            away: rawStatus.isAway,
          },
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState(previousState => ({ ...previousState, loading: false }));
      });

    this._subscription = getUserStatusSubscriptionProvider().subscribe(sdk.session.userId, (status) => {
      this.setState({ status });
    });
  }

  componentDidUpdate() {
    setCachedStatus(sdk.session.userId, this.state.status);
  }

  componentWillUnmount() {
    if (this._subscription) {
      this._subscription();
    }
  }

  onSetStatus = (status) => {
    const previousStatus = this.state.status;
    this.setState({status, loading: true});

    sdk.profile.setStatus(status)
      .catch((err) => {
        console.error(err);
        this.setState({status: previousStatus});
      })
      .then(() => {
        this.setState({loading: false});
      });
  }

  onClearStatus = () => {
    const previousStatus = this.state.status;
    this.setState({status: null, loading: true});

    sdk.profile.clearStatus()
      .catch((err) => {
        console.error(err);
        this.setState({status: previousStatus});
      })
      .then(() => {
        this.setState({loading: false});
      });
  }

  render() {
    return (
      <LocalStorage.Value
        name={customPresetsStorageKey}
        defaultValue="[]"
        watch
      >
        {({value}) => (
          <MobileDashboard>
            {yes => (yes || (
              <div className={css.UserStatusMenuContainer}>
                <UserStatusMenu
                  customPresets={JSON.parse(value)}
                  loading={this.state.loading}
                  status={this.state.status}
                  onSetStatus={this.onSetStatus}
                  onClearStatus={this.onClearStatus}
                />
              </div>
            ))}
          </MobileDashboard>
        )}
      </LocalStorage.Value>
    );
  }
}

export default UserStatusMenuContainer;
