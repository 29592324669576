import React, { useCallback, useMemo, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useVirtualizer, defaultRangeExtractor } from '@tanstack/react-virtual';
import { VirtualListContext } from '../context/VirtualListContext';
import { VirtualListItemContext } from '../context/VirtualListItemContext';
import { useLocalStorage } from '../../../components/LocalStorage';


const VirtualList = forwardRef((props, ref) => {
  const [showPageCommentPaneDivider] = useLocalStorage('pageproof.app.showCommentPageDivider', 'true');
  const isShowCommentPageDivider = showPageCommentPaneDivider === 'true';

  const { items, defaultProps, defaultSize, alwaysMountedItemKeys, ...wrapperProps } = props;

  const wrapperRef = useRef();

  const itemsRef = useRef();
  itemsRef.current = items;

  // This code handles keeping certain items mounted even if they are not in the viewport.
  // This feature is used for components that use state (react or even DOM, like text fields) and need to keep that
  // state even if they are not in the viewport. Otherwise the component is unmounted, and that state is lost.
  const [keepMountedItemKeys, setKeepMountedItemKeys] = useState({});

  const setItemKeepMounted = useCallback((itemKey, shouldKeepMounted) => {
    setKeepMountedItemKeys((previousKeepMountedItemKeys) => {
      const count = (previousKeepMountedItemKeys[itemKey] || 0) + (shouldKeepMounted ? 1 : -1);
      if (count < 0) {
        throw new Error('Cannot set keepMounted to false more times than it was set to true.');
      }
      const newKeepMountedItemKeys = { ...previousKeepMountedItemKeys };
      if (count !== 0) {
        newKeepMountedItemKeys[itemKey] = count;
      } else {
        delete newKeepMountedItemKeys[itemKey];
      }
      return newKeepMountedItemKeys;
    });
  }, []);

  const rangeExtractor = useCallback((range) => {
    const keepMountedItemIndexes = [];

    // Optimization: If there are no items to keep mounted, then we can skip finding their indexes.
    if (
      (alwaysMountedItemKeys && alwaysMountedItemKeys.length) ||
      Object.keys(keepMountedItemKeys).length
    ) {
      items.forEach((item, index) => {
        if (keepMountedItemKeys[item.key]) {
          keepMountedItemIndexes.push(index);
        } else if (alwaysMountedItemKeys && alwaysMountedItemKeys.includes(item.key)) {
          keepMountedItemIndexes.push(index);
        }
      });
    }

    const itemIndexes = new Set([
      ...keepMountedItemIndexes,
      ...defaultRangeExtractor(range),
    ]);

    return [...itemIndexes].sort((a, b) => a - b);
  }, [
    keepMountedItemKeys,
    items,
    alwaysMountedItemKeys ? alwaysMountedItemKeys.join(',') : '',
  ]);

  // This function handles providing the virtualizer with an estimation of the item. We only use this to get a rough
  // approximation of the total size of all the items. That way the scrollbar is more accurate.
  const estimateSize = useCallback((index) => {
    const item = itemsRef.current[index];
    if (typeof item.defaultSize === 'function') {
      return item.defaultSize();
    }
    if (typeof item.defaultSize === 'number') {
      return item.defaultSize;
    }
    if (typeof defaultSize === 'function') {
      return defaultSize(item);
    }
    if (typeof defaultSize === 'number') {
      return defaultSize;
    }
    return 100;
  }, []);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => wrapperRef.current,
    estimateSize,
    rangeExtractor,
    overscan: 25,
    getItemKey: index => itemsRef.current[index].key,
  });

  const runWhenKeyPresent = (key, fn) => {
    const getIndex = () => itemsRef.current.findIndex(item => item.key === key);
    return new Promise((resolve, reject) => {
      let index = getIndex();
      if (index !== -1) {
        fn(index);
        resolve();
      } else {
        setTimeout(() => {
          index = getIndex();
          if (index !== -1) {
            fn(index);
            resolve();
          } else {
            fn(null);
            reject(new Error(`Key ${key} not found in VirtualList items.`));
          }
        });
      }
    });
  };

  useImperativeHandle(ref, () => ({
    scrollToIndex(index, align) {
      rowVirtualizer.scrollToIndex(index, { align });
    },
    scrollToKey(key, align) {
      if (isShowCommentPageDivider) {
        return runWhenKeyPresent(key, (index) => {
          rowVirtualizer.scrollToIndex(index, { align });
        });
      } else if ((key.includes('new') || key.includes('comment')) && !isShowCommentPageDivider) {
        return runWhenKeyPresent(key, (index) => {
          rowVirtualizer.scrollToIndex(index, { align });
        });
      } else {
        return null;
      }
    },
    scrollToTop(align = 'start') {
      rowVirtualizer.scrollToOffset(0, { align });
    },
    getVirtualItems() {
      const virtualItems = rowVirtualizer.getVirtualItems();
      return virtualItems.map(({ index }) => itemsRef.current[index]);
    },
  }), [isShowCommentPageDivider]);

  // Expose an API/set of functions to the children to interact with the VirtualList component.
  const context = useMemo(() => ({
    setItemKeepMounted,
  }), []);

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <VirtualListContext.Provider value={context}>
      <div
        {...wrapperProps}
        ref={wrapperRef}
      >
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {virtualItems.map((virtualRow) => {
            const item = items[virtualRow.index];
            return (
              <VirtualListItemRenderer
                key={item.key}
                item={item}
                virtualRow={virtualRow}
                rowVirtualizer={rowVirtualizer}
                defaultProps={defaultProps}
                defaultSize={defaultSize}
              />
            );
          })}
        </div>
      </div>
    </VirtualListContext.Provider>
  );
});

function VirtualListItemRenderer({ item, virtualRow, rowVirtualizer, defaultProps, defaultSize }) {
  const VirtualComponent = item.component;
  return (
    <div
      key={item.key}
      style={{
        position: 'absolute',
        top: virtualRow.start,
        left: 0,
        width: '100%',
        height: defaultSize,
      }}
      data-index={virtualRow.index}
      ref={rowVirtualizer.measureElement}
    >
      <VirtualListItemContext.Provider value={item.key}>
        <VirtualComponent
          {...defaultProps}
          {...item.props}
        />
      </VirtualListItemContext.Provider>
    </div>
  );
}

VirtualList.defaultProps = {
  items: [],
  defaultProps: {},
  defaultSize: 100, // can also be a function that takes in the virtualized item, and spits out a number that is supposed to an approximation of the height of the item
};

export default VirtualList;
