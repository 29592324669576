/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import factory from '../factory';

// eslint-disable-next-line
export default factory(function Verbose(m) {
  return m.format('LLLL');
});
