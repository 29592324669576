/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useSynchronizedUrl } from '../../hooks/useSynchronizedUrl';
import WebhooksDashboard from '../../components/WebhooksDashboard';

export default ({ initialRouteParams }) => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const [filter, setFilter] = useState(initialRouteParams.filter || 'active');

  useSynchronizedUrl('/team/webhooks', { filter });

  return (
    <Fragment>
      {hasInitialized && (
        <style>{`
          header {
            background: transparent;
            backdrop-filter: none;
            -webkit-backdrop-filter: none;
            border-bottom: 0;
          }
        `}</style>
      )}

      <WebhooksDashboard
        filterState={filter}
        onFilterStateChange={(filterState) => {
          unstable_batchedUpdates(() => {
            setFilter(filterState);
          });
        }}
        onInit={() => {
          setHasInitialized(true);
        }}/>
    </Fragment>
  );
};
