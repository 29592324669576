/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import getTimecodeWithHyphen from '../../../util/get-timecode-with-hyphen';

/* eslint-disable no-nested-ternary */

// Move this somewhere common - so ProofPageComments can use it too.
const UNSUPPORTED_VIEW_ATTACHMENT_EXTENSIONS = [
  'zip',
  'eml',
  'gif',
  'mp4',
  'mov',
  'm4v',
];

export const getProofHelper = (proofCtrl) => {
  const safeApply = () => {
    if (!proofCtrl.$$.$scope.$$phase) {
      proofCtrl.$$.$scope.$apply();
    }
  };

  const compareType = null; // props.type;

  const proof = compareType
    ? (
      compareType === 'left'
        ? proofCtrl.leftImageData.proofData
        : proofCtrl.rightImageData.proofData
    )
    : proofCtrl.proof;

  const permissions = compareType
    ? (
      compareType === 'left'
        ? proofCtrl.leftImageData.permissions
        : proofCtrl.rightImageData.permissions
    )
    : proofCtrl.permissions;

  const { isVideo, isAudio, isStatic, isWeb, framesPerSecond } = proof;
  const isMedia = isVideo || isAudio;

  let mentionSuggestions;
  const getMentionSuggestions = () => {
    if (!mentionSuggestions) {
      mentionSuggestions = proof.allowedUsersForMention.map(user => ({
        id: user.id,
        label: user.name || user.email || user.display, // "display" is for the @all/@everyone mentions
      }));
    }
    return mentionSuggestions;
  };

  function isAttachmentViewable(attachment) {
    if (!attachment) {
      return false;
    }
    return attachment.status === 'OK' &&
      !UNSUPPORTED_VIEW_ATTACHMENT_EXTENSIONS.includes(attachment.extension);
  }

  function canEditComment(comment) {
    return permissions.proofer.commentLevel.canEdit &&
      (comment.ownerId === proofCtrl.user.id ||
        (proof.canOthersEditComments &&
        (proof.isOwnerOrCoOwner || permissions.proofer.proofLevel.isFinalApprover)));
  }

  function getAttachmentProps(comment, attachment) {
    const isUploading = !!attachment.$progress;
    const isProcessing = attachment.status === 'Queued' || attachment.status === 'Ripping';
    const hasChunks = attachment.chunks !== 0;
    return {
      id: attachment.id,
      name: attachment.name,
      isProcessing,
      isUploading,
      canDownload: hasChunks && permissions.proofer.proofLevel.canDownloadAttachments,
      canView: isAttachmentViewable(attachment),
      canDelete: attachment.id && canEditComment(comment),
      onView: () => {
        proofCtrl.previewAttachment(attachment, comment);
        safeApply();
      },
      onDownload: (onProgress) => {
        const promise = proofCtrl.downloadAttachment(attachment);
        promise.then(null, null, (progress) => {
          onProgress(progress);
        });
        safeApply();
        return promise;
      },
      onDelete: () => {
        proofCtrl.deleteAttachment(attachment, comment);
        safeApply();
      },
    };
  }

  function validateAttachmentType(attachment) {
    try {
      return proofCtrl.validateAttachmentType(attachment);
    } finally {
      safeApply();
    }
  }

  function validateAttachmentSize(attachment) {
    try {
      return proofCtrl.validateAttachmentSize(attachment);
    } finally {
      safeApply();
    }
  }

  function updateAttachments(comment, files) {
    const newAttachments = files.map((file) => {
      const attachment = new (proofCtrl.$$.PPProofCommentAttachment)();
      attachment.updateFromFile(file);
      return attachment;
    });
    // eslint-disable-next-line no-param-reassign
    comment.attachments = [...comment.attachments, ...newAttachments];
    proofCtrl.updateComment(comment, newAttachments);
    safeApply();
  }

  function getTimecodes(comment) {
    const { pins, isReply } = comment;

    if (!isMedia || isReply) {
      return null;
    }

    return [...pins]
      .sort((pin1, pin2) => pin1.time - pin2.time)
      .map(pin => getTimecodeWithHyphen(pin, isVideo, framesPerSecond));
  }

  function getMediaPin(comment) {
    return (comment && isMedia)
      ? comment.pins.find(pin => typeof pin.time !== 'undefined' && typeof pin.duration !== 'undefined')
      : null;
  }

  return {
    proof,
    permissions,
    compareType,

    isVideo,
    isAudio,
    isStatic,
    isWeb,
    isMedia,

    getMentionSuggestions,
    getAttachmentProps,
    validateAttachmentType,
    validateAttachmentSize,
    updateAttachments,
    getTimecodes,
    isAttachmentViewable,
    canEditComment,
    getMediaPin,

    safeApply,
  };
};
