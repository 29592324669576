/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmDeleteOption, PopupMenu } from '../PopupMenu';
import IconButton from '../Button/IconButton';

const DeleteButton = ({
  onDelete,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <PopupMenu
      options={
        <ConfirmDeleteOption
          isDeleting={isDeleting}
          onClick={() => {
            const result = onDelete();

            if (result && typeof result.finally === 'function') {
              setIsDeleting(true);
              result.finally(() => {
                setIsDeleting(false);
              });
            }
            return false;
          }}
        />
      }
    >
      <IconButton src="/img/content/proof/icons/delete.svg" />
    </PopupMenu>
  );
};

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default DeleteButton;
