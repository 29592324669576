/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { InlineSVG } from '@jmshal/react-inline-svg';
import { Option, PopupMenu, Separator } from '../PopupMenu';
import Tooltip from '../Tooltip';
import Translation from '../Text/Translation';
import css from './NotificationFilter.scss';

const NotificationFilter = ({
  onFilterChange,
  filter,
}) => (
  <PopupMenu
    options={
      <Fragment>
        <Option
          label={<Translation value="notifications.filter-by" />}
          disabled
        />
        <Separator />
        <Option
          label={<Translation value="notifications.filter-read" />}
          checked={filter === 'read'}
          onClick={() => onFilterChange('read')}
        />
        <Option
          label={<Translation value="notifications.filter-unread" />}
          checked={filter === 'unread'}
          onClick={() => onFilterChange('unread')}
        />
        <Separator />
        <Option
          label={<Translation value="notifications.clear-filter" />}
          onClick={() => onFilterChange(null)}
        />
      </Fragment>
    }
    down
    center
    arrow
    offset={15}
  >
    {popupMenu => (
      <div>
        <Tooltip
          up
          center
          title={<Translation value="notifications.filter.tooltip" />}
          disabled={popupMenu.state.visible}
        >
          <div
            className={classname(css.NotificationFilter__icon, {
              [css['NotificationFilter__icon--active']]: filter,
            })}
          >
            <InlineSVG
              src="img/interface/sort-filter.svg"
              width="13"
              height="15"
            />
          </div>
        </Tooltip>
      </div>
    )}
  </PopupMenu>
);


NotificationFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.string,
};

export default NotificationFilter;
