/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';
import Tooltip from '../Tooltip';
import { useStitchEmbed } from '../../hooks/useStitchEmbed';
import { ProofFooterChecklistIcon } from './ProofFooterChecklistIcon';
import useEventListener from '../../hooks/useEventListener';

const TOOLTIP_WIDTH = 600;
const DEFAULT_EMBED_PADDING = 100;

const ProofChecklist = ({ checklistId, name, canBeOpen, isHeaderVisible, onChecklistUpdated }) => {
  const [isVisible, setIsVisible] = useState(false);

  // 120 and 70 work to keep the popover below the comment count tab
  const getMaxHeight = () => Math.round(window.innerHeight / 2) - (isHeaderVisible ? 120 : 70);
  const [maxHeight, setMaxHeight] = useState(getMaxHeight);

  useEffect(() => {
    setMaxHeight(getMaxHeight());
  }, [isHeaderVisible]);

  const [embedPadding, setEmbedPadding] = useState({
    top: DEFAULT_EMBED_PADDING,
    right: DEFAULT_EMBED_PADDING,
    bottom: 0,
    left: DEFAULT_EMBED_PADDING,
  });

  const ProofChecklistStitch = useStitchEmbed('ProofChecklist', {
    iframeProps: {
      style: {
        zIndex: 99999998,
        width: TOOLTIP_WIDTH + embedPadding.left + embedPadding.right,
        clipPath: `inset(${embedPadding.top}px ${embedPadding.right}px ${embedPadding.bottom}px ${embedPadding.left}px)`,
      },
    },
    observeHeight: true,
  });

  useEventListener(window, 'resize', () => {
    setMaxHeight(getMaxHeight());
  });

  return (
    <ProofChecklistStitch.Eager
      padding={embedPadding}
      checklistId={checklistId}
      onChecklistUpdated={onChecklistUpdated}
      onClose={() => setIsVisible(false)}
      onOpen={() => setIsVisible(true)}
      maxHeight={maxHeight}
      onBeforeEmbedUpdatesPosition={({ inlineStyles, isInitialPosition }) => ({
        inlineStyles: {
          ...inlineStyles,
          transition: !isInitialPosition && canBeOpen ? 'opacity 250ms ease-in-out' : '',
          opacity: isVisible && canBeOpen && !isInitialPosition ? 1 : 0,
        },
      })}
    >
      {ProofChecklistStitchPlacement => (
        <Tooltip
          delay={0}
          zIndex={99999997}
          visible={isVisible && canBeOpen}
          popover
          onUpdatePosition={({ left, top }) => {
            setEmbedPadding({
              top: Math.min(top, DEFAULT_EMBED_PADDING),
              right: Math.min(window.innerWidth - left - TOOLTIP_WIDTH, DEFAULT_EMBED_PADDING),
              bottom: 0,
              left: Math.min(left, DEFAULT_EMBED_PADDING),
            });
          }}
          title={(
            <ProofChecklistStitchPlacement
              style={{
                width: TOOLTIP_WIDTH + embedPadding.left + embedPadding.right,
                marginTop: -embedPadding.top,
                marginLeft: -embedPadding.left,
                marginRight: -embedPadding.right,
                marginBottom: -embedPadding.bottom,
                pointerEvents: 'none',
              }}
            />
          )}
          variant="light"
          center
          arrow
          animateDisappear={canBeOpen}
          offset={0}
          padding={false}
          maxWidth={false}
        >
          <ProofFooterChecklistIcon
            name={name}
            showTooltip={!isVisible}
            onClick={() => setIsVisible(prevIsVisible => !prevIsVisible)}
          />
        </Tooltip>
      )}
    </ProofChecklistStitch.Eager>
  );
};

export default ProofChecklist;
