/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import Tooltip from '../Tooltip';
import Translation from '../Text/Translation';
import css from './CustomTool.scss';
import { unique } from '../../util/object-array-utils';
import CustomToolButton from '../CustomToolButton';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const DEFAULT_BUBBLE_SIZE = 60;
const DEFAULT_BUBBLE_SPACING = 15;
const DEFAULT_BUBBLE_HIT_BOX_PADDING = 20;

export default class CustomTool extends React.Component {
  state = {
    isOpen: false,
  };

  static defaultProps = {
    native: false,
    direction: 'right',
    inline: false,
    size: DEFAULT_BUBBLE_SIZE,
    padding: DEFAULT_BUBBLE_HIT_BOX_PADDING,
    spacing: DEFAULT_BUBBLE_SPACING,
    order: [],
    constraints: {
      width: 40,
      height: 40,
    },
  };

  getOptions() {
    return (this.props.options || []).filter(Boolean);
  }

  getOrderedOptions() {
    const options = this.getOptions();
    const modesInOrder = [];
    const ids = [
      this.props.value,
      ...this.props.order,
      ...options.map(mode => mode.id),
    ];
    ids.forEach((modeId) => {
      const mode = options.filter(_mode => _mode.id === modeId)[0];
      if (mode) {
        if (modesInOrder.indexOf(mode) === -1) {
          modesInOrder.push(mode);
        } else {
          // Tool is already in the array
        }
      } else {
        // Tool is no longer available
      }
    });
    return modesInOrder;
  }

  handleTouch = () => {
    if (this.state.isOpen) {
      this.close();
    }
    this.removeEventListener();
  }

  open = () => {
    this.setState({ isOpen: true });
    this.addEventListener();
  }

  close = () => {
    this.setState({ isOpen: false });
  }

  select = id => () => {
    this.close();
    this.props.onChange({
      selected: this.props.value === id
        ? !this.props.selected
        : true,
      value: id,
      order: unique([id, ...this.props.order]),
    });
  }

  removeEventListener = () => {
    window.removeEventListener('touchstart', this.handleTouch, false);
  }

  addEventListener = () => {
    window.addEventListener('touchstart', this.handleTouch, false);
  }

  render() {
    const { isOpen: stateOpen } = this.state;
    const isOpen = 'open' in this.props ? this.props.open : stateOpen;
    const {
      native,
      value,
      selected,
      direction,
      inline,
      padding,
      spacing,
      size,
      constraints,
    } = this.props;

    const options = this.getOptions();
    const orderedOptions = this.getOrderedOptions();
    const horizontal = direction === 'left' || direction === 'right';
    const dimensions = {
      [horizontal ? 'width' : 'height']: isOpen
        ? (size * orderedOptions.length) + (spacing * (orderedOptions.length - 1))
        : size,
      [horizontal ? 'height' : 'width']: size,
    };

    const ariaLabel = {
      pin: 'Red pen',
      text: 'Highlight pen',
      draw: 'Drawing pen',
      general: 'General comment',
    };

    return (
      <div
        className={classname(css.CustomTool, this.props.className, {
          [css['CustomTool--open']]: isOpen,
          [css[`CustomTool--${direction}-direction`]]: true,
        })}
        onMouseEnter={this.open}
        onMouseLeave={this.close}
        onTouchEnd={this.open}
        style={{
          zIndex: isOpen ? 100000 : 0,
          width: size,
          height: size,
          ...(inline && dimensions),
        }}
      >
        <div
          className={css.CustomTool__hitbox}
          style={{
            width: dimensions.width + (isOpen ? padding * 2 : 0),
            height: dimensions.height + (isOpen ? padding * 2 : 0),
            margin: isOpen ? -padding : 0,
            ...(direction === 'up' && { bottom: 0 }),
            ...(direction === 'left' && { right: 0 }),
          }}
        />
        <div
          style={{
            position: 'absolute',
            ...(direction === 'up' && { bottom: size }),
            ...(direction === 'left' && { right: size }),
          }}
        >
          {options.map((option) => {
            const index = orderedOptions.indexOf(option);
            const { id, icon, tooltip } = option;
            let iconOffset = (size * index) + (spacing * index);
            if (direction === 'up' || direction === 'left') {
              iconOffset = -iconOffset;
            }
            return (
              <Tooltip
                key={id}
                title={
                  <Translation
                    value={tooltip}
                    className={native && css.CustomTool__nativeLabel}
                  />
                }
                up={horizontal}
                center={horizontal}
                right={!horizontal}
                middle={!horizontal}
                delay={0}
                disabled={!tooltip}
                variant={native ? 'no-background' : 'dark'}
              >
                <div
                  {...(option.walkthroughHook ? { 'data-walkthrough-hook': option.walkthroughHook } : {})}
                  className={classname(css.CustomTool__option, {
                    [css['CustomTool__option--primary']]: value === id || index === 0,
                  })}
                  style={{
                    transform: isOpen
                      ? (horizontal ? 'translateX' : 'translateY') + `(${iconOffset}px)`
                      : '',
                    zIndex: orderedOptions.length - index,
                  }}
                  onClick={this.select(id)}
                >
                  <CustomToolButton
                    icon={icon}
                    size={size}
                    constraints={constraints}
                    selected={value === id && selected}
                    ariaLabel={ariaLabel[id] || 'Custom tool'}
                  />
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    );
  }
}
