/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-shadow, no-param-reassign, consistent-return */
import { useMemo, useState, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom'; // eslint-disable-line camelcase
import { Enum } from '@pageproof/sdk';
import { sdk } from '../../../util/sdk';
import { makeUnstableBackendRequest } from '../../../util/unstable-backend-request';
import { nudgeUser } from '../../../features';
import { normaliseUTCToISO } from '../../../util/date-time-utils';
import { addMember, getMembers, removeMember } from '../../WorkflowTemplatePage/internals/members';

export const useWorkflow = (workflowId, proofId, proofLockerUserId, onWorkflowUpdate) => {
  const [workflow, setWorkflow] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [proofLocker, setProofLocker] = useState(null);
  const [proofCommentCounts, setProofCommentCounts] = useState({});

  function load() {
    setIsLoading(true);
    Promise.all([
      sdk.workflows.load(workflowId),
      makeUnstableBackendRequest({ path: `/api/proofs/comment/usercount/${proofId}` }),
      proofLockerUserId && window.__pageproof_bridge__.userRepositoryService.get(proofLockerUserId),
    ])
      .then(([data, rawProofCommentCounts, lockerUser]) => {
        const proofCommentCounts = {};
        Object.entries(rawProofCommentCounts).forEach(([key, value]) => {
          proofCommentCounts[key] = {
            count: value.Count,
            lastCommentDate: new Date(normaliseUTCToISO(value.Timestamp)),
          };
        });

        // Patch the workflow object
        const finalStep = data.steps[data.steps.length - 1];
        if (finalStep.name === 'primary_email') {
          finalStep.name = null;
        }


        unstable_batchedUpdates(() => {
          setIsLoading(false);
          setWorkflow(data);
          setProofLocker(lockerUser);
          setProofCommentCounts(proofCommentCounts);
        });
      });
  }

  useEffect(() => {
    const unwatch = window.__pageproof_bridge__.$rootScope.$on('reloadWorkflow', (_, data) => {
      if (data.workflowId === workflowId) {
        load();
      }
    });

    return unwatch;
  }, [workflowId]);


  // Reload the workflow when workflowId changes
  useEffect(() => {
    if (workflow && workflow.id !== workflowId) {
      setWorkflow(null);
      setProofCommentCounts({});
    }

    setProofLocker(null);

    if (workflowId) {
      load();
    }
  }, [workflowId, proofLockerUserId]);

  const mutations = useMemo(() => ({
    addWorkflowStep(workflow, localStep, index) {
      localStep.state = 'not-visible';

      if (workflow.steps[workflow.steps.length - 1].state !== 'not-visible') {
        localStep.position = 900;
        localStep.state = Enum.WorkflowStepState.COMPLETE;
      }

      setWorkflow(workflow);
      return sdk.workflows._addWorkflowStepAndUsers(workflowId, localStep, false)
        .then((serverStep) => {
          setWorkflow((workflow) => {
            const step = workflow.steps.find(step => step._id === localStep._id);
            if (step) {
              Object.assign(step, serverStep);
            }
            return { ...workflow };
          });
          const nextStep = workflow.steps[index + 1];
          if (nextStep.position !== 1000) {
            return sdk.workflows.steps.moveBefore(serverStep.id, nextStep.id);
          }
          onWorkflowUpdate();
        });
    },
    deleteWorkflowStep(workflow, step) {
      setWorkflow(workflow);
      return makeUnstableBackendRequest({
        method: 'POST',
        path: '/api/workflows/step/delete',
        body: { StepId: step.id },
      }).then((data) => {
        onWorkflowUpdate();
        return data;
      });
    },
    addWorkflowUsers(workflow, step, users, shouldReload = true) {
      setWorkflow(workflow);
      return makeUnstableBackendRequest({
        method: 'POST',
        path: '/api/v2/workflows/users/add',
        body: {
          WorkflowId: workflow.id,
          StepId: step.id,
          Users: users.map(user => ({
            Email: user.email.trim().toLowerCase(),
            Role: user.role,
            Permissions: {
              Inviter: user.permissions.inviter,
            },
          })),
        },
      }).then((response) => {
        const userIds = {};
        response.forEach((user) => {
          userIds[user.Email] = user.UserId;
        });
        onWorkflowUpdate();
        setWorkflow((workflow) => {
          workflow.steps.forEach((workflowStep) => {
            workflowStep.users.forEach((stepUser) => {
              if (stepUser.email in userIds) {
                stepUser.id = userIds[stepUser.email];
              }
            });
          });
          return { ...workflow };
        });
        if (shouldReload) {
          return load();
        }
      });
    },
    removeWorkflowUser(workflow, step, user) {
      setWorkflow(workflow);
      return makeUnstableBackendRequest({
        method: 'POST',
        path: '/api/v2/workflows/users/remove',
        body: {
          WorkflowId: workflow.id,
          StepId: step.id,
          UserId: user.id,
        },
      }).then(() => {
        onWorkflowUpdate();
        if (step.state === 'visible') {
          return load();
        }
      });
    },
    updateWorkflowStepName(workflow, step) {
      setWorkflow(workflow);
      return sdk.workflows.steps.update(step.id, {
        title: step.name,
      }).then((data) => {
        onWorkflowUpdate();
        return data;
      });
    },
    updateWorkflowStepDueDate(workflow, step) {
      setWorkflow(workflow);
      return sdk.workflows.steps.update(step.id, {
        dueDate: step.dueDate,
      }).then((data) => {
        onWorkflowUpdate();
        return data;
      });
    },
    updateWorkflowStepMandatoryDecisionThreshold(workflow, step) {
      setWorkflow(workflow);
      return sdk.workflows.steps.update(step.id, {
        mandatoryDecisionThreshold: step.mandatoryDecisionThreshold,
      }).then(() => {
        onWorkflowUpdate();
        if (step.state === 'visible') {
          return load();
        }
      });
    },
    updateWorkflowUserRole(workflow, step, user) {
      setWorkflow(workflow);
      return makeUnstableBackendRequest({
        method: 'POST',
        path: '/api/v2/workflows/users/update',
        body: {
          WorkflowId: workflow.id,
          StepId: step.id,
          UserId: user.id,
          Role: user.role,
        },
      }).then(() => {
        onWorkflowUpdate();
        if (step.state === 'visible') {
          return load();
        }
      });
    },
    updateWorkflowUserPermissions(workflow, step, user) {
      workflow.steps.forEach((workflowStep) => {
        workflowStep.users.forEach((stepUser) => {
          if (stepUser.id === user.id) {
            stepUser.permissions.inviter = user.permissions.inviter;
          }
        });
      });
      setWorkflow(workflow);
      if (step.position === 1000) {
        if (user.permissions.inviter) {
          return addMember(workflow.id, user.id, 'WorkflowInviter').then((data) => {
            onWorkflowUpdate();
            return data;
          });
        }

        return getMembers(workflow.id).then(((members) => {
          const member = members.users.find(member => member.id === user.id);
          return removeMember(member._memberId, 'WorkflowInviter').then((data) => {
            onWorkflowUpdate();
            return data;
          });
        }));
      }

      return makeUnstableBackendRequest({
        method: 'POST',
        path: '/api/v2/workflows/users/update',
        body: {
          WorkflowId: workflow.id,
          StepId: step.id,
          UserId: user.id,
          Permissions: {
            Inviter: user.permissions.inviter,
          },
        },
      }).then((data) => {
        onWorkflowUpdate();
        return data;
      });
    },
    nudgeUser(workflowId, userId) {
      return nudgeUser(workflowId, userId).then(() => {
        onWorkflowUpdate();
        load();
      });
    },
    skipUser(stepId, userId) {
      return makeUnstableBackendRequest({
        method: 'POST',
        path: '/api/workflows/step/user/skip',
        body: {
          StepId: stepId,
          UserId: userId,
        },
      }).then(() => {
        onWorkflowUpdate();
        load();
      });
    },
  }), [workflowId]);

  return {
    workflow,
    mutations,
    proofLocker,
    reload: load,
    proofCommentCounts,
    isLoading,
  };
};
