/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {PureComponent} from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import css from './VideoScrubber.scss';
import Pins from './Pins';
import videoTime from '../../util/video-time';
import getSDK from '../../util/sdk';
import {
  DEFAULT_COMMENT_DURATION,
  MINIMUM_COMMENT_SPAN_DURATION,
  MINIMUM_VIDEO_DURATION_FOR_SPAN,
} from '../../util/constants';

const sdk = getSDK();

class VideoScrubber extends PureComponent {
  state = {
    isScrubbing: false,
    isSelectingDuration: false,
    initialX: null,
    selectedCommentId: this.props.selectedCommentId || '',
    rightDirection: false,
    lineWidth: 0,
    hasReplayingStarted: false,
    isLeftSpanAllowed: false,
    isRightSpanAllowed: false,
    editingPinIndex: -1,
    timeAtHover: null,
    widthPercentageAtMouse: 0,
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.progress < 1 && !this.state.hasReplayingStarted) {
      this.setState({
        hasReplayingStarted: true
      });
    }

    if (nextProps.progress > 1 && this.state.hasReplayingStarted) {
      this.setState({
        hasReplayingStarted: false
      });
    }

    if (this.props.selectedCommentId !== nextProps.selectedCommentId) {
      this.setState({
        selectedCommentId: nextProps.selectedCommentId,
      });
    }
  }

  componentDidMount() {
    this.scrubber.addEventListener('mousemove', this.onScrubHover, true);
    this.scrubber.addEventListener('mousedown', this.handleScrubStart, true);
  }

  componentWillUnmount() {
    if (this.scrubber) {
      this.scrubber.removeEventListener('mouseup', this.handleScrubCompletion, true);
      this.scrubber.removeEventListener('mousedown', this.handleScrubStart, true);
      this.scrubber.removeEventListener('mousemove', this.setProgress, true);
      this.scrubber.removeEventListener('mouseleave', this.handleScrubCompletion, true);
      this.scrubber.removeEventListener('mousemove', this.onScrubHover, true);

      this.scrubber.removeEventListener('mousemove', this.updateCommentLineWidth, true);
      this.scrubber.removeEventListener('mouseup', this.setCommentDuration, true);
    }
  }

  setProgress = (event) => {
    const newProgress = this.calculateProgress(event.pageX);
    this.props.scrubTo(this.convertDurationTimeToTime(newProgress));
  };

  // eslint-disable-next-line consistent-return
  onScrubHover = (event) => {
    if (event.target.matches('.js-pin-on-hover, .js-pin-on-hover *')) {
      return null;
    }

    const newTime = videoTime(this.calculateProgress(event.pageX));
    const videoScrubberWidth = this.scrubber.offsetWidth;
    this.setState({
      widthPercentageAtMouse: 100 * ((event.pageX - this.scrubber.getBoundingClientRect().left) / videoScrubberWidth),
      timeAtHover: newTime,
    });
  };

  onPinHover = (pinTime) => {
    this.setState({
      widthPercentageAtMouse: (pinTime / this.props.duration) * 100,
      timeAtHover: videoTime(pinTime),
    });
  }

  setCommentDuration = (event) => {
    if (this.isSpanPossible()) {
      event.stopPropagation();
      this.scrubber.removeEventListener('mousemove', this.updateCommentLineWidth, true);
      this.scrubber.removeEventListener('mouseup', this.setCommentDuration, true);

      const {selectedCommentId, editingPinIndex} = this.state;
      const {comments, updateCommentDuration} = this.props;
      const {index, currentComment} = comments.reduce((acc, curr, i) => {
        if (curr.id === selectedCommentId) {
          return {
            index: i,
            currentComment: curr,
          };
        } else {
          return acc;
        }
      }, {
        index: null,
        currentComment: {},
      });

      const pin = currentComment.pins[editingPinIndex];
      console.log('setCommentDuration', { commentIndex: index, currentComment, pin, editingPinIndex, selectedCommentId });
      const commentEnd = this.calculateProgress(event.pageX);
      const commentDuration = this.convertDurationTimeToTime(commentEnd) - pin.time;
      const allowedCommentDuration = (Math.abs(commentDuration) > 0 && Math.abs(commentDuration) < DEFAULT_COMMENT_DURATION)
        ? Math.sign(commentDuration) * MINIMUM_COMMENT_SPAN_DURATION
        : commentDuration;

      this.setState({
        lineWidth: 0,
        isSelectingDuration: false,
        editingPinIndex: -1,
      });

      if (this.canUpdate(commentDuration, currentComment, editingPinIndex)) {
        updateCommentDuration(index, allowedCommentDuration, editingPinIndex);
      }

      // ToDo: fix an issue with the comment line.
      // When you give a duration and then you give it an opposite side duration it blinks, showing the previous duration
      // for a millisecond. It's because when we set a comment duration, it takes time to update it in Comment object and
      // pass it back to the component, so it rerenders the span with the old duration.
    }
  };

  hasNewVersion = proofStatus => proofStatus === 66;

  getCurrentXPosition = (mouseX) => {
    const { left: scrubberStartX, right: scrubberEndX } = this.scrubber.getBoundingClientRect();

    if (mouseX > scrubberEndX) {
      return scrubberEndX;
    }
    if (mouseX < scrubberStartX) {
      return scrubberStartX;
    }
    return mouseX;
  };

  getDirection = (isSelected, duration) => {
    const {isSelectingDuration, rightDirection} = this.state;
    if (isSelected && isSelectingDuration) {
      return rightDirection ? 'right' : 'left';
    } else {
      return duration > DEFAULT_COMMENT_DURATION ? 'right' : 'left';
    }
  };

  canUpdate = (commentDuration, currentComment, pinIndex) => {
    const duration = this.props.duration;
    if (commentDuration < 0) {
      return currentComment.pins[pinIndex].time >= DEFAULT_COMMENT_DURATION;
    } else {
      return (duration - currentComment.pins[pinIndex].time) >= DEFAULT_COMMENT_DURATION;
    }
  };

  handleScrubStart = (event) => {
    event.preventDefault();
    this.setState({
      isScrubbing: true,
    });

    this.setProgress(event);

    this.scrubber.addEventListener('mousemove', this.setProgress, true);
    this.scrubber.addEventListener('mouseup', this.handleScrubCompletion, true);
    this.scrubber.addEventListener('mouseleave', this.handleScrubCompletion, false);
  };

  handleScrubCompletion = (event) => {
    this.scrubber.removeEventListener('mousemove', this.setProgress, true);

    event.stopPropagation();
    this.setState({
      isScrubbing: false,
    });
  };

  selectPin = (comment, pin) => {
    const {
      selectComment,
      scrubToPin,
    } = this.props;

    this.setState({
      selectedCommentId: comment.id,
    });

    selectComment(comment);
    scrubToPin(pin);
  };

  deselectPin = () => !this.state.isSelectingDuration && this.setState({selectedCommentId: ''});

  startSpan = (event, time, pinIndex) => {
    const isLeftSpanAllowed = time > MINIMUM_COMMENT_SPAN_DURATION;
    const isRightSpanAllowed = time + MINIMUM_COMMENT_SPAN_DURATION <= this.props.duration;
    event.stopPropagation();

    this.setState({
      initialX: event.pageX,
      isSelectingDuration: true,
      isLeftSpanAllowed,
      isRightSpanAllowed,
      editingPinIndex: pinIndex,
    });

    this.scrubber.addEventListener('mousemove', this.updateCommentLineWidth, true);
    this.scrubber.addEventListener('mouseup', this.setCommentDuration, true);
  };

  isSpanPossible = () => {
    const {
      isLeftSpanAllowed,
      isRightSpanAllowed,
      rightDirection,
    } = this.state;
    return (rightDirection && isRightSpanAllowed) || (!rightDirection && isLeftSpanAllowed);
  };

  updateCommentLineWidth = (event) => {
    const {initialX} = this.state;
    const currentX = this.getCurrentXPosition(event.pageX);
    const isRight = initialX < currentX;
    const lineWidth = Math.abs(initialX - currentX);
    this.setState({
      rightDirection: isRight,
    });

    if (this.isSpanPossible()) {
      this.setProgress(event);
      this.setState({
        lineWidth,
      });
    }
  };

  timeToPosition = duration => (time) => {
    const convertedTime = this.convertTimeToDurationTime(time) / duration;
    return (convertedTime > 1 ? 1 : convertedTime) * 100;
  }

  timeToWidth = (scrubberWidth, videoDuration) => (commentDuration, time) => {
    const currentTime = this.convertTimeToDurationTime(time);
    const commentDurationTime = this.convertTimeToDurationTime(commentDuration);
    return currentTime + commentDurationTime > videoDuration
      ? scrubberWidth - (scrubberWidth * (currentTime / videoDuration))
      : Math.abs(scrubberWidth * (commentDurationTime / videoDuration));
  }

  percentageConvertor = (value, length) => ((value / length) * 100).toFixed(2);

  // These functions => convertTimeToDurationTime, convertDurationTimeToTime
  // are added for correcting the difference between the duration time of the video-proof and the progress time.
  // The duration time what bring back from AMP(Azure Media Player) is a rounded number. So, The duration time is a bit different from real playtime
  // These functions are especially for the short video (like GIF). Ex.) duration time: 2 sec, real total progress time: 1.57 sec
  convertTimeToDurationTime = (time) => {
    const {buffered, duration} = this.props;
    return duration === Math.round(buffered.end) && buffered.end <= duration
      ? (time / buffered.end) * duration
      : time;
  }

  convertDurationTimeToTime = (time) => {
    const {buffered, duration} = this.props;
    return duration === Math.round(buffered.end) && buffered.end <= duration
      ? (time / duration) * buffered.end
      : time;
  }

  calculateProgress(positionX) {
    const duration = this.props.duration;
    const videoScrubberWidth = this.scrubber.offsetWidth;
    return duration * ((positionX - this.scrubber.getBoundingClientRect().left) / videoScrubberWidth);
  }

  render() {
    const {
      comments,
      duration,
      progress: originProgress,
      proofStatus,
      buffered,
      getPinColor,
    } = this.props;

    const {
      start: bufferedStart,
      end: bufferedEnd,
    } = buffered;

    const {
      isScrubbing,
      selectedCommentId,
      isSelectingDuration,
      lineWidth,
      hasReplayingStarted,
      editingPinIndex,
      timeAtHover,
      widthPercentageAtMouse,
    } = this.state;

    const scrubber = this.scrubber;
    const bufferedWidth = ((bufferedEnd + bufferedStart) >= duration) || (duration === Math.round(bufferedEnd)) ? duration : bufferedEnd;
    const isSpanAllowed = duration > MINIMUM_VIDEO_DURATION_FOR_SPAN;
    const progress = this.convertTimeToDurationTime(originProgress);
    return (
      <div
        className={classname(css.VideoScrubber, 'js-video-scrubber', {
          [css['VideoScrubber--scrubbing']]: isScrubbing || hasReplayingStarted,
        })}
        style={{
          '--widthPercentageAtMouse': `${widthPercentageAtMouse}%`,
          '--progress': `${progress}%`,
          '--bufferProgress': `${this.percentageConvertor(bufferedWidth, duration)}%`,
        }}
        onContextMenu={event => event.preventDefault()}
        ref={videoScrubber => (this.scrubber = videoScrubber)}
        role="presentation"
      >
        <div className={css.VideoScrubber__buffered} />
        <div className={css.VideoScrubber__progress} />
        <div className={css.VideoScrubber__timeAtHover}>
          {timeAtHover}
        </div>
        <div className={css.VideoScrubber__scrubberMouseLine} />
        {comments.length > 0 && scrubber &&
        <Pins
          comments={comments}
          selectedCommentId={selectedCommentId}
          editingPinIndex={editingPinIndex}
          videoDuration={duration}
          lineWidth={lineWidth}
          isSelectingDuration={isSelectingDuration}
          userId={sdk.session.userId}
          startSpan={this.startSpan}
          timeToPosition={this.timeToPosition(duration)}
          timeToWidth={this.timeToWidth(scrubber.offsetWidth, duration, bufferedEnd)}
          getPinColour={getPinColor}
          getDirection={this.getDirection}
          selectPin={this.selectPin}
          deselectPin={this.deselectPin}
          scrubberWidth={scrubber.offsetWidth}
          isSpanAllowed={isSpanAllowed}
          hasNewVersion={this.hasNewVersion(proofStatus)}
          onPinHover={this.onPinHover}
        />}
      </div>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  VideoScrubber.propTypes = {
    updateCommentDuration: PropTypes.func,
    comments: PropTypes.arrayOf(PropTypes.object),
    duration: PropTypes.number,
    progress: PropTypes.string,
    scrubTo: PropTypes.func.isRequired,
    proofStatus: PropTypes.number,
    getPinColor: PropTypes.func,
    buffered: PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    }),
    selectedCommentId: PropTypes.string,
    selectComment: PropTypes.func,
  };
}

VideoScrubber.defaultProps = {
  comments: [],
  duration: 0,
  progress: '0',
  proofStatus: -1,
  buffered: {
    start: 0,
    end: 0,
  },
};

export default VideoScrubber;
