/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { useRef } from 'react';
import classname from 'classname';
import FileDropperIconButton from './FileDropperIconButton';
import css from './FileDropper.scss';

import SelectFileOptions from './SelectFileOptions';

const FileDropper = ({
  type, // "proof" or "brief"
  variant, // "tile" or "list" or "info-pane"
  compact,
  color,
  highlight, // "error" or "new-version" or "brief"
  multiple,
  parameters,
  initialize,
  direction,
  tooltipTitle,
}) => {
  const buttonRef = useRef();

  return (
    <div
      className={classname(css.FileDropper, css['FileDropper--' + variant], {
        [css['FileDropper--compact']]: compact,
        [css[`FileDropper--${highlight}`]]: highlight,
      })}
      onClick={(event) => {
        event.stopPropagation();
        if (event.currentTarget === event.target) {
          event.preventDefault();
          buttonRef.current.click();
        }
      }}
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        SelectFileOptions.drop({ type, multiple, parameters, initialize }, [...event.dataTransfer.files]);
      }}
    >
      <SelectFileOptions
        type={type}
        multiple={multiple}
        parameters={parameters}
        initialize={initialize}
        disabled={variant === 'info-pane'}
        direction={direction}
        tooltipTitle={tooltipTitle}
      >
        <FileDropperIconButton
          variant={variant}
          compact={compact}
          color={color}
          onClick={(event) => {
            event.preventDefault();
          }}
          ref={buttonRef}
          disabled={variant === 'info-pane'}
        />
      </SelectFileOptions>
    </div>
  );
};

FileDropper.defaultProps = {
  variant: 'tile',
  compact: true,
};

export default FileDropper;
