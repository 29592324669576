/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState, useRef, useEffect } from 'react';
import classname from 'classname';
import { VideoPlayerButton } from './VideoPlayerButton';
import { Translation } from '../Text';
import { RangeInput } from '../RangeInput';
import css from './VideoPlayerVolumeButton.scss';

const EXPANDED_WIDTH = 150;
const HIDE_TIMEOUT = 1000;

export const VideoPlayerVolumeButton = ({
  isMuted,
  volume,
  setVolume,
  setMuted,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const interactionStateRef = useRef({
    isHovering: false,
    isChanging: false,
  });
  const timerRef = useRef(null);

  function updateInteractionState(updates) {
    interactionStateRef.current = {
      ...interactionStateRef.current,
      ...updates,
    };
    const { isHovering, isChanging } = interactionStateRef.current;
    if (isHovering || isChanging) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    } else {
      timerRef.current = setTimeout(() => {
        setIsVisible(false);
        timerRef.current = null;
      }, HIDE_TIMEOUT);
    }
  }

  useEffect(() => () => {
    clearInterval(timerRef.current);
  }, []);

  return (
    <div
      className={classname(css.VideoPlayerVolumeButton, {
        [css['VideoPlayerVolumeButton--isVisible']]: isVisible,
      })}
      onPointerEnter={() => {
        setIsVisible(true);
        updateInteractionState({ isHovering: true });
      }}
      onPointerLeave={() => {
        updateInteractionState({ isHovering: false });
      }}
    >
      <div style={{ width: isVisible ? EXPANDED_WIDTH : 0, transition: 'width 300ms ease-in-out', overflow: 'hidden', position: 'absolute', right: '100%', height: '100%', backgroundColor: 'inherit' }}>
        <div style={{ width: EXPANDED_WIDTH, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '100%', paddingLeft: 14 }}>
            <RangeInput
              min={0}
              max={100}
              step={1}
              value={isMuted ? 0 : (volume * 100)}
              onChange={(value) => {
                setMuted(false);
                setVolume(value / 100);
                updateInteractionState({ isChanging: true });
              }}
              onChanged={() => {
                updateInteractionState({ isChanging: false });
              }}
              tooltip={value => (value
                ? `${Math.round(value)}%`
                : <Translation value="video-player.muted" />)}
            />
          </div>
        </div>
      </div>
      <VideoPlayerButton
        tooltip={
          <div>
            {isMuted || volume === 0
              ? <Translation value="video-player.unmute" />
              : <Translation value="video-player.mute" />
            }
            {!isMuted && volume > 0 && (
              <Fragment>
                <br />
                <div style={{ color: 'rgba(255, 255, 255, .6)' }}>
                  {Math.round(volume * 100)}
                  %
                </div>
              </Fragment>
            )}
          </div>
        }
        iconUrl={
          /* eslint-disable no-nested-ternary */
          volume === 0 || isMuted
            ? 'img/interface/volume/mute.svg'
            : volume >= 0.5
              ? 'img/interface/volume/high.svg'
              : 'img/interface/volume/low.svg'
          /* eslint-enable */
        }
        onClick={() => {
          if (!isMuted && volume === 0) {
            setVolume(1);
          } else {
            setMuted(!isMuted);
          }
        }}
        isHighlighted={isVisible}
      />
    </div>
  );
};
