/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './Flex.scss';

export const Flex = ({
  root,
  container,
  direction,
  wrap,
  alignItems,
  alignContent,
  justifyContent,
  grow,
  shrink,
  gap,
  style,
  className,
  children,
}) => (
  <div
    style={{
      '--flex-item-grow': grow,
      '--flex-item-shrink': shrink,
      '--flex-item-gap': typeof gap !== 'undefined' ? (gap + 'px') : '',
      ...style,
    }}
    className={classname(css.Box, className, {
      [css['Flex--root']]: root,
      [css['Flex--container']]: container,
      [css[`Flex--direction-${direction}`]]: direction,
      [css[`Flex--wrap-${wrap}`]]: wrap,
      [css[`Flex--alignItems-${alignItems}`]]: alignItems,
      [css[`Flex--alignContent-${alignContent}`]]: alignContent,
      [css[`Flex--justifyContent-${justifyContent}`]]: justifyContent,
      [css['Flex--grow']]: typeof grow !== 'undefined',
      [css['Flex--shrink']]: typeof shrink !== 'undefined',
      [css['Flex--gap']]: typeof gap !== 'undefined',
    })}
  >
    {children}
  </div>
);

Flex.propTypes = {
  root: PropTypes.bool,
  container: PropTypes.bool,
  direction: PropTypes.oneOf(['horizontal', 'vertical', 'horizontal-reverse', 'vertical-reverse']),
  wrap: PropTypes.oneOf(['no-wrap', 'wrap', 'wrap-reverse']),
  alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  alignContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly', 'stretch']),
  justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly', 'stretch']),
  grow: PropTypes.number,
  shrink: PropTypes.number,
  gap: PropTypes.number,
};
