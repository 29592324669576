/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { VideoPlayerButton } from './VideoPlayerButton';
import { Translation } from '../Text';
import { PopupMenu, Option, Heading, Separator } from '../PopupMenu';
import useDarkMode from '../../hooks/useDarkMode';

function QualityLabel({ label, info }) {
  return (
    <Fragment>
      {label}
      {info.height >= 720 && (
        <span style={{ fontSize: 8, opacity: 0.65, marginLeft: 2, verticalAlign: 'super' }}>
          HD
        </span>
      )}
    </Fragment>
  );
}

export const VideoPlayerQualityButton = ({
  qualities,
  preferredQualityId,
  playbackQualityId,
  setPreferredQuality,
}) => {
  const playbackQuality = qualities.find(quality => quality.id === playbackQualityId);
  const preferredQuality = qualities.find(quality => quality.id === preferredQualityId);

  const [isDarkMode] = useDarkMode();
  const optionsVariant = isDarkMode ? 'dark' : 'light';

  return (
    <PopupMenu
      options={
        <Fragment>
          <Heading
            label="Quality"
            variant={optionsVariant}
          />
          {qualities.map(quality => (
            <Option
              key={quality.id}
              label={<QualityLabel {...quality} />}
              selected={quality.id === preferredQualityId}
              checked={quality.id === preferredQualityId}
              onClick={() => {
                setPreferredQuality(quality.id);
              }}
            />
          ))}
          <Separator variant={optionsVariant} />
          <Option
            label={
              <div style={{ padding: '6px 0' }}>
                Auto
                {(preferredQualityId === null && playbackQualityId !== null) && (
                  <Fragment>
                    <br />
                    <span style={{ opacity: 0.3 }}>
                      <QualityLabel {...playbackQuality} />
                    </span>
                  </Fragment>
                )}
              </div>
            }
            selected={preferredQualityId === null}
            checked={preferredQualityId === null}
            onClick={() => {
              setPreferredQuality(null);
            }}
          />
        </Fragment>
      }
      variant="light"
    >
      {menu => (
        <VideoPlayerButton
          tooltip={
            !menu.isVisible &&
            <div>
              <Translation value="proof.utilities.quality" />
              <br />
              <div style={{ opacity: 0.6 }}>
                {preferredQualityId === null
                  ? (
                    <Fragment>
                      Auto
                      {playbackQuality ? (
                        <Fragment>
                          {' - '}
                          <QualityLabel {...playbackQuality} />
                        </Fragment>
                      ) : ''}
                    </Fragment>
                  )
                  : <QualityLabel {...preferredQuality} />
                }
              </div>
            </div>
          }
          iconUrl="img/icons/material/tune_black_24dp.svg"
        />
      )}
    </PopupMenu>
  );
};
