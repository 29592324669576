/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useMemo, useState, useCallback } from 'react';
import Media from 'react-media';
import { ProofTemplates } from './ProofTemplates';
import HeaderPortal from '../../../HeaderPortal';
import { unique } from '../../../../util/object-array-utils';
import { mapWorkflowFromSdk, getWorkflowTemplateProofOwners } from '../../utils/workflows';
import useTeamProofTemplates from '../../../../hooks/useTeamProofTemplates';
import { gql } from '../../../../util/gql/tag';
import { sdk } from '../../../../util/sdk';
import Translation from '../../../Text/Translation';
import { getModifiedFields } from './getModifiedFields';
import { getWorkflowWithAvailableRoles } from '../../utils/getWorkflowWithAvailableRoles';
import { structuredCloneWithFallback } from '../../utils/structuredCloneWithFallback';

const proofTemplateQuery = gql`
query ppxapp_getProofTemplate($proofTemplateId: ID!) {
  proofTemplate(id: $proofTemplateId) {
    name
    id
    values {
      absoluteDueDate
      canDownload
      isPublic
      name
      reference
      tags
      messageToReviewers
      owners {
        email
      }
      workflowTemplate {
        id
        name
        proofOwners {
          email
        }
      }
      collection {
        name
        id
      }
      checklistTemplate {
        name
        id
        defaultShouldOpenOnProofLoad
        defaultIsRequiredForFinalApproval
      }
    }
  }
}`;


export const ProofTemplatesContainer = ({
  setState: setProofSetupState,
  state: proofSetupState,
  currentProofIndex,
  hasMultipleProofs,
  setIsLoadingWorkflow,
  userPreferences,
  isShared,
}) => {
  const { currentProofTemplateId } = proofSetupState;
  const [readyState, proofTemplates] = useTeamProofTemplates();
  const [isLoadingProofTemplates, setIsLoadingProofTemplates] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  const isLoading = (
    (
      readyState === 'loading' &&
      proofTemplates.length === 0
    ) ||
    readyState === 'reloading' ||
    isLoadingProofTemplates
  );

  const getModifiedFieldsMemoized = useCallback((originalState, currentState, proofIndex) => {
    const { modifiedFields, sharedProofsModifiedFields } = getModifiedFields(originalState, currentState, proofIndex, userPreferences);
    return { modifiedFields, sharedProofsModifiedFields };
  }, [getModifiedFields, userPreferences]);

  const { modifiedFields, sharedProofsModifiedFields } = useMemo(() => {
    if (!proofSetupState.currentProofTemplateSnapshot || !currentProofTemplateId || isLoadingProofTemplates) {
      return { modifiedFields: [], sharedProofsModifiedFields: [] };
    }

    return getModifiedFieldsMemoized(
      proofSetupState.currentProofTemplateSnapshot,
      proofSetupState,
      currentProofIndex
    );
  }, [proofSetupState, currentProofIndex, getModifiedFieldsMemoized, currentProofTemplateId, isLoadingProofTemplates]);

  const resetProofTemplate = () => {
    if (proofSetupState.initialProofSetupState) {
      setProofSetupState(proofSetupState.initialProofSetupState);
    }
  };

  // TODO: Extract this function from this component
  const updateProofSetupState = (proofTemplateId, previousState, values, workflow, workflowTemplateOwners) => {
    const newState = structuredCloneWithFallback(previousState.initialProofSetupState || previousState);
    const currentProof = isShared ? newState.shared : newState.proofs[currentProofIndex];

    newState.proofTemplateValues = {
      ...values,
      owners: (values.owners && values.owners.map(owner => owner.email)) || [],
      tags: values.tags || [],
    };

    let newProofState = {
      name: values.name !== null ? values.name : currentProof.name,
      tags: [
        ...currentProof.tags || [],
        ...newState.proofTemplateValues.tags,
      ],
      reference: values.reference !== null ? values.reference : currentProof.reference,
      isPublic: values.isPublic !== null ? values.isPublic : currentProof.isPublic,
      canDownload: values.canDownload !== null ? values.canDownload : currentProof.canDownload,
      messageToReviewers: values.messageToReviewers !== null ? values.messageToReviewers : currentProof.messageToReviewers,
      dueDate: values.absoluteDueDate !== null ? values.absoluteDueDate : currentProof.dueDate,
    };

    if (isShared && hasMultipleProofs) {
      newState.shared = { ...currentProof, ...newProofState };
    } else {
      newProofState = { ...currentProof, ...newProofState };
      newState.proofs[currentProofIndex] = newProofState;
    }

    if (values.collection && values.collection.id) {
      newState.parameters.addToCollection = values.collection.name;
      newState.shared.collection = values.collection.name;
      newState.shared.addToCollection = {
        id: values.collection.id,
        name: values.collection.name,
      };
    }

    if (values.checklistTemplate && values.checklistTemplate.id) {
      newState.checklist = {
        templateId: values.checklistTemplate.id,
        name: values.checklistTemplate.name,
        shouldOpenOnProofLoad: values.checklistTemplate.defaultShouldOpenOnProofLoad,
        isRequiredForFinalApproval: values.checklistTemplate.defaultIsRequiredForFinalApproval,
      };
    }

    if (workflow) {
      newState.workflow = getWorkflowWithAvailableRoles({ ...workflow });
    }

    newState.workflowTemplateOwners = workflowTemplateOwners || [];
    newState.owners = unique([
      ...newState.owners || [],
      ...newState.proofTemplateValues.owners,
      ...newState.workflowTemplateOwners,
    ]);

    newState.currentProofTemplateSnapshot = structuredCloneWithFallback(newState);
    newState.currentProofTemplateId = proofTemplateId;
    newState.initialProofSetupState = previousState.initialProofSetupState || structuredCloneWithFallback(previousState);
    return newState;
  };

  const loadAndSetProofTemplate = (proofTemplateId) => {
    sdk.graphql(proofTemplateQuery.toString(), { proofTemplateId }, { throwOnError: false })
      .then((result) => {
        const { proofTemplate } = result.data;
        const { values } = proofTemplate;
        let workflowAndOwners = null;

        if (values.workflowTemplate && values.workflowTemplate.id) {
          setIsLoadingWorkflow(true);
          workflowAndOwners = Promise.all([
            sdk.workflows.load(values.workflowTemplate.id),
            getWorkflowTemplateProofOwners(values.workflowTemplate.id),
          ])
            .then(([workflow, owners]) => ({
              mappedWorkflow: mapWorkflowFromSdk(workflow),
              owners,
            }));
        }

        return { values, workflowAndOwners, proofTemplateId: proofTemplate.id };
      // eslint-disable-next-line consistent-return
      }).then(({ values, workflowAndOwners, proofTemplateId: templateId }) => {
        if (workflowAndOwners) {
          return workflowAndOwners.then(({ mappedWorkflow, owners }) => {
            setProofSetupState(prevState => (updateProofSetupState(templateId, prevState, values, mappedWorkflow, owners)));
          });
        } else {
          setProofSetupState(prevState => (updateProofSetupState(templateId, prevState, values)));
        }
      }).then(() => {
        setIsLoadingWorkflow(false);
        setIsLoadingProofTemplates(false);
      });
  };

  const selectProofTemplate = (proofTemplateId) => {
    setIsLoadingProofTemplates(true);

    loadAndSetProofTemplate(proofTemplateId);
  };

  const filteredProofTemplates = proofTemplates.filter((template) => {
    if (searchTerm) {
      return template.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return true;
  });

  let selectedProofTemplateName = <Translation value="proof.setup.proof-template.chooser.select" />;

  if (currentProofTemplateId) {
    proofTemplates.forEach((template) => {
      if (template.id === currentProofTemplateId) {
        selectedProofTemplateName = template.name;
      }
    });
  }

  if (!proofTemplates.length) {
    return null;
  }

  return (
    <Media query="(min-width: 1100px)">
      {matches => (
        <HeaderPortal position="left-middle">
          <ProofTemplates
            currentProofTemplateId={currentProofTemplateId}
            variant={matches ? null : 'popover'}
            selectedProofTemplateName={selectedProofTemplateName}
            filteredProofTemplates={filteredProofTemplates}
            setSearchTerm={setSearchTerm}
            isLoading={isLoading}
            canSearch={proofTemplates.length > 5}
            onSelect={selectProofTemplate}
            searchTerm={searchTerm}
            onReset={resetProofTemplate}
            proofSetupState={proofSetupState}
            modifiedFields={modifiedFields}
            sharedProofsModifiedFields={sharedProofsModifiedFields}
            isShared={isShared}
          />
        </HeaderPortal>
      )}
    </Media>
  );
};
