/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Translation from '../../Text/Translation';
import CommentTooltipContent from '../CommentTooltipContent';
import UnstyledButton from '../../Button/UnstyledButton';
import css from './CommentTooltipNavigation';

const CommentTooltipNavigation = ({ comments, selectComment }) => {
  const [currentIndex, setCurrentIndex] = useState(() => {
    const selectedCommentIndex = comments.findIndex(comment => comment.$selected);
    return selectedCommentIndex === -1 ? 0 : selectedCommentIndex;
  });

  useEffect(() => {
    if (!comments[currentIndex].$selected) {
      selectComment(comments[currentIndex]);
    }
  }, [currentIndex]);

  const importMetadata = comments[currentIndex].sourceMetadata && comments[currentIndex].sourceMetadata.pdfImport && {
    type: 'pdf',
    author: comments[currentIndex].sourceMetadata.pdfImport.author,
  };

  return (
    <Fragment>
      <CommentTooltipContent
        commentData={comments[currentIndex]}
        isPdfImport={importMetadata && importMetadata.type === 'pdf'}
        name={importMetadata && importMetadata.author}
      />
      {comments.length > 1 && (
        <div className={css.CommentTooltipNavigation__navigate}>
          <UnstyledButton
            ariaLabel="Pagination button"
            className={css.CommentTooltipNavigation__navigate__arrowButton}
            onClick={() => setCurrentIndex(currentIndex === 0 ? comments.length - 1 : currentIndex - 1)}
          >
            &#8592;
          </UnstyledButton>
          <Translation
            value="proof.comment.tooltip.navigation"
            params={{ currentPage: currentIndex + 1, totalCount: comments.length }}
          />
          <UnstyledButton
            ariaLabel="Pagination button"
            className={css.CommentTooltipNavigation__navigate__arrowButton}
            onClick={() => setCurrentIndex(currentIndex === comments.length - 1 ? 0 : currentIndex + 1)}
          >
            &#8594;
          </UnstyledButton>
        </div>
      )}
    </Fragment>
  );
};

if (process.env.NODE_ENV !== 'production') {
  CommentTooltipNavigation.propTypes = {
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        $selected: PropTypes.bool,
        id: PropTypes.string,
        ownerId: PropTypes.string,
        ownerEmail: PropTypes.string,
        comment: PropTypes.string,
        decryptedComment: PropTypes.string,
      })
    ),
    selectComment: PropTypes.func,
  };
}

export default CommentTooltipNavigation;
