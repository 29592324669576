/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import canFinalize from './canFinalize';
import { addOrUpdateProofSetupValidation } from '../proofSetup';

export default function handleFinalizationValidation(proofSetup) {
  if (!canFinalize.hasProofs(proofSetup)) {
    addOrUpdateProofSetupValidation('noProofs');
  }
  if (!canFinalize.allProofsWithFilesHaveFileIds(proofSetup)) {
    addOrUpdateProofSetupValidation('awaitingFile');
  }
  if (!canFinalize.isWorkflowValid(proofSetup)) {
    addOrUpdateProofSetupValidation('workflowIsInvalid');
  }
  if (!canFinalize.hasOwner(proofSetup)) {
    addOrUpdateProofSetupValidation('noOwner');
  }
  if (!canFinalize.hasValidRecipient(proofSetup)) {
    addOrUpdateProofSetupValidation('invalidRecipient');
  }
}
