/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import MarkUnmarked from '../Icon/MarkUnmarked';
import MarkTodo from '../Icon/MarkTodo';
import MarkDone from '../Icon/MarkDone';

const icons = {
  unmarked: MarkUnmarked,
  todo: MarkTodo,
  done: MarkDone,
};

function Mark({
  type,
  ...props
}) {
  const Icon = icons[type] || icons.unmarked;
  return <Icon
    active
    {...props}
  />;
}

Mark.propTypes = {
  type: PropTypes.oneOf([
    'unmarked',
    'todo',
    'done',
  ]).isRequired,
  light: PropTypes.bool,
};

export default Mark;
