/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSize } from '../../hooks/useSize';
import css from './TextareaWithTools';
import Textarea from '../Textarea';

const TextareaWithTools = forwardRef(({ tools, ...textareaProps }, ref) => {
  const toolsRef = useRef();
  const size = useSize(toolsRef);

  return (
    <div className={css.TextareaWithTools}>
      <span
        className={css.TextareaWithTools__tools}
        ref={toolsRef}
      >
        {tools}
      </span>
      <Textarea
        {...textareaProps}
        ref={ref}
        style={{
          ...textareaProps.style,
          paddingRight: size && size.width,
        }}
      />
    </div>
  );
});

if (process.env.NODE_ENV !== 'production') {
  TextareaWithTools.propTypes = {
    tools: PropTypes.node,
    ...Textarea.propTypes,
  };
}

export default TextareaWithTools;
