/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classname from 'classname';
import css from './HeaderPortal.scss';

import useCustomLogo from '../../hooks/useCustomLogo';

const headerReactRoot = document.querySelector('#header-react-root');

const HeaderPortal = ({
  position = 'center-center', // or 'center-bottom-overlap'
  className,
  ...props,
  children,
}) => {
  const customLogo = useCustomLogo();

  const [element] = useState(() => {
    const element = document.createElement('div');
    headerReactRoot.appendChild(element);
    return element;
  });

  useEffect(() => {
    return () => {
      headerReactRoot.removeChild(element);
    };
  }, []);

  return ReactDOM.createPortal((
    <div
      className={classname(css.HeaderPortal, className, {
        [css['HeaderPortal--' + position]]: position,
        [css['HeaderPortal--customLogo']]: customLogo,
      })}
      {...props}
    >
      {children}
    </div>
  ), element);
};

if (process.env.NODE_ENV !== 'production') {
  HeaderPortal.propTypes = {
    position: PropTypes.oneOf(['center-middle', 'bottom-center-overlap', 'center-bottom-overlap', 'left-middle', 'right-middle']).isRequired,
  };
}

export default HeaderPortal;
