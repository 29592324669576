/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Tabs, Tab } from '../Tabs';
import { Translation } from '../Text';
import NotificationFilter from '../NotificationFilter/NotificationFilter';

const TABS = [
  {
    id: 'all',
    label: <Translation value="notifications.tab.all" />,
  },
  {
    id: 'attention-needed',
    label: <Translation value="notifications.tab.attention-needed" />,
  },
  {
    id: 'your-decisions',
    label: <Translation value="notifications.tab.your-decisions" />,
  },
];

function NotificationsTabs({
  selectedTab,
  onTabChange,
  filter,
  onFilterChange,
}) {
  return (
    <div className="NotificationsTabs">
      <Tabs selected={selectedTab}>
        {TABS.map(tab => (
          <Tab
            key={tab.id}
            id={tab.id}
            name={tab.label}
            onClick={() => onTabChange(tab.id)}
          />
        ))}
      </Tabs>
      <NotificationFilter
        onFilterChange={onFilterChange}
        filter={filter}
      />
    </div>
  );
}

export default NotificationsTabs;
