/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import FormControl from '../FormControl';
import {Validation, Translation} from '../Text';

class MultiEmailFormField extends Component {
  state = {
    hasBlurred: false,
  }

  onBlur = () => {
    this.setState({
      hasBlurred: true,
    });
  }

  render() {
    const {
      valid,
      message,
      compact,
      onChange,
      value,
      placeholder,
      inputRef,
    } = this.props;
    const {hasBlurred} = this.state;
    return (
      <FormControl
        compact={compact}
        message={!valid && hasBlurred && (
          <Validation>
            <Translation value={message} />
          </Validation>
        )}
      >
        <Input
          onChange={onChange}
          onBlur={this.onBlur}
          value={value}
          placeholder={placeholder}
          inputRef={inputRef}
          autoComplete="no"
        />
      </FormControl>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  MultiEmailFormField.propTypes = {
    valid: PropTypes.bool,
    message: PropTypes.string,
    compact: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    inputRef: PropTypes.func,
  };
}

export default MultiEmailFormField;
