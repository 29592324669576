function parseCookies() {
  return document.cookie.split(/;\s+/g)
    .map(c => c.split(/=/g))
    .reduce((obj, c) => ({...obj, [c[0]]: c.slice(1).join('=')}), {});
}

function setCookie(key, value, expiryDate = null) {
  let cookie = `${key}=${value || ''}; path=/`;
  if (expiryDate === null) {
    expiryDate = new Date('9999-01-01'); // eslint-disable-line no-param-reassign
  }
  cookie += `; expires=${expiryDate.toUTCString()}`;
  document.cookie = cookie;
}

const cookies = {
  // gets all the cookies
  all: () => parseCookies(),
  // gets just a single cookie
  get: (key, defaultValue) => parseCookies()[key] || defaultValue,
  // sets a single cookie
  set: (key, value, expiryDate) => setCookie(key, value, expiryDate),
};

export default cookies;
