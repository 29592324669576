/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {cloneElement} from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import filterSeparators from '../../util/filter-separators';
import Separator from './Separator';
import getChildrenArray from '../../util/children-arrays';
import css from './OptionList.scss';

function OptionList({
  variant,
  options,
  className,
  onClick,
  children,
  naked,
  wrap = true,
  ...props
}) {
  let addAdditionalCheckedSpace = 0;
  const childs = getChildrenArray(options || children).filter(Boolean).map((option) => {
    if (option && option.props && 'checked' in option.props) {
      addAdditionalCheckedSpace = Math.max(addAdditionalCheckedSpace, option.props.checkedIconWidth || 20);
    }
    return option;
  });
  return (
    <div
      {...props}
      className={classname(
        !naked && css.OptionList,
        !wrap && css.nowrap,
        className,
      )}
    >
      {filterSeparators(
        childs.map(option => (
          cloneElement(option, {
            _optionListVariant: variant,
            _optionListChecked: !!addAdditionalCheckedSpace,
            checkedIconWidth: addAdditionalCheckedSpace,
            onClick: (...args) => {
              let result;
              if (option.props.onClick) {
                result = option.props.onClick(...args);
              }
              if (onClick) {
                onClick(result);
              }
            },
          })
        )),
        Separator
      )}
    </div>
  );
}

if (process.env.NODE_ENV === 'development') {
  OptionList.propTypes = {
    options: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
    ]),
    onClick: PropTypes.func,
  };
}

export default OptionList;
