/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '@jmshal/react-inline-svg';
import InputOptions from '../ProofSetup/components/InputOptions';
import css from './ProofScreenMobileMenu.scss';
import { Translation } from '../Text';
import ProofDownloadButtonContainer from '../../containers/ProofDownloadButton';

const ProofScreenMobileMenu = props => (
  <div className={css.ProofScreenMobileMenu}>
    <InputOptions
      options={[
        <InputOptions.Option
          label={
            <div className={css.ProofScreenMobileMenu__option}>
              <InlineSVG src="img/interface/info.svg" />
              <span className={css.ProofScreenMobileMenu__option__label}>
                <Translation value="proof.screen.mobile.menu.option.info" />
              </span>
            </div>
          }
          onClick={() => props.onProofInfo()}
        />,
        <InputOptions.Option
          label={
            <div className={css.ProofScreenMobileMenu__option}>
              <InlineSVG src="img/interface/settings.svg" />
              <span className={css.ProofScreenMobileMenu__option__label}>
                <Translation value="proof.screen.mobile.menu.option.manage" />
              </span>
            </div>
          }
          onClick={() => props.onManageProof()}
        />,
        <ProofDownloadButtonContainer
          {...props.proofDownloadButtonProps}
          isMobile
        />,
      ]}
    />
  </div>
);

export default ProofScreenMobileMenu;
