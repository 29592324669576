/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { openModal } from '../../util/modal';
import FormControl from '../FormControl';
import Input from '../Input';
import { Subheading } from '../Page';
import Button from '../Button/Button';
import ButtonGroup from '../ButtonGroup';
import { Translation, Ellipsis } from '../Text';
import SpfRecords from '../SpfRecords';
import BlockMessage from '../BlockMessage';
import css from './TeamSenderEmail';

const TeamSenderEmail = ({ isFeatureAvailable, email, onSubmit }) => {
  const [teamSenderEmail, setTeamSenderEmail] = useState(email);
  const [isSaving, setSaving] = useState(false);
  const [spfValidationData, setSpfValidationData] = useState({ domain: '', records: [] });
  const [messageTranslationKey, setMessageTranslationKey] = useState(null);

  const saveCustomEmail = (event) => {
    event.preventDefault();

    setMessageTranslationKey(null);
    setSpfValidationData({ domain: '', records: [] });

    if (teamSenderEmail !== '' && !window.validateEmail(teamSenderEmail)) {
      setMessageTranslationKey('domain-admin.settings.sender-email.error.email-format');
      return;
    }

    setSaving(true);

    onSubmit(teamSenderEmail || null)
      .then(({ success, domain, email: updatedEmail, records }) => {
        setSaving(false);

        setSpfValidationData({
          domain,
          records: (success || !records) ? [] : records,
        });

        if (success) {
          if (teamSenderEmail === '') {
            setTeamSenderEmail(updatedEmail);
            setMessageTranslationKey('domain-admin.settings.sender-email.message.reset');
          } else {
            const { destroy } = openModal(
              <Fragment>
                <h3>
                  <Translation value="domain-admin.settings.sender-email.message.title" />
                </h3>
                <p>
                  <Translation value="domain-admin.settings.sender-email.message.body" />
                </p>
                <ButtonGroup>
                  <Button
                    type="button"
                    variant="primary"
                    label={<Translation value="button.ok-got-it" />}
                    onClick={() => destroy()}
                  />
                </ButtonGroup>
              </Fragment>
            );
          }
        }
      })
      .catch((err) => {
        const status = err && err.response && err.response.data && err.response.data.ResponseStatus;

        if (status === 'ERROR_INVALID_DOMAIN_NAME') {
          setMessageTranslationKey('domain-admin.settings.sender-email.error.invalid-domain');
        } else if (status === 'ERROR_TEAM_DOMAIN_REQUIRED') {
          setMessageTranslationKey('domain-admin.settings.sender-email.error.team-domain-required');
        } else if (status === 'ERROR_SPF_VERIFICATION_FAILED') {
          setMessageTranslationKey('domain-admin.settings.sender-email.error.spf-verification-failed');
        }

        setSaving(false);
      });
  };

  useEffect(() => {
    setTeamSenderEmail(email);
  }, [email]);

  useEffect(() => {
    setMessageTranslationKey(isFeatureAvailable ? null : 'domain-admin.enterprise-only.message');
  }, [isFeatureAvailable]);

  return (
    <form
      onSubmit={saveCustomEmail}
      noValidate
    >
      <Subheading
        title={<Translation value="domain-admin.settings.sender-email.heading" />}
        description={<Translation value="domain-admin.settings.sender-email.description" />}
        helpMessage={<Translation value="domain-admin.settings.sender-email.heading.tooltip" />}
        helpUrl="http://help.pageproof.com/en/articles/5831821-changing-the-sending-email-address-for-email-notifications"
      />
      {isFeatureAvailable && (
        <Fragment>
          <FormControl label={<Translation value="domain-admin.settings.sender-email.label" />}>
            <div>
              <Input
                type="email"
                variant="unified"
                value={teamSenderEmail}
                onChange={setTeamSenderEmail}
                required
              />
            </div>
            <Button
              type="submit"
              className={css.TeamSenderEmail__submitButton}
              autoWidth
              size="small"
              label={isSaving
                ? (
                  <Fragment>
                    <Translation value="button.save.active" />
                    <Ellipsis />
                  </Fragment>
                )
                : <Translation value="button.save" />
              }
            />
          </FormControl>
          {spfValidationData.records.length > 0 && (
            <SpfRecords
              domain={spfValidationData.domain}
              records={spfValidationData.records}
            />
          )}
        </Fragment>
      )}
      {messageTranslationKey && (
        <BlockMessage
          message={<Translation value={messageTranslationKey} />}
        />
      )}
    </form>
  );
};

if (process.env.NODE_ENV !== 'production') {
  TeamSenderEmail.propTypes = {
    isFeatureAvailable: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };
}

export default TeamSenderEmail;
