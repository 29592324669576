/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { PasswordIndicatorText } from '../../components/PasswordIndicator';
import Reveal from '../../components/Reveal';

const ProfilePasswordIndicatorText = ({ visible, ...rest }) => (
  <Reveal visible={visible}>
    <PasswordIndicatorText {...rest} />
  </Reveal>
);

if (process.env.NODE_ENV !== 'production') {
  ProfilePasswordIndicatorText.propTypes = {
    visible: PropTypes.bool.isRequired,
    ...PasswordIndicatorText.propTypes,
  };
}

export default ProfilePasswordIndicatorText;
