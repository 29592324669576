/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './Option.scss';

function Option({
  component: Component = 'div',
  label,
  subLabel,
  variant,
  selected,
  checked,
  checkedIcon,
  checkedIconWidth,
  _optionListChecked,
  _optionListVariant,
  disabled,
  readOnly,
  className,
  onClick,
  onHover,
  sticky,
  onMouseDown,
  onMouseLeave,
}) {
  const isChecked = checked || _optionListChecked;
  const checkMarkClass = checkedIcon ? 'Option__checked-icon' : 'Option__checked-mark';
  return (
    // eslint-disable-next-line
    <Component
      className={classname(css.Option, className, css['Option--' + (variant || _optionListVariant)], {
        [css['Option--disabled']]: disabled,
        [css['Option--selected']]: selected || (String(className).indexOf('js-active') !== -1),
        [css['Option--checked']]: isChecked,
        [css['Option--read-only']]: readOnly,
        [css['Option--sticky']]: sticky,
        [css['Option--subLabeled']]: subLabel,
      })}
      onClick={onClick}
      onMouseOver={onHover}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      tabIndex="0"
    >

      <div className={css.Option__label}>{label}</div>
      {subLabel &&
        <div className={css.Option__subLabel}>{subLabel}</div>
      }

      {isChecked &&
        <div
          className={css[checkMarkClass]}
          style={{ width: checkedIconWidth }}
        >
          {checked ? (checkedIcon || <Fragment>&#10003;</Fragment>) : null}
        </div>
      }
    </Component>
  );
}

if (process.env.NODE_ENV === 'development') {
  Option.propTypes = {
    label: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    subLabel: PropTypes.string,
    sticky: PropTypes.bool,
    onMouseLeave: PropTypes.func,
  };
}

export default Option;
