/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect } from 'react';
import { addExistingFile } from '../../components/ProofSetup/utils/addFile';
import requestProofSetup from '../../components/ProofSetup/utils/requestProofSetup';
import { sdk } from '../../util/sdk';
import getTagsFromFilename from '../../util/generate-initial-tags';

export default function ProofSetupByCEP({ initialRouteParams }) {
  useEffect(() => {
    const {
      name: inputName,
      file_id: fileId,
      previous_proof_id: previousProofId,
    } = initialRouteParams;

    // TODO: Handle if file.assignedTo is not null
    // TODO: Handle if the user does not own the file

    // TODO: Handle no fileId??

    Promise.all([
      sdk.files.details(fileId),
      previousProofId ? sdk.proofs.load(previousProofId) : undefined,
    ]).then(([file, proof]) => {
      let defaults = null;
      let attachNewVersion = null;
      let initialize = null;
      let addToCollection = null;
      const extensionPosition = file.name.lastIndexOf('.');
      const fileName = file.name.substring(0, extensionPosition);

      if (previousProofId) {
        defaults = {
          name: inputName || fileName,
          reference: proof.reference,
          tags: proof.tags,
          messageToReviewers: proof.messageToReviewers,
          canDownload: proof.canDownload,
          integrationReferences: proof.integrationReferences,
          isPublic: proof.isPublic,
          commentVisibility: proof.commentVisibility,
          dueDate: proof.usePreviousVersionDueDate ? proof.dueDate : undefined,
        };

        attachNewVersion = {
          proofId: proof.id,
          workflowId: proof.workflowId,
          collectionId: proof.groupId,
          canBePublic: proof.canBePublic,
          canAddChecklist: proof.canAddChecklist,
          availableWorkflowRoles: proof.availableWorkflowRoles,
          canHaveCommentVisibility: proof.canHaveCommentVisibility,
          dueDate: proof.dueDate,
        };

        initialize = {
          checklist: proof.checklist && {
            ...proof.checklist,
            id: undefined,
          },
          workflow: { id: proof.workflowId },
          owners: proof.owners.map(user => user.email),
        };
      } else {
        defaults = {
          name: inputName || fileName,
          tags: getTagsFromFilename(fileName),
        };
        addToCollection = true;
      }

      requestProofSetup(
        {
          parameters: {
            attachNewVersion,
            defaults,
            addToCollection,
          },
          initialize,
        },
        () => {
          addExistingFile(file);

          const { $location } = window.__pageproof_bridge__;

          $location.url('/create');
        }
      );
    });
  }, []);

  return null;
}
