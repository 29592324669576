/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * */
import { useUserPreferences } from './useUserPreferences';
import { getUserColorPreference } from '../util/user-color-preference';

const useUserPreferenceColors = () => {
  const [, userPreferences] = useUserPreferences();
  return getUserColorPreference(userPreferences);
};

export default useUserPreferenceColors;
