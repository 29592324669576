/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import getSDKInstance from '../../util/sdk';
import Progress from '../Progress';
import {goTo} from '../../util/location';
import generateInitialTags from '../../util/generate-initial-tags';
import { getUserFeatures } from '../../hooks/useUserFeatures';

class UploadBrief extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingProgress: 0,
      status: 0,
    };

    this.bridge = window.__pageproof_bridge__;

    this.client = getSDKInstance();

    if (props.brief.proofId && props.brief.isReupload) {
      this.updateBrief(props);
    } else {
      this.startCreateBrief(props);
    }
  }

  onUploadProgress = (progress) => {
    this.setState({
      uploadingProgress: progress
    });
  };

  getTotalProgress = () => {
    const {
      uploadingProgress,
    } = this.state;
    return Math.round(uploadingProgress / 2);
  };

  updateProgressManually = (progress) => {
    // There are 2 parts to this progress bar: uploading and processing. Both go to a maximum of 100 for a total of 200.
    // This value gets halved in getTotalProgress to determine the width of the encryption bar.
    // 100 below represents the uploading portion of the total progress, which is complete when this function runs.
    const uploadingProgress = 100 + progress;
    this.setState({uploadingProgress});
  };

  startCreateBrief = (props) => {
    let briefId = '';
    const {appService} = this.bridge;
    appService.showLoader();
    appService.showTranslatedLoaderMessage('loader-message.creating-brief');

    const {
      name,
      tag: tags,
      dueDate,
      $file,
    } = props.brief;

    const data = {
      name,
      tags: generateInitialTags(tags),
      dueDate: dueDate ? new Date(dueDate) : undefined,
      isPublic: getUserFeatures(false).defaultProofIsPublic,
    };

    const fileObject = {
      name: $file.name,
      contents: $file,
      download: true,
      onProgress: progress => this.onUploadProgress(progress),
    };

    return this.client.briefs.create(data)
      .then((_brief) => {
        briefId = _brief.id;
      })
      .then(() => this.client.files.upload(fileObject))
      .then((fileData) => {
        appService.hideLoader();
        this.state = {
          status: 0,
        };
        this.client.briefs.setFile(briefId, fileData.id);
      })
      .then(() => this.waitForFileToCompleteProgress(briefId))
      .catch(err => console.error(err));
  };

  updateBrief = (props) => {
    const {appService} = this.bridge;
    appService.showLoader();
    appService.showTranslatedLoaderMessage('loader-message.uploading-brief-file');

    const {
      proofId: briefId,
      name,
      tag: tags,
      dueDate,
      $file,
    } = props.brief;

    const data = {
      briefId,
      data: {
        title: name,
        tags: generateInitialTags(tags),
        dueDate: dueDate ? new Date(dueDate) : undefined,
      },
    };

    const fileObject = {
      name: $file.name,
      contents: $file,
      download: true,
      onProgress: progress => this.onUploadProgress(progress),
    };


    return this.client.briefs.update(data)
      .then(() => this.client.files.upload(fileObject))
      .then((fileData) => {
        appService.hideLoader();
        this.state = {
          status: 0,
        };
        this.client.briefs.setFile(briefId, fileData.id);
      })
      .then(() => this.waitForFileToCompleteProgress(briefId))
      .catch(err => console.error(err));
  };

  waitForFileToCompleteProgress = (briefId) => {
    this.client.proofs.load(briefId)
      .then((brief) => {
        this.updateProgress(brief.processStatus);
        if (brief.processStatus === 'rip_finished') {
          setTimeout(() => goTo('brief/static/' + briefId), 1000);
        } else {
          setTimeout(() => this.waitForFileToCompleteProgress(briefId), 1000);
        }
        return false;
      });
  };

  updateProgress = (processStatus) => {
    const statuses = {
      rip_queued: 5,
      rip_started: 10,
      rip_download_started: 15,
      rip_download_finished: 20,
      rip_decrypt_started: 25,
      rip_decrypt_finished: 30,
      rip_process_started: 40,
      rip_process_finished: 50,
      rip_encrypt_started: 60,
      rip_encrypt_finished: 70,
      rip_upload_started: 80,
      rip_upload_finished: 90,
      rip_success: 95,
      rip_finished: 100,
    };
    if (statuses[processStatus]) {
      this.updateProgressManually(statuses[processStatus]);
    }
    let status;
    if (processStatus === 'rip_finished') {
      status = 2;
    } else if (statuses[processStatus]) {
      status = 1;
    } else {
      status = 0;
    }
    if (status > this.state.status) {
      this.setState({status});
    }
  };

  render() {
    const {
      status,
    } = this.state;

    const progress = this.getTotalProgress();

    return (
      <Progress
        progress={progress}
        total={1}
        type="brief-upload"
        status={status}
      />
    );
  }
}

export default UploadBrief;
