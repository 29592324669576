/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import css from './RevealingInput.scss';
import Suggestions from '../Suggestions';
import GreyCircleIconButton from '../GreyCircleIconButton';
import RevealTextHorizontal from '../ProofSetup/components/RevealTextHorizontal';
import ExpandingControl from '../ExpandingControl';
import commaSeparatedEmails from '../../util/commaSeparatedEmails';
import { useText } from '../Text';
import Delay from '../Delay';
import { useIsWithinTabs } from '../Tabs/useIsWithinTabs';

const RevealingEmailInput = ({ exclusions, onAdd, onAddBatch }) => {
  const text = useText();
  const inputRef = useRef();
  const [value, setValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const isWithinTabs = useIsWithinTabs();

  const add = (event) => {
    const emails = commaSeparatedEmails(value);
    if (emails.length) {
      if (onAddBatch) {
        onAddBatch(emails);
      } else if (onAdd) {
        emails.forEach(email => onAdd(email));
      }
      event.preventDefault();
      inputRef.current.value = '';
      setValue('');
      event.target.focus();
      return true;
    }
    return false;
  };

  return (
    <div className={css.RevealingInput}>
      <GreyCircleIconButton
        iconSrc="/img/icons/plus-3.svg"
        onClick={() => {
          if (!isVisible) {
            setIsVisible(true);
          }
          inputRef.current.focus();
        }}
      />
      <Delay ms={isWithinTabs ? 350 : 0}>
        <RevealTextHorizontal show={isVisible}>
          <ExpandingControl
            control={
              <Suggestions
                inputRef={inputRef}
                onSelect={(email) => {
                  if (onAdd) {
                    onAdd(email);
                  } else if (onAddBatch) {
                    onAddBatch([email]);
                  }
                  inputRef.current.value = '';
                  setValue('');
                }}
                exclusions={exclusions}
              >
                <input
                  type="text"
                  value={value}
                  onChange={(event) => {
                    setValue(event.target.value);
                  }}
                  onBlur={(event) => {
                    if (!add(event)) {
                      setIsVisible(false);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      add(event);
                    }
                  }}
                  className={css.RevealingInput__input}
                  placeholder={text('default.placeholder.enter-an-email-address')}
                />
              </Suggestions>
            }
          />
        </RevealTextHorizontal>
      </Delay>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  RevealingEmailInput.propTypes = {
    exclusions: PropTypes.arrayOf(PropTypes.string),
    onAdd: PropTypes.func,
    onAddBatch: PropTypes.func,
  };
}

export default RevealingEmailInput;
