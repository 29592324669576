import { useMemo } from 'react';

const useSectionList = (arr) => {
  const sectionList = useMemo(() => {
    const alphabetObj = {};

    arr.sort().forEach((email) => {
      const firstLetter = email.split('')[0].toLowerCase();

      if (alphabetObj[firstLetter]) {
        return alphabetObj[firstLetter].push(email);
      } else {
        return alphabetObj[firstLetter] = [email];
      }
    });

    return Object.entries(alphabetObj).map(([key, value]) => ({
      label: key,
      items: value,
    }));
  }, [arr]);

  return sectionList;
};

export default useSectionList;
