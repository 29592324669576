import { sdk } from '../util/sdk';

export function dumpBrokenProofDiagnosticInformation(proofId) {
  if (!sdk.session) {
    return { message: 'Session is missing from SDK.' };
  }

  if (!sdk.session.privateKeyPEM) {
    return { message: 'Private key is missing from SDK session.' };
  }

  const diagnosticInformation = {};

  diagnosticInformation.currentLocalTime = new Date().toISOString();
  diagnosticInformation.currentServerTime = window.fetch('/debug/server-time').then(response => new Date(response.text()).toISOString());

  diagnosticInformation.browserExtensionInfo = window.detectExtension ? window.detectExtension() : false;

  diagnosticInformation.cdnTrace = window.fetch('/cdn-cgi/trace').then(response => response.json());

  const { userId, user, publicKeyPEM } = sdk.session;

  diagnosticInformation.userInfo = {
    id: userId,
    email: user.email,
    hasActivated: user.hasActivated,
    isAdmin: user.isAdmin,
    teamId: user.teamId,
    teamType: user.teamType,
    features: user.features,
    publicKeyPEM,
  };

  const cachedThumbnailImages = window.__pageproof_bridge__.temporaryStorageService.get({
    proofId,
    userId,
    publicKey: sdk.session.serverPublicKeyPEM,
    _: 'thumbnail-images',
  });
  diagnosticInformation.cachedThumbnailImages = cachedThumbnailImages;

  const latestThumbnailImages = sdk.proofs.images(proofId);
  diagnosticInformation.latestThumbnailImages = latestThumbnailImages;

  diagnosticInformation.canDownloadCachedThumbnailImage = cachedThumbnailImages.then(images => !!images && attemptDownloadThumbnailImage(images));
  diagnosticInformation.canDownloadLatestThumbnailImage = latestThumbnailImages.then(images => attemptDownloadThumbnailImage(images));

  diagnosticInformation.canUnsealCachedEnvelope = cachedThumbnailImages.then(images => !!images && attemptEnvelopeUnseal(images.envelope.envelope));
  diagnosticInformation.canUnsealLatestEnvelope = latestThumbnailImages.then(images => attemptEnvelopeUnseal(images.envelope.envelope));

  diagnosticInformation.storageEstimate = window.navigator.storage && window.navigator.storage.estimate();
  diagnosticInformation.localStorageSize = calculateLocalStorageSize();

  return Promise.all(
    Object.entries(diagnosticInformation).map(([key, value]) => {
      const startTime = window.performance.now();
      return Promise.resolve(value)
        .catch(err => ({ error: true, message: err.message, stack: err.stack }))
        .then(result => ({ key, value: result, duration: window.performance.now() - startTime }));
    })
  ).then(results => results.reduce((accumulated, result) => ({
    ...accumulated,
    duration: {
      ...accumulated.duration,
      [result.key]: result.duration,
    },
    [result.key]: result.value,
  }), {}));
}

function attemptEnvelopeUnseal(envelope) {
  return sdk.crypto().envelopeUnseal(envelope, sdk.keyPairs.getPrivateKeyPEMs())
    .then(() => true);
}

function calculateLocalStorageSize() {
  let total = 0;
  for (let index = 0; index < window.localStorage.length; index += 1) {
    const key = window.localStorage.key(index);
    total += new window.Blob([window.localStorage.getItem(key)]).size;
  }
  return total;
}

function attemptDownloadThumbnailImage(images) {
  let url;
  if (images.images && images.images.pages) {
    images.images.pages.some((page) => {
      if (page.low && page.low.url) {
        url = page.low.url;
        return true;
      } else if (page.high && page.high.url) {
        url = page.high.url;
        return true;
      }
      return false;
    });
  }
  if (!url) {
    if (images.images.thumbnail && images.images.thumbnail.url) {
      url = images.images.thumbnail.url;
    }
  }
  if (!url) {
    if (images.images.sprite && images.images.sprite.url) {
      url = images.images.sprite.url;
    }
  }
  if (!url) {
    return { message: 'No URLs found in images object.' };
  }
  return window.fetch(url)
    .then(response => ({
      url,
      ok: response.ok,
      size: +response.headers.get('content-length'),
    }));
}
