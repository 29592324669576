/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect } from 'react';
import { addEmail, addExternalFile, addWebURL } from '../../components/ProofSetup/utils/addFile';
import requestProofSetup from '../../components/ProofSetup/utils/requestProofSetup';

export default function ProofSetupByExternal({ initialRouteParams }) {
  useEffect(() => {
    const {
      name,
      file_name: fileName,
      file_url: fileUrl,
      website_url: websiteUrl,
      due_date: dueDate,
      create_email: shouldCreateEmail,
      message_to_reviewers: messageToReviewers,
      reference_id: referenceId,
      reference_label: referenceLabel,
      reference_url: referenceUrl,
      reference_type: referenceType,
      reference_metadata: referenceMetadata,
    } = initialRouteParams;

    const defaults = {
      name,
      messageToReviewers,
    };

    const file = fileUrl
      ? {
        url: fileUrl,
        name: fileName,
      }
      : undefined;

    if (!Number.isNaN(+dueDate)) {
      defaults.dueDate = new Date(+dueDate);
    }

    // TODO: validate referenceType and support lowered referenceType
    if (referenceId && referenceLabel && referenceType && referenceUrl) {
      defaults.integrationReferences = [{
        referenceId,
        referenceType,
        referenceLabel,
        referenceUrl,
        metadata: referenceMetadata,
      }];
    }

    requestProofSetup({
      parameters: {
        defaults,
        file,
      },
    }, () => {
      if (file) {
        addExternalFile(undefined, file.url, file.name, undefined, undefined, true);
      }

      if (shouldCreateEmail === 'true') {
        addEmail();
      }

      if (websiteUrl) {
        addWebURL(websiteUrl);
      }

      const { $location } = window.__pageproof_bridge__;
      $location.url('/create');
    });
  }, []);

  return null;
}
