/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Translation from '../../Text/Translation';
import Comment from '../Comment';

function CreateReply({
  ...props,
}) {
  return (
    <Comment
      {...props}
      user={null}
      placeholder={
        <Translation value="comment-reply-create.placeholder" />
      }
      isEditing={props.replyEditorVisible}
      isCreate
      isReply
    />
  );
}

export default CreateReply;
