/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown, {
  Option as DropdownOption,
  OptionLabel as DropdownOptionLabel,
} from '../Dropdown';
import Text from '../Text/Translation';

function DomainAdminNavigationDropdown({
  selectedTab,
  tabs,
  onClick,
}) {
  const selectedTabObject = tabs.filter(tab => tab.id === selectedTab)[0];
  return (
    <div className="navigation__mobile">
      <Dropdown
        selected={selectedTab}
        variant="light"
        placeholder={
          <DropdownOption
            label={(
              <span>
                <Text value={selectedTabObject.label} />
                {selectedTabObject.users && (
                  <span className="dashboard__tab__proof-count">
                    {selectedTabObject.users.length || ''}
                  </span>
                )}
              </span>
            )}
          />
        }
      >
        {tabs.map(tab => (
          <DropdownOption
            key={tab.id}
            onClick={() => onClick(tab.id)}
            selected={selectedTab === tab.id}
            label={
              <DropdownOptionLabel
                title={(
                  <div className="dashboard__tab__label">
                    <Text value={tab.label} />
                    {tab.users && (
                      <span className="dashboard__tab__proof-count">
                        {tab.users.length || ''}
                      </span>
                    )}
                  </div>
                )}
              />
            }
          />
        ))}
      </Dropdown>
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  DomainAdminNavigationDropdown.propTypes = {
    selectedTab: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func.isRequired,
  };
}

export default DomainAdminNavigationDropdown;
