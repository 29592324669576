/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { createContext, useMemo, useEffect, useState } from 'react';
import i18n from '../../util/i18n';

export const I18nContext = createContext({
  locale: 'en-US',
  translations: {},
});

export const I18nProvider = ({ locale: customLocale, children }) => {
  const [locale, setLocale] = useState(customLocale || i18n.getLocale);
  const [translations, setTranslations] = useState({});

  // If the `locale` prop is provided, we use it instead of the i18n locale, so we should avoid creating
  // any i18n event listeners - because the changes to the user's locale will be ignored anyway.
  // Because of this, we may not always return a cleanup callback from useEffect.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!customLocale) {
      const updateLocale = () => setLocale(i18n.getLocale());
      i18n.on('did-change-locale', updateLocale);
      return () => i18n.off('did-change-locale', updateLocale);
    }
  }, []);

  useEffect(() => {
    i18n.getTranslations(locale)
      .then(values => setTranslations(values));
  }, [locale]);

  const context = useMemo(() => ({
    locale,
    translations: {
      ...i18n.getFallbackTranslations(),
      ...translations,
    },
  }), [locale, translations]);

  return (
    <I18nContext.Provider value={context}>
      {children}
    </I18nContext.Provider>
  );
};
