/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './VideoPlayerBuffer.scss';

export const VideoPlayerBuffer = ({ buffer }) => (
  <div className={css.VideoPlayerBuffer}>
    {buffer.map(({ left, width }, index) => (
      <div
        key={index}
        className={css.VideoPlayerBuffer__buffer}
        style={{ left, width }}
      />
    ))}
  </div>
);
