/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';
import shadowContainer from '../../util/shadow-container';

class ExpandingControl extends Component {
  componentDidMount() {
    this.element = findDOMNode(this);
    this.shadow = document.createElement('span');
    shadowContainer.appendChild(this.shadow);
    this.style = window.getComputedStyle(this.element);

    if (this.props.initialWidth) {
      this.element.style.width = this.props.initialWidth + 'px';
    } else {
      this.calculateDimensions();
    }
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(props) {
    if (this.previousValue !== this.getControlValue()) {
      this.calculateDimensions(props);
    }
  }

  componentWillUnmount() {
    shadowContainer.removeChild(this.shadow);
    this.element = this.shadow = this.style = null;
  }

  getControlValue() {
    return this.element.value || this.element.placeholder;
  }

  prepareShadow() {
    const {shadow, style} = this;
    Object.assign(shadow.style, {
      fontSize: style.fontSize,
      fontWeight: style.fontWeight,
      fontStyle: style.fontStyle,
      letterSpacing: style.letterSpacing,
      whiteSpace: 'nowrap',
      wordSpacing: '5px',
    });
  }

  calculateDimensions() {
    const CURSOR_WIDTH = 1;
    this.prepareShadow();
    const value = this.previousValue = this.getControlValue();
    const {shadow} = this;
    shadow.textContent = value;
    const width = shadow.offsetWidth + CURSOR_WIDTH;
    this.element.style.width = (width || this.props.initialWidth) + 'px';
  }

  render() {
    return React.Children.only(this.props.control);
  }
}

ExpandingControl.propTypes = {
  control: PropTypes.node.isRequired,
  initialWidth: PropTypes.number,
};

export default ExpandingControl;
