/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';
import Translation from '../Text/Translation';
import styles from './RoleDot.scss';

function RoleDot({ userRole, tooltipMessage }) {
  return (
    <Tooltip
      title={(<Translation value={tooltipMessage || `role-dot.tooltip.${userRole}`} />)}
      up
      center
      delay={0}
    >
      <div className={styles.RoleDot}>
        <Translation value={`role-dot.role.${userRole}`} />
      </div>
    </Tooltip>
  );
}

RoleDot.defaultProps = {
  tooltipMessage: '',
};

RoleDot.propTypes = {
  userRole: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string,
};

export default RoleDot;
