/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useRef } from 'react';
import ActionMessageModal from '../../../ActionMessageModal';
import ButtonGroup from '../../../ButtonGroup';
import Button from '../../../Button/Button';
import { openModal } from '../../../../util/modal';
import {
  Option as DropdownOption,
  Separator as DropdownSeparator,
  OptionLabel as DropdownOptionLabel,
} from '../../../Dropdown';

import Translation from '../../../Text/Translation';
import { Search } from '../../../Search';
import { TranslatedProps } from '../../../Text';
import css from './ProofTemplates.scss';
import { ProofTemplatesDropdown } from './ProofTemplatesDropdown';
import { ProofTemplatesPopover } from './ProofTemplatesPopover';

export const ProofTemplates = ({
  currentProofTemplateId,
  variant,
  selectedProofTemplateName,
  filteredProofTemplates,
  setSearchTerm,
  isLoading,
  onSelect,
  canSearch,
  searchTerm,
  onReset,
  proofSetupState,
  modifiedFields,
  sharedProofsModifiedFields,
  isShared,
}) => {
  const popoverRef = useRef();

  const handleOnClick = (onConfirm, action) => {
    if (popoverRef.current) {
      popoverRef.current.hide();
    }

    if (modifiedFields.length) {
      const { destroy: close } = openModal(
        <ActionMessageModal
          title={`${action} proof template?`}
          message={
            <div className={css.ProofTemplates__modal__container}>
              <div className={css.ProofTemplates__modal__separator} />
              Any changes you have made since applying the previous template will be lost.
              <Fragment>
                <div style={{ height: 20 }} />
                <strong>{isShared ? 'Changes in the shared setup:' : 'Changes:'}</strong>
                <div style={{ height: 4 }} />
                {modifiedFields.map(field => (
                  <li key={field}>{field}</li>
                ))}
                {sharedProofsModifiedFields.length > 0 && (
                  <Fragment>
                    <div className={css.ProofTemplates__modal__separator} />
                    <strong>Changes in one of the proofs:</strong>
                  </Fragment>
                )}
                {sharedProofsModifiedFields.map(({ proofIndex, proofName, fields }) => (
                  <Fragment key={proofIndex}>
                    <div className={css.ProofTemplates__modal__separator} />
                    {`Proof ${proofIndex}: ${proofName}`}
                    {fields.map(field => (
                      <li key={field}>{field}</li>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            </div>
          }
          action={
            <ButtonGroup align="center">
              <Button
                variant="text"
                label={<Translation value="button.cancel" />}
                onClick={() => close()}
              />
              <Button
                variant="primary"
                label={action}
                onClick={() => {
                  onConfirm();
                  close();
                }}
              />
            </ButtonGroup>
          }
        />,
        null,
        true
      );
      return;
    }
    onConfirm();
  };

  const proofTemplatesList = (
    <Fragment>
      {proofSetupState.currentProofTemplateId && (
        <Fragment>
          <DropdownOption
            value={-2}
            label={
              <DropdownOptionLabel
                title={<Translation value="proof.setup.proof-template.chooser.no-proof-template" />}
                description={<Translation value="proof.setup.proof-template.chooser.no-proof-template.description" />}
              />
            }
            onClick={() => handleOnClick(onReset, 'Remove')}
          />
          <DropdownSeparator fullWidth />
        </Fragment>
      )}
      {canSearch && (
        <div className={css.ProofTemplates__searchHolder}>
          <TranslatedProps placeholder="workflow.search.placeholder">
            <Search
              searchTerm={searchTerm}
              onSearch={setSearchTerm}
              onClearSearch={() => setSearchTerm()}
              theme="grey"
              simple
              autoFocus
            />
          </TranslatedProps>
        </div>
      )}
      {!filteredProofTemplates.length && (
        <Fragment>
          {!searchTerm && (
            <div className={css.ProofTemplates__templateHeader}>
              <Translation value="workflow.dashboard.my-templates" />
            </div>
          )}
          <div className={css.ProofTemplates__none}>
            {!searchTerm
              ? <Translation value="proof.setup.proof-template.chooser.none-workflow" />
              : <Translation value="proof.setup.proof-template.chooser.no-templates-match" />
            }
          </div>
        </Fragment>
      )}
      {filteredProofTemplates.map(template => (
        <DropdownOption
          key={template.id}
          label={template.name}
          value={template.id}
          selected={currentProofTemplateId === template.id}
          onClick={() => {
            handleOnClick(() => onSelect(template.id), 'Replace');
          }}
        />
      ))}
    </Fragment>
  );

  if (variant === 'popover') {
    return (
      <div style={{ position: 'relative', left: '-10px' }}>
        <ProofTemplatesPopover
          isLoading={isLoading}
          ref={popoverRef}
        >
          {proofTemplatesList}
        </ProofTemplatesPopover>
      </div>
    );
  }

  return (
    <ProofTemplatesDropdown
      selectedProofTemplateName={selectedProofTemplateName}
      isLoading={isLoading}
      currentProofTemplateId={currentProofTemplateId}
      modifiedFields={modifiedFields}
      sharedProofsModifiedFields={sharedProofsModifiedFields}
      isShared={isShared}
    >
      {proofTemplatesList}
    </ProofTemplatesDropdown>
  );
};
