/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';

function insertBetweenItems(arr, insert) {
  const mapped = new Array(arr.length + Math.max(0, arr.length - 1));
  for (let i = 0; i < arr.length; i += 1) {
    mapped[i * 2] = arr[i];
    if (i + 1 < arr.length) {
      mapped[(i * 2) + 1] = React.cloneElement(insert, { key: `insertBetweenItems Arr[${i}]` });
    }
  }
  return mapped;
}

export default insertBetweenItems;
