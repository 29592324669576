/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

const buttonTypes = {
  unstyled: [],
  primary: [
    'app__btn--green',
    'app__btn--white-text',
    'app__btn--hover--green',
    'app__btn--hover--white-text',
  ],
  outline: [
    'app__btn--green-outline',
    'app__btn--green-text',
    'app__btn--hover--green',
    'app__btn--hover--white-text',
  ],
  outlineGrey: [
    'app__btn--grey-outline',
    'app__btn--grey-text',
    'app__btn--hover--grey',
    'app__btn--hover--white-text',
  ],
  overColor: [
    'app__btn--white-text',
    'app__btn--hover--white',
    'app__btn--hover--green-text',
  ],
  text: [
    'app__btn--green-text',
    'app__btn--hover--dark-green-text',
    'app__btn--no-shadow',
  ],
  orange: [
    'app__btn--orange',
    'app__btn--white-text',
    'app__btn--hover--orange',
    'app__btn--hover--white-text',
  ],
  'orange-text': [
    'app__btn--orange-text',
    'app__btn--hover--orange-text',
    'app__btn--no-shadow',
  ],
  danger: [
    'app__btn--red',
    'app__btn--white-text',
    'app__btn--hover--red',
    'app__btn--hover--white-text',
  ],
  'red-text': [
    'app__btn--red-text',
    'app__btn--hover--dark-red-text',
    'app__btn--no-shadow',
  ],
  icon: [
    'app__btn--green-text',
    'app__btn--no-shadow',
    'app__btn--auto-width',
  ],
  roundLightGreen: [
    'app__btn--round-light-green',
    'app__btn--no-shadow',
    'app__btn--auto-width',
  ],
};

function Button({
  component: Component,
  variant,
  label,
  active,
  autoWidth,
  size,
  ...props
}) {
  return (
    <Component
      {...props}
      tabIndex="0"
      className={classname('app__btn', buttonTypes[variant], `app__btn--${size}`, {
        'app__btn--hover': active,
        'app__btn--auto-width': autoWidth,
        [`app__btn--${size}`]: !!size,
      }, props.className)}
    >
      {label}
    </Component>
  );
}

Button.defaultProps = {
  component: 'button',
  variant: 'primary',
  autoWidth: false,
};

if (process.env.NODE_ENV !== 'production') {
  Button.propTypes = {
    component: PropTypes.any, // eslint-disable-line
    variant: PropTypes.string,
    label: PropTypes.node,
    active: PropTypes.bool,
    autoWidth: PropTypes.bool,
    size: PropTypes.string,
  };
}

export default Button;
