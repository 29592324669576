/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment} from 'react';
import classname from 'classname';
import {InlineSVG} from '@jmshal/react-inline-svg';
import Popover from '../Popover';
import Avatar from '../../containers/NextAvatar';
import {OptionList, Option, Separator} from '../PopupMenu';
import css from './UserList.scss';

/*

<UserList
  groups={[
    {
      id: 'approved',
      name: 'Approved',
      onClick() { },
      actions: [
        {
          id: 'nudge-all',
          name: 'Nudge all',
          onClick() { },
        },
      ],
      users: [
        {
          id: '',
          name: 'jacob@pageproof.com',
          onClick() { },
          actions: [
            {
              id: 'nudge',
              name: 'Nudge',
              onClick() { },
            },
            {
              id: 'skip',
              name: 'Skip',
              onClick() { },
            },
          ],
        },
      ],
    },
  ]}
  onGroupAction={(group, action) => {
    console.log('group action', action.id);
  }}
  onUserAction={(group, user, action) => {
    console.log('user action', user.id, action.id);
  }}
/>

*/

function ActionsPopover({
  actions,
  onAction,
  children,
}) {
  const hasActions = !!(actions && actions.length);
  return (
    <Popover
      content={popover => (
        <OptionList>
          {actions.map((action, index) => (
            action
              ? <Option
                key={action.id || index}
                label={action.name || action.label}
                onClick={() => {
                  popover.hide();
                  onAction(action);
                }}
              />
              : <Separator />
          ))}
        </OptionList>
      )}
      right
      middle
      down
      offset={-6}
      disabled={!hasActions}
    >
      {children}
    </Popover>
  );
}

const actionsIcon = (
  <InlineSVG
    className={css.UserList__actionsIcon}
    width={24}
    height={24}
    src="img/content/proof/icons/metadata/more.svg"
  />
);

function UserList({
  variant = 'light',
  groups,
  onGroupAction,
  onGroupClick,
  onUserAction,
  onUserClick,
}) {
  return (
    <OptionList
      variant={variant}
      className={classname(css.UserList, css['UserList--' + variant])}
      naked
    >
      {groups.map((group, index) => {
        const hasGroupActions = group.actions && group.actions.length;
        return (
          <Fragment key={group.id || index}>
            {!!(group.name || group.label) && (
              <ActionsPopover
                actions={group.actions}
                onAction={(action) => {
                  if (onGroupAction) onGroupAction(group, action);
                  if (action.onClick) action.onClick(group, action);
                }}
              >
                <Option
                  variant={variant}
                  onClick={() => {
                    if (onGroupClick) onGroupClick(group);
                    if (group.onClick) group.onClick(group);
                  }}
                  label={group.name || group.label}
                  checked={hasGroupActions}
                  checkedIcon={actionsIcon}
                  checkedIconWidth={24}
                  readOnly={!hasGroupActions && !onGroupClick}
                  className={css.UserList__heading}
                />
              </ActionsPopover>
            )}
            {group.users.map((user, userIndex) => {
              const hasActions = !!(user.actions && user.actions.length);
              return (
                <ActionsPopover
                  key={user.id || userIndex}
                  actions={user.actions}
                  onAction={(action) => {
                    if (onUserAction) onUserAction(group, user, action);
                    if (action.onClick) action.onClick(group, user, action);
                  }}
                >
                  <Option
                    variant={variant}
                    onClick={() => {
                      if (onUserClick) onUserClick(group, user);
                      if (user.onClick) user.onClick(group, user);
                    }}
                    label={
                      <div className={css.UserList__user}>
                        <div className={css.UserList__user__label}>
                          <div className={css.UserList__user__label__avatar}>
                            {user.avatar || <Avatar id={user.id} size={20} active />}
                          </div>
                          <div className={css.UserList__user__label__name}>
                            {user.name}
                          </div>
                        </div>
                      </div>
                    }
                    checked={hasActions}
                    checkedIcon={actionsIcon}
                    checkedIconWidth={24}
                    readOnly={!hasActions && !onUserClick}
                    className={css.UserList__option}
                  />
                </ActionsPopover>
              );
            })}
          </Fragment>
        );
      })}
    </OptionList>
  );
}

export default UserList;
