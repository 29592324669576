export default function commaSeparatedEmails(value, limit) {
  const emails = value
    .toLowerCase()
    .split(',')
    .map(email => email.trim())
    .filter(email => window.validateEmail(email))
    .filter(Boolean);

  if (typeof limit === 'number') {
    return emails.slice(0, limit);
  }

  return emails;
}

export function generateAvatarOptions(user) {
  const email = user.email.substring(0, user.email.indexOf('@'));
  const [, avatarFirst, , avatarSecond] = email.match(/(\w)(.*[.\s](\w))*.*/);
  let avatarData = avatarFirst + (avatarSecond || '');

  let avatarOption = 'initials';
  if (user.hasAvatar) {
    avatarData = user.id;
    avatarOption = 'avatar';
  } else if (!user.hasRegistered) {
    avatarOption = '-';
    avatarData = user.email;
  }

  return { avatarData, avatarOption };
}
