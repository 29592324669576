/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Component } from 'react';
import Option from './Option';
import SideBySide from '../Transitions/SideBySide';

class ConfirmOption extends Component {
  state = {
    showConfirm: false,
  };

  // eslint-disable-next-line consistent-return
  handleClick = () => {
    if (this.state.showConfirm) {
      this.props.onClick();
    }
    this.setState(({ showConfirm }) => ({
      showConfirm: !showConfirm,
    }));
  }

  render() {
    return (
      <SideBySide
        reverse={false}
        id={this.state.showConfirm}
        transitionDuration={180}
      >
        <Option
          label={this.state.showConfirm
            ? this.props.confirmLabel
            : this.props.label
          }
          disabled={this.props.disabled}
          onClick={(event) => {
            event.stopPropagation();

            if (!this.props.disabled) {
              this.handleClick();
            }
          }}
        />
      </SideBySide>
    );
  }
}

export default ConfirmOption;
