/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-multi-spaces */

import React, { Fragment, useEffect, useState, useRef } from 'react';
import Portal from '../Portal';
import { EncryptedSpriteThumbnail } from '../Encrypted/EncryptedSpriteThumbnail';
import { EncryptedColorSeparation } from '../Encrypted/EncryptedColorSeparation';
import { EncryptedHighPreview } from '../Encrypted/EncryptedHighPreview';
import { EncryptedLowPreview } from '../Encrypted/EncryptedLowPreview';
import { Flex } from '../Flex';
import Spinner from '../Spinner/Spinner';
import Reveal from '../Reveal';
import { sdk } from '../../util/sdk';
import PlateOption from './PlateOption';
import css from './ColorSeparationsPreview.scss';
import Switch from '../Switch';
import HelpBubble from '../HelpBubble';
import Translation from '../Text/Translation';
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut';
import { useLocalStorage } from '../LocalStorage';
import useDarkMode from '../../hooks/useDarkMode';
import HexColor from './HexColor';
import ExitModeButton from '../Button/ExitModeButton';
import PlateCoverageLayer from './PlateCoverageLayer';
import { DraggableCanvas } from '../DraggableCanvas';
import ZoomSlider from '../ZoomSlider';
import { RaisedSurface } from '../RaisedSurface';

const thumbnailMultiplier = 1.3;

const getPlatesInPriorityOrder = (plates) => {
  const ordered = {
    Cyan: null,
    Magenta: null,
    Yellow: null,
    Black: null,
  };

  plates.forEach((plate) => {
    ordered[plate.name] = plate;
  });

  return Object.values(ordered);
};

const ColorSeparationsPreview = ({ proofId, initialPageNumber = 1, fileId, onClose }) => {
  const [zoom, setZoom] = useState(1);
  const canvasRef = useRef();
  const [isDragging, setDragging] = useState(false);

  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [selectedPlates, setSelectedPlates] = useState([]);

  const [compositeImages, setCompositeImages] = useState(null);
  const [colorSeparations, setColorSeparations] = useState(null);

  const [enableColors, setEnableColors] = useLocalStorage('pageproof.app.color-separations.enable-colors', false);
  const [customColors, setCustomColors] = useLocalStorage('pageproof.app.color-separations.custom-colors', {});

  const [isDarkMode] = useDarkMode();

  const isMac = window.__pageproof_bridge__.browserService.os === 'mac';

  let currentPage = null;
  let currentPagePlates = [];
  let selectedPlateNames = {};

  if (colorSeparations) {
    currentPage = colorSeparations.pages.find(page => page.number === pageNumber);
    currentPagePlates = getPlatesInPriorityOrder(currentPage.plates);
    selectedPlateNames = selectedPlates.reduce((names, plate) => ({ ...names, [plate]: true }), {});
  }

  useEffect(() => {
    sdk.proofs.images(proofId)
      .then(result => setCompositeImages(result));

    sdk.proofs.assets.colorSeparations.resources(proofId)
      .then(result => setColorSeparations(result));
  }, [proofId]);

  const close = () => {
    onClose(pageNumber);
  };

  const nextPage = () => {
    const lastPageNumber = colorSeparations.pages[colorSeparations.pages.length - 1].number;
    if (pageNumber < lastPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const previousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const toggleAllPlates = () => {
    if (selectedPlates.length === currentPagePlates.length) {
      setSelectedPlates([]);
    } else {
      setSelectedPlates(currentPagePlates.map(plate => plate.name));
    }
  };

  const getPlateAtOffset = (indexOffset) => {
    if (selectedPlates.length > 1) return null;
    const page = colorSeparations.pages.find(_page => _page.number === pageNumber);
    const pagePlates = getPlatesInPriorityOrder(page.plates);
    const currentPlateIndex = pagePlates.findIndex(plate => plate.name === selectedPlates[0]);
    return pagePlates[currentPlateIndex + indexOffset];
  };

  const nextPlate = () => {
    const plate = getPlateAtOffset(+1);
    if (plate) {
      setSelectedPlates([plate.name]);
    }
  };

  const previousPlate = () => {
    const plate = getPlateAtOffset(-1);
    if (plate) {
      setSelectedPlates([plate.name]);
    } else if (selectedPlates.length) {
      setSelectedPlates([]);
    }
  };

  // type can be any of the following: 'set', 'add', or 'range'
  const onSelectPlate = (type, name) => {
    const isSelected = selectedPlateNames[name];

    // Disabling eslint here because our rules have a weird config for indentation around switch statements
    /* eslint-disable indent */
    switch (type) {
      case 'add': {
        if (isSelected) {
          const newSelectedPlates = [...selectedPlates];
          newSelectedPlates.splice(newSelectedPlates.indexOf(name), 1);
          setSelectedPlates(newSelectedPlates);
        } else {
          setSelectedPlates([...selectedPlates, name]);
        }
        break;
      }
      case 'range': {
        const currentPagePlateNames = currentPagePlates.map(plate => plate.name);
        const previousIndex = currentPagePlateNames.indexOf(selectedPlates[selectedPlates.length - 1]);
        const currentIndex = currentPagePlateNames.indexOf(name);
        const rangePlateNames = currentPagePlateNames.slice(
          Math.min(previousIndex, currentIndex),
          Math.max(previousIndex, currentIndex) + 1,
        );
        setSelectedPlates([...new Set([
          ...selectedPlates,
          ...rangePlateNames,
        ])]);
        break;
      }
      case 'set': {
        setSelectedPlates([name]);
        break;
      }
      default: {
        throw new Error('Unknown selection type: ' + type);
      }
    }
    /* eslint-enable indent */
  };

  useKeyboardShortcut('right', (event) => {
    event.preventDefault();
    nextPage();
  });

  useKeyboardShortcut('left', (event) => {
    event.preventDefault();
    previousPage();
  });

  useKeyboardShortcut('down', (event) => {
    event.preventDefault();
    nextPlate();
  });

  useKeyboardShortcut('up', (event) => {
    event.preventDefault();
    previousPlate();
  });

  useKeyboardShortcut('a', (event) => {
    event.preventDefault();
    toggleAllPlates();
  });

  const handleResetZoomLevel = (value) => {
    canvasRef.current.setPosition(50, 50);
    setZoom(value);
  };

  const spinner = <div className={css.ColorSeparationsPreview__spinner}><Spinner color={isDarkMode ? 'white' : undefined} /></div>;
  let content = spinner;

  if (colorSeparations && compositeImages) {
    content = (
      <Fragment>
        {colorSeparations && (
          <Flex
            container
            shrink={0}
            direction="vertical"
            alignItems="center"
            justifyContent="center"
            className={css.ColorSeparationsPreview__sidebar}
          >
            <Flex
              container
              direction="vertical"
              style={{ width: '100%', height: '100%' }}
            >
              <Flex
                shrink={0}
                grow={0}
                style={{ padding: 20, borderBottom: '1px solid rgba(0, 0, 0, .05)' }}
              >
                <HelpBubble
                  size="small"
                  message={<Translation value="proof.color-separations.settings.help.tooltip" />}
                  url="https://help.pageproof.com/en/articles/5417401-color-separations"
                  className={css.ColorSeparationsPreview__helpBubble}
                />
                <div className={css.ColorSeparationsPreview__settingsHeading}>
                  <Translation value="proof.color-separations.settings.heading" />
                </div>
                <div className={css.ColorSeparationsPreview__switchButton}>
                  <Switch
                    size="tiny"
                    minimal
                    value={enableColors}
                    onChange={() => setEnableColors(!enableColors)}
                  />
                </div>
                <div className={css.ColorSeparationsPreview__settingsLabel}>
                  <Translation value="proof.color-separations.settings.colors.label" />
                </div>
              </Flex>
              <Flex
                container
                direction="vertical"
                grow={1}
                shrink={1}
              >
                <Flex
                  shrink={0}
                  style={{ margin: 'auto', width: 180 }}
                >
                  {colorSeparations.pages.map(page => (
                    <div
                      key={page.number}
                      style={{ margin: '20px 0' }}
                    >
                      <EncryptedSpriteThumbnail
                        fileId={fileId}
                        pageNumber={page.number}
                        imageProps={{
                          className: css.ColorSeparationsPreview__pageThumbnail,
                          style: {
                            width: 100 * thumbnailMultiplier,
                            height: 136 * thumbnailMultiplier,
                            filter: pageNumber === page.number ? '' : 'grayscale(1)',
                          },
                          onClick: () => {
                            if (pageNumber === page.number) {
                              setSelectedPlates([]);
                            } else {
                              setPageNumber(page.number);
                            }
                          },
                        }}
                      />
                      <Reveal
                        visible={pageNumber === page.number}
                        align="top"
                      >
                        <div style={{ padding: '2px 0' }}>
                          <PlateOption
                            name={null}
                            isActive={selectedPlates.length === 0}
                            onClick={() => setSelectedPlates([])}
                          />
                          {currentPagePlates.map(plate => (
                            <PlateOption
                              key={plate.name}
                              name={plate.name}
                              color={customColors[plate.name]}
                              isActive={selectedPlateNames[plate.name]}
                              onClick={(event) => {
                                if (isMac ? event.metaKey : event.ctrlKey) {
                                  onSelectPlate('add', plate.name);
                                } else if (event.shiftKey) {
                                  onSelectPlate('range', plate.name);
                                } else {
                                  onSelectPlate('set', plate.name);
                                }
                              }}
                              onColor={(color) => {
                                setCustomColors({
                                  ...customColors,
                                  [plate.name]: color,
                                });
                              }}
                            />
                          ))}
                        </div>
                      </Reveal>
                    </div>
                  ))}
                </Flex>
              </Flex>
              <Flex
                shrink={0}
                style={{ height: 80 }}
              />
            </Flex>
          </Flex>
        )}
        <Flex
          container
          direction="vertical"
          alignItems="center"
          justifyContent="center"
          grow={1}
          style={{ height: '100%', padding: 20, overflow: 'hidden' }}
        >
          <Flex
            container
            direction="vertical"
            alignItems="center"
            justifyContent="center"
            shrink={0}
            className={css.ColorSeparationsPreview__header}
          >
            <div className={css.ColorSeparationsPreview__description}>
              <Translation
                value="proof.color-separations.title.page"
                params={{ pageNumber }}
              />
              <span className={css.ColorSeparationsPreview__header__separator}>–</span>
              {selectedPlates.length === 0
                ? <Translation value="proof.color-separations.title.composite" />
                : selectedPlates.length === currentPage.plates.length
                  ? <Translation value="proof.color-separations.title.all-plates" />
                  : selectedPlates.length > 5
                    ? (
                      <Translation
                        value="proof.color-separations.title.multiple-plates"
                        params={{ count: selectedPlates.length }}
                      />
                    )
                    : Object.keys(selectedPlateNames).join(' + ')
              }
            </div>
            {!!selectedPlates.length && (
              <div className={css.ColorSeparationsPreview__warning}>
                <Translation value="proof.color-separations.subtitle.color-warning" />
              </div>
            )}
          </Flex>
          <div style={{ flexGrow: 1, width: '100%', height: '100%', position: 'relative' }}>
            <DraggableCanvas
              ref={canvasRef}
              onDragStart={() => setDragging(true)}
              onDragEnd={() => setDragging(false)}
            >
              <div
                className={css.ColorSeparationsPreview__imageContainer}
                style={{ transform: `scale(${zoom})` }}
              >
                <PlateCoverageLayer
                  key={pageNumber}
                  proofId={proofId}
                  colorSeparations={colorSeparations}
                  pageNumber={pageNumber}
                  selectedPlates={selectedPlates}
                  customColors={customColors}
                  pagePlates={currentPagePlates}
                  isDragging={isDragging}
                />
                {selectedPlates.length
                  ? (
                    Object.keys(selectedPlateNames)
                      .map((plateName, index) => (
                        <HexColor
                          key={plateName}
                          name={plateName}
                        >
                          {color => (
                            <div
                              style={{
                                backgroundColor: enableColors ? (customColors[plateName] || color || '#000') : '',
                                mixBlendMode: 'multiply',
                                ...(index && { position: 'absolute', top: 0, left: 0 }),
                              }}
                            >
                              <EncryptedColorSeparation
                                proofId={proofId}
                                colorSeparations={colorSeparations}
                                pageNumber={pageNumber}
                                plateName={plateName}
                                imageProps={{
                                  className: css.ColorSeparationsPreview__image,
                                  style: { mixBlendMode: enableColors ? 'screen' : '' },
                                }}
                                fallback={!index && spinner}
                              />
                            </div>
                          )}
                        </HexColor>
                      ))
                  )
                  : (
                    <EncryptedHighPreview
                      proofId={proofId}
                      images={compositeImages}
                      pageNumber={pageNumber}
                      imageProps={{ className: css.ColorSeparationsPreview__image }}
                      fallback={
                        <EncryptedLowPreview
                          proofId={proofId}
                          images={compositeImages}
                          pageNumber={pageNumber}
                          imageProps={{ className: css.ColorSeparationsPreview__image }}
                          fallback={spinner}
                        />
                      }
                    />
                  )
                }
              </div>
            </DraggableCanvas>
          </div>
          <Flex
            container
            direction="vertical"
            alignItems="center"
            justifyContent="center"
            shrink={0}
            className={css.ColorSeparationsPreview__footer}
          >
            <RaisedSurface>
              <ZoomSlider
                action={<Translation value="proof.color-separations.zoom-slider-fit" />}
                zoomLevel={zoom}
                minZoomLevel={0.5}
                maxZoomLevel={4}
                onReset={() => handleResetZoomLevel(1)}
                onZoomChange={value => setZoom(value)}
              />
            </RaisedSurface>
          </Flex>
        </Flex>
      </Fragment>
    );
  }

  return (
    <Portal>
      <div className={css.ColorSeparationsPreview}>
        <ExitModeButton
          className={css.ColorSeparationsPreview__closeButton}
          onExit={close}
          tooltip={<Translation value="proof.color-separations.exit" />}
        />
        <Flex
          root
          container
          alignItems="center"
          justifyContent="center"
        >
          {content}
        </Flex>
      </div>
    </Portal>
  );
};

export default ColorSeparationsPreview;
