/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState, useEffect } from 'react';
import { Button } from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import Textarea from '../../components/Textarea';
import { sdk } from '../../util/sdk';

const NudgeMessageModal = ({ workflowId, userId, onCancel, onSubmit }) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    sdk.workflows.getNudgeMessage(workflowId, userId)
      .then((previousNudgeMessage) => {
        // Only update if the user hasn't already started writing a message.
        if (previousNudgeMessage && !message) {
          setMessage(previousNudgeMessage);
        }
      });
  }, [workflowId, userId]);

  return (
    <Fragment>
      <h3>
        Add a message if you like.
      </h3>
      <Textarea
        autoFocus
        value={message}
        onChange={newMessage => setMessage(newMessage)}
      />
      <br />
      <br />
      <ButtonGroup>
        <Button
          variant="text"
          label="Cancel"
          onClick={onCancel}
        />
        <Button
          variant="primary"
          label={userId
            ? 'Nudge'
            : 'Nudge all'
          }
          onClick={() => {
            onSubmit(message || null);
          }}
        />
      </ButtonGroup>
    </Fragment>
  );
};

export default NudgeMessageModal;
