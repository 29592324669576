/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export default function (sec) {
  let hours = null;
  let minutes = Math.floor(sec / 60);
  let seconds = String(Math.floor(sec % 60));

  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);

    if (minutes < 10) {
      minutes = '0' + minutes;
    }
  }

  if (seconds.length === 1) {
    seconds = '0' + seconds;
  }

  return `${hours ? hours + ':' : ''}${minutes}:${seconds}`;
}
