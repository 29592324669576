/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PopupMenu, Option, ConfirmDeleteOption, Separator } from '../../PopupMenu';
import Translation from '../../Text/Translation';
import AttachmentThumbnail from '../AttachmentThumbnail';
import AttachmentDownloadOption from '../AttachmentDownloadOption';
import { Spinner } from '../../Spinner';
import css from './Attachment.scss';

function Attachment({
  id,
  name,
  isUploading,
  isProcessing,
  canDownload,
  canDelete,
  canView,
  onDelete,
  onDownload,
  onView,
}) {
  return (
    <PopupMenu
      options={
        <Fragment>
          {isUploading &&
            <Option
              label={<Translation value="attachment.uploading" />}
              disabled
            />
          }
          {isProcessing &&
            <Option
              label={<Translation value="attachment.processing" />}
              disabled
            />
          }
          <Separator />
          {canView &&
            <AttachmentThumbnail
              id={id}
              onClick={onView}
            />
          }
          {canDownload &&
            <AttachmentDownloadOption
              onDownload={onDownload}
            />
          }
          {canView &&
            <Option
              label={<Translation value="attachment.view" />}
              onClick={onView}
            />
          }
          <Separator />
          {canDelete &&
            <ConfirmDeleteOption
              onClick={onDelete}
            />
          }
        </Fragment>
      }
    >
      <div className={css.Attachment}>
        <div className={css.Attachment__name}>
          {name}
        </div>
        {isUploading &&
          <div className={css.Attachment__spinner}>
            <Spinner
              size={15}
              color="white"
            />
          </div>
        }
      </div>
    </PopupMenu>
  );
}

Attachment.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  isUploading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  canDownload: PropTypes.bool,
  canView: PropTypes.bool,
  canDelete: PropTypes.bool,
  onDownload: PropTypes.func,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Attachment;
