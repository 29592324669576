/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { map, prop, compose, sort, flatten } from 'rambda';
import { reduce } from './curried-functions';

const getCommonValues = (values) => {
  const getCommonTags = (acc, curr, i, a) => {
    if (acc.indexOf(curr) === -1 && curr === a[i + 1]) {
      return [
        ...acc,
        curr,
      ];
    }
    return acc;
  };

  return compose(
    reduce(getCommonTags, []),
    sort((a, b) => a > b),
    flatten
  )(values);
};

const getCommonFromObjectProp = (propName, obj) => (
  compose(
    getCommonValues,
    map(prop(propName))
  )(obj)
);

const getValidationObject = obj => obj.reduce((acc, curr) => ([
  ...acc,
  {
    id: curr.id,
    title: {
      empty: false,
      long: false,
    },
    message: {
      long: false,
    },
  },
]), []);

const objectHasTrueValue = obj => (
  obj
    ? Object.keys(obj).some(key => obj[key] === true)
    : false
);

const calculateTotalValue = object => Object.keys(object).reduce((acc, curr) => acc + object[curr], 0);

const unique = array => (
  array.reduce((acc, curr) => (acc.indexOf(curr) < 0 ? [...acc, curr] : acc), [])
);

const partition = (array, filterFunc) => {
  const result = [[], []];
  array.forEach((x) => {
    const index = filterFunc(x) ? 0 : 1;
    result[index].push(x);
  });

  return result;
};

export {
  getCommonValues,
  getCommonFromObjectProp,
  getValidationObject,
  objectHasTrueValue,
  calculateTotalValue,
  unique,
  partition,
};
