/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';

function Tab({
  id,
  selected,
  children,
}) {
  return (
    <div
      id={`Tab-${id}`}
      className="Tab"
      role="tabpanel"
      aria-labelledby={`TabButton-${id}`}
      aria-hidden={!selected}
    >
      {children}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Tab.propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  };
}

export default Tab;
