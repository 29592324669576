/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './Heading.scss';

function Heading({
  title,
  subtitle,
  options,
}) {
  return (
    <div className={css.Heading}>
      <div className={css.Heading__title}>
        {title}
        {subtitle &&
          <div className={css.Heading__subtitle}>
            {subtitle}
          </div>
        }
      </div>
      {options &&
        <div className={css.Heading__options}>
          {options}
        </div>
      }
    </div>
  );
}

export default Heading;
