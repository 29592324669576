import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './MultiEmailField.scss';
import EmailAddressEdit from '../EmailAddressEdit';
import Suggestions, { addEmail } from '../Suggestions';
import commaSeparatedEmails from '../../util/commaSeparatedEmails';
import { useText } from '../Text';

const MultiEmailField = ({ emails, updateEmails, color }) => {
  const text = useText();
  const [inputEmail, setInputEmail] = useState('');
  const emailsRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the container - as new emails are added to the end, and without this change, the user will not be able to initially see the new emails they add.
    emailsRef.current.scrollTop = emailsRef.current.scrollHeight;
  }, [emails]);

  const filterNewEmails = targetEmails => (
    commaSeparatedEmails(targetEmails, null)
      .filter((email) => {
        const isNewEmail = emails.indexOf(email) === -1;
        if (isNewEmail) addEmail(email);
        return isNewEmail;
      })
  );

  const onSubmit = (submittedEmail) => {
    updateEmails([...emails, ...filterNewEmails(submittedEmail)]);
    setInputEmail('');
  };

  const onUpdateEmail = (value, previousValue) => {
    const index = emails.indexOf(previousValue);
    const updatedEmails = [...emails];

    updatedEmails[index] = value;
    updateEmails(updatedEmails);
  };

  const onRemoveEmail = (targetEmail) => {
    updateEmails(emails.filter(email => email !== targetEmail));
  };

  return (
    <div
      className={classname(css.MultiEmailField, css[`MultiEmailField--${color}`])}
    >
      <div
        className={css.MultiEmailField__emails}
        ref={emailsRef}
      >
        {emails.map(email => (
          <EmailAddressEdit
            key={email}
            email={email}
            onDelete={onRemoveEmail}
            onUpdate={onUpdateEmail}
          />
        ))}
      </div>
      <Suggestions
        exclusions={emails}
        onSelect={onSubmit}
      >
        <input
          autoFocus
          placeholder={text('dashboard.manage.multi-email-filed.placeholder')} // The Suggestions component requires <input /> as a direct child for attaching events, so we can't use TranslatedProps component here as it will break Suggestions.
          className={css.MultiEmailField__inputEmail}
          value={inputEmail}
          onChange={event => setInputEmail(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onSubmit(inputEmail);
            }
          }}
          onBlur={() => onSubmit(inputEmail)}
        />
      </Suggestions>
    </div>
  );
};
if (process.env.NODE_ENV !== 'production') {
  MultiEmailField.propTypes = {
    emails: PropTypes.arrayOf(PropTypes.string).isRequired,
    updateEmails: PropTypes.func.isRequired,
    color: PropTypes.oneOf(['white', 'grey']),
  };
}

export default MultiEmailField;
