/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import PropTypes from 'prop-types';
import css from './CollectionManageBackButton.scss';
import { Button } from '../../components/Button';
import { Translation } from '../../components/Text';

const CollectionManageBackButton = ({ onClick }) => (
  <Button
    variant="unstyled"
    className={css.CollectionManageBackButton}
    onClick={onClick}
    label={
      <span>
        <InlineSVG
          className={css.CollectionManageBackButton__arrow}
          src="img/interface/arrow-down.svg"
        />
        <Translation value="button.back" />
      </span>
    }
  />
);

if (process.env.NODE_ENV !== 'production') {
  CollectionManageBackButton.propTypes = {
    onClick: PropTypes.func.isRequired,
  };
}

export default CollectionManageBackButton;
