/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import Input from '../Input';
import CopyButton from '../Button/CopyButton';
import { Button } from '../Button';
import css from './AwaitingEmailMessageForSafari.scss';

function AwaitingEmailMessageForSafari({ generatedEmail, onGenerateEmail }) {
  return (
    <React.Fragment>
      <h3><Translation value="proof-info.message.awaiting-email.header" /></h3>
      <p><Translation value="proof-info.message.awaiting-email.message.generate" /></p>
      {!generatedEmail &&
        <Button
          onClick={() => onGenerateEmail()}
          label={<Translation value="proof-info.message.awaiting-email.button.generate" />}
        />
      }
      {generatedEmail &&
        <React.Fragment>
          <CopyButton
            variant="roundLightGreen"
            text={generatedEmail}
            className={css.AwaitingEmailMessageForSafari__copyEmailButton}
          />
          <Input
            className={css.AwaitingEmailMessageForSafari__inputField}
            variant="compact"
            readOnly
            value={generatedEmail}
            onFocus={(event) => {
              event.target.select();
            }}
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
}

if (process.env.NODE_ENV !== 'production') {
  AwaitingEmailMessageForSafari.propTypes = {
    generatedEmail: PropTypes.string.isRequired,
    onGenerateEmail: PropTypes.func.isRequired,
  };
}

export default AwaitingEmailMessageForSafari;
