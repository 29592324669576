/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';

const emailRegex = /(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)/;

export const linkifyMessageWithEmails = (message = '') => {
  const parts = message.split(emailRegex);

  const transformedMessage = parts.map((part, index) => {
    if (window.validateEmail(part)) {
      return <a href={`mailto:${part}`} key={index}>{part}</a>;
    } else {
      return <Fragment key={index}>{part}</Fragment>;
    }
  });
  return <Fragment>{transformedMessage}</Fragment>;
};
