/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import ChecklistTemplatePreview from '../../ChecklistTemplatePreview';
import FormControl from '../../FormControl';
import HelpBubble from '../../HelpBubble';
import Switch from '../../Switch';
import css from './ChecklistSection.scss';
import { ChecklistTemplateDropdown } from './ChecklistTemplateDropdown';
import ExpandingSection from './ExpandingSection';
import { PeopleSection } from './PeopleSection';
import { Translation } from '../../Text';

const ChecklistSettings = ({ state, setState }) => {
  const updateSetting = (key, value) => {
    setState(prevState => ({
      ...prevState,
      checklist: {
        ...prevState.checklist,
        [key]: value,
      },
    }));
  };

  const {
    shouldOpenOnProofLoad,
    isRequiredForFinalApproval,
  } = state.checklist;

  return (
    <div className={css.ChecklistSection__settings}>
      <FormControl
        compact
        label={
          <div className={css.ChecklistSection__settings__label}>
            <Translation value="proof.setup.checklist.required-for-final-approval" />
            <HelpBubble
              size="small"
              message={<Translation value="proof.setup.checklist.required-for-final-approval.help" />}
            />
          </div>
        }
      >
        <Switch
          value={isRequiredForFinalApproval}
          onChange={value => updateSetting('isRequiredForFinalApproval', value)}
        />
      </FormControl>

      <FormControl
        compact
        label={<Translation value="proof.setup.checklist.open-on-proof-load.label" />}
      >
        <Switch
          value={shouldOpenOnProofLoad}
          onChange={value => updateSetting('shouldOpenOnProofLoad', value)}
        />
      </FormControl>
    </div>
  );
};

export const ChecklistSection = ({ state, setState }) => (
  <PeopleSection
    walkthroughHook="proof-setup-checklist"
    preview={
      <ExpandingSection.Heading
        icon="/img/icons/material/checklist-24px.svg"
        title={<Translation value="proof.setup.checklist.title" />}
        description={(
          <React.Fragment>
            <div>
              <Translation value="proof.setup.checklist.description" />
            </div>
            <div className={css.ChecklistSection__options}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                className={css.ChecklistSection__dropdown}
                onClick={event => event.stopPropagation()}
              >
                {state.checklist && state.checklist.id
                  ? (
                    <div className={css.ChecklistSection__existingChecklistName}>
                      {state.checklist.name}
                    </div>
                  )
                  : (
                    <ChecklistTemplateDropdown
                      currentChecklist={state.checklist}
                      previousVersionChecklist={state.parameters.attachNewVersion && state.parameters.attachNewVersion.checklist}
                      onSelect={(checklistTemplate) => {
                        setState((prevState) => {
                          if (!checklistTemplate) {
                            return {
                              ...prevState,
                              checklist: null,
                            };
                          }
                          return {
                            ...prevState,
                            checklist: {
                              templateId: checklistTemplate.id,
                              name: checklistTemplate.name,
                              shouldOpenOnProofLoad: checklistTemplate.defaultShouldOpenOnProofLoad,
                              isRequiredForFinalApproval: checklistTemplate.defaultIsRequiredForFinalApproval,
                            },
                          };
                        });
                      }}
                    />
                  )}
              </div>
            </div>
          </React.Fragment>
        )}
      />
    }
    open={!!state.checklist}
    canOpen={!!state.checklist}
  >
    <ChecklistSettings
      state={state}
      setState={setState}
    />
    {state.checklist && state.checklist.templateId && (
      <div className={css.ChecklistSection__preview}>
        <ChecklistTemplatePreview
          checklistTemplateId={state.checklist.templateId}
        />
      </div>
    )}
  </PeopleSection>
);
