/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import {
  DomainAdminNavigationPanel,
  DomainAdminNavigationDropdown,
} from '../../components/DomainAdmin';

function DomainAdminDashboardNavigationContainer(props) {
  return (
    <div>
      <DomainAdminNavigationPanel {...props} />
      <DomainAdminNavigationDropdown {...props} />
    </div>
  );
}

export default DomainAdminDashboardNavigationContainer;
