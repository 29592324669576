/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Translation } from '../../components/Text';
import { walkthroughIds } from './walkthroughIds';

const ACTION_COMMENTS_STEP = {
  hooks: ['flyout-panel'],
  tooltipDirections: {
    middle: true,
    left: true,
  },
  styles: {
    overflow: 'hidden',
    borderRadius: 4,
  },
  heading: <Translation value="walkthrough.action-comments-step.heading" />,
  description: <Translation value="walkthrough.action-comments-step.description" />,
};


const FILTER_COMMENTS_STEP = {
  hooks: ['proof-comments-header'],
  tooltipDirections: {
    middle: true,
    left: true,
  },
  additionalPadding: {
    top: 10,
    bottom: 10,
    left: 20,
    right: 20,
  },
  heading: <Translation value="walkthrough.filter-comments-step.heading" />,
  description: <Translation value="walkthrough.filter-comments-step.description" />,
};


const UPLOAD_NEW_VERSION_STEP = {
  hooks: ['proof-upload-new-version-button'],
  tooltipDirections: {
    up: true,
    center: true,
  },
  heading: <Translation value="walkthrough.upload-new-version-step.heading" />,
  description: <Translation value="walkthrough.upload-new-version-step.description" />,
};

export const proofEditorWalkthrough = {
  manualTrigger: true,
  id: walkthroughIds.proofEditor,
  canDisplay: () => window.location.href.match(/\/proof\/[^/.]*\/p/i),
  requiredSteps: [ACTION_COMMENTS_STEP, UPLOAD_NEW_VERSION_STEP],
  steps: [
    [ACTION_COMMENTS_STEP],
    [FILTER_COMMENTS_STEP],
    [UPLOAD_NEW_VERSION_STEP],
  ],
  includedWalkthroughIds: [walkthroughIds.proofEditor],
};
