/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

function Radio({
  selected,
  variant,
  onChange,
}) {
  return (
    <button
      tabIndex="0"
      className={classname('Radio unstyled', {
        'Radio--selected': selected,
        [`Radio--${variant}`]: variant,
      })}
      onClick={(event) => {
        event.preventDefault();
        if (onChange) {
          onChange(!selected);
        }
      }}
    >
      <input
        type="radio"
        className="Radio__input"
      />
      <div className="Radio__dot" />
    </button>
  );
}

Radio.defaultProps = {
  variant: 'green',
};

if (process.env.NODE_ENV !== 'production') {
  Radio.propTypes = {
    selected: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf([
      'green',
      'white',
    ]),
    onChange: PropTypes.func,
  };
}

export default Radio;
