/* eslint-disable */

import randomId from './randomId';

let noConflictIndex = 0;
const WEEK_IN_MS = 1000 * 60 * 60 * 24 * 7;

export function storeBlob(blob) {
  const { temporaryStorageService, utilService } = window.__pageproof_bridge__;
  const reference = utilService.sha512('reference' + Date.now() + randomId() + (++noConflictIndex));

  return temporaryStorageService.set(reference, blob, Date.now() + WEEK_IN_MS).then(() => {
    return reference;
  });
}

export function retrieveBlob(reference) {
  const { temporaryStorageService } = window.__pageproof_bridge__;
  return temporaryStorageService.get(reference);
}

export function deleteBlob(reference) {
  const { temporaryStorageService } = window.__pageproof_bridge__;
  return temporaryStorageService.remove(reference);
}
