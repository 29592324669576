/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';

function Separator({ fullWidth }) {
  return (
    <div
      className={classname('DropdownSeparator', {
        'DropdownSeparator--fullWidth': fullWidth,
      })}
    />
  );
}

export default Separator;
