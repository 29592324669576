/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import classname from 'classname';
import { v4 as uuidv4 } from 'uuid';
import Tag from './Tag';
import TagInput from './TagInput';
import InputOptions from '../ProofSetup/components/InputOptions';
import { Translation } from '../Text';

class Tags extends Component {
  state = {
    input: '',
    focused: false,
  };

  constructor(props) {
    super(props);

    this.inputId = uuidv4();
  }

  handleChange = (input) => {
    this.setState({ input });
  }

  onBlur = () => {
    this.handleFocused(false);
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
  }

  handleRemove = () => {
    const tag = this.removeTag(this.props.tags.length - 1);
    this.setState({ input: tag });
  }

  handleCreate = (createTags) => {
    const newTags = this.normaliseCreate(createTags);
    if (newTags) {
      this.setState({ input: '' });
      this.createTag(newTags);
    }
  }

  normaliseCreate(createTags) {
    const { tags, unique } = this.props;
    if (unique) {
      // Extract all the "new" tags (do not yet exist within the tags array)
      const newTags = createTags.filter(tag => tags.indexOf(tag) === -1);
      if (newTags.length) {
        return newTags;
      }
    } else {
      return createTags;
    }
    return null;
  }

  removeTag(index) {
    const tags = this.props.tags.slice();
    const removedTags = tags.splice(index, 1);
    this.props.onChange(tags);
    const { onRemove } = this.props;
    if (onRemove) {
      onRemove(removedTags[0]);
    }
    return removedTags[0];
  }

  createTag(newTags) {
    const { tags, onAdd, onAddMultiple } = this.props;
    this.props.onChange([...tags, ...newTags]);
    if (onAdd) {
      newTags.forEach(tag => onAdd(tag));
    }
    if (onAddMultiple) {
      onAddMultiple(newTags);
    }
  }

  handleFocused(focused) {
    this.setState({ focused });
  }

  focus(event) {
    event.preventDefault();
    // eslint-disable-next-line
    const element = findDOMNode(this).querySelector('input');
    if (element) {
      // Select the input element (if there is one - readOnly removes the input)
      element.focus();
    }
  }

  render() {
    const {
      tags,
      readOnly,
      placeholder,
      importedTags = [],
    } = this.props;
    const {
      input,
      focused,
    } = this.state;
    return (
      <label
        className={classname('Tags unstyled', {
          'Tags--readonly': readOnly,
          'Tags--focused': !readOnly && focused,
        })}
        onMouseDown={event => this.focus(event)}
        htmlFor={this.inputId}
      >
        {tags.map((tag, index) => (
          <div
            key={tag}
            className="Tags__tag-wrapper"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <Tag
              value={tag}
              onClick={() => !readOnly && this.removeTag(index)}
              imported={importedTags.includes(tag)}
            />
          </div>
        ))}
        {!readOnly &&
          <TagInput
            value={input}
            placeholder={placeholder}
            onRemove={this.handleRemove}
            onCreate={this.handleCreate}
            onChange={this.handleChange}
            onFocus={() => this.handleFocused(true)}
            onBlur={this.onBlur}
            id={this.inputId}
          />
        }
        <InputOptions
          absolute
          options={
            <InputOptions.Option
              label={<Translation value="button.clear" />}
              onClick={() => {
                this.props.onChange([]);
                this.setState({ input: '' });
              }}
            />
          }
        />
      </label>
    );
  }
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onAddMultiple: PropTypes.func,
  placeholder: PropTypes.string,
  unique: PropTypes.bool,
  onBlur: PropTypes.func,
  onRemove: PropTypes.func,
};

Tags.defaultProps = {
  readOnly: false,
  placeholder: '',
  unique: true,
};

export default Tags;
