/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useRef, useState, useEffect } from 'react';

export function useAngularWatcher(scope, createWatchFns) {
  const watchFnsRef = useRef();
  if (!watchFnsRef.current) {
    watchFnsRef.current = createWatchFns();
  }

  const watchFns = watchFnsRef.current;

  const [values, setValues] = useState(() => {
    const newValues = [];
    watchFns.forEach((watchFn, index) => {
      try {
        newValues[index] = watchFn();
      } catch (err) {
        newValues[index] = undefined;
      }
    });
    return newValues;
  });

  useEffect(() => {
    let latestValues = values;
    const watcher = scope.$watch(() => {
      let changed = false;
      const newValues = [];
      watchFns.forEach((watchFn, index) => {
        const oldValue = latestValues[index];
        let newValue;
        try {
          newValue = watchFn();
        } catch (err) {
          newValue = undefined;
        }
        if (newValue !== oldValue) {
          changed = true;
        }
        newValues[index] = newValue;
      });
      if (changed) {
        latestValues = newValues;
        setValues(newValues);
      }
    }, () => {
      // Noop - this should only trigger once, because the value from our watcher never actually changes.
    });
    return () => {
      watcher();
    };
  }, [scope]);

  return values;
}
