/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Enum } from '@pageproof/sdk';
import PrintOptionsButton, { PrintButton } from '../../components/PrintOptionsButton';

const PrintOptionsButtonContainer = ({ role, onSelection }) => {
  if (role === Enum.ProofRole.UNLISTED_REVIEWER) {
    return (
      <PrintButton
        ariaLabel="Print button"
        showTooltip
        onClick={() => { onSelection('new'); }}
      />
    );
  }

  return (
    <PrintOptionsButton
      ariaLabel="Print button"
      onSelection={onSelection}
    />
  );
};

export default PrintOptionsButtonContainer;
