/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './Fraction.scss';

const Fraction = ({ numerator, denominator, size, isHorizontal }) => {
  if (isHorizontal) {
    return (
      <div style={{ fontSize: Math.round(size * 0.6) }}>
        {numerator}
        /
        {denominator}
      </div>
    );
  }

  return (
    <div className={css.Fraction}>
      <div className={css.Fraction__number}>
        {numerator}
      </div>
      <div className={css.Fraction__line} />
      <div className={css.Fraction__number}>
        {denominator}
      </div>
    </div>
  );
};

Fraction.propTypes = {
  numerator: PropTypes.number,
  denominator: PropTypes.number,
  size: PropTypes.number,
  isHorizontal: PropTypes.bool,
};

export default Fraction;
