/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addEmail } from '../Suggestions';
import commaSeparatedEmails from '../../util/commaSeparatedEmails';
import EmailAddressEdit from '../EmailAddressEdit';
import { Translation, useText } from '../Text';
import { Button } from '../Button';
import css from './AddContactPopup.scss';

const AddContactPopup = ({ onConfirmAdd }) => {
  const text = useText();
  const [inputEmail, setInputEmail] = useState('');
  const [emails, setEmails] = useState([]);

  const onAdd = (submittedEmail) => {
    setEmails(prevState => [...prevState, ...commaSeparatedEmails(submittedEmail, null)
      .filter(email => emails.indexOf(email) === -1)]);
    setInputEmail('');
  };

  const onEdit = (value, previousValue) => {
    const index = emails.indexOf(previousValue);
    const updatedEmails = [...emails];

    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const onDelete = (targetEmail) => {
    setEmails(prevState => prevState.filter(email => email !== targetEmail));
  };

  const onSubmit = () => {
    Promise.all(emails.map(email => addEmail(email)))
      .then(() => {
        setEmails([]);

        if (onConfirmAdd) {
          onConfirmAdd();
        }
      });
  };

  return (
    <div className={css.AddContactPopup}>
      <div className={css.AddContactPopup__heading}>
        <Translation value="profile.address-book.tooltip.add.heading" />
      </div>
      <div className={css.AddContactPopup__newContact}>
        <div className={css.AddContactPopup__newContact__emails}>
          {emails.map(email => (
            <EmailAddressEdit
              key={email}
              email={email}
              onDelete={onDelete}
              emailTooltip={<Translation value="profile.address-book.tooltip.edit" />}
              deleteTooltip={<Translation value="profile.address-book.tooltip.remove" />}
              onUpdate={onEdit}
            />
          ))}
        </div>
        <input
          autoFocus
          placeholder={text('proof.utilities.share.email.placeholder')}
          className={css.AddContactPopup__newContact__inputEmail}
          value={inputEmail}
          onChange={event => setInputEmail(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onAdd(inputEmail);
            }
          }}
          onBlur={() => onAdd(inputEmail)}
        />
      </div>
      <div className={css.AddContactPopup__button}>
        <Button
          variant="primary"
          autoWidth
          disabled={emails.length === 0}
          label={<Translation value="profile.address-book.tooltip.add.button" />}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  AddContactPopup.propTypes = {
    onConfirmAdd: PropTypes.func.isRequired,
  };
}

export default AddContactPopup;
