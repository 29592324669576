/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';

export function SmartCompareDiffOverlay({ imageUrl, settings }) {
  const [isVisible, setVisible] = useState(true);
  const [isJiggling, setJiggling] = useState(false);

  const strobeSpeedMs = settings.strobeSpeed
    ? 1000 / settings.strobeSpeed
    : 0;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setVisible(true);

    if (strobeSpeedMs) {
      const interval = setInterval(() => {
        setVisible(color => !color);
      }, strobeSpeedMs);
      return () => {
        clearInterval(interval);
      };
    }
  }, [strobeSpeedMs]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setJiggling(false);

    if (settings.jiggleDistance) {
      const timeout = setTimeout(() => setJiggling(true), 100);
      return () => clearTimeout(timeout);
    }
  }, [
    settings.jiggleDistance,
  ]);

  // eslint-disable-next-line no-nested-ternary
  const opacity = settings.ghostOpacity
    ? settings.ghostOpacity
    : (imageUrl ? 0 : 0.5);

  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          inset: 0,
          backgroundColor: settings.diffColor === '#ffffff' ? '#000' : '#fff',
          opacity,
        }}
      />
      <div
        style={{
          position: 'absolute',
          inset: 0,
          transition: `opacity ${strobeSpeedMs}ms ease-in-out`,
          opacity: isVisible ? 1 : 0,
          // transform: 'translate3d(0 0 0)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            visibility: imageUrl ? 'visible' : 'hidden',
            WebkitMaskImage: `url(${imageUrl})`,
            maskImage: `url(${imageUrl})`,
            backgroundColor: settings.diffColor === 'rainbow' ? '#e51c23' : settings.diffColor,
            '--smart-compare-diff-overlay-jiggle-distance': settings.jiggleDistance + 'px',
            animation: [
              isJiggling ? 'smart-compare-diff-overlay-jiggle 0.6s ease-in-out infinite' : null,
              settings.diffColor === 'rainbow' ? 'smart-compare-diff-overlay-rainbow 1s ease-in-out infinite' : null,
            ].filter(Boolean).join(', '),
          }}
        />
      </div>
    </React.Fragment>
  );
}
