/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ProfileChangePassword from '../../components/ProfileChangePassword';
import ProfileMFA from '../../components/ProfileMFA';

const ProfilePasswordAndSecurity = ({ hasTOTP, onUpdateHasTOTP, onChangePassword }) => (
  <Fragment>
    <ProfileChangePassword onSubmit={onChangePassword} />
    <br />
    <ProfileMFA
      hasTOTP={hasTOTP}
      onUpdateHasTOTP={onUpdateHasTOTP}
    />
  </Fragment>
);

if (process.env.NODE_ENV !== 'production') {
  ProfilePasswordAndSecurity.propTypes = {
    hasTOTP: PropTypes.bool.isRequired,
    onUpdateHasTOTP: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
  };
}

export default ProfilePasswordAndSecurity;
