/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';

function withBundle(load, fallback) {
  return class Bundle extends React.Component {
    static Component = null;

    state = {
      Component: Bundle.Component,
    };

    componentWillMount() {
      if (!this.state.Component) {
        this.load();
      }
    }

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    load() {
      load().then((mod) => {
        const Component = mod.default || mod;
        Bundle.Component = Component;
        if (this.mounted && Component !== this.state.Component) {
          this.setState({Component});
        }
      });
    }

    render() {
      if (module.hot) {
        this.load();
      }
      const {Component} = this.state;
      const {innerRef, ...props} = this.props;
      if (Component) {
        return (
          <Component
            ref={innerRef}
            {...props}
          />
        );
      } else if (fallback) {
        return fallback({
          ref: innerRef,
          ...props,
        });
      }
      return null;
    }
  };
}

export {
  withBundle
};
