/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import BookmarkButton from '../../components/BookmarkButton';
import { useScreenSize } from '../../hooks/useScreenSize';
import { useLocalStorage } from '../../components/LocalStorage';

function BookmarkButtonContainer({ proofId, currentPage }) {
  const { isSmallScreen } = useScreenSize();
  const [bookMarkPage, setBookmarkPage] = useLocalStorage('pageproof.app.bookmark', null);

  const getUrl = () => {
    setBookmarkUrl(currentPage);
  };

  const setBookmarkUrl = (page) => {
    if (!bookMarkPage || !bookMarkPage[proofId]) {
      const newBookmarkPages = Object.assign({}, bookMarkPage);
      newBookmarkPages[proofId] = page;
      setBookmarkPage(newBookmarkPages);
    }
  };

  const removeBookmarkUrl = () => {
    if (bookMarkPage && bookMarkPage[proofId]) {
      const newBookmarkPages = Object.assign({}, bookMarkPage);
      delete newBookmarkPages[proofId];
      setBookmarkPage(newBookmarkPages);
    }
  };

  const goToPage = () => {
    const { $location, $rootScope } = window.__pageproof_bridge__;
    if (bookMarkPage && bookMarkPage[proofId]) {
      $location.search('page', bookMarkPage[proofId]);
      $rootScope.$apply();
    }
  };
  // eslint-disable-next-line no-prototype-builtins
  const isProofBookmarked = bookMarkPage && bookMarkPage.hasOwnProperty(proofId);

  return (

    <BookmarkButton
      getUrl={getUrl}
      direction={isSmallScreen ? 'down' : 'up'}
      isProofBookmarked={isProofBookmarked}
      pageNumber={bookMarkPage && bookMarkPage[proofId]}
      removeBookmarkUrl={removeBookmarkUrl}
      goToPage={goToPage}
    />
  );
}

export default BookmarkButtonContainer;
