/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Dropdown, {
  Option as DropdownOption,
  Separator as DropdownSeparator,
  Label as DropdownLabel,
  OptionLabel as DropdownOptionLabel,
} from '../../components/Dropdown';
import {Translation} from '../../components/Text';
import networks from '../../resources/networks.json';

const speeds = [
  {
    icon: 'ethernet',
    id: null,
    name: <Translation value="network.name" />,
    description: <Translation value="network.description" />,
    latency: null,
    download: null,
    upload: null,
    variant: 'muted',
  },
  null,
  ...networks,
];

function NetworkDropdown({
  speed: selectedSpeed,
  onSpeedChange,
  ...props,
}) {
  return (
    <Dropdown
      {...props}
      selected={selectedSpeed}
      placeholder={
        <DropdownOption
          icon="/img/interface/network/network.svg"
          label={
            <DropdownOptionLabel
              title={<Translation value="network.speed-select" />}
            />
          }
        />
      }
    >
      {speeds.map((speed, index) => (
        // eslint-disable-next-line no-nested-ternary
        typeof speed === 'string'
          ? <DropdownLabel
            key={index}
            label={speed}
          />
          : speed === null
            ? <DropdownSeparator
              key={index}
            />
            : <DropdownOption
              key={index}
              value={speed.id}
              icon={speed.icon ? `/img/interface/network/${speed.icon}.svg` : null}
              label={
                <DropdownOptionLabel
                  title={speed.name}
                  description={speed.description}
                />
              }
              onClick={() => onSpeedChange(speed)}
              variant={speed.variant}
            />
      ))}
    </Dropdown>
  );
}

export default NetworkDropdown;
