/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';

function Label({label}) {
  return (
    <div className="DropdownLabel">
      {label}
    </div>
  );
}

export default Label;
