/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './Separator.scss';

function Separator({ variant, styles }) {
  return <div
    style={{ ...styles }}
    className={classname(css.Separator, css['Separator--' + variant])}
  />;
}

export default Separator;
