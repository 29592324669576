/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Input from '../../components/Input';
import ExpandingControl from '../../components/ExpandingControl';
import Portal from '../../components/Portal';
import { Translation, TranslatedProps } from '../../components/Text';
import DownloadIcon from '../../components/DownloadIcon';
import ProgressCircle from '../../components/ProgressCircle';
import Tooltip from '../../components/Tooltip';
import styles from './SearchBar';
import ProofListSort from '../../components/ProofListSort';

class ProofSearchContainer extends Component {
  state = {
    searchInput: '',
    selectedTab: '',
  };

  updateSearch = window.debounce(() => {
    this.props.onSearchChange(this.state.searchInput);
  }, 1000);

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.search !== this.state.searchInput && nextProps.search !== this.props.search) {
      this.setState({
        searchInput: nextProps.search,
      });
    }
    if (nextProps.selectedTab !== this.state.selectedTab) {
      this.setState({
        selectedTab: nextProps.selectedTab,
      });
    }
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.input.blur();
    }
  }

  onSearchChange = (value) => {
    this.setState({
      searchInput: value,
    }, () => {
      this.updateSearch();
    });
    if (!value) {
      this.props.onSearchChange(value);
    }
  };

  render() {
    const {
      search,
      placeholderTranslationKey,
      extendedPlaceholderTranslationKey,
      selectedFilters,
      selectedDateFilters,
      toggleFilter,
      toggleDateFilter,
      orderBy,
      onOrderByChange,
      reverseOrder,
      onReverseOrderChange,
      onExport,
      exportProgress,
      onCancelExport,
      onClearFilters,
      onResetOrder,
    } = this.props;

    const {
      searchInput,
    } = this.state;

    const filterShown = true;

    return (
      <Portal>
        <div className={styles.SearchBar}>
          <InlineSVG
            className={styles['SearchBar__search-icon']}
            src="/img/interface/search.svg"
          />
          <ExpandingControl
            initialWidth={110}
            control={
              <TranslatedProps placeholder={extendedPlaceholderTranslationKey}>
                <Input
                  type="text"
                  className={styles['SearchBar__placeholder--large']}
                  value={searchInput || search}
                  onChange={this.onSearchChange}
                  onKeyDown={this.onKeyDown}
                  inputRef={(input) => { this.input = input; }}
                />
              </TranslatedProps>
            }
          />
          <ExpandingControl
            initialWidth={110}
            control={
              <TranslatedProps placeholder={placeholderTranslationKey}>
                <Input
                  type="text"
                  className={styles['SearchBar__placeholder--small']}
                  value={searchInput || search}
                  onChange={this.onSearchChange}
                  onKeyDown={this.onKeyDown}
                  inputRef={(input) => { this.input = input; }}
                />
              </TranslatedProps>
            }
          />
          {filterShown &&
            <ProofListSort
              filters={selectedFilters}
              dateFilters={selectedDateFilters}
              onClickFilter={toggleFilter}
              onClickDateFilter={toggleDateFilter}
              order={orderBy}
              onClickOrder={onOrderByChange}
              isReversed={reverseOrder}
              onClickReversed={onReverseOrderChange}
              onClearFilters={onClearFilters}
              onResetOrder={onResetOrder}
            />
          }
          <Tooltip
            title={<Translation value="audit-summary.download.enabled" />}
            down
            center
          >
            {tooltip => (
              <div className={classname(styles['SearchBar__download-icon'], {
                [styles['SearchBar__download-icon--progress']]: exportProgress,
              })}
              >
                {exportProgress
                  ? (
                    <Fragment>
                      <ProgressCircle
                        size={20}
                        width={3}
                        value={Math.max(1, exportProgress)} // start at 2%
                      />
                      <span onClick={() => onCancelExport()}>
                        <InlineSVG src="img/interface/close.svg" />
                      </span>
                    </Fragment>
                  )
                  : <DownloadIcon
                    disabled={false}
                    active
                    size={21}
                    onClick={() => {
                      onExport();
                      tooltip.hide();
                    }}
                  />
                }
              </div>
            )}
          </Tooltip>
        </div>
      </Portal>
    );
  }
}

ProofSearchContainer.defaultProps = {
  search: '',
};

if (process.env.NODE_ENV !== 'production') {
  ProofSearchContainer.propTypes = {
    search: PropTypes.string,
    placeholderTranslationKey: PropTypes.string,
    extendedPlaceholderTranslationKey: PropTypes.string,
    onSearchChange: PropTypes.func,
    selectedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedDateFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
    orderBy: PropTypes.string,
    toggleFilter: PropTypes.func.isRequired,
    toggleDateFilter: PropTypes.func.isRequired,
    onOrderByChange: PropTypes.func.isRequired,
    onCancelExport: PropTypes.func,
    onClearFilters: PropTypes.func,
  };
}

export default ProofSearchContainer;
