import shadowContainer from '../../../util/shadow-container';

export default function renderImage(blob, size) {
  return new Promise((resolve, reject) => {
    const blobUrl = URL.createObjectURL(blob);

    const videoElement = document.createElement('video');
    videoElement.muted = true;
    videoElement.src = blobUrl;
    videoElement.style.maxWidth = size + 'px';
    videoElement.style.maxHeight = size + 'px';
    videoElement.style.opacity = 0;
    videoElement.style.position = 'absolute';
    videoElement.style.top = 0;
    videoElement.style.left = 0;
    videoElement.style.zIndex = 1000;
    videoElement.style.pointerEvents = 'none';
    document.body.appendChild(videoElement);

    const canvasElement = document.createElement('canvas');
    const context = canvasElement.getContext('2d');
    shadowContainer.appendChild(canvasElement);

    const cleanup = () => {
      URL.revokeObjectURL(blobUrl);
      videoElement.remove();
      canvasElement.remove();
    };

    videoElement.addEventListener('error', () => {
      cleanup();
      reject(new Error('Failed to load video.'));
    });

    setTimeout(() => {
      cleanup();
      reject(new Error('Rendering video timed out (3 seconds).'));
    }, 3000);

    let attempts = 0;
    videoElement.addEventListener('canplay', function draw() {
      attempts += 1;

      const videoWidth = videoElement.clientWidth;
      const videoHeight = videoElement.clientHeight;

      canvasElement.width = videoWidth;
      canvasElement.height = videoHeight;

      videoElement.style.opacity = 0.00000001;
      context.drawImage(videoElement, 0, 0, videoWidth, videoHeight);
      const imageData = context.getImageData(0, 0, 1, 1).data;
      videoElement.style.opacity = 0;

      let success = false;
      for (let index = 0; index < imageData.length; index += 1) {
        if (imageData[index] !== 0) {
          success = true;
          break;
        }
      }

      if (success) {
        canvasElement.toBlob((thumbnail) => {
          cleanup();
          resolve(thumbnail);
        });
      } else if (attempts >= 10) {
        reject(new Error('Rendering video timed out after 10 attempts.'));
        cleanup();
      } else {
        setTimeout(draw, 100);
      }
    });
  });
}
