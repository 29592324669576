/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { getSavedSession } from '../../../../shared/session';

export function validateUserSession() {
  const session = getSavedSession();
  if (!session || !session.userId) {
    throw new Error('Attempted to create graphql client without a logged in user');
  }
}
