/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { createContext } from 'react';
import { S_BREAK_M } from '../../util/constants';

const FormControlContext = createContext({ minWidthBeforeCompact: S_BREAK_M });

export default FormControlContext;
