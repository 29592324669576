/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useImperativeHandle, useRef } from 'react';
import { withBundle } from '../../Bundle';
import { Translation } from '../../Text';
import { useUserPreferences } from '../../../hooks/useUserPreferences';

const EditorBundle = withBundle(() => import('./Editor'));

const Editor = React.forwardRef((props, ref) => {
  const editorRef = useRef(null);

  const [, userPreferences] = useUserPreferences();

  const getValue = () => {
    if (editorRef.current) {
      return editorRef.current.getValue();
    } else {
      throw new Error(Translation.text('editor.error.not-finished'));
    }
  };

  const setValue = (value) => {
    if (editorRef.current) {
      return editorRef.current.setValue(value);
    } else {
      throw new Error(Translation.text('editor.error.not-complete'));
    }
  };

  useImperativeHandle(ref, () => ({
    getValue,
    setValue,
  }));

  return (
    <EditorBundle
      userPreferences={userPreferences}
      innerRef={editorRef}
      {...props}
    />
  );
});

export default Editor;
