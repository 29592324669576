/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

export default function canFinalize(proofSetup) {
  return (
    canFinalize.hasOwner(proofSetup) &&
    canFinalize.hasProofs(proofSetup) &&
    canFinalize.allProofsWithFilesHaveFileIds(proofSetup) &&
    canFinalize.isWorkflowValid(proofSetup) &&
    canFinalize.hasValidRecipient(proofSetup)
  );
}

canFinalize.hasOwner = (proofSetup) => {
  return proofSetup.owners.length >= 1;
};

canFinalize.hasValidRecipient = (proofSetup) => {
  return (
    proofSetup.parameters.proofType === 'proof' ||
    proofSetup.proofs.every(brief => window.validateEmail(brief.recipient))
  );
};

canFinalize.hasProofs = (proofSetup) => {
  return proofSetup.proofs.length !== 0;
};

canFinalize.allProofsWithFilesHaveFileIds = (proofSetup) => {
  return proofSetup.proofs.every(proof => (!proof.file || proof.file.id));
};

canFinalize.isWorkflowValid = (proofSetup) => {
  return (
    !proofSetup.workflow || 
    // This situation can occur when a new version is uploaded, as the workflow object only contains the workflow ID
    // of the previous version, and the actual workflow object is loaded in the background.
    proofSetup.workflow.steps
  );
};
