/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import classname from 'classname';
import React from 'react';
import Checkbox from '../../Checkbox';
import HelpBubble from '../../HelpBubble';
import css from './ImportPdfCommentsOption.scss';

export const ImportPdfCommentsOption = (props) => {
  const {
    value,
    onChange,
    label,
    isDisabled,
    helpBubbleMessage,
  } = props;

  return (
    <div
      className={classname(
        css.ImportPdfCommentsOption,
        'unstyled',
        {
          [css['ImportPdfCommentsOption--isDisabled']]: isDisabled,
        }
      )}
    >
      <label className={classname('unstyled', css.ImportPdfCommentsOption__label)}>
        <Checkbox
          selected={value}
          onChange={() => {
            if (!isDisabled) {
              onChange();
            }
          }}
        />
        {label}
      </label>
      {helpBubbleMessage && (
        <HelpBubble
          size="small"
          message={(
            <div className={css.ImportPdfCommentsOption__helpBubble__message}>
              {helpBubbleMessage}
            </div>
          )}
        />
      )}
    </div>
  );
};
