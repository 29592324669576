/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
// source: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors/62640342#62640342

const colorShade = (hexColor, amt) => {
  let color = hexColor.replace(/^#/, '');

  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  const r = parseInt(color.slice(0, 2), 16) + amt;
  const g = parseInt(color.slice(2, 4), 16) + amt;
  const b = parseInt(color.slice(4, 6), 16) + amt;

  return '#' + [r, g, b].map((colorCode) => {
    const convertedCode = Math.max(Math.min(255, colorCode), 0).toString(16);
    return convertedCode.length < 2 ? `0${convertedCode}` : convertedCode;
  }).join('');
};

export default colorShade;
