/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { getSavedSession } from '../../../../shared/session';

export function getAuthHeaders(method) {
  const timestamp = Date.now();
  const session = getSavedSession();

  // eslint-disable-next-line no-undef
  const hmac = forge.hmac.create();
  hmac.start('sha256', session.sharedKey);
  hmac.update(session.token);
  hmac.update(method);
  hmac.update(String(timestamp));
  hmac.update('/graphql');
  // eslint-disable-next-line no-undef
  const final = forge.util.encode64(hmac.digest().data);

  return {
    'x-timestamp': timestamp,
    'x-authorization-token': session.token,
    'x-authorization-hmac': final,
  };
}
