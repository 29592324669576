/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { NavigationPanel } from '../../components/Dashboard';
import DashboardNavigationDropdown from '../DashboardNavigationDropdown';

function separateTabs(tabs, showBriefTab) {
  const tabsArrays = {
    staticTabs: [],
    searchTabs: [],
    groupTabs: [],
    currentCollectionTabs: [],
    archivedCollectionTabs: [],
    newUserTabs: [
      {
        label: 'dashboard.tab.new-user.owner',
        href: 'https://pageproof.com/guide/PageProof_QuickGuide_ProofOwners.pdf',
      },
      {
        label: 'dashboard.tab.new-user.reviewer',
        href: 'https://pageproof.com/guide/PageProof_QuickGuide.pdf',
      },
    ],
    newUserVideoTab:
      {
        label: 'dashboard.tab.new-user.reviewer-experience',
        href: 'https://www.youtube.com/watch?v=uO8XZ-UpSdI',
      },
  };
  Object.keys(tabs).forEach((tab) => {
    if (tab === 'group') {
      tabsArrays.groupTabs.push(tabs[tab]);
    } else if (tab === 'proof') {
      // do nothing
    } else if (tabs[tab].isCollection) {
      if (tabs[tab].isArchived) {
        tabsArrays.archivedCollectionTabs.push(tabs[tab]);
      } else {
        tabsArrays.currentCollectionTabs.push(tabs[tab]);
      }
    } else if (tabs[tab].isStatic) {
      if ((tab === 'brief' && showBriefTab) ||
        (tab !== 'brief' && ((tab !== 'sent' && tab !== 'outbox') || ((tab === 'sent' || tab === 'outbox') && tabs[tab].proofCount > 0)))) {
        tabsArrays.staticTabs.push(tabs[tab]);
      }
    } else {
      tabsArrays.searchTabs.push(tabs[tab]);
    }
  });
  tabsArrays.searchTabs.reverse();
  return tabsArrays;
}

function DashboardNavigationContainer(props) {
  const tabs = separateTabs(props.tabData, props.showBrief);
  const selectedTabObject = props.tabData[props.selectedTab];
  return (
    <div>
      <NavigationPanel
        tabs={tabs}
        {...props}
      />
      <DashboardNavigationDropdown
        tabs={tabs}
        selectedTabObject={selectedTabObject}
        {...props}
      />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  DashboardNavigationContainer.propTypes = {
    tabData: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedTab: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    showBrief: PropTypes.bool.isRequired,
  };
}

export default DashboardNavigationContainer;
