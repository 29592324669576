/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from './createIcon';

export default createIcon({
  displayName: 'SearchIcon',
  src: 'img/interface/search.svg',
  activeColor: '#8c8c8c',
  defaultSize: 30,
});
