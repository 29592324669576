/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import ConfirmBox from '../ConfirmBox';
import Reveal from '../Reveal';
import { Button } from '../Button';

function ProfileTeam({
  user,
  onRemoveUserFromTeam,
}) {
  const [showConfirm, toggleShowConfirm] = useState(false);
  const userDomain = user.Email.substring(user.Email.indexOf('@'));

  return (
    <Fragment>
      <div className="DomainAdminProfile__access__subheading">
        <Translation value="domain-admin.user-profile.team" />
      </div>
      <div className="DomainAdminProfile__access__remove-user">
        {!user.CanRemove &&
          <Translation
            value="domain-admin.user-profile.team.can-not-remove"
            params={{ domain: userDomain }}
          />
        }
        {!showConfirm && user.CanRemove &&
          <Fragment>
            <div className="DomainAdminProfile__access__remove-user__confirm">
              <Translation value="domain-admin.user-profile.remove-user.header" />
            </div>
            <Button
              variant="danger"
              label={<Translation value="button.remove-from-team" />}
              onClick={() => toggleShowConfirm(true)}
            />
          </Fragment>
        }
      </div>
      <Reveal
        duration={500}
        visible={showConfirm}
        align="bottom"
        immediate={false}
      >
        <ConfirmBox
          message={user.Activated
            ? 'domain-admin.user-profile.remove-user.message'
            : 'domain-admin.user-profile.remove-not-activated-user.message'
          }
          buttons={[
            {
              ...ConfirmBox.CancelButton,
              onClick: () => toggleShowConfirm(false),
            },
            {
              id: 'remove-button',
              type: 'danger',
              label: 'button.yes-remove',
              activeLabel: 'button.remove.active',
              onClick: () => onRemoveUserFromTeam(user),
            },
          ]}
        />
      </Reveal>
    </Fragment>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ProfileTeam.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    onRemoveUserFromTeam: PropTypes.func.isRequired,
  };
}

export default ProfileTeam;
