/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTimers} from '../../hoc/Timers';
import {Translation} from '../Text';
import {Button} from '../Button';
import {setPromptDismissed} from '../../util/local-storage-utils';
import {ScaledUpAppearing} from '../Transitions';
import Prompt from '../Prompt';

const MAXIMUM_ATTEMPTS_NUMBER = 5;
const DASHBOARD = /https?:\/\/[^/]+(:\d+)?\/dashboard\//;
class PromptExtension extends Component {
  state = {
    currentURL: this.props.currentLocation,
    isInstalled: false,
    pending: true,
    closed: false,
  };

  componentDidMount() {
    this.props.setTimeout(() => {
      this.detectExtension();
    }, 10000);
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {currentURL} = this.state;
    const newUrl = nextProps.currentLocation;
    if (currentURL !== newUrl) {
      this.setState({
        currentURL: newUrl,
      });
    }
  }

  componentWillUnmount() {
    this.props.cancelListener();
  }

  shouldPrompt = () => {
    const {
      isInstalled,
      currentURL,
      pending,
    } = this.state;
    return !(isInstalled || pending) && DASHBOARD.test(currentURL);
  };

  install = () => {
    const browser = window.__pageproof_bridge__.browserService.name;
    const envKey = browser + '_ext_url';

    switch (browser) {
    case 'chrome':
    case 'firefox':
    case 'safari':
    case 'edge': {
      this.close();
      window.open(window.env(envKey), '_blank');
      break;
    }
    default: {
      throw new Error(`Unknown/unsupported browser "${browser}" attempted to install browser extension.`);
    }
    }
  };

  close = () => {
    this.props.cancelListener();
    this.setState({closed: true});
    setPromptDismissed(true);
  };

  detectExtension() {
    let attempts = 0;
    this.setState({pending: true});

    const intId = window.setInterval(() => {
      window.detectExtension()
        .then((isInstalled) => {
          if (isInstalled) {
            this.setState({
              pending: false,
              isInstalled,
            });
            window.clearInterval(intId);
          }
          attempts += 1;
        });
      if (attempts === MAXIMUM_ATTEMPTS_NUMBER) {
        this.setState({pending: false});
        window.clearInterval(intId);
      }
    }, 500);
  }

  render() {
    const {closed} = this.state;
    const isPrompting = this.shouldPrompt();
    return (
      <ScaledUpAppearing>
        {!closed && isPrompting && (
          <Prompt
            title={<Translation value="app.extension.install.title" />}
            message={<Translation value="app.extension.install.text" />}
            action={(
              <Button
                variant="primary"
                label={<Translation value="button.install" />}
                onClick={this.install}
              />
            )}
            onClose={this.close}
          />
        )}
      </ScaledUpAppearing>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  PromptExtension.propTypes = {
    currentLocation: PropTypes.string,
    cancelListener: PropTypes.func,
    setTimeout: PropTypes.func,
  };
}

export default withTimers(PromptExtension);
