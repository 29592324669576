/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// This component allows us to have a video with controls only after the users first mouseover
// Without this the controls will briefly display on screen when the video loads, then disappear
export const VideoWithControls = ({ src, posterSrc, srcType = 'video/mp4', style }) => {
  const [showControls, setShowControls] = useState(false);

  const enableVideoControls = () => {
    if (!showControls) {
      setShowControls(true);
    }
  };

  return (
    <video
      onMouseOver={enableVideoControls}
      onFocus={enableVideoControls}
      width="100%"
      style={style}
      autoPlay
      loop
      muted
      controls={showControls}
      poster={posterSrc}
    >
      <source
        src={src}
        type={srcType}
      />
    </video>
  );
};

export default VideoWithControls;

if (process.env.NODE_ENV !== 'production') {
  VideoWithControls.propTypes = {
    src: PropTypes.string.isRequired,
    srcType: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
  };
}
