/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import css from './MessageBox.scss';
import {Button} from '../Button';

const MessageBox = ({
  heading,
  message,
  closeLabel,
  onClose,
}) => (
  <div className={css.MessageBox}>
    {heading &&
      <h1>{heading}</h1>
    }
    <p>{message}</p>
    <Button
      variant="primary"
      label={closeLabel}
      onClick={onClose}
    />
  </div>
);

export default MessageBox;
