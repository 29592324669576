/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Trash from '../Icon/Trash';
import Gear from '../Icon/Gear';
import { Translation } from '../Text';
import { useI18n } from '../../hooks/useI18n';

function StaticTab({
  selected,
  tab,
  onClick,
  count,
  color,
  from,
  tip,
  onRemove,
  dropAllowed,
  onDrop,
  onManage,
  initialCasing,
}) {
  const { locale } = useI18n();

  let dropRef = null;
  let isActive = false;
  let isInactive = false;
  if (from !== 'team-member-dashboard') {
    const [{ isOver }, drop] = useDrop({
      accept: ['proof'],
      drop: onDrop,
      collect: monitor => ({
        isOver: monitor.isOver(),
        items: monitor.getItem(),
      }),
    });
    dropRef = drop;
    isActive = isOver && dropAllowed;
    isInactive = isOver && !dropAllowed;
  }

  const style = {};
  if (isActive) {
    style.backgroundColor = 'darkgreen';
  } else if (isInactive) {
    style.fontStyle = 'italic';
  }

  return (
    <div
      ref={dropRef}
      style={style}
      className={classname(`VerticalTab VerticalTab--${color}`, {
        'VerticalTab--selected': selected,
        'VerticalTab--canDelete': onRemove,
        'VerticalTab--highlighted': (tab.id === 'outbox'),
        'VerticalTab--initialCasing': initialCasing,
      })}
      onClick={() => onClick(tab.id)}
    >
      <div className="VerticalTab__label">
        {tab.translate
          ? <Translation value={tab.label} />
          : tab.label
        }
        {count > 0 &&
          <span className="VerticalTab__proof-count">
            &nbsp;
            {count}
          </span>
        }
      </div>
      {/* Only show english tip as a hack for the dashboard wrapping weirdly with longer text. Should be removed with the new dashboard. */}
      {locale.match(/^en/) && tip &&
        <span className="VerticalTab__tip">
          <Translation value={`tab.${from}.${tab.id}.tip`} />
        </span>
      }
      <div className="VerticalTab__options">
        {onManage &&
          <span
            role="presentation"
            className="VerticalTab__options__gear"
            onClick={(event) => {
              event.stopPropagation();
              onManage(tab.id);
            }}
          >
            <Gear
              active
              activeColor={from === 'team-dashboard' && '#fff'}
              activeHoverColor={from === 'team-dashboard' && '#eee'}
            />
          </span>
        }
        {onRemove &&
          <span
            className="VerticalTab__options__trash"
            onClick={(e) => { e.stopPropagation(); onRemove(tab.id); }}
          >
            <Trash
              active
              size={16}
              activeColor={from === 'team-dashboard' ? '#fff' : '#555'}
              activeHoverColor={from === 'team-dashboard' ? '#eee' : '#e51c23'}
            />
          </span>
        }
        <InlineSVG
          className="VerticalTab__arrow"
          src="/img/interface/arrow-down.svg"
        />
      </div>
    </div>
  );
}

StaticTab.defaultProps = {
  from: '',
  tip: true,
  dropAllowed: false,
};

if (process.env.NODE_ENV !== 'production') {
  StaticTab.propTypes = {
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    tab: PropTypes.objectOf(PropTypes.any).isRequired,
    count: PropTypes.number,
    color: PropTypes.string.isRequired,
    from: PropTypes.string,
    tip: PropTypes.bool,
    dropAllowed: PropTypes.bool,
    onDrop: PropTypes.func,
    onRemove: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    onManage: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    initialCasing: PropTypes.bool,
  };
}

export default StaticTab;
