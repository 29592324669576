/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Enum } from '@pageproof/sdk';
import { sdk } from '../../util/sdk';
import { getDecisionSummaryProps } from '../../features';
import ProofWorkflowStatusBar from '../../components/ProofWorkflowStatusBar';
import { Spinner } from '../../components/Spinner';

const { APPROVED, APPROVED_WITH_CHANGES, SEND_CHANGES, NO_DECISION } = Enum.Decision;

function mapDecisionsFromService(decisions) {
  const thumbsUpDecisions = { decision: APPROVED, users: [] };
  const approvedWithChangesDecisions = { decision: APPROVED_WITH_CHANGES, users: [] };
  const thumbsDownDecisions = { decision: SEND_CHANGES, users: [] };
  const noDecisions = { decision: NO_DECISION, users: [] };
  decisions.forEach((decisionItem) => {
    const user = {
      ...decisionItem.user,
      role: decisionItem.role,
      nudgedDate: new Date(decisionItem.lastNudgedDate),
    };

    switch (decisionItem.decision.id) {
    case APPROVED:
      thumbsUpDecisions.users = [user, ...thumbsUpDecisions.users];
      break;
    case APPROVED_WITH_CHANGES:
      approvedWithChangesDecisions.users = [user, ...approvedWithChangesDecisions.users];
      break;
    case SEND_CHANGES:
      thumbsDownDecisions.users = [user, ...thumbsDownDecisions.users];
      break;
    case NO_DECISION:
    default:
      noDecisions.users = [user, ...noDecisions.users];
      break;
    }
  });
  return [thumbsUpDecisions, approvedWithChangesDecisions, thumbsDownDecisions, noDecisions];
}

function mapWorkflowMetricsFromService(workflowMetrics) {
  if (!workflowMetrics) {
    return null;
  }

  const currentStepDueDate = workflowMetrics.currentStepDueDate && new Date(workflowMetrics.currentStepDueDate);

  return {
    ...workflowMetrics,
    currentStepDueDate,
  };
}

const DecisionSummaryContainer = ({
  proofId,
  workflowId,
  canNudgeUsers,
  shouldFireConfetti,
  onAction,
  angularRef,
  hasBorder,
  isOverdue,
  onRefresh,
  canSkipAll,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [decisions, setDecisions] = useState([]);
  const [workflowMetrics, setWorkflowMetrics] = useState();

  function loadData() {
    return Promise.all([
      sdk.proofs.decisions.load(proofId)
        .then(mapDecisionsFromService)
        .then(setDecisions),
      sdk.proofs.getWorkflowMetrics(proofId)
        .then(mapWorkflowMetricsFromService)
        .then(setWorkflowMetrics),
    ]);
  }

  useEffect(() => {
    loadData().then(() => setIsLoading(false));
  }, [proofId]);

  useImperativeHandle(
    angularRef,
    () => ({
      refreshDecisionSummary,
    }),
  );

  if (isLoading) {
    return <Spinner size={40} />;
  }

  const refreshDecisionSummary = () => {
    loadData();
    if (onRefresh) {
      onRefresh();
    }
  };

  const { thumbsUpUsers, approvedWithChangesUsers, thumbsDownUsers, noDecisionUsers, noDecisionGroup } = getDecisionSummaryProps({
    workflowId,
    currentUserId: sdk.session.userId,
    decisionSummary: decisions,
    availableActions: [
      canNudgeUsers ? 'nudge-user' : undefined,
      canNudgeUsers ? 'nudge-all' : undefined,
    ],
    onActionCompleted: () => {
      refreshDecisionSummary();
      if (onAction) {
        onAction();
      }
    },
  });

  const workflowMetricsProps = workflowMetrics
    ? {
      currentStepName: workflowMetrics.currentStepName,
      currentStepDueDate: workflowMetrics.currentStepDueDate,
      totalStepCount: workflowMetrics.totalStepCount,
      visibleStepCount: workflowMetrics.visibleStepCount,
      visibleReviewerPercentage: workflowMetrics.visibleReviewerPercentage,
    }
    : {};

  return (
    <ProofWorkflowStatusBar
      shouldFireConfetti={shouldFireConfetti}
      thumbsUpUsers={thumbsUpUsers}
      approvedWithChangesUsers={approvedWithChangesUsers}
      thumbsDownUsers={thumbsDownUsers}
      noDecisionUsers={noDecisionUsers}
      noDecisionGroup={noDecisionGroup}
      hasBorder={hasBorder}
      isOverdue={isOverdue}
      workflowId={workflowId}
      refreshDecisionSummary={refreshDecisionSummary}
      canSkipAll={canSkipAll}
      {...workflowMetricsProps}
    />
  );
};
export default DecisionSummaryContainer;
