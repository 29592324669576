/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown, {
  Option as DropdownOption,
  OptionLabel as DropdownOptionLabel,
} from '../../components/Dropdown';

function DashboardNavigationDropdown({selectedTab, selectedTabObject, tabs, onSelect, onRemove, proofTab, groupTab}) {
  const tabsArray = tabs.staticTabs.concat(tabs.searchTabs);
  let selectedTabId = '';
  let selectedTabLabel = '';
  if (selectedTabObject) {
    selectedTabId = selectedTabObject.id;
    selectedTabLabel = selectedTabObject.label;
  } else {
    selectedTabId = 'proof';
    if (proofTab) {
      selectedTabLabel = proofTab.title;
    }
  }
  return (
    <div className="navigation__mobile">
      <Dropdown
        selected={selectedTabId}
        variant="light"
        placeholder={
          <DropdownOption
            label={(
              <span>
                {selectedTabLabel}
                {(selectedTabId !== 'proof' && selectedTabId !== '') && (
                  <span className="dashboard__tab__proof-count">
                    {selectedTabObject.proofs.length || ''}
                  </span>
                )}
              </span>
            )}
          />
        }
      >
        {tabsArray.map(tab => (
          <DropdownOption
            key={tab.id}
            onClick={() => onSelect(tab.id)}
            selected={selectedTab === tab.id}
            label={
              <DropdownOptionLabel
                title={(
                  <div className="dashboard__tab__label">
                    {tab.label}
                    <span className="dashboard__tab__proof-count">
                      {tab.proofCount || tab.proofs.length || ''}
                    </span>
                    {!tab.isStatic && (
                      <span
                        className="dashboard__search__close"
                        onClick={() => onRemove(tab.id)}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                )}
              />
            }
          />
        ))}
        {proofTab && (
          <DropdownOption
            onClick={() => onSelect('proof')}
            selected={selectedTab === 'proof'}
            label={
              <DropdownOptionLabel
                title={(
                  <div className="dashboard__tab__label">
                    {proofTab.title}
                    <span
                      className="dashboard__search__close"
                      onClick={() => onRemove('proof')}
                    >
                      &times;
                    </span>
                  </div>
                )}
              />
            }
          />
        )}
        {groupTab && (
          <DropdownOption
            onClick={() => onSelect('group')}
            selected={selectedTab === 'group'}
            label={
              <DropdownOptionLabel
                title={(
                  <div className="dashboard__tab__label">
                    {groupTab.label}
                  </div>
                )}
              />
            }
          />
        )}
      </Dropdown>
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  DashboardNavigationDropdown.propTypes = {
    selectedTab: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    selectedTabObject: PropTypes.objectOf(PropTypes.any),
    proofTab: PropTypes.objectOf(PropTypes.any),
    groupTab: PropTypes.objectOf(PropTypes.any),
    tabs: PropTypes.objectOf(PropTypes.any).isRequired,
    onSelect: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
  };
}
export default DashboardNavigationDropdown;
