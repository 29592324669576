/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import AdditionalText from '../AdditionalText';
import InlineSpinner from '../../Spinner/InlineSpinner';
import Translation from '../../Text/Translation';
import css from './Error.scss';

function Error() {
  return (
    <AdditionalText>
      <InlineSpinner size={20} />
      <span className={css.Message}>
        <Translation value="comment.offline.message" />
      </span>
    </AdditionalText>
  );
}

export default Error;
