/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Translation from '../Text/Translation';
import { Separator } from '../PopupMenu';
import Checkbox from '../Checkbox';
import Reveal from '../Reveal';
import css from './ProofsSelectionSummary.scss';

const ProofsSelectionSummary = ({
  selectableProofsCount,
  selectedProofsCount,
  onToggleSelectionOfAllProofs,
  visible,
  selectedTab,
  searchStr,
}) => {
  /* eslint-disable no-nested-ternary */
  const type = !selectableProofsCount
    ? 'zero'
    : selectableProofsCount > 1
      ? 'multi'
      : 'single';
  const hasAllProofsSelected = selectableProofsCount && selectableProofsCount === selectedProofsCount;

  const getAvailableToSelectI18nKey = () => {
    switch (selectedTab) {
    case 'search':
      return `dashboard.proof-selection.available-to-select.search.${type}`;
    case 'brief':
      return `dashboard.proof-selection.available-to-select.brief.${type}`;
    default:
      return `dashboard.proof-selection.available-to-select.${type}`;
    }
  };

  return (
    <Reveal
      visible={visible}
      align="bottom"
      minHeight={visible ? 120 : 0}
    >
      <div className={css.ProofsSelectionSummary}>
        <Translation
          value={getAvailableToSelectI18nKey()}
          params={{
            count: selectableProofsCount,
            tab: selectedTab,
            searchStr,
          }}
        />
        {selectedProofsCount > 0 &&
          <div className={css.ProofsSelectionSummary__selected}>
            <Translation
              value="dashboard.proof-selection.selected"
              params={{ count: selectedProofsCount }}
            />
          </div>
        }
        <div className={css.ProofsSelectionSummary__checkboxWrapper}>
          <Checkbox
            selected={hasAllProofsSelected}
            onChange={onToggleSelectionOfAllProofs}
            isDisabled={!selectableProofsCount}
          />
          <span className={css.ProofsSelectionSummary__checkboxWrapper__text}>
            <Translation value="dashboard.proof-selection.select-all" />
          </span>
        </div>
        <Separator variant="light" />
      </div>
    </Reveal>
  );
};

export default ProofsSelectionSummary;
