/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import FileDrop from '../../components/FileDrop';
import FormControl from '../../components/FormControl';
import { Validation, Translation } from '../../components/Text';
import Pill from '../../components/Pill';

class UploadFile extends Component {
  state = {
    active: false,
    error: null,
  };

  selectFile(file) {
    const {
      onValidate,
      onSelect
    } = this.props;

    if (onValidate) {
      onValidate(file, (err) => {
        this.setState({
          error: err && err.message,
        });
        if (!err) {
          onSelect(file);
        }
      });
    } else {
      onSelect(file);
    }
  }

  render() {
    const {
      active,
      error,
    } = this.state;
    const {
      disabled,
      title,
      accept,
    } = this.props;
    return (
      <FormControl
        compact
        message={
          error && <Validation>{error}</Validation>
        }
      >
        {disabled
          ? (
            <label className="BrandingUploadFile BrandingUploadFile--inactive unstyled">
              <h3>
                {title}
              </h3>
              <div className="BrandingUploadFile__enterprise-only">
                <Pill label={<Translation value="label.available-on-enterprise" />} />
              </div>
            </label>
          ) : (
            <FileDrop
              onSelect={file => this.selectFile(file)}
              onEnter={() => this.setState({ active: true })}
              onLeave={() => this.setState({ active: false })}
            >
              <label
                className={classname('BrandingUploadFile unstyled', {
                  'BrandingUploadFile--active': active,
                })}
              >
                <input
                  accept={accept}
                  type="file"
                  className="invisible"
                  onChange={(event) => {
                    if (event.target.files.length) {
                      this.selectFile(event.target.files[0]);
                    }
                  }}
                />
                <h3>
                  {title}
                </h3>
                <p>
                  <Translation value="domain-admin.menu.branding.email.banner-select" />
                </p>
              </label>
            </FileDrop>
          )
        }
      </FormControl>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  UploadFile.propTypes = {
    title: PropTypes.string.isRequired,
    onValidate: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    accept: PropTypes.string,
  };
}

export default UploadFile;
