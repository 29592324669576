/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import EmailPreference from './EmailPreference';

const EmailPreferences = ({ preferences, emailPreferences, onUpdateEmailPreference }) => (
  preferences.map(preference => (
    <EmailPreference
      key={preference.type}
      value={emailPreferences[preference.type]}
      batchValue={preference.batchType && emailPreferences[preference.batchType]}
      onUpdateEmailPreference={onUpdateEmailPreference}
      {...preference}
    />
  ))
);

export default EmailPreferences;
