/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import { Button } from '../Button';
import ButtonGroup from '../ButtonGroup';
import { Translation, Ellipsis } from '../Text';

const ACTION_STATUS = { CANCELLING: 'cancelling', RESENDING: 'resending', RESENT: 'resent' };

function ProfileUserInvitation({
  user,
  onResendInvitation,
  onCancelInvitation,
}) {
  const [actionStatus, setActionStatus] = useState(null);

  const handleOnCancelInvitation = () => {
    setActionStatus(ACTION_STATUS.CANCELLING);
    onCancelInvitation(user.InvitationId);
  };

  const handleOnResendInvitation = () => {
    setActionStatus(ACTION_STATUS.RESENDING);
    onResendInvitation(user.Email).then(() => setActionStatus(ACTION_STATUS.RESENT));
  };

  return (
    <Fragment>
      <br />
      <br />
      {actionStatus === ACTION_STATUS.RESENT
        ? <Translation value="team-dashboard.invite-sent.message.user-invited" params={{ email: <b>{user.Email}</b> }} />
        : (
          <ButtonGroup>
            <Button
              variant="orange"
              onClick={handleOnCancelInvitation}
              label={actionStatus === ACTION_STATUS.CANCELLING
                ? (
                  <Fragment>
                    <Translation value="domain-admin.user-profile.button.cancel-invite.active" />
                    <Ellipsis />
                  </Fragment>
                )
                : <Translation value="domain-admin.user-profile.button.cancel-invite" />}
            />
            <Button
              variant="primary"
              onClick={handleOnResendInvitation}
              label={actionStatus === ACTION_STATUS.RESENDING
                ? (
                  <Fragment>
                    <Translation value="domain-admin.user-profile.button.resend-invite.active" />
                    <Ellipsis />
                  </Fragment>
                )
                : <Translation value="domain-admin.user-profile.button.resend-invite" />}
            />
          </ButtonGroup>
        )
      }
    </Fragment>
  );
}

export default ProfileUserInvitation;
