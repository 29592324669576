/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment, useRef } from 'react';
import css from './SharedSetup.scss';

import TextareaWithTools from '../../TextareaWithTools';
import FormControl from '../../FormControl';
import IntegrationReferences from '../../IntegrationReferences';
import Tags from '../../../containers/Tags';
import Switch from '../../Switch/Switch';
import DueDateAndReminders from './DueDateAndReminders';
import InputLimitCounter from '../../InputLimitCounter';
import getShared from '../utils/finalize/getShared';
import guardOnChangeLength from '../../../util/guardOnChangeLength';
import { MAX_PROOF_REFERENCE_LENGTH } from '../../../util/constants';
import IntegrationReferenceSelector from './IntegrationReferenceSelector'
import InputOptions from './InputOptions';
import CollectionInProofSetup from './CollectionInProofSetup';
import FloatingLabelDescription from './FloatingLabelDescription';
import { TranslatedProps, Translation } from '../../Text';

const SharedSetup = ({ parameters, shared: sourceShared, userPreferences, userFeatures, onChange, importedTags }) => {
  const messageToReviewersRef = useRef(null);
  const shared = getShared(sourceShared, parameters, userPreferences);

  const handleChange = () => {
    shared._isEdited = true;
    onChange(shared);
  };

  return (
    <div className={css.SharedSetup}>
        <CollectionInProofSetup
          autoFocus
          className={css.SharedSetup__collectionName}
          shared={shared}
          canShareCollectionsWithTeam={userFeatures.canShareCollectionsWithTeam}
          onChange={onChange}
          helpBubble={!shared.collection && !parameters.addToCollection}
        />
      <FormControl label={<Translation value='create.proof.tags' />}>
        <Tags
          tags={shared.tags}
          importedTags={importedTags}
          onChange={(tags) => {
            shared.tags = tags;
            handleChange();
          }}
        />
      </FormControl>
      {userFeatures.hasProofReferences && (
        <Fragment>
          <FormControl
            label={<Translation value="create.proof.reference" />}
            after={
              <IntegrationReferences
                removeReference={referenceId => {
                  shared.integrationReferences = shared.integrationReferences.filter(reference => reference.referenceId !== referenceId);
                  handleChange();
                }}
                integrationReferences={shared.integrationReferences}
              />
            }
          >
            <TranslatedProps placeholder="create.proof.reference.placeholder">
              <TextareaWithTools
                tools={
                  <IntegrationReferenceSelector
                    isCurrentUserInProofTeam
                    onSelectReferenceOption={(reference) => {
                      if (shared.integrationReferences) {
                        shared.integrationReferences.push(reference);
                      } else {
                        shared.integrationReferences = [reference];
                      }
                      handleChange();
                    }}
                    integrationReferences={shared.integrationReferences || []}
                  />
                }
                variant="compact"
                value={shared.reference}
                onChange={guardOnChangeLength(shared.reference, MAX_PROOF_REFERENCE_LENGTH, (reference) => {
                  shared.reference = reference;
                  handleChange();
                })}
              />
            </TranslatedProps>
            <InputLimitCounter
              value={shared.reference}
              limit={MAX_PROOF_REFERENCE_LENGTH}
            />
          </FormControl>
        </Fragment>
      )}
      <FormControl
        label={<Translation value="create.proof.message-to-reviewers" />}
      >
        <TextareaWithTools
          tools={
            <InputOptions
              options={
                <React.Fragment>
                  <InputOptions.Option
                    label={<Translation value="create.proof.message-to-reviewers.use-my-default" />}
                    onClick={() => {
                      shared.messageToReviewers = userPreferences.messageToReviewers.value;
                      handleChange();
                    }}
                  />
                  <InputOptions.Separator />
                  <InputOptions.Option
                    label={<Translation value="button.clear" />}
                    onClick={() => {
                      shared.messageToReviewers = '';
                      messageToReviewersRef.current.focus()
                      handleChange();
                    }}
                  />
                </React.Fragment>
              }
            />
          }
          ref={messageToReviewersRef}
          variant="compact"
          value={shared.messageToReviewers}
          onChange={(messageToReviewers) => {
            shared.messageToReviewers = messageToReviewers;
            handleChange();
          }}
        />
      </FormControl>
      <FormControl label={<Translation value="create.proof.due-date" />}>
        <DueDateAndReminders
          dueDate={shared.dueDate}
          onDueDateChange={(dueDate) => {
            shared.dueDate = dueDate;
            handleChange();
          }}
          reminders={shared.reminders}
          onRemindersChange={(reminders) => {
            shared.reminders = reminders;
            handleChange();
          }}
        />
      </FormControl>
      <FormControl label={<Translation value="create.proof.can-download" />}>
        <Switch
          value={shared.canDownload}
          onChange={(canDownload) => {
            shared.canDownload = canDownload;
            handleChange();
          }}
        />
      </FormControl>
      {userFeatures.hasPublicProofs && (
        <FormControl
          label={
            <Fragment>
              <Translation value="create.proof.can-share-link" />
              <a
                href="http://help.pageproof.com/en/articles/5251788-what-is-a-proof-share-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FloatingLabelDescription
                  description={<Translation value="create.proof.can-share-link.help" />}
                />
              </a>
            </Fragment>
          }
        >
          <Switch
            value={shared.isPublic}
            onChange={(isPublic) => {
              shared.isPublic = isPublic;
              handleChange();
            }}
          />
        </FormControl>
      )}
      {userFeatures.hasCommentVisibility && (
        <FormControl
          label={
            <Fragment>
              <Translation value="create.proof.invisible-reviewer-comments" />
              <a
                href="http://go.pageproof.com/help/invisible-reviewer-comments"
                target="_blank"
              >
                <FloatingLabelDescription
                  description={<Translation value="create.proof.invisible-reviewer-comments.help" />}
                />
              </a>
            </Fragment>
          }
        >
          <Switch
            value={shared.commentVisibility === 'commentCreatorOrProofTeam'}
            onChange={(commentVisibility) => {
              shared.commentVisibility = commentVisibility ? 'commentCreatorOrProofTeam' : null;;
              handleChange();
            }}
          />
        </FormControl>
      )}
    </div>
  );
};

export default SharedSetup;
