/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {Component} from 'react';

class UserEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
    this.userId = this.props.id;
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    this.getEmail(this.userId);
  }

  getEmail(id) {
    window.__pageproof_bridge__.
      userRepositoryService
      .get(id)
      .then((data) => {
        this.setState({
          email: data.email,
        });
      });
  }

  render() {
    const email = this.state.email;
    return (
      <div>
        {email}
      </div>
    );
  }
}

export default UserEmail;
