/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Fragment } from 'react';
import { util } from '@pageproof/sdk';
import { hasValue } from '../Editor/serialization';
import { KeepVirtualListItemMounted } from '../../../containers/CommentsPane/components/KeepVirtualListItemMounted';
import Comment from '../Comment';
import Translation from '../../Text/Translation';

class CreateCommentContainer extends Component {
  constructor(props) {
    super(props);

    const userId = this.props.user && this.props.user.id;

    const commentVisibilityDefault = this.getCommentVisibilityDefault(userId);
    this.state = {
      date: new Date(),
      value: props.initialValue,
      attachments: null,
      isPrivate: this.props.canTogglePrivate && commentVisibilityDefault ? commentVisibilityDefault.Value === 'private' : false,
      mentionsCount: util.comments.mentions(props.initialValue).length,
      isLoading: true,
      userPreferences: null,
    };
  }

  getCommentVisibilityDefault = (userId) => {
    const rawPreferences = window.localStorage.getItem(`pageproof.cache.user-preferences.${userId}`);
    const userPreferences = rawPreferences ? JSON.parse(rawPreferences) : null;
    return userPreferences ? userPreferences.find(pref => pref.Name === 'commentVisibilityDefault') : null;
  }


  onChange = (value) => {
    this.setState(() => ({
      value,
      mentionsCount: util.comments.mentions(value).length,
    }));
  }

  onAttach = (files) => {
    const { attachments } = this.state;
    this.setState({
      attachments: attachments ? [...attachments, ...files] : files,
    });
  }

  onAttachmentDelete = (fileName) => {
    const { attachments } = this.state;
    this.setState({
      attachments: attachments.filter(attachment => attachment.name !== fileName),
    });
  }

  getAttachmentProps = attachments => attachments.map(attachment => ({
    name: attachment.name,
    canDelete: true,
    onDelete: () => this.onAttachmentDelete(attachment.name),
  }));

  onCreate = () => {
    if (this.props.canSave && hasValue(this.state.value)) {
      this.props.onCreate({
        value: this.state.value,
        attachments: this.state.attachments,
        isPrivate: this.state.isPrivate,
      });
    }
  }

  onTogglePrivate = () => {
    this.setState(prevState => ({
      isPrivate: !prevState.isPrivate,
    }));
  }

  render() {
    const { attachments, value } = this.state;
    return (
      <Fragment>
        <KeepVirtualListItemMounted />
        <Comment
          {...this.props}
          {...this.state}
          attachments={attachments && this.getAttachmentProps(attachments)}
          canSave={this.props.canSave && hasValue(value) && (!this.state.isPrivate || !this.state.mentionsCount)}
          onChange={this.onChange}
          onSave={this.onCreate}
          onAttach={this.onAttach}
          onTogglePrivate={this.onTogglePrivate}
          canTogglePrivate={this.props.canTogglePrivate && !this.state.mentionsCount}
          togglePrivateDisabledMessage={this.state.mentionsCount > 0 && this.props.canTogglePrivate ? 'Comments with mentions cannot be private' : null}
          placeholder={
            <Translation value="comment-create.placeholder" />
          }
          canMark={false}
          canCancel
          isEditing
          isCreate
        />
      </Fragment>
    );
  }
}

export default CreateCommentContainer;
