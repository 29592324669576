/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useMemo } from 'react';

const useIsMobile = () => useMemo(() => (
  window.__pageproof_bridge__.browserService.is('mobile') &&
  !window.__pageproof_bridge__.browserService.is('tablet')
), []);

export default useIsMobile;
