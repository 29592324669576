import React from 'react';
import { sdk } from '../../util/sdk';
import IntegrationReferences from '../../components/IntegrationReferences';

const IntegrationReferencesContainer = ({ proofId, integrationReferences, onChange, readOnly }) => {
  const removeReference = (referenceId) => {
    sdk.proofs.integrationReferences.remove(proofId, referenceId)
      .then(() => onChange(integrationReferences.filter(reference => reference.referenceId !== referenceId)));
  };

  if (!integrationReferences || !integrationReferences.length) {
    return null;
  }

  return (
    <IntegrationReferences
      integrationReferences={integrationReferences}
      removeReference={removeReference}
      readOnly={readOnly}
    />
  );
};

export default IntegrationReferencesContainer;
