import { createContext, useContext } from 'react';

const context = createContext('spacious'); // eg. "spacious" or "condensed"
context.displayName = 'CommentSpacing';

export default context;

export const { Provider, Consumer } = context.Provider;

export const useCommentSpacing = () => useContext(context);
