/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Translation } from '../components/Text';

export const getInitialSelectionState = files => files.reduce((accum, file) => ({
  ...accum,
  [file.id]: true,
}), {});

export const mapFileForSelection = (file, selection, downloadProgress, errors) => {
  const selected = selection[file.id];
  return {
    id: file.id,
    name: file.name,
    selected,
    progress: downloadProgress[file.id],
    ...(errors && { error: errors[file.id] }),
  };
};

export const getSelectedIdsToDownload = selection => Object.keys(selection).filter(id => selection[id] === true);

export const selectAllIds = (onSelectChange, ids, allSelected) => (
  onSelectChange(ids.reduce((selection, id) => {
    selection[id] = !allSelected; // eslint-disable-line
    return selection;
  }, {})));

export const getDownloadButtonLabel = (status, downloadCount) => {
  if (status) {
    return (
      <Translation
        value={status === 'downloading'
          ? 'proof.download.status.downloading'
          : 'proof.download.status.compressing'
        }
      />
    );
  }
  if (downloadCount && downloadCount === 1) {
    return <Translation value="proof.download.button.download-file" />;
  }
  if (downloadCount && downloadCount > 1) {
    return <Translation value="proof.download.button.download-file.multi" params={{ count: downloadCount }} />;
  }
  return <Translation value="proof.download.button.download" />;
};
