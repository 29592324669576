/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Translation from '../Text/Translation';
import css from './NotVisibleOverlay.scss';

function NotVisibleOverlay({
  replyCount,
  onClick,
  label,
}) {
  return (
    <div
      className={css.NotVisibleOverlay}
      onClick={onClick}
    >
      <Translation
        value={label}
        params={{replyCount}}
      />
    </div>
  );
}

export default NotVisibleOverlay;
