/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import { Tabs, Tab } from '../../components/Tabs';
import Form from '../../components/Form';
import { Translation } from '../../components/Text';
import { Heading } from '../../components/Page';
import { Spinner } from '../../components/Spinner';
import Email from './Email';
import Logo from './Logo';

const settings = {
  email: {
    component: Email,
    label: <Translation value="domain-admin.menu.branding.email.tab-label" />,
  },
  logo: {
    component: Logo,
    label: <Translation value="domain-admin.menu.branding.logo.tab-label" />,
  },
};

class BrandingSettings extends Component {
  state = {
    tab: Object.keys(settings)[0],
  };

  render() {
    const { tab } = this.state;
    const { loading } = this.props;
    return (
      <Form>
        <div className="BrandingSettings">
          <Heading
            title={<Translation value="domain-admin.menu.branding.add" />}
            description={<Translation value="domain-admin.menu.branding.description" />}
          />
          <Tabs selected={tab}>
            {Object.keys(settings).map((id) => {
              const TabComponent = settings[id].component;
              return (
                <Tab
                  key={id}
                  id={id}
                  name={settings[id].label}
                  onClick={() => this.setState({ tab: id })}
                >
                  {/* the condition below prevents any inactive tabs from building a vdom */}
                  {tab === id &&
                    (loading[id].tab
                      ? <Spinner
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          marginTop: 80,
                        }}
                      />
                      : <TabComponent
                        {...this.props}
                        loading={loading[id]}
                      />)
                  }
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </Form>
    );
  }
}

export default BrandingSettings;
