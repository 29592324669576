/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import firstNotNullOrUndefined from '../firstNotNullOrUndefined';
import firstNotUndefined from '../firstNotUndefined';
import getFallbackDueDateAndTime from '../getFallbackDueDateAndTime';
import { getFallbackCanDownload } from '../canDownload';
import { getSharedWithTeamLocalPreference } from '../../../../util/collections';
import getFallbackReminders from '../getFallbackReminders';
import getFallbackIsPublic from '../getFallbackIsPublic';
import getFallbackCommentVisibility from '../getFallbackCommentVisibility';

export default function getShared(shared, parameters, userPreferences) {
  return {
    ...shared,
    messageToReviewers: firstNotNullOrUndefined(
      shared.messageToReviewers,
      parameters.defaultMessageToReviewers,
      (
        userPreferences &&
        userPreferences.messageToReviewers &&
        userPreferences.messageToReviewers.value
      ),
      '',
    ),
    tags: shared.tags || [],
    reminders: firstNotNullOrUndefined(
      shared.reminders,
      getFallbackReminders(userPreferences),
    ),
    dueDate: firstNotNullOrUndefined(
      shared.dueDate,
      getFallbackDueDateAndTime(userPreferences),
    ),
    canDownload: firstNotNullOrUndefined(
      shared.canDownload,
      getFallbackCanDownload(),
    ),
    isPublic: firstNotNullOrUndefined(
      shared.isPublic,
      getFallbackIsPublic(),
    ),
    isOwnedByTeam: firstNotNullOrUndefined(
      shared.isOwnedByTeam,
      getSharedWithTeamLocalPreference(),
    ),
    reference: firstNotNullOrUndefined(
      shared.reference,
      '',
    ),
    collection: firstNotNullOrUndefined(
      shared.collection,
      '',
    ),
    commentVisibility: firstNotUndefined(
      shared.commentVisibility,
      getFallbackCommentVisibility(),
    ),
  };
}
