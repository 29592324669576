/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '../FormControl';
import {Translation} from '../Text/index';
import Dropdown, {
  Option as DropdownOption,
  OptionLabel as DropdownOptionLabel,
} from '../Dropdown';

function WorkflowDropdown({
  workflows,
  selectedWorkflow,
  onItemSelected
}) {
  let selected = selectedWorkflow;
  for (let i = 0; i < workflows.length; i += 1) {
    if (workflows[i].value === selected) {
      selected = workflows[i].name;
      break;
    }
  }
  const regex = /[^\w]/g;
  return (
    <div className="WorkflowDropdown">
      {workflows.length > 1 && (
        <FormControl
          label={<Translation value="proof.setup.workflow" />}
        >
          <Dropdown
            selected={Translation.text(selected)}
            variant="light"
            placeholder={<DropdownOption label={Translation.text(selected)} />}
            keyDownScroll
            regex={regex}
          >
            {workflows.map(workflow => (
              <DropdownOption
                key={workflow.value}
                onClick={() => onItemSelected(workflow.value)}
                selected={selected === Translation.text(workflow.name)}
                label={<DropdownOptionLabel title={Translation.text(workflow.name)} />}
                name={workflow.name.replace(regex, '')}
              />
            ))}
          </Dropdown>
        </FormControl>
      )}
    </div>
  );
}

WorkflowDropdown.propTypes = {
  workflows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  selectedWorkflow: PropTypes.string.isRequired,
  onItemSelected: PropTypes.func.isRequired,
};

export default WorkflowDropdown;
