/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {withBundle} from '../Bundle';

const DatePickerBundle = withBundle(
  () => import('./DatePicker')
);

export default DatePickerBundle;
