/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState, useEffect } from 'react';
import FormControl from '../../FormControl';
import TextareaWithTools from '../../TextareaWithTools';
import { sdk } from '../../../util/sdk';
import InputOptionsSearchSelector from '../../InputOptionsSearchSelector';
import FloatingInputDescription from './FloatingInputDescription';
import FloatingLabelDescription from './FloatingLabelDescription';
import guardOnChangeLength from '../../../util/guardOnChangeLength';
import InputLimitCounter from '../../InputLimitCounter';
import { MAX_COLLECTION_NAME_LENGTH } from '../../../util/constants';
import { Translation, TranslatedProps } from '../../Text';
import Reveal from '../../Reveal';
import Switch from '../../Switch';
import css from './CollectionInProofSetup.scss';

const CollectionInProofSetup = ({
  shared,
  className,
  autoFocus,
  helpBubble,
  canShareCollectionsWithTeam,
  onChange,
}) => {
  const [availableCollections, setAvailableCollections] = useState([]);
  const { collection, addToCollection } = shared;
  const isCreatingNewCollection = collection && (!addToCollection || !addToCollection.id);

  const handleChange = (updatedCollection) => {
    const newShared = {
      collection: updatedCollection.name,
      addToCollection: updatedCollection.id ? updatedCollection : null,
      _isEdited: true,
    };
    onChange({ ...shared, ...newShared });
  };

  useEffect(() => {
    sdk.dashboard.groups.current()
      .then((groups) => {
        setAvailableCollections(groups.filter(group => group.canAddProofs));
      });
  }, []);

  useEffect(() => {
    if (availableCollections && addToCollection && addToCollection.id) {
      const matchingCollectionFromId = availableCollections.find(group => group.id === addToCollection.id);
      if (matchingCollectionFromId) {
        handleChange(matchingCollectionFromId);
      }
    }
  }, [availableCollections]);

  return (
    <FormControl label={<Translation value="proof.setup.collection-name" />}>
      {helpBubble && (
        <div className={css.CollectionInProofSetup__helpBubble}>
          <FloatingInputDescription
            description={<Translation value="proof.setup.collection.tooltip" />}
          />
        </div>
      )}
      <TranslatedProps placeholder="proof.setup.collection.placeholder">
        <TextareaWithTools
          tools={
            <InputOptionsSearchSelector
              options={availableCollections}
              onSelect={handleChange}
            />
          }
          autoFocus={autoFocus}
          variant="compact"
          className={className}
          value={collection}
          onChange={guardOnChangeLength(collection, MAX_COLLECTION_NAME_LENGTH, (collectionName) => {
            handleChange({ name: collectionName });
          })}
        />
      </TranslatedProps>
      <InputLimitCounter
        value={collection}
        limit={MAX_COLLECTION_NAME_LENGTH}
      />
      <Reveal visible={!!(canShareCollectionsWithTeam && isCreatingNewCollection)}>
        <div style={{ padding: '1px 0' }}>
          <FormControl
            label={
              <Fragment>
                <Translation value="proof.setup.collection.share-with-my-team" />
                <FloatingLabelDescription
                  description={<Translation value="proof.setup.collection.share-with-my-team.help" />}
                />
              </Fragment>
            }
          >
            <Switch
              value={shared.isOwnedByTeam}
              onChange={(isOwnedByTeam) => {
                onChange({ ...shared, isOwnedByTeam });
              }}
            />
          </FormControl>
        </div>
      </Reveal>
    </FormControl>
  );
};

export default CollectionInProofSetup;
