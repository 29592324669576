import React from 'react';

class ZapierEmbed extends React.Component {
  constructor(props) {
    super(props);
    this.id = '' + Math.floor(Math.random() * 10000);
  }

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://zapier.com/apps/embed/widget.js?services=pageproof&limit=10&html_id=' + this.id;
    this.instance.appendChild(s);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div id={this.id} ref={el => (this.instance = el)} />
    );
  }
}

export default ZapierEmbed;
