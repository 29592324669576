import React from 'react';
import { CommentsPage } from './CommentsPage';

export default function CommentsPanePage({ proofCtrl, page, comments, atLeastOnePageHasCommentsAfterFiltering }) {
  const isCurrentPage = proofCtrl.currentPage === page.pageNumber;

  return (
    <div style={{ padding: '0 20px' }}>
      <CommentsPage
        displayHeader={(
          proofCtrl.filter.name !== 'search' ||
          (isCurrentPage && comments.length > 0) ||
          (proofCtrl.filter.name === 'search' && comments.length > 0)
        )}
        proofCtrl={proofCtrl}
        whenGoToPage={() => proofCtrl.switchPage(page)}
        isCurrentPage={isCurrentPage}
        isPage
        proofName={proofCtrl.proof.name}
        page={page}
        comments={comments}
        atLeastOnePageHasCommentsAfterFiltering={atLeastOnePageHasCommentsAfterFiltering}
      />
      <div style={{ height: 20 }} />
    </div>
  );
}
