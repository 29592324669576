/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './SetupMFA.scss';
import OtpInputField from '../OtpInputField';
import { Translation } from '../Text';
import { sdk } from '../../util/sdk';
import Reveal from '../Reveal';
import Message from '../UnifiedLogin/components/Message';

const SetupMFA = ({ qrCodeImage, onComplete }) => {
  const [totp, setTotp] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessageTranslationKey, setErrorMessageTranslationKey] = useState(null);

  const onCodeInput = (input) => {
    setTotp(input);
    if (input.length === 6) {
      verifyCode(input);
    }
  };

  const verifyCode = (code) => {
    setIsDisabled(true);

    sdk.accounts.mfa.enable(code)
      .then(() => onComplete())
      .catch(() => {
        setErrorMessageTranslationKey('enable.mfa.error.invalid-code');
        setIsDisabled(false);
      });
  };

  return (
    <div className={css.SetupMFA}>
      <h1><Translation value="setup.mfa.header" /></h1>
      <p><Translation value="setup.mfa.description.scan-qr" /></p>
      <img
        alt="qr-code"
        src={qrCodeImage}
        className={css.SetupMFA__qrImg}
      />
      <p><Translation value="setup.mfa.description.enter-code" /></p>
      <OtpInputField
        autoFocus
        value={totp}
        onChange={onCodeInput}
        className={css.SetupMFA__otpInput}
        isDisabled={isDisabled}
      />
      <Reveal
        align="bottom"
        duration={300}
        visible={!!errorMessageTranslationKey}
      >
        <div className={css.SetupMFA__errorMessage}>
          <Message
            translation={errorMessageTranslationKey}
          />
        </div>
      </Reveal>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  SetupMFA.propTypes = {
    qrCodeImage: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
  };
}

export default SetupMFA;
