/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import ExpandingControl from '../ExpandingControl';

const BACKSPACE_KEY = 8;
const TAB_KEY = 9;
const SPACE_KEY = 32;
const ENTER_KEY = 13;
const DELIMITER_CHARS = /[\s+,]/;

function TagInput({value, placeholder, onRemove, onCreate, onChange, onFocus, onBlur}) {
  function normaliseCreate(tags) {
    const createTags = tags.filter(tag => tag && tag.trim().length);
    if (createTags.length) {
      onCreate(createTags);
    }
  }
  function handleKeyDown(event) {
    const {value: targetValue} = event.target;
    switch (event.keyCode) {
    case TAB_KEY:
    case SPACE_KEY:
    case ENTER_KEY: {
      if (targetValue) {
        event.preventDefault();
        normaliseCreate([targetValue]);
      }
      break;
    }
    case BACKSPACE_KEY: {
      if (!targetValue) {
        event.preventDefault(); // Prevents the last character of the previous tag to be removed
        onRemove();
      }
      break;
    }
    }
  }
  function handleChange(event) {
    const {value: targetValue} = event.target;
    const tags = targetValue.split(DELIMITER_CHARS);
    if (tags.length > 1) {
      normaliseCreate(tags);
    } else {
      onChange(targetValue);
    }
  }
  function handleBlur(event) {
    const {value: targetValue} = event.target;
    if (targetValue) {
      normaliseCreate([targetValue]);
    }
    if (onBlur) {
      onBlur();
    }
  }
  return (
    <ExpandingControl
      initialWidth={placeholder ? 110 : 1}
      control={
        <input
          type="text"
          className="TagInput unstyled"
          value={value}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
        />
      }
    />
  );
}

export default TagInput;
