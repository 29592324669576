/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import HeaderTrayTunnel from './HeaderTrayTunnel';
import css from './HeaderTray.scss';

function HeaderTray() {
  return (
    <div className={css.HeaderTray}>
      <HeaderTrayTunnel.TunnelRenderer />
    </div>
  );
}

export default HeaderTray;
