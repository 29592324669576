/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import getLabel from './getLabel';
import replaceLabel from './replaceLabel';
import removeLabel from './removeLabel';

export {
  getLabel,
  replaceLabel,
  removeLabel,
};
