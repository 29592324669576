/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import Tag from './Tag';
import TagInput from './TagInput';
import Tags from './Tags';

export default Tags;

export {
  Tag,
  TagInput,
  Tags,
};
