/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import FormControl from '../FormControl';
import {Translation} from '../Text';
import Input from '../Input';
import getSDK from '../../util/sdk';
import styles from './AddressBookControl.css';
import Suggestions from '../Suggestions';

const sdk = getSDK();

class AddressBookControl extends Component {
  // eslint-disable-next-line
  service = window.__pageproof_bridge__.addressBookRepositoryService;

  state = {
    userId: sdk.session.userId,
    ownerId: '',
    emails: [],
    inputValue: this.props.value,
    emptyApprover: false,
    invalidEmail: false,
  };

  componentDidMount() {
    if (!this.props.approver) {
      this.validateEmail(this.state.inputValue);
    }
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.inputValue) {
      this.service.get(this.state.userId)
        .then(data => this.setState({
          ownerId: data.ownerId,
          emails: data.emails,
        }));

      this.setState({
        inputValue: nextProps.value,
      });
    }
  }

  catchFunctionalKeys = (event) => {
    const keyCode = event.which;

    if (keyCode === 32) {
      event.preventDefault();
    }

    this.setState({
      invalidEmail: false,
    });
  };


  setInputValue = (email) => {
    this.props.onChange(email);
    this.setState({
      inputValue: email,
    }, () => this.validateEmail(email));
  };

  addEmailIfNew = () => {
    const {
      ownerId,
      emails: currentEmails,
      inputValue,
      userId,
    } = this.state;
    const isNew = inputValue && currentEmails.indexOf(inputValue) === -1;

    if (isNew) {
      const emails = [...currentEmails, inputValue];
      this.service.set(userId, {emails, ownerId})
        .then(() => this.setState({emails}));
    }
  };

  validateEmail = (email) => {
    const {approver, setValidation} = this.props;
    const isApproverEmpty = approver && !email;
    const isEmailValid = !email || window.validateEmail(email);
    const isValid = !isApproverEmpty && isEmailValid;

    this.setState({
      invalidEmail: !isEmailValid,
      emptyApprover: isApproverEmpty,
    });
    if (setValidation) {
      setValidation(isValid);
    }

    return isValid;
  };

  handleBlur = (email) => {
    const {onBlur} = this.props;
    const isValid = this.validateEmail(email);
    if (isValid && !!email) {
      if (onBlur) {
        onBlur();
      }
      this.addEmailIfNew();
    }
  };

  getMessage = (message) => {
    const {
      invalidEmail,
      emptyApprover,
    } = this.state;

    const {className} = this.props;

    if (invalidEmail) {
      return (
        <Translation
          value="workflow.error.invalid-email"
          className={classname(styles.error, className)}
        />
      );
    } if (emptyApprover) {
      return (
        <Translation
          value="proof.setup.multiple.approvers.needed"
          className={classname(styles.error, className)}
        />
      );
    } else {
      return !!message && message;
    }
  };

  render() {
    const {
      inputValue: value,
    } = this.state;
    const {
      message,
      placeholder,
      autoFocus,
      onChange,
      setValidation,
      showError,
      ...props
    } = this.props;

    return (
      <FormControl
        {...props}
        message={showError && this.getMessage(message)}
      >
        <Suggestions
          onSelect={this.setInputValue}
        >
          <Input
            type="email"
            autoFocus={autoFocus}
            placeholder={placeholder}
            value={value}
            onKeyDown={this.catchFunctionalKeys}
            onChange={onChange}
            onBlur={() => this.handleBlur(value)}
            autoComplete="no"
          />
        </Suggestions>
      </FormControl>
    );
  }
}

AddressBookControl.defaultProps = {
  approver: false,
  showError: true,
};

if (process.env.NODE_ENV !== 'production') {
  AddressBookControl.propTypes = {
    approver: PropTypes.bool,
    message: PropTypes.node,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    setValidation: PropTypes.func,
    onBlur: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func,
    ]),
    showError: PropTypes.bool,
  };
}

export default AddressBookControl;
