/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import HeaderTray from './HeaderTray';
import HeaderTrayChild from './HeaderTrayChild';

export {
  HeaderTray,
  HeaderTrayChild,
};

export default HeaderTray;
