/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './BulkGeneralCommentsButton.scss';
import Tooltip from '../Tooltip';
import { Translation } from '../Text';
import CustomToolButton from '../CustomToolButton';
import Popover from '../Popover';
import BulkGeneralCommentPopover from '../BulkGeneralCommentsPopover';

const BulkGeneralCommentsButton = (props) => {
  const availableProofs = !!props.proofs.length;

  return (
    <Popover
      content={<BulkGeneralCommentPopover {...props} />}
      middle
      right
      variant="light"
      arrow
      offset={15}
      disabled={!availableProofs}
      padding={false}
    >
      {popover => (
        <div>
          <Tooltip
            title={
              <Translation
                value={availableProofs
                  ? 'collection.tools.bulk-general-comment.tooltip'
                  : 'collection.tools.bulk-general-comment.unavailable.tooltip'
                }
              />
            }
            middle
            right
            maxWidth={200}
            disabled={popover.isVisible}
          >
            <div
              className={classname({
                [css['BulkGeneralCommentsButton--disabled']]: !availableProofs,
              })}
            >
              <CustomToolButton
                icon={availableProofs
                  ? 'img/icons/sticky-note.svg'
                  : 'img/icons/sticky-note-unavailable.svg'
                }
                constraints={{
                  width: 24,
                  height: 24,
                }}
                selected={popover.isVisible}
                disabled={!availableProofs}
              />
            </div>
          </Tooltip>
        </div>
      )}
    </Popover>
  );
};

export default BulkGeneralCommentsButton;
