/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import classname from 'classname';
import InlineTooltip from '../InlineTooltip';
import {Translation} from '../Text';
import css from './WorkflowStepExplanationTooltip.scss';

function WorkflowStepExplanationTooltip({
  desc,
  excludedStep,
  mobile,
  ...props,
}) {
  const variant = (excludedStep || desc === 'no-mandatory') ? 'light' : 'dark';
  return (
    <div
      className={
        classname(css.WorkflowStepExplanationTooltip, {
          [css.WorkflowStepExplanationTooltip__mobile]: mobile,
        })
      }
    >
      <InlineTooltip
        variant={variant}
        {...props}
      >
        <Translation value={`workflow.step-description.${desc}`} />
      </InlineTooltip>
    </div>
  );
}

export default WorkflowStepExplanationTooltip;
