/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import classname from 'classname';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import IconButton from '../Button/IconButton';
import SearchIcon from '../Icon/SearchIcon';
import Input from '../Input';
import Translation from '../Text/Translation';
import css from './Search.scss';

const Search = ({
  theme,
  onSearch,
  onBlur,
  searchTerm = '',
  searchRef,
  placeholder = '',
  onClearSearch,
  simple,
  autoFocus,
}) => (
  <div
    className={classname(css.Search, {
      [css['Search--' + theme]]: !!theme,
      [css['Search--simple']]: simple,
    })}
  >
    <Input
      type="text"
      inputRef={searchRef}
      value={searchTerm}
      placeholder={Translation.text(placeholder)}
      onChange={onSearch}
      onBlur={onBlur}
      autoFocus={autoFocus}
    />
    <SearchIcon
      size={18}
      className={css.Search__icon}
    />
    {onClearSearch && searchTerm &&
    <IconButton
      className={css.Search__close}
      src="img/interface/close.svg"
      onClick={() => onClearSearch()}
    />
    }
  </div>
);

const SearchWithRef = forwardRef(Search);

if (process.env.NODE_ENV !== 'production') {
  Search.propTypes = {
    onSearch: PropTypes.func,
    onBlur: PropTypes.func,
    searchTerm: PropTypes.string,
    onClearSearch: PropTypes.func,
    placeholder: PropTypes.string,
    simple: PropTypes.bool,
    autoFocus: PropTypes.bool,
  };
}

export default SearchWithRef;
