/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import { InlineSVG } from '@jmshal/react-inline-svg';
import classname from 'classname';
import PropTypes from 'prop-types';
import LinkSummaryDot from './LinkSummaryDot';
import css from './LinkCheckResultRow.scss';
import LinkCheckURLTooltip from './LinkCheckURLTooltip';

const LinkCheckResultRow = ({ url, rating, detail }) => (
  <div className={css.LinkCheckResultRow__container}>
    <LinkCheckURLTooltip url={url} />
    <div className={classname(css.LinkCheckResultRow__container, css.LinkCheckResultRow__right)}>
      {url.startsWith('http') &&
      <div
        className={css.LinkCheckResultRow__open}
        onClick={() => {
          window.open(url, '_blank');
        }}
      >
        <InlineSVG
          src="img/icons/external-link.svg"
          width={16}
          height={16}
          fill="#FFFFFF"
        />
      </div>
      }
      {!url.startsWith('http') &&
      <div />
      }
      <LinkSummaryDot
        status={rating}
        detail={detail}
      />
    </div>
  </div>
);

if (process.env.NODE_ENV === 'development') {
  LinkCheckResultRow.propTypes = {
    url: PropTypes.string.isRequired,
    rating: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired,
  };
}

export default LinkCheckResultRow;
