/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'AgreePumpkin',
  src: 'img/icons/agree-pumpkin.svg',
  activeColor: '#f6a500',
  activeHoverColor: '#c38300',
});
