/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import { getWorkflowWithAvailableRoles } from './getWorkflowWithAvailableRoles';
import { prepareProofSetup, clearProofSetup, startProofSetup } from './proofSetup';

export default function requestProofSetup({ parameters, initialize: rawInitialize }, successCallback) {
  const { appService } = window.__pageproof_bridge__;

  const initialize = rawInitialize && {
    ...rawInitialize,
    workflow: getWorkflowWithAvailableRoles(rawInitialize.workflow, parameters),
  };

  prepareProofSetup({ parameters, initialize }, (err) => {
    if (!err) {
      appService.hideLoader();
      successCallback();
      return;
    }

    const { modalService, $location, $rootScope } = window.__pageproof_bridge__;

    switch (err) {
      case prepareProofSetup.NewVersionInProgressError:
      case prepareProofSetup.ParameterMismatchError: {
        modalService.create(
          'Resume draft?',
          'You were in the middle of creating a proof, would you like to discard it or finish it off?',
          [
            {
              text: 'Discard',
              type: 'text',
              click: () => {
                clearProofSetup();
                startProofSetup(parameters, initialize);
                successCallback();
              },
            },
            {
              text: 'Resume draft',
              type: 'primary',
              click: () => {
                $location.url('/create');
              },
            },
          ],
          {},
          true,
        );
        break;
      }
      default: {
        // window.alert('Unknown error during preparation.');
        break;
      }
    }

    if (!$rootScope.$$phase) $rootScope.$apply();
  });
}
