/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { sdk } from '../../util/sdk';
import { PROOFS_PER_PAGE } from '../../util/constants';

const bridge = window.__pageproof_bridge__;

let isDownlaodCancelled = false;

const bulkSetStatusAsAwaitingNewVersion = proofs => sdk.proofs.return(proofs);

const bulkArchive = proofs => sdk.proofs.archive(proofs);

const bulkAddOwner = (proofs, emails) => sdk.proofs.owners.add(proofs, emails);

const bulkAddWorkflow = (proofs, workflowId) => sdk.proofs.addWorkflow(proofs, workflowId);

const bulkAddReviewer = (proofs, emails) => sdk.proofs.reviewers.add(proofs, emails);

const bulkDueDateChange = proofsWithDueDate => sdk.proofs.update(proofsWithDueDate);

const bulkReplaceApprovers = (workflows, email) => sdk.workflows.replaceApprover(workflows, email);

const bulkAddEditor = proofsWithEditors => sdk.proofs.editors.addBulk(proofsWithEditors);

const bulkMessageToReviewers = (proofs, message) => sdk.proofs.setMessageToReviewers(proofs, message);

const loadGroups = (type, from) => { // type -> current/archived
  switch (from) {
  case 'team-dashboard':
    return sdk.admin()
      .then(admin => admin.team.dashboard.groups[type]());

  case 'dashboard':
  default:
    return sdk.dashboard.groups[type]();
  }
};

const bulkUpdate = ({ email, emails, workflowId, messageToReviewers }, selectedIds, type) => {
  switch (type) {
  case 'replace-approver':
    return bulkReplaceApprovers(selectedIds, { email });

  case 'add-owner':
    return bulkAddOwner(selectedIds, emails.map(e => ({ email: e })));

  case 'add-reviewer':
    return bulkAddReviewer(selectedIds, emails.map(e => ({ email: e })));

  case 'add-workflow':
    return bulkAddWorkflow(selectedIds, workflowId);

  case 'set-status-as-awaiting-new-version':
    return bulkSetStatusAsAwaitingNewVersion(selectedIds);

  case 'message-to-reviewers':
    return bulkMessageToReviewers(selectedIds, messageToReviewers);

  default:
    return false;
  }
};

function loadCustomProofList(endpoint, params) {
  return sdk.proofs[endpoint](params)
    .then(proof => proof)
    .catch(() => {
      bridge.$location.url('access/1');
    });
}

function loadCustomGroupList(groupId, from) {
  switch (from) {
  case 'team-dashboard':
    return sdk.admin()
      .then(admin => admin.team.dashboard.groups.load(groupId))
      .catch(() => {
        bridge.$location.url('access/13');
      });

  case 'dashboard':
  default:
    return sdk.proofs.groups.load(groupId)
      .catch(() => {
        bridge.$location.url('access/13');
      });
  }
}

function fetchAllProofsForAdmin(adminTab, onProgress) {
  const { proofCount, search, endpoint, filters } = adminTab;
  const totalPages = Math.ceil(proofCount / PROOFS_PER_PAGE);
  isDownlaodCancelled = false;

  const allPages = () => {
    const allProofs = [];
    let chain = Promise.resolve();
    for (let page = 1; page <= totalPages; page++) { // eslint-disable-line
      let data = null;
      let firstArg = page;

      if (endpoint === 'search') {
        data = {
          status: filters,
          everything: 0,
          page,
        };
        firstArg = search;
      }
      chain = chain
        .then(() => sdk.admin())
        .then(admin => admin.team.dashboard[endpoint](firstArg, data))
        .then((proofData) => { // eslint-disable-line
          allProofs.push(...proofData.proofs);
          if (onProgress && !isDownlaodCancelled) {
            onProgress((page / totalPages) * 100);
          }
          if (!isDownlaodCancelled) {
            return allProofs;
          } else {
            return [];
          }
        });
    }
    return chain;
  };
  return allPages();
}

function cancelDownload() {
  isDownlaodCancelled = true;
}

function downloadProof(proof, callback) {
  isDownlaodCancelled = false;
  let fileName = proof.name + '.' + proof.fileExtension;
  return sdk.files.download({
    fileId: proof.fileId,
    onFileDetails: (fileDetails) => {
      fileName = fileDetails.name;
    },
    onProgress: (percent) => {
      if (callback && !isDownlaodCancelled) {
        callback(proof, percent);
      }
    },
  })
    .then((file) => {
      if (!isDownlaodCancelled) {
        if (callback) {
          callback(proof, null);
        }
        window.downloadFile(fileName, file);
      }
    })
    .then(() => {
      if (callback) {
        callback(proof, null);
      }
    });
}

function removeProofFromGroup(proofId) {
  return sdk.proofs.groups.removeProof(proofId);
}

function addProofToGroup(proofId, groupId) {
  return sdk.proofs.groups.addProof(groupId, proofId);
}

function deleteCollection(groupId) {
  return sdk.proofs.groups.delete(groupId);
}

export {
  bulkArchive,
  loadGroups,
  loadCustomProofList,
  loadCustomGroupList,
  downloadProof,
  cancelDownload,
  removeProofFromGroup,
  addProofToGroup,
  deleteCollection,
  bulkAddEditor,
  bulkDueDateChange,
  fetchAllProofsForAdmin,
  bulkUpdate,
};
