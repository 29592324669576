import getTagsFromFilename from '../../../util/generate-initial-tags';

export default function beginBriefSetup(file, updateProof) {
  const { dataService, $location, $rootScope } = window.__pageproof_bridge__;

  const extensionPosition = file.name.lastIndexOf('.');
  const name = file.name.substring(0, extensionPosition);

  dataService.addName(name);
  dataService.addTag(getTagsFromFilename(name).join(' '));
  dataService.addFile(file);

  if (updateProof) {
    dataService.addProofId(updateProof.id);
    dataService.addToggleIsRerun();
  }

  $location.url('/brief-upload-progress');
  $rootScope.$apply();
}
