/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Translation, useText } from '../Text';
import {
  GraduationLine,
  GridGraduationLine,
  round,
} from './GridGraduationLine';
import { GridRuler, GridRulerPanel } from './GridRuler';
import ColorDot from '../ColorDot';
import colorPickerColors from '../../resources/colorPickerColors.json';
import { useLocalStorage } from '../LocalStorage';

const CLIP_AXIS_NEGATIVE_OVERFLOW = true;
const TOP_BAR = 'topBar';
const LEFT_BAR = 'leftBar';
const RULER_BAR = 'rulerBar';
const CORNER_BAR = 'cornerBar';
const DIRECTION_ROW = 'row';
const DIRECTION_COL = 'col';
const HEADER_HEIGHT = 100;
const LINE_HEIGHT = 15;
const UNIT_SCALE_CORRECTIONS = {
  mm: 1,
  cm: 0.1,
  m: 0.1,
  inch: 0.1,
  ft: 0.1,
  pt: 10,
  px: 10,
};
const DEFAULT_PAGE_DIMENSION_INFO = {
  unit: 'px',
  isDecimal: false,
  scale: 1,
};
const rotatePosition = ({ width, height, top, left, direction }, rotateAngle, gridHeight, gridWidth) => {
  switch (rotateAngle) {
  case 90:
    return {
      height: width,
      width: height,
      top: left,
      left: direction === DIRECTION_COL ? top : gridHeight - top + LINE_HEIGHT,
      direction: direction === DIRECTION_ROW ? DIRECTION_COL : DIRECTION_ROW,
    };
  case 180:
    return {
      height,
      width,
      top: direction === DIRECTION_COL ? top : gridHeight - top + LINE_HEIGHT,
      left: direction === DIRECTION_COL ? gridWidth - left + LINE_HEIGHT : left,
      direction,
    };
  case 270:
    return {
      height: width,
      width: height,
      top: direction === DIRECTION_COL ? gridWidth - left + LINE_HEIGHT : left,
      left: top,
      direction: direction === DIRECTION_ROW ? DIRECTION_COL : DIRECTION_ROW,
    };
  default:
    return { width, height, top, left, direction };
  }
};

function GridLines({
  canvasPosition,
  viewContainerId,
  targetZIndex,
  isInitGridRulers,
  onChangeIsInitGridRuler,
  pageDimensionInfo = {},
  hasHeader,
}) {
  const {
    top,
    left,
    width,
    height,
    zoomLevel,
    angle,
    isRotating,
  } = canvasPosition;
  const {
    height: unitConvertedHeight,
    width: unitConvertedWidth,
    unit,
    isDecimal,
    scale,
  } = pageDimensionInfo.unit
    ? pageDimensionInfo
    : {
      ...DEFAULT_PAGE_DIMENSION_INFO,
      width: width / zoomLevel,
      height: height / zoomLevel,
      scale: pageDimensionInfo.scale || DEFAULT_PAGE_DIMENSION_INFO.scale,
    };
  const [userRulerColor, setUserRulerColor] = useLocalStorage('pageproof.app.grid-lines.ruler-color', colorPickerColors.orange.normal);
  const [userRulerCustomColor, setUserRulerCustomColor] = useLocalStorage('pageproof.app.grid-lines.ruler-custom-color', null);
  const text = useText();
  const [activeGridRuler, setActiveGridRuler] = useState(false);
  const [gridRulers, setGridRulers] = useState({
    lastKey: 0,
    zoomLevel,
    angle,
    rulers: {},
  });
  const [selectedRuler, setSelectedRuler] = useState(null);
  const [panelPosition, setPanelPosition] = useState({
    isShown: false,
    top: 0,
    left: 0,
  });

  const viewEl = document.getElementById(viewContainerId);
  const gridLinesZIndex = targetZIndex + 1;
  const debouncedReScalingGridRulers = useCallback(window.debounce(setGridRulers, 200), []);
  const getGraduationInterval = (length, convertedLength, isCorrection = false) => (length / convertedLength) * (isCorrection
    ? UNIT_SCALE_CORRECTIONS[unit]
    : 1);

  const handleMouseDownEvent = (barType, key = null) => {
    setActiveGridRuler(true);
    if (barType === RULER_BAR) {
      setSelectedRuler(key);
    } else {
      const newKey = gridRulers.lastKey + 1;
      setGridRulers({
        ...gridRulers,
        lastKey: newKey,
        rulers: {
          ...gridRulers.rulers,
          [newKey]: {
            height: barType === TOP_BAR ? LINE_HEIGHT : height,
            width: barType === TOP_BAR ? width : LINE_HEIGHT,
            top: barType === TOP_BAR ? 0 : LINE_HEIGHT,
            left: barType === TOP_BAR ? LINE_HEIGHT : 0,
            direction: barType === TOP_BAR ? DIRECTION_ROW : DIRECTION_COL,
          },
        },
      });
      setSelectedRuler(newKey);
    }
  };
  const handleMouseUpEvent = () => {
    setActiveGridRuler(false);
    if (selectedRuler && gridRulers.rulers[selectedRuler]) {
      const { top: rulerTop, left: rulerLeft, direction } = gridRulers.rulers[selectedRuler];
      if ((direction === DIRECTION_ROW && (rulerTop <= LINE_HEIGHT / 2)) ||
        (direction === DIRECTION_COL && (rulerLeft <= LINE_HEIGHT / 2))) {
        const copiedGridRulers = { ...gridRulers };
        delete copiedGridRulers.rulers[selectedRuler];
        setGridRulers(copiedGridRulers);
      }
    }
  };
  const handleMouseMoveEvent = (event) => {
    let nextTop = 0;
    let nextLeft = 0;

    if (event.clientY - top < 0) {
      nextTop = 0;
    } else if (event.clientY - top > height + LINE_HEIGHT / 2) {
      nextTop = height + LINE_HEIGHT / 2;
    } else {
      nextTop = event.clientY - top;
    }

    if (event.clientX - left < 0) {
      nextLeft = 0;
    } else if (event.clientX - left > width + LINE_HEIGHT / 2) {
      nextLeft = width + LINE_HEIGHT / 2;
    } else {
      nextLeft = event.clientX - left;
    }

    setGridRulers({
      ...gridRulers,
      rulers: {
        ...gridRulers.rulers,
        [selectedRuler]: {
          ...gridRulers.rulers[selectedRuler],
          top: gridRulers.rulers[selectedRuler].direction === DIRECTION_ROW ? nextTop : LINE_HEIGHT,
          left: gridRulers.rulers[selectedRuler].direction === DIRECTION_ROW ? LINE_HEIGHT : nextLeft,
        },
      },
    });
  };

  const displayGridRulerPanel = (barType, position) => (event) => {
    const widthGraduationInterval = getGraduationInterval(width, unitConvertedWidth);
    const heightGraduationInterval = getGraduationInterval(height, unitConvertedHeight);
    const decimal = isDecimal ? 1 : 0;
    const getCoordText = (axisText, coordValue) => (
      `${axisText}: ${(coordValue * scale).toFixed(decimal)} ${unit}\r\n${text('proof.page.info.scale', { inputScale: scale })}`
    );
    let newPanelPosition = { ...panelPosition, isShown: false };

    if (barType === TOP_BAR || barType === LEFT_BAR) {
      newPanelPosition = {
        ...panelPosition,
        isShown: true,
        top: event.clientY - top,
        left: event.clientX - left,
        label: barType === TOP_BAR
          ? getCoordText('X', (event.clientX - left) / widthGraduationInterval)
          : getCoordText('Y', (event.clientY - top) / heightGraduationInterval),
      };
    } else if (barType === RULER_BAR && !activeGridRuler) {
      newPanelPosition = {
        ...panelPosition,
        isShown: true,
        top: position.direction === DIRECTION_COL ? event.clientY - top : position.top,
        left: position.direction === DIRECTION_COL ? position.left : event.clientX - left,
        label: position.direction === DIRECTION_COL
          ? getCoordText('X', (position.left - LINE_HEIGHT / 2) / widthGraduationInterval)
          : getCoordText('Y', (position.top - LINE_HEIGHT / 2) / heightGraduationInterval),
      };
    } else if (barType === CORNER_BAR) {
      newPanelPosition = {
        ...panelPosition,
        isShown: true,
        top: event.clientY - top,
        left: event.clientX - left,
        label: <Translation value="proof.page.info.grid-line.corner" />,
      };
    }

    setPanelPosition(newPanelPosition);
  };

  useEffect(() => {
    viewEl.addEventListener('mouseup', handleMouseUpEvent);
    return () => viewEl.removeEventListener('mouseup', handleMouseUpEvent);
  }, [selectedRuler, gridRulers.rulers[selectedRuler]]);

  useEffect(() => {
    if (activeGridRuler) {
      viewEl.addEventListener('mousemove', handleMouseMoveEvent);
    } else {
      viewEl.removeEventListener('mousemove', handleMouseMoveEvent);
    }
    return () => viewEl.removeEventListener('mousemove', handleMouseMoveEvent);
  }, [activeGridRuler]);

  useEffect(() => {
    const rotationAngle = angle - gridRulers.angle + (angle > gridRulers.angle ? 0 : 360);
    const newScaleLevel = round(zoomLevel / gridRulers.zoomLevel);
    let scaledGridRulers = { ...gridRulers };
    Object.keys(gridRulers.rulers).forEach((key) => {
      const scaledRuler = {
        ...scaledGridRulers.rulers[key],
        height: scaledGridRulers.rulers[key].direction === DIRECTION_ROW
          ? LINE_HEIGHT
          : round(scaledGridRulers.rulers[key].height * newScaleLevel),
        width: scaledGridRulers.rulers[key].direction === DIRECTION_ROW
          ? round(scaledGridRulers.rulers[key].width * newScaleLevel)
          : LINE_HEIGHT,
        top: scaledGridRulers.rulers[key].direction === DIRECTION_ROW
          ? round((scaledGridRulers.rulers[key].top - LINE_HEIGHT / 2) * newScaleLevel) + (LINE_HEIGHT / 2)
          : LINE_HEIGHT,
        left: scaledGridRulers.rulers[key].direction === DIRECTION_ROW
          ? LINE_HEIGHT
          : round((scaledGridRulers.rulers[key].left - LINE_HEIGHT / 2) * newScaleLevel) + (LINE_HEIGHT / 2),
      };

      scaledGridRulers = {
        ...scaledGridRulers,
        zoomLevel,
        angle,
        rulers: {
          ...scaledGridRulers.rulers,
          [key]: { ...rotatePosition(scaledRuler, rotationAngle, height * newScaleLevel, width * newScaleLevel) },
        },
      };
    });
    debouncedReScalingGridRulers(scaledGridRulers);
  }, [angle, zoomLevel]);

  useEffect(() => {
    if (isInitGridRulers) {
      setGridRulers({
        lastKey: 0,
        zoomLevel,
        rulers: {},
      });
      onChangeIsInitGridRuler(false);
    }
  }, [isInitGridRulers]);

  const colorPallet = Object.keys(colorPickerColors).map((key) => {
    const color = colorPickerColors[key];
    return {
      ...color,
      disabled: userRulerColor === color.normal,
    };
  });

  const rootTop = top - LINE_HEIGHT - (hasHeader ? HEADER_HEIGHT : 0);
  const rootLeft = left - LINE_HEIGHT;

  const stickyTop = rootTop <= 0 ? Math.abs(rootTop) : 0;
  const stickyLeft = rootLeft <= 100 ? (100 - rootLeft) : 0;

  return (
    <div
      style={{
        position: 'absolute',
        top: rootTop,
        left: rootLeft,
        width: width + (LINE_HEIGHT * 2),
        height: height + (LINE_HEIGHT * 2),
        visibility: isRotating ? 'hidden' : 'visible',
        userSelect: 'none',
      }}
    >
      <GridGraduationLine
        top={stickyTop}
        left={LINE_HEIGHT}
        clipPath={CLIP_AXIS_NEGATIVE_OVERFLOW && `inset(-10px -10px -10px ${stickyLeft}px)`}
        width={width}
        height={LINE_HEIGHT}
        zIndex={gridLinesZIndex}
        zoomLevel={zoomLevel}
        graduationInterval={getGraduationInterval(width, unitConvertedWidth, true)}
        lineHeight={LINE_HEIGHT}
        onMouseDown={() => handleMouseDownEvent(TOP_BAR)}
        onMouseMove={displayGridRulerPanel(TOP_BAR)}
        onMouseOut={displayGridRulerPanel()}
        onBlur={displayGridRulerPanel()}
      />
      <GridGraduationLine
        top={LINE_HEIGHT}
        left={stickyLeft}
        clipPath={CLIP_AXIS_NEGATIVE_OVERFLOW && `inset(${stickyTop}px -10px -10px -10px)`}
        width={LINE_HEIGHT}
        height={height}
        zIndex={gridLinesZIndex}
        zoomLevel={zoomLevel}
        graduationInterval={getGraduationInterval(height, unitConvertedHeight, true)}
        lineHeight={LINE_HEIGHT}
        onMouseDown={() => handleMouseDownEvent(LEFT_BAR)}
        onMouseMove={displayGridRulerPanel(LEFT_BAR)}
        onMouseOut={displayGridRulerPanel()}
        onBlur={displayGridRulerPanel()}
      />
      <ColorDot.PopoverWrapper
        colors={colorPallet}
        onChange={color => setUserRulerColor(color.normal)}
        onCustomColorChange={color => setUserRulerCustomColor(color)}
        selectedColor={userRulerColor}
        customColor={userRulerCustomColor}
        hasCustomColor
        offset={12}
      >
        {popover => (
          <GraduationLine
            top={stickyTop}
            left={stickyLeft}
            width={LINE_HEIGHT}
            height={LINE_HEIGHT}
            zIndex={gridLinesZIndex}
            color={userRulerColor}
            lineHeight={LINE_HEIGHT}
            cursor="pointer"
            onClick={popover.toggle}
            onMouseMove={displayGridRulerPanel(CORNER_BAR)}
            onMouseOut={displayGridRulerPanel()}
            onBlur={displayGridRulerPanel()}
          />
        )}
      </ColorDot.PopoverWrapper>
      <GridRulerPanel
        top={panelPosition.top}
        left={panelPosition.left}
        label={panelPosition.label}
        isShown={panelPosition.isShown}
      />
      {
        Object.keys(gridRulers.rulers).map(key => (
          <GridRuler
            key={key}
            rulerPosition={gridRulers.rulers[key]}
            color={userRulerColor}
            zIndex={targetZIndex}
            onMouseDown={() => handleMouseDownEvent(RULER_BAR, key)}
            onMouseMove={displayGridRulerPanel(RULER_BAR, gridRulers.rulers[key])}
            onMouseOut={displayGridRulerPanel()}
            onBlur={displayGridRulerPanel()}
          />
        ))
      }
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  GridLines.propTypes = {
    canvasPosition: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
      zoomLevel: PropTypes.number,
      angle: PropTypes.number,
      isRotating: PropTypes.bool,
    }),
    viewContainerId: PropTypes.string,
    targetZIndex: PropTypes.number,
    isInitGridRulers: PropTypes.bool,
    onChangeIsInitGridRuler: PropTypes.func,
    pageDimensionInfo: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      isDecimal: PropTypes.bool,
      unit: PropTypes.string,
      scale: PropTypes.number,
    }),
    hasHeader: PropTypes.bool,
  };
}

export default GridLines;
