/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { createRef } from 'react';
import { openModal } from '../../../util/modal';
import { addLocalFile } from './addFile';
import CombineFilesModal from '../components/CombineFilesModal';
import { clearProofSetup, getProofSetupProofCount } from './proofSetup';
import Translation from '../../Text/Translation';

const SUPPORTED_COMBINATION_PDF_FILE_TYPES = ['image/tiff', 'image/png', 'image/jpeg', 'application/pdf', 'application/postscript', 'image/vnd.adobe.photoshop', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

export default function addLocalFiles(files, initialize, parameters, canAddMultiple, onComplete) {
  if (files.length === 1) {
    const file = files[0];
    const success = addLocalFile(file, file.name, initialize, parameters);

    onComplete(success ? 1 : 0);
    return;
  }

  const fileTypesCanBeCombined = !files.some(file => !SUPPORTED_COMBINATION_PDF_FILE_TYPES.includes(file.type));

  const abortObjectRef = createRef(false);

  const onCancel = () => {
    abortObjectRef.current = true;
    if (!getProofSetupProofCount()) {
      clearProofSetup();
    }
  };

  if (!canAddMultiple) {
    if (!fileTypesCanBeCombined) {
      const { modalService, $rootScope } = window.__pageproof_bridge__;
      modalService.create(
        Translation.text('proof.combine-files.title.select-one'),
        Translation.text('proof.combine-files.body.select-one'),
        [
          {
            text: Translation.text('button.ok'),
            type: 'primary',
            click: onCancel,
          },
        ],
        {},
        true,
      );

      if (!$rootScope.$$phase) {
        $rootScope.$apply();
      }

      return;
    }
  }

  if (!fileTypesCanBeCombined) {
    const successCount = files.filter(file => addLocalFile(file, file.name, initialize, parameters)).length;

    onComplete(successCount);
    return;
  }

  const modalApi = openModal(
    <CombineFilesModal
      onComplete={(fileCount) => {
        modalApi.destroy();
        onComplete(fileCount);
      }}
      onCancel={() => {
        modalApi.destroy();
        onCancel();
      }}
      $files={files}
      initialize={initialize}
      parameters={parameters}
      canAddMultiple={canAddMultiple}
      abortObjectRef={abortObjectRef}
    />,
    onCancel
  );
}
