/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './ButtonGroup.scss';

function ButtonGroup({
  align,
  children,
}) {
  return (
    <div
      className={classname(css.ButtonGroup, {
        [css['ButtonGroup--' + align]]: align,
      })}
    >
      {children}
    </div>
  );
}

ButtonGroup.defaultProps = {
  align: 'center',
};

ButtonGroup.propTypes = {
  align: PropTypes.oneOf([
    'left',
    'right',
    'center',
    'justify',
  ]),
  children: PropTypes.node,
};

export default ButtonGroup;
