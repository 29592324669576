/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Option, OptionList } from '../PopupMenu';
import SideBySide from '../Transitions/SideBySide';
import css from './AdvancedSubOptionList.scss';
import SubLabel from './SubLabel';

function AdvancedSubOptionList({
  label,
  children,
}) {
  const [changing, setChanging] = useState(false);
  return (
    <SideBySide
      reverse={!changing}
      id={changing}
    >
      {changing
        ? (
          <OptionList
            naked
            className={css.AdvancedSubOptionList}
          >
            <Option
              label={
                <div className={css.AdvancedSubOptionList__label}>
                  <span>&#x276e;&nbsp;&nbsp;</span>
                  <span className={css.AdvancedSubOptionList__label__name}>
                    {label}
                  </span>
                </div>
              }
              onClick={() => setChanging(false)}
              sticky
            />
            {children}
          </OptionList>
        ) : (
          <SubLabel
            label={label}
            subOptionCount={children.length}
            onClick={() => setChanging(true)}
          />
        )
      }
    </SideBySide>
  );
}

if (process.env.NODE_ENV !== 'production') {
  AdvancedSubOptionList.propTypes = {
    label: PropTypes.oneOf([PropTypes.node, PropTypes.string]).isRequired,
    children: PropTypes.node.isRequired,
  };
}

export default AdvancedSubOptionList;
