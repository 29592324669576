/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Button from './Button';
import FileDrop from '../FileDrop';

function FileButton({
  variant,
  label,
  multiple,
  extensions,
  onSelect,
  ...props,
}) {
  let input = null;
  return (
    <FileDrop
      onSelect={onSelect}
      multiple={multiple}
    >
      <Button
        {...props}
        className={classname('FileButton', props.className)} // unstyled due to the default label styles (legacy)
        variant={variant}
        label={
          <span>
            <input
              ref={ref => (input = ref)}
              type="file"
              className="FileButton__input"
              multiple={multiple}
              accept={extensions.map(extension => `.${extension}`).join(', ')} // ".png, .jpeg"
              onChange={(event) => {
                const files = multiple
                  ? Array.prototype.slice.apply(event.target.files)
                  : event.target.files[0];
                if (onSelect) onSelect(files);
              }}
            />
            {label}
          </span>
        }
        onClick={() => input.click()}
      />
    </FileDrop>
  );
}

FileButton.defaultProps = {
  variant: 'primary',
  label: 'Upload file',
  multiple: false,
  extensions: [],
};

if (process.env.NODE_ENV !== 'production') {
  FileButton.propTypes = {
    variant: PropTypes.string,
    label: PropTypes.node,
    multiple: PropTypes.bool,
    extensions: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
  };
}

export default FileButton;
