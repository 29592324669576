/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import DeviceDropdown from '../DeviceDropdown';
import NetworkDropdown from '../NetworkDropdown';
import Tooltip from '../../components/Tooltip';
import { Translation } from '../../components/Text';

function WebProofOptions({
  device,
  customDevices,
  orientation,
  speed,
  isLoading,
  isExternal,
  isFitViewport,
  onToggleFitViewport,
  onDeviceChange,
  onOrientationChange,
  onSpeedChange,
  onRefresh,
  onStopRefresh,
  onFullscreen,
  onHome,
}) {
  return (
    <div className="WebProofOptions">
      <DeviceDropdown
        direction="up"
        device={device}
        customDevices={customDevices}
        orientation={orientation}
        isFitViewport={isFitViewport}
        onToggleFitViewport={onToggleFitViewport}
        onDeviceChange={onDeviceChange}
        onOrientationChange={onOrientationChange}
      />
      {!isExternal && false && // hiding speed dropdown, untill we fix it
        <NetworkDropdown
          direction="up"
          speed={speed}
          onSpeedChange={onSpeedChange}
        />
      }
      <div style={{display: 'inline-block', width: 10}}>&nbsp;</div>
      <Tooltip
        title={<Translation value="button.go-to-homepage.tooltip" />}
        up
        center
      >
        <button
          className={classname('WebProofOptions__home depress-active')}
          onClick={onHome}
          aria-label="Go to homepage"
        >
          <InlineSVG src="/img/icons/home.svg" />
        </button>
      </Tooltip>
      <Tooltip
        title={<Translation value="button.reload-content.tooltip" />}
        up
        center
      >
        <button
          className={classname('WebProofOptions__refresh depress-active', {
            'WebProofOptions__refresh--loading': isLoading,
          })}
          onClick={isLoading ? onStopRefresh : onRefresh}
          aria-label="Reload content"
        >
          <InlineSVG
            className="WebProofOptions__refresh__stop"
            src="/img/interface/close.svg"
          />
          <InlineSVG
            className={classname('WebProofOptions__refresh__start', {
              'animate-spin': isLoading,
            })}
            src="/img/content/proof/icons/refresh.svg"
          />
        </button>
      </Tooltip>
      <Tooltip
        title={<Translation value="button.go-fullscreen.tooltip" />}
        up
        center
      >
        <button
          className={classname('WebProofOptions__fullscreen depress-active')}
          onClick={onFullscreen}
          aria-label="Go fullscreen"
        >
          <InlineSVG src="/img/icons/fullscreen.svg" />
        </button>
      </Tooltip>
    </div>
  );
}

WebProofOptions.defaultProps = {
  enableOptions: true,
  enableFrame: true,
};

export default WebProofOptions;
