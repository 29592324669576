/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { getTranslationsSync } from './getTranslations';
import i18n from '../../util/i18n';

let translations = {
  ...i18n.getFallbackTranslations(),
  ...getTranslationsSync(i18n.getLocale()),
};

function setTranslations() {
  i18n.getTranslations(i18n.getLocale()).then((newTranslations) => {
    translations = {
      ...i18n.getFallbackTranslations(),
      ...newTranslations,
    };
  });
}

i18n.on('did-change-locale', setTranslations);

export function getCurrentTranslations() {
  return translations;
}
