/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const listeners = [];

const update = () => {
  const {innerWidth: width, innerHeight: height} = window;
  listeners.forEach(callback => callback(width, height));
};

const deferUpdate = () => {
  setTimeout(update);
};

const register = (callback) => {
  let active = true;
  listeners.push(callback);
  return () => {
    if (active) {
      active = false;
      listeners.splice(listeners.indexOf(callback), 1);
    }
  };
};

window.addEventListener('resize', update);

export default register;
export {update, deferUpdate};
