/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { sdk } from '../../../../util/sdk';
import createWorkflow from './createWorkflow';

/* eslint-disable brace-style */
export default function ensureWorkflowTemplate(proofSetup) {
  if (proofSetup.parameters.showWorkflowReadonly) {
    return Promise.resolve(null); // Don't do anything if workflow is set to readOnly mode during proof setup
  }
  if (proofSetup.workflow) {
    if (
      proofSetup.workflow.id &&
      !proofSetup.workflow._isEdited &&
      !proofSetup.workflow._hasStartStep &&
      !proofSetup.workflow.steps[0]._position // check included to ensure duplicate is called when appropriate e.g. upon workflow template select or new proof version upload without editing workflow.
    ) {
      return sdk.workflows.duplicate(proofSetup.workflow.id, { includeOwners: false, includeStepDueDates: false });
    }
    return createWorkflow(proofSetup.workflow);
  }

  return Promise.resolve(null);
}
