/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable arrow-body-style, consistent-return */

const endpoints = window.env('static_endpoint').split(',').map((endpoint) => {
  const [prefix, suffix] = endpoint.split('{id}');
  return { prefix, suffix };
});

let confirmedEndpoint = endpoints[0];

function checkEndpoint(endpoint) {
  const id = 'check-connectivity-' + (Math.floor(Math.random() * 10000));
  return window.fetch(endpoint.prefix + id + endpoint.suffix + '/troubleshooting-wizard/access.json')
    .then((response) => {
      const isOk = (
        response.status === 200 &&
        response.headers.get('x-troubleshooting-wizard') === '1'
      );
      return [isOk, null, response];
    })
    .catch(err => [false, err, null]);
}

function reportConnectivityIssue(endpoint, err, response) {
  Promise.resolve(
    response
      ? response.text().catch(() => null)
      : null
  )
    .then((responseBody) => {
      if (err) {
        console.error(err);
      }
      window.Bugsnag.notify(
        'WebProofEndpointConnectivityError',
        (err && err.message) || 'There was an error verifying connectivity to one of the web proof endpoints.',
        {
          'web proof endpoint': endpoint,
          'web proof endpoint error': err && {
            message: err.message,
            stack: err.stack,
          },
          'web proof endpoint response': response && {
            status: response.status,
            headers: Object.fromEntries(response.headers.entries()),
            body: responseBody,
          },
        },
        'warning',
      );
    });
}

function reportFatalConnectivityIssue() {
  window.Bugsnag.notify(
    'WebProofEndpointFatalConnectivityError',
    'All web proof endpoints are inaccessible to the user.',
    {},
    'error',
  );
}

let _isReady;
const isReady = () => {
  if (!_isReady) {
    _isReady = checkEndpoint(endpoints[0])
      .then(([primaryIsOk, primaryErr, primaryResponse]) => {
        if (!primaryIsOk) {
          reportConnectivityIssue(endpoints[0], primaryErr, primaryResponse);
          let found = false;
          return Promise.all(endpoints.slice(1).map((secondaryEndpoint) => {
            return checkEndpoint(secondaryEndpoint).then(([secondaryIsOk, secondaryErr, secondaryResponse]) => {
              if (secondaryIsOk && !found) {
                found = true;
                confirmedEndpoint = secondaryEndpoint;
              } else if (!secondaryIsOk) {
                reportConnectivityIssue(secondaryEndpoint, secondaryErr, secondaryResponse);
              }
            });
          })).then(() => {
            if (!found) {
              confirmedEndpoint = null;
              reportFatalConnectivityIssue();
              const err = new Error('No web proof endpoints successfully connected.');
              err.userMessage = `
                Your device is having a hard time reaching our servers.
                Please [click here](https://app.pageproof.com/troubleshooting-wizard) for more information.
              `;
              throw err;
            }
          });
        }
      });
  }
  return _isReady;
};

function getProxyRedirectUrl(url) {
  const b64Url = window.btoa(url);
  return [confirmedEndpoint.prefix + 'proxy-redirect' + confirmedEndpoint.suffix, b64Url].join('/');
}

function getWebZipProofUrl(proofId) {
  return confirmedEndpoint.prefix + proofId + confirmedEndpoint.suffix;
}

function isProxiedWebUrl(url) {
  try {
    const { origin } = new URL(url);
    return endpoints.some(endpoint => (
      origin.startsWith(endpoint.prefix + 'proxy-web-url-') &&
      origin.endsWith(endpoint.suffix)
    ));
  } catch (err) {
    // This is here in case "url" isn't a valid URL - which it isn't when px-static provides it.
    return false;
  }
}

export {
  isReady,
  getProxyRedirectUrl,
  getWebZipProofUrl,
  isProxiedWebUrl,
};
