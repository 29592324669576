/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment} from 'react';
import classname from 'classname';
import Portal from '../Portal';
import TransitioningVisibility from '../TransitioningVisibility';
import Overlay from './Overlay';
import css from './Flyout.scss';

function Flyout({
  scoped,
  position,
  visible,
  duration,
  children,
  onClose,
}) {
  const Wrapper = scoped ? Fragment : Portal;
  return (
    <TransitioningVisibility
      visible={visible}
      duration={duration}
    >
      {render => (visible || render) && (
        <Wrapper>
          <div
            style={{
              transitionDuration: duration + 'ms',
            }}
            className={classname(css.Flyout, css['Flyout--' + position], {
              [css['Flyout--open']]: visible && render,
            })}
          >
            {children}
          </div>
          <Overlay
            visible={visible && render}
            onClick={onClose}
          />
        </Wrapper>
      )}
    </TransitioningVisibility>
  );
}

Flyout.defaultProps = {
  scoped: false,
  position: 'right',
};

export default Flyout;
