/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import getSDK from './sdk';

const sdk = getSDK();

// ToDo: Clean up this file
const APP = 'pageproof.app';
const SESSION = `${APP}.userData`;
const DOWNLOAD_PREFERRED = `${APP}.download_preferred_`;
const WORKFLOW_EDIT_PREFERRED = `${APP}.workflow_edit_preferred_`;
const PROMPT_DISMISSED = `${APP}.extension.prompt_dismissed_`;

const getUserId = () => {
  if (sdk.session != null) {
    return sdk.session.userId;
  } else {
    return null;
  }
};

const get = (value) => {
  const item = window.localStorage.getItem(value);
  return item ? JSON.parse(item) : null;
};

const set = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));

const setDownloadPreferred = preferred => set(DOWNLOAD_PREFERRED + getUserId(), preferred);
const setWorkflowEditPreferred = preferred => set(WORKFLOW_EDIT_PREFERRED + getUserId(), preferred);

const isSessionSet = () => {
  const session = get(SESSION);
  return session ? !!session.token : false;
};

const getPreferred = (preferenceKey) => {
  const preferred = get(preferenceKey);
  return preferred === null ? true : preferred;
};

const getPromptDismissed = () => get(PROMPT_DISMISSED + getUserId());
const setPromptDismissed = value => set(PROMPT_DISMISSED + getUserId(), {
  dismissed: value,
  timestamp: new Date().getTime(),
});

const downloadPreferred = () => getPreferred(DOWNLOAD_PREFERRED + getUserId());

const workflowEditPreferred = () => getPreferred(WORKFLOW_EDIT_PREFERRED + getUserId());

export {
  setDownloadPreferred,
  setWorkflowEditPreferred,
  isSessionSet,
  downloadPreferred,
  workflowEditPreferred,
  getPromptDismissed,
  setPromptDismissed,
};
