/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Range from '../../components/Range/Range';

const getVolumeStatus = (volume) => {
  switch (volume) {
  case 0:
    return 'mute';
  case 1:
    return 'high';
  default:
    return volume < 0.5 ? 'low' : 'high';
  }
};

function PlayerVolume({
  volume,
  onChange,
}) {
  const volumeStatus = getVolumeStatus(volume);
  const changeTo = volume ? 0 : 1;
  return (
    <div className="Volume">
      <span
        onClick={() => onChange(changeTo)}
        className="Volume--icon"
      >
        <InlineSVG src={`/img/interface/volume/${volumeStatus}.svg`} />
      </span>
      <Range
        className="Volume--slider"
        size={100}
        orientation="vertical"
        value={volume * 100}
        onChange={percent => onChange(percent / 100)}
      />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  PlayerVolume.propTypes = {
    volume: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  };
}

export default PlayerVolume;
