/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import setLabel from './setLabel';

const firstUpper = str => (
  str[0].toUpperCase() +
  str.substring(1)
);

function replaceLabel(value, label) {
  return setLabel(value, firstUpper(label));
}

export default replaceLabel;
