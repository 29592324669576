/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import Button from './Button';
import { Translation } from '../Text';

class CopyButton extends Component {
  state = {
    copied: false,
  };

  componentWillUnmount() {
    clearTimeout(this._timeout);
  }

  onCopy = () => {
    window.navigator.clipboard.writeText(this.props.text)
      .then(() => {
        this.setState({copied: true});
        clearTimeout(this._timeout);
        this._timeout = setTimeout(() => {
          this.setState({copied: false});
        }, 3000);
      });
  }

  render() {
    const {text, ...props} = this.props;
    return (
      <Button
        {...props}
        autoWidth
        label={this.state.copied ? <Translation value="button.copy.finished" /> : <Translation value="button.copy" />}
        onClick={this.onCopy}
      />
    );
  }
}

export default CopyButton;
