/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import classname from 'classname';
import { InlineSpinner } from '../../components/Spinner';
import IconButton from '../../components/Button/IconButton';
import Tooltip from '../../components/Tooltip';
import Translation from '../../components/Text/Translation';
import css from './BarcodeScanner.scss';

const BarcodeScanner = (props) => {
  const {
    isLoading,
    isHighlighting,
    barcodes,
    pageNumber,
    onIsHighlightingChanged,
    onClose,
  } = props;

  const barcodesScanned = barcodes || [];
  const numberOfUniqueBarcodes = new Set((barcodesScanned).map(barcode => `${barcode.format}-${barcode.rawValue}`)).size;

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 30,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '20px 28px',
        borderRadius: 10000,
        overflow: 'hidden',
        maxHeight: 120,
        maxWidth: 500,
        '--shadow-color': '0deg 0% 20%',
        // eslint-disable-next-line quotes
        boxShadow: `0 2px 2px hsl(var(--shadow-color) / 0.05), 0 4px 4px hsl(var(--shadow-color) / 0.05), 0 8px 8px hsl(var(--shadow-color) / 0.05), 0 16px 16px hsl(var(--shadow-color) / 0.05), 0 32px 32px hsl(var(--shadow-color) / 0.05)`,
        backgroundColor: '#fff',
        zIndex: 10000,
      }}
    >
      {isLoading
        ? (
          <Fragment>
            <InlineSpinner size={32} />
            <span style={{ fontWeight: '500', paddingLeft: 14 }}>
              <Translation value="barcode.scanning" />
            </span>
          </Fragment>
        )
        : (
          <Fragment>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div>
                <div style={{ fontWeight: '500' }}>
                  {barcodesScanned.length === 0 && <Translation value="barcode.found.none" />}
                  {barcodesScanned.length === 1 && <Translation value="barcode.found.single" />}
                  {barcodesScanned.length > 1 && (
                    <Translation
                      value="barcode.found.multiple"
                      params={{ total: barcodesScanned.length }}
                    />
                  )}
                </div>
                <div style={{ fontSize: 12, color: '#777' }}>
                  {numberOfUniqueBarcodes === barcodesScanned.length
                    ? <Translation value="barcode.found.none.unique" />
                    : (
                      <Fragment>
                        {numberOfUniqueBarcodes === 0 && <Translation value="barcode.found.none.unique" />}
                        {numberOfUniqueBarcodes === 1 && <Translation value="barcode.found.single.unique" />}
                        {numberOfUniqueBarcodes > 1 && (
                          <Translation
                            value="barcode.found.multiple.unique"
                            params={{ total: numberOfUniqueBarcodes }}
                          />
                        )}
                      </Fragment>
                    )
                  }
                  {pageNumber}
                </div>
              </div>
              <div style={{ display: 'flex', gap: 2 }}>
                <Tooltip
                  title={
                    <span style={{ fontSize: 14 }}>
                      <Translation
                        value={isHighlighting
                          ? 'barcode.disable-spotlight'
                          : 'barcode.enable-spotlight'}
                      />
                    </span>
                  }
                  up
                  center
                >
                  <IconButton
                    src={isHighlighting
                      ? 'img/icons/material/light_bulb_filled-48px.svg'
                      : 'img/icons/material/light_bulb-48px.svg'}
                    onClick={() => {
                      onIsHighlightingChanged(!isHighlighting);
                    }}
                    className={classname(css.BarcodeScanner__button, {
                      [css['BarcodeScanner__button--enabled']]: isHighlighting,
                    })}
                  />
                </Tooltip>
                <Tooltip
                  title={<span style={{ fontSize: 14 }}><Translation value="button.close" /></span>}
                  up
                  center
                >
                  <IconButton
                    src="img/icons/material/rounded_close-48px.svg"
                    onClick={onClose}
                    className={css.BarcodeScanner__button}
                  />
                </Tooltip>
              </div>
            </div>
          </Fragment>
        )}
    </div>
  );
};

export default BarcodeScanner;
