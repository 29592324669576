/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'Snapshot',
  src: 'img/content/proof/icons/metadata/photo_camera_24dp.svg',
  activeColor: '#555',
  activeHoverColor: '#333',
  defaultSize: 21,
});
