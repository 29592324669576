let PDFJSPromise = null;

function loadPDFJS() {
  if (!PDFJSPromise) {
    PDFJSPromise = new Promise((resolve, reject) => {
      const element = document.createElement('script');
      element.onload = () => {
        const pdfjsLib = window['pdfjs-dist/build/pdf'];
        if (pdfjsLib) {
          pdfjsLib.GlobalWorkerOptions.workerSrc = '/static/pdfjs-2.4.456/pdf.worker.js';
          resolve(pdfjsLib);
        } else {
          reject(new Error('Failed to load PDF.js (script load did not populate global context with library).'));
        }
      };
      element.onerror = () => {
        reject(new Error('Failed to load PDF.JS (network error).'));
      };
      element.async = true;
      element.src = '/static/pdfjs-2.4.456/pdf.js';
      document.head.appendChild(element);
    });
  }
  return PDFJSPromise;
}

export default function getPDFJS() {
  return loadPDFJS();
}
