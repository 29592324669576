/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import css from './HeaderBanner.scss';

const HeaderBanner = ({ children, walkthroughHook }) => (
  <div
    data-walkthrough-hook={walkthroughHook}
    className={css.HeaderBanner}
  >
    {children}
  </div>
);

HeaderBanner.IconButton = ({ size = 'normal', variant = 'gray', active, src, disabled, rotate, translateY, translateX, scale, onClick, ariaLabel }) => {
  const transform = (
    [
      [rotate, `rotate(${rotate}deg)`],
      [translateY, `translateY(${translateY}px)`],
      [translateX, `translateX(${translateX}px)`],
      [scale, `scale(${scale})`],
    ]
      .map(([value, transformValue]) => (typeof value !== 'undefined' ? transformValue : ''))
      .filter(Boolean)
      .join(' ')
  );
  return (
    <button
      type="button"
      className={classname(css.IconButton, css[`IconButton--${variant}`], {
        [css['IconButton--' + size]]: size,
        [css['IconButton--active']]: active,
      })}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <div style={{ transform }}>
        <InlineSVG
          src={src}
          className={css.IconButton__icon}
        />
      </div>
    </button>
  );
};

export default HeaderBanner;
