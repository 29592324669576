/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-shadow, no-param-reassign, consistent-return */
import { useMemo, useState, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom'; // eslint-disable-line camelcase
import { sdk } from '../../../util/sdk';
import { makeUnstableBackendRequest } from '../../../util/unstable-backend-request';
import { getProofOwners, addProofOwner, removeProofOwner } from './proofOwners';
import { addSharedWith, getSharedWith, enableSharedWithCanEdit, disableSharedWithCanEdit, removeSharedWith } from './sharedWith';
import { addMember, getMembers, removeMember } from './members';

const cache = {};

export const useWorkflowTemplate = (workflowId) => {
  const [isLoading, setLoading] = useState(true);
  const [pendingMutations, setPendingMutations] = useState(0);

  const [workflowTemplate, setWorkflowTemplate] = useState(cache[workflowId] || null);
  cache[workflowId] = workflowTemplate;

  function load() {
    setLoading(true); // Only necessary if the workflowId changes

    Promise.all([
      sdk.workflows.load(workflowId),
      getSharedWith(workflowId),
      getProofOwners(workflowId),
    ])
      .then(([data, sharedWith, proofOwners]) => {
        window.__pageproof_bridge__.userRepositoryService.get(data.ownerId).then((user) => {
          data.sharedWith = sharedWith.users;
          data.proofOwners = proofOwners.users;
          data.owner = {
            id: data.ownerId,
            name: user.name,
            email: user.email,
          };

          // Patch the workflow object
          const finalStep = data.steps[data.steps.length - 1];
          if (finalStep.name === 'primary_email') {
            finalStep.name = null;
          }

          unstable_batchedUpdates(() => {
            setLoading(false);
            setWorkflowTemplate(data);
          });
        });
      })
      .catch(() => {
        setLoading(false);
      });
  }

  // Reload the workflow template when workflowId changes
  useEffect(load, [workflowId]);

  const {
    id: userId,
    teamId,
    isAdmin: isTeamAdmin,
  } = sdk.session.user;

  const canEdit = (
    !!workflowTemplate &&
    (
      workflowTemplate.ownerId === userId ||
      (workflowTemplate.teamId === teamId && isTeamAdmin) ||
      workflowTemplate.sharedWith.find(user => user.id === userId && user.permissions.canEdit)
    )
  );

  const mutations = useMemo(() => {
    function mutate(fn) {
      return new Promise((resolve, reject) => {
        setPendingMutations(count => count + 1);
        try {
          fn().then(resolve, reject);
        } catch (err) {
          reject(err);
        }
      }).then(
        () => setPendingMutations(count => count - 1),
        (err) => {
          setPendingMutations(count => count - 1);
          throw err;
        },
      );
    }

    return {
      delete() {
        return mutate(() => sdk.workflows.delete(workflowId));
      },
      setName(name) {
        return mutate(() => sdk.workflows.update(workflowId, { name }));
      },
      setFavorite(isFavorite) {
        return mutate(() => {
          setWorkflowTemplate((workflowTemplate) => {
            workflowTemplate.isFavorite = isFavorite;
            return { ...workflowTemplate };
          });

          if (isFavorite) {
            return sdk.workflows.favorite(workflowId);
          } else {
            return sdk.workflows.unfavorite(workflowId);
          }
        });
      },
      addWorkflowStep(workflow, localStep, index) {
        return mutate(() => {
          setWorkflowTemplate(workflow);
          return sdk.workflows._addWorkflowStepAndUsers(workflowId, localStep, false)
            .then((serverStep) => {
              setWorkflowTemplate((workflowTemplate) => {
                const step = workflowTemplate.steps.find(step => step._id === localStep._id);
                if (step) {
                  step.id = serverStep.id;
                }
                return { ...workflowTemplate };
              });
              const nextStep = workflow.steps[index + 1];
              if (nextStep.position !== 1000) {
                return sdk.workflows.steps.moveBefore(serverStep.id, nextStep.id);
              }
            });
        });
      },
      deleteWorkflowStep(workflow, step) {
        return mutate(() => {
          setWorkflowTemplate(workflow);
          return makeUnstableBackendRequest({
            method: 'POST',
            path: '/api/workflows/step/delete',
            body: { StepId: step.id },
          });
        });
      },
      addWorkflowUsers(workflow, step, users) {
        return mutate(() => {
          setWorkflowTemplate(workflow);
          return makeUnstableBackendRequest({
            method: 'POST',
            path: '/api/v2/workflows/users/add',
            body: {
              WorkflowId: workflow.id,
              StepId: step.id,
              Users: users.map(user => ({
                Email: user.email.trim().toLowerCase(),
                Role: user.role,
                Permissions: {
                  Inviter: user.permissions.inviter,
                },
              })),
            },
          }).then((response) => {
            const userIds = {};
            response.forEach((user) => {
              userIds[user.Email] = user.UserId;
            });
            setWorkflowTemplate((workflow) => {
              workflow.steps.forEach((workflowStep) => {
                workflowStep.users.forEach((stepUser) => {
                  if (stepUser.email in userIds) {
                    stepUser.id = userIds[stepUser.email];
                  }
                });
              });
              return { ...workflow };
            });
          });
        });
      },
      removeWorkflowUser(workflow, step, user) {
        return mutate(() => {
          setWorkflowTemplate(workflow);
          return makeUnstableBackendRequest({
            method: 'POST',
            path: '/api/v2/workflows/users/remove',
            body: {
              WorkflowId: workflow.id,
              StepId: step.id,
              UserId: user.id,
            },
          });
        });
      },
      moveWorkflowStep(workflow, step, nextStep) {
        return mutate(() => {
          setWorkflowTemplate(workflow);
          return sdk.workflows.steps.moveBefore(step.id, nextStep.id);
        });
      },
      updateWorkflowStepName(workflow, step) {
        return mutate(() => {
          setWorkflowTemplate(workflow);
          return sdk.workflows.steps.update(step.id, {
            title: step.name,
          });
        });
      },
      updateWorkflowStepMandatoryDecisionThreshold(workflow, step) {
        return mutate(() => {
          setWorkflowTemplate(workflow);
          return sdk.workflows.steps.update(step.id, {
            mandatoryDecisionThreshold: step.mandatoryDecisionThreshold,
          });
        });
      },
      updateWorkflowUserRole(workflow, step, user) {
        return mutate(() => {
          setWorkflowTemplate(workflow);
          return makeUnstableBackendRequest({
            method: 'POST',
            path: '/api/v2/workflows/users/update',
            body: {
              WorkflowId: workflow.id,
              StepId: step.id,
              UserId: user.id,
              Role: user.role,
            },
          });
        });
      },
      updateWorkflowUserPermissions(workflow, step, user) {
        return mutate(() => {
          workflow.steps.forEach((workflowStep) => {
            workflowStep.users.forEach((stepUser) => {
              if (stepUser.id === user.id) {
                stepUser.permissions.inviter = user.permissions.inviter;
              }
            });
          });
          setWorkflowTemplate(workflow);
          if (step.position === 1000) {
            if (user.permissions.inviter) {
              return addMember(workflow.id, user.id, 'WorkflowInviter');
            }

            return getMembers(workflow.id).then(((members) => {
              const member = members.users.find(member => member.id === user.id);
              return removeMember(member._memberId, 'WorkflowInviter');
            }));
          }

          return makeUnstableBackendRequest({
            method: 'POST',
            path: '/api/v2/workflows/users/update',
            body: {
              WorkflowId: workflow.id,
              StepId: step.id,
              UserId: user.id,
              Permissions: {
                Inviter: user.permissions.inviter,
              },
            },
          });
        });
      },
      setSharedWithTeam(isSharedWithTeam) {
        return mutate(() => {
          setWorkflowTemplate(workflowTemplate => ({
            ...workflowTemplate,
            isOwnedByTeam: isSharedWithTeam,
          }));
          return sdk.workflows.update(workflowId, {
            isOwnedByTeam: isSharedWithTeam,
          });
        });
      },
      enableSharedUserCanEdit(userId) {
        return mutate(() => {
          setWorkflowTemplate(workflowTemplate => ({
            ...workflowTemplate,
            sharedWith: workflowTemplate.sharedWith
              .map(user => (
                user.id === userId
                  ? {
                    ...user,
                    permissions: {
                      ...user.permissions,
                      canEdit: true,
                    },
                  }
                  : user
              )),
          }));
          return enableSharedWithCanEdit(workflowId, userId)
            .then(({ memberId }) => {
              setWorkflowTemplate(workflowTemplate => ({
                ...workflowTemplate,
                sharedWith: workflowTemplate.sharedWith
                  .map(user => (
                    user.id === userId
                      ? {
                        ...user,
                        _memberId: memberId,
                      }
                      : user
                  )),
              }));
            });
        });
      },
      disableSharedUserCanEdit(userId, memberId) {
        return mutate(() => {
          setWorkflowTemplate(workflowTemplate => ({
            ...workflowTemplate,
            sharedWith: workflowTemplate.sharedWith
              .map(user => (
                user.id === userId
                  ? {
                    ...user,
                    permissions: {
                      ...user.permissions,
                      canEdit: false,
                    },
                  }
                  : user
              )),
          }));
          return disableSharedWithCanEdit(memberId);
        });
      },
      addSharedWith(email) {
        return mutate(() => {
          setWorkflowTemplate(workflowTemplate => ({
            ...workflowTemplate,
            sharedWith: [
              ...workflowTemplate.sharedWith,
              {
                email,
                permissions: {
                  canEdit: false,
                },
              },
            ],
          }));

          return addSharedWith(workflowId, email).then(({ userId }) => {
            setWorkflowTemplate(workflowTemplate => ({
              ...workflowTemplate,
              sharedWith: workflowTemplate.sharedWith
                .map(user => (
                  user.email === email
                    ? {
                      ...user,
                      id: userId,
                    }
                    : user
                )),
            }));
          });
        });
      },
      removeSharedWith(userId) {
        return mutate(() => {
          setWorkflowTemplate(workflowTemplate => ({
            ...workflowTemplate,
            sharedWith: workflowTemplate.sharedWith
              .filter(user => user.id !== userId),
          }));

          return removeSharedWith(workflowId, userId);
        });
      },
      addProofOwner(email) {
        return mutate(() => {
          setWorkflowTemplate(workflowTemplate => ({
            ...workflowTemplate,
            proofOwners: [
              ...workflowTemplate.proofOwners,
              {
                email,
              },
            ],
          }));

          return addProofOwner(workflowId, email).then(({ userId, memberId }) => {
            setWorkflowTemplate(workflowTemplate => ({
              ...workflowTemplate,
              proofOwners: workflowTemplate.proofOwners
                .map(user => (
                  user.email === email
                    ? {
                      id: userId,
                      email,
                      _memberId: memberId,
                    }
                    : user
                )),
            }));
          });
        });
      },
      removeProofOwner({ email, _memberId: memberId }) {
        return mutate(() => {
          setWorkflowTemplate(workflowTemplate => ({
            ...workflowTemplate,
            proofOwners: workflowTemplate.proofOwners
              .filter(user => user.email !== email),
          }));

          return removeProofOwner(memberId);
        });
      },
    };
  }, [workflowId]);

  return {
    isLoading: isLoading || pendingMutations > 0,
    isReadOnly: !canEdit,
    workflowTemplate,
    mutations,
    reload: load,
  };
};
