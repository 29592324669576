/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Popover from '../Popover';
import CRPopover from '../CRPopover';
import css from './ContentCredentials.scss';


function ContentCredentials({
  crData,
  showPopover,
  label,
  onClick,
}) {
  return (
    <Popover
      backdrop={false}
      right
      middle
      arrow
      offset={50}
      visible={showPopover}
      content={() => (<CRPopover crData={crData} />)}
      variant="light"
    >
      <div
        role="presentation"
        onClick={onClick}
        className={css['ContentCredentials__option-label__label']}
      >
        {label}
        <span className={css['ContentCredentials__option-label__arrow']}>
            &#x276e;
        </span>
      </div>
    </Popover>
  );
}

export default ContentCredentials;
