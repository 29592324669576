/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import NotVisibleOverlay from '../NotVisibleOverlay';
import css from './WorkflowNotVisibleOverlay';

function WorkflowNotVisibleOverlay({
  label,
  onHover,
  hovered,
}) {
  return (
    <div
      className={classname(
        css.WorkflowNotVisibleOverlay,
        {[css['WorkflowNotVisibleOverlay--hide']]: onHover && hovered},
      )}
    >
      <NotVisibleOverlay
        label={label}
      />
    </div>
  );
}

export default WorkflowNotVisibleOverlay;
