import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Option, Separator } from '../PopupMenu';
import SubOptionList from './SubOptionList';
import Translation from '../Text/Translation';

const EmailMetadata = ({ emailMetadata: { from, subject } }) => (
  <Fragment>
    <Separator />
    <Option
      label={<Translation value="proof.page.info.email.title" />}
      disabled
    />
    <SubOptionList
      label={<Translation value="proof.page.info.email.sender" />}
      options={[from]}
    >
      <Option readOnly />
    </SubOptionList>

    <SubOptionList
      label={<Translation value="proof.page.info.email.subject" />}
      options={[subject]}
    >
      <Option readOnly />
    </SubOptionList>
  </Fragment>
);


if (process.env.NODE_ENV === 'development') {
  EmailMetadata.propTypes = {
    emailMetadata: PropTypes.shape({
      from: PropTypes.string,
      subject: PropTypes.string,
    }).isRequired,
  };
}


export default EmailMetadata;
