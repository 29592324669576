import { sdk } from '../../../util/sdk';

export function getFallbackCanDownload() {
  const { userId } = sdk.session;
  return window.localStorage.getItem('download_preferred_' + userId) !== 'false';
}

export function setFallbackCanDownload(canDownload) {
  const { userId } = sdk.session;
  window.localStorage.setItem('download_preferred_' + userId, canDownload);
}
