import { useEffect } from 'react';

function useOnMessageEventListener(onMessageReceived) {
  useEffect(() => {
    window.addEventListener('message', onMessageReceived);

    return () => {
      window.removeEventListener('message', onMessageReceived);
    };
  }, []);
}

export default useOnMessageEventListener;
