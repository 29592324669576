/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useMemo } from 'react';
import { sortObjectsByKey } from '../util/sort';

const useFolderList = (arr) => {
  const folderList = useMemo(() => {
    const foldersObj = {};
    const unassociatedCollectionsArr = [];

    arr.forEach((item) => {
      const { folder } = item;

      if (folder) {
        if (foldersObj[folder.name]) {
          return foldersObj[folder.name].push(item);
        } else {
          return foldersObj[folder.name] = [item];
        }
      } else {
        return unassociatedCollectionsArr.push(item);
      }
    });

    const mappedFoldersArr = Object.entries(foldersObj).map(([key, value]) => {
      const collections = value.sort(sortObjectsByKey('groupName', false, true));

      return {
        name: key,
        collections,
      };
    });

    const mappedCollectionsArr = unassociatedCollectionsArr.map(collection => ({
      name: collection.groupName,
      collection,
    }));

    return mappedFoldersArr.concat(mappedCollectionsArr).sort(sortObjectsByKey('name', false, true));
  }, [arr]);

  return folderList;
};

export default useFolderList;
