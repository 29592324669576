/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useState, useEffect } from 'react';
import { sdk } from '../util/sdk';
import { useLocalStorage } from '../components/LocalStorage';

const sortWorkflowsByName = workflows => workflows.slice().sort((a, b) => a.name.localeCompare(b.name));

function useUserWorkflowTemplates() {
  const userId = sdk.session ? sdk.session.userId : null;

  const [readyState, setReadyState] = useState('loading');
  const [workflowTemplates, setWorkflowTemplates] = useLocalStorage(`pageproof.cache.user-workflow-templates.${userId}`, () => ({
    favorites: [],
    owned: [],
    shared: [],
    all: [],
  }));

  function loadWorkflowTemplates(isReload) {
    setReadyState(isReload ? 'reloading' : 'loading');
    Promise.all([
      sdk.workflows.owned(),
      sdk.workflows.shared(),
    ])
      .then(([owned, shared]) => {
        const sortedOwned = sortWorkflowsByName(owned);
        const sortedShared = sortWorkflowsByName(shared);
        const sortedAll = sortWorkflowsByName([...sortedOwned, ...sortedShared]);

        setReadyState('loaded');
        setWorkflowTemplates({
          favorites: sortedAll
            .filter(workflow => workflow.isFavorite === true)
            .map(workflow => ({
              id: workflow.id,
              name: workflow.name,
            })),
          owned: sortedOwned.map(workflow => ({
            id: workflow.id,
            name: workflow.name,
          })),
          shared: sortedShared.map(workflow => ({
            id: workflow.id,
            name: workflow.name,
          })),
          all: sortedAll.map(workflow => ({
            id: workflow.id,
            name: workflow.name,
          })),
        });
      }, () => {
        setReadyState('loaded');
      });
  }

  function reloadWorkflowTemplates() {
    loadWorkflowTemplates(true);
  }

  useEffect(() => {
    loadWorkflowTemplates(false);
  }, [userId]);

  workflowTemplates.favorites = workflowTemplates.favorites || []; // Adding this to ensure "favorites" is always present and doesn't throw an error when a user opens a page for the first time.

  return [readyState, workflowTemplates, reloadWorkflowTemplates];
}

export default useUserWorkflowTemplates;
