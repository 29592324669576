/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Enum } from '@pageproof/sdk';
import HorizontalDelimiter from '../HorizontalDelimiter';
import ColorDot from '../ColorDot';
import { useUserPreferences } from '../../hooks/useUserPreferences';
import InlineSpinner from '../Spinner/InlineSpinner';
import { sortObjectsByKey } from '../../util/sort';
import Translation from '../Text/Translation';
import { ALL_PIN_COLORS, DEFAULT_PIN_COLORS } from '../../util/user-color-preference';
import css from './PinColorPreferences.scss';
import Button from '../Button/Button';

const getColorOrder = (color, { unmarked, todo, done }) => {
  switch (color.normal) {
  case unmarked.normal:
    return 1;
  case todo.normal:
    return 2;
  case done.normal:
    return 3;
  default:
    return 4;
  }
};


const PinColorPreferences = () => {
  const [isLoading, userPreferences, { updateUserPreference, resetUserPreference }] = useUserPreferences();
  const pinColors = userPreferences && userPreferences.colors;

  const onChange = (color, type) => {
    const newColorPreferenceValue = {
      light: color.light,
      normal: color.normal,
      dark: color.dark,
    };
    updateUserPreference(Enum.Preference.COLORS, {
      ...pinColors.value,
      [type]: newColorPreferenceValue,
    });
  };

  const onReset = () => {
    resetUserPreference(Enum.Preference.COLORS);
  };

  if (isLoading || !pinColors) {
    return (
      <div style={{ paddingTop: 10 }}>
        <InlineSpinner size={25} />
      </div>
    );
  } else {
    const { unmarked, todo, done } = pinColors.value;
    const disabledColorArray = [
      unmarked.normal,
      todo.normal,
      done.normal,
    ];

    const sortedPinColors = Object.keys(ALL_PIN_COLORS).map((key) => {
      const color = ALL_PIN_COLORS[key];
      return {
        ...color,
        disabled: disabledColorArray.includes(color.normal),
        order: getColorOrder(color, { unmarked, todo, done }),
      };
    }).sort(sortObjectsByKey('order'));

    const displayPinColors = [
      ...sortedPinColors.slice(0, 3),
      { isLine: true, id: 'line' },
      ...sortedPinColors.slice(3),
    ];

    const canReset = (
      unmarked.normal !== DEFAULT_PIN_COLORS.unmarked.normal ||
      todo.normal !== DEFAULT_PIN_COLORS.todo.normal ||
      done.normal !== DEFAULT_PIN_COLORS.done.normal
    );

    const delimiter = <div style={{ width: 20 }} />;
    return (
      <div>
        <HorizontalDelimiter delimiter={delimiter}>
          <PinColorPreferences.UnmarkedComment
            selectedColor={unmarked.normal}
            displayPinColors={displayPinColors}
            onChange={onChange}
          />
          <PinColorPreferences.TodoComment
            selectedColor={todo.normal}
            displayPinColors={displayPinColors}
            onChange={onChange}
          />
          <PinColorPreferences.DoneComment
            selectedColor={done.normal}
            displayPinColors={displayPinColors}
            onChange={onChange}
          />
          {canReset &&
            <PinColorPreferences.Reset
              selected={false}
              onChange={onReset}
            />
          }
        </HorizontalDelimiter>
      </div>
    );
  }
};

PinColorPreferences.UnmarkedComment = ({ ...props }) => (
  <PinColorPreferences.ColorDotWrapper
    type="unmarked"
    {...props}
  />
);

PinColorPreferences.TodoComment = ({ ...props }) => (
  <PinColorPreferences.ColorDotWrapper
    type="todo"
    {...props}
  />
);

PinColorPreferences.DoneComment = ({ ...props }) => (
  <PinColorPreferences.ColorDotWrapper
    type="done"
    {...props}
  />
);

PinColorPreferences.Reset = ({ onChange }) => (
  <Button
    className={css.PinColorPreferences__reset}
    variant="outlineGrey"
    size="small"
    autoWidth
    label={<Translation value="profile.settings.pin-colors.reset" />}
    onClick={onChange}
  />
);

PinColorPreferences.ColorDotWrapper = ({
  type,
  displayPinColors,
  selectedColor,
  onChange,
}) => (
  <ColorDot
    colors={displayPinColors}
    tooltipText={`color-preference.pin.label.${type}.tooltip`}
    selectedColor={selectedColor}
    onChange={color => onChange(color, type)}
    variant="light"
    maxWidth={170}
  >
    <ColorDot.Label
      label={<Translation value={`color-preference.pin.label.${type}`} />}
    />
  </ColorDot>
);

export default PinColorPreferences;
