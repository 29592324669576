/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './Alert.scss';

function Alert({ children, variant, size = 'medium' }) {
  return (
    <div
      className={classname(
        css.Alert,
        css[`Alert--${variant}-variant`],
        css[`Alert--${size}-size`],
      )}
    >
      <div className={css.Alert__content}>
        {children}
      </div>
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Alert.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.string.isRequired,
    size: PropTypes.string,
  };
}

export default Alert;
