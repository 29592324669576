/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import Translation from '../../Text/Translation';
import {sdk} from '../../../util/sdk';

class Agrees extends Component {
  state = {
    isLoading: true, // todo change this back
    emails: [],
  };

  componentDidMount() {
    this.load(this.props);
  }

  load(props) {
    const selfId = sdk.session.userId;
    const userIds = props.users.slice();
    const selfIdIndex = userIds.indexOf(selfId);
    if (selfIdIndex !== -1) {
      userIds.splice(selfIdIndex, 1);
      userIds.unshift(selfId);
    }
    const userIdsToLoad = userIds.filter((userId, index) => (
      index < props.limit ||
      userId === selfId
    ));
    const omitted = userIds.length - userIdsToLoad.length;
    const wait = Promise.all(userIdsToLoad.map(userId => (
      window.__pageproof_bridge__.userRepositoryService
        .get(userId).then(user => user.email)
        .then(email => `${email}${userId === selfId ? ' (you)' : ''}`)
    )));
    wait.then((emails) => {
      this.setState({
        isLoading: false,
        emails: emails.concat(omitted
          ? [`+ ${omitted} other${omitted > 1 ? 's' : ''}`] : []),
      });
    });
  }

  render() {
    if (this.props.users.length === 0) {
      return <Translation value="agrees.no-agrees" />;
    }
    if (this.state.isLoading) {
      return <Translation value="loading.message" />;
    }
    return (
      <Fragment>
        <div>
          <strong>
            <Translation value="agrees.title" />
          </strong>
        </div>
        {this.state.emails.map((email, index) => (
          <div key={index}>{email}</div>
        ))}
      </Fragment>
    );
  }
}

Agrees.defaultProps = {
  limit: 5,
};

Agrees.propTypes = {
  users: PropTypes.arrayOf(PropTypes.string).isRequired,
  limit: PropTypes.number, // eslint-disable-line
};

export default Agrees;
