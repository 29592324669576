/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {Component} from 'react';
import PropTypes from 'prop-types';
import {createPortal} from 'react-dom';

class Portal extends Component {
  currentRoot = null;

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    this.element = document.createElement('div');
    this.currentRoot = this.props.root || document.body;
    this.currentRoot.appendChild(this.element);
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.currentRoot && (nextProps.root || document.body) !== this.currentRoot) {
      this.currentRoot.removeChild(this.element);
      this.currentRoot = nextProps.root || document.body;
      this.currentRoot.appendChild(this.element);
    }
  }

  componentWillUnmount() {
    this.currentRoot.removeChild(this.element);
  }

  render() {
    return createPortal(this.props.children, this.element);
  }
}

Portal.propTypes = {
  root: PropTypes.any, // eslint-disable-line
  children: PropTypes.node,
};

export default Portal;
