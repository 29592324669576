/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const env = {};

try {
  // The following code is used in the Adobe Addon to ensure that the SSO flow generates a
  // session that can be used along with the Adobe Addon's application ID. Please don't remove me :)

  /* eslint-disable */
  const LOCALSTORAGE_PREFIX = 'pageproof.env.';
  let length = window.localStorage.length;
  while (length--) {
    const key = window.localStorage.key(length);
    if (key.indexOf(LOCALSTORAGE_PREFIX) === 0) {
      const name = key.substring(LOCALSTORAGE_PREFIX.length);
      env[name] = window.localStorage.getItem(key);
    }
  }
  /* eslint-enable */
} catch (err) {
  // When embedding the application, and the origins don't match (cross-origin), certain browsers
  // disable access to localStorage, so the code above will throw.
}

const elements = Array.prototype.slice.apply(document.querySelectorAll('meta[data-env]'));
elements.forEach(element => (env[element.name] = element.dataset.env));

module.exports = env;
