/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const regex = /(\d+)x(\d+|auto\b)/;

function match(value) {
  if (!value) {
    return null;
  }
  const groups = String(value).match(regex);
  if (groups) {
    return {
      width: +groups[1],
      height: groups[2] === 'auto' ? groups[2] : +groups[2],
    };
  } else {
    return null;
  }
}

export {
  match,
};
