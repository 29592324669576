/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import Translation from '../Text/Translation';
import { useLocalStorage } from '../LocalStorage';
import { sdk } from '../../util/sdk';
import Tooltip from '../Tooltip';
import css from './NotificationSnooze.scss';

function NotificationSnooze() {
  const [isSnoozed, setIsSnooze] = useLocalStorage(`pageproof.app.notifications.${sdk.session.user.id}.snoozed`, false);

  return (
    <Tooltip
      center
      down
      title={isSnoozed
        ? <Translation value="notifications.snoozed" />
        : <Translation value="notifications.snooze" />
      }
    >
      <button
        className={classname(css.NotificationSnooze, {
          [css['NotificationSnooze--active']]: isSnoozed,
        })}
        type="button"
        onClick={() => setIsSnooze(!isSnoozed)}
      >
        <div
          className={css.NotificationSnooze__backdrop}
        />
        <div className={css.NotificationSnooze__snoozeText}>
          <span className={css.NotificationSnooze__snoozeText__zFirst}>z</span>
          <sup className={css.NotificationSnooze__snoozeText__zSecond}>z</sup>
        </div>
        <InlineSVG
          src="img/interface/notification.svg"
          className={css.NotificationSnooze__snoozeIcon}
        />
      </button>
    </Tooltip>
  );
}

export default NotificationSnooze;
