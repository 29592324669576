/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useRef } from 'react';
import classname from 'classname';
import Tooltip from '../../Tooltip';
import { drag } from '../utils/drag';
import { useForceRender } from '../../../hooks/useForceRender';
import css from './VideoPlayerTimelinePin.scss';

const useUpdatePin = (pin) => {
  // Fun little hack that changes the way old pins are displayed.
  // Half a second duration means the pin should be placed on the frame (aka. no duration).
  const ogDuration = pin.duration === 0.5 ? 0 : pin.duration;

  const pinTime = Math.min(pin.time, pin.time + ogDuration);
  const pinDuration = Math.max(pin.time, pin.time + ogDuration) - pinTime;

  const forceRender = useForceRender();
  const updatingRef = useRef(null);
  const hasUpdatesRef = useRef(false);

  const latestPin = useRef({ time: pinTime, duration: pinDuration });
  if (latestPin.current.time !== pinTime || latestPin.current.duration !== pinDuration) {
    latestPin.current = { time: pinTime, duration: pinDuration };
    updatingRef.current = null;
  }

  function get() {
    return updatingRef.current || latestPin.current;
  }

  return {
    isUpdating: updatingRef.current !== null,
    get() {
      return get();
    },
    start() {
      updatingRef.current = { ...latestPin.current, ...get() };
      forceRender();
    },
    update(updates) {
      updatingRef.current = { ...updatingRef.current, ...updates };
      hasUpdatesRef.current = true;
      forceRender();
    },
    reset() {
      updatingRef.current = null;
      hasUpdatesRef.current = false;
      forceRender();
    },
    hasUpdates() {
      return hasUpdatesRef.current;
    },
  };
};

export const VideoPlayerTimelinePin = ({
  currentTime,
  durationTime,
  comment,
  pin,
  pinIndex,
  pinNumber,
  pinColor,
  timePresentation,
  selectedPinIndex,
  selectedCommentId,
  rootRef,
  scrub,
  scrubEnd,
  selectComment,
  canUpdate,
  updatePin,
  isDisabled,
}) => {
  const info = useUpdatePin(pin);
  const { time, duration } = info.get();

  const update = () => {
    if (info.hasUpdates()) {
      updatePin(comment.id, pinIndex, info.get());
    }
    info.reset();
  };

  const isCommentSelected = selectedCommentId === comment.id;
  const isPinSelected = selectedPinIndex === pinIndex || selectedPinIndex === -1;
  const isCurrent = currentTime >= (time - 0.150) && currentTime <= (time + duration + 0.150);

  return (
    <Tooltip
      title={() => (
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontVariantNumeric: 'tabular-nums' }}>
            {timePresentation.fn(time)}
            {duration > 1 && (
              <Fragment>
                {' - '}
                {timePresentation.fn(time + duration)}
              </Fragment>
            )}
          </div>
          <div style={{ marginTop: 5, opacity: 0.6 }}>
            Comment #
            {comment.number}
            {comment.pins.length > 1 && (
              <span style={{ paddingLeft: 10 }}>
                Pin
                {' '}
                {pinNumber}
                {' of '}
                {comment.pins.length}
              </span>
            )}
          </div>
        </div>
      )}
      down
      center
      arrow
      offset={14}
      {...(info.isUpdating && {
        visible: true,
      })}
    >
      {/* eslint-disable-next-line */}
      <div
        className={classname(css.VideoPlayerTimelinePin, {
          [css['VideoPlayerTimelinePin--canUpdate']]: canUpdate,
          [css['VideoPlayerTimelinePin--isSelected']]: isPinSelected,
          [css['VideoPlayerTimelinePin--isCurrent']]: isCurrent,
          [css['VideoPlayerTimelinePin--isDragging']]: info.isUpdating,
          [css['VideoPlayerTimelinePin--isDisabled']]: isDisabled,
        })}
        style={{
          left: `${(time / durationTime) * 100}%`,
          width: `max(4px, ${(duration / durationTime) * 100}%)`,
          // eslint-disable-next-line
          zIndex: (info.isUpdating || isCommentSelected) ? 100000 : 1000,
          '--pin-color': pinColor,
        }}
        onPointerDown={(event) => {
          event.stopPropagation();
          event.preventDefault();

          if (event.button !== 0 || !canUpdate) {
            return;
          }

          drag({
            startEvent: event,
            containerElement: rootRef.current,
            targetElement: event.target,
            onMove: ({ targetLeft }) => {
              // Uncomment this line to allow squashing the pin duration on the right side
              // const newDuration = Math.min(duration, durationTime - (targetLeft.x.percent * durationTime));

              const newDuration = duration;
              const newTime = Math.min(durationTime - newDuration, targetLeft.x.percent * durationTime);

              info.update({
                time: newTime,
                duration: newDuration,
              });

              scrub(newTime, true);
            },
            onEnd: () => {
              scrubEnd();
              update();
            },
          });
        }}
        onClick={() => {
          if (selectedPinIndex === pinIndex || (selectedPinIndex === -1 && comment.pins.length === 1)) {
            selectComment(null, -1);
          } else {
            selectComment(comment.id, pinIndex);
            scrub(time);
          }
        }}
        onDoubleClick={() => {
          selectComment(comment.id, -1);
          scrub(time);
        }}
      >
        {canUpdate && (
          <Fragment>
            <div
              className={css.VideoPlayerTimelinePin__handle}
              style={{
                right: 'calc(100% + 4px)',
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                borderRight: 'none',
              }}
              onPointerDown={(event) => {
                event.stopPropagation();
                event.preventDefault();

                if (event.button !== 0) {
                  return;
                }

                info.start();
                drag({
                  startEvent: event,
                  targetElement: event.target,
                  containerElement: rootRef.current,
                  onMove: ({ targetRight: pointer }) => {
                    const current = info.get();
                    const updatedTime = pointer.x.percent * durationTime;
                    const updatedDuration = (current.time - updatedTime) + current.duration;
                    info.update({
                      time: updatedTime,
                      duration: Math.max(0, updatedDuration),
                    });
                    scrub(updatedTime, true);
                  },
                  onEnd: () => {
                    scrubEnd();
                    update();
                  },
                  cursor: 'ew-resize',
                });
              }}
            />
            <div
              className={css.VideoPlayerTimelinePin__handle}
              style={{
                left: 'calc(100% + 4px)',
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3,
                borderLeft: 'none',
              }}
              onPointerDown={(event) => {
                event.stopPropagation();
                event.preventDefault();

                if (event.button !== 0) {
                  return;
                }

                info.start();
                drag({
                  startEvent: event,
                  targetElement: event.target,
                  containerElement: rootRef.current,
                  onMove: ({ targetLeft: pointer }) => {
                    const current = info.get();
                    const updatedDuration = (pointer.x.percent * durationTime) - current.time;
                    info.update({
                      duration: updatedDuration,
                      ...(updatedDuration < 0 && {
                        time: current.time + updatedDuration,
                      }),
                    });
                    scrub(current.time + updatedDuration, true);
                  },
                  onEnd: () => {
                    scrubEnd();
                    update();
                  },
                  cursor: 'ew-resize',
                });
              }}
            />
          </Fragment>
        )}
      </div>
    </Tooltip>
  );
};
