/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import Portal from '../Portal';

const root = document.createElement('div');
root.id = 'scoped-stylesheets';
document.body.appendChild(root);

let index = 0;
const base = Math.round(Math.random() * 1000);

function serialiseStyles(styles, id) {
  return (
    Object.keys(styles).map((selector) => {
      const selectors = selector.split(/,\s*/).map(segment => (
        '#' + id + (
          segment.indexOf('&') === 0
            ? segment.substring(1)
            : ' ' + segment
        )
      ));
      const rules = Object.keys(styles[selector]).map((rule) => {
        const name = rule.replace(/([A-Z])/g, '-$1').toLowerCase();
        const value = styles[selector][rule];
        if (!name || !value) {
          return null;
        }
        return `${name}: ${value}${typeof value === 'number' ? 'px' : ''}`;
      }).filter(Boolean).join('; ');
      if (!rules) {
        return null;
      }
      return (
        `${selectors.join(',')} {
          ${rules}
        }\n`
      );
    }).filter(Boolean).join('')
  );
}

class ScopedStylesheet extends Component {
  static propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.func, // class
      PropTypes.string,
    ]),
    styles: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    component: 'div',
  };

  isPureReactComponent = true;

  constructor(props) {
    super(props);

    this.id = '_' + base + '_ss_' + (++index); // eslint-disable-line no-plusplus
    this.state = {
      stylesheet: serialiseStyles(this.props.styles, this.id),
    };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const stylesheet = serialiseStyles(nextProps.styles, this.id);
    if (stylesheet !== this.state.stylesheet) {
      this.setState({stylesheet});
    }
  }

  render() {
    const {stylesheet} = this.state;
    const {component: Container, styles, children, ...props} = this.props;
    return (
      <Fragment>
        <Container id={this.id} {...props}>
          {children}
        </Container>
        <Portal root={root}>
          <style>
            {stylesheet}
          </style>
        </Portal>
      </Fragment>
    );
  }
}

export default ScopedStylesheet;
