/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import PropTypes from 'prop-types';
import EmailAddressEdit from '../EmailAddressEdit';
import { addEmail } from '../Suggestions';
import css from './Contacts.scss';

const Contacts = ({ emails, removeEmail }) => (
  emails.sort().map(email => (
    <Contact
      key={email}
      email={email}
      removeEmail={removeEmail}
    />
  ))
);

const Contact = ({ email, removeEmail }) => {
  const handleUpdate = (editedEmail) => {
    if (editedEmail !== email) {
      removeEmail(email);
      addEmail(editedEmail);
    }
  };

  return (
    <div className={css.Contact}>
      <EmailAddressEdit
        showRemoveOnHover
        email={email}
        onDelete={removeEmail}
        onUpdate={handleUpdate}
      />
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Contact.propTypes = {
    email: PropTypes.string.isRequired,
    removeEmail: PropTypes.func.isRequired,
  };
}

export default Contacts;
