/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Translation from '../Text/Translation';
import ButtonGroup from '../ButtonGroup';
import { Button } from '../Button';
import { Ellipsis } from '../Text';
import css from './ConfirmBox.scss';

const ConfirmBox = ({
  heading,
  message,
  subHeading,
  className,
  buttons,
  params,
}) => {
  const [clickedButtonId, setClickedButtonId] = useState(null);
  return (
    <div className={classname(css.ConfirmBox, className)}>
      {heading &&
        <h3 className={css.ConfirmBox__heading}>
          <Translation value={heading} />
        </h3>
      }
      {subHeading &&
        <div className={css.ConfirmBox__subHeading}>
          <Translation value={subHeading} />
        </div>
      }
      <div className={css.ConfirmBox__message}>
        <Translation
          value={message}
          params={params}
        />
      </div>
      <ButtonGroup align="center">
        {buttons.map(button => (
          <Button
            key={button.id}
            variant={button.type}
            label={
              button.id === clickedButtonId
                ? (
                  <Fragment>
                    <Translation value={button.activeLabel} />
                    <Ellipsis />
                  </Fragment>
                )
                : <Translation value={button.label} />
            }
            disabled={button.id === clickedButtonId}
            onClick={() => {
              setClickedButtonId(button.id);
              button.onClick();
            }}
          />
        ))}
      </ButtonGroup>
    </div>
  );
};

ConfirmBox.CancelButton = {
  id: 'cancel-button',
  label: 'button.cancel',
  type: 'text',
};

if (process.env.NODE_ENV !== 'production') {
  ConfirmBox.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    message: PropTypes.string.isRequired,
    className: PropTypes.string,
    buttons: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.string,
          label: PropTypes.string,
          activeLabel: PropTypes.string,
          onClick: PropTypes.func,
        })
      ),
      PropTypes.bool,
    ]),
  };
}

export default ConfirmBox;
