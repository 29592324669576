/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {Component} from 'react';
import {watch, unwatch} from './watcher';

class Value extends Component {
  state = {
    value: null,
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    this.setState({
      value: this.getValue(this.props.name, this.props.defaultValue),
    });
  }

  componentDidMount() {
    if (this.props.watch) {
      watch(this.props.name, this.checkUpdate);
    }
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.name !== nextProps.name) {
      if (this.props.watch) {
        unwatch(this.props.name, this.checkUpdate);
        watch(nextProps.name, this.checkUpdate);
      }
      this.updateValue(nextProps.name, nextProps.defaultValue);
    }
  }

  // shouldComponentUpdate() {
  //   return false;
  // }

  componentWillUnmount() {
    if (this.props.watch) {
      unwatch(this.props.name, this.checkUpdate);
    }
  }

  // eslint-disable-next-line
  getValue(name, defaultValue) {
    const rawValue = window.localStorage.getItem(name);
    if (rawValue === null) {
      return defaultValue;
    }
    return rawValue;
  }

  updateValue = (name, defaultValue) => {
    const value = this.getValue(name, defaultValue);
    if (this.state.value !== value) {
      this.setState({value});
    }
  }

  checkUpdate = () => {
    this.updateValue(this.props.name, this.props.defaultValue);
  }

  setValue = (value) => {
    window.localStorage.setItem(this.props.name, value);
    this.checkUpdate();
  }

  unsetValue = () => {
    window.localStorage.removeItem(this.props.name);
    this.checkUpdate();
  }

  render() {
    return this.props.children({
      value: this.state.value,
      update: this.setValue,
      remove: this.unsetValue,
    });
  }
}

Value.defaultProps = {
  watch: true,
};

export default Value;
