/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useState, useEffect } from 'react';

/**
 * Exported functions are accessible through window.__pageproof_quark__.localStorage except
 * for the useLocalStorage hook which is exported as a named export (and unused by Angular).
 */

const callbacksByUniqueKey = {};
const callbacksByWildcardKey = {};

export function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(get(key) || defaultValue);

  useEffect(() => watch(key, setValue), [key]);

  const updateValue = (newValue) => {
    if (newValue == null || typeof newValue === 'undefined') {
      remove(key);
    } else {
      set(key, newValue);
    }
  };

  return [value, updateValue];
}

/**
 * Register a callback to be called when the value of the key changes.
 * @param {String} key - the key to watch
 * @param {Function} callback - the callback receives (newValue, oldValue, key)
 * @returns {Function} - unwatch function
 */
export function watch(rawKey, callback) {
  const key = isWildcardKey(rawKey) ? rawKey.split('*')[0] : rawKey;
  const callbacksByKey = isWildcardKey(rawKey) ? callbacksByWildcardKey : callbacksByUniqueKey;

  callbacksByKey[key] = callbacksByKey[key] || [];
  callbacksByKey[key].push(callback);
  return unwatch.bind(null, rawKey, callback);
}

/**
 * Unwatches a specific callback for a key, or all callbacks for a key if no callback is provided
 */
export function unwatch(rawKey, callback) {
  const key = isWildcardKey(rawKey) ? rawKey.split('*')[0] : rawKey;
  const callbacksByKey = isWildcardKey(rawKey) ? callbacksByWildcardKey : callbacksByUniqueKey;

  if (!callback) {
    delete callbacksByKey[key];
    return;
  }

  const callbacks = callbacksByKey[key];
  if (callbacks && callbacks.length) {
    const index = callbacks.indexOf(callback);
    if (index !== -1) {
      callbacks.splice(index, 1);
    }
  }
}

/**
 * Returns the value of the specified key or an array of key/value pairs if a wildcard key is provided.
 */
export function get(rawKey) {
  if (isWildcardKey(rawKey)) {
    const key = rawKey.split('*')[0];
    return Object.entries(window.localStorage)
      .filter(([storedKey]) => storedKey.startsWith(key))
      .map(([storedKey, value]) => {
        let parsedValue;
        try {
          parsedValue = JSON.parse(value);
        } catch (e) {
          parsedValue = value;
        }

        return [storedKey, parsedValue];
      });
  }

  const value = window.localStorage.getItem(rawKey);

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

export function set(key, value) {
  const oldValue = get(key);
  const newValue = typeof value === 'object' ? JSON.stringify(value) : value;
  window.localStorage.setItem(key, newValue);
  handleChange({ key, oldValue, newValue });
}

export function remove(rawKey) {
  if (isWildcardKey(rawKey)) {
    const key = rawKey.split('*')[0];
    Object.entries(window.localStorage)
      .filter(([storedKey]) => storedKey.startsWith(key))
      .forEach(([storedKey]) => {
        const oldValue = get(storedKey);
        window.localStorage.removeItem(storedKey);
        handleChange({ key: storedKey, oldValue, newValue: undefined });
      });
  } else {
    const oldValue = get(rawKey);
    window.localStorage.removeItem(rawKey);
    handleChange({ rawKey, oldValue, newValue: undefined });
  }
}

/**
 * The storage event is only triggered on other tabs, not the tab that made the change.
 * So we manually trigger the watchers on the current tab on `set` and `remove`.
 */
function handleChange({ newValue, oldValue, key }) {
  if (newValue === oldValue) {
    return;
  }

  const parsedNewValue = parseStorageValue(newValue);
  const parsedOldValue = parseStorageValue(oldValue);

  const callbacks = callbacksByUniqueKey[key];
  if (callbacks && callbacks.length) {
    callbacks.forEach(callback => callback(parsedNewValue, parsedOldValue, key));
  }

  handleWildCardCallbacks(key, parsedNewValue, parsedOldValue);
}

function handleWildCardCallbacks(key, newValue, oldValue) {
  const wildcardKeys = Object.keys(callbacksByWildcardKey);
  wildcardKeys.forEach((wildcardKey) => {
    if (key.startsWith(wildcardKey)) {
      const [oldValues, newValues] = getWildCardValues(wildcardKey, key, newValue, oldValue);
      const callbacks = callbacksByWildcardKey[wildcardKey];
      callbacks.forEach(callback => callback(newValues, oldValues, key));
    }
  });
}

function getWildCardValues(wildcardKey, key, newValue, oldValue) {
  const currentValues = get(wildcardKey + '*');
  const oldValues = [...currentValues];
  const newValues = [...currentValues];
  const index = currentValues.findIndex(([storedKey]) => storedKey === key);
  if (index !== -1) {
    oldValues[index] = [key, oldValue];
    newValues[index] = [key, newValue];
  }

  return [oldValues, newValues];
}

function isWildcardKey(key) {
  if (!key.includes('*')) {
    return false;
  }

  if (key.indexOf('*') !== key.lastIndexOf('*')) {
    throw new Error('Invalid key. Only one wildcard is allowed.');
  }

  if (key.indexOf('*') !== key.length - 1) {
    throw new Error('Invalid key. Wildcard must be at the end of the key.');
  }

  return true;
}

function parseStorageValue(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

window.addEventListener('storage', handleChange);
