/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import ProgressBar from '../ProgressBar';
import Ellipsis from '../Text/Ellipsis';
import { Translation } from '../Text';
import css from './BulkActionProgressMessage';

const BulkActionProgressMessage = ({ isInProgress, progress, heading, message, onClose }) => (
  <Fragment>
    <h1 className={css.BulkActionProgressMessage__heading}>
      {heading}
    </h1>
    {isInProgress && <ProgressBar progress={progress} />}
    <div className={css.BulkActionProgressMessage__message}>
      {message}
      {isInProgress && <Ellipsis />}
    </div>
    <Button
      label={<Translation value={isInProgress ? 'button.cancel' : 'button.close'} />}
      variant={isInProgress ? 'orange-text' : 'text'}
      onClick={onClose}
    />
  </Fragment>
);

if (process.env.NODE_ENV !== 'production') {
  BulkActionProgressMessage.propTypes = {
    isInProgress: PropTypes.bool,
    progress: PropTypes.number,
    heading: PropTypes.node,
    message: PropTypes.node,
    onClose: PropTypes.func,
  };
}

export default BulkActionProgressMessage;
