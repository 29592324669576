/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export const MIN_PASSWORD_LENGTH = 8;

export const getPasswordValidation = (password) => {
  const numbers = !!password.match(/\d/);
  const lowercase = !!password.match(/[a-z]/);
  const uppercase = !!password.match(/[A-Z]/);
  const specialChar = !!password.match(/[!@#$%^&*(),.?":{}|<>]/);
  const length = password.length >= MIN_PASSWORD_LENGTH;

  return {
    valid: numbers && lowercase && uppercase && specialChar && length,
    numbers,
    lowercase,
    uppercase,
    specialChar,
    length,
    password,
  };
};

export default getPasswordValidation;
