/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import { Subheading } from '../Page';
import Input from '../Input';
import CopyButton from '../Button/CopyButton';
import Tooltip from '../Tooltip/Tooltip';
import css from './SpfRecords';

const SpfRecords = ({ domain, records }) => (
  <div className={css.SpfRecords}>
    <Translation
      value="domain-admin.settings.spf-record.heading"
      params={{ domain }}
    />
    {records.map(({ version, current: currentRecord, new: newRecord }) => (
      <div key={version}>
        <Subheading title={<span className={css.SpfRecords__spfVersion}>{version}</span>} />
        <Translation value="domain-admin.settings.spf-record.current" />
        <Tooltip
          up
          center
          title={currentRecord}
          offset={10}
          disabled={!currentRecord}
        >
          <Input
            variant="unified"
            readOnly
            value={currentRecord}
          />
        </Tooltip>
        <br />
        <Translation value="domain-admin.settings.spf-record.new" />
        <div className={css.SpfRecords__newRecord}>
          <Tooltip
            up
            center
            title={newRecord}
            offset={10}
            disabled={!newRecord}
          >
            <Input
              variant="unified"
              readOnly
              value={newRecord}
            />
          </Tooltip>
          <CopyButton
            type="button"
            variant="text"
            text={newRecord}
          />
        </div>
      </div>
    ))}
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  SpfRecords.propTypes = {
    domain: PropTypes.string.isRequired,
    records: PropTypes.arrayOf(PropTypes.shape({
      version: PropTypes.string,
      current: PropTypes.string,
      new: PropTypes.string,
    })).isRequired,
  };
}

export default SpfRecords;
