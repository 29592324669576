/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {Children, cloneElement} from 'react';

let callback;

const input = document.createElement('input');
input.type = 'file';
Object.assign(input.style, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  opacity: 0,
  visibility: 'hidden',
  overflow: 'hidden',
});
input.onchange = (event) => {
  const value = input.multiple
    ? Array.prototype.slice.apply(event.target.files)
    : event.target.files[0];
  input.value = '';
  callback(value);
};
document.body.appendChild(input);

function promptFile(multiple, extensions, _callback) {
  callback = _callback;
  input.multiple = multiple;
  input.accept = (extensions || []).map(e => (e.indexOf('/') === -1 ? `.${e}` : e)).join(',');
  input.click();
}

function FileClick({
  multiple,
  extensions = [],
  onSelect,
  children,
  ...props
}) {
  return cloneElement(Children.only(children), {
    ...props,
    onClick: () => {
      if (children.props.onClick) {
        children.props.onClick();
      }
      promptFile(multiple, extensions, onSelect);
    },
  });
}

FileClick.defaultProps = {
  multiple: true,
};

export default FileClick;
