import React from 'react';
import { CreateComment } from './CreateComment';

export default function CommentsPaneCreateComment({ proofCtrl, proofHelper, pageNumber }) {
  return (
    <div style={{ padding: '0 20px 20px' }}>
      <CreateComment
        proofCtrl={proofCtrl}
        proofHelper={proofHelper}
        pageNumber={pageNumber}
      />
    </div>
  );
}
