/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { createStitchEmbed } from '../../util/createStitchEmbed';

export const AccountSwitcher = createStitchEmbed('AccountSwitcher', {
  iframeProps: {
    style: {
      width: 460,
      margin: '0px -50px',
      maxHeight: 'calc(100vh - 120px)',
    },
  },
  observeHeight: true,
});
