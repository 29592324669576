/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useState, useEffect } from 'react';
import { getHexFromColorName } from './getHexFromColorName';

const HexColor = ({ name, children }) => {
  const [color, setColor] = useState(loadColor);

  function loadColor() {
    return getHexFromColorName(name, (hex) => {
      setColor(hex);
    });
  }

  useEffect(() => {
    setColor(loadColor());
  }, [name]);

  return children(color);
};

export default HexColor;
