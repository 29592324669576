/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useRef, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import AppTile from './AppTile';
import css from './IntegrationApps.scss';
import { partition } from '../../util/object-array-utils';
import appsToInstall from './appsToInstall';
import getAppListingUrl from './getAppListingUrl';
import { Translation, TranslatedProps } from '../Text';
import useOnMessageEventListener from '../../hooks/useOnMessageEventListener';
import IntegrationSectionHeading from './IntegrationSectionHeading';
import useIntegrationsClientRef from '../../hooks/useIntegrationsClientRef';
import ModalServiceBridge from '../ModalServiceBridge';
import { Spinner } from '../Spinner';

const AppsSection = ({ title, message, apps, tileActions, getAnimation, openIntegrationOptions }) => {
  if (!apps.length) {
    return null;
  }
  return (
    <Fragment>
      <IntegrationSectionHeading
        title={title}
        message={message}
      />
      <div className={css.SectionBody}>
        {apps.map(app => (
          <TranslatedProps
            label={app.labelTranslationKey}
            key={app.integrationKey}
          >
            <AppTile
              label={app.labelTranslationKey ? '' : undefined}
              openIntegrationOptions={() => openIntegrationOptions(app)}
              actions={tileActions}
              animation={getAnimation && getAnimation(app.integrationKey)}
              {...app}
            />
          </TranslatedProps>
        ))
        }
      </div>
    </Fragment>
  );
};

export const IntegrationApps = ({ type }) => {
  const [integrations, _setIntegrations] = useState();
  const [integrationBeingConfigured, setIntegrationBeingConfigured] = useState();
  const integrationsRef = useRef(integrations);
  const setIntegrations = (data) => {
    integrationsRef.current = data;
    _setIntegrations(data);
  };

  const [lastModified, setLastModified] = useState();
  const [integrationsClientLoaded, integrationsClient] = useIntegrationsClientRef(type);

  const setIntegrationConfigurationInState = (integrationKey, configuration, toggleAnimation = false) => {
    if (toggleAnimation) {
      setLastModified(integrationKey);
    }

    setIntegrations(integrationsRef.current
      .map(integration => (integration.integrationKey !== integrationKey
        ? integration
        : {
          ...integration,
          configuration,
        })));
  };

  const setEnabledInState = (integrationKey, enabled) => {
    const integrationConfiguration = integrationsRef.current.find(integration => integration.integrationKey === integrationKey).configuration;
    setIntegrationConfigurationInState(integrationKey, { ...integrationConfiguration, enabled }, true);
  };

  const onMessageReceived = (event) => {
    const { integrationKey, enabled } = event.data;
    if (integrationKey) {
      const { enableType } = integrationsRef.current.find(integration => integration.integrationKey === integrationKey).configuration;

      if (enableType === 'oauth') {
        setEnabledInState(integrationKey, enabled);
      }
    }
  };
  useOnMessageEventListener(onMessageReceived);

  const openAppListing = (integrationKey) => {
    const appListingUrl = getAppListingUrl(integrationKey);

    if (appListingUrl) {
      window.open(appListingUrl);
    }
  };

  const disableIntegration = (integrationKey) => {
    const integration = integrations.find(i => i.integrationKey === integrationKey);

    if (integration.configuration.disableType === 'direct') {
      integrationsClient.current.disable(integrationKey)
        .then((success) => {
          if (success) {
            setEnabledInState(integrationKey, false);
          }
        });
      return;
    }

    openAppListing(integrationKey);
  };

  const openIntegrationOptions = (integration) => {
    setIntegrationBeingConfigured(integration.integrationKey);
  };

  const enableIntegration = (integrationKey) => {
    const integration = integrations && integrations.find(i => i.integrationKey === integrationKey);

    if (integration && integration.configuration.enableType === 'oauth') {
      const oauthWindow = window.open('/_private/integrations/spinner', 'IntegrationAuth', 'innerHeight=800,innerWidth=700');
      integrationsClient.current.getSetupUrl(integrationKey)
        .then(({ url }) => { oauthWindow.location = url; });
      return;
    }

    if (integration && integration.configuration.enableType === 'direct') {
      openIntegrationOptions(integration);
    }

    openAppListing(integrationKey);
  };

  const getConfigurations = () => {
    integrationsClient.current.configurations()
      .then((configurations) => {
        setIntegrations(configurations);
      });
  };

  useEffect(() => {
    if (integrationsClientLoaded && !integrations) {
      getConfigurations();
    }
  }, [integrationsClientLoaded]);

  const [installed, available] = integrations
    ? partition(integrations, integration => integration.configuration && integration.configuration.enabled)
    : [[], []];

  const tileActions = {
    disable: disableIntegration,
    enable: enableIntegration,
  };

  if (!integrations) {
    return (
      <div className={css.SpinnerHolder}>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppsSection
        title={<Translation value="integration.heading.connected" />}
        apps={installed}
        tileActions={tileActions}
        getAnimation={integrationKey => (integrationKey === lastModified ? 'emphasis' : null)}
        openIntegrationOptions={openIntegrationOptions}
      />
      <AppsSection
        title={<Translation value="integration.heading.available-to-connect" />}
        apps={available}
        tileActions={tileActions}
        getAnimation={integrationKey => (integrationKey === lastModified ? 'subtle' : null)}
      />
      <AppsSection
        title={<Translation value="integration.heading.available-to-install" />}
        apps={appsToInstall.filter(app => app.availableLevels.includes(type))}
        tileActions={tileActions}
      />
      <ModalServiceBridge
        componentName="IntegrationOptionsContainer"
        isModalVisible={!!integrationBeingConfigured}
        onClose={() => setIntegrationBeingConfigured()}
        level={type}
        integration={integrationBeingConfigured && integrations.find(x => x.integrationKey === integrationBeingConfigured)}
        onIntegrationConfigurationUpdate={setIntegrationConfigurationInState}
        onCancel={() => setIntegrationBeingConfigured()}
      />
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  IntegrationApps.propTypes = {
    type: PropTypes.string.isRequired,
  };
  AppsSection.propTypes = {
    title: PropTypes.element.isRequired,
    message: PropTypes.objectOf(PropTypes.any),
    apps: PropTypes.arrayOf(PropTypes.any).isRequired,
    tileActions: PropTypes.objectOf(PropTypes.any).isRequired,
    getAnimation: PropTypes.func,
    openIntegrationOptions: PropTypes.func,
  };
}

export default IntegrationApps;
