/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {withTimers} from '../../hoc/Timers';
import { I18nContext } from '../I18n/I18nProvider';

function factory(format) {
  class InnerComponent extends Component {
    state = {
      text: this.getText(this.props),
    };

    // eslint-disable-next-line camelcase, react/sort-comp
    UNSAFE_componentWillMount() {
      // TODO(jacob) This is a pretty naive approach, but for now, it works :P
      this.props.setInterval(() => this.check(this.props), 30 * 1000); // every 30 seconds
    }

    // eslint-disable-next-line camelcase, react/sort-comp
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      if ((nextProps.date && nextProps.date.getTime()) !== (this.props.date && this.props.date.getTime()) ||
        (this.context.locale !== nextContext.locale)) {
        this.check(nextProps, nextContext.locale);
      }
    }

    // eslint-disable-next-line class-methods-use-this
    getText(props, locale = this.context.locale) {
      const m = moment(props.date);
      m.locale(locale);
      return format(m, props);
    }

    check(props, locale) {
      const text = this.getText(props, locale);
      if (this.state.text !== text) {
        this.setState({text});
      }
    }

    render() {
      return (
        <time dateTime={this.props.date.toISOString()}>
          {this.state.text}
        </time>
      );
    }
  }

  InnerComponent.contextType = I18nContext;

  InnerComponent.propTypes = {
    date: PropTypes.instanceOf(Date),
  };

  InnerComponent.displayName = `Date(${format.displayName || format.name})`;

  return withTimers(InnerComponent);
}

export default factory;
