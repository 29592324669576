/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './GridGraduationLine.scss';

const GRADUATION_WIDTH = 1;
const SCALE_INTERVAL = 10;
const RULER_ACCURACY = 10000000;

const round = value => Math.round(value * RULER_ACCURACY) / RULER_ACCURACY;

const Graduation = ({
  position: {
    top = 0,
    left = 0,
    width,
    height,
  },
}) => (
  <div
    className={css.GridGraduationLine__graduation}
    style={{ top, left, width, height }}
  />
);

const GraduationLine = ({
  top,
  left,
  width,
  height,
  clipPath,
  zIndex,
  color,
  cursor = 'default',
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onBlur,
  onClick,
  children,
}) => (
  <div
    className={`
      ${css.GridGraduationLine}
    `}
    style={{ top, left, width, height, cursor, zIndex, clipPath, backgroundColor: color }}
    onMouseDown={onMouseDown}
    onMouseMove={onMouseMove}
    onMouseOut={onMouseOut}
    onBlur={onBlur}
    onClick={onClick}
  >
    {children}
  </div>
);

const GridGraduationLine = ({
  top,
  left,
  clipPath,
  width,
  height,
  zIndex,
  graduationInterval,
  color,
  lineHeight,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onBlur,
}) => {
  const Graduations = [];
  const getGraduationHeight = (count) => {
    if (count % SCALE_INTERVAL === 0) {
      return 0; // 0, 10, 20 ...
    } else if ((count - (SCALE_INTERVAL / 2)) % SCALE_INTERVAL === 0) {
      return 8; // 5, 15, 25, 35 ...
    } else {
      return 11; // 1, 2, 3, 4, 6, 7, 8, 9, 11, 12, 13 ...
    }
  };
  const maxGridLineLength = Math.max(width, height);
  for (let position = 0, count = 0; position < maxGridLineLength; position += graduationInterval, count += 1) {
    Graduations.push({
      width: width > height ? GRADUATION_WIDTH : lineHeight,
      height: width > height ? lineHeight : GRADUATION_WIDTH,
      left: width > height ? position : getGraduationHeight(count),
      top: width > height ? getGraduationHeight(count) : position,
    });
  }
  return (
    <GraduationLine
      top={top}
      left={left}
      clipPath={clipPath}
      width={width}
      height={height}
      zIndex={zIndex}
      color={color}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
      onBlur={onBlur}
    >
      {Graduations.map((position, index) => <Graduation key={index} position={position} />)}
    </GraduationLine>
  );
};

if (process.env.NODE_ENV !== 'production') {
  GraduationLine.propTypes = {
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    zIndex: PropTypes.number,
    cursor: PropTypes.string,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseOut: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.node,
  };

  GridGraduationLine.propTypes = {
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    zIndex: PropTypes.number,
    graduationInterval: PropTypes.number,
    lineHeight: PropTypes.number,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseOut: PropTypes.func,
    onBlur: PropTypes.func,
  };
}

export {
  GraduationLine,
  GridGraduationLine,
  round,
};
