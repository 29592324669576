/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import css from './NewPasswordConfirmationFieldGroup.scss';
import { Translation } from '../Text';
import FormControl from '../FormControl';
import Reveal from '../Reveal';
import Input from '../Input';
import { getPasswordValidation, MIN_PASSWORD_LENGTH } from '../PasswordIndicator/getPasswordValidation';
import {
  PasswordIndicatorProgressCircle,
  PasswordIndicatorTickIcon,
  PasswordIndicatorText,
} from '../PasswordIndicator';
import Cross from '../Icon/Cross';

const NewPasswordConfirmationFieldGroup = ({ loading, onChange, autoFocus, showRequirementsWhenBlurredAndEmpty }) => {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const { valid } = getPasswordValidation(password);

  const handleInputChange = (updatedPassword, updatedConfirm) => {
    const { valid: isUpdatedPasswordValid } = getPasswordValidation(updatedPassword);

    onChange(updatedPassword, isUpdatedPasswordValid && updatedPassword === updatedConfirm);
  };

  return (
    <Fragment>
      <FormControl
        compact
        label={
          <div className={css.NewPasswordConfirmationFieldGroup__formLabel}>
            <Translation value="handle-password.label.password-field" />
          </div>
        }
      >
        <div className={css.NewPasswordConfirmationFieldGroup__passwordIndicator}>
          {valid
            ? <PasswordIndicatorTickIcon />
            : <PasswordIndicatorProgressCircle password={password} />
          }
        </div>
        <Input
          variant="unified"
          type="password"
          name="password"
          id="password"
          value={password}
          // onInput is used because Chrome for iOS does not fire onChange when autofill is used
          onInput={(event) => {
            setPassword(event.target.value);
            handleInputChange(event.target.value, confirm);
          }}
          onFocus={() => setIsPasswordFocused(true)}
          onBlur={() => setIsPasswordFocused(false)}
          disabled={loading}
          required
          minLength={MIN_PASSWORD_LENGTH}
          autoFocus={autoFocus}
          autoComplete="new-password"
          className={css.NewPasswordConfirmationFieldGroup__passwordField}
        />
      </FormControl>
      <Reveal
        visible={
          !valid &&
          (showRequirementsWhenBlurredAndEmpty || isPasswordFocused || password.length >= 1)
        }
      >
        <PasswordIndicatorText
          password={password}
        />
      </Reveal>
      <Reveal
        visible={valid}
        align="bottom"
      >
        <FormControl
          compact
          label={
            <div className={css.NewPasswordConfirmationFieldGroup__formLabel}>
              <Translation value="handle-password.label.confirm-field" />
            </div>
          }
        >
          <div className={css.NewPasswordConfirmationFieldGroup__passwordIndicator}>
            {confirm &&
              (password === confirm
                ? <PasswordIndicatorTickIcon />
                : (
                  <Cross
                    active
                    readOnly
                    activeColor={(confirm.length >= password.length) && '#e51c23'}
                    size={21}
                  />
                )
              )}
          </div>
          <Input
            variant="unified"
            type="password"
            name="confirm-password"
            id="confirm-password"
            value={confirm}
            // onInput is used because Chrome for iOS does not fire onChange when autofill is used
            onInput={(event) => {
              setConfirm(event.target.value);
              handleInputChange(password, event.target.value);
            }}
            disabled={loading}
            required
            minLength={MIN_PASSWORD_LENGTH}
            autoComplete="new-password"
            className={css.NewPasswordConfirmationFieldGroup__passwordField}
          />
        </FormControl>
      </Reveal>
    </Fragment>
  );
};

NewPasswordConfirmationFieldGroup.defaultProps = {
  loading: false,
  autoFocus: false,
  showRequirementsWhenBlurredAndEmpty: false,
};

if (process.env.NODE_ENV !== 'production') {
  NewPasswordConfirmationFieldGroup.propTypes = {
    loading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    showRequirementsWhenBlurredAndEmpty: PropTypes.bool,
  };
}

export default NewPasswordConfirmationFieldGroup;
