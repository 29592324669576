/* eslint-disable consistent-return */

import React, { useEffect, useState } from 'react';
import { Bluffer } from '@pageproof/sdk';
import { sdk } from '../../util/sdk';
import { loadImage } from './utils';

function downloadAndDecrypt(url, envelope) {
  return sdk.rawRequest({ method: 'GET', url: new URL(url) })
    .then(response => (
      sdk.crypto()
        .decryptImage(envelope + '-' + response.body, sdk.keyPairs.getPrivateKeyPEMs())
    ))
    .then((imageBase64) => {
      const bluffer = new Bluffer();
      return bluffer.createBase64(imageBase64);
    });
}

export const EncryptedImage = ({ cacheKey, url, envelope, fallback = null, imageProps = {} }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const previousImageUrl = imageUrl;
    return loadImage(cacheKey, () => downloadAndDecrypt(url, envelope), setImageUrl, previousImageUrl);
  }, [cacheKey, url, envelope]);

  if (!imageUrl) {
    return fallback;
  }

  return (
    <img
      src={imageUrl}
      alt=""
      {...imageProps}
    />
  );
};
