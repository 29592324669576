/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import { sortObjectsByKey } from '../../util/sort';
import CommentsHeader from '../../components/CommentsHeader';

class CommentsHeaderContainer extends Component {
  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    this.proofCtrl = this.props.getProofCtrl();
    this.permissions = this.proofCtrl.permissions;
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const newProofCtrl = nextProps.getProofCtrl();
    const newPermissions = newProofCtrl.permissions;
    if (newPermissions !== this.permissions) {
      this.permissions = newPermissions;
    }
  }

  onFilter = (filter) => {
    this.proofCtrl.$setFilter(filter, (this.permissions ? this.permissions.proof : this.proofCtrl));
    this.proofCtrl.$$.$rootScope.$apply();
  }

  onMark = (key) => {
    this.proofCtrl.markAllComments(
      this.permissions.proofer.commentLevel.markAll[key].type,
      this.permissions.proofer.commentLevel.markAll[key].state,
    );
  }

  switchPage = (page) => {
    this.proofCtrl.switchPage(page);
  }

  getMarkOptions = () => {
    const { markAll } = this.permissions.proofer.commentLevel;
    const keys = Object.keys(markAll);
    return keys.filter(key => markAll[key].count).map(key => ({
      ...markAll[key],
      action: markAll[key].state ? 'mark' : 'unmark',
    }));
  }

  getDownloadComments = () => {
    const pageCommentsKey = this.proofCtrl.filter.name ? 'filteredComments' : 'comments';
    let { orderCommentsBy, isReversedCommentOrder } = this.proofCtrl.commentOrder;

    if (orderCommentsBy[0] === '-') {
      orderCommentsBy = orderCommentsBy.substring(1);
      isReversedCommentOrder = !isReversedCommentOrder;
    }

    return this.proofCtrl.proof.pages.reduce((downloadComments, page) => (
      [
        ...downloadComments,
        ...page[pageCommentsKey].sort(sortObjectsByKey(orderCommentsBy, isReversedCommentOrder))
      ]
    ), []);
  }

  render() {
    const { permissions } = this;
    const {
      filterName,
      commentCount,
      privateCount,
      unmarkedCount,
      todoCount,
      doneCount,
      agreeCount,
      notAgreeCount,
      attachmentCount,
      repliesCount,
      privateRepliesCount,
      commentedPages,
      mentionedUsers,
      commentByUsers,
      filteredMentionId,
      commentLabelCount,
      deviceCategoryCounts,
      importFileNameCounts,
      webPageCounts,
      filteredLabelId,
      filteredDeviceCategory,
      filteredImportFileName,
      filteredWebPage,
      filteredPage,
      filteredUserId,
      filteredSearchText,
      isCompareMode,
      isVideoOrAudio,
      proofFileCategory,
      onChangeCommentOrder,
      commentOrder,
    } = this.props;

    const mark = !!(
      permissions &&
      permissions.proof.isLatestVersion &&
      permissions.proofer.commentLevel.canMarkAll
    );
    return (
      <CommentsHeader
        filter={filterName || null}
        marks={mark && this.getMarkOptions()}
        comments={commentCount}
        privates={privateCount}
        unmarked={unmarkedCount}
        todos={todoCount}
        dones={doneCount}
        mentionedUsers={mentionedUsers}
        commentByUsers={commentByUsers}
        agrees={agreeCount}
        notAgrees={notAgreeCount}
        attachments={attachmentCount}
        repliesCount={repliesCount}
        privateRepliesCount={privateRepliesCount}
        commentLabels={commentLabelCount}
        deviceCategoryCounts={deviceCategoryCounts}
        importFileNameCounts={importFileNameCounts}
        webPageCounts={webPageCounts}
        commentedPages={commentedPages}
        onFilter={this.onFilter}
        onMark={this.onMark}
        onClearFilter={() => this.onFilter(null)}
        filteredMentionId={filteredMentionId}
        filteredLabelId={filteredLabelId}
        filteredDeviceCategory={filteredDeviceCategory}
        filteredImportFileName={filteredImportFileName}
        filteredWebPage={filteredWebPage}
        filteredPage={filteredPage}
        filteredUserId={filteredUserId}
        filteredSearchText={filteredSearchText}
        switchPage={this.switchPage}
        isCompareMode={isCompareMode}
        isVideoOrAudio={isVideoOrAudio}
        proofFileCategory={proofFileCategory}
        onChangeCommentOrder={onChangeCommentOrder}
        commentOrder={commentOrder}
        getDownloadComments={this.getDownloadComments}
        proofTitle={this.proofCtrl.proof.title}
        framesPerSecond={this.proofCtrl.proof.framesPerSecond}
      />
    );
  }
}

export default CommentsHeaderContainer;
