/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import styles from './checkbox.scss';

function Checkbox({
  selected,
  onChange,
  isDisabled,
}) {
  return (
    <button
      tabIndex="0"
      className={classname(styles.Checkbox, {
        [styles['Checkbox--on']]: selected,
        [styles['Checkbox--disabled']]: isDisabled,
      })}
      onClick={(event) => {
        event.preventDefault();
        if (onChange) {
          onChange(!selected);
        }
      }}
      disabled={isDisabled}
      aria-label="Checkbox"
    >
      {!!selected &&
        <InlineSVG
          className={styles.Checkbox__icon}
          src="img/interface/green-tick.svg"
        />
      }
    </button>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Checkbox.propTypes = {
    selected: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
  };
}

export default Checkbox;
