/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Translation} from '../Text';
import styles from './UserHeading.scss';

const UserHeading = ({value}) => (
  <div className={styles.userHeading}>
    <Translation value={value} />
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  UserHeading.propTypes = {
    value: PropTypes.string.isRequired,
  };
}

export default UserHeading;
