/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useState, useEffect } from 'react';
import { watchRect } from '../util/watchRect';

const useWatchRect = (elementRef) => {
  const [rect, setRect] = useState();
  useEffect(() => watchRect(() => elementRef.current, setRect), []);
  return rect;
};

export default useWatchRect;
