/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ProgressCircle from '../ProgressCircle';
import getPasswordValidation, { MIN_PASSWORD_LENGTH } from './getPasswordValidation';

export const PasswordIndicatorProgressCircle = ({ password }) => {
  const { numbers, lowercase, uppercase, length, valid } = getPasswordValidation(password);
  const score = [numbers, lowercase, uppercase, length].reduce((n, s) => (n + (s ? 1 : 0)), 0);
  const progress = Math.max(Math.min(password.length / MIN_PASSWORD_LENGTH, 1), 0) * (valid ? 100 : 85);
  const color = [
    '#e51c23',
    '#e51c23',
    '#e51c23',
    '#fe8125',
    '#138b3b',
  ][score];
  return (
    <ProgressCircle
      size={16}
      width={2}
      color={color}
      trackColor="transparent"
      value={progress}
    />
  );
};

if (process.env.NODE_ENV !== 'production') {
  PasswordIndicatorProgressCircle.propTypes = {
    password: PropTypes.string.isRequired,
  };
}

export default PasswordIndicatorProgressCircle;
