/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { InlineSVG } from '@jmshal/react-inline-svg';
import { Translation } from '../Text';
import css from './ProofDownloadButton.scss';
import InputOptions from '../ProofSetup/components/InputOptions';

function ProofDownloadButtonForMobileMenu() {
  return (
    <InputOptions.Option
      label={
        <div className={css.ProofDownloadButton__mobileButton}>
          <InlineSVG src="img/interface/download.svg" />
          <span className={css.ProofDownloadButton__mobileButton__label}>
            <Translation value="proof.screen.mobile.menu.option.download" />
          </span>
        </div>
      }
    />
  );
}

export default ProofDownloadButtonForMobileMenu;
