/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useSynchronizedUrl } from '../../hooks/useSynchronizedUrl';
import ProofTemplatesDashboard from '../../components/ProofTemplatesDashboard';

export default ({ initialRouteParams }) => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const [filter, setFilter] = useState(initialRouteParams.filter || 'active');
  const [proofTemplateId, setProofTemplateId] = useState(initialRouteParams.proofTemplateId || null);

  useSynchronizedUrl(['/team/proof-templates', proofTemplateId], { filter });

  return (
    <Fragment>
      {hasInitialized && (
        <style>{`
          header {
            background: transparent;
            backdrop-filter: none;
            -webkit-backdrop-filter: none;
            border-bottom: 0;
          }
        `}</style>
      )}
      <ProofTemplatesDashboard
        proofTemplateId={proofTemplateId}
        filterState={filter}
        onFilterStateChange={(filterState) => {
          unstable_batchedUpdates(() => {
            setFilter(filterState);
            setProofTemplateId(null);
          });
        }}
        onSelectProofTemplate={(proofTemplateId) => {
          setProofTemplateId(proofTemplateId);
        }}
        onNewProofTemplate={(proofTemplateId) => {
          unstable_batchedUpdates(() => {
            setFilter('active');
            setProofTemplateId(proofTemplateId);
          });
        }}
        onInit={() => {
          setHasInitialized(true);
        }}
      />
    </Fragment>
  );
};
