/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {cloneElement} from 'react';
import classname from 'classname';
import css from './DecisionSummary.scss';
import ThumbsUp from '../Icons/ThumbsUp';
import Popover from '../../Popover';
import ThumbsUpOrange from '../Icons/ThumbsUpOrange';
import ThumbsDown from '../Icons/ThumbsDown';
import QuestionMark from '../Icons/QuestionMark';
import HorizontalDelimiter from '../../HorizontalDelimiter';
import optionalChildrenFunc from '../../../util/optional-children-func';
import { Translation } from '../../Text';
import useDarkMode from '../../../hooks/useDarkMode';

function DecisionSummary({
  children,
  viewType,
  onClick,
  hasBorder,
}) {
  const delimiter = <div
    className={`
      ${css.DecisionSummary__delimiter}
      ${css[`DecisionSummary__delimiter--${viewType}`]}
    `}
  />;
  return (
    <div
      className={
        classname(css.DecisionSummary, {
          [css['DecisionSummary--hasBorder']]: hasBorder,
          [css.DecisionSummary__tile]: viewType,
          [css[`DecisionSummary__tile--${viewType}`]]: viewType,
        })
      }
      onClick={onClick}
    >
      <HorizontalDelimiter delimiter={delimiter}>
        {children}
      </HorizontalDelimiter>
    </div>
  );
}

function createIcon(icon) {
  return ({size, count, labelPosition, children}) => {
    const [isDarkMode] = useDarkMode();
    return (
      <Popover
        variant="light"
        content={popover => (
          <div className={css.DecisionSummary__popover}>
            {optionalChildrenFunc(children, popover)}
          </div>
        )}
        arrow
        down
        center
        offset={28}
        padding={false}
      >
        {cloneElement(icon(isDarkMode), {
          active: true,
          size,
          label: count,
          labelPosition,
        })}
      </Popover>
    );
  };
}

DecisionSummary.ThumbsUp = createIcon(() => (
  <ThumbsUp
    activeColor="#138b3b"
    activeHoverColor="#0d5e28"
  />
));

DecisionSummary.ThumbsUpOrange = createIcon(() => (
  <ThumbsUpOrange
    activeColor="#fe8125"
    activeHoverColor="#ef6601"
  />
));

DecisionSummary.ThumbsDown = createIcon(() => (
  <ThumbsDown
    activeColor="#e51c23"
    activeHoverColor="#b9151b"
  />
));

DecisionSummary.QuestionMark = createIcon(isDarkMode => (
  <QuestionMark
    activeColor={isDarkMode ? '#8c8c8c' : '#666'}
    activeHoverColor={isDarkMode ? '#acacac' : '#444'}
  />
));

DecisionSummary.Nudged = () => (
  <span className={css.DecisionSummary__nudged}>
    <Translation value="decision-summary.action.nudged" />
  </span>
);

export default DecisionSummary;
