/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Translation, Muted, withTranslations} from '../Text';

class ProfileInfo extends Component {
  state = {
    name: '',
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((!this.state.name && nextProps.user.Name) ||
      (this.props.user.UserId !== nextProps.user.UserId)) {
      this.setState({
        name: nextProps.user.Name,
      });
    }
  }

  updateValue = (event) => {
    this.setState({
      name: event.target.value,
    });
  }

  render() {
    const {
      onUpdateName,
      self,
      user,
      lastLoggedIn,
      isReadOnly,
      showLastLoggedInDate,
    } = this.props;
    return (
      <div className="DomainAdminProfile__info">
        {(user.Name || !isReadOnly) && (
          <div className="DomainAdminProfile__name">
            <input
              onChange={event => this.updateValue(event)}
              onBlur={event => onUpdateName(event)}
              value={this.state.name || ''}
              placeholder={self ? Translation.text('domain-admin.user-profile.your-name') : Translation.text('domain-admin.user-profile.users-name')}
              type="text"
              disabled={isReadOnly}
            />
          </div>
        )}
        <div className="DomainAdminProfile__email">
          <a href={`mailto:${user.Email}`}>
            {user.Email}
          </a>
        </div>
        {showLastLoggedInDate && (
          <Muted>
            {user.IsRegistered
              ? <Translation
                value={'domain-admin.user-profile.last-logged-in' + (self ? '.self' : '')}
                params={{lastLoggedIn}}
              />
              : <Translation value="domain-admin.user-profile.no-account" />}
          </Muted>
        )}
      </div>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  ProfileInfo.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    onUpdateName: PropTypes.func.isRequired,
    self: PropTypes.bool.isRequired,
    lastLoggedIn: PropTypes.string,
    isReadOnly: PropTypes.bool,
    showLastLoggedInDate: PropTypes.bool,
  };
}

export default withTranslations(ProfileInfo);
