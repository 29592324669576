/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useContext, useEffect } from 'react';
import { VirtualListContext } from '../context/VirtualListContext';
import { VirtualListItemContext } from '../context/VirtualListItemContext';

export function useKeepVirtualListItemMounted(condition, itemKey) {
  const { setItemKeepMounted } = useContext(VirtualListContext);
  const itemKeyFromContext = useContext(VirtualListItemContext);

  const key = itemKey || itemKeyFromContext;

  // We only return the cleanup function if the condition is true(thy).
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (key && condition) {
      setItemKeepMounted(key, true);
      return () => setItemKeepMounted(key, false);
    }
  }, [key, condition]);
}
