/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import css from './ProfileAddressBook.scss';
import Tooltip from '../Tooltip/Tooltip';
import { RevealingTextInput } from '../RevealingInput';
import { PopupMenu } from '../PopupMenu';
import { Spinner } from '../Spinner';
import AddContactPopup from './AddContactPopup';
import GreyCircleIconButton from '../GreyCircleIconButton';
import AddressBook from './AddressBook';

const ProfileAddressBook = ({ emails, onRemove, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredEmails = useMemo(() => {
    if (!searchTerm) {
      return emails;
    }

    const loweredSearchTerm = searchTerm.toLowerCase();

    return emails.filter(email => email.toLowerCase().includes(loweredSearchTerm));
  }, [emails, searchTerm]);

  return (
    <div className={css.ProfileAddressBook}>
      <div className={css.ProfileAddressBook__utilityWrapper}>
        <RevealingTextInput
          value={searchTerm}
          onChange={setSearchTerm}
        />
        <AddNewContact />
      </div>
      <div className={css.ProfileAddressBook__addressBookContainer}>
        {isLoading
          ? (
            <div className={css.ProfileAddressBook__addressBookContainer__spinner}>
              <Spinner />
            </div>
          )
          : <AddressBook
            emails={emails}
            filteredEmails={filteredEmails}
            removeEmail={onRemove}
          />
        }
      </div>
    </div>
  );
};

const AddNewContact = () => (
  <PopupMenu
    options={popover => (
      <AddContactPopup
        onConfirmAdd={popover.hide}
      />)}
    down
    variant="light"
  >
    {popover => (
      <div>
        <Tooltip
          up
          center
          title={<Translation value="profile.address-book.tooltip.add" />}
          maxWidth={150}
          disabled={popover.isVisible}
        >
          <GreyCircleIconButton
            iconSrc="/img/icons/plus-3.svg"
          />
        </Tooltip>
      </div>
    )}
  </PopupMenu>
);

if (process.env.NODE_ENV !== 'production') {
  ProfileAddressBook.propTypes = {
    emails: PropTypes.arrayOf(PropTypes.string),
    onRemove: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };
}

export default ProfileAddressBook;
