/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Enum } from '@pageproof/sdk';
import randomId from '../utils/randomId';
import Translation from '../../Text/Translation';
import { sdk } from '../../../util/sdk';
import { ModalHeading } from '../../ModalMessages/components/ModalHeading';
import ButtonGroup from '../../ButtonGroup';
import { Button } from '../../Button';
import css from './SkipAlreadyApprovedWorkflowUsers.scss';
import WrapConditionally from '../../WrapConditionally';
import Tooltip from '../../Tooltip';

export const SkipAlreadyApprovedWorkflowUsers = ({
  previousVersionWorkflowId,
  workflow,
  onChangeWorkflow,
  onClose,
}) => {
  const [previousVersionWorkflow, setPreviousVersionWorkflow] = useState(null);

  useEffect(() => {
    setPreviousVersionWorkflow(null);

    if (previousVersionWorkflowId) {
      sdk.workflows.load(previousVersionWorkflowId).then(setPreviousVersionWorkflow);
    }
  }, [previousVersionWorkflowId]);

  return (
    <div>
      <ModalHeading
        heading={<Translation value="proof.setup.workflow.chooser.skip-previously-approved.title" />}
        subheading={<Translation value="proof.setup.workflow.chooser.skip-previously-approved.message" />}
      />
      <div className={css.SkipAlreadyApprovedWorkflowUsers__footer}>
        <ButtonGroup>
          <Button
            variant="text"
            label={<Translation value="button.cancel" />}
            onClick={onClose}
          />
          <WrapConditionally
            condition={!previousVersionWorkflow}
            wrapper={(
              <Tooltip
                title={<Translation value="proof.setup.workflow.chooser.skip-previously-approved.loading" />}
                up
                center
              />
            )}
          >
            <Button
              variant="primary"
              label={<Translation value="proof.setup.workflow.chooser.skip-previously-approved.skip" />}
              disabled={!previousVersionWorkflow}
              onClick={() => {
                const emailsToSkip = new Set();
                const requestedChangesUsers = new Set();

                previousVersionWorkflow.steps.forEach((step) => {
                  step.users.forEach((user) => {
                    if (user.decisionId === Enum.Decision.SEND_CHANGES) {
                      emailsToSkip.delete(user.email);
                      requestedChangesUsers.add(user.email);
                      return;
                    }

                    if (!requestedChangesUsers.has(user.email) && [Enum.Decision.APPROVED, Enum.Decision.APPROVED_WITH_CHANGES].includes(user.decisionId)) {
                      emailsToSkip.add(user.email);
                    }
                  });
                });

                const usersToSkip = [];

                workflow.steps.forEach((step) => {
                  step.users = step.users.filter((user) => {
                    if (emailsToSkip.has(user.email)) {
                      if (!usersToSkip.some(userToSkip => userToSkip.email === user.email)) {
                        usersToSkip.push({
                          email: user.email,
                          role: 'reviewer',
                          permissions: {
                            inviter: user.permissions.inviter,
                          },
                        });
                      }

                      return false;
                    }

                    return true;
                  });
                });

                if (!workflow.steps.some(step => step.start)) {
                  workflow.steps[0].start = true;
                }

                const newStep = {
                  _id: randomId(),
                  name: 'Approved the previous version',
                  users: usersToSkip,
                  mandatoryDecisionThreshold: null,
                };

                workflow._lastCreatedStepId = newStep._id;
                workflow.steps.splice(0, 0, newStep);
                workflow._isEdited = true;

                onChangeWorkflow(workflow);
                window.__pageproof_bridge__.SegmentIo.track(67, { 'previous workflow id': previousVersionWorkflowId });

                onClose();
              }}
            />
          </WrapConditionally>
        </ButtonGroup>
      </div>
    </div>
  );
};
