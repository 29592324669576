/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import UploadBrief from '../../components/UploadProof/UploadBrief';

const UploadBriefContainer = props => <UploadBrief {...props} />;

export default UploadBriefContainer;
