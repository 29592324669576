/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { forwardRef } from 'react';
import Popover from '../../../Popover';
import Tooltip from '../../../Tooltip/Tooltip';
import HeaderBanner from '../HeaderBanner';
import InlineSpinner from '../../../Spinner/InlineSpinner';
import css from './ProofTemplates.scss';

export const ProofTemplatesPopover = forwardRef(({ isLoading, children }, ref) => (
  <Popover
    down
    center
    arrow
    padding={false}
    content={
      <div className={css.ProofTemplates__popover}>
        {children}
      </div>
    }
    variant="light"
    offset={15}
    innerRef={ref}
  >
    {popover => (
      <Tooltip
        left
        title="Proof templates"
        maxWidth={150}
        disabled={popover.isVisible}
        offset={15}
      >
        {isLoading
          ? (
            <div style={{ padding: 10 }}>
              <InlineSpinner size={38} />
            </div>
          )
          : (
            <HeaderBanner.IconButton
              size="large"
              variant="transparent"
              src="/img/icons/proof-templates.svg"
              onClick={popover.toggle}
              ariaLabel="Proof templates"
            />
          )
        }
      </Tooltip>
    )}
  </Popover>
));
