/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LinkCheckResults from '../../components/ProofPageInfo/LinkCheckResults';
import { sdk } from '../../util/sdk';

class LinkCheckResultsContainer extends Component {
  state = {
    rechecked: false,
  };

  onRecheck() {
    this.setState({ rechecked: true });
    return sdk.proofs.linkCheck.recheck(this.props.proofId);
  }


  render() {
    return (
      <LinkCheckResults
        data={this.props.data}
        hasRechecked={this.state.rechecked}
        onRecheck={() => this.onRecheck()}
      />
    );
  }
}


if (process.env.NODE_ENV !== 'production') {
  LinkCheckResultsContainer.propTypes = {
    proofId: PropTypes.string.isRequired,
    data: PropTypes.shape({
      lastRan: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      summary: PropTypes.string.isRequired,
      results: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string.isRequired,
        rating: PropTypes.string.isRequired,
        summary: PropTypes.shape({
          total: PropTypes.number.isRequired,
          ok: PropTypes.number.isRequired,
          error: PropTypes.number.isRequired,
          warning: PropTypes.number.isRequired,
        }).isRequired,
        details: PropTypes.arrayOf(PropTypes.shape({
          url: PropTypes.string.isRequired,
          status: PropTypes.number.isRequired,
          secure: PropTypes.bool.isRequired,
          rating: PropTypes.string.isRequired,
          detail: PropTypes.string.isRequired,
        })).isRequired,
      })).isRequired,
    }).isRequired,
  };
}

export default LinkCheckResultsContainer;
