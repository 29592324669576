/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import * as PageProof from '@pageproof/sdk';

function id() {
  return window.randomString(6, 'aA#');
}

const index = (() => {
  let i = 0;
  return () => (i++); // eslint-disable-line
})();

const STYLES = (() => (
  Object.keys({
    italic: true,
    bold: true,
    underline: true,
    strikethrough: true,
    superscript: true,
    subscript: true,
  }).reduce((obj, key) => ({
    ...obj,
    [key]: key.toUpperCase(),
    [key.toUpperCase()]: key,
  }), {})
))();

// const ENTITIES = (() => {
//   const a = {
//     mention: 'mention',
//     link: 'LINK',
//   };
//   const b = Object.keys(a).reduce((obj, key) => ({
//     ...obj,
//     [a[key]]: key,
//   }), {});
//   return {
//     ...a,
//     ...b,
//   };
// })();

export function hasValue(str) {
  if (!str) return false;
  const { tokens } = window.generalfunctions_parseCommentText(str);
  const text = PageProof.util.comments.text(tokens);
  return !!text.trim().length;
}

export function toTokens(raw) {
  return {
    version: 2,
    blocks: raw.blocks.map(block => ({
      type: !block.type || block.type === 'unstyled' ? 'text' : block.type,
      value: block.text,
      styles: block.inlineStyleRanges.map((styleRange) => {
        const name = STYLES[styleRange.style];
        if (name) {
          return {
            type: name,
            index: styleRange.offset,
            length: styleRange.length,
          };
        }
        return null;
      }).filter(Boolean),
      entities: block.entityRanges.map((blockRange) => {
        const entity = raw.entityMap[blockRange.key];
        let type;
        let metadata;
        switch (entity.type) {
        case 'mention': {
          type = 'mention';
          metadata = {
            id: typeof entity.data.mention.get === 'function'
              ? entity.data.mention.get('link') // immutable js
              : entity.data.mention.link, // plain js object
          };
          break;
        }
        default: {
          return null;
        }
        }
        return {
          type,
          index: blockRange.offset,
          length: blockRange.length,
          metadata,
        };
      }).filter(Boolean),
    })),
  };
}

export function toRaw(tokens) {
  const entityMap = {};
  const blocks = [];

  tokens.blocks.forEach((block) => {
    const inlineStyleRanges = [];
    const entityRanges = [];
    block.entities.forEach((entity) => {
      const key = index();
      const value = block.value.substr(entity.index, entity.length);
      switch (entity.type) {
      case 'mention': {
        entityMap[key] = {
          type: 'mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              link: entity.metadata.id,
              name: value,
            },
          },
        };
        break;
      }
      case 'LINK': {
        entityMap[key] = {
          type: 'link',
          mutability: 'MUTABLE',
          data: {
            url: entity.metadata.url,
          },
        };
        break;
      }
      default: {
        return; // skip adding the entity range for an unknown entity type
      }
      }
      entityRanges.push({
        key,
        offset: entity.index,
        length: entity.length,
      });
    });
    block.styles.forEach((style) => {
      const name = STYLES[style.type];
      if (name) {
        inlineStyleRanges.push({
          style: name,
          offset: style.index,
          length: style.length,
        });
      }
    });
    blocks.push({
      key: id(),
      type: block.type === 'text' ? 'unstyled' : block.type,
      text: block.value,
      inlineStyleRanges,
      entityRanges,
      data: {},
      depth: 0,
    });
  });

  return {
    blocks,
    entityMap,
  };
}
