/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import Translation from '../Text/Translation';
import { getProofLink } from '../../util/proof-utils';
import { openProof } from '../../containers/Dashboard/ActionsInternal';
import ProofShortcut from '../ProofShortcut';
import css from './RecentProofsStyles.scss';
import { useLocalStorage } from '../../util/localStorage';

const bridge = window.__pageproof_bridge__;

function RecentProofs({ userId }) {
  const storageKey = `pageproof.app.recentProofs.${userId}.`;
  const [proofs] = useLocalStorage(`${storageKey}*`, []);
  const proofData = useMemo(() => proofs.map(([, value]) => value).filter(Boolean), [proofs]);

  return (
    <Fragment>
      {proofData.length > 0 &&
        <div className={css.RecentProofs}>
          <div className={css.RecentProofs__heading}>
            <Translation value="dashboard.recent-proofs.recent" />
          </div>
          <div className={css.RecentProofs__proofs}>
            {proofData.sort((a, b) => bridge.moment(b.timeAdded).diff(bridge.moment(a.timeAdded))).map(proof => (
              <ProofShortcut
                key={proof.id}
                fileId={proof.fileId}
                title={proof.title}
                href={getProofLink(proof)}
                onClick={(event) => {
                  if (window.__pageproof_quark__.timing.shouldReloadOnNavigation()) {
                    return;
                  }
                  event.preventDefault();
                  openProof(proof);
                }}
              />
            ))}
          </div>
          <div className={css.RecentProofs__separator} />
        </div>
      }
    </Fragment>
  );
}

if (process.env.NODE_ENV !== 'production') {
  RecentProofs.propTypes = {
    userId: PropTypes.string.isRequired,
  };
}

export default RecentProofs;
