/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable consistent-return */

// eslint-disable-next-line
import { unstable_batchedUpdates } from 'react-dom';

const counts = {}; // { [cacheKey: string]: number }
const memoryCache = {}; // { [cacheKey: string]: Promise<{ blob: Blob, url: string }> }
const cleanupTimeouts = {}; // { [cacheKey: string]: number }
const preloadCache = {}; // { [url: string]: Promise<void> }

const scheduleBatchedUpdates = (() => {
  const callbacks = [];
  let scheduled = false;

  return (callback) => {
    callbacks.push(callback);
    if (!scheduled) {
      scheduled = true;
      Promise.resolve().then(() => {
        const copy = callbacks.slice();
        callbacks.length = 0;
        scheduled = false;
        unstable_batchedUpdates(() => {
          copy.forEach(cb => cb());
        });
      });
    }
  };
})();

function createRecord(blob) {
  return blob && {
    blob,
    url: URL.createObjectURL(blob),
  };
}

function fromCache(cacheKey) {
  return window.__pageproof_bridge__.temporaryStorageService.get({ encryptedImage: cacheKey })
    .then(createRecord);
}

function cacheAs(cacheKey, blobPromise) {
  return blobPromise.then((blob) => {
    const imageBlob = new window.Blob([blob], { type: 'image/jpg' });
    window.__pageproof_bridge__.temporaryStorageService.set({ encryptedImage: cacheKey }, imageBlob);
    return createRecord(imageBlob);
  });
}

function scheduleCleanupMemoryCache(cacheKey) {
  clearTimeout(cleanupTimeouts[cacheKey]);
  cleanupTimeouts[cacheKey] = setTimeout(() => {
    if (!counts[cacheKey]) {
      const cached = memoryCache[cacheKey];
      URL.revokeObjectURL(cached.url);
      cached.url = null;
      cached.blob = null;
      delete memoryCache[cacheKey];
    }
  }, 10000);
}

export function loadImage(cacheKey, handler, setImageUrl, previousImageUrl) {
  let currentImageUrl = null;
  let aborted = false;

  // Increment the counter to ensure that any already cached value is not destroyed
  counts[cacheKey] = counts[cacheKey] || 0;
  counts[cacheKey] += 1;

  if (!memoryCache[cacheKey] && previousImageUrl) {
    setImageUrl(null);
  }

  const imagePromise = Promise.resolve(
    memoryCache[cacheKey] ||
    (
      memoryCache[cacheKey] = fromCache(cacheKey)
        .then((cachedBlob) => {
          if (!cachedBlob) {
            return cacheAs(cacheKey, handler());
          }
          return cachedBlob;
        })
    )
  );

  imagePromise
    .then((record) => {
      if (!aborted && record) {
        currentImageUrl = record.url;

        if (!preloadCache[currentImageUrl]) {
          preloadCache[currentImageUrl] = window.generalfunctions_preloadImagePromise(currentImageUrl).then(() => {
            preloadCache[currentImageUrl] = true;
          });
        }

        if (preloadCache[currentImageUrl] === true) {
          scheduleBatchedUpdates(() => {
            if (!aborted) {
              setImageUrl(currentImageUrl);
            }
          });
        } else {
          preloadCache[currentImageUrl].then(() => {
            scheduleBatchedUpdates(() => {
              if (!aborted) {
                setImageUrl(currentImageUrl);
              }
            });
          });
        }
      }
    });

  return () => {
    aborted = true;

    // Decrement the counter, and if it reaches 0, delete the cached value to free up memory
    counts[cacheKey] -= 1;
    if (counts[cacheKey] === 0) {
      delete counts[cacheKey];
      scheduleCleanupMemoryCache(cacheKey);
    }
  };
}
