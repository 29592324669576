/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {
  cloneElement,
  Children,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import Popover from '../Popover';
import OptionList from './OptionList';

function optionalChildrenFunction(children, ...args) {
  if (typeof children === 'function') {
    return children(...args);
  }
  return children;
}

function PopupMenu({
  up,
  down,
  hover,
  popoverProps,
  maxHeight,
  variant,
  options,
  children,
  arrow,
  maxWidth,
  onShow,
  onHide,
  zIndex,
  disabled,
  offset,
  mountElement,
}) {
  // eslint-disable-next-line
  const direction = up
    ? 'up'
    : down
      ? 'down'
      : 'up';
  return (
    <Popover
      content={popover => (
        <Fragment>
          <OptionList
            style={maxHeight && { maxHeight, overflowY: 'auto' }}
            variant={variant}
            options={optionalChildrenFunction(options, popover)}
            onClick={(shouldHide) => {
              // If the option returned false then skip hiding the popover
              if (typeof shouldHide === 'undefined' || shouldHide !== false) {
                popover.hide();
              }
            }}
          />
        </Fragment>
      )}
      up={direction === 'up'}
      down={direction === 'down'}
      variant={variant}
      center
      offset={offset}
      delay={hover ? 200 : 0}
      arrow={arrow}
      maxWidth={maxWidth}
      onShow={onShow}
      onHide={onHide}
      zIndex={zIndex}
      disabled={disabled}
      mountElement={mountElement}
      {...popoverProps}
    >
      {(popover) => {
        const child = Children.only(optionalChildrenFunction(children, popover));
        return (
          cloneElement(child, {
            ...(hover
              ? {
                onMouseEnter: popover.show,
                onMouseLeave: () => {
                  if (!popover.isVisible) {
                    popover.hide();
                  }
                },
              }
              : {
                style: {
                  ...(child.props.style || {}),
                  ...(disabled ? {} : { cursor: 'pointer' }),
                },
              }
            ),
          })
        );
      }}
    </Popover>
  );
}

PopupMenu.defaultProps = {
  offset: 12,
  hover: false,
  arrow: true,
};

const dynamicNode = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.node,
]);

if (process.env.NODE_ENV !== 'production') {
  PopupMenu.propTypes = {
    options: dynamicNode.isRequired,
    children: dynamicNode.isRequired,
    hover: PropTypes.bool,
    up: PropTypes.bool,
    down: PropTypes.bool,
    popoverProps: PropTypes.shape(Popover.propTypes),
    arrow: PropTypes.bool,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    offset: PropTypes.number,
  };
}

export default PopupMenu;
