/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import css from './getMetadata.scss';
import Translation from '../../Text/Translation';
import ScrollTop from '../../Metadata/Icon/ScrollTop';
import ScrollLeft from '../../Metadata/Icon/ScrollLeft';
import Page from '../../Metadata/Page';
import Hover from '../../Hover';
import WrapConditionally from '../../WrapConditionally';
import { isProxiedWebUrl } from '../../../util/webProofUrls';
import NextAvatar from '../../../containers/NextAvatar';
import Date from '../Date';

const getMetadata = metadata => (
  [
    metadata.importSource && metadata.importSource.importer && {
      name: <Translation value="comment.metadata.import.importer" />,
      value: (
        <Fragment>
          <NextAvatar id={metadata.importSource.importer.id} size={20} />
          <span style={{ marginLeft: 8 }}>
            {metadata.importSource.importer.email}
          </span>
        </Fragment>
      ),
    },
    metadata.importSource && metadata.importSource.fileName && {
      name: <Translation value="comment.metadata.import.source-file" />,
      value: metadata.importSource.fileName,
    },
    metadata.importSource && metadata.importSource.importDate && {
      name: <Translation value="comment.metadata.import.imported-date" />,
      value: <Date date={metadata.importSource.importDate} />,
    },
    metadata.importSource && metadata.importSource.createdDate && {
      name: <Translation value="comment.metadata.import.created-date" />,
      value: <Date date={metadata.importSource.createdDate} />,
    },
    metadata.importSource && metadata.importSource.lastModifiedDate && {
      name: <Translation value="comment.metadata.import.last-modified-date" />,
      value: <Date date={metadata.importSource.lastModifiedDate} />,
    },
    metadata.device && {
      name: <Translation value="comment.metadata.device.header" />,
      value: (
        <Fragment>
          {metadata.device.id && <Translation value={`comment.metadata.device.${metadata.device.id}`} />}
          {metadata.device.id && ' ('}
          {metadata.device.width}
          {' px x '}
          {metadata.device.height}
          {' px'}
          {metadata.device.id && ')'}
        </Fragment>
      ),
    },
    (metadata.throttle && metadata.throttle.id) && {
      name: <Translation value="comment.metadata.network.header" />,
      value: <Translation value={`comment.metadata.network.${metadata.throttle.id}`} />,
    },
    metadata.browser && {
      name: <Translation value="comment.metadata.browser.header" />,
      value: (
        <span title={metadata.browser.userAgent}>
          <Translation value={`comment.metadata.browser.${metadata.browser.name}`} />
          {' '}
          {metadata.browser.version}
          {' '}
          (
          <Translation value={`comment.metadata.os.${metadata.browser.os}`} />
          )
        </span>
      ),
    },
    (metadata.page && (metadata.page.top || metadata.page.left)) && {
      name: <Translation value="comment.metadata.scroll.header" />,
      value: (
        <Fragment>
          {(metadata.page.top || null) && (
            <span>
              <ScrollTop />
              {Math.round(metadata.page.top)}
              px
            </span>
          )}
          {(metadata.page.left || null) && (
            <span className={css.getMetadata__scrollLeft}>
              <ScrollLeft />
              {Math.round(metadata.page.left)}
              px
            </span>
          )}
        </Fragment>
      ),
    },
    (metadata.page && metadata.page.path) && {
      name: <Translation value="comment.metadata.page.path" />,
      value: (
        <Hover>
          {({ Target, isActive }) => (
            <Target>
              <div>
                <WrapConditionally
                  condition={!isProxiedWebUrl(metadata.page.path)}
                  wrapper={
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={metadata.page.path}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  }
                >
                  <Page
                    url={metadata.page.path}
                    hovered={isActive}
                  />
                </WrapConditionally>
              </div>
            </Target>
          )}
        </Hover>
      ),
    },
  ].filter(Boolean)
);

export default getMetadata;
