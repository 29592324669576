/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {Component, Children} from 'react';
import {findDOMNode} from 'react-dom';
import {withEventListeners} from '../../hoc/EventListeners';

function createTarget(container) {
  class Target extends Component {
    componentDidMount() {
      this.registerEventListeners();
    }

    // componentDidUpdate() {
    //   this.registerEventListeners();
    // }

    setHover = isActive => (
      () => {
        container.setState({isActive});
      }
    )

    registerEventListeners() {
      const target = findDOMNode(this);
      if (target === this.target) {
        return;
      }
      this.unregisterEventListeners();
      if (target) {
        this._onMouseEnter = this.props.addEventListener(target, 'mouseenter', this.setHover(true));
        this._onMouseLeave = this.props.addEventListener(target, 'mouseleave', this.setHover(false));
      }
      this.target = target;
    }

    unregisterEventListeners() {
      if (this._onMouseEnter) {
        this._onMouseEnter();
      }
      if (this._onMouseLeave) {
        this._onMouseLeave();
      }
    }

    render() {
      return Children.only(this.props.children);
    }
  }

  return withEventListeners(Target);
}

export {
  createTarget,
};
