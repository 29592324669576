/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import Dropdown from '../../../Dropdown';
import DropdownOption from '../../../Dropdown/Option';
import DropdownOptionLabel from '../../../Dropdown/OptionLabel';
import Translation from '../../../Text/Translation';

export const ProofTemplatesDropdown = ({
  selectedProofTemplateName,
  isLoading,
  currentProofTemplateId,
  children,
  modifiedFields,
  sharedProofsModifiedFields,
  isShared,
}) => {
  const isModified = !!modifiedFields.length || !!sharedProofsModifiedFields.length;

  const modifiedInfo = (
    <div>
      <div style={{ height: 10 }} />
      These fields are different to what is set in the proof template:
      {isModified && (
        <Fragment>
          <div style={{ height: 10 }} />
          {(modifiedFields.length > 0 && isShared) && (
            <Fragment>
              <div style={{ height: 10 }} />
              Differences in the shared setup:
            </Fragment>
          )}
          {modifiedFields.map(field => (
            <li key={field}>{field}</li>
          ))}
          {sharedProofsModifiedFields.length > 0 && (
            <Fragment>
              <div style={{ height: 10 }} />
              Differences in one of the proofs:
            </Fragment>
          )}
          {sharedProofsModifiedFields.map(({ proofNumber, proofName, fields }) => (
            <Fragment key={proofNumber}>
              <div style={{ height: 10 }} />
              {`Proof ${proofNumber}: ${proofName}`}
              {fields.map(field => (
                <li key={field}>{field}</li>
              ))}
            </Fragment>
          ))}
        </Fragment>
      )}
    </div>
  );

  const isModifiedLabelText = (isModified && !isLoading) ? <Translation value="proof.setup.workflow.chooser.modified" /> : null;

  return (
    <div style={{ fontSize: 0, maxWidth: 250 }}>
      <Dropdown
        selected={isLoading
          ? undefined
          : currentProofTemplateId
        }
        placeholder={
          <DropdownOption
            label={
              <DropdownOptionLabel
                title={isLoading
                  ? <Translation value="loading.message" />
                  : selectedProofTemplateName}

                aside={isModifiedLabelText}
                asideTooltip={modifiedInfo}
              />

            }
          />
        }
      >
        {children}
      </Dropdown>
    </div>

  );
};
