/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';

const WrapConditionally = ({
  condition,
  wrapper,
  children,
}) => (condition ? React.cloneElement(wrapper, { children }) : children);

if (process.env.NODE_ENV !== 'production') {
  WrapConditionally.propTypes = {
    condition: PropTypes.bool.isRequired,
    wrapper: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
  };
}

export default WrapConditionally;
