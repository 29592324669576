/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import CustomTool from '../CustomTool';
import Popover from '../Popover';
import { Translation } from '../Text';
import { Option, OptionList, Separator } from '../PopupMenu';
import css from './CompareSwitchSidesTool.scss';

class CompareSwitchSidesTool extends Component {
  state = {
    showPopover: false,
  };

  getToolPopoverContent(popover) {
    const { onSwitch, left, right } = this.props;

    return (
      <div>
        <OptionList>
          <Option
            label={<Translation
              value="proof.tools.switch-position.proof"
              params={{ proof: 'A' }}
            />}
            disabled
          />
          <Option
            label={<Translation value={left.title} />}
            readOnly
          />
          <Option
            label={<Translation
              value="proof.tools.switch-position.version"
              params={{ version: left.version }}
            />}
            readOnly
          />
          <Separator />
          <Option
            label={<Translation
              value="proof.tools.switch-position.proof"
              params={{ proof: 'B' }}
            />}
            disabled
          />
          <Option
            label={<Translation value={right.title} />}
            readOnly
          />
          <Option
            label={<Translation
              value="proof.tools.switch-position.version"
              params={{ version: right.version }}
            />}
            readOnly
          />
          <Separator />
          <Option
            label={<Translation value="proof.tools.switch-position" />}
            onClick={() => {
              onSwitch();
              popover.hide();
              this.toggleShowPopover(false);
            }}
          />
        </OptionList>
      </div>
    );
  }

  toggleShowPopover(bool) {
    this.setState({
      showPopover: bool,
    });
  }

  render() {
    const { showPopover } = this.state;

    return (
      <Popover
        right
        middle
        arrow
        maxWidth={200}
        offset={10}
        visible={showPopover}
        onBeforeHide={() => this.toggleShowPopover(false)}
        content={popover => this.getToolPopoverContent(popover)}
      >
        <span className={css.CompareSwitchSidesTool}>
          <CustomTool
            native
            direction="up"
            options={[{
              id: 'switch-tool',
              icon: 'img/icons/switch-position.svg',
              tooltip: !showPopover && 'proof.tools.switch-position',
            }]}
            constraints={{ width: 25, height: 25 }}
            size={60}
            onChange={() => this.toggleShowPopover(!showPopover)}
          />
        </span>
      </Popover>
    );
  }
}

export default CompareSwitchSidesTool;
