/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import ApproverModalMessage from './ApproverModalMessage';
import GatekeeperModalMessage from './GatekeeperModalMessage';
import OwnerTodosRequestedModalMessage from './OwnerTodosRequestedModalMessage';
import EditorTodosRequestedModalMessage from './EditorTodosRequestedModalMessage';
import RecipientUploadProof from './RecipientUploadProof';
import MarkCommentsUploadProofModalMessage from './MarkCommentsUploadProofModalMessage';
import UploadNewVersionModalMessage from './UploadNewVersionModalMessage';
import ThirdPartyCookiesModalMessage from './ThirdPartyCookiesModalMessage';
import css from './ModalMessage.scss';

function ModalMessages({
  type,
  ...props,
}) {
  return (
    <div className={css.ModalMessage}>
      {type === 'ApproverModalMessage' &&
        <ApproverModalMessage {...props} />
      }
      {type === 'GatekeeperModalMessage' &&
        <GatekeeperModalMessage {...props} />
      }
      {type === 'OwnerTodosRequestedModalMessage' &&
        <OwnerTodosRequestedModalMessage {...props} />
      }
      {type === 'EditorTodosRequestedModalMessage' &&
        <EditorTodosRequestedModalMessage {...props} />
      }
      {type === 'RecipientUploadProof' &&
        <RecipientUploadProof />
      }
      {type === 'MarkCommentsUploadProofModalMessage' &&
        <MarkCommentsUploadProofModalMessage {...props} />
      }
      {type === 'UploadNewVersionModalMessage' &&
        <UploadNewVersionModalMessage />
      }
      {type === 'ThirdPartyCookiesModalMessage' &&
        <ThirdPartyCookiesModalMessage />
      }
    </div>
  );
}

export default ModalMessages;
