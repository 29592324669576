/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { makeUnstableBackendRequest } from '../../../util/unstable-backend-request';

export function getUserId(email) {
  // eslint-disable-next-line no-param-reassign
  email = email.toLowerCase();

  return makeUnstableBackendRequest({
    method: 'POST',
    path: '/api/users/email/',
    body: { Email: email },
  }).then((data) => {
    if (!data.length) {
      return makeUnstableBackendRequest({
        method: 'POST',
        path: '/api/users/create',
        body: { Email: email },
      });
    }
    return data[0];
  }).then(data => data.UserId);
}
