/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import { Translation } from '../../components/Text';
import { Button } from '../../components/Button';
import ColorDot from '../../components/ColorDot';
import css from './EmailColors.scss';

const DEFAULT_TITLE_TEXT_COLOR = '#138b3b';
const DEFAULT_LINK_TEXT_COLOR = '#138b3b';
const DEFAULT_BUTTON_BACKGROUND_COLOR = '#138b3b';
const DEFAULT_BUTTON_TEXT_COLOR = '#ffffff';

function ColorPicker({ color, defaultColor, label, onChange }) {
  const [selectedColor, setSelectedColor] = useState(color);

  useEffect(() => {
    setSelectedColor(color);
  }, [color]);

  return (
    <label className={classname(css.EmailColors__colorPicker, 'unstyled')}>
      <input
        type="color"
        className={css.EmailColors__colorPicker__inputColor}
        value={selectedColor || defaultColor}
        onClick={event => event.stopPropagation()}
        onChange={event => setSelectedColor(event.target.value)}
        onBlur={() => {
          if (color !== selectedColor) {
            onChange(selectedColor);
          }
        }}
      />
      <div className={css.EmailColors__colorPicker__colorDot}>
        <ColorDot.Generic
          color={selectedColor || defaultColor}
          outline
          label={label}
        />
      </div>
    </label>
  );
}

function EmailColors({
  titleTextColor,
  linkTextColor,
  buttonBackgroundColor,
  buttonTextColor,
  onChange,
}) {
  return (
    <div className={css.EmailColors}>
      <ColorPicker
        color={titleTextColor}
        defaultColor={DEFAULT_TITLE_TEXT_COLOR}
        label={<Translation value="domain-admin.menu.branding.email.colors.title-text" />}
        onChange={newColor => onChange({ titleTextColor: newColor })}
      />
      <ColorPicker
        color={linkTextColor}
        defaultColor={DEFAULT_LINK_TEXT_COLOR}
        label={<Translation value="domain-admin.menu.branding.email.colors.link-text" />}
        onChange={newColor => onChange({ linkTextColor: newColor })}
      />
      <ColorPicker
        color={buttonBackgroundColor}
        defaultColor={DEFAULT_BUTTON_BACKGROUND_COLOR}
        label={<Translation value="domain-admin.menu.branding.email.colors.button-background" />}
        onChange={newColor => onChange({ buttonBackgroundColor: newColor })}
      />
      <ColorPicker
        color={buttonTextColor}
        defaultColor={DEFAULT_BUTTON_TEXT_COLOR}
        label={<Translation value="domain-admin.menu.branding.email.colors.button-text" />}
        onChange={newColor => onChange({ buttonTextColor: newColor })}
      />
      {(titleTextColor || linkTextColor || buttonBackgroundColor || buttonTextColor) && (
        <div className={css.EmailColors__reset}>
          <Button
            variant="text"
            label={<Translation value="domain-admin.menu.branding.email.colors.reset" />}
            autoWidth
            onClick={() => {
              onChange({
                titleTextColor: null,
                linkTextColor: null,
                buttonBackgroundColor: null,
                buttonTextColor: null,
              });
            }}
          />
        </div>
      )}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ColorPicker.propTypes = {
    color: PropTypes.string,
    defaultColor: PropTypes.string.isRequired,
    label: PropTypes.node,
    onChange: PropTypes.func.isRequired,
  };

  EmailColors.propTypes = {
    titleTextColor: PropTypes.string,
    linkTextColor: PropTypes.string,
    buttonBackgroundColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };
}

export default EmailColors;
