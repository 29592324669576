/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { sdk } from '../util/sdk';

const clearLocalStorageKeys = [
  'pageproof.app.proofData',
  'pageproof.app.imageData',
  'pageproof.app.workflowId',
  'pageproof.app.uploadFinished',
  'pageproof.app.progress',
  'pageproof.app.proof-setup',
  'pageproof.app.commentQueue.*',
  'pageproof.app.dashboard.tabs.<currentUserId>.*',
  'pageproof.app.team-dashboard.tabs.<currentUserId>.*',
  'pageproof.app.proof.<currentUserId>.dialogs',
  'pageproof.app.recentProofs.<currentUserId>.*',
  'pageproof.app.user-status.<currentUserId>',
  'pageproof.app.notifications.<currentUserId>.snoozed',
  'pageproof.cache.user-preferences.<currentUserId>',
  'pageproof.cache.user-workflow-templates.<currentUserId>',
  'pageproof.app.recently-selected-device.<currentUserId>.*',
  'pageproof.app.default-measurement-unit.<currentUserId>',
  'pageproof.app.proof.*.<currentUserId>.has-seen-multiple-pages',
  'download_preferred_<currentUserId>',

  // make sure this is the last item to clear, because if anything is listening for this key to be removed, we want to
  // ensure that the other keys aren't prevented from being cleared. we have pieces of code that synchronously emit
  // events whenever a key is updated, and we can't make them async for risk of breaking other things.
  'pageproof.app.userData',
];

// TODO Delete any entries in IndexedDB for the current user.

export function logout() {
  const currentUserId = sdk.session.userId;

  return sdk.accounts.logout().finally(() => {
    const keys = Object.keys(window.localStorage);

    clearLocalStorageKeys.forEach((keyPattern) => {
      try {
        // eslint-disable-next-line no-param-reassign
        keyPattern = keyPattern.replace('<currentUserId>', currentUserId);

        if (keyPattern.includes('*')) {
          keys.forEach((existingKey) => {
            if (matchesPattern(keyPattern, existingKey)) {
              safeDeleteKey(existingKey);
            }
          });
        } else {
          safeDeleteKey(keyPattern);
        }
      } catch (err) {
        console.error(err);
      }
    });
  });
}

function matchesPattern(pattern, value) {
  const keyPattern = new RegExp(
    pattern
      .replace(/\./g, '\\.') // escape the character "."
      .replace(/\*/g, '.*') // replace wildcard characters "*" with the regular expression syntax for 'anything'
  );
  return !!value.match(keyPattern);
}

function safeDeleteKey(key) {
  try {
    window.localStorage.removeItem(key);
  } catch (err) {
    console.error(err);
  }
}
