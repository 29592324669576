/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect } from 'react';
import { sdk } from '../../util/sdk';

const EncryptedThumbnail = ({ id, fallback, ...props }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    const thumbnailUrlPromise = (
      sdk.files.thumbnail(id)
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          setThumbnailUrl(url);
          return url;
        })
        .catch((err) => {
          setThumbnailUrl(null);
          return Promise.reject(err);
        })
    );

    return () => {
      thumbnailUrlPromise.then((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [id]);

  if (!thumbnailUrl) {
    return fallback || null;
  }

  return (
    <img
      alt="Encrypted Thumbnail"
      {...props}
      src={thumbnailUrl}
    />
  );
};

export default EncryptedThumbnail;
