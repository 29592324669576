/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {Fragment} from 'react';
import UserEmail from './UserEmail';
import css from './TodosMessage.scss';

function TodosMessage(status) {
  const commentsDoneStatus = status.data;
  return (
    <Fragment>
      <div className={css.TodosMessage__message}>
        <div>{commentsDoneStatus.message}</div>
        <br />
        <div className={css.TodosMessage__flex}>
          <span>&mdash;&nbsp;</span>
          <UserEmail id={commentsDoneStatus.id} />
        </div>
      </div>
    </Fragment>
  );
}

export default TodosMessage;
