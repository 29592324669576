/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './ActionMessageModal.scss';
import Checkbox from '../Checkbox';
import { Translation } from '../Text';
import useSkippedDialog from '../../hooks/useSkippedDialog';

const ActionMessageModal = ({
  title,
  message,
  action,
  dialogType,
  canSkip,
}) => {
  const { shouldBeSkipped, setSkipped } = useSkippedDialog(dialogType);

  return (
    <div className={css.ActionMessageModal}>
      <h1>{title}</h1>
      <p className={css.ActionMessageModal__message}>
        {message}
      </p>
      {action &&
        <div className={css.ActionMessageModal__action}>
          {action}
        </div>
      }
      {canSkip &&
        <div className={css.ActionMessageModal__skipDialog}>
          <Checkbox
            selected={shouldBeSkipped}
            onChange={() => setSkipped(shouldBeSkipped)}
          />
          <span className={css.ActionMessageModal__skipDialog__text}>
            <Translation value="proof.dialog.message.dont-show" />
          </span>
        </div>
      }
    </div>
  );
};

ActionMessageModal.defaultProps = {
  canSkip: false,
  action: null,
};

if (process.env.NODE_ENV !== 'production') {
  ActionMessageModal.propTypes = {
    title: PropTypes.node.isRequired,
    message: PropTypes.node.isRequired,
    action: PropTypes.node,
    dialogType: PropTypes.string,
    canSkip: PropTypes.bool,
  };
}

export default ActionMessageModal;
