/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component, Fragment, useMemo } from 'react';
import Form from '../../components/Form';
import Button from '../../components/Button/Button';
import CopyButton from '../../components/Button/CopyButton';
import Reveal from '../../components/Reveal';
import Input from '../../components/Input';
import {Translation} from '../../components/Text';
import {Heading, Subheading} from '../../components/Page';
import {InlineSpinner} from '../../components/Spinner';
import FormControl from '../../components/FormControl';
import Switch from '../../components/Switch';
import { linkifyMessageWithEmails } from './linkifyMessageWithEmails';

const spinner = display => (
  display &&
    <InlineSpinner
      size={26}
      style={{marginLeft: 10}}
    />
);

// eslint-disable-next-line
class RegenerateAccessTokenButton extends Component {
  state = {
    loading: false,
    regenerated: false,
  };

  onRegenerate = () => {
    if (!this.state.loading) {
      clearTimeout(this._timeout);
      this.setState({loading: true});
      Promise.resolve(this.props.onClick())
        .then(() => {
          this.setState({
            loading: false,
            regenerated: true,
          });
        });
    }
  }

  render() {
    if (this.state.regenerated) {
      return null;
    }
    return (
      <Button
        {...this.props}
        variant="orange-text"
        autoWidth
        // eslint-disable-next-line
        label={this.state.loading ? 'Regenerating...' : this.state.regenerated ? 'Regenerated!' : 'Regenerate'}
        onClick={this.onRegenerate}
      />
    );
  }
}

function SCIMSettings({
  loading,
  error,
  settings,
  onEnable,
  onDisable,
  onRegenerateAccessToken,
}) {
  const messageWithLinks = useMemo(() => linkifyMessageWithEmails(error), [error]);

  return (
    <Form>
      <div>
        <Heading
          title={<Translation value="team.scim.settings.title" />}
          description={<Translation value="team.scim.settings.description" />}
        />
        <Reveal
          visible={error}
          postRender={false}
        >
          <div style={{backgroundColor: '#d6d6d6', padding: 25, borderRadius: 6, color: '#555', textAlign: 'center', fontWeight: '500', marginBottom: 100}}>
            {messageWithLinks}
          </div>
        </Reveal>
        {(!settings && !error) &&
          <div style={{textAlign: 'center'}}>
            <InlineSpinner size={60} />
          </div>
        }
        {settings && (
          <Fragment>
            <Subheading
              title={
                <Fragment>
                  <Translation value="team.scim.settings.enabled.title" />
                  {spinner(loading)}
                </Fragment>
              }
              description={<Translation value="team.scim.settings.enabled.description" />}
            />
            <FormControl
              compact
            >
              <Switch
                disabled={loading}
                value={settings.enabled}
                onChange={value => (value ? onEnable() : onDisable())}
              />
            </FormControl>
            <Reveal
              visible={!!(settings.baseUrl || settings.accessTokens)}
              align="bottom"
              postRender={false}
            >
              {() => (
                <div style={{paddingTop: 1}}>
                  <hr />
                  <div style={{height: 5}} />
                  <div style={{float: 'right', marginTop: 13, marginRight: -20}}>
                    <CopyButton
                      variant="text"
                      text={settings.baseUrl}
                      style={{width: 100}}
                    />
                  </div>
                  <Subheading
                    title={<Translation value="team.scim.settings.base-url.title" />}
                  />
                  <div style={{marginTop: -10}} />
                  <Input
                    readOnly
                    value={settings.baseUrl}
                    style={{width: '100%', color: '#999', fontSize: 20}}
                    onFocus={event => event.target.select()}
                    onMouseOver={event => event.target.select()}
                    onClick={event => event.target.select()}
                  />
                  {settings.accessTokens.map(({type, value}) => (
                    <Fragment>
                      <br />
                      <br />
                      <div style={{float: 'right', marginTop: 13, marginRight: -20}}>
                        <RegenerateAccessTokenButton
                          onClick={() => onRegenerateAccessToken(type)}
                        />
                        <CopyButton
                          variant="text"
                          text={value}
                          style={{width: 100}}
                        />
                      </div>
                      <Subheading
                        title={<Translation value={`team.scim.settings.${type}-access-token.title`} />}
                        description={<Translation value={`team.scim.settings.${type}-access-token.description`} />}
                      />
                      <div style={{marginTop: -10}} />
                      <Input
                        readOnly
                        type="password"
                        value={value}
                        style={{width: '100%', color: '#999', fontSize: 20}}
                        onMouseOver={event => (event.target.type = 'text')} // eslint-disable-line
                        onMouseLeave={event => (event.target.type = 'password')} // eslint-disable-line
                        onFocus={() => {}}
                      />
                    </Fragment>
                  ))}
                </div>
              )}
            </Reveal>
          </Fragment>
        )}
        <div style={{height: 100}} />
        {/* <pre>
          {JSON.stringify(settings, null, '  ')}
        </pre> */}
      </div>
    </Form>
  );
}

export default SCIMSettings;
