/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import propTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import { Translation } from '../../components/Text';
import styles from './CollectionManageStyles.scss';

function NavigationMenu({
  onClickToggle,
  id,
  name,
  isHeading,
  isLabel,
  showTooltip = true,
  sideIcon,
}) {
  const MenuComponent = ({ children, ...props }) => (
    isLabel
      ? <div {...props}>{ children }</div>
      : (
        <a
          href="#"
          {...props}
        >
          { children }
        </a>
      )
  );

  return (
    <nav className={styles.CollectionManage__options}>
      {isHeading
        ? (
          <h5 className={styles.CollectionManage__options__heading}>
            <Translation value={`collection.manage.heading.${name}`} />
          </h5>
        )
        : (
          <MenuComponent
            className={styles.CollectionManage__options__menu}
            onClick={(event) => {
              event.preventDefault();

              if (onClickToggle) {
                onClickToggle(id);
              }
            }}
          >
            <Translation value={`collection.manage.${name}.heading`} />
            {showTooltip &&
              <span className={styles.CollectionManage__options__tip}>
                <Translation value={`collection.manage.${name}.tip`} />
              </span>
            }
            <span className={styles.CollectionManage__options__sideIcon}>
              {sideIcon || <InlineSVG src="img/interface/arrow-down.svg" />}
            </span>
          </MenuComponent>
        )
      }
    </nav>
  );
}

if (process.env.NODE_ENV !== 'production') {
  NavigationMenu.propTypes = {
    onClickToggle: propTypes.func,
    id: propTypes.string,
    name: propTypes.string,
    isHeading: propTypes.bool,
    isLabel: propTypes.bool,
    showTooltip: propTypes.bool,
    sideIcon: propTypes.node,
  };
}
export default NavigationMenu;
