/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { createTransitionComponent } from './factory';
import css from './ScaledUpAppearing.scss';

const styles = {
  enter: css.ScaledUpAppearing__enter,
  enterActive: css['ScaledUpAppearing__enter--active'],
  leave: css.ScaledUpAppearing__leave,
  leaveActive: css['ScaledUpAppearing__leave--active'],
};

export default createTransitionComponent(styles);
