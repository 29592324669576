/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

function PageHeading({
  title,
  description,
  compact,
}) {
  return (
    <div
      className={classname('PageHeading', {
        'PageHeading--compact': compact,
      })}
    >
      <h1>{title}</h1>
      {description &&
        <div>{description}</div>}
    </div>
  );
}

PageHeading.defaultProps = {
  compact: false,
};

if (process.env.NODE_ENV !== 'production') {
  PageHeading.propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.node,
    compact: PropTypes.bool, // same style as the proof-setup page heading (not as much margin)
  };
}

export default PageHeading;
