/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '../../components/FormControl';
import css from './InputWrapper.scss';
import HelpBubble from '../../components/HelpBubble';
import { Spinner } from '../../components/Spinner';

export function InputWrapper({ children, fieldOutsideLabel, isUpdating, label, tooltip, tooltipUrl }) {
  const tooltipMessage = (tooltipUrl && tooltip)
    ? `${tooltip}${tooltip.trim().endsWith('.') ? '' : '.'} Click for more information.`
    : tooltip;

  return (
    <FormControl
      compact
      fieldOutsideLabel={fieldOutsideLabel}
      label={(
        <div className={css.FormLabel}>
          {label}
          {tooltip &&
            <HelpBubble
              className={css.FormLabel__bubble}
              size="small"
              message={tooltipMessage}
              url={tooltipUrl}
            />}
          <div className={css.FormLabel__spinnerHolder}>
            {isUpdating && <Spinner size={24} />}
          </div>
        </div>
      )}
    >
      {children}
    </FormControl>
  );
}

if (process.env.NODE_ENV !== 'production') {
  InputWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    fieldOutsideLabel: PropTypes.bool,
    isUpdating: PropTypes.bool,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    tooltipUrl: PropTypes.string,
  };
}

export default InputWrapper;
