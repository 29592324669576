/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { createContext } from 'react';

export const VirtualListContext = createContext({
  // eslint-disable-next-line no-unused-vars
  setItemKeepMounted: (itemKey, shouldKeepMounted) => {},
});
