/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import ChecklistTemplatesDashboard from '../../components/ChecklistTemplatesDashboard';
import { useSynchronizedUrl } from '../../hooks/useSynchronizedUrl';

export default ({ initialRouteParams }) => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const [filter, setFilter] = useState(initialRouteParams.filter || 'active');
  const [checklistTemplateId, setChecklistTemplateId] = useState(initialRouteParams.checklistTemplateId || null);

  useSynchronizedUrl(['/team/checklists/templates', checklistTemplateId], { filter });

  return (
    <Fragment>
      {hasInitialized && (
        <style>{`
          header {
            background: transparent;
            backdrop-filter: none;
            -webkit-backdrop-filter: none;
            border-bottom: 0;
          }
        `}</style>
      )}
      <ChecklistTemplatesDashboard
        checklistTemplateId={checklistTemplateId}
        filterState={filter}
        onFilterStateChange={(filterState) => {
          unstable_batchedUpdates(() => {
            setFilter(filterState);
            setChecklistTemplateId(null);
          });
        }}
        onSelectChecklistTemplate={(checklistTemplateId) => {
          setChecklistTemplateId(checklistTemplateId);
        }}
        onNewChecklistTemplate={(checklistTemplateId) => {
          unstable_batchedUpdates(() => {
            setFilter('active');
            setChecklistTemplateId(checklistTemplateId);
          });
        }}
        onInit={() => {
          setHasInitialized(true);
        }}
      />
    </Fragment>
  );
};
