/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Contacts from './Contacts';
import css from './Groups.scss';
import { Translation } from '../Text';
import useSectionList from '../../hooks/useSectionList';

const Groups = ({ emails, removeEmail }) => {
  const sections = useSectionList(emails);

  return (
    <ul className={css.Groups}>
      {emails.length > 15
        ? sections.map(group => (
          <li
            className={css.Groups__sections}
            key={group.label}
          >
            <h1 className={css.Groups__letter}>{group.label}</h1>
            <Contacts
              emails={group.items}
              removeEmail={removeEmail}
            />
          </li>
        ))
        : (
          <li>
            <h1 className={css.Groups__letter}>
              <Translation value="profile.address-book-contacts" />
            </h1>
            <Contacts
              emails={emails}
              removeEmail={removeEmail}
            />
          </li>
        )}
    </ul>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Groups.propTypes = {
    emails: PropTypes.arrayOf(PropTypes.string).isRequired,
    removeEmail: PropTypes.func.isRequired,
  };
}

export default Groups;
