/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';

export const DraggableCanvas = forwardRef((props, ref) => {
  const rootRef = useRef();

  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [isDragging, setDragging] = useState(false);

  // This is a hack because we can't use useEffectEvent
  const propsRef = useRef();
  propsRef.current = props;

  useImperativeHandle(ref, () => ({
    setPosition(x, y) {
      setPosition({ x, y });
    },
  }), []);

  const startDrag = useCallback((startEvent) => {
    if (startEvent.button !== 0) {
      return;
    }

    startEvent.preventDefault();

    const rootRect = rootRef.current.getBoundingClientRect();

    let originalPosition;
    const startPosition = getPositionFromEvent(startEvent);

    updatePosition(startEvent);
    setDragging(true);

    if (propsRef.current.onDragStart) {
      propsRef.current.onDragStart();
    }

    function updatePosition(event) {
      const updatedPosition = getPositionFromEvent(event);

      setPosition((currentPosition) => {
        if (!originalPosition) {
          originalPosition = currentPosition;
        }

        return {
          x: originalPosition.x + (updatedPosition.x - startPosition.x),
          y: originalPosition.y + (updatedPosition.y - startPosition.y),
        };
      });
    }

    function getPositionFromEvent(event) {
      return {
        x: ((event.clientX - rootRect.left) / rootRect.width) * 100,
        y: ((event.clientY - rootRect.top) / rootRect.height) * 100,
      };
    }

    window.addEventListener('mousemove', updatePosition);
    window.addEventListener('mouseup', function endDrag() {
      setDragging(false);

      if (propsRef.current.onDragEnd) {
        propsRef.current.onDragEnd();
      }

      window.removeEventListener('mousemove', updatePosition);
      window.removeEventListener('mouseup', endDrag);
    });
  }, []);

  // const onScroll = (event) => {
  //   event.preventDefault();

  //   const rootRect = rootRef.current.getBoundingClientRect();

  //   console.log(event.nativeEvent);
  //   // const delta = {
  //   //   x: (event.deltaX * 20) / rootRect.width,
  //   //   y: (event.deltaY * 20) / rootRect.height,
  //   // };
  //   const delta = {
  //     x: (event.nativeEvent.wheelDeltaX * -0.5) / rootRect.width,
  //     y: (event.nativeEvent.wheelDeltaY * -0.5) / rootRect.height,
  //   };

  //   setPosition((currentPosition) => {
  //     return {
  //       x: currentPosition.x + delta.x,
  //       y: currentPosition.y + delta.y,
  //     };
  //   });
  // };

  return (
    <div
      ref={rootRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: isDragging ? 'grabbing' : 'grab',
        overscrollBehaviorX: 'none',
      }}
      onMouseDown={startDrag}
    >
      <div
        style={{
          position: 'absolute',
          top: position.y + '%',
          left: position.x + '%',
          transform: 'translate3d(-50%, -50%, 0)',
          willChange: 'top, left',
        }}
      >
        {props.children}
      </div>
    </div>
  );
});
