/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import css from './Scroll.scss';

function ScrollTop() {
  return (
    <span className={css.Scroll}>
      <InlineSVG src="img/content/proof/icons/metadata/top.svg" />
    </span>
  );
}

export default ScrollTop;
