/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/**
 * Created by preeti on 18/08/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Dropdown, {
  Option as DropdownOption,
  OptionLabel as DropdownOptionLabel,
  Label as DropdownLabel,
  Separator as DropdownSeparator,
} from '../../components/Dropdown';
import Tooltip from '../../components/Tooltip';
import Translation from '../../components/Text/Translation';

function VideoQualityDropdown({
  selected,
  onChange,
  ...props,
}) {
  return (
    <Dropdown
      variant="video-quality"
      selected={selected}
      {...props}
      target={
        <span>
          <Tooltip title={<Translation value="proof.utilities.quality" />} up center>
            <div>
              <InlineSVG
                src="/img/interface/qualities/quality.svg"
              />
            </div>
          </Tooltip>
        </span>
      }
    >
      {props.qualities.map((quality, index) => (
        // eslint-disable-next-line no-nested-ternary
        typeof quality === 'string'
          ? <DropdownLabel
            key={index}
            label={quality}
          />
          : quality === null
            ? <DropdownSeparator key={index} />
            : <DropdownOption
              key={index}
              value={quality.id}
              icon="/img/interface/qualities/quality.svg"
              label={
                <DropdownOptionLabel
                  title={(
                    <span>
                      {quality.name}
                      {quality.icon && (
                        <span className="hd-icon">
                          <InlineSVG src="/img/interface/qualities/hd.svg" />
                        </span>
                      )}
                    </span>
                  )}
                  description={(quality.id === -1) ? props.autoQuality : null}
                />
              }
              onClick={() => onChange(quality)}
            />
      ))}
    </Dropdown>
  );
}

if (process.env.NODE_ENV !== 'production') {
  VideoQualityDropdown.propTypes = {
    selected: PropTypes.number,
    direction: PropTypes.oneOf([
      'down',
      'up',
    ]),
    qualities: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
    autoQuality: PropTypes.string,
  };
}

VideoQualityDropdown.defaultProps = {
  selected: '-1',
  direction: 'up',
  qualities: [],
};

export default VideoQualityDropdown;
