/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown, {
  Option,
  OptionLabel,
} from '../../components/Dropdown';
import {Translation} from '../../components/Text';

const CommentOptionsContainer = ({
  canDelete,
  isEditing,
  onStartDelete,
  onStartUpdate,
  onCancelUpdate,
}) => (
  <Dropdown
    variant="comment-options"
    selected="none"
    placeholder={<Option icon="/img/content/proof/icons/metadata/more.svg" />}
  >
    {!isEditing
      ? (<Option
        label={<OptionLabel title={<Translation value="comment.options.edit" />} />}
        onClick={onStartUpdate}
      />)
      : (<Option
        label={<OptionLabel title={<Translation value="comment.options.cancel-edit" />} />}
        onClick={onCancelUpdate}
      />)
    }
    {canDelete && (
      <Option
        label={<OptionLabel title={<Translation value="comment.options.delete" />} />}
        onClick={onStartDelete}
      />
    )}
  </Dropdown>
);

if (process.env.NODE_ENV !== 'production') {
  CommentOptionsContainer.propTypes = {
    canDelete: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onStartDelete: PropTypes.func,
    onStartUpdate: PropTypes.func.isRequired,
    onCancelUpdate: PropTypes.func.isRequired,
  };
}

export default CommentOptionsContainer;
