/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import classname from 'classname';
import Translation from '../../Text/Translation';
import css from './StatusMessage.scss';

const StatusMessage = data => (
  <div className={classname(
    css.StatusMessage,
    css[`StatusMessage__${data.reviewStatus.statusClass}`],
  )}
  >
    <Translation
      value={`proof.viewed-status.${data.reviewStatus.statusText}`}
      params={{
        totalComplete: data.reviewStatus.totalComplete,
        totalReviewers: data.reviewStatus.totalReviewers,
      }}
    />
    {!!data.reviewStatus.totalComplete &&
      <div
        className={css.StatusMessage__seeWho}
        onClick={() => data.reviewStatus.openAndScrollToWorkflow()}
      >
        <Translation value="proof.dialog.see-who" />
      </div>
    }
  </div>
);

export default StatusMessage;
