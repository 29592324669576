import { useEffect } from 'react';

const DropAnywhere = ({ onFiles, disabled, onDisabledDrop }) => {
  useEffect(() => {
    const onDragOver = (event) => {
      event.preventDefault();

      if (disabled) {
        event.dataTransfer.dropEffect = 'none'; // eslint-disable-line no-param-reassign
        return;
      }

      if (event.dataTransfer.files.length) {
        event.dataTransfer.dropEffect = 'copy'; // eslint-disable-line no-param-reassign
      }
    };
    const onDrop = (event) => {
      event.preventDefault();

      if (disabled) {
        onDisabledDrop();
        return;
      }

      if (event.dataTransfer.files.length) {
        onFiles([...event.dataTransfer.files]);
      }
    };

    document.addEventListener('dragover', onDragOver);
    document.addEventListener('drop', onDrop);

    return () => {
      document.removeEventListener('dragover', onDragOver);
      document.removeEventListener('drop', onDrop);
    };
  }, [disabled]);

  return null;
};

export default DropAnywhere;
