/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './Owners.scss';

import Avatar from '../../Avatar/Avatar';
import { PopupMenu, Option, Separator } from '../../PopupMenu';
import Suggestions from '../../Suggestions';
import ExpandingSection from './ExpandingSection';
import { unique } from '../../../util/object-array-utils';
import env from '../../../../../shared/env';
import ItemBorderWrapper from '../../ItemBorderWrapper';
import { Translation } from '../../Text';
import { RevealingEmailInput } from '../../RevealingInput';

const Owners = ({
  owners: sourceOwners,
  workflowTemplateOwners = [],
  proofTemplateOwners = [],
  onChange,
}) => {
  const owners = unique(sourceOwners || []);

  const templateOwners = unique([
    ...workflowTemplateOwners,
    ...proofTemplateOwners,
  ]);

  return (
    <div className={css.Owners}>
      <div className={css.Owners__avatars}>
        {owners.map((owner, index) => (
          <ItemBorderWrapper
            key={owner}
            items={owners}
            currentItem={owner}
            outlineItems={templateOwners}
          >
            <PopupMenu
              maxWidth={270}
              options={
                <React.Fragment>
                  <Option
                    label={<div className={css.Owners__email}>{owner}</div>}
                    disabled
                  />
                  {workflowTemplateOwners.includes(owner) && (
                    <Option
                      className={css.Owners__inheritedDescription}
                      label={<Translation value="proof.setup.owners.workflow-owner.inherited.description" />}
                      disabled
                    />
                  )}
                  {proofTemplateOwners.includes(owner) && (
                    <Option
                      className={css.Owners__inheritedDescription}
                      label={<Translation value="proof.setup.owners.workflow-owner.inherited.proof-template.description" />}
                      disabled
                    />
                  )}
                  <Separator />
                  {index === 0
                    ? (
                      <Option
                        className={css.Owners__inheritedDescription}
                        label={<Translation value="proof.setup.owners.will-send-invitation-from-this-owner" />}
                        disabled
                      />
                    )
                    : (
                      <Option
                        label={<Translation value="proof.setup.owners.send-invitation-from-this-owner" />}
                        onClick={() => {
                          const newOwners = [owner, ...owners];
                          onChange(unique(newOwners));
                        }}
                      />
                    )
                  }
                  {owners.length > 1 && (
                    <Option
                      label={<Translation value="option.remove" />}
                      onClick={() => {
                        const newOwners = [...owners];
                        newOwners.splice(newOwners.indexOf(owner), 1);
                        onChange(unique(newOwners));
                      }}
                    />
                  )}
                </React.Fragment>
              }
            >
              <Avatar
                active
                url={`${env.avatar_url}/-/${owner}`}
                size={40}
                spinner
              />
            </PopupMenu>
          </ItemBorderWrapper>
        ))}
        <RevealingEmailInput
          exclusions={owners}
          onAdd={(email) => {
            const newOwners = [...owners, email];
            onChange(unique(newOwners));
            // eslint-disable-next-line import/no-named-as-default-member
            Suggestions.addEmail(email);
          }}
        />
      </div>
    </div>
  );
};

Owners.Heading = ({ count }) => (
  <ExpandingSection.Heading
    icon="/img/icons/material/military_tech-24px.svg"
    title={<Translation value="proof.setup.owners.title" />}
    count={count}
    description={<Translation value="proof.setup.owners.description" />}
  />
);

export default Owners;
