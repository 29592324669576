/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../../components/Text';
import { sdk } from '../../util/sdk';
import BulkActionProgressMessage from '../../components/BulkActionProgressMessage';

const COMPLETION_PERCENTAGE = 100;

function deleteProof(proofId) {
  return sdk.proofs.versions.list(proofId)
    .then((versions) => {
      let baseProofId = proofId;
      if (versions.length) {
        baseProofId = versions[0].id;
      }
      const canDeleteAllVersions = !versions.some(version => !version.canDelete);
      return canDeleteAllVersions && baseProofId;
    })
    .then((proofIdToDelete) => {
      if (proofIdToDelete) {
        return sdk.proofs.delete(proofIdToDelete);
      } else {
        return Promise.reject(new Error(`Can not delete all versions on proof ${proofId}, as you are not owner on all versions.`));
      }
    });
}

const BulkDeleteProofsModal = ({
  proofs,
  onClose,
}) => {
  const recentProofsStorage = useMemo(() => window.__pageproof_bridge__.storageService(`pageproof.app.recentProofs.${sdk.session.userId}.`), []);
  const [deletingProofIndex, setDeletingProofIndex] = useState(0);
  const [unprocessedProofs, setUnprocessedProofs] = useState([]);

  const progress = deletingProofIndex ? (deletingProofIndex / proofs.length) * COMPLETION_PERCENTAGE : 1;
  const isInProgress = progress < COMPLETION_PERCENTAGE;

  const doneMessage = unprocessedProofs.length === proofs.length
    ? 'dashboard.manage.bulk-delete.none-deleted'
    : window.generalfunctions_getLanguagePluralKey(unprocessedProofs.length, {
      none: 'dashboard.manage.bulk-delete.all-deleted',
      single: 'dashboard.manage.bulk-delete.some-deleted.single',
      multi: 'dashboard.manage.bulk-delete.some-deleted.multi',
    });

  const modalHeading = (
    <Translation value={isInProgress
      ? 'dashboard.manage.bulk-delete.heading.in-progress'
      : 'dashboard.manage.bulk-delete.heading.done'}
    />
  );
  const modalMessage = isInProgress
    ? (
      <Translation
        value="dashboard.manage.bulk-delete.deleting"
        params={{ proofName: <span className="green">{proofs[deletingProofIndex].name}</span> }}
      />
    )
    : (
      <Translation
        value={doneMessage}
        params={{
          proofs: (
            <span className="green">
              {unprocessedProofs.slice(0, -1).map(proof => proof.name).join(', ')}
            </span>
          ),
          lastProof: (
            <span className="green">
              {unprocessedProofs.length && unprocessedProofs[unprocessedProofs.length - 1].name}
            </span>
          ),
        }}
      />
    );

  useEffect(() => {
    let requestInProgress = true;
    const deleteByIndex = (index) => {
      const proof = proofs[index];
      deleteProof(proof.id)
        .then(() => recentProofsStorage.remove(proof.id))
        .catch(() => {
          if (requestInProgress) {
            setUnprocessedProofs(prevUnprocessedProofs => [...prevUnprocessedProofs, proof]);
          }
        })
        .finally(() => {
          if (requestInProgress) {
            const nextIndex = index + 1;
            setDeletingProofIndex(nextIndex);
            if (nextIndex < proofs.length) {
              deleteByIndex(nextIndex);
            } else {
              requestInProgress = false;
            }
          }
        });
    };

    deleteByIndex(0);

    return () => {
      requestInProgress = false;
    };
  }, []);

  return (
    <BulkActionProgressMessage
      isInProgress={isInProgress}
      progress={progress}
      heading={modalHeading}
      message={modalMessage}
      onClose={onClose}
    />
  );
};

if (process.env.NODE_ENV !== 'production') {
  BulkDeleteProofsModal.propTypes = {
    proofs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
    onClose: PropTypes.func,
  };
}

export default BulkDeleteProofsModal;
