import { DEFAULT_DPI, INCH_TO_MM } from './constants';

export function byteLength(str) {
  return new window.Blob([str]).size;
}

// Converts a given pixel value to a whole mm value, based on 72DPI
export function pixelsToMm(pixels, SVG_SCALE = 1, DPI = DEFAULT_DPI) {
  const effectivePixels = pixels * SVG_SCALE;
  return Math.round((effectivePixels / DPI) * INCH_TO_MM);
}

export function convertMultipleDimensions(dimension, measurementUnit) {
  if (dimension && measurementUnit) {
    switch (measurementUnit) {
    case 'mm':
      return dimension * 1;
    case 'cm':
      return dimension / 10;
    case 'm':
      return dimension / 1000;
    case 'inch':
      return dimension / 25.4;
    case 'ft':
      return dimension / 304.8;
    case 'pt':
      return dimension * 2.835;
    case 'px':
      return dimension;
    case 'pxW':
      return dimension * 2.835;
    case 'pxP':
      return dimension * 11.811;
    default:
      return dimension;
    }
  }
  return dimension;
}
