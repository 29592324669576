/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import InputOptions from '../InputOptions';
import css from './IntegrationReferenceSelector.scss';

function BackButton({ label, onClick, className, sublabel }) {
  return (
    <InputOptions.Option
      className={className}
      label={(
        <div
          className={css.Back}
        >
          <InlineSVG
            className={css.Back__arrow}
            src="/img/icons/arrow-down.svg"
          />
          <span>
            {label}
            {sublabel && (
              <span className={css.Back__label__sublabel}>
                {sublabel}
              </span>
            )}
          </span>
        </div>
      )}
      onClick={() => {
        onClick();
      }}
    />
  );
}

export default BackButton;
