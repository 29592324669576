/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import i18n from '../../util/i18n';
import { isObject } from '../../util/isObject';

export function getTranslationsSync(locale) {
  if (isObject(i18n.cache[locale])) {
    return i18n.cache[locale];
  }
  i18n.getTranslations(locale); // load the translations in the background
  return {};
}

export function getTranslations(locale) {
  return i18n.getTranslations(locale);
}
