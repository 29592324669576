/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function goTo(location) {
  const {
    $location,
    $rootScope,
  } = window.__pageproof_bridge__;

  $location.path(location);
  $rootScope.$apply();
}

function goToUrl(location, event = null) {
  const {
    $location,
    $rootScope,
  } = window.__pageproof_bridge__;

  if (event) {
    event.preventDefault();
  }

  $location.url(location);
  if (!$rootScope.$$phase) {
    $rootScope.$apply();
  }
}

export {
  goTo,
  goToUrl,
};
