/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Visible from '@pageproof/visible';
import css from './SetupItem.scss';

import Tooltip from '../../Tooltip/Tooltip';
import getProofEditedFields from '../utils/finalize/getProofEditedFields';
import TemporaryThumbnail from './TemporaryThumbnail';
import GenericFileThumbnail from './GenericFileThumbnail';
import EncryptedThumbnail from '../../EncryptedThumbnail';
import Favicon from './Favicon';
import getProofFileType from '../utils/getProofFileType';
import Translation from '../../Text/Translation';
import getImportProviderTranslationKey from '../utils/getImportProviderTranslationKey';

const SetupItem = ({
  icon,
  iconOverlay,
  title,
  description,
  options,
  isEdited = false,
  editedReason,
  isSelected = false,
  isHovered = false,
  canInteract = true,
  isCompact = false,
  inTooltip = false,
  className,
  ...props
}) => (
  <div className={classname(css.SetupItemWrapper, className)}>
    <button
      type="button"
      className={classname(css.SetupItem, {
        [css['SetupItem--canInteract']]: canInteract,
        [css['SetupItem--isSelected']]: isSelected,
        [css['SetupItem--isHovered']]: isHovered,
        [css['SetupItem--isCompact']]: isCompact,
        [css['SetupItem--inTooltip']]: inTooltip,
      })}
      {...props}
    >
      {icon && (
        <div className={css.SetupItem__icon}>
          {typeof icon === 'string'
            ? (
              <InlineSVG
                src={icon}
                className={css.SetupItem__icon__svgIcon}
              />
            )
            : icon
          }
          {iconOverlay && (
            <div className={css.SetupItem__icon__overlay}>
              {iconOverlay}
            </div>
          )}
        </div>
      )}
      {!isCompact && (
        <div className={css.SetupItem__info}>
          <div className={css.SetupItem__info__title}>{title}</div>
          <div className={css.SetupItem__info__description}>{description}</div>
        </div>
      )}
      <div className={css.SetupItem__options}>
        {options}
      </div>
    </button>
    {isEdited && (
      <div style={{ position: 'relative' }}>
        <Tooltip
          title={editedReason}
          disabled={!editedReason}
          down
          center
          arrow
          offset={18}
        >
          <div
            className={css.SetupItem__editedIndicator}
          />
        </Tooltip>
      </div>
    )}
  </div>
);

SetupItem.Option = ({
  icon,
  visibility = 'always', // "always" | "on-interaction" | "not-on-interaction"
  className,
  onClick,
}) => (
  <button
    type="button"
    className={classname(css.SetupItemOption, className, {
      [css['SetupItemOption--canInteract']]: !!onClick,
      [css['SetupItemOption--visible-' + visibility]]: visibility,
    })}
    onClick={(event) => {
      if (onClick) {
        event.stopPropagation();
        onClick(event);
      }
    }}
  >
    <div className={css.SetupItemOption__icon}>
      {typeof icon === 'string'
        ? (
          <InlineSVG
            src={icon}
            className={css.SetupItemOption__icon__svgIcon}
          />
        )
        : icon
      }
    </div>
  </button>
);

SetupItem.ExpandOption = ({ onClick }) => (
  <SetupItem.Option
    icon="/img/icons/arrow-down.svg"
    visibility="always"
    onClick={onClick}
    className={css.SetupItemExpandOption}
  />
);

const getProofExtension = proof => (
  proof.file && (proof.file.name.substring(proof.file.name.lastIndexOf('.') + 1).toUpperCase())
);

SetupItem.ProofName = ({ proof }) => {
  const proofFileType = getProofFileType(proof);

  if (!proofFileType) {
    return <span>{proof.name}</span>;
  }

  if (proof.isAwaitingEmail) {
    return <span>••••••••••</span>;
  }

  switch (proofFileType) {
  case 'web-url':
  case 'email': {
    if (proof.name) {
      return <span>{proof.name}</span>;
    }
    return (
      <span>
        {proof.file.emailAddress || proof.file.url}
      </span>
    );
  }
  default: {
    return <span>{proof.file.name}</span>;
  }
  }
};

const getProofDescriptionTranslation = (proof) => {
  const proofFileType = getProofFileType(proof);

  if (!proofFileType) {
    return 'proof.setup.item.description.no-file';
  }

  if (proof.file && proof.file.status === 'error') {
    return 'proof.setup.item.description.file-error';
  }

  switch (proofFileType) {
  case 'web-url': {
    if (proof.file.url.toLowerCase().indexOf('https:') === 0) {
      if (proof.file.basicAuth) {
        return 'proof.setup.item.description.web.secure-with-basic-auth';
      }
      return 'proof.setup.item.description.web.secure';
    }

    if (proof.file.basicAuth) {
      return 'proof.setup.item.description.web.insecure-with-basic-auth';
    }
    return 'proof.setup.item.description.web.insecure';
  }
  case 'external':
  case 'local': {
    const extension = getProofExtension(proof);

    if (proofFileType === 'external' && proof.file.provider) {
      if (typeof proof.file.size === 'number') {
        return [
          'proof.setup.item.description.external.with-size',
          {
            size: window.bytesToSize(proof.file.size, false),
            extension,
            provider: <Translation value={getImportProviderTranslationKey(proof.file.provider)} />,
          },
        ];
      }
      return [
        'proof.setup.item.description.external',
        {
          extension,
          provider: <Translation value={getImportProviderTranslationKey(proof.file.provider)} />,
        },
      ];
    }

    if (typeof proof.file.size === 'number') {
      return [
        'proof.setup.item.description.local.with-size',
        {
          size: window.bytesToSize(proof.file.size, false),
          extension,
        },
      ];
    }

    return [
      'proof.setup.item.description.local',
      { extension },
    ];
  }
  case 'email': {
    return [
      'proof.setup.item.description.email',
      { iconName: proof.file.icon.name },
    ];
  }
  default: {
    return 'proof.setup.item.description.no-file';
  }
  }
};

SetupItem.ProofDescription = ({ proof }) => {
  const translation = getProofDescriptionTranslation(proof);

  return (
    typeof translation === 'string'
      ? <Translation value={translation} />
      : <Translation value={translation[0]} params={translation[1]} />
  );
};

SetupItem.ProofEditedReason = ({ proof, shared, parameters, userPreferences }) => {
  if (!proof._isEdited) {
    return null;
  }
  const editedFields = getProofEditedFields(proof, shared, parameters, userPreferences);
  return (
    <div>
      This proof has been edited.
      <div style={{ height: 10 }} />
      Changes in the shared setup area won’t
      <br />
      automatically be applied to this proof.
      {editedFields.length !== 0 && (
        <Fragment>
          <div style={{ height: 10 }} />
          Differences:
          <div style={{ height: 4 }} />
          {editedFields.map(field => (
            <li key={field}>{field}</li>
          ))}
        </Fragment>
      )}
    </div>
  );
};

SetupItem.ProofIcon = ({ proof, size }) => {
  const proofFileType = getProofFileType(proof);

  if (!proofFileType) {
    return <GenericFileThumbnail type="?" size={size} />;
  }

  if (proofFileType === 'email') {
    return <SetupItem.EmailIcon icon={proof.file.icon} />;
  }

  const isWebUrl = proofFileType === 'web-url';

  const extension = isWebUrl
    ? proof.file.url.substring(0, proof.file.url.indexOf(':')) // use protocol
    : getProofExtension(proof);

  const hasThumbnailReference = !!(proof.file && proof.file.thumbnailReference);

  const fallback = (
    <GenericFileThumbnail
      type={extension}
      size={size}
    />
  );

  const favicon = isWebUrl && (
    <Favicon
      url={proof.file.url}
      width={size}
      height={size}
      style={{ objectFit: 'contain' }}
      fallback={fallback}
    />
  );

  const temporaryThumbnail = (
    <TemporaryThumbnail
      src={proof.file.thumbnailReference}
      width={size}
      height={size}
      style={{ objectFit: 'contain' }}
      fallback={fallback}
    />
  );

  const encryptedThumbnail = proof.file.status === 'ok' && (
    <EncryptedThumbnail
      id={proof.file.id}
      alt={proof.name}
      width={size}
      height={size}
      style={{ objectFit: 'contain' }}
      fallback={favicon || fallback}
    />
  );

  return (
    <Visible>
      {({ hasBeenVisible }) => (
        <div style={{ width: size, height: size }}>
          {encryptedThumbnail || (
            hasThumbnailReference && hasBeenVisible
              ? temporaryThumbnail
              : favicon || fallback
          )}
        </div>
      )}
    </Visible>
  );
};

SetupItem.CollectionIcon = () => (
  <InlineSVG
    src="/img/content/proof/icons/collection_icon.svg"
    className={css.SetupItemCollectionIcon}
  />
);

SetupItem.EmailIcon = ({ icon }) => (
  <InlineSVG
    className={css.SetupItemEmailIcon}
    src={icon.url}
  />
);

export default SetupItem;
