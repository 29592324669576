/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Prompt from '../../components/Prompt';
import {Translation} from '../../components/Text';
import {Button} from '../../components/Button';
import Portal from '../../components/Portal';

const PromptUpdateContainer = ({
  onClick,
  isMobile,
}) => (
  <Portal>
    <Prompt
      title={<Translation value="app.update.available.title" />}
      message={<Translation value={`app.update.available.message${isMobile ? '.mobile' : ''}`} />}
      action={(
        <Button
          variant="orange"
          label={<Translation value="button.refresh" />}
          onClick={onClick}
        />
      )}
    />
  </Portal>
);

if (process.env.NODE_ENV !== 'production') {
  PromptUpdateContainer.propTypes = {
    onClick: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
  };
}

export default PromptUpdateContainer;
