/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-env browser */
import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import css from './CheckForOverflow.scss';

export const CheckForOverflow = ({ children }) => {
  const [doesOverflow, setDoesOverflow] = useState(false);

  const wrapperRef = useRef();
  const pollingTimeout = useRef();

  const performOverflowCheck = () => {
    if (!wrapperRef.current) {
      return;
    }
    const { scrollWidth, clientWidth } = wrapperRef.current;
    setDoesOverflow(scrollWidth > clientWidth);
  };

  // Backup incase the observer doesn't do its job
  // e.g. user is in IE or parent element size doesn't change but a sibling does
  const runPoller = () => {
    pollingTimeout.current = setTimeout(() => {
      runPoller();
    }, 2000);
    performOverflowCheck();
  };

  useEffect(() => {
    runPoller();

    // IE relies purely on runPoller
    if (!window.ResizeObserver) {
      return () => clearTimeout(pollingTimeout.current);
    }

    const resizeObserver = new ResizeObserver(performOverflowCheck);
    resizeObserver.observe(wrapperRef.current.parentElement, { box: 'border-box' });
    resizeObserver.observe(wrapperRef.current, { box: 'border-box' });

    return () => {
      clearTimeout(pollingTimeout.current);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Fragment>
      <div
        ref={wrapperRef}
        className={css.ChildrenHider}
      >
        {children(false)}
      </div>
      {children(doesOverflow)}
    </Fragment>
  );
};


if (process.env.NODE_ENV !== 'production') {
  CheckForOverflow.propTypes = {
    children: PropTypes.func.isRequired,
  };
}
