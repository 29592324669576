/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import Label from '../Label';
import Translation from '../../Text/Translation';
import { PopupMenu, Option } from '../../PopupMenu';
import Tooltip from '../../Tooltip';

const options = [
  'approved',
  'highlight',
  null,
];

function LabelOptions({
  type,
  onChange,
}) {
  return (
    <PopupMenu
      options={
        <Fragment>
          {options.map(option => (
            <Option
              key={option}
              label={<Translation value={`comment.label.${option}`} />}
              selected={option === type}
              onClick={option !== type && (() => onChange(option))}
            />
          ))}
        </Fragment>
      }
    >
      {popover => (
        <div>
          <Tooltip
            up
            center
            title={<Translation value="comments.box-fill.tooltip" />}
            disabled={popover.isVisible}
          >
            <div>
              <Label
                type={type}
              />
            </div>
          </Tooltip>
        </div>
      )}
    </PopupMenu>
  );
}

export default LabelOptions;
