/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button/Button';
import Translation from '../../components/Text/Translation';

const DuplicateWorkflowTemplateModal = ({ onDuplicate }) => (
  <Fragment>
    <h3><Translation value="workflow.structure.dialog.duplicate.title" /></h3>
    <p><Translation value="workflow.structure.dialog.duplicate.message" /></p>
    <br />
    <br />
    <ButtonGroup>
      <Button
        type="button"
        variant="outline"
        label={<Translation value="workflow.structure.dialog.duplicate.no" />}
        onClick={() => onDuplicate(false)}
      />
      <Button
        variant="primary"
        label={<Translation value="workflow.structure.dialog.duplicate.yes" />}
        onClick={() => onDuplicate(true)}
      />
    </ButtonGroup>
  </Fragment>
);

export default DuplicateWorkflowTemplateModal;
