/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import MobileProofHeaderCenterTunnel from './MobileProofHeaderCenterTunnel';
import css from './MobileProofHeaderCenter.scss';

function MobileProofHeaderCenter() {
  return (
    <div className={css.MobileProofHeaderCenter}>
      <MobileProofHeaderCenterTunnel.TunnelRenderer />
    </div>
  );
}

export default MobileProofHeaderCenter;
