/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import css from './OptionalDateTimePicker.scss';
import Tooltip from '../Tooltip';
import { Translation } from '../Text';
import { PopupMenu, Option, Separator } from '../PopupMenu';
import { useDateTimePicker } from '../../hooks/useDateTimePicker';
import FormatDate from '../Date/FormatDate';

const OptionalDateTimePicker = ({
  title,
  onChange,
  value,
  children,
}) => {
  const [open] = useDateTimePicker();
  const openDateTimePicker = () => open().then(onChange);

  if (!value) {
    return (
      <Tooltip
        title={title}
        up
        center
      >
        <button
          className={css.OptionalDateTimePicker__button}
          type="button"
          onClick={openDateTimePicker}
        >
          {children}
        </button>
      </Tooltip>
    );
  }

  const formattedDate = (
    <FormatDate
      date={value}
      formatTo="h:mma, Do MMMM YYYY"
    />
  );

  return (
    <PopupMenu
      maxWidth={300}
      options={
        <Fragment>
          <Option
            className={css.OptionalDateTimePicker}
            label={title}
            disabled
          />
          <Separator />
          <Option
            className={css.OptionalDateTimePicker}
            label={formattedDate}
            onClick={openDateTimePicker}
          />
          <Option
            className={css.OptionalDateTimePicker}
            label={<Translation value="option.remove" />}
            onClick={() => onChange(null)}
          />
        </Fragment>
      }
    >
      {popupMenu => (
        <div>
          <Tooltip
            title={
              <Translation
                value="workflow.step.due-date.active"
                params={{ dueDate: formattedDate }}
              />
            }
            up
            center
            disabled={popupMenu.isVisible}
          >
            <div>
              {children}
            </div>
          </Tooltip>
        </div>
      )}
    </PopupMenu>
  );
};

if (process.env.NODE_ENV !== 'production') {
  OptionalDateTimePicker.propTypes = {
    title: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.oneOf([null]),
    ]),
    children: PropTypes.node.isRequired,
  };
}

export default OptionalDateTimePicker;
