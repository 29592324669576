/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import classname from 'classname';

class ShyText extends Component {
  state = {
    text: null,
  };

  componentDidMount() {
    this.updateVisibility(this.props);
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateVisibility(nextProps);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  show = () => {
    this.setState({visible: true});
  }

  hide = () => {
    this.setState({visible: false});
  }

  updateVisibility(props) {
    const {children: text} = props;
    const {text: previousText} = this.state;
    if (previousText !== text) {
      this.setState({text});
      this.show();
      this.scheduleHide();
    }
  }

  scheduleHide() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.hide, this.props.delay);
  }

  render() {
    const {visible} = this.state;
    const {component, duration, delay, show, ...props} = this.props;
    const C = component || 'span';
    const style = {
      opacity: visible || show ? 1 : 0,
      transitionDuration: `${duration}ms`,
    };
    return (
      <C
        {...props}
        className={classname('ShyText', props.className)}
        style={{
          ...props.style,
          ...style,
        }}
      />
    );
  }
}

ShyText.defaultProps = {
  component: 'span',
  delay: 1000,
  duration: 250,
  show: false,
};

// ShyText.propTypes = {
//   component: PropTypes.oneOfType([
//     PropTypes.string,
//     Component,
//   ]),
// };

export default ShyText;
