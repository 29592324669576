/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { VideoPlayerButton } from './VideoPlayerButton';
import { Translation } from '../Text';

export const VideoPlayerLoopingButton = ({
  isLooping,
  setLooping,
}) => (
  <VideoPlayerButton
    tooltip={isLooping
      ? <Translation value="proof.utilities.loop.on" />
      : <Translation value="proof.utilities.loop.off" />
    }
    iconUrl="img/content/proof/icons/video/loop.svg"
    onClick={() => {
      setLooping(!isLooping);
    }}
    isActive={isLooping}
  />
);
