/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import Translation from '../../Text/Translation';
import Option from '../../PopupMenu/Option';
import ProgressCircle from '../../ProgressCircle';

class AttachmentDownloadOption extends Component {
  state = {
    isDownloading: false,
    downloadPercentage: 0,
    isDownloadComplete: false,
  };

  onDownloadProgressUpdate = (downloadPercentage) => {
    if (this.state.isDownloading) { // prevent updating when we're no longer downloading
      this.setState({downloadPercentage});
    }
  }

  handleClick = (event) => {
    const {onDownload} = this.props;
    const promise = onDownload(this.onDownloadProgressUpdate);
    if (promise) {
      this.setState({
        isDownloading: true,
        downloadPercentage: 0,
        isDownloadComplete: false,
      });
      promise
        .catch(() => {}) // ignore errors
        .then(() => {
          this.setState({
            isDownloading: false,
            isDownloadComplete: true,
          });
        });
      event.target.blur(); // prevent the option from staying highlighted
    }
  }

  render() {
    const {
      isDownloading,
      downloadPercentage,
      isDownloadComplete,
    } = this.state;
    return (
      <Option
        disabled={isDownloading}
        label={<Translation value="attachment.download" />}
        checked={
          isDownloading ||
          isDownloadComplete
        }
        checkedIcon={
          isDownloading &&
            <ProgressCircle
              size={20}
              width={3}
              color="#fff"
              trackColor="rgba(255, 255, 255, .2)"
              value={Math.max(2, downloadPercentage)} // start at 2%
            />
        }
        onClick={this.handleClick}
      />
    );
  }
}

export default AttachmentDownloadOption;
