/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Media from 'react-media';
import { Flex } from '../Flex';
import { RangeInput } from '../RangeInput';
import Button from '../Button/Button';
import css from './ZoomSlider.scss';

const ZoomSlider = ({
  action,
  zoomLevel,
  minZoomLevel,
  maxZoomLevel,
  onZoomChange,
  onReset,
}) => (
  <Media query="(min-width: 750px)">
    {matches => (!!matches &&
      <Flex
        container
        direction="horizontal"
        alignItems="center"
        justifyContent="center"
        gap={10}
        className={css.ZoomSlider}
      >
        <InlineSVG
          src="img/icons/material/symbols/zoom_in_FILL0_wght200_GRAD200_opsz20.svg"
          className={css.ZoomSlider__icon}
        />
        <div className={css.ZoomSlider__rangeInput}>
          <RangeInput
            value={zoomLevel}
            min={minZoomLevel}
            max={maxZoomLevel}
            step={0.05}
            onChange={value => onZoomChange(value)}
            tooltip={value => `${(value).toFixed(1)}x`}
          />
        </div>
        {action &&
          <Button
            variant="roundLightGreen"
            autoWidth
            onClick={() => onReset()}
            label={action}
          />
        }
      </Flex>
    )}
  </Media>
);

if (process.env.NODE_ENV !== 'production') {
  ZoomSlider.propTypes = {
    action: PropTypes.node,
    zoomLevel: PropTypes.number.isRequired,
    minZoomLevel: PropTypes.number.isRequired,
    maxZoomLevel: PropTypes.number.isRequired,
    onZoomChange: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
  };
}

export default ZoomSlider;
