/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Enum } from '@pageproof/sdk';
import ProofShareButton from '../../components/ProofShareButton';
import { HeaderTrayChild } from '../../components/HeaderTray';
import { Translation } from '../../components/Text';
import { useScreenSize } from '../../hooks/useScreenSize';
import WrapConditionally from '../../components/WrapConditionally';

function ProofShareButtonContainer({
  proofId,
  url,
  title,
  canSendShareLink,
  proofStatus,
}) {
  const { isSmallScreen } = useScreenSize();

  return (
    <WrapConditionally
      condition={isSmallScreen}
      wrapper={
        <HeaderTrayChild />
      }
    >
      <ProofShareButton
        proofId={proofId}
        url={url}
        title={title}
        direction={isSmallScreen ? 'down' : 'up'}
        canSendShareLink={canSendShareLink}
        heading={
          <Translation
            value={[Enum.ProofStatus.NEW, Enum.ProofStatus.PROOFING, Enum.ProofStatus.FINAL_APPROVING].includes(proofStatus)
              ? 'proof.utilities.share.heading.for-review'
              : 'proof.utilities.share.heading'
            }
          />
        }
      />
    </WrapConditionally>
  );
}

export default ProofShareButtonContainer;
