/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import MobileProofHeaderCenter from './MobileProofHeaderCenter';
import MobileProofHeaderCenterChild from './MobileProofHeaderCenterChild';

export {
  MobileProofHeaderCenter,
  MobileProofHeaderCenterChild,
};

export default MobileProofHeaderCenter;
