/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import DtPicker from 'material-ui-datetimepicker';
import DatePickerDialog from 'material-ui/DatePicker/DatePickerDialog';
import TimePickerDialog from 'material-ui/TimePicker/TimePickerDialog';
import { useI18n } from '../../hooks/useI18n';
import { useText } from '../Text';
import translateDateTimePickerDialog from './translateDateTimePickerDialog';

const DateTimePicker = forwardRef(({ onDismiss, onChange, children }, ref) => {
  const { locale } = useI18n();
  const text = useText(); // Material UI v0 is using `unstable_renderSubtreeIntoContainer` which have an issue(https://github.com/facebook/react/issues/16721) that does not transfer context, so not able to pass in a Translation component here.

  return (
    <label className="unstyled">
      <Media query="(max-width: 750px)">
        {matches => (
          <DtPicker
            ref={ref}
            onTimePickerDismiss={onDismiss}
            onDatePickerDismiss={onDismiss}
            disabled={false}
            underlineStyle={{ display: 'none' }}
            DatePicker={translateDateTimePickerDialog(DatePickerDialog)}
            TimePicker={translateDateTimePickerDialog(TimePickerDialog)}
            datePickerMode={matches ? 'portrait' : 'landscape'}
            clearIconStyle={{ display: 'none' }}
            onTimeSelected={onChange}
            minutesStep={5}
            readOnly
            textFieldStyle={{
              borderBottom: 'none',
              width: 0,
              height: 0,
            }}
            inputStyle={{
              color: 'transparent',
              float: 'right',
              zIndex: 1000,
              cursor: 'pointer',
            }}
            tabIndex="-1"
            DateTimeFormat={Intl.DateTimeFormat}
            locale={locale}
            okLabel={text('button.ok')}
            timePickerBodyStyle={{
              minHeight: '398px', // 398px is the minimum height of the time picker body before the clock starts to cut off
            }}
          />
        )}
      </Media>
      {children}
    </label>
  );
});

DateTimePicker.propTypes = {
  onDismiss: PropTypes.func,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default DateTimePicker;
