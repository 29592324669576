/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { useI18n } from '../../hooks/useI18n';

export default function withTranslations(InnerComponent) {
  const OuterComponent = (props) => {
    useI18n();
    return <InnerComponent {...props} />;
  };
  OuterComponent.displayName = `withTranslations(${InnerComponent.displayName || InnerComponent.name})`;
  return OuterComponent;
}
