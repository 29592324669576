/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './ProfileChangePassword';
import NewPasswordConfirmationFieldGroup from '../NewPasswordConfirmationFieldGroup';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button/Button';
import { Translation } from '../Text';
import PageSubheading from '../Page/Subheading';

const ProfileChangePassword = ({ onSubmit }) => {
  const [password, setPassword] = useState('');
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);

  const onPasswordChange = (passwordInput, isValid) => {
    setPassword(passwordInput);
    setEnableSubmitButton(isValid);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        onSubmit(password);
      }}
    >
      <PageSubheading
        title={<Translation value="change-password.header" />}
        description={<Translation value="change-password.description" />}
      />
      <br />
      <NewPasswordConfirmationFieldGroup onChange={onPasswordChange} />
      <div className={css.ProfileChangePassword__buttonWrapper}>
        <ButtonGroup align="center">
          <Button
            type="submit"
            className={css.ProfileChangePassword__submitChangeButton}
            label={<Translation value="change-password.button.change-password" />}
            disabled={!enableSubmitButton}
          />
        </ButtonGroup>
      </div>
    </form>
  );
};

if (process.env.NODE_ENV !== 'production') {
  ProfileChangePassword.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
}

export default ProfileChangePassword;
