/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import BrandingUploadFile from './BrandingUploadFile';
import EmailBrandingPreview from './EmailBrandingPreview';
import FormControl from '../../components/FormControl';
import Switch from '../../components/Switch';
import { Translation } from '../../components/Text';
import { InlineSpinner } from '../../components/Spinner';
import { Subheading } from '../../components/Page';
import { getDescription, validateFile } from '../../util/validation';
import BlockMessage from '../../components/BlockMessage';
import EmailColors from './EmailColors';

const constraints = {
  fileTypes: [
    'png',
    'jpeg',
    'jpg',
    'gif',
  ],
  maxWidth: 1200,
  height: 160,
  maxFileSize: 250e3,
};

const spinner = display => (
  display &&
  <InlineSpinner
    size={26}
    style={{ marginLeft: 20 }}
  />
);

function Email({
  uploadFile,
  toggleBranding,
  updateAlignment,
  updateEmailColors,
  email,
  emailFooter,
  emailColors,
  loading,
  restrictedFooterBanner,
  isEmailColorsFeatureEnabled,
}) {
  return (
    <div className="EmailBrandingSettings">
      <Subheading
        title={
          <span>
            <Translation value="domain-admin.menu.branding.email.banner-upload" />
            {spinner(loading.upload)}
          </span>
        }
        description={
          <Translation
            value="domain-admin.menu.branding.email.banner-must-be"
            params={{ description: getDescription(constraints) }}
          />
        }
      />
      <BrandingUploadFile
        title={<Translation value="domain-admin.menu.branding.email.banner-drag.header" />}
        onValidate={(file, callback) => {
          validateFile(file, constraints, callback);
        }}
        onSelect={(file) => {
          uploadFile(email.typeKey, file);
        }}
      />
      <BrandingUploadFile
        title={<Translation value="domain-admin.menu.branding.email.banner-drag.footer" />}
        onValidate={(file, callback) => {
          validateFile(file, constraints, callback);
        }}
        onSelect={(file) => {
          uploadFile(emailFooter.typeKey, file);
        }}
        disabled={restrictedFooterBanner}
      />
      <br />
      <Subheading
        title={
          <span>
            <Translation value="domain-admin.menu.branding.ready" />
            {spinner(loading.toggle)}
          </span>
        }
        description={
          <span>
            <Translation value="domain-admin.menu.branding.email.banner-turn-on" />
          </span>
        }
      />
      <FormControl compact>
        <Switch
          value={email.enabled}
          onChange={value => toggleBranding('email', value)}
        />
      </FormControl>
      <br />
      <Subheading
        title={
          <span>
            <Translation value="domain-admin.menu.branding.email.colors.title" />
            {spinner(loading.colors)}
          </span>
        }
        description={<Translation value="domain-admin.menu.branding.email.colors.description" />}
      />
      {isEmailColorsFeatureEnabled
        ? (
          <EmailColors
            titleTextColor={emailColors.titleTextColor}
            linkTextColor={emailColors.linkTextColor}
            buttonBackgroundColor={emailColors.buttonBackgroundColor}
            buttonTextColor={emailColors.buttonTextColor}
            onChange={updateEmailColors}
          />
        )
        : (
          <BlockMessage message={<Translation value="domain-admin.enterprise-only.message" />} />
        )
      }
      <br />
      {(email.exists || emailFooter.exists) &&
        <div>
          <Subheading
            title={
              <span>
                <Translation value="domain-admin.menu.branding.look" />
                {spinner(loading.alignment)}
              </span>
            }
            description={
              <span>
                <Translation value="domain-admin.menu.branding.email.preview" />
              </span>
            }
          />
          <EmailBrandingPreview
            email={email}
            emailFooter={emailFooter}
            updateAlignment={updateAlignment}
            titleTextColor={emailColors.titleTextColor}
            linkTextColor={emailColors.linkTextColor}
            buttonBackgroundColor={emailColors.buttonBackgroundColor}
            buttonTextColor={emailColors.buttonTextColor}
          />
        </div>
      }
      <br />
      <Translation
        className="EmailBrandingSettings__note"
        value="domain-admin.menu.branding.email.note"
      />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  const bannerPropTypes = PropTypes.shape({
    alignment: PropTypes.string,
    enabled: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    size: PropTypes.number,
    type: PropTypes.string,
    typeKey: PropTypes.string,
    url: PropTypes.string,
  });
  Email.propTypes = {
    uploadFile: PropTypes.func.isRequired,
    toggleBranding: PropTypes.func.isRequired,
    updateAlignment: PropTypes.func.isRequired,
    updateEmailColors: PropTypes.func.isRequired,
    email: bannerPropTypes,
    emailFooter: bannerPropTypes,
    emailColors: PropTypes.shape({
      titleTextColor: PropTypes.string,
      buttonBackgroundColor: PropTypes.string,
      buttonTextColor: PropTypes.string,
      linkTextColor: PropTypes.string,
    }),
    loading: PropTypes.shape({
      alignment: PropTypes.bool,
      colors: PropTypes.bool,
      tab: PropTypes.bool,
      toggle: PropTypes.bool,
      upload: PropTypes.bool,
    }),
    restrictedFooterBanner: PropTypes.bool,
    isEmailColorsFeatureEnabled: PropTypes.bool,
  };
}

export default Email;
