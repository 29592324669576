/* eslint-disable */

import React, { cloneElement, createRef, useEffect, useState } from 'react';

export default function WhenSticky({ children, ...props }) {
  const childRef = createRef();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const cachedRef = childRef.current;

    const observer = new IntersectionObserver(
      ([element]) => {
        setIsSticky(element.intersectionRatio < 1);
      },
      {
        rootMargin: '0px',
        threshold: [1],
      },
    );

    observer.observe(cachedRef);

    return () => {
      observer.unobserve(cachedRef);
    };
  }, []);

  return cloneElement(children, {
    ref: childRef,
    ...(isSticky ? props : {}),
  });
}
