/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { getUserPreferences } from '../../../hooks/useUserPreferences';
import { sdk } from '../../../util/sdk';
import getProof from './finalize/getProof';
import ensureCollection from './finalize/ensureCollection';
import ensureWorkflowTemplate from './finalize/ensureWorkflowTemplate';
import watchUploadProgress from './watchUploadProgress';
import isProofUploading from './finalize/isProofUploading';
import { deleteBlob } from './temporaryReferences';
import { createProgress } from './progress';
import { setFallbackCanDownload } from './canDownload';
import { getUserFeatures } from '../../../hooks/useUserFeatures';

export default function finalize(proofSetup, _userPreferences, onProgress) {
  const numberOfProofs = proofSetup.proofs.length;
  const { proofType, attachNewVersion, updateProof: _updateProof } = proofSetup.parameters;
  const isBrief = proofType === 'brief';

  let proofs;
  let userPreferences;
  let collection;
  let workflowTemplate;

  // task percentage code

  const progress = createProgress({ onProgress });

  progress.addTasks(3); // preparation steps

  if (
    proofSetup.shared.collection ||
    (proofSetup.parameters.attachNewVersion && proofSetup.parameters.attachNewVersion.collectionId) // TODO: PP-6808 remove this condition
  ) {
    progress.addTask(); // ready collection (creating collection is part of the preparation step)
  }

  progress.addTasks(numberOfProofs); // create proof (x number of proofs)

  // end task percentage code

  const prepare = Promise.all([
    Promise.resolve(_userPreferences || getUserPreferences()).then(progress.completeTask),
    ensureCollection(proofSetup).then(progress.completeTask),
    ensureWorkflowTemplate(proofSetup).then(progress.completeTask),
  ]).then((prepared) => {
    ([userPreferences, collection, workflowTemplate] = prepared);
  });

  const files = Promise.all(proofSetup.proofs.map((proof) => {
    if (isProofUploading(proof)) {
      const updateProgressTask = progress.addProgressTask();
      return watchUploadProgress(proof._id, percent => updateProgressTask(percent)).then(() => updateProgressTask(100));
    }
    return Promise.resolve();
  }));

  proofSetup.proofs.forEach((proof) => {
    if (proof.file) {
      if (proof.file.fileReference) {
        deleteBlob(proof.file.fileReference);
      }
      if (proof.file.thumbnailReference) {
        deleteBlob(proof.file.thumbnailReference);
      }
    }
  });

  return prepare
    .then(() => Promise.all(
      proofSetup.proofs.map((sourceProof) => {
        const proof = getProof(sourceProof, proofSetup.shared, proofSetup.parameters, userPreferences);

        let task;
        const previousProof = attachNewVersion ? { id: attachNewVersion.proofId } : undefined;

        if (_updateProof) {
          if (isBrief) {
            task = updateBrief(proof);
          } else {
            task = updateProof(proof, proofSetup.owners, workflowTemplate, proofSetup.checklist, proofSetup.currentProofTemplateId, collection);
          }
        } else {
          task = createProof(proof, proofSetup.owners, collection, workflowTemplate, previousProof, proofSetup.checklist, proofSetup.currentProofTemplateId);

          if (numberOfProofs === 1) {
            onCreateSingleProof(proof);
          }
        }

        return task.then(progress.completeTask);
      })
    ))
    .then((resolvedProofs) => {
      proofs = resolvedProofs;
      // If we have a collection, mark the collection as ready, so the proofs can start
      return (collection && collection.id) && sdk.proofs.groups.ready(collection.id)
        .then(progress.completeTask, progress.completeTask);
    })
    .then(() => (
      // If we have a temporary workflow template, time to clean that up
      (workflowTemplate && !_updateProof) && sdk.workflows.delete(workflowTemplate.id)
    ))
    .then(() => files) // wait for the files to finish uploading
    .catch((err) => {
      console.error(err);
      window.Bugsnag.notifyException(err);
      // window.alert(err.stack || err.toString()); // TODO remove in production (debug code only)
    })
    .then(() => ({ collection, proofs }));
}

function createProof(proof, owners, collection, workflowTemplate, previousProof, checklist, proofTemplateId) {
  return sdk.proofs.create({
    name: proof.name,
    tags: proof.tags,
    dueDate: proof.dueDate ? new Date(proof.dueDate) : undefined,
    messageToReviewers: proof.messageToReviewers,
    file: proof.file ? { id: proof.file.id } : undefined,
    group: collection,
    reference: proof.reference,
    integrationReferences: proof.integrationReferences,
    reminders: proof.reminders,
    previousProof,
    canDownload: proof.canDownload,
    commentVisibility: proof.commentVisibility,
    isPublic: getUserFeatures(false).hasPublicProofs && proof.isPublic,
    workflowTemplate,
    checklist,
    proofTemplate: proofTemplateId ? { id: proofTemplateId } : undefined,
    owners: owners.map(email => ({ email })),
    start: !!workflowTemplate,
  });
}

function updateBrief(brief) {
  return sdk.briefs.update({
    briefId: brief.id,
    data: {
      title: brief.name,
      dueDate: brief.dueDate ? new Date(brief.dueDate) : undefined,
      tags: brief.tags,
      recipient: brief.recipient,
      messageToRecipient: brief.messageToRecipient,
      reference: brief.reference,
      integrationReferences: brief.integrationReferences,
      isPublic: brief.isPublic,
    },
    start: true,
  });
}

function updateProof(proof, owners, workflow, checklist, proofTemplateId, collection) {
  return sdk.proofs.update({
    id: proof.id,
    name: proof.name,
    tags: proof.tags,
    dueDate: proof.dueDate ? new Date(proof.dueDate) : undefined,
    messageToReviewers: proof.messageToReviewers,
    file: { id: proof.file.id },
    group: collection,
    reference: proof.reference,
    integrationReferences: proof.integrationReferences,
    checklist,
    reminders: proof.reminders,
    canDownload: proof.canDownload,
    commentVisibility: proof.commentVisibility,
    isPublic: proof.isPublic,
    workflow,
    proofTemplate: proofTemplateId ? { id: proofTemplateId } : undefined,
    owners: owners.map(email => ({ email })),
    start: !!workflow,
  });
}

function onCreateSingleProof(proof) {
  setFallbackCanDownload(proof.canDownload);
}
