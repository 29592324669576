/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import { Subheading } from '../Page';
import { EmailPreferences } from '../EmailPreferences';
import css from './ProfileNotifications';

const ProfileNotifications = ({
  emailPreferences,
  onUpdateEmailPreference,
  generalPreferences,
  ownerPreferences,
  criticalPreferences,
}) => (
  <div className={css.ProfileNotifications}>
    <div className={css.ProfileNotifications__description}>
      <Translation value="profile.notify-me.description" />
    </div>
    <Subheading title={<Translation value="profile.notify-me.general" />} />
    <EmailPreferences
      preferences={generalPreferences}
      emailPreferences={emailPreferences}
      onUpdateEmailPreference={onUpdateEmailPreference}
    />
    <Subheading title={<Translation value="profile.notify-me.general.owner" />} />
    <EmailPreferences
      preferences={ownerPreferences}
      emailPreferences={emailPreferences}
      onUpdateEmailPreference={onUpdateEmailPreference}
    />
    <Subheading
      title={<Translation value="profile.notify-me.critical" />}
      helpMessage={<Translation value="profile.notify-me.critical.tooltip" />}
    />
    <EmailPreferences
      preferences={criticalPreferences}
      emailPreferences={emailPreferences}
      onUpdateEmailPreference={onUpdateEmailPreference}
    />
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  ProfileNotifications.propTypes = {
    emailPreferences: PropTypes.objectOf(
      PropTypes.oneOfType(
        PropTypes.number,
        PropTypes.bool,
      )
    ).isRequired,
    onUpdateEmailPreference: PropTypes.func.isRequired,
    generalPreferences: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
    ownerPreferences: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
    criticalPreferences: PropTypes.arrayOf(PropTypes.objectOf(
      PropTypes.oneOfType(
        PropTypes.string,
        PropTypes.bool,
      )
    )).isRequired,
  };
}

export default ProfileNotifications;
