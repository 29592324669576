/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';

function Link({
  path,
  search,
  ignore,
  onClick,
  children,
  ...props,
}) {
  const {
    $location,
    $rootScope,
  } = window.__pageproof_bridge__;
  return (
    // eslint-disable-next-line
    <a
      {...props}
      onClick={(event) => {
        event.preventDefault();
        if (path) {
          $location.path(path);
        }
        if (search) {
          $location.search(search);
        }
        if (ignore) {
          $location.ignore();
        }
        $rootScope.$apply();
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {children}
    </a>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Link.propTypes = {
    path: PropTypes.string,
    search: PropTypes.object, // eslint-disable-line
    ignore: PropTypes.bool,
    onClick: PropTypes.func,
  };
}

export default Link;
