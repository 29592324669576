/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
* Unauthorized copying of this file, via any medium is strictly prohibited.
* Proprietary and confidential.
*/
import React from 'react';
import { Translation } from '../../../components/Text';
import { IconButton } from './IconButton';

export const FavoriteButton = ({ isFavorite, onClick }) => (
  <div style={{ position: 'absolute', top: 8, right: 24 }}>
    <IconButton
      title={
        <Translation
          value={isFavorite
            ? 'workflow.dashboard.unfavorite'
            : 'workflow.dashboard.favorite'
          }
        />
      }
      iconUrl="img/icons/agree-outline.svg"
      activeIconUrl="img/icons/agree.svg"
      activeColor="#f6a500"
      activeColorHover="#dd9400"
      isActive={isFavorite}
      onClick={onClick}
    />
  </div>
);
