/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment, Component} from 'react';
import {hasValue} from '../Editor/serialization';
import Reply from '.';
import { KeepVirtualListItemMounted } from '../../../containers/CommentsPane/components/KeepVirtualListItemMounted';

class ReplyContainer extends Component {
  state = {
    isEditing: false,
    showMetadata: false,
    editingValue: null,
  };

  onEdit = (isEditing) => {
    this.setState(() => ({
      isEditing,
      editingValue: this.props.value,
    }));
  }

  onChange = (value) => {
    this.setState(() => ({
      editingValue: value,
    }));
  }

  onSave = (value) => {
    this.setState({
      isEditing: false,
    });
    if (this.props.value !== value) {
      this.props.onSave(value);
    }
  }

  onToggleMetadata = () => {
    this.setState(({showMetadata}) => ({
      showMetadata: !showMetadata,
    }));
  }

  render() {
    const value = this.state.isEditing
      ? this.state.editingValue
      : this.props.value;
    return (
      <Fragment>
        {this.state.isEditing && <KeepVirtualListItemMounted />}
        <Reply
          {...this.props}
          {...this.state}
          value={value}
          hasUnsavedChanges={
            this.state.isEditing &&
            this.state.editingValue !== this.props.value
          }
          canSave={hasValue(value)}
          onEdit={this.onEdit}
          onChange={this.onChange}
          onSave={this.onSave}
          onToggleMetadata={this.onToggleMetadata}
        />
      </Fragment>
    );
  }
}

export default ReplyContainer;
