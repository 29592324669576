/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'Send',
  src: 'img/interface/send.svg',
  activeColor: '#138b3b',
  activeHoverColor: '#0d5e28',
  defaultSize: 24,
});
