/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect, useState } from 'react';
import { useLocalStorage } from '../components/LocalStorage';
import { sdk } from '../util/sdk';
import { gql } from '../util/gql/tag';

let getTeamMembersResolver;
let getTeamMembersPromise;

const query = gql`
  query ppxapp_getTeamUserEmails($states: [TeamMemberState!]) {
    me {
      id
      team {
        id
        members(states: $states){
          user {
            email
            name
          }
        }
      }
    }
  }
`;

const loadTeamMembers = () => {
  sdk.graphql(query.toString(), { states: ['active'] }, { throwOnError: false })
    .then((result) => {
      getTeamMembersResolver(result.data.me.team.members.map(member => member.user));
    });
};


export const getTeamMembers = () => {
  if (!getTeamMembersPromise) {
    getTeamMembersPromise = new Promise((resolve) => {
      getTeamMembersResolver = resolve;
    });

    loadTeamMembers();
  }

  return getTeamMembersPromise;
};

const useTeamMemberSuggestions = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  const [userData] = useLocalStorage('pageproof.app.userData', null);
  const suggestionsIncludeTeamMembers = userData && userData.features && userData.features.suggestionsIncludeTeamMembers;

  useEffect(() => {
    if (!suggestionsIncludeTeamMembers) {
      setTeamMembers([]);
      return;
    }

    getTeamMembers().then((members) => {
      setTeamMembers(members.map(user => ({ email: user.email, name: user.name || null })));
    });
  }, [suggestionsIncludeTeamMembers]);

  return teamMembers;
};

export default useTeamMemberSuggestions;
