/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';

function Timecode({
  value,
  detailed,
  framesPerSecond,
}) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: window.generalfunctions_getTimecode(value, detailed, framesPerSecond),
      }}
    />
  );
}

Timecode.prototype.isPureReactComponent = true;

export default Timecode;
