/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable no-param-reassign */

import { sdk } from '../../../util/sdk';
import { updateProofSetup, updateProofSetupProof, getProofSetupProof } from './proofSetup';
import { retrieveBlob, deleteBlob } from './temporaryReferences';
import fakeProgress from './fakeProgress';

const getUploadProgressKey = id => 'pageproof.app.upload-progress.' + id;

const localFileQueue = [];
let localFileQueueTimer = null;

export function startLocalFileUpload(proofId, blob, name, fileTypeOverride, assignedTo) {
  localFileQueue.push({
    proofId,
    contents: blob,
    name,
    fileTypeOverride,
    assignedTo,
  });
  if (localFileQueueTimer) {
    clearTimeout(localFileQueueTimer);
  }
  localFileQueueTimer = setTimeout(() => {
    const items = [...localFileQueue];
    localFileQueue.length = 0;

    sdk.files.upload(items.map(item => ({
      name: item.name,
      contents: item.contents,
      fileTypeOverride: item.fileTypeOverride,
      assignedTo: item.assignedTo,
      onProgress(percent) {
        const uploadProgressKey = getUploadProgressKey(item.proofId);
        if (percent === 100) {
          window.localStorage.removeItem(uploadProgressKey);
          updateProofSetupProof(item.proofId, (proof) => {
            if (proof && proof.file && proof.file.id === item.fileId) {
              proof.file.status = 'processing';
              if (proof.file.fileReference) {
                deleteBlob(proof.file.fileReference);
                delete proof.file.fileReference;
              }
            }
          });
        } else {
          window.localStorage.setItem(uploadProgressKey, JSON.stringify({ percent }));
        }
      },
      async: true,
    })))
      .then((files) => {
        updateProofSetup((proofSetup) => {
          files.forEach((file, index) => {
            const item = items[index];
            item.fileId = file.id; // copy across the file id

            proofSetup.proofs.forEach((proof) => {
              if (proof._id === item.proofId) {
                proof.file.id = file.id;
                proof.file.status = 'uploading';
              }
            });
          });
        });
      });
  });
}

export function createWebURLFile(proofId, url, basicAuth) {
  const { ImportProvider } = window.__pageproof_quark__.sdk.Enum;
  importFile(proofId, ImportProvider.WEBSITE, url, undefined, basicAuth);
}

export function importExternalFile(proofId, provider, url, name, bearerToken) {
  importFile(proofId, provider, url, name, bearerToken);
}

function importFile(proofId, provider, url, name, auth) {
  const { ImportProvider } = window.__pageproof_quark__.sdk.Enum;

  updateProofSetupProof(proofId, (proof) => {
    if (proof && proof.file) {
      proof.file.status = 'uploading';
    }
  });

  if (provider === ImportProvider.WEBSITE && auth) {
    url = window.generalfunctions_setUriCredentials(url, auth.username, auth.password);
    auth = undefined;
  }

  const promise = sdk.files.import({
    provider,
    url,
    auth,
    name,
  }).then(
    (file) => {
      updateProofSetupProof(proofId, (proof) => {
        if (proof && proof.file) {
          proof.file.id = file.id;
          proof.file.status = 'processing';
        }
      });
    },
    (err) => {
      updateProofSetupProof(proofId, (proof) => {
        if (proof && proof.file) {
          proof.file.status = 'error';
        }
      });
      console.error(err);
    }
  );

  const uploadProgressKey = getUploadProgressKey(proofId);

  fakeProgress(promise, 400, 95, (percent) => {
    window.localStorage.setItem(uploadProgressKey, JSON.stringify({ percent }));
  }).then(() => {
    window.localStorage.removeItem(uploadProgressKey);
  });
}

export function createEmailFile(proofId) {
  updateProofSetupProof(proofId, (proof) => {
    if (proof && proof.file) {
      proof.file.status = 'uploading';
    }
  });

  const promise = sdk.files.email().then(
    (file) => {
      updateProofSetupProof(proofId, (proof) => {
        if (proof && proof.file) {
          proof.file.id = file.id;
          proof.file.emailAddress = file.emailAddress;
          proof.file.status = 'ready';
        }
      });
    },
    (err) => {
      updateProofSetupProof(proofId, (proof) => {
        if (proof && proof.file) {
          proof.file.status = 'error';
        }
      });
      console.error(err);
    }
  );

  const uploadProgressKey = getUploadProgressKey(proofId);

  fakeProgress(promise, 150, 95, (percent) => {
    window.localStorage.setItem(uploadProgressKey, JSON.stringify({ percent }));
  }).then(() => {
    window.localStorage.removeItem(uploadProgressKey);
  });
}

export function canRetry(file) {
  if (!file) {
    return false;
  }
  if (file.type === 'local') {
    // To retry a local file type, we need the original file (stored locally)
    return typeof file.fileReference === 'string';
  }
  return (
    file.status === 'uploading' ||
    file.status === 'preparing'
  );
}

/* eslint-disable indent */
export function retry(proofId) {
  getProofSetupProof(proofId, (proof) => {
    const { file } = proof;
    switch (file.type) {
      case 'local': {
        if (file.fileReference) {
          retrieveBlob(file.fileReference).then((blob) => {
            startLocalFileUpload(proofId, blob, file.name);
          });
        }
        break;
      }
      case 'web-url': {
        createWebURLFile(proofId, file.url, file.basicAuth);
        break;
      }
      case 'external': {
        importExternalFile(proofId, file.provider, file.url, file.name, file.bearerToken);
        break;
      }
      case 'email': {
        createEmailFile(proofId);
        break;
      }
      default: {
        break;
      }
    }
  });
}
/* eslint-enable indent */
