/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { sdk } from '../../../util/sdk';

const newVersionPreferredProofNameStorageKey = 'pageproof.app.new-version-preferred-proof-name.';

export const NEW_VERSION_PREFERRED_PROOF_NAME_TYPES = {
  filename: 'new_version_preferred_proof_name:filename',
};

export function getNewVersionPreferredProofName() {
  const { userId } = sdk.session;
  return window.localStorage.getItem(newVersionPreferredProofNameStorageKey + userId);
}

export function setNewVersionPreferredProofName(preferred) {
  const { userId } = sdk.session;
  return window.localStorage.setItem(newVersionPreferredProofNameStorageKey + userId, preferred);
}
