/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { S_BREAK_M } from '../../util/constants';
import NextAvatar from '../../containers/NextAvatar';
import Tooltip from '../Tooltip';
import css from './HeaderAvatar.scss';

const HeaderAvatar = ({
  userId,
  userName,
  userEmail,
  userTeamName,
}) => (
  <Tooltip
    title={() => (
      <Fragment>
        <div>{userName}</div>
        <div>{userEmail}</div>
        <div>{userTeamName}</div>
      </Fragment>
    )}
    down
    left
    disabled={!userName && !userEmail && !userTeamName}
  >
    <div className={css.HeaderAvatar}>
      <Media query={`(max-width: ${S_BREAK_M}px)`}>
        {matches => (
          <NextAvatar
            id={userId}
            size={matches ? 40 : 60}
            disableStatus
          />
        )}
      </Media>
    </div>
  </Tooltip>
);

if (process.env.NODE_ENV !== 'production') {
  HeaderAvatar.propTypes = {
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string,
    userEmail: PropTypes.string.isRequired,
    userTeamName: PropTypes.string,
  };
}

export default HeaderAvatar;
