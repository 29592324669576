import React from 'react';
import classname from 'classname';
import css from './GenericFileThumbnail.scss';

const scalingFactor = {
  1: 0.456,
  2: 0.34,
  3: 0.321,
  4: 0.27,
  5: 0.225,
};

const GenericFileThumbnail = ({ size, type }) => {
  const trimmedType = type.substring(0, 5);
  let fontSize = Math.floor(size * (scalingFactor[trimmedType.length] || scalingFactor[3]));
  if (size % 2 !== fontSize % 2) {
    fontSize += 1;
  }
  return (
    <div
      className={classname(css.GenericFileThumbnail, css['GenericFileThumbnail--' + type.toLowerCase()])}
      style={{
        width: size,
        height: size,
        fontSize,
        borderWidth: Math.ceil(size * 0.0456),
      }}
    >
      {trimmedType}
    </div>
  );
};

export default GenericFileThumbnail;
