/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

function createTransitionComponent(styles) {
  return ({children}) => (
    <CSSTransitionGroup
      component="div"
      transitionName={styles}
      transitionEnterTimeout={200}
      transitionLeaveTimeout={200}
    >
      {children}
    </CSSTransitionGroup>
  );
}

export {
  createTransitionComponent,
};
