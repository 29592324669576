/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import { PopupMenu, Option, ConfirmActionOption, Separator } from '../../../components/PopupMenu';
import css from './ItemListInput.scss';

export function ItemListInput({ onConfigurationOptionInput, option }) {
  const onClickRemoveOption = (id) => {
    const updateValue = {
      action: 'remove',
      value: {
        id,
      },
    };
    const tempValue = option.value.filter(item => item.id !== id);
    onConfigurationOptionInput(option.key, updateValue, tempValue);
  };

  if (!option.value || !option.value.length) {
    return (
      <div className={css.NoItems}>
        {option.noItemsMessage || 'None'}
      </div>
    );
  }

  return (
    <ul className={css.ItemList}>
      {option.value.map(item => (
        <li className={css.ItemList__item}>
          <a
            className={css.ItemList__item__link}
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.label}
          </a>
          <PopupMenu
            options={[
              <Option
                className={css.ItemList__item__removeWarning}
                label={item.removeWarning}
                disabled
              />,
              <Separator />,
              <ConfirmActionOption
                onClick={() => onClickRemoveOption(item.id)}
                iconSrc="img/content/proof/icons/delete.svg"
                label="Remove"
                confirmLabel="Are you sure?"
              />,
            ]}
          >
            <div className={css.ItemList__item__icon}>
              <InlineSVG
                src="img/interface/close.svg"
              />
            </div>
          </PopupMenu>
        </li>
      ))}
    </ul>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ItemListInput.propTypes = {
    onConfigurationOptionInput: PropTypes.func.isRequired,
    option: PropTypes.objectOf(PropTypes.any).isRequired,
  };
}

export default ItemListInput;
