/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState, useEffect } from 'react';
import { VideoPlayerTimelineComment } from './timeline/VideoPlayerTimelineComment';

export const VideoPlayerTimeline = ({
  currentTime,
  durationTime,
  comments,
  timePresentation,
  getPinColor,
  canUpdateComment,
  scrub,
  scrubEnd,
  selectedCommentId,
  selectComment,
  updatePin,
  rootRef,
}) => {
  const [selectedPin, setSelectedPin] = useState({ commentId: null, pinIndex: -1 });
  useEffect(() => {
    if (selectedPin.commentId !== selectedCommentId) {
      setSelectedPin({ commentId: selectedCommentId, pinIndex: -1 });
    }
  }, [selectedCommentId]);

  return (
    <Fragment>
      {comments.map(comment => (
        <VideoPlayerTimelineComment
          key={comment.id}
          currentTime={currentTime}
          durationTime={durationTime}
          comment={comment}
          timePresentation={timePresentation}
          getPinColor={getPinColor}
          selectedPinIndex={comment.id === selectedCommentId ? selectedPin.pinIndex : null}
          selectedCommentId={selectedCommentId}
          rootRef={rootRef}
          scrub={scrub}
          scrubEnd={scrubEnd}
          selectComment={(commentId, pinIndex) => {
            selectComment(commentId, pinIndex);
            setSelectedPin({ commentId, pinIndex });
          }}
          canUpdate={canUpdateComment(comment)}
          updatePin={updatePin}
        />
      ))}
    </Fragment>
  );
};
