import React, {Component} from 'react';
import moment from 'moment';

class TimeRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.getText(),
    };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      +this.props.fromDate !== +nextProps.fromDate ||
      +this.props.toDate !== +nextProps.toDate
    ) {
      this.setState({
        text: this.getText(),
      });
    }
  }

  getText() {
    return moment(this.props.fromDate).from(this.props.toDate, true);
  }

  render() {
    return (
      <span>{this.state.text}</span>
    );
  }
}

export default TimeRange;
