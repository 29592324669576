/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useRef, useState } from 'react';
import Suggestions from '../../components/Suggestions';

/**
 * This is a React component that acts as an Angular input but with the React suggestions component.
 *
 * It was done this way because the upgrade is needed NOW and it avoids having to rewrite a bunch of Angular code.
 *
 * @deprecated
 */
const LegacyUserSuggestionsInput = ({
  onChange,
  ngModelObject,
  ngModelKey,
  inputAttributes,
  isControlled,
  onEnter,
  onSelect,
}) => {
  const { $rootScope } = window.__pageproof_bridge__;

  const ngValue = ngModelObject[ngModelKey];
  const lastNgModelValueSyncedRef = useRef(ngValue);

  const setNgValue = (updatedValue) => {
    lastNgModelValueSyncedRef.current = updatedValue;
    // eslint-disable-next-line no-param-reassign
    ngModelObject[ngModelKey] = updatedValue;

    if (!$rootScope.$$phase) {
      $rootScope.$apply();
    }

    if (onChange) {
      onChange(updatedValue);
    }
  };

  const [_value, _setValue] = useState(ngValue || '');

  const value = isControlled ? ngValue : _value;
  const setValue = isControlled ? setNgValue : _setValue;

  const inputRef = useRef();

  if (!isControlled && lastNgModelValueSyncedRef.current !== ngValue) {
    lastNgModelValueSyncedRef.current = ngValue;
    setValue(ngValue);
  }

  const onSubmit = (email) => {
    setNgValue(email);

    if (onSelect) {
      onSelect(email);

      if (!$rootScope.$$phase) {
        $rootScope.$apply();
      }
    }
  };

  return (
    <Suggestions
      onSelect={(selectedValue) => {
        setValue(selectedValue);
        // This is needed because some of hte old Angular code immediately reads the value from the input element.
        inputRef.current.value = selectedValue;

        // inputRef.current.blur(); this breaks update approver in info pane, but I don't think it's needed anyway coz of onSubmit
        onSubmit(selectedValue);
      }}
      inputId={inputAttributes.id}
      inputRef={inputRef}
    >
      <input
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onEnter) {
            onEnter(value);

            if (!$rootScope.$$phase) {
              $rootScope.$apply();
            }
          }
        }}
        value={value}
        onBlur={() => onSubmit(value)}
        {...inputAttributes}
      />
    </Suggestions>
  );
};

export default LegacyUserSuggestionsInput;
