/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component, Fragment, createElement} from 'react';
import PropTypes from 'prop-types';
import shallowDiffers from '../../util/shallow-differs';
import { useI18n } from '../../hooks/useI18n';
import { getCurrentTranslations } from '../I18n/getCurrentTranslations';

// TODO When React gains support for dom fragments, this function will have full support for HTML content
// until then, HTML content cannot wrap around a dynamic template value. This is currently the only major
// limitation - excluding Angular filter support (for obvious reasons).
function buildChildren(template, params, plainText) {
  // console.time('Translation.buildChildren');
  let missingParams = 0;
  const children = [];
  let buffer = '';
  let index = 0;

  function end() {
    if (buffer.length) {
      const richBuffer = buffer.indexOf('<') !== -1; // naive
      children.push(
        (plainText || !richBuffer)
          ? buffer
          : <span
            key={index}
            dangerouslySetInnerHTML={{__html: buffer}}
          />
      );
      buffer = '';
    }
  }

  let cursor = 0;
  while (cursor < template.length) {
    const char = template[cursor];
    switch (char) {
    case '{': {
      const nextChar = template[cursor + 1];
      if (nextChar === '{') {
        if (buffer.length) {
          end();
          index += 1;
        }
        const match = template.substring(cursor, template.indexOf('}}', cursor));
        const symbol = match.substring(2).trim(); // remove leading "{{" and remove whitespace
        const found = typeof params[symbol] !== 'undefined';
        if (!found) missingParams += 1;
        const value = found ? params[symbol] : symbol;
        children.push(
          plainText
            ? value
            : (
              <Fragment key={index}>
                {value}
              </Fragment>
            )
        );
        cursor += match.length + 2;
        index += 1;
      } else if (nextChar === '}') {
        if (buffer.length) {
          end();
          index += 1;
        }
        const found = typeof params._ !== 'undefined';
        if (!found) missingParams += 1;
        children.push(
          <Fragment key={index}>
            {params._}
          </Fragment>
        );
        cursor += 2;
        index += 1;
      } else {
        buffer += char;
        cursor += 1;
      }
      break;
    }
    default: {
      buffer += char;
      cursor += 1;
      break;
    }
    }
  }
  if (buffer.length) {
    end();
  }
  if (process.env.NODE_ENV === 'development' && missingParams) {
    console.warn('There are missing parameters in the template.', template, params);
  }
  // console.timeEnd('Translation.buildChildren');
  return children;
}

class Translation extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.value !== nextProps.value ||
      shallowDiffers(this.props.params, nextProps.params)
    ) {
      return true;
    }
    return false;
  }

  render() {
    return <Renderer {...this.props} />;
  }
}

const Renderer = ({ value: key, params, className, naked }) => {
  const { translations } = useI18n();
  const value = typeof key === 'function' ? key() : key;
  const template = typeof translations[value] === 'string' ? translations[value] : value;
  if (!template) return null;
  return createElement(
    naked && !className ? Fragment : 'span',
    naked && !className ? {} : { className },
    buildChildren(template, params, false),
  );
};

Translation.text = (value, params, _translations) => {
  const translations = _translations || getCurrentTranslations();
  const template = typeof translations[value] === 'string' ? translations[value] : value;
  return buildChildren(template, params, true).join('');
};

Translation.defaultProps = {
  params: {},
  naked: true,
};

if (process.env.NODE_ENV !== 'production') {
  Translation.propTypes = {
    value: PropTypes.oneOf(PropTypes.string, PropTypes.func).isRequired,
    params: PropTypes.object, // eslint-disable-line
    className: PropTypes.string,
    naked: PropTypes.bool,
  };
}

export default Translation;
