/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './RemoveMFA.scss';
import { Translation } from '../Text';
import { sdk } from '../../util/sdk';
import Reveal from '../Reveal';
import Message from '../UnifiedLogin/components/Message';
import Input from '../Input';
import ButtonGroup from '../ButtonGroup';
import { Button } from '../Button';

const RemoveMFA = ({ onComplete }) => {
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessageTranslationKey, setErrorMessageTranslationKey] = useState(null);

  const onSubmit = () => {
    setIsDisabled(true);
    sdk.accounts.mfa.remove(password)
      .then(() => onComplete())
      .catch(() => {
        setIsDisabled(false);
        setErrorMessageTranslationKey('remove.mfa.error.invalid-code');
      });
  };

  return (
    <form
      className={css.RemoveMFA}
      onSubmit={(event) => {
        event.preventDefault();

        onSubmit();
      }}
    >
      <h1><Translation value="remove.mfa.header" /></h1>
      <p><Translation value="remove.mfa.description" /></p>
      <Input
        variant="unified"
        type="password"
        value={password}
        onChange={setPassword}
        disabled={isDisabled}
        autoFocus
        autoComplete="new-password"
      />
      <ButtonGroup>
        <Button
          type="submit"
          variant="orange"
          label={<Translation value="remove.mfa.button" />}
          className={css.RemoveMFA__button}
        />
      </ButtonGroup>
      <Reveal
        align="bottom"
        duration={300}
        visible={errorMessageTranslationKey}
      >
        <div className={css.RemoveMFA__errorMessage}>
          <Message
            translation={errorMessageTranslationKey}
          />
        </div>
      </Reveal>
    </form>
  );
};

if (process.env.NODE_ENV !== 'production') {
  RemoveMFA.propTypes = {
    onComplete: PropTypes.func.isRequired,
  };
}

export default RemoveMFA;
