/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classnames from 'classnames';
import Ellipsis from '../Text/Ellipsis';
import {Translation} from '../Text';
import {
  PopupMenu,
  Option,
  Separator,
} from '../PopupMenu';
import styles from './LockStyles';
import Tooltip from '../Tooltip';

class Lock extends Component {
  state = {
    isClicked: false,
    isConfirming: false,
  };

  updateClick = () => {
    this.setState({
      isClicked: true,
    });
  }

  setIsConfirming = (isConfirming) => {
    this.setState({ isConfirming });
  }

  getUnlockedTooltipTranslationValue = () => {
    const { isRequestPending, hasRequested, canUnlock } = this.props;
    if (canUnlock) {
      return 'lock-tooltip.unlock-proof';
    }

    if (isRequestPending || hasRequested) {
      return 'lock-tooltip.unlock-requested';
    }

    return 'lock-tooltip.request-unlock';
  }

  getUnlockedPopupMenuHeaderTranslationValue = () => {
    const { canUnlock, isRequestPending } = this.props;
    if (isRequestPending) {
      return 'lock-tooltip.unlock-requested.heading';
    }

    if (canUnlock) {
      return 'lock-tooltip.unlock-proof.heading';
    }

    return 'lock-tooltip.request-unlock.heading';
  }

  render() {
    const {
      isLocked,
      isLockVisible,
      canUnlock,
      canRequest,
      hasRequested,
      isRequestPending,
      onLock,
      onUnlock,
      onUnlockRequest,
    } = this.props;

    const { isClicked, isConfirming } = this.state;

    return (
      <div className={styles.Lock}>
        <div className={styles.Lock__container}>
          {isLockVisible && !isLocked &&
            <PopupMenu
              up
              maxWidth={150}
              onShow={() => this.setIsConfirming(true)}
              onHide={() => this.setIsConfirming(false)}
              options={menu => (
                <Fragment>
                  <Option
                    label={<Translation value="lock-tooltip.lock-proof.heading" />}
                    disabled
                  />
                  <Separator />
                  <Option
                    onClick={() => {
                      this.updateClick();
                      onLock()
                        .then(menu.hide);
                      return false;
                    }}
                    iconSrc="img/interface/padlock.svg"
                    label={
                      <Fragment>
                        <Translation
                          value={isClicked
                            ? 'lock-tooltip.lock-proof.active'
                            : 'lock-tooltip.confirm'
                          }
                        />
                        {!isClicked &&
                          <InlineSVG
                            src="img/interface/padlock.svg"
                            className={styles.Lock__container__confirmIcon}
                          />
                        }
                        &nbsp;
                        {isClicked && <Ellipsis />}
                      </Fragment>
                    }
                  />
                </Fragment>
              )}
            >
              <div>
                <Tooltip
                  up
                  center
                  disablePointerEvents
                  title={<Translation value="lock-tooltip.lock-proof" />}
                  disabled={isConfirming}
                >
                  <div className={styles.Lock__container__lockIcon}>
                    <InlineSVG src="img/interface/unlock.svg" />
                  </div>
                </Tooltip>
              </div>
            </PopupMenu>
          }
          {isLocked &&
            <PopupMenu
              up
              arrow={!hasRequested}
              maxWidth={150}
              onShow={() => this.setIsConfirming(true)}
              onHide={() => this.setIsConfirming(false)}
              disabled={!canUnlock && (hasRequested || isRequestPending)}
              options={menu => (
                <Fragment>
                  <Option
                    label={<Translation value={this.getUnlockedPopupMenuHeaderTranslationValue()} />}
                    disabled
                  />
                  <Separator />
                  {canRequest &&
                    <Option
                      onClick={() => {
                        this.updateClick();
                        onUnlockRequest()
                          .then(menu.hide);
                        return false;
                      }}
                      iconSrc="img/interface/lock-with-red-dot.svg"
                      label={
                        <Fragment>
                          <Translation
                            value={isClicked
                              ? 'lock-tooltip.request-unlock.active'
                              : 'lock-tooltip.confirm'
                            }
                          />
                          &nbsp;
                          {isClicked && <Ellipsis />}
                        </Fragment>
                      }
                    />
                  }
                  {canUnlock &&
                    <Option
                      onClick={() => {
                        this.updateClick();
                        onUnlock()
                          .then(menu.hide);
                        return false;
                      }}
                      iconSrc="img/interface/unlock.svg"
                      label={
                        <Fragment>
                          <Translation
                            value={isClicked
                              ? 'lock-tooltip.unlock-proof.active'
                              : 'lock-tooltip.confirm'
                            }
                          />
                          {!isClicked &&
                            <InlineSVG
                              src="img/interface/unlock.svg"
                              className={styles.Lock__container__confirmIcon}
                            />
                          }
                          &nbsp;
                          {isClicked && <Ellipsis />}
                        </Fragment>
                      }
                    />
                  }
                </Fragment>
              )}
            >
              <div>
                <Tooltip
                  up
                  center
                  disablePointerEvents
                  title={<Translation value={this.getUnlockedTooltipTranslationValue()} />}
                  disabled={isConfirming}
                >
                  <div className={classnames(styles.Lock__container__lockIcon,
                    {
                      [styles['Lock__container__lockIcon--disabled']]: hasRequested || isRequestPending,
                    })}
                  >
                    {(hasRequested || isRequestPending) &&
                      <span className={styles.Lock__container__lockIcon__redDot} />
                    }
                    <InlineSVG src="img/interface/padlock.svg" />
                  </div>
                </Tooltip>
              </div>
            </PopupMenu>
          }
        </div>
      </div>
    );
  }
}

Lock.defaultProps = {
  isLocked: false,
};

if (process.env.NODE_ENV !== 'production') {
  Lock.propTypes = {
    isLocked: PropTypes.bool,
    isLockVisible: PropTypes.bool,
    canUnlock: PropTypes.bool.isRequired,
    canRequest: PropTypes.bool.isRequired,
    hasRequested: PropTypes.bool.isRequired,
    isRequestPending: PropTypes.bool.isRequired,
    onUnlock: PropTypes.func.isRequired,
    onUnlockRequest: PropTypes.func.isRequired,
    onLock: PropTypes.func.isRequired,
  };
}
export default Lock;
