/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Translation} from '../Text';
import {
  StaticTab,
  ToggleView,
} from '../Dashboard';

function DomainAdminNavigationPanel({
  selectedTab,
  tabs,
  onClick,
  onToggle,
  view,
}) {
  const color = 'grey';
  const from = 'team-member-dashboard';
  return (
    <div>
      <div className={`NavigationPanel NavigationPanel--${color}`}>
        <div className="NavigationPanel__heading">
          <Translation value="team-dashboard.header" />
          <ToggleView
            onToggle={onToggle}
            view={view}
            color={color}
          />
        </div>
        <div className="NavigationPanel__tabs">
          {tabs.map(tab => tab.isStatic && (
            <StaticTab
              selected={selectedTab === tab.id}
              count={tab.users.length}
              tab={tab}
              onClick={onClick}
              key={tab.id}
              color={color}
              from={from}
            />
          ))}
        </div>
        <div className="NavigationPanel__separator" />
        <div className="NavigationPanel__heading">
          <Translation value="team-dashboard.manage" />
        </div>
        {tabs.map(tab => !tab.isStatic && (
          <StaticTab
            selected={selectedTab === tab.id}
            count={tab.users && tab.users.length}
            tab={tab}
            onClick={onClick}
            key={tab.id}
            color={color}
            from={from}
          />
        ))}
      </div>
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  DomainAdminNavigationPanel.propTypes = {
    selectedTab: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    view: PropTypes.string.isRequired,
  };
}

export default DomainAdminNavigationPanel;
