/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import css from './GreyCircleIconButton.scss';

const GreyCircleIconButton = ({ iconSrc, onClick }) => (
  <button
    type="button"
    className={css.GreyCircleIconButton}
    onClick={onClick}
  >
    <InlineSVG
      src={iconSrc}
      className={css.GreyCircleIconButton__icon}
    />
  </button>
);

if (process.env.NODE_ENV !== 'production') {
  GreyCircleIconButton.propTypes = {
    iconSrc: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };
}

export default GreyCircleIconButton;
