import React from 'react';

export default function CommentsPaneSpacer() {
  return (
    <div
      style={{
        height: 'calc(100vh - 459px)',
      }}
    />
  );
}
