/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import style from './InlineTooltip.scss';

function InlineTooltip({
  variant,
  ...props,
}) {
  return (
    <div
      className={classname(style.InlineTooltip, style['InlineTooltip--' + variant])}
    >
      {props.children}
    </div>
  );
}

InlineTooltip.defaultProps = {
  variant: 'dark',
};

InlineTooltip.propTypes = {
  variant: PropTypes.oneOf([
    'dark',
    'light',
    'yellow',
  ]),
};

export default InlineTooltip;
