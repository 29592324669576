/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import classname from 'classname';
import Tooltip, { onlyWhenTruncated } from '../Tooltip/Tooltip';
import css from './PlateOption.scss';
import HexColor from './HexColor';

const standardColorNames = [
  'Cyan',
  'Magenta',
  'Yellow',
  'Black',
];

const PlateOption = ({ name, color, isActive, onClick, onColor }) => {
  const displayName = name || 'Composite';
  const isComposite = !name;

  return (
    <Tooltip
      title={displayName}
      delay={0}
      right
      middle
      onBeforeOpen={onlyWhenTruncated('.' + css.PlateOption__name)}
    >
      <button
        type="button"
        className={classname(css.PlateOption, { [css['PlateOption--isActive']]: isActive })}
        onClick={onClick}
      >
        <div className={css.PlateOption__preview}>
          <HexColor name={name}>
            {hexColor => (
              <Fragment>
                {(standardColorNames.indexOf(name) === -1 && !isComposite) && (
                  <input
                    type="color"
                    value={color || hexColor || '#000000'}
                    onClick={event => event.stopPropagation()}
                    onChange={event => onColor(event.target.value)}
                    onDoubleClick={() => onColor(null)}
                    className={css.PlateOption__colorWheel}
                  />
                )}
                {(color || hexColor)
                  ? <div style={{ backgroundColor: color || hexColor, width: 14, height: 14 }} />
                  : <img src="/img/icons/rainbow-wheel.png" alt={name} style={{ width: 14, height: 14 }} />}
              </Fragment>
            )}
          </HexColor>
        </div>
        <div className={css.PlateOption__name}>
          {displayName}
        </div>
      </button>
    </Tooltip>
  );
};

export default PlateOption;
