/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import Media from 'react-media';
import { S_BREAK_M } from '../../util/constants';
import Flyout from '../Flyout';
import FlyoutHeading from '../Flyout/Heading';
import FlyoutClose from '../Flyout/Close';
import FlyoutInfo from '../Icon/Info';
import FlyoutManage from '../Icon/Gear';
import { Spinner } from '../Spinner';
import AuditSummary from '../AuditSummary';
import Tooltip from '../Tooltip';
import DownloadIcon from '../DownloadIcon';
import Translation from '../Text/Translation';
import Reveal from '../Reveal';
import HorizontalDelimiter from '../HorizontalDelimiter';
import SummaryDownload from './SummaryDownload';
import css from './AuditSummaryPane.scss';

const delimiter = <div className={css.AuditSummaryPane__delimiter} />;

function AuditSummaryPane({
  isOpen,
  proofId,
  proofTitle,
  proofType,
  proofTeamType,
  isLoading,
  summary,
  selectedEvent,
  onSelectEvent,
  onClose,
  enableDownload,
  hasDecisionsEnabled,
  from,
}) {
  const [showCSVPopup, setShowCSVPopup] = useState(false);
  const [viewType, setViewType] = useState('lessDetail');

  const isEnterpriseAccount = proofTeamType === 2;
  const fullDetailSummary = summary.filter(eventLog => !eventLog.isChecklistEvent);
  const lessDetailSummary = fullDetailSummary.filter(eventLog => !eventLog.excludeInLowDetail);
  const checklistSummary = summary.filter(eventLog => eventLog.isChecklistEvent);

  const viewTypeSummary = ({
    lessDetail: lessDetailSummary,
    fullDetail: fullDetailSummary,
    checklist: isEnterpriseAccount ? checklistSummary : lessDetailSummary,
  })[viewType];

  return (
    <Media query={`(max-width: ${S_BREAK_M}px)`}>
      {matches => (
        <Flyout
          position="top"
          visible={isOpen}
          duration={200}
          onClose={onClose}
        >
          <div className={css.AuditSummaryPane}>
            <FlyoutHeading
              title={<Translation value="domain-admin.menu.audit" />}
              subtitle={proofTitle}
              options={
                <HorizontalDelimiter
                  delimiter={delimiter}
                >
                  <Tooltip
                    title={enableDownload
                      ? <Translation value="audit-summary.download.enabled" />
                      : <Translation value="audit-summary.download.disabled" />}
                    down
                    center
                    offset={20}
                  >
                    {tooltip => (
                      <div className={css.AuditSummaryPane__download}>
                        <DownloadIcon
                          disabled={!enableDownload}
                          active
                          onClick={() => {
                            setShowCSVPopup(true);
                            tooltip.hide();
                          }}
                          size={matches ? 20 : 23}
                        />
                      </div>
                    )}
                  </Tooltip>
                  {from === 'manage' && (
                    <Tooltip
                      title={<Translation value="proof-info.manage.cog.tooltip" />}
                      down
                      center
                      offset={20}
                      maxWidth={150}
                    >
                      <FlyoutManage
                        active
                        activeColor="#777"
                        activeHoverColor="#a1a1a1"
                        size={matches ? 22 : 30}
                        onClick={() => onClose()}
                      />
                    </Tooltip>
                  )}
                  {from === 'info' && (
                    <Tooltip
                      title={<Translation value="proof-info.manage.info.tooltip" />}
                      down
                      center
                      offset={20}
                      maxWidth={150}
                    >
                      <FlyoutInfo
                        active
                        size={matches ? 22 : 24}
                        onClick={() => onClose()}
                      />
                    </Tooltip>
                  )}
                  <FlyoutClose
                    active
                    size={matches ? 25 : 28}
                    onClick={() => onClose(true)}
                  />
                </HorizontalDelimiter>
              }
            />
            <Reveal
              duration={500}
              visible={showCSVPopup}
              align="bottom"
              immediate={false}
            >
              <SummaryDownload
                hasDecisionsEnabled={hasDecisionsEnabled}
                summary={viewTypeSummary}
                proofType={proofType}
                selectedEvent={selectedEvent}
                onSelectEvent={onSelectEvent}
                proofTitle={proofTitle}
                onCancel={() => setShowCSVPopup(false)}
                proofId={proofId}
                viewType={viewType}
              />
            </Reveal>
            <div className={css.AuditSummaryPane__summary}>
              {isLoading
                ? (
                  <div style={{ textAlign: 'center' }}>
                    <Spinner size={60} />
                  </div>
                )
                : (
                  <AuditSummary
                    compact
                    hasDecisionsEnabled={hasDecisionsEnabled}
                    summary={viewTypeSummary}
                    proofType={proofType}
                    isEnterpriseAccount={isEnterpriseAccount}
                    selectedEvent={selectedEvent}
                    onSelectEvent={onSelectEvent}
                    viewType={viewType}
                    setViewType={setViewType}
                    hasChecklistEvents={!!checklistSummary.length}
                  />
                )
              }
            </div>
          </div>
        </Flyout>
      )}
    </Media>
  );
}

export default AuditSummaryPane;
