/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * */
import ALL_PIN_COLORS from '../resources/pinColorPreferences.json';
import { getUserPreferencesFromRawPreferences } from '../hooks/useUserPreferences';

const DEFAULT_PIN_COLORS = {
  todo: ALL_PIN_COLORS.red,
  done: ALL_PIN_COLORS.green,
  unmarked: ALL_PIN_COLORS.grey,
};

const getUserColorPreference = userPreferences => (userPreferences && userPreferences.colors
  ? userPreferences.colors.value
  : DEFAULT_PIN_COLORS);

const getUserPinPreferenceColors = (userPreferences) => {
  let userPinColorPreferences = DEFAULT_PIN_COLORS;
  if (userPreferences) {
    const normalizedUserPreferences = getUserPreferencesFromRawPreferences(userPreferences);
    userPinColorPreferences = getUserColorPreference(normalizedUserPreferences);
  }
  return userPinColorPreferences;
};

// Is used on proof page to get mapped pin color preference.
// Please pass user preference from localStorage here, as can't use hook useUserPreference.
const getPreferencePinColorMappingObject = (userPreferences) => {
  const userPinPreferenceColors = getUserPinPreferenceColors(userPreferences);
  return {
    todo: userPinPreferenceColors.todo,
    done: userPinPreferenceColors.done,
    unmarked: userPinPreferenceColors.unmarked,
  };
};

export {
  ALL_PIN_COLORS,
  DEFAULT_PIN_COLORS,
  getUserColorPreference,
  getPreferencePinColorMappingObject,
};
