/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import StaticTab from './StaticTab';

const CollectionOption = ({
  collection,
  selectedCollection,
  onSelect,
  color,
  from,
  onDrop,
  onRemove,
  onManage,
}) => (
  <StaticTab
    initialCasing
    selected={selectedCollection === collection.groupName}
    tab={collection}
    onClick={onSelect}
    count={collection.proofCount || collection.proofs.length}
    color={color}
    from={from}
    tip={false}
    dropAllowed={collection.canAddProofs}
    onDrop={item => onDrop(item, collection.id)}
    onRemove={collection.isEmpty && onRemove}
    onManage={collection.canAddProofs && onManage}
  />
);

if (process.env.NODE_ENV !== 'production') {
  CollectionOption.propTypes = {
    collection: PropTypes.shape({
      groupName: PropTypes.string.isRequired,
      proofCount: PropTypes.number.isRequired,
      proofs: PropTypes.arrayOf(PropTypes.object).isRequired,
      canAddProofs: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      isEmpty: PropTypes.bool.isRequired,
    }).isRequired,
    selectedCollection: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    onSelect: PropTypes.func.isRequired,
    onManage: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    from: PropTypes.string,
    color: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
  };
}

export default CollectionOption;
