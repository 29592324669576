/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from './createIcon';

export default createIcon({
  displayName: 'Trash',
  src: 'img/content/proof/icons/delete.svg',
  activeColor: '#555',
  activeHoverColor: '#e51c23',
  defaultSize: 20,
});
