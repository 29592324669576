/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import css from './MultiEditorForm.scss';
import { sdk } from '../../util/sdk';
import env from '../../../../shared/env';
import { RevealingEmailInput } from '../RevealingInput';
import { Translation } from '../Text';
import { Button } from '../Button';
import { Avatar } from '../Avatar';
import { PopupMenu, Option, Separator } from '../PopupMenu';
import ItemBorderWrapper from '../ItemBorderWrapper';
import Toaster from '../ProofSetup/components/Toaster';
import ButtonGroup from '../ButtonGroup';

const MultiEditorForm = ({ owners, editors, proofId, onAdd, onRemove, message, onCancel }) => {
  const [pendingEmails, setPendingEmails] = useState([]);
  const [validation, setValidation] = useState([]);

  const editorsStringArr = editors.map(({ email }) => email).concat(pendingEmails);

  const getValidEmails = (emailsArr) => {
    const validEmails = [];
    const alreadyOwners = [];
    const alreadyEditors = [];
    setValidation([]);

    emailsArr.forEach((email) => {
      const existingOwner = owners.includes(email);
      const existingEditor = editorsStringArr.includes(email);

      if (!existingEditor && !existingOwner) {
        validEmails.push(email);
      } else if (existingOwner) {
        alreadyOwners.push(email);
      } else if (existingEditor) {
        alreadyEditors.push(email);
      }
    });

    if (alreadyOwners.length > 0) {
      setValidation(currentValidation => Toaster.addToast(currentValidation, 'isAlreadyOwner', { alreadyOwners }));
    }

    if (alreadyEditors.length > 0) {
      setValidation(currentValidation => Toaster.addToast(currentValidation, 'isAlreadyEditor', { alreadyEditors }));
    }

    return validEmails;
  };

  const onEmailSelect = (emailArr) => {
    const validEmails = getValidEmails(emailArr);
    if (validEmails.length > 0) {
      setPendingEmails(currentPendingEmails => [...currentPendingEmails, ...validEmails]);
    }
  };

  const onSend = () => {
    const emailsToInvite = pendingEmails.map(email => ({ email }));
    return sdk.proofs.editors.add(proofId, emailsToInvite, message)
      .then((response) => {
        setPendingEmails([]);
        onAdd(response.data);
      });
  };

  const onRemoveEditor = (selectedEmail) => {
    if (!pendingEmails.includes(selectedEmail)) {
      const editorId = editors.find(editor => editor.email === selectedEmail).userId;
      sdk.proofs.editors.remove(proofId, { id: editorId })
        .then(() => {
          onRemove(editorId);
        });
    } else {
      const filteredEmails = pendingEmails.filter(email => email !== selectedEmail);
      setPendingEmails(filteredEmails);
    }
  };

  return (
    <div className={css.MultiEditorForm}>
      <div className={css.MultiEditorForm__toasterWrapper}>
        <Toaster
          render={{
            isAlreadyEditor: ({ alreadyEditors }) => (
              <Translation
                value={alreadyEditors.length > 1
                  ? 'add-editor.error.already-editor.multi'
                  : 'add-editor.error.already-editor'
                }
                params={{
                  email: <b>{alreadyEditors[alreadyEditors.length - 1]}</b>,
                  emails: <b>{alreadyEditors.slice(0, -1).join(', ')}</b>,
                  lastEmail: <b>{alreadyEditors[alreadyEditors.length - 1]}</b>,
                }}
              />
            ),
            isAlreadyOwner: ({ alreadyOwners }) => (
              <Translation
                value={alreadyOwners.length > 1
                  ? 'add-editor.error.already-owner.multi'
                  : 'add-editor.error.already-owner'
                }
                params={{
                  email: <b>{alreadyOwners[alreadyOwners.length - 1]}</b>,
                  emails: <b>{alreadyOwners.slice(0, -1).join(', ')}</b>,
                  lastEmail: <b>{alreadyOwners[alreadyOwners.length - 1]}</b>,
                }}
              />
            ),
          }}
          toasts={validation}
          onDismiss={() => {
            setValidation(validation.slice(1));
          }}
        />
      </div>
      <div className={css.MultiEditorForm__editorsWrapper}>
        {editorsStringArr.map(email => (
          <ItemBorderWrapper
            key={email}
            items={editorsStringArr}
            currentItem={email}
            outlineItems={pendingEmails}
          >
            <PopupMenu
              maxWidth={270}
              options={
                <Fragment>
                  <Option
                    label={email}
                    disabled
                  />
                  {pendingEmails.includes(email) && (
                    <Option
                      label={<Translation value="add-editor.tooltip.not-invited-yet" />}
                      disabled
                    />
                  )}
                  <Separator />
                  <Option
                    label={<Translation value="option.remove" />}
                    onClick={() => onRemoveEditor(email)}
                  />
                </Fragment>
              }
            >
              <Avatar
                active
                url={`${env.avatar_url}/-/${email}`}
                size={40}
                spinner
              />
            </PopupMenu>
          </ItemBorderWrapper>
        ))}
        <div className={css.MultiEditorForm__editorsWrapper__addEditorIcon}>
          <RevealingEmailInput
            onAddBatch={onEmailSelect}
          />
        </div>
      </div>
      <ButtonGroup>
        {onCancel &&
          <Button
            variant="text"
            label={<Translation value="button.back" />}
            onClick={onCancel}
          />
        }
        <Button
          variant="primary"
          label={
            <Translation
              value={pendingEmails.length > 1 ? 'button.send-to-editors' : 'button.send-to-editor'}
            />
          }
          disabled={!pendingEmails.length}
          onClick={onSend}
        />
      </ButtonGroup>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  MultiEditorForm.propTypes = {
    proofId: PropTypes.string.isRequired,
    message: PropTypes.string,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    editors: PropTypes.arrayOf(PropTypes.object).isRequired,
    owners: PropTypes.arrayOf(PropTypes.string),
    onCancel: PropTypes.func.isRequired,
  };
}

export default MultiEditorForm;
