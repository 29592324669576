/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import devices from '../../../resources/devices';

export default function getCommentDeviceCategory(deviceId) {
  const match = deviceId && devices.filter(device => device.id === deviceId)[0];
  if (match) {
    return match.category;
  }
  return 'responsive';
}
