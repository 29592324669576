/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import InlineTooltip from '../InlineTooltip';
import css from './ProofInfoInlineTooltip.scss';

function ProofInfoInlineTooltip({
  children,
}) {
  return (
    <div className={css.ProofInfoInlineTooltip}>
      <InlineTooltip>
        {children}
      </InlineTooltip>
    </div>
  );
}

export default ProofInfoInlineTooltip;
