/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-env browser */
import { WALKTHROUGHS } from '../resources/walkthroughDefinitions';
import getQueryParams from '../util/get-query-params';
import { sdk } from '../util/sdk';
import { getUserPreferences, getObservableUserPreferences } from './userPreferences';

export const resetAllWalkthroughsToNotBeCompleted = () => {
  sdk.preferences.update('completedWalkthroughs', []);
};

let queuedWalkthroughId;
let completedWalkthroughs;

getUserPreferences().then((userPreferences) => {
  completedWalkthroughs = (userPreferences.completedWalkthroughs && userPreferences.completedWalkthroughs.value) || [];
  if (queuedWalkthroughId) {
    const walkthroughId = queuedWalkthroughId;
    queuedWalkthroughId = null;
    startWalkthrough(walkthroughId);
  } else {
    onLocationChangeSuccess();
  }
});

const completedWalkthroughsObserver = {
  next({ data: { userPreferences } }) {
    completedWalkthroughs = userPreferences.completedWalkthroughs.value || [];
  },
};

getObservableUserPreferences().then(observableUserPreferences => observableUserPreferences.subscribe(completedWalkthroughsObserver));

/* eslint-disable import/no-mutable-exports */
export let currentWalkthrough = null;
export let isWalkthroughPending = false;
/* eslint-enable import/no-mutable-exports */


const areRequiredStepsVisible = (walkthrough) => {
  const availableHooks = [...document.querySelectorAll('[data-walkthrough-hook]')]
    .filter((element) => {
      const { width, height } = element.getBoundingClientRect();
      return width && height;
    })
    .map(element => element.getAttribute('data-walkthrough-hook'));

  if (!availableHooks) {
    return false;
  }

  const { requiredSteps, steps } = walkthrough;

  return requiredSteps
    ? requiredSteps.every(step => step.hooks.some(hook => availableHooks.includes(hook)))
    : steps.some(stepSteps => stepSteps.some(step => step.hooks.some(hook => availableHooks.includes(hook))));
};

export const dismiss = () => {
  currentWalkthrough = null;
  isWalkthroughPending = false;
  cleanUp();
  if (!window.__pageproof_bridge__.$rootScope.$$phase) {
    window.__pageproof_bridge__.$rootScope.$apply();
  }
};

export const completeWalkthrough = () => {
  sdk.preferences.update('completedWalkthroughs', [...completedWalkthroughs, ...currentWalkthrough.includedWalkthroughIds]);
  dismiss();
};

const cleanUp = () => {
  if (requiredStepsObserver) {
    requiredStepsObserver.disconnect();
    requiredStepsObserver = null;
  }
  clearTimeout(requiredStepsTimeout);

  queuedWalkthroughId = null;

  if (!currentWalkthrough) {
    isWalkthroughPending = false;
  }
};

let requiredStepsObserver;
let requiredStepsTimeout;

const startWalkthroughRequiredStepsRecheck = (walkthrough) => {
  if (areRequiredStepsVisible(walkthrough)) {
    currentWalkthrough = walkthrough;

    cleanUp();
  }
};

export const startWalkthrough = (walkthroughId, ignoreCurrentCheck = false, ignoreCompletedCheck = false) => {
  // IE users don't get a walkthrough because IE can barely walk
  if (window.__pageproof_bridge__.browserService.is('ie')) {
    return;
  }

  if (ignoreCurrentCheck) {
    dismiss();
  }

  if (currentWalkthrough || queuedWalkthroughId || isWalkthroughPending) {
    throw new Error('Cannot start multiple walkthroughs at the same time');
  }

  isWalkthroughPending = true;

  if (!completedWalkthroughs) {
    queuedWalkthroughId = walkthroughId;
    return;
  }

  if (!ignoreCompletedCheck && completedWalkthroughs.includes(walkthroughId)) {
    cleanUp();
    return;
  }

  const walkthrough = WALKTHROUGHS.find(w => w.id === walkthroughId);

  if (areRequiredStepsVisible(walkthrough)) {
    currentWalkthrough = walkthrough;
    if (!window.__pageproof_bridge__.$rootScope.$$phase) {
      window.__pageproof_bridge__.$rootScope.$apply();
    }
  } else {
    if (!requiredStepsObserver) {
      requiredStepsObserver = new MutationObserver(() => startWalkthroughRequiredStepsRecheck(walkthrough));
      requiredStepsObserver.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }

    requiredStepsTimeout = setTimeout(cleanUp, 5000);
  }
};

export const onLocationChangeSuccess = () => {
  if (currentWalkthrough) {
    if (currentWalkthrough.canDisplay() && areRequiredStepsVisible(currentWalkthrough)) {
      return;
    } else {
      dismiss();
    }
  } else {
    cleanUp();
  }

  // This function runs a second time once completedWalkthroughs has loaded
  if (!completedWalkthroughs) {
    return;
  }

  const queryWalkthroughId = getQueryParams().walkthrough;

  if (queryWalkthroughId) {
    const queryWalkthrough = WALKTHROUGHS.find(w => w.id === queryWalkthroughId);

    if (queryWalkthrough.canDisplay()) {
      startWalkthrough(queryWalkthroughId, true, true);
    }

    return;
  }

  const walkthrough = WALKTHROUGHS
    .filter(({ id, manualTrigger }) => !manualTrigger && !completedWalkthroughs.includes(id))
    .find(({ canDisplay }) => canDisplay());

  if (walkthrough) {
    startWalkthrough(walkthrough.id);
  }
};
