/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useRef, useEffect } from 'react';
import { sdk } from '../../util/sdk';
import IntegrationReferenceSelector from '../../components/ProofSetup/components/IntegrationReferenceSelector';
import { scheduleBackgroundTask } from '../../util/scheduleBackgroundTask';


const IntegrationReferenceSelectorContainer = ({ proofId, integrationReferences, onChange, isCurrentUserInProofTeam }) => {
  const integrationReferencesRef = useRef();
  useEffect(() => {
    integrationReferencesRef.current = integrationReferences;
  }, [integrationReferences]);

  const cancelPolling = useRef();
  useEffect(() => () => {
    if (cancelPolling.current) {
      cancelPolling.current();
    }
  }, []);

  // TODO: Load just the references rather than the whole proof
  const loadReferences = () => sdk.proofs.load(proofId).then((proof) => {
    const updateIntegrationReferencesLength = proof.integrationReferences ? proof.integrationReferences.length : 0;
    if (updateIntegrationReferencesLength > integrationReferencesRef.current.length) {
      cancelPolling.current();
      onChange(proof.integrationReferences);
    }
  });

  const addReference = (reference) => {
    sdk.proofs.integrationReferences.add(proofId, reference)
      .then(() => {
        if (cancelPolling.current) {
          cancelPolling.current();
        }
        onChange(integrationReferences.concat([reference]));
        cancelPolling.current = scheduleBackgroundTask(() => loadReferences(), 60000);
      });
  };

  return (
    <span style={{ position: 'absolute', top: -4, right: 0 }}>
      <IntegrationReferenceSelector
        onSelectReferenceOption={addReference}
        popoverProps={{ center: false, left: true }}
        integrationReferences={integrationReferences}
        isCurrentUserInProofTeam={isCurrentUserInProofTeam}
      />
    </span>
  );
};

export default IntegrationReferenceSelectorContainer;
