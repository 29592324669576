/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect } from 'react';
import { sdk } from '../../util/sdk';
import { Spinner } from '../../components/Spinner';
import { useText } from '../../components/Text';

export default function NewWorkflowTemplateContainer({ initialRouteParams }) {
  const { from } = initialRouteParams;

  const text = useText();

  useEffect(() => {
    const { $location } = window.__pageproof_bridge__;

    sdk.workflows
      .create({
        isOwnedByTeam: from === 'team',
        name: text('workflow.structure.needs-a-name'),
      })
      .then((workflow) => {
        let workflowUrl = `/workflows/templates/${workflow.id}`;

        if (from) {
          workflowUrl += `?from=${from}`;
        }

        $location.url(workflowUrl).replace();
      });
  }, []);

  return <Spinner center />;
}
