/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import InlineSVG from 'jacobmarshall-react-inline-svg';
import React, { Fragment } from 'react';
import Popover from '../Popover';
import { Option, OptionList } from '../PopupMenu';
import { Translation } from '../Text';
import styles from './Reminder.scss';

const options = [
  {
    label: 'proof.reminder.option.overdue-48-hours',
    id: 'overdue-48h',
    selected: false,
    value: -48,
    relativeDate: -48 * 60 * 60 * 1000,
  },
  {
    label: 'proof.reminder.option.overdue-24-hours',
    id: 'overdue-24h',
    selected: false,
    value: -24,
    relativeDate: -24 * 60 * 60 * 1000,
  },
  {
    label: 'proof.reminder.option.on-due-date',
    id: '0h',
    selected: true,
    value: 0,
    relativeDate: 0,
  },
  {
    label: 'proof.reminder.option.1-hour',
    id: '1h',
    selected: false,
    value: 1,
    relativeDate: 1 * 60 * 60 * 1000,
  },
  {
    label: 'proof.reminder.option.3-hours',
    id: '3h',
    selected: false,
    value: 3,
    relativeDate: 3 * 60 * 60 * 1000,
  },
  {
    label: 'proof.reminder.option.24-hours',
    id: '24h',
    selected: false,
    value: 24,
    relativeDate: 24 * 60 * 60 * 1000,
  },
  {
    label: 'proof.reminder.option.48-hours',
    id: '48h',
    selected: false,
    value: 48,
    relativeDate: 48 * 60 * 60 * 1000,
  },
  {
    label: 'proof.reminder.option.72-hours',
    id: '72h',
    selected: false,
    value: 72,
    relativeDate: 72 * 60 * 60 * 1000,
  },
];

function Reminder({
  variant,
  reminders,
  canEdit,
  canDelete,
  onAdd,
  onRemove,
  onRemoveAll,
  canShow,
  inCreateProofProccess,
  timeLeft,
}) {
  const modifiedOptions = options.filter(({ value }) => timeLeft >= value).map((option) => {
    const modifiedOption = { ...option };
    const selectedReminder = reminders.filter(reminder => reminder.relativeDate === -option.relativeDate);
    if (selectedReminder.length) {
      modifiedOption.selected = true;
      modifiedOption.reminderId = selectedReminder[0].id;
    } else {
      modifiedOption.selected = false;
      modifiedOption.reminderId = null;
    }
    return modifiedOption;
  });

  if (!canShow || modifiedOptions.length === 0) {
    return null;
  }

  return (
    (canShow &&
      <div
        className={styles.Reminder}
        onClick={event => event.preventDefault()}
      >
        <div className={inCreateProofProccess ? styles.Reminder__newProof : ''}>
          <Popover
            up
            center
            arrow
            offset={15}
            content={
              <div style={{ minWidth: '150px' }}>
                <OptionList wrap={false}>
                  <Option
                    label={<Translation value={`${variant}.reminder.option.set-reminder`} />}
                    disabled
                  />
                  <hr className={styles.Reminder__separator} />
                  {modifiedOptions.map(option => (
                    <Option
                      key={option.id}
                      checked={option.selected}
                      readOnly={!canEdit}
                      label={<Translation value={option.label} />}
                      onClick={() => canEdit && (
                        option.selected
                          ? onRemove(option.reminderId)
                          : onAdd(option.value)
                      )}
                    />
                  ))}
                  {canDelete && reminders && reminders.length > 0 &&
                    <Fragment>
                      <hr className={styles.Reminder__separator} />
                      <Option
                        label={
                          <Translation
                            value={reminders && reminders.length > 1
                              ? `${variant}.reminder.clear-reminder.multi`
                              : `${variant}.reminder.clear-reminder`
                            }
                          />
                        }
                        onClick={() => onRemoveAll()}
                      />
                    </Fragment>
                  }
                </OptionList>
              </div>
            }
          >
            <div className={styles.Reminder__iconsHolder}>
              <InlineSVG
                src="img/interface/baseline-alarm.svg"
                className={reminders && reminders.length > 0 ? styles.Reminder__iconsHolder__reminderIconGreen : styles.Reminder__iconsHolder__reminderIcon}
              />
            </div>
          </Popover>
        </div>
      </div>
    )
  );
}

export default Reminder;
