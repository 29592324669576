/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
let storage = null;
try {
  storage = JSON.stringify(window.localStorage);
} catch (error) {
  console.error(error);
  storage = '{}';
}
const callbacks = {};

function checkForUpdates() {
  const currentStorage = JSON.stringify(window.localStorage);
  if (storage !== currentStorage) {
    const _storage = JSON.parse(storage);
    const _currentStorage = JSON.parse(currentStorage);
    let keys = [
      ...Object.keys(_storage),
      ...Object.keys(_currentStorage),
    ];
    keys = keys.filter((item, index) => keys.indexOf(item) === index);
    keys.forEach((key) => {
      const oldValue = _storage[key];
      const newValue = _currentStorage[key];
      if (newValue !== oldValue) {
        const watchers = callbacks[key];
        if (watchers && watchers.length) {
          watchers.forEach(callback => callback({ key, oldValue, newValue }));
        }
      }
    });
    storage = currentStorage;
  }
}

function watch(key, callback) {
  const watchers = (callbacks[key] || (callbacks[key] = []));
  watchers.push(callback);
}

function unwatch(key, callback) {
  const watchers = callbacks[key];
  if (!watchers) {
    return;
  }
  if (!callback) {
    watchers.length = 0;
  } else {
    const index = watchers.indexOf(callback);
    if (index !== -1) {
      watchers.splice(index, 1);
    }
  }
}

function injectAfter(base, name, func) {
  const original = base[name];
  // eslint-disable-next-line no-param-reassign
  base[name] = function (...args) {
    const returnValue = original.call(this, ...args);
    func();
    return returnValue;
  };
}

window.addEventListener('storage', checkForUpdates);
injectAfter(window.Storage.prototype, 'setItem', checkForUpdates);
injectAfter(window.Storage.prototype, 'removeItem', checkForUpdates);
injectAfter(window.Storage.prototype, 'clear', checkForUpdates);

export {
  watch,
  unwatch,
};
