/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './ProofThumbnailNavigator.scss';
import UnstyledButton from '../Button/UnstyledButton';
import NavigatorThumbnail from './NavigatorThumbnail';

const ProofThumbnailNavigator = ({
  prevProofThumbnail,
  currentProofThumbnail,
  nextProofThumbnail,
  onPreviousProof,
  onNextProof,
}) => (
  <div className={css.ProofThumbnailNavigator}>
    <UnstyledButton
      ariaLabel="Previous proof"
      className={classname(css.ProofThumbnailNavigator__side, {
        [css['ProofThumbnailNavigator__side--empty']]: !prevProofThumbnail,
      })}
      onClick={() => onPreviousProof()}
    >
      {prevProofThumbnail && (
        <Fragment>
          <NavigatorThumbnail
            thumbnail={prevProofThumbnail}
            variant="tiny"
          />
          <div className={css.ProofThumbnailNavigator__side__arrow}>&#8592;</div>
        </Fragment>
      )}
    </UnstyledButton>
    <div className={css.ProofThumbnailNavigator__current}>
      <NavigatorThumbnail
        thumbnail={currentProofThumbnail}
        variant="list"
      />
    </div>
    <UnstyledButton
      ariaLabel="Next proof"
      className={classname(css.ProofThumbnailNavigator__side, {
        [css['ProofThumbnailNavigator__side--empty']]: !nextProofThumbnail,
      })}
      onClick={() => onNextProof()}
    >
      {nextProofThumbnail && (
        <Fragment>
          <NavigatorThumbnail
            thumbnail={nextProofThumbnail}
            variant="tiny"
          />
          <div className={css.ProofThumbnailNavigator__side__arrow}>&#8594;</div>
        </Fragment>
      )}
    </UnstyledButton>
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  ProofThumbnailNavigator.propTypes = {
    prevProofThumbnail: PropTypes.string,
    currentProofThumbnail: PropTypes.string,
    nextProofThumbnail: PropTypes.string,
    onPreviousProof: PropTypes.func.isRequired,
    onNextProof: PropTypes.func.isRequired,
  };
}

export default ProofThumbnailNavigator;
