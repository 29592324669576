/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect } from 'react';

/**
 * Synchronizes the URL with the given parameters.
 *
 * @param {string|string[]} pathOrSegments Either the path or segments that make up the path.
 * @param {object} [search] The search parameters to include in the URL.
 */
export const useSynchronizedUrl = (pathOrSegments, search) => {
  const searchParams = search && new URLSearchParams(search).toString();

  const path = Array.isArray(pathOrSegments) ? pathOrSegments.filter(Boolean).join('/') : pathOrSegments;
  const expectedUrl = path + (searchParams ? '?' + searchParams : '');

  useEffect(() => {
    const { $location, $rootScope } = window.__pageproof_bridge__;

    if (expectedUrl !== $location.url()) {
      $location.url(expectedUrl).replace().ignore();
      if (!$rootScope.$$phase) {
        $rootScope.$apply();
      }
    }
  }, [expectedUrl]);
};
