import getPDFJS from '../../../util/pdfjs';

function createCanvas() {
  const canvas = document.createElement('canvas');
  document.body.appendChild(canvas);
  canvas.style.width = '0px';
  canvas.style.height = '0px';
  canvas.style.position = 'absolute';
  canvas.style.opacity = 0;
  canvas.style.visibility = 'hidden';
  canvas.style.pointerEvents = 'none';
  const canvasContext = canvas.getContext('2d');
  return [canvas, canvasContext];
}

export default function renderPDF(blob, pageNumber, size) {
  const blobUrl = URL.createObjectURL(blob);

  const chain = getPDFJS()
    .then(pdfjs => pdfjs.getDocument(blobUrl).promise)
    .then(document => Promise.all([document, document.getPage(pageNumber)]))
    .then(([document, page]) => {
      const desiredSize = size;
      const viewport = page.getViewport({ scale: 1 });
      const scale = Math.min(desiredSize / viewport.width, desiredSize / viewport.height);
      const scaledViewport = page.getViewport({ scale });

      const [canvas, context] = createCanvas();
      canvas.height = scaledViewport.height;
      canvas.width = scaledViewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      };

      return page.render(renderContext).promise.then(() => (
        new Promise(resolve => canvas.toBlob((thumbnail) => {
          canvas.remove();
          resolve(Promise.all([
            thumbnail,
            document.destroy(),
          ]));
        }))
      ));
    })
    .then(([thumbnail]) => thumbnail);

  chain.catch(() => {})
    .then(() => {
      URL.revokeObjectURL(blobUrl);
    });

  return chain;
}
