/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import pixelmatch from '../util/pixelmatch';
import { I18nProvider } from '../components/I18n/I18nProvider';
import { SmartCompareDiffOverlay } from '../components/SmartCompareDiffOverlay';

/** @returns {Promise<Image>} */
function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
}

function createCanvas(width, height) {
  const canvas = document.createElement('canvas');
  // Object.assign(canvas.style, {
  //   position: 'fixed',
  //   zIndex: 1000000000000,
  //   top: '-10000px',
  //   left: '-10000px',
  //   width: 0,
  //   height: 0,
  //   // top: 0,
  //   // left: 0,
  //   // width: '100%',
  //   // height: '100%',
  // });
  // document.body.appendChild(canvas);
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  return {
    canvas,
    ctx,
    toBlob: options => new Promise((resolve, reject) => {
      try {
        canvas.toBlob(resolve, options);
      } catch (err) {
        reject(err);
      }
    }),
    destroy: () => canvas.remove(),
  };
}

function getImageData(img) {
  const { width, height } = img;
  const { ctx, destroy } = createCanvas(width, height);
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, width, height);
  destroy();
  return imageData;
}

export function diffImages(urlA, urlB) {
  return Promise.all([
    loadImage(urlA),
    loadImage(urlB),
  ]).then(([imageA, imageB]) => {
    if (imageA.width !== imageB.width || imageA.height !== imageB.height) {
      throw new Error('Images must have the same dimensions.');
    }
    return Promise.all([
      getImageData(imageA),
      getImageData(imageB),
    ]);
  }).then(([imageDataA, imageDataB]) => {
    const { width, height } = imageDataA;
    const { ctx, toBlob, destroy } = createCanvas(width, height);
    const outputImageData = ctx.createImageData(width, height);
    const pixelCount = width * height;
    const diffCount = pixelmatch(
      imageDataA.data,
      imageDataB.data,
      outputImageData.data,
      width,
      height,
      {
        diffMask: true,
        // alpha: 0.2,
        includeAA: true,
        diffColor: [19, 139, 59],
        diffColorAlt: [19, 139, 59],
        threshold: 0.07,
      },
    );
    ctx.putImageData(outputImageData, 0, 0);
    return toBlob().then((maskImageBlob) => {
      destroy();
      return {
        pixelCount,
        diffCount,
        diffRatio: diffCount / pixelCount,
        imageBlob: maskImageBlob,
        imageUrl: URL.createObjectURL(maskImageBlob),
      };
    });
  });
}

export function renderSmartCompareDiffOverlay(element, props) {
  render(
    (
      <I18nProvider>
        <SmartCompareDiffOverlay {...props} />
      </I18nProvider>
    ),
    element,
  );
}

export function destroySmartCompareDiffOverlay(element) {
  unmountComponentAtNode(element);
}
