/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {Component} from 'react';
import DecisionButton from '../../components/Decisions/DecisionButton';
import ThumbsUp from '../../components/Decisions/Icons/ThumbsUp';
import ThumbsUpOrange from '../../components/Decisions/Icons/ThumbsUpOrange';
import ThumbsDown from '../../components/Decisions/Icons/ThumbsDown';

class DecisionButtonContainer extends Component {
  state = {
    deciding: false,
  };

  render() {
    const {
      canSendChanges,
      hasApprovedWithChangesDecision,
      onDecision,
      onClick,
      cannotLeaveDecision,
    } = this.props;
    const {deciding} = this.state;
    const options = [
      {
        id: 'approved',
        label: 'decision.option.approved',
        icon: <ThumbsUp />,
      },
      {
        id: 'approved_with_changes',
        label: 'decision.option.approved-with-changes',
        icon: <ThumbsUpOrange />,
        isHidden: !hasApprovedWithChangesDecision,
        disabled: !canSendChanges,
      },
      {
        id: 'send_changes',
        label: 'decision.option.changes-please',
        icon: <ThumbsDown />,
        disabled: !canSendChanges,
      },
    ].filter(({ isHidden }) => !isHidden);

    return (
      <div>
        <DecisionButton
          options={options}
          disabled={deciding || cannotLeaveDecision}
          label={deciding
            ? 'decision.button.label.active'
            : 'decision.button.label'}
          onSelect={(selectedOption) => {
            this.setState({ deciding: true });
            Promise.resolve(onDecision(selectedOption).then(() => {
              this.setState({ deciding: false });
            }));
          }}
          onToggle={onClick}
          cannotLeaveDecision={cannotLeaveDecision}
        />
      </div>
    );
  }
}

export default DecisionButtonContainer;
