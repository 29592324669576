/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { useText } from '../Text';
import { Spinner } from '../Spinner';

function Avatar({
  url,
  size,
  active,
  initials,
  registered,
  disabled,
  onClick,
  onDoubleClick,
  spinner,
}) {
  const text = useText();

  if (spinner) {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      const image = new window.Image();
      image.onload = () => setIsLoading(false);
      image.src = url;
      setIsLoading(true);
    }, [
      url,
    ]);
    if (isLoading) {
      return (
        <Spinner
          size={size}
          onDoubleClick={onDoubleClick}
        />
      );
    }
  }
  return (
    <div
      className={classname('Avatar', {
        'Avatar--not-registered': !registered,
        'Avatar--disabled': disabled,
        'Avatar--active': active,
      })}
      style={{
        width: size,
        height: size,
      }}
      onClick={onClick}
      tabIndex="0"
      onDoubleClick={onDoubleClick}
    >
      {url
        ? <img
          alt={text('avatar.alt')}
          className="Avatar__image"
          src={url}
          draggable="false"
        />
        : (
          <div
            className="Avatar__initials"
            style={{
              fontSize: size * 0.30,
              lineHeight: size + 'px',
            }}
          >
            {initials}
          </div>
        )
      }
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Avatar.propTypes = {
    url: PropTypes.string,
    size: PropTypes.number,
    initials: PropTypes.string,
    registered: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };
}

export default Avatar;
