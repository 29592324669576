/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment} from 'react';
import {Translation} from '../Text';
import Popover from '../Popover';
import {Option, OptionList, Heading, Separator} from '../PopupMenu';
import styles from './FilterPopover.scss';
import Tooltip from '../Tooltip';

function FilterPopover({
  canFilter,
  onAddFilter,
  onRemoveFilter,
  canClear,
  onRemoveAllFilters,
  options,
  multiSelect,
  heading,
  children,
  canCancel,
  onCancel,
  variant,
}) {
  if (!options) {
    return (
      <div>
        {children}
      </div>
    );
  }
  return (
    <div>
      <Popover
        down
        center
        arrow
        offset={15}
        variant={variant}
        padding={false}
        content={popover => (
          <div className={styles.FilterPopover}>
            <OptionList
              variant={variant}
            >
              {heading &&
                <Heading
                  variant={variant}
                  label={heading}
                />
              }
              {options && options.length > 0 && options.map(option => (
                <Option
                  readOnly={!canFilter}
                  // This prevents Option allocating space for check marks if we never pass in selected
                  {...(option.selected !== undefined && { checked: option.selected })}
                  key={option.id}
                  label={
                    <Tooltip
                      delay={500}
                      up
                      center
                      title={option.tooltip}
                      disabled={!option.tooltip}
                    >
                      <div className={styles.FilterPopover__label}>
                        {option.icon &&
                          <span className={styles.FilterPopover__label__icon}>
                            {option.icon}
                          </span>
                        }
                        <span className={styles.FilterPopover__label__text}>
                          {option.label}
                        </span>
                      </div>
                    </Tooltip>
                  }
                  onClick={canFilter && (() => {
                    if (option.selected && multiSelect) {
                      onRemoveFilter(option.id);
                    } else {
                      onAddFilter(option.id);
                    }
                    popover.hide();
                  })}
                />
              ))}
              {canClear &&
                <Fragment>
                  <Separator variant={variant} />
                  <Option
                    variant={variant}
                    label={
                      <Translation value="comments-header.filter.clear-filter" />
                    }
                    onClick={() => {
                      popover.hide();
                      onRemoveAllFilters();
                    }}
                  />
                </Fragment>
              }
              {canCancel &&
                <Fragment>
                  <Separator variant={variant} />
                  <Option
                    variant={variant}
                    label={
                      <Translation value="button.cancel" />
                    }
                    onClick={() => {
                      popover.hide();
                      if (onCancel) {
                        onCancel();
                      }
                    }}
                  />
                </Fragment>
              }
            </OptionList>
          </div>
        )}
      >
        {children}
      </Popover>
    </div>
  );
}

FilterPopover.defaultProps = {
  canFilter: true,
  canClear: false,
  canCancel: false,
  heading: false,
  multiSelect: false,
};

export default FilterPopover;
