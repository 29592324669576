/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sdk } from '../../util/sdk';
import DashboardManageContainer from '../../containers/DashboardManage';
import { getSharedWithTeamLocalPreference, setSharedWithTeamLocalPreference } from '../../util/collections';

function createAndReadyCollection(options) {
  return sdk.proofs.groups.create(options)
    .then(group => sdk.proofs.groups.ready(group.id)
      .then(() => group));
}

function AddBlankCollection({
  canShow,
  onCancel,
  onAction,
  color,
}) {
  const [isSharedWithTeam, setSharedWithTeam] = useState(getSharedWithTeamLocalPreference);
  const [isCreatingCollection, setIsCreatingCollection] = useState(false);

  const manageCollection = {
    showManagePane: canShow,
    onToggle: onCancel,
    label: 'collection.new.title',
    tip: 'collection.new.subtitle',
    manageItem: {
      id: 'add-collection',
      buttonLabel: 'button.add',
      cancelButton: 'button.cancel',
      placeholder: 'collection.new.placeholder',
      onCancel,
      onAction: (name) => {
        setIsCreatingCollection(true);
        createAndReadyCollection({ name, isOwnedByTeam: isSharedWithTeam })
          .then((newCollection) => {
            setIsCreatingCollection(false);
            onAction(newCollection);
          });
        setSharedWithTeamLocalPreference(isSharedWithTeam);
      },
      switchEnabled: !!sdk.session.user.teamType,
      switchLabel: 'collection.new.share-with-team',
      switchValue: isSharedWithTeam,
      onToggleSwitch: setSharedWithTeam,
      disabled: isCreatingCollection,
    },
  };
  return (
    <DashboardManageContainer
      {...manageCollection}
      color={color}
    />
  );
}

if (process.env.NODE_ENV !== 'production') {
  AddBlankCollection.propTypes = {
    onAction: PropTypes.func.isRequired,
    canShow: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    color: PropTypes.string,
  };
}

export default AddBlankCollection;
