import React, { useState, useEffect } from 'react';

const TemporaryThumbnail = ({
  src,
  fallback = null,
  ...props,
}) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    if (src) {
      const { temporaryStorageService } = window.__pageproof_bridge__;
      const thumbnailUrlPromise = temporaryStorageService.get(src)
        .then((thumbnailBlob) => {
          if (thumbnailBlob) {
            const url = URL.createObjectURL(thumbnailBlob);
            setThumbnailUrl(url);
            return url;
          } else {
            setThumbnailUrl(null);
          }
          return null;
        });
      return () => {
        thumbnailUrlPromise.then((url) => {
          if (url) {
            URL.revokeObjectURL(url);
          }
        });
      };
    } else {
      setThumbnailUrl(null);
      return () => {
        // no cleanup required
      };
    }
  }, [
    src,
  ]);

  if (!thumbnailUrl) {
    return fallback || null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      {...props}
      src={thumbnailUrl}
      onError={() => {
        setThumbnailUrl(null);
      }}
    />
  );
};

export default TemporaryThumbnail;
