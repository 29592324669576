/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { Translation, Validation } from '../../components/Text';
import { Button } from '../../components/Button';
import AddressBookControl from '../../components/AddressBookControl';
import FormControl from '../../components/FormControl';
import styles from './CollectionManageStyles';
import CollectionManageBackButton from './CollectionManageBackButton';

function CollectionManageBoxWithEmail({
  id,
  name,
  onAdd,
  onCancel,
  show,
  ownedCount,
  validEmails,
  invalidEmails,
  initValue,
  fieldValue,
  onChange,
  isSingle,
}) {
  const isSeperatorExist = newUsersStr => newUsersStr.indexOf(',') !== -1 || newUsersStr.indexOf(';') !== -1;
  const isMultiAdd = !isSingle && isSeperatorExist(fieldValue);
  return (
    <div
      className={classname(
        styles.CollectionManage__confirm,
        { [styles.CollectionManage__confirm__show]: show }
      )}
    >
      <CollectionManageBackButton onClick={onCancel} />
      <div className={styles.CollectionManage__confirm__box}>
        <div className={styles.CollectionManage__owner}>
          {validEmails.length
            ? (
              <div>
                <h3><Translation value={`collection.manage.${name}.done.heading`} /></h3>
                <p>
                  <Translation
                    value={`collection.manage.${name}.done.message${validEmails.length > 1 ? '.multi' : ''}`}
                    params={{
                      emailsStr: validEmails.length > 1
                        ? validEmails.slice(0, validEmails.length - 1).join(', ')
                        : validEmails[0],
                      lastEmailStr: validEmails[validEmails.length - 1],
                    }}
                  />
                </p>
              </div>
            )
            : (
              <div>
                <h3><Translation value={`collection.manage.${name}.heading`} /></h3>
                <p>
                  <Translation
                    value={`collection.manage.${name}.message${ownedCount > 1 ? '.multi' : ''}`}
                    params={{ count: ownedCount }}
                  />
                </p>
              </div>
            )
          }
          {(invalidEmails.length || initValue) &&
            <FormControl
              label={<Translation value="label.email" />}
              compact
            >
              <AddressBookControl
                showError={false}
                autoFocus
                value={invalidEmails.length ? invalidEmails.join() : fieldValue}
                onChange={val => onChange(val)}
              />
            </FormControl>
          }
          {invalidEmails.length > 0 &&
            <div className={styles.CollectionManage__owner__error}>
              <Validation>
                <Translation value="collection.manage.invalid-email" />
              </Validation>
            </div>
          }
        </div>
        {(invalidEmails.length || initValue) &&
          <Button
            variant="primary"
            label={<Translation value={isMultiAdd ? `button.${id}s` : `button.${id}`} />}
            onClick={onAdd}
          />
        }
      </div>
    </div>
  );
}

CollectionManageBoxWithEmail.defaultProps = {
  isSingle: false,
};
if (process.env.NODE_ENV !== 'production') {
  CollectionManageBoxWithEmail.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    onAdd: PropTypes.func,
    onCancel: PropTypes.func,
    show: PropTypes.bool,
    ownedCount: PropTypes.number,
    validEmails: PropTypes.arrayOf(PropTypes.string),
    invalidEmails: PropTypes.arrayOf(PropTypes.string),
    initValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    fieldValue: PropTypes.string,
    onChange: PropTypes.func,
    isSingle: PropTypes.bool,
  };
}

export default CollectionManageBoxWithEmail;
