import { Enum } from '@pageproof/sdk';
import { hasProofSetup, getProofSetup, updateProofSetupProof } from './proofSetup';
import { deleteBlob } from './temporaryReferences';
import { sdk } from '../../../util/sdk';

const statuses = {
  [Enum.FileStatus.CREATED]: 'ready',
  [Enum.FileStatus.QUEUED]: 'processing',
  [Enum.FileStatus.RIPPING]: 'processing',
  [Enum.FileStatus.ERROR]: 'error',
  [Enum.FileStatus.OK]: 'ok',
};

export default function updateProcessingFileStatuses() {
  if (!hasProofSetup()) {
    return;
  }

  const proofSetup = getProofSetup();
  const processingProofs = proofSetup.proofs.filter(proof => (
    proof.file &&
    proof.file.id &&
    (proof.file.status === 'processing' || proof.file.status === 'ready')
  ));

  /* eslint-disable-next-line consistent-return */
  return Promise.all(processingProofs.map(proof => (
    sdk.files.details(proof.file.id)
      .then((file) => {
        const mappedStatus = statuses[file.status];
        if (mappedStatus) {
          updateProofSetupProof(proof._id, (setupProof) => {
            /* eslint-disable no-param-reassign */
            if (setupProof) {
              if (setupProof.file && setupProof.file.id === proof.file.id) {
                setupProof.file.status = mappedStatus;

                if (file.status === Enum.FileStatus.OK && setupProof.file.thumbnailReference) {
                  deleteBlob(setupProof.file.thumbnailReference);
                  delete setupProof.file.thumbnailReference;
                }

                if (file.sourceMetadata && file.sourceMetadata.subject && !setupProof.name) {
                  setupProof.name = file.sourceMetadata.subject;
                }
              }
            }
            /* eslint-enable no-param-reassign */
          });
        }
      })
  )));
}
