/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../Button/IconButton';
import css from './CollectionProofDownloadButton.scss';

const CollectionProofDownloadButtonPropTypes = {
  onClick: PropTypes.func,
};

const CollectionProofDownloadButton = ({ onClick }) => (
  <IconButton
    ariaLabel="Download proof"
    src="img/interface/download.svg"
    className={css.CollectionProofDownloadButton}
    onClick={onClick}
  />
);

CollectionProofDownloadButton.propTypes = CollectionProofDownloadButtonPropTypes;

export default CollectionProofDownloadButton;
