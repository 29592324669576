/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import DashboardNavigationContainer from '../DashboardNavigation';

function TeamDashboardContainer(props) {
  return (
    <DashboardNavigationContainer
      {...props}
      color="green"
      title="domain-admin.team-dashboard.header"
      from="team"
    />
  );
}

export default TeamDashboardContainer;
