/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './HighlightedText.css';

const HighlightedText = ({parentText, subtext}) => {
  const index = parentText.indexOf(subtext);

  if (index !== -1) {
    const splitText = parentText.split(subtext.toLowerCase());
    return (
      <span>
        {splitText.map((text, i, arr) => (
          text
            ? (
              <span key={i}>
                {text}
                {arr[i + 1] &&
                  <span
                    key={i}
                    className={styles.highlighted}
                  >
                    {subtext}
                  </span>
                }
              </span>
            )
            : (
              <span
                key={i}
                className={styles.highlighted}
              >
                {subtext}
              </span>
            )
        ))}
      </span>
    );
  } else {
    return <span>{subtext}</span>;
  }
};

if (process.env.NODE_ENV !== 'production') {
  HighlightedText.propTypes = {
    parentText: PropTypes.string,
    subtext: PropTypes.string,
  };
}

export default HighlightedText;
