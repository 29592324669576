/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import { Translation, TranslatedProps, Ellipsis } from '../../components/Text';
import { Button } from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import styles from './CropAvatarModal.scss';

function CropAvatarModal({ url, onCropped, onCancel }) {
  const [isAdding, setIsAdding] = useState(false);
  const cropperRef = useRef(null);

  const handleOnAdd = () => {
    const cropper = cropperRef.current.cropper;

    if (cropper) {
      setIsAdding(true);

      const canvas = cropper.getCroppedCanvas();
      if (canvas.msToBlob) {
        onCropped(canvas.msToBlob());
      } else {
        canvas.toBlob(onCropped);
      }
    }
  };

  return (
    <Fragment>
      <TranslatedProps alt="avatar.alt">
        <Cropper
          className={styles.CropAvatarModal}
          src={url}
          ref={cropperRef}
          aspectRatio={1}
          viewMode={1}
          minCropBoxWidth={100}
          minCropBoxHeight={100}
          movable={false}
          rotatable={false}
          scalable={false}
          zoomable={false}
        />
      </TranslatedProps>
      <br />
      <ButtonGroup>
        <Button
          variant="text"
          label={<Translation value="avatar.cancel" />}
          onClick={onCancel}
        />
        <Button
          variant="primary"
          disabled={isAdding}
          label={isAdding
            ? (
              <Fragment>
                <Translation value="button.add.active" />
                <Ellipsis />
              </Fragment>
            )
            : <Translation value="avatar.add" />}
          onClick={handleOnAdd}
        />
      </ButtonGroup>
    </Fragment>
  );
}

if (process.env.NODE_ENV !== 'production') {
  CropAvatarModal.propTypes = {
    url: PropTypes.string.isRequired,
    onCropped: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };
}

export default CropAvatarModal;
