/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment} from 'react';
import UnifiedLogin from '../UnifiedLogin';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button/Button';
import {Translation} from '../Text';
import {Spinner} from '../Spinner';

function TeamInvitation({
  loading,
  error,
  team,
  onAccept,
  onDecline,
}) {
  if (!team && !error) {
    return (
      <Spinner
        style={{
          position: 'absolute',
          top: 'calc(50% - 30px)',
          left: 'calc(50% - 30px)',
        }}
      />
    );
  }

  return (
    <Fragment>
      <div style={{height: 40}} />
      <UnifiedLogin
        loading={loading}
        mode={error ? 'message' : ':custom'}
        message={error || 'team-invitation'}
        avatar={team && team.logo}
        team={team && team.name}
      >
        {!error && (
          <ButtonGroup>
            <Button
              autoWidth
              variant="text"
              label={<Translation value="team-invitation.decline" />}
              onClick={onDecline}
              disabled={loading}
            />
            <Button
              variant="primary"
              label={<Translation value="team-invitation.accept" />}
              onClick={onAccept}
              disabled={loading}
            />
          </ButtonGroup>
        )}
      </UnifiedLogin>
    </Fragment>
  );
}

export default TeamInvitation;
