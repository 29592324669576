/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { sdk } from '../util/sdk';

function getUserFeatures(resolveAsync = true) {
  const { user } = sdk.session;
  const features = user.features || {};

  const userFeatures = {
    canCreateNewProofs: features.createNewProofs === true,
    hasProofReferences: features.proofReference === true,
    isProofReferencesRequired: features.proofReferenceRequired === true,
    hasChatSupport: features.supportChat === true,
    canShareCollectionsWithTeam: user.teamType !== 0,
    hasPublicProofs: features.publicProofs === true,
    defaultProofIsPublic: features.defaultProofIsPublic === true,
    hasChecklists: features.enableChecklists === true,
    hasProofTemplates: features.enableProofTemplates === true,
    hasCommentVisibility: features.enableCommentVisibility === true,
    availableWorkflowRoles: features.availableWorkflowRoles,
    defaultCommentVisibility: features.defaultCommentVisibility || null,
  };

  if (resolveAsync) {
    return Promise.resolve(userFeatures);
  }

  return userFeatures;
}

function useUserFeatures() {
  return [false, getUserFeatures(false)];
}

export default useUserFeatures;
export { getUserFeatures, useUserFeatures };
