/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useRef, useState } from 'react';
import InputOptions from '../InputOptions';
import SideBySide from '../../../Transitions/SideBySide';
import css from './IntegrationReferenceSelector.scss';
import { sdk } from '../../../../util/sdk';
import { Spinner } from '../../../Spinner';
import BackButton from './BackButton';
import SearchableSelector from './SearchableSelector';
import getIntegrationFileUrl from '../../../../util/integration-service-static-files';
import useDebounce from '../../../../hooks/useDebounce';

const HIDDEN_SELECTOR_IDS = ['mondayItemByBoard'];

const IntegrationReferenceSelector = ({ onSelectReferenceOption, popoverProps, integrationReferences, isCurrentUserInProofTeam }) => {
  const [reverse, setReverse] = useState(false);
  const [currentIntegrationKey, setCurrentIntegrationKey] = useState();
  const [currentSelectorId, setCurrentSelectorId] = useState();
  const [integrations, setIntegrations] = useState();
  const [integrationOptions, setIntegrationOptions] = useState({});
  const [noIntegrationsMessage, setNoIntegrationsMessage] = useState('References to apps connected to PageProof can be found here');

  const [integrationsBeingLoaded, setIntegrationsBeingLoaded] = useState([]);
  const [referenceTypeSelectorsBeingLoaded, setReferenceTypeSelectorsBeingLoaded] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const pendingSearchesRef = useRef();

  const [mondayBoardId, _setMondayBoardId] = useState();
  const mondayBoardIdRef = useRef(mondayBoardId);
  const setMondayBoardId = (data) => {
    mondayBoardIdRef.current = data;
    _setMondayBoardId(data);
  };

  const getReferenceType = (integrationKey, selectorId) => integrationOptions[integrationKey].find(referenceType => referenceType.selectorId === selectorId || referenceType.id === selectorId);

  const getCurrentReferenceType = () => getReferenceType(currentIntegrationKey, currentSelectorId);

  const loadIntegrationOptions = (integrationKey, rehydrateCache = false) => {
    if (integrationsBeingLoaded.includes(integrationKey)) {
      return;
    }

    setIntegrationsBeingLoaded([...integrationsBeingLoaded, integrationKey]);
    sdk.proofs.integrationReferences.options(integrationKey, { rehydrate_cache: rehydrateCache })
      .then((result) => {
        setIntegrationOptions({
          ...integrationOptions,
          [integrationKey]: result.map(referenceType => ({
            selectorId: referenceType.id, // Not all reference types have a selectorId so we use the id as a fallback
            ...referenceType,
          })),
        });
        setIntegrationsBeingLoaded(integrationsBeingLoaded.filter(li => li !== integrationKey));
      });
  };

  const loadReferenceTypeOptions = (integrationKey, selectorId, rehydrateCache) => {
    setReferenceTypeSelectorsBeingLoaded(referenceTypes => [...referenceTypes, selectorId]);

    const referenceType = getReferenceType(integrationKey, selectorId);

    const optionsParams = { reference_type: referenceType.id, rehydrate_cache: rehydrateCache, selector_id: selectorId };

    if (referenceType.selectorId === 'mondayItemByBoard') {
      optionsParams.monday_board_id = mondayBoardIdRef.current;
    }

    sdk.proofs.integrationReferences.options(integrationKey, optionsParams)
      .then(([result]) => {
        setIntegrationOptions((previousIntegrationOptions) => {
          if (result.selectorId === 'mondayItemByBoard' && result.mondayBoardId !== mondayBoardIdRef.current) {
            return previousIntegrationOptions;
          }

          const previousIntegrationReferenceTypes = [...previousIntegrationOptions[integrationKey]];

          const referenceTypeIndex = previousIntegrationReferenceTypes.findIndex(prevReferenceType => prevReferenceType.selectorId === referenceType.selectorId);
          previousIntegrationReferenceTypes[referenceTypeIndex] = {
            selectorId: result.id, // Not all reference types have a selectorId so we use the id as a fallback
            ...result,
          };

          const nextIntegrationOptions = {
            ...previousIntegrationOptions,
            [integrationKey]: previousIntegrationReferenceTypes,
          };

          return nextIntegrationOptions;
        });
        setReferenceTypeSelectorsBeingLoaded(referenceTypes => referenceTypes.filter(type => type !== selectorId));
      });
  };

  const serverSideSearch = (integrationKey, referenceTypeId, selectorId, term, rehydrateCache = false) => {
    if (term.length < getReferenceType(integrationKey, selectorId).minimumSearchLength) {
      setReferenceTypeSelectorsBeingLoaded(referenceTypeSelectorsBeingLoaded.filter(type => type !== selectorId));
      return;
    }

    setReferenceTypeSelectorsBeingLoaded(referenceTypes => [...referenceTypes, selectorId]);

    sdk.proofs.integrationReferences.options(integrationKey, { reference_type: referenceTypeId, search_term: term, rehydrate_cache: rehydrateCache, selector_id: selectorId })
      .then((result) => {
        if (pendingSearchesRef.current[selectorId] === result[0].searchTerm) {
          setReferenceTypeSelectorsBeingLoaded(referenceTypes => referenceTypes.filter(type => type !== selectorId));
          setSearchResults({
            ...searchResults,
            [selectorId]: result[0],
          });
        }
      });
  };

  const debouncedServerSideSearch = useDebounce(serverSideSearch, 500);

  const search = (term) => {
    setSearchTerm(term);

    const referenceType = getCurrentReferenceType();
    if (referenceType.serverSideSearch) {
      if (term.length < referenceType.minimumSearchLength) {
        setSearchResults({
          ...searchResults,
          [referenceType.selectorId]: null,
        });
      }
      pendingSearchesRef.current = { ...pendingSearchesRef.current, [referenceType.selectorId]: term };
      debouncedServerSideSearch(currentIntegrationKey, referenceType.id, referenceType.selectorId, term);
    }
  };

  const reloadIntegrationOptions = () => {
    const referenceType = getCurrentReferenceType();
    if (searchTerm && referenceType && referenceType.serverSideSearch) {
      serverSideSearch(currentIntegrationKey, referenceType.id, referenceType.selectorId, searchTerm, true);
    } else {
      const additionalOptionsParams = currentSelectorId === 'mondayItemByBoard' ? { monday_board_id: mondayBoardId } : {};
      loadReferenceTypeOptions(currentIntegrationKey, currentSelectorId, true, additionalOptionsParams);
    }
  };

  const selectIntegration = (key) => {
    setSearchTerm('');

    setReverse(!key);
    setCurrentIntegrationKey(key);

    if (key && !integrationOptions[key]) {
      loadIntegrationOptions(key);
    }
  };

  const selectReferenceType = (selectorId, forceLoad = false) => {
    const referenceType = getReferenceType(currentIntegrationKey, selectorId);
    // Lazy load options if they need to be lazy loaded and are not already being loaded
    if (referenceType && (forceLoad || (!referenceType.options && referenceType.lazyLoad && !referenceTypeSelectorsBeingLoaded.includes(selectorId)))) {
      loadReferenceTypeOptions(currentIntegrationKey, selectorId, false);
    }

    setSearchTerm(searchResults[selectorId] ? searchResults[selectorId].searchTerm : '');
    setReverse(!selectorId || (selectorId === 'mondayItemByBoardBoard' && currentSelectorId === 'mondayItemByBoard'));

    setCurrentSelectorId(selectorId);
  };

  useEffect(() => {
    if (!isCurrentUserInProofTeam) {
      setNoIntegrationsMessage('This proof belongs to a different team. Your integration references are not available.');
      setIntegrations([]);
      return;
    }

    sdk.proofs.integrationReferences.availableIntegrations()
      .then((result) => {
        setIntegrations(result);
      });
  }, []);


  const selectIntegrationOption = (option) => {
    setSearchTerm('');

    setReverse(false);
    setCurrentIntegrationKey();
    setCurrentSelectorId();

    onSelectReferenceOption(option);
  };

  const spinner = (
    <div className={css.Holder__spinner}>
      <Spinner
        color="white"
        size={40}
      />
    </div>
  );

  return (
    <InputOptions
      popoverProps={popoverProps}
      options={popover => (
        <SideBySide
          id={currentSelectorId || currentIntegrationKey || 'integrations'}
          reverse={reverse}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {!currentIntegrationKey
            ? (
              <div className={css.Holder}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {!integrations

                  ? spinner
                  : integrations.length
                    ? integrations.map(integration => (
                      <InputOptions.Option
                        key={integration.integrationKey}
                        label={(
                          <div className={css.IntegrationLabel}>
                            <img
                              alt=""
                              className={css.IntegrationLabel__icon}
                              src={getIntegrationFileUrl('icon', integration.integrationKey, ['dark'])}
                            />
                            <span>
                              {integration.label}
                            </span>
                          </div>
                        )}
                        onClick={() => {
                          selectIntegration(integration.integrationKey);
                        }}
                      />
                    ))
                    : (
                      <InputOptions.Option
                        className={css.NoIntegrationsMessage}
                        label={noIntegrationsMessage}
                        disabled
                      />
                    )
                }
              </div>
            )
            : currentSelectorId ? (
              <SearchableSelector
                referenceType={searchResults[currentSelectorId] || getReferenceType(currentIntegrationKey, currentSelectorId)}
                searchTerm={searchTerm}
                onClickBack={() => {
                  if (currentSelectorId === 'mondayItemByBoard') {
                    selectReferenceType('mondayItemByBoardBoard');
                    return;
                  }
                  selectReferenceType();
                }}
                onSearch={search}
                selectOption={(option) => {
                  if (currentSelectorId === 'mondayItemByBoardBoard') {
                    const selectedBoardId = option.referenceId;
                    let forceLoad = false;
                    if (selectedBoardId !== mondayBoardIdRef.current) {
                      forceLoad = true;
                      setIntegrationOptions((previousIntegrationOptions) => {
                        const previousIntegrationReferenceTypes = [...previousIntegrationOptions['monday.com']];

                        const referenceTypeIndex = previousIntegrationReferenceTypes.findIndex(prevReferenceType => prevReferenceType.selectorId === 'mondayItemByBoard');
                        previousIntegrationReferenceTypes[referenceTypeIndex] = {
                          ...previousIntegrationReferenceTypes[referenceTypeIndex],
                          options: undefined,
                        };

                        const nextIntegrationOptions = {
                          ...previousIntegrationOptions,
                          'monday.com': previousIntegrationReferenceTypes,
                        };

                        return nextIntegrationOptions;
                      });

                      setMondayBoardId(selectedBoardId);
                    }
                    selectReferenceType('mondayItemByBoard', forceLoad, { monday_board_id: selectedBoardId });
                    return;
                  }

                  selectIntegrationOption({
                    ...option,
                    referenceType: getCurrentReferenceType().id,
                    integration: integrations.find(integration => integration.integrationKey === currentIntegrationKey),
                  });
                  popover.hide();
                }}
                selectedOptions={integrationReferences ? integrationReferences.filter(reference => reference.referenceType === getCurrentReferenceType().id) : []}
                hasSearchableBreadcrumbs={getCurrentReferenceType().hasSearchableBreadcrumbs}
                isLoadingOptions={integrationsBeingLoaded.includes(currentIntegrationKey) || referenceTypeSelectorsBeingLoaded.includes(currentSelectorId)}
                onReloadReferenceOptions={reloadIntegrationOptions}
              />
            )
              : (
                <div className={css.Holder}>
                  <BackButton
                    label={integrations.find(integration => integration.integrationKey === currentIntegrationKey).label}
                    onClick={selectIntegration}
                  />
                  {
                    integrationOptions[currentIntegrationKey]
                      ? integrationOptions[currentIntegrationKey].filter(referenceType => !HIDDEN_SELECTOR_IDS.includes(referenceType.selectorId)).map(referenceType => (
                        <InputOptions.Option
                          key={referenceType.selectorId}
                          label={(
                            <div className={css.Holder__label}>
                              {referenceType.label}
                              {referenceType.sublabel && (
                                <div className={css.Holder__label__sublabel}>
                                  {referenceType.sublabel}
                                </div>
                              )}
                            </div>
                          )}
                          onClick={() => {
                            selectReferenceType(referenceType.selectorId);
                          }}
                        />
                      ))
                      : spinner
                  }
                </div>
              )
          }
        </SideBySide>
      )
      }
    />
  );
};

export default IntegrationReferenceSelector;
