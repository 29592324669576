/* eslint-disable */

import randomId from './randomId';
import renderImage from './renderImage';
import renderPDF from './renderPDF';
import renderPSD from './renderPSD';
import renderVideo from './renderVideo';

let noConflictIndex = 0;
const WEEK_IN_MS = 1000 * 60 * 60 * 24 * 7;

const thumbnailRenderSize = 160;

function generateThumbnail(blob, extension) {
  const { temporaryStorageService, utilService } = window.__pageproof_bridge__;
  const id = utilService.sha512('generateThumbnail' + Date.now() + randomId() + (++noConflictIndex));
  const saveThumbnail = (thumbnail) => temporaryStorageService.set(id, thumbnail, Date.now() + WEEK_IN_MS);

  let renderer = null;

  switch (String(extension).toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'svg':
    case 'ico':
    case 'cur':
    case 'png': {
      renderer = renderImage(blob, thumbnailRenderSize);
      break;
    }
    case 'mov':
    case 'mkv':
    case 'mp4': {
      renderer = renderVideo(blob, thumbnailRenderSize);
      break;
    }
    case 'pdf': {
      renderer = renderPDF(blob, 1, thumbnailRenderSize);
      break;
    }
    case 'psd': {
      renderer = renderPSD(blob, thumbnailRenderSize);
      break;
    }
  }

  if (renderer) {
    return renderer
      .then(saveThumbnail)
      .then(() => id)
      .catch(() => {
        // Ignore any errors - this isn't a critical part of the app.
        // Just return a null, as if we didn't have a renderer.
        return null;
      });
  } else {
    return Promise.resolve(null);
  }
}

function oneAtATime(promiseReturningFunction) {
  let chain = Promise.resolve();

  return (...args) => {
    chain = chain.then(() => (
      Promise.resolve(promiseReturningFunction(...args))
        .catch(() => {})
    ));
    return chain;
  };
}

export default generateThumbnail;
