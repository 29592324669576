import React, { useEffect, useRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import DateTimePicker from '../components/DateTimePicker/DateTimePicker';
import { buildWrapper } from '../register';

const WrappedDateTimePicker = buildWrapper(DateTimePicker);

export function useDateTimePicker() {
  const mountRef = useRef();
  const dateTimePickerRef = useRef();

  function open() {
    if (mountRef.current) {
      throw new Error('DateTimePicker already open.');
    }
    mountRef.current = document.createElement('div');
    document.body.appendChild(mountRef.current);
    return new Promise((resolve) => {
      render(
        <WrappedDateTimePicker
          ref={dateTimePickerRef}
          onDismiss={close}
          onChange={(value) => {
            close();
            resolve(value);
          }}
        />,
        mountRef.current,
      );
      setTimeout(() => {
        // Call the date picker component's show method to kickstart the flow.
        // This needs to be called within a setTimeout as calling it immediately causes an error to be thrown.
        dateTimePickerRef.current.datePicker.show();
      }, 1);
    });
  }

  function close() {
    if (mountRef.current) {
      const currentMountElement = mountRef.current;
      // Delay unmounting to allow dialog closing animation to complete first
      setTimeout(() => {
        unmountComponentAtNode(currentMountElement);
        currentMountElement.remove();
      }, 1000);
      mountRef.current = null;
    }
  }

  useEffect(() => close, []);
  return [open, close];
}
