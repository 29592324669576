/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import HelpBubble from '../../HelpBubble';
import CommentStatusCountBar from '../CommentStatusCountBar';
import css from './ImportPdfCommentsSummary.scss';
import { Translation } from '../../Text';

const countToQuantity = (count) => {
  if (count === 1) {
    return 'one';
  } else if (count > 1) {
    return 'many';
  } else {
    return 'zero';
  }
};

/**
 * Outer keys = number of comments
 * Inner keys = number of replies
 */
const summaryTranslationKeys = {
  zero: {
    zero: 'proof.import-comments.summary.total.none',
    one: 'proof.import-comments.summary.total.reply.single',
    many: 'proof.import-comments.summary.total.reply.multi',
  },
  one: {
    zero: 'proof.import-comments.summary.total.comment.single',
    one: 'proof.import-comments.summary.total.comment.single.reply.single',
    many: 'proof.import-comments.summary.total.comment.single.reply.multi',
  },
  many: {
    zero: 'proof.import-comments.summary.total.comment.multi',
    one: 'proof.import-comments.summary.total.comment.multi.reply.single',
    many: 'proof.import-comments.summary.total.comment.multi.reply.multi',
  },
};

/**
 * Outer keys = number of comments
 * Inner keys = number of replies
 */
const skippedCommentsTranslationKeys = {
  // These 0 comment strings probably can't be hit in reality, but just in case
  zero: {
    zero: 'proof.import-comments.summary.skipped.none',
    one: 'proof.import-comments.summary.skipped.reply.single',
    many: 'proof.import-comments.summary.skipped.reply.multi',
  },
  one: {
    zero: 'proof.import-comments.summary.skipped.comment.single',
    one: 'proof.import-comments.summary.skipped.comment.single.reply.single',
    many: 'proof.import-comments.summary.skipped.comment.single.reply.multi',
  },
  many: {
    zero: 'proof.import-comments.summary.skipped.comment.multi',
    one: 'proof.import-comments.summary.skipped.comment.multi.reply.single',
    many: 'proof.import-comments.summary.skipped.comment.multi.reply.multi',
  },
};

export const ImportPdfCommentsSummary = (props) => {
  const {
    importedComments,
    unsupportedAnnotationsImported,
    showCommentStatusCounts,
    skippedComments,
    skippedReplies,
  } = props;

  return (
    <div className={css.ImportPdfCommentsSummary}>
      {(!showCommentStatusCounts || !!importedComments.replies) && (
        <div className={css.ImportPdfCommentsSummary__message}>
          <Translation
            value={summaryTranslationKeys[countToQuantity(importedComments.comments)][countToQuantity(importedComments.replies)]}
            params={{ commentCount: importedComments.comments, replyCount: importedComments.replies }}
          />
        </div>
      )}
      {showCommentStatusCounts && (
        <CommentStatusCountBar
          notMarked={importedComments.unmarked}
          todos={importedComments.todo}
          dones={importedComments.done}
          showTotal
          showTooltip
          hasMargin={false}
        />
      )}
      {(!!skippedComments || !!skippedReplies) && (
        <div className={css.ImportPdfCommentsSummary__unsupportedAnnotations}>
          <div className={css.ImportPdfCommentsSummary__unsupportedAnnotations__message}>
            <Translation
              value={skippedCommentsTranslationKeys[countToQuantity(skippedComments)][countToQuantity(skippedReplies)]}
              params={{ commentCount: skippedComments, replyCount: skippedReplies }}
            />
          </div>
        </div>
      )}
      {!!unsupportedAnnotationsImported && (
        <div className={css.ImportPdfCommentsSummary__unsupportedAnnotations}>
          <div className={css.ImportPdfCommentsSummary__unsupportedAnnotations__message}>
            {unsupportedAnnotationsImported > 1
              ? (
                <Translation
                  value="proof.import-comments.summary.unsupported.multi"
                  params={{ unsupportedCount: unsupportedAnnotationsImported }}
                />
              )
              : <Translation value="proof.import-comments.summary.unsupported.single" />
            }
          </div>
          <HelpBubble
            size="small"
            message={<Translation value="proof.import-comments.summary.unsupported.tooltip" />}
          />
        </div>
      )}
    </div>
  );
};
