/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './BulkGeneralCommentsPopover.scss';
import OptionGroup from '../OptionGroup';
import { Translation } from '../Text';
import { Option } from '../PopupMenu';
import useDarkMode from '../../hooks/useDarkMode';
import TextButtonTool from '../OptionGroup/TextButtonTool';
import Tooltip, { onlyWhenTruncated } from '../Tooltip/Tooltip';
import CommentSpacing from '../Comment/CommentSpacing';
import Wrapper from '../Comment/Wrapper';
import Comment from '../Comment/Comment';
import { hasValue } from '../Comment/Editor/serialization';
import Pill from '../Pill';
import Cross from '../Icon/Cross';
import ProgressCircle from '../ProgressCircle';

const BulkGeneralCommentPopover = ({
  proofs,
  onSelectChange,
  onSelectAll,
  allSelected,
  user,
  onSave,
  commentValue,
  onCommentValueChange,
  createdCommentCount,
}) => {
  const [isDarkMode] = useDarkMode();
  const noProofsSelected = !proofs.some(proof => proof.selected);
  const isCommenting = proofs.some(proof => proof.progress > 0);

  return (
    <div className={css.BulkGeneralCommentPopover}>
      <OptionGroup
        label={
          <div className={css.BulkGeneralCommentPopover__heading}>
            <Translation value="bulk-general-comments.popover.heading.comment" />
          </div>
        }
      >
        <div className={css.BulkGeneralCommentPopover__comment}>
          <CommentSpacing.Provider value="condensed">
            <Wrapper noShadow>
              <Comment
                key={createdCommentCount}
                editButtonTooltip={noProofsSelected && <Translation value="bulk-general-comments.one-or-more-proofs.warning" />}
                placeholder={<Translation value="comment-create.placeholder" />}
                canSave={proofs.some(proof => proof.selected) && hasValue(commentValue) && !isCommenting}
                onSave={onSave}
                onChange={onCommentValueChange}
                value={commentValue}
                user={user}
                isEditing
                isCreate
                isSelected
                canMark={false}
              />
            </Wrapper>
          </CommentSpacing.Provider>
        </div>
      </OptionGroup>
      <OptionGroup
        label={
          <div className={css.BulkGeneralCommentPopover__heading}>
            <Translation value="bulk-general-comments.popover.heading.proofs" />
            {noProofsSelected && (
              <Pill
                label={<Translation value="bulk-general-comments.one-or-more-proofs.warning" />}
                variant="warning"
              />
            )}
          </div>}
        tool={proofs.length > 1 && (
          <TextButtonTool
            disabled={isCommenting}
            allSelected={allSelected}
            label={
              <Translation
                value={allSelected
                  ? 'bulk-general-comments.popover.deselect-all'
                  : 'bulk-general-comments.popover.select-all'
                }
              />
            }
            onClick={() => onSelectAll()}
          />
        )}
      >
        {proofs.map(({ id, name, selected, progress, error }) => (
          <Tooltip
            key={id}
            middle
            right
            title={name}
            onBeforeOpen={onlyWhenTruncated(`.${css.BulkGeneralCommentPopover__option__label}`)}
            offset={20}
            delay={1000}
          >
            {tooltip => (
              <div>
                <Option
                  className={css.BulkGeneralCommentPopover__option}
                  variant={!isDarkMode && 'light'}
                  label={
                    <div className={css.BulkGeneralCommentPopover__option__label}>
                      {name}
                    </div>
                  }
                  onClick={() => {
                    if (!error && !progress) {
                      onSelectChange({ [id]: !selected });
                    }
                  }}
                  checked={selected || error}
                  disabled={isCommenting || error}
                  checkedIcon={((typeof progress === 'number') && (
                    <ProgressCircle
                      size={20}
                      width={3}
                      value={progress}
                    />
                  )) || (error && (
                    <Tooltip
                      title={<Translation value="bulk-general-comments.unsuccessful-comment-create.error" />}
                      middle
                      right
                      offset={40}
                    >
                      <Cross
                        onMouseEnter={() => tooltip.hide()}
                        active
                        readOnly
                        activeColor="#e51c23"
                        size={20}
                      />
                    </Tooltip>
                  ))}
                />
              </div>
            )}
          </Tooltip>
        ))}
      </OptionGroup>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  BulkGeneralCommentPopover.propTypes = {
    proofs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      progress: PropTypes.number,
    })).isRequired,
    onSelectChange: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    allSelected: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    onSave: PropTypes.func.isRequired,
    commentValue: PropTypes.string.isRequired,
    onCommentValueChange: PropTypes.func.isRequired,
    createdCommentCount: PropTypes.number.isRequired,
  };
}

export default BulkGeneralCommentPopover;
