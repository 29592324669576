import React from 'react';
import SingleComment from './SingleComment';

export default function CommentsPaneComment({ comment, proofCtrl, proofHelper, spacing }) {
  const showMark = (
    !comment.isPrivate &&
    (
      (
        proofCtrl.permissions.proofer.commentLevel.canViewChanged ||
        (proofCtrl.permissions.proofer.commentLevel.canViewDone && comment.isDone)
      ) ||
      (
        proofCtrl.permissions.proofer.commentLevel.canMarkAsChanged ||
        proofCtrl.permissions.proofer.commentLevel.canMarkAsDone
      )
    )
  );

  return (
    <div style={{ padding: '0 20px 20px' }}>
      <SingleComment
        proofCtrl={proofCtrl}
        comment={comment}
        showMark={showMark}
        canAddCommentReplies
        proofHelper={proofHelper}
        spacing={spacing}
      />
    </div>
  );
}
