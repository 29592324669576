/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect } from 'react';
import Progress from '../../components/Progress';
import LocalStorage from '../../components/LocalStorage';

const ProgressContainer = () => {
  const { appService } = window.__pageproof_bridge__;

  appService.hideLoader();

  useEffect(() => () => {
    window.localStorage.removeItem('pageproof.app.progress');
    window.localStorage.removeItem('pageproof.app.uploadFinished');
  });

  return (
    <LocalStorage.Value
      name="pageproof.app.progress"
      defaultValue={100}
    >
      {({ value: progress }) => {
        const percentComplete = parseInt(progress, 10);
        return (
          <Progress
            progress={percentComplete}
            total={1}
            type="proof" // Briefs are handled in brief uploader.
            status={percentComplete < 100 ? 1 : 2}
          />
        );
      }}
    </LocalStorage.Value>
  );
};

export default ProgressContainer;
