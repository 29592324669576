/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '@jmshal/react-inline-svg';
import css from './MandatoryDecisionThreshold.scss';
import Tooltip from '../Tooltip';
import { Translation } from '../Text';
import { Button } from '../Button';

const MandatoryDecisionThreshold = ({
  allMandatoryDecisionsRequired,
  mandatoryStepUserRoles,
  onClick,
  showDivider,
  disabled,
}) => {
  const roleTranslationKey = (() => {
    let mandatoryCount = 0;
    let gatekeeperCount = 0;

    for (let i = 0; i < mandatoryStepUserRoles.length; i += 1) {
      switch (mandatoryStepUserRoles[i]) {
      case 'mandatory': {
        mandatoryCount += 1;
        break;
      }
      case 'gatekeeper': {
        gatekeeperCount += 1;
        break;
      }
      default: {
        break;
      }
      }
    }

    if (mandatoryCount && !gatekeeperCount) {
      return 'workflow.step.mandatory-decision-threshold.mandatory';
    } else if (!mandatoryCount && gatekeeperCount) {
      return 'workflow.step.mandatory-decision-threshold.gatekeeper';
    } else if (allMandatoryDecisionsRequired) {
      return 'workflow.step.mandatory-decision-threshold.mandatory-and-gatekeeper';
    } else {
      return 'workflow.step.mandatory-decision-threshold.mandatory-or-gatekeeper';
    }
  })();

  return (
    <div className={css.MandatoryDecisionThreshold}>
      {showDivider && <div className={css.MandatoryDecisionThreshold__divider} />}
      <Tooltip
        title={
          <Translation
            value={allMandatoryDecisionsRequired
              ? 'workflow.step.mandatory-decision-threshold.all.tooltip'
              : 'workflow.step.mandatory-decision-threshold.one.tooltip'
            }
            params={{ roleType: <Translation value={roleTranslationKey} /> }}
          />
        }
        center
        up
        maxWidth={250}
      >
        <div>
          <Button
            variant="unstyled"
            className={css.MandatoryDecisionThreshold__button}
            onClick={onClick}
            disabled={disabled}
            label={
              <Fragment>
                <Translation
                  value={allMandatoryDecisionsRequired
                    ? 'workflow.step.mandatory-decision-threshold.all-reviewers'
                    : 'workflow.step.mandatory-decision-threshold.one-mandatory'
                  }
                  params={{ roleType: <Translation value={roleTranslationKey} /> }}
                />
                <div className={css.MandatoryDecisionThreshold__button__icon}>
                  <InlineSVG
                    src={allMandatoryDecisionsRequired
                      ? 'img/icons/group.svg'
                      : 'img/icons/person-no-background.svg'
                    }
                  />
                </div>
              </Fragment>
            }
          />
        </div>
      </Tooltip>
    </div>
  );
};

MandatoryDecisionThreshold.defaultProps = {
  showDivider: false,
  disabled: false,
};

if (process.env.NODE_ENV !== 'production') {
  MandatoryDecisionThreshold.propTypes = {
    allMandatoryDecisionsRequired: PropTypes.bool.isRequired,
    mandatoryStepUserRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClick: PropTypes.func.isRequired,
    showDivider: PropTypes.bool,
    disabled: PropTypes.bool,
  };
}

export default MandatoryDecisionThreshold;
