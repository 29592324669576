/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { Translation } from '../Text';
import { LinkButton } from '../Button';
import { HasFeature } from '../FeatureFlags/HasFeature';
import css from './Progress.scss';
import ProgressCopyShareLinkButton from './ProgressCopyShareLinkButton';
import useIsMobile from '../../hooks/useIsMobile';
import { getProofLink } from '../../util/proof-utils';

const PROGRESS_MESSAGES = ['alt', 'uploading', 'complete'];

const getTranslateString = (type, total, titleType, status) => `${type}-progress.${total > 1 ? 'multiple' : 'single'}.${titleType}.${PROGRESS_MESSAGES[status]}`;

const Progress = ({
  progress,
  total,
  status,
  type,
  collectionId,
  proofs,
}) => {
  const rainbowRef = useRef(null);
  const copiedTimeoutRef = useRef();
  const isMobile = useIsMobile();
  const [isCopied, setIsCopied] = useState(false);
  const individualProofLink = proofs && getProofLink(proofs[0]);
  const url = window.location.origin + (collectionId ? `/dashboard/group?groupId=${collectionId}` : individualProofLink);

  useEffect(() => {
    rainbowRef.current = window.__pageproof_bridge__.rainbowService.load('progress');
    rainbowRef.current.call('onLoad', {});
    rainbowRef.current.ready.then((hooks) => {
      if (hooks.onRainbowTranslation) {
        hooks.onRainbowTranslation();
        window.__pageproof_i18n__.on('did-load-locale-translations', hooks.onRainbowTranslation);
      }
    });
    return () => {
      rainbowRef.current.call('onDestroy');
      rainbowRef.current.ready.then((hooks) => {
        if (hooks.onRainbowTranslation) {
          window.__pageproof_i18n__.off('did-load-locale-translations', hooks.onRainbowTranslation);
        }
      });
    };
  }, []);

  useEffect(() => () => clearTimeout(copiedTimeoutRef.current), []);

  useEffect(() => {
    rainbowRef.current.call('onProgressChange', progress, progress === 100);
  }, [progress]);

  const copyShareButtonOnClick = () => {
    if (isMobile && window.navigator.share) {
      window.navigator.share({ url });
      return;
    }

    clearTimeout(copiedTimeoutRef.current);
    setIsCopied(true);
    window.generalfunction_copyToClipboard(url);
    copiedTimeoutRef.current = window.setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <div
      className={classname(css.Progress, 'upload-progress-container', {
        [css['Progress--white']]: status === 2,
      })}
    >
      <div
        id="background"
        className={css.Progress__background}
        style={{ width: progress + '%' }}
      />
      <div className={css.Progress__title}>
        {total > 1 &&
          <Translation
            value="proof-progress.multiple.of"
            params={{
              number: Math.floor(progress / (100 / total)),
              total,
            }}
            className={classname(css.Progress__title__counter, {
              [css['Progress__title__counter--undisplayed']]: progress === 100,
            })}
          />
        }
        <div className={css.Progress__title__header}>
          <Translation value={getTranslateString(type, total, 'title', status)} />
          <div className={css.Progress__title__subheader}>
            {progress === 100 && type === 'proof'
              ? (
                <HasFeature flag="dashboard">
                  <Translation value={getTranslateString(type, total, 'subtitle', status)} />
                </HasFeature>
              )
              : <Translation value={getTranslateString(type, total, 'subtitle', status)} />
            }
          </div>
        </div>
        {progress === 100 && (type === 'proof' || type === 'brief') &&
          <HasFeature flag="dashboard">
            <LinkButton
              id="progress-dashboard-button"
              path="dashboard"
              href="/dashboard"
              variant="primary"
              className={css.Progress__title__dashboardButton}
              label={<Translation value="button.ok" />}
            />
          </HasFeature>
        }
        {progress === 100 && type === 'proof' &&
          <HasFeature flag="dashboard">
            <div
              className={css.Progress__title__goTo}
              id="progress-go-to-button"
            >
              {collectionId
                ? (
                  <LinkButton
                    path="/dashboard/group"
                    search={{ groupId: collectionId }}
                    href={`/dashboard/group?groupId=${collectionId}`}
                    variant="unstyled"
                    className={css.Progress__title__goTo__button}
                    label={<Translation value="button.go-to-collection" />}
                  />
                )
                : (
                  <LinkButton
                    path={individualProofLink}
                    variant="unstyled"
                    className={css.Progress__title__goTo__button}
                    label={<Translation value="button.go-to-proof" />}
                  />
                )
              }
              <div
                className={classname(css.Progress__title__goTo__copy, {
                  [css['Progress__title__goTo__copy--showButton']]: isMobile || isCopied,
                })}
              >
                <ProgressCopyShareLinkButton
                  isCopied={isCopied}
                  onClick={copyShareButtonOnClick}
                />
              </div>
            </div>
          </HasFeature>
        }
      </div>
    </div>
  );
};

Progress.defaultProps = {
  status: 0,
  type: 'proof',
};

if (process.env.NODE_ENV !== 'production') {
  Progress.propTypes = {
    progress: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    status: PropTypes.number,
    type: PropTypes.string,
    collectionId: PropTypes.string,
    proofs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  };
}

export default Progress;
