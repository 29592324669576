import { useEffect, useRef, useState } from 'react';
import { sdk } from '../util/sdk';

const useIntegrationsClientRef = (level) => {
  const integrationsClientRef = useRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (level === 'team') {
      sdk.admin()
        .then((admin) => {
          integrationsClientRef.current = admin.team.integrations;
          setLoaded(true);
        });
    } else {
      integrationsClientRef.current = sdk.profile.integrations;
      setLoaded(true);
    }
  }, []);

  return [loaded, integrationsClientRef];
};

export default useIntegrationsClientRef;
