/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import env from '../../../shared/env';

export const INITIAL_PROOF_DUE = 'in 3 days';
export const DEFAULT_COMMENT_DURATION = 0;
export const MINIMUM_COMMENT_SPAN_DURATION = 0.6;
export const MINIMUM_VIDEO_DURATION_FOR_SPAN = 1.0;
export const PROOFS_PER_PAGE = 50;
export const DASHBOARD_REFRESH_MS = 30 * 1000; // 30 sec
export const DASHBOARD_LIMIT = Number(env.dashboard_proof_limit || 30);
export const COMBINED_PDF_MAX_IMAGE_SIZE_IN_PX = Number(env.combined_pdf_max_image_size_in_px || 1200);
export const MAX_PROOF_NAME_LENGTH = 255;
export const MAX_PROOF_REFERENCE_LENGTH = 512;
export const MAX_COLLECTION_NAME_LENGTH = 128;
export const MAX_AVATAR_FILE_SIZE = 2000000;
export const AVATAR_FILE_TYPES = ['png', 'jpeg', 'jpg', 'svg', 'gif'];
export const MAX_SHARE_LINK_MESSAGE_LENGTH = 255;
export const DEFAULT_SPRITE_WIDTH = 100;
export const DEFAULT_SPRITE_HEIGHT = 136;

/* MEDIA */
export const S_BREAK_LXXX = 1600;
export const S_BREAK_LXX = 1500;
export const S_BREAK_LX = 1400;
export const S_BREAK_L = 1200;
export const S_BREAK_MXX = 1050;
export const S_BREAK_MX = 900;
export const S_BREAK_M = 750;
export const S_BREAK_S = 600;

export const DEFAULT_DPI = 72;
export const INCH_TO_MM = 25.4;
export const ASPECT_RATIO_ACCURACY = 3; // Decimal places for accuracy
