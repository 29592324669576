/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {DEFAULT_COMMENT_DURATION} from '../../util/constants';
import Pin from './Pin';

// ToDo: Replace <span /> with <Fragment /> once updated to React 16.2
const Pins = ({
  comments,
  selectedCommentId,
  editingPinIndex,
  lineWidth,
  isSelectingDuration,
  userId,
  startSpan,
  timeToPosition,
  timeToWidth,
  getPinColour,
  getDirection,
  selectPin,
  deselectPin,
  isSpanAllowed,
  hasNewVersion,
  onPinHover,
}) => (
  <Fragment>
    {comments.flatMap(comment => comment.pins.map((pin, pinIndex) => {
      const {
        id,
        ownerId,
        isDone,
        isTodo,
      } = comment;
      const {
        time: pinTime,
        duration: pinDuration,
      } = pin;

      const leftPosition = timeToPosition(pinTime);
      const colour = getPinColour(isDone, isTodo);
      const isSelected = selectedCommentId === id;
      const isEditingPin = isSelected && isSelectingDuration && editingPinIndex === pinIndex;
      const hasDuration = pinDuration !== DEFAULT_COMMENT_DURATION || isEditingPin;
      const isEditingAllowed = userId === ownerId && !hasNewVersion && isSpanAllowed;
      const pinDurationWidth = timeToWidth(pinDuration, pinTime);
      const commentLineWidth = isEditingPin ? lineWidth : pinDurationWidth;
      const direction = getDirection(isSelected && editingPinIndex === pinIndex, pinDuration);

      return (
        <Pin
          key={id + '/' + pinIndex}
          leftPosition={leftPosition}
          pinColour={colour}
          isSelected={isSelected}
          hasDuration={hasDuration}
          isEditingAllowed={isEditingAllowed}
          onMouseDown={event => isEditingAllowed && isSelected && startSpan(event, pinTime, pinIndex)}
          onBlur={deselectPin}
          onClick={() => selectPin(comment, pin)}
          direction={direction}
          lineWidth={commentLineWidth}
          onPinHover={() => onPinHover(pinTime)}
        />
      );
    }))}
  </Fragment>
);

if (process.env.NODE_ENV !== 'production') {
  Pins.propTypes = {
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedCommentId: PropTypes.string.isRequired,
    lineWidth: PropTypes.number.isRequired,
    isSelectingDuration: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    startSpan: PropTypes.func.isRequired,
    timeToPosition: PropTypes.func.isRequired,
    timeToWidth: PropTypes.func.isRequired,
    getPinColour: PropTypes.func.isRequired,
    getDirection: PropTypes.func.isRequired,
    selectPin: PropTypes.func.isRequired,
    deselectPin: PropTypes.func.isRequired,
    isSpanAllowed: PropTypes.bool,
    hasNewVersion: PropTypes.bool,
    onPinHover: PropTypes.func.isRequired,
  };
}

Pins.defaultProps = {
  isSpanAllowed: false,
};

export default Pins;
