/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Translation } from '../../components/Text';
import { walkthroughIds } from './walkthroughIds';

const PROOF_SETUP_FILE_STEP = {
  hooks: ['proof-setup-file'],
  tooltipDirections: {
    down: true,
    center: true,
  },
  additionalPadding: {
    left: 10,
    right: 10,
  },
  styles: {
    borderRadius: '6px',
  },
  heading: <Translation value="walkthrough.proof-setup-file-step.heading" />,
  description: <Translation value="walkthrough.proof-setup-file-step.description" />,
};

const PROOF_SETUP_FILES_STEP = {
  ...PROOF_SETUP_FILE_STEP,
  hooks: ['proof-setup-files'],
  additionalPadding: 10,
  heading: <Translation value="walkthrough.proof-setup-files-step.heading" />,
  description: <Translation value="walkthrough.proof-setup-files-step.description" />,
};

const PROOF_SETUP_INFO_STEP = {
  hooks: ['proof-setup-info'],
  tooltipDirections: {
    middle: true,
    right: true,
  },
  additionalPadding: {
    top: 16,
  },
  styles: {
    borderRadius: '5px',
  },
  heading: <Translation value="walkthrough.proof-setup-info-step.heading" />,
  description: <Translation value="walkthrough.proof-setup-info-step.description" />,
};

const PROOF_SETUP_INFO_WITH_REFERENCE_STEP = {
  ...PROOF_SETUP_INFO_STEP,
  hooks: ['proof-setup-info-with-reference'],
  description: <Translation value="walkthrough.proof-setup-info-with-reference-step.description" />,
};

const PROOF_SETUP_REVIEWERS_STEP = {
  hooks: ['proof-setup-reviewers'],
  tooltipDirections: {
    middle: true,
    left: true,
  },
  heading: <Translation value="walkthrough.proof-setup-reviewers-step.heading" />,
  description: (
    <Translation
      value="walkthrough.proof-setup-reviewers-step.description"
      params={{
        clickHere: (
          <a
            // This is going to our own help site
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            href="https://help.pageproof.com/en/articles/923799-a-guide-on-workflows"
          >
            <Translation value="walkthrough.proof-setup-reviewers-step.description.click-here" />
          </a>
        ),
      }}
    />
  ),
};

const PROOF_SETUP_OWNERS_STEP = {
  hooks: ['proof-setup-owners'],
  tooltipDirections: {
    middle: true,
    left: true,
  },
  heading: <Translation value="walkthrough.proof-setup-owners-step.heading" />,
  description: <Translation value="walkthrough.proof-setup-owners-step.description" />,
};


export const proofSetupWalkthrough = {
  id: walkthroughIds.proofSetup,
  canDisplay: () => window.location.href.match(/\/create/i) && window.innerWidth > 1220 && window.innerHeight > 720,
  steps: [
    [PROOF_SETUP_FILE_STEP, PROOF_SETUP_FILES_STEP],
    [PROOF_SETUP_INFO_STEP, PROOF_SETUP_INFO_WITH_REFERENCE_STEP],
    [PROOF_SETUP_REVIEWERS_STEP],
    [PROOF_SETUP_OWNERS_STEP],
  ],
  includedWalkthroughIds: [walkthroughIds.proofSetup],
};
