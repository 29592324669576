/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import MobileProofHeaderCenterTunnel from './MobileProofHeaderCenterTunnel';

function MobileProofHeaderCenterChild({ children }) {
  return (
    <MobileProofHeaderCenterTunnel.Tunnel>
      {children}
    </MobileProofHeaderCenterTunnel.Tunnel>
  );
}

export default MobileProofHeaderCenterChild;
