import React from 'react';
import css from './IntegrationApps.scss';
import HelpBubble from '../HelpBubble/HelpBubble';

const IntegrationSectionHeading = ({ title, message }) => (
  <div className={css.SectionTitle}>
    {title}
    {message && (
      <HelpBubble
        className={css.SectionTitle__bubble}
        message={message}
      />
    )}
  </div>
);

export default IntegrationSectionHeading;
