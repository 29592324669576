/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { useState, useRef, useEffect } from 'react';
import Popover from '../Popover';
import SideBySide from '../Transitions/SideBySide';
import Reveal from '../Reveal';
import Tooltip from '../Tooltip';
import Option from '../PopupMenu/Option';
import Separator from '../PopupMenu/Separator';
import Spinner from '../Spinner/Spinner';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import css from './CustomToolsNavigator.scss';

function oForEach(obj, callback) {
  return Object.keys(obj).map((key) => {
    return callback(obj[key], key, obj);
  });
}

function loadAsyncOptions(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        try {
          const parsed = JSON.parse(xhr.responseText);
          if (parsed.success === false && typeof parsed.message === 'string') {
            reject(new Error(parsed.message));
          }
          resolve(parsed);
        } catch (err) {
          reject(err);
        }
      }
    };
    xhr.onerror = () => {
      reject(new Error('Network error: ' + url));
    };
    xhr.open('GET', url);
    xhr.send();
  });
}

class AsyncOnClickOption extends React.Component {
  state = {
    loading: false,
    error: null,
  };

  onClick = (...args) => {
    const result = this.props.onClick(...args);

    if (result && typeof result.then === 'function') {
      this.setState({
        loading: true,
        error: null,
      });

      result.then(
        () => this.setState({
          loading: false,
          error: false,
        }),
        (err) => this.setState({
          loading: false,
          error: err.message,
        }),
      );
    }

    return result;
  }

  renderIcon() {
    if (this.state.error) {
      return (
        <Tooltip
          title={this.state.error}
          up center
          variant="light"
        >
          <div className={css.ErrorSymbol}>
            <InlineSVG src="img/icons/error-symbol.svg" />
          </div>
        </Tooltip>
      );
    }

    return <Spinner size={21} color="#ddd" />;
  }

  render() {
    return (
      <Option
        {...this.props}
        checked={this.state.loading || this.state.error}
        checkedIcon={this.renderIcon()}
        checkedIconWidth={21}
        readOnly={this.state.loading}
        onClick={this.onClick}
      />
    );
  }
}

class CustomToolsNavigator extends React.Component {
  state = {
    stack: [],
    direction: 'push',
  };

  _navigateCallbacks = [];

  push = (action) => {
    if (!action) {
      return;
    }
    switch (action.type) {
      case 'open-external-url': {
        window.open(action.url, '_blank');
        this.clear();
        break;
      }
      case 'options':
      case 'embed-url': {
        this.setState((state) => ({
          stack: [
            ...state.stack,
            action,
          ],
          direction: 'push',
        }), this.notifyOnNavigate);
        break;
      }
      case 'async-options': {
        return loadAsyncOptions(action.url)
          .then((options) => this.push({
            type: 'options',
            options,
          }));
      }
      default: {
        throw new Error('Unsupported action: ' + action.type);
      }
    }
  }

  start = (action) => {
    this.clear();
    this.push(action);
  }

  pop = () => {
    this.setState((state) => ({
      stack: state.stack.slice(0, state.stack.length - 1),
      direction: 'pop',
    }), this.notifyOnNavigate);
  }

  replace = (action) => {
    this.pop();
    return this.push(action);
  }

  clear = () => {
    this.setState(() => ({
      stack: [],
    }), this.notifyOnNavigate);
  }

  get isOpen() {
    return this.state.stack.length >= 1;
  }

  onNavigate(callback) {
    this._navigateCallbacks.push(callback);
  }

  notifyOnNavigate = () => {
    this._navigateCallbacks.forEach(callback => callback());
  }

  renderOptions(action) {
    return (
      <div style={{maxHeight: 'calc(100vh - 200px)'}}>
        {oForEach(action.options, (option, id) => {
          if (option.label === '---') {
            return (
              <Separator
                key={id}
              />
            );
          } else if (option.action) {
            return (
              <AsyncOnClickOption
                key={id}
                label={option.label}
                onClick={() => this.push(option.action)}
              />
            );
          } else {
            return (
              <Option
                key={id}
                label={option.label}
                disabled
              />
            );
          }
        })}
      </div>
    );
  }

  renderEmbedURL(action) {
    return <CustomToolsEmbedURL action={action} />;
  }

  renderContent() {
    const action = this.state.stack[this.state.stack.length - 1];

    return (
      <div class={css.CustomToolsNavigator__content}>
        <Reveal
          preRender
          easing="ease-in-out"
          duration={360}
          visible={this.state.stack.length > 1}
          align="top"
        >
          <Option
            label={
              <React.Fragment>
                &#x276e;&nbsp;
                Back
              </React.Fragment>
            }
            className={css.CustomToolsNavigator__back}
            readOnly
            onClick={this.pop}
          />
        </Reveal>
        <SideBySide
          reverse={this.state.direction === 'pop'}
          id={this.state.stack.length - 1}
        >
          {(() => {
            switch (action.type) {
              case 'options': {
                return this.renderOptions(action);
              }
              case 'embed-url': {
                return this.renderEmbedURL(action);
              }
              default: {
                return <pre>{JSON.stringify(action, null, '  ')}</pre>;
              }
            }
          })()}
        </SideBySide>
      </div>
    );
  }

  render() {
    return (
      <Popover
        right
        middle
        arrow
        maxWidth={false}
        offset={12}
        visible={this.state.stack.length >= 1}
        content={(...args) => this.renderContent(...args)}
        onBeforeHide={() => this.clear()}
      >
        {this.props.children}
      </Popover>
    );
  }
}

function CustomToolsEmbedURL({ action }) {
  const [dimensions, setDimensions] = useState(action.dimensions);
  const iframeRef = useRef();

  useEffect(() => {
    if (iframeRef.current) {
      const contentWindow = iframeRef.current.contentWindow;
      function onMessage(event) {
        if (
          // Ensure the message is from the iframe we created.
          event.source === contentWindow &&
          // Check that the message is in the format we expect.
          event.data &&
          // Listen to a specific event type.
          event.data.type === 'pageproof.custom-tools.embed-url.set-dimensions' &&
          // Ensure that the dimensions we're sent are properly formed.
          typeof event.data.dimensions.width === 'number' &&
          typeof event.data.dimensions.height === 'number'
        ) {
          setDimensions(event.data.dimensions);
        }
      }
      window.addEventListener('message', onMessage);
      return () => window.removeEventListener('message', onMessage);
    }
  }, []);

  return (
    <iframe
      title="Custom Tools"
      ref={iframeRef}
      src={action.url}
      style={{
        border: 0,
        position: 'static', // apparently theres a css style for iframes to make them position: absolute in our app
        width: dimensions.width,
        height: dimensions.height,
      }}
    />
  );
}

export {
  CustomToolsNavigator,
};
