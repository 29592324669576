/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './Spacing.scss';
import { useCommentSpacing } from '../CommentSpacing';

function Spacing({ children }) {
  const spacing = useCommentSpacing();

  return (
    <div className={classname(css.Spacing, css[`Spacing--${spacing}`])}>
      {children}
    </div>
  );
}

export default Spacing;
