/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { useRef, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import css from './RevealingInput.scss';
import GreyCircleIconButton from '../GreyCircleIconButton';
import RevealTextHorizontal from '../ProofSetup/components/RevealTextHorizontal';
import ExpandingControl from '../ExpandingControl';
import { useText } from '../Text';
import IconButton from '../Button/IconButton';

const RevealingTextInput = ({ value, onChange }) => {
  const text = useText();
  const inputRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const focusInput = () => inputRef.current.focus();

  const onClearInput = () => {
    onChange('');
    focusInput();
  };

  return (
    <div className={css.RevealingInput}>
      <GreyCircleIconButton
        iconSrc="/img/interface/search.svg"
        size={40}
        onClick={() => {
          if (!isVisible) {
            setIsVisible(true);
          }
          inputRef.current.focus();
        }}
      />
      <RevealTextHorizontal show={isVisible || !!value}>
        <ExpandingControl
          control={
            <Fragment>
              <input
                ref={inputRef}
                type="text"
                value={value}
                onChange={(event) => {
                  onChange(event.target.value);
                }}
                onBlur={() => {
                  if (value === '') {
                    setIsVisible(false);
                  }
                }}
                className={css.RevealingInput__input}
                placeholder={text('default.placeholder.enter-an-email-address')}
              />
              {value &&
                <IconButton
                  className={css.RevealingInput__close}
                  src="img/interface/close.svg"
                  onClick={onClearInput}
                />}
            </Fragment>
          }
        />
      </RevealTextHorizontal>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  RevealingTextInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
}

export default RevealingTextInput;
