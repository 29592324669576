/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Visible from '@pageproof/visible';
import styles from './Textarea.scss';

class Textarea extends Component {
  componentDidMount() {
    this.autogrow();
    window.addEventListener('resize', this.handleScreenResize, false);
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue && this.props.defaultValue == null && !this._hasSetDefaultValue) {
      this._hasSetDefaultValue = true;
      this.textarea.value = nextProps.defaultValue;
      this.autogrow();
    }
    if (nextProps.value && nextProps.value !== this.props.value) {
      this.textarea.value = nextProps.value;
      this.autogrow();
    }
  }

  componentDidUpdate() {
    if (this._previousVisibleValue !== this.getVisibleValue()) {
      this.autogrow();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleScreenResize, false);
  }

  getVisibleValue() {
    return this.textarea.value || this.textarea.placeholder;
  }

  getCalculatedHeight = () => {
    const style = window.getComputedStyle(this.textarea);
    const borderWidth = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
    const padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
    const scrollHeight = this.textarea.scrollHeight;
    return scrollHeight + borderWidth + padding;
  }

  autogrow = () => {
    if (this.props.allowAutoGrow) {
      this.textarea.style.setProperty('height', 0);
      const currHeight = this.getCalculatedHeight();
      this.textarea.style.setProperty('height', `${currHeight}px`, 'important');
    }
    this._previousVisibleValue = this.getVisibleValue();
  }

  focus = () => this.textarea.focus();

  handleScreenResize = () => {
    this.autogrow();
  }

  render() {
    const { onChange, allowAutoGrow, variant, ...props } = this.props;
    return (
      <Visible
        onVisibilityChange={visible => (visible && this.autogrow())}
      >
        <textarea
          {...props}
          ref={area => (this.textarea = area)}
          className={classname(styles.Textarea, props.className, styles['Textarea--' + variant])}
          onChange={(event) => {
            if (onChange) {
              onChange(event.target.value);
            }
          }}
          onInput={this.autogrow}
        />
      </Visible>
    );
  }
}

Textarea.defaultProps = {
  allowAutoGrow: true,
};

if (process.env.NODE_ENV !== 'production') {
  Textarea.propTypes = {
    onChange: PropTypes.func,
    allowAutoGrow: PropTypes.bool,
  };
}

export default Textarea;
