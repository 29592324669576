/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import DecisionSummary from '../Decisions/DecisionSummary';
import WorkflowProgress from '../WorkflowProgress/WorkflowProgress';
import UserList from '../UserList';
import { Translation } from '../Text';
import Confetti from '../Confetti';
import css from './ProofWorkflowStatusBar';

function ProofWorkflowStatusBar({
  shouldFireConfetti,
  thumbsUpGroup,
  thumbsUpUsers,
  approvedWithChangesUsers,
  thumbsDownGroup,
  thumbsDownUsers,
  noDecisionGroup,
  noDecisionUsers,
  currentStepName,
  currentStepDueDate,
  totalStepCount,
  visibleStepCount,
  visibleReviewerPercentage,
  isOverdue,
  viewType,
  onClick,
  hasBorder,
  workflowId,
  refreshDecisionSummary,
  canSkipAll,
}) {
  const isCompact = viewType === 'tile';
  const iconSize = isCompact || viewType === 'list' ? 17 : 20;
  const labelPosition = isCompact ? 'vertical' : 'horizontal';

  return (
    <div className={css.ProofWorkflowStatusBar}>
      {((thumbsUpUsers && thumbsUpUsers.length > 0) ||
        (approvedWithChangesUsers && approvedWithChangesUsers.length > 0) ||
        (thumbsDownUsers && thumbsDownUsers.length > 0) ||
        (noDecisionUsers && noDecisionUsers.length > 0) ||
        totalStepCount > 1) &&
        <DecisionSummary
          viewType={viewType}
          onClick={onClick}
          hasBorder={hasBorder}
        >
          {thumbsUpUsers.length > 0 &&
            <DecisionSummary.ThumbsUp
              size={iconSize}
              count={thumbsUpUsers.length}
              labelPosition={labelPosition}
            >
              {popover => (
                <UserList
                  groups={[
                    {
                      id: 'approved',
                      name: <Translation value="decision-summary.title.approved" />,
                      users: thumbsUpUsers,
                      ...thumbsUpGroup,
                    },
                  ]}
                  onGroupAction={() => popover.hide()}
                  onUserAction={() => popover.hide()}
                />
              )}
            </DecisionSummary.ThumbsUp>
          }
          {approvedWithChangesUsers.length > 0 &&
            <DecisionSummary.ThumbsUpOrange
              size={iconSize}
              count={approvedWithChangesUsers.length}
              labelPosition={labelPosition}
            >
              {popover => (
                <UserList
                  groups={[
                    {
                      id: 'approved-with-changes',
                      name: <Translation value="decision-summary.title.approved-with-changes" />,
                      users: approvedWithChangesUsers,
                    },
                  ]}
                  onGroupAction={() => popover.hide()}
                  onUserAction={() => popover.hide()}
                />
              )}
            </DecisionSummary.ThumbsUpOrange>
          }
          {thumbsDownUsers.length > 0 &&
            <DecisionSummary.ThumbsDown
              size={iconSize}
              count={thumbsDownUsers.length}
              labelPosition={labelPosition}
            >
              {popover => (
                <UserList
                  groups={[
                    {
                      id: 'send-changes',
                      label: <Translation value="decision-summary.title.changes-please" />,
                      users: thumbsDownUsers,
                      ...thumbsDownGroup,
                    },
                  ]}
                  onGroupAction={() => popover.hide()}
                  onUserAction={() => popover.hide()}
                />
              )}
            </DecisionSummary.ThumbsDown>
          }
          {noDecisionUsers.length > 0 &&
            <DecisionSummary.QuestionMark
              size={iconSize}
              count={noDecisionUsers.length}
              labelPosition={labelPosition}
            >
              {popover => (
                <UserList
                  groups={[
                    {
                      id: 'no-decision',
                      name: <Translation value="decision-summary.title.no-decision" />,
                      users: noDecisionUsers,
                      ...noDecisionGroup,
                    },
                  ]}
                  onGroupAction={() => popover.hide()}
                  onUserAction={() => popover.hide()}
                />
              )}
            </DecisionSummary.QuestionMark>
          }
          {totalStepCount > 1 &&
            <WorkflowProgress
              currentStepName={currentStepName}
              currentStepDueDate={currentStepDueDate}
              totalStepCount={totalStepCount}
              visibleStepCount={visibleStepCount}
              visibleReviewerPercentage={visibleReviewerPercentage}
              isProofOverdue={isOverdue}
              workflowId={workflowId}
              refreshDecisionSummary={refreshDecisionSummary}
              canSkipAll={canSkipAll}
              size={iconSize}
              labelPosition={labelPosition}
            />
          }
        </DecisionSummary>
      }
      {shouldFireConfetti &&
        <span className={css.ProofWorkflowStatusBar__confetti}>
          <Confetti />
        </span>
      }
    </div>
  );
}

export default ProofWorkflowStatusBar;
