/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

class Range extends Component {
  state = {
    active: false,
    padding: 0,
  };

  start = (downEvent) => {
    downEvent.preventDefault();

    if (downEvent.button === 2 /* RIGHT_CLICK */) {
      return;
    }

    const t = downEvent.type === 'touchstart';

    const calculate = (event) => {
      event.preventDefault();

      const details = t && event.touches ? event.touches[0] : event;
      const {step} = this.props;
      const h = this.props.orientation === 'horizontal';
      const pageAxis = h ? 'pageX' : 'pageY';
      const spaceOffset = h ? 'left' : 'top';
      const spaceSize = h ? 'width' : 'height';
      const space = this.track.getBoundingClientRect();
      const percent = Math.min(100, Math.max(0, Math.round((((details[pageAxis] - space[spaceOffset]) / space[spaceSize]) * 100))));
      const stepPercent = Math.round(percent / step) * step;
      // TODO properly handle vertical percentage invert
      const finalPercent = h ? stepPercent : 100 - stepPercent;

      if (finalPercent !== this.props.value) {
        this.props.onChange(finalPercent);
      }
    };

    this.setState({active: true});
    calculate(downEvent);

    const that = this;
    window.addEventListener(t ? 'touchmove' : 'mousemove', calculate, false);
    window.addEventListener(t ? 'touchend' : 'mouseup', function cleanup() {
      that.setState({active: false});
      window.removeEventListener(t ? 'touchmove' : 'mousemove', calculate, false);
      window.removeEventListener(t ? 'touchend' : 'mouseup', cleanup, false);

      if (that.props.onEnd) {
        that.props.onEnd();
      }
    }, false);

    if (this.props.onStart) {
      this.props.onStart();
    }
  }

  handleButtonRef(ref) {
    if (ref && ref !== this.button) {
      this.button = ref;
      const h = this.props.orientation === 'horizontal';
      const padding = ref.getBoundingClientRect()[h ? 'width' : 'height'] / 2;
      if (padding !== this.state.padding) {
        this.setState({padding});
      }
    }
  }

  render() {
    const {
      className,
      orientation,
      value,
      button,
      size,
      track,
    } = this.props;
    const {
      active,
      padding,
    } = this.state;
    const h = orientation === 'horizontal';
    return (
      <div
        className={classname('Range', `Range--${orientation}`, active && 'Range--active', className)}
        onMouseDown={this.start}
        onTouchStart={this.start}
        ref={ref => (this.bar = ref)}
        style={{
          [h ? 'width' : 'height']: size === 'auto' ? '100%' : size,
        }}
      >
        <div
          className="Range__track"
          style={{
            [h ? 'left' : 'top']: padding,
            [h ? 'right' : 'bottom']: padding,
          }}
          ref={ref => (this.track = ref)}
        >
          {track &&
            <div
              className="Range__line"
              // style={{
              //   [h ? 'width' : 'height']: value + '%',
              // }}
            />}
          <span
            className="Range__button"
            style={{
              [h ? 'left' : 'bottom']: value + '%',
            }}
            onMouseDown={this.start}
            ref={ref => this.handleButtonRef(ref)}
          >
            {button}
          </span>
        </div>
      </div>
    );
  }
}

Range.defaultProps = {
  orientation: 'horizontal',
  step: 1,
  size: 'auto',
  track: false,
};

Range.propTypes = {
  orientation: PropTypes.oneOf([
    'horizontal',
    'vertical',
  ]),
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['auto']), // auto only works on horizontal (width: 100%)
  ]),
  track: PropTypes.bool,
  step: PropTypes.number,
  button: PropTypes.node,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onStart: PropTypes.func,
  onEnd: PropTypes.func, // eslint-disable-line
};

export default Range;
