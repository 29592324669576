/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Fragment} from 'react';

function optionalChildrenFunc(node, ...args) {
  if (typeof node === 'function') {
    return node(...args);
  } else if (typeof node === 'string') {
    return <Fragment>{node}</Fragment>;
  } else {
    return node;
  }
}

export default optionalChildrenFunc;
