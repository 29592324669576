/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Reveal extends Component {
  static propTypes = {
    duration: PropTypes.number,
    align: PropTypes.oneOf([
      'top',
      'bottom',
      'center',
    ]),
    easing: PropTypes.string,
    preRender: PropTypes.bool,
    postRender: PropTypes.bool, // whether to render the children after it's made invisible again
    // immediate is used within UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    immediate: PropTypes.bool,
    minHeight: PropTypes.number,
    fade: PropTypes.bool,
  };

  static defaultProps = {
    duration: 500,
    align: 'center',
    easing: 'cubic-bezier(0.23, 1, 0.32, 1)',
    preRender: false,
    postRender: true,
    immediate: false,
    minHeight: 0,
    fade: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      height: this.props.visible ? '' : 0,
      opacity: this.props.visible ? '' : 0,
      animate: false,
      render: this.props.visible,
      pending: false,
    };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.immediate || !this.state.pending) &&
        (nextProps.visible !== this.props.visible)) {
      this.updateVisibility(nextProps);
    }
  }

  componentWillUnmount() {
    this.previousChildren = null; // clear up any post-rendered react elements
  }

  updateVisibility({visible, duration}) {
    clearTimeout(this.endTransition);
    this.setState({
      height: visible ? 0 : '',
      opacity: visible ? 0 : '',
      render: true,
      pending: true,
    }, () => {
      const contentHeight = this.content.scrollHeight;
      this.setState({
        height: visible ? 0 : contentHeight,
        opacity: visible ? 0 : 1,
        animate: true,
      }, () => {
        setTimeout(() => {
          this.setState({
            height: visible ? contentHeight : 0,
            opacity: visible ? 1 : 0,
          });
        });
      });
    });
    this.endTransition = setTimeout(() => {
      this.setState({
        height: visible ? '' : 0,
        opacity: visible ? '' : 0,
        animate: false,
        render: visible,
        pending: false,
      });
      if (this.props.visible !== visible) {
        this.updateVisibility(this.props);
      }
    }, duration);
  }

  render() {
    const {opacity, height, animate, render, pending} = this.state;
    const {visible, duration, align, easing, children, preRender, postRender, minHeight, fade} = this.props;
    return (
      <div
        ref={ref => (this.container = ref)}
        style={{
          position: animate ? 'relative' : '',
          opacity: minHeight ? '' : (animate && !fade ? '' : opacity), // eslint-disable-line
          height: height === '' ? '' : Math.max(height, minHeight),
          transition: animate
            ? (fade ? `opacity ${duration}ms ${easing},` : '') +
              `height ${duration}ms ${easing}`
            : '',
          pointerEvents: render || minHeight ? '' : 'none',
          overflow: ((align === 'center' && !minHeight) || (visible && !pending)) ? '' : 'hidden',
        }}
      >
        <div
          ref={ref => (this.content = ref)}
          style={
            animate
              ? {
                position: 'absolute',
                top: align === 'top' ? 0 : align === 'center' ? '50%' : '', // eslint-disable-line
                bottom: align === 'bottom' ? 0 : '',
                transform: align === 'center' ? 'translateY(-50%)' : '',
                width: '100%',
              }
              : {
                // n/a
              }
          }
        >
          {(render || preRender || minHeight) // eslint-disable-line
            ? (
              (!postRender && !visible)
                ? this.previousChildren
                : (
                  this.previousChildren = (
                    typeof children === 'function'
                      ? children({pending})
                      : children
                  )
                )
            )
            : null}
        </div>
      </div>
    );
  }
}

export default Reveal;
