/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import colorPickerColors from '../../resources/colorPickerColors.json';
import ColorDot from '../../components/ColorDot';

const ColorDotWrapper = ({
  onChange,
  defaultColor,
  customColor,
  onCustomColorChange,
}) => {
  const selectedColor = defaultColor || colorPickerColors.grey.normal;

  const sortedPinColors = Object.keys(colorPickerColors).map((key) => {
    const color = colorPickerColors[key];
    return {
      ...color,
      disabled: selectedColor === color.normal,
    };
  });

  return (
    <ColorDot
      colors={sortedPinColors}
      tooltipText="proof-comparison.slider.color"
      selectedColor={selectedColor}
      onChange={color => onChange(color.normal)}
      onCustomColorChange={color => onCustomColorChange(color)}
      customColor={customColor}
      variant="light"
      hasCustomColor
    />
  );
};

export default ColorDotWrapper;
