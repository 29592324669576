/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Avatar, EmailAvatar } from '../../components/Avatar';
import env from '../../../../shared/env';
import Tooltip from '../../components/Tooltip';
import getUserStatusSubscriptionProvider from '../../util/user-status-subscription-provider';
import Emoji from '../../components/Emoji';
import css from './NextAvatar.scss';

function StatusDot({
  size,
  status,
}) {
  const smallestDotSize = 8;
  const borderWidth = Math.max(1, (size * 0.04));
  const dotSize = Math.max(smallestDotSize, size * 0.3);
  const dotHitbox = dotSize === smallestDotSize ? 0 : 8;
  return (
    <div
      style={{
        marginLeft: 'auto',
        position: 'absolute',
        zIndex: 1,
        top: -borderWidth,
        right: -borderWidth,
        width: dotSize,
        height: dotSize,
        backgroundColor: '#ff8200',
        borderRadius: '100%',
        borderStyle: 'solid',
        borderWidth,
      }}
      className="avatar-status-dot"
    >
      <Tooltip
        title={() => (
          <div className={css.UserStatus}>
            <div className={css.UserStatus__emoji}>
              <Emoji text={status.emoji} />
            </div>
            <span className={css.UserStatus__message}>
              {status.message}
            </span>
          </div>
        )}
        up
        center
        arrow
        offset={dotHitbox}
        delay={0}
      >
        <div
          style={{
            position: 'absolute',
            top: -dotHitbox,
            left: -dotHitbox,
            right: -dotHitbox,
            bottom: -dotHitbox,
          }}
        />
      </Tooltip>
    </div>
  );
}

function NextAvatar({
  id,
  size,
  active,
  spinner,
  showEmails,
  onHoverOnly,
  disableStatus,
  onClick,
  showTooltip,
  showNameAndEmail,
  additionalText,
}) {
  const [cacheBuster, setCacheBuster] = useState(0);
  const [status, setStatus] = useState(null);
  const [userData, setUserData] = useState({ name: null, email: null });

  useEffect(() => {
    // This should listen to 'update' but since the cache is removing before updating this works for now.
    // This is an exception because other components are built around this bug as well.
    // It will be removed and fixed with new codebase
    const removeInitEventListener = window.__pageproof_bridge__.avatarRepositoryService.cache.on('remove', { userId: id }, () => {
      setCacheBuster(prevState => prevState + 1);
    });

    const removeUserStatusSubscription = getUserStatusSubscriptionProvider().subscribe(id, setStatus);

    return () => {
      removeInitEventListener();
      removeUserStatusSubscription();
    };
  }, [id]);

  useEffect(() => {
    if (showNameAndEmail || showEmails || showTooltip) {
      window.__pageproof_bridge__.userRepositoryService.get(id).then(setUserData);
    }
  }, [id, showNameAndEmail, showEmails, showTooltip]);

  const inner = (
    <Fragment>
      {!disableStatus && status && status.away &&
        <StatusDot
          size={size}
          status={status}
        />
      }
      {showEmails
        ? <EmailAvatar
          onHoverOnly={onHoverOnly}
          email={userData.email}
          url={`${env.avatar_url}/-/${id}?${cacheBuster}`}
          size={size}
          spinner={spinner}
          registered
          active={active}
          onClick={onClick}
        />
        : (
          <Tooltip
            title={(
              <Fragment>
                <div>{userData.email}</div>
                {!!additionalText && <br />}
                {!!additionalText && additionalText}
              </Fragment>
            )}
            up
            center
            arrow
            delay={0}
            offset={12}
            disabled={!showTooltip}
          >
            <div>
              <Avatar
                url={`${env.avatar_url}/-/${id}?${cacheBuster}`}
                size={size}
                spinner={spinner}
                registered
                active={active}
                onClick={onClick}
              />
            </div>
          </Tooltip>
        )
      }
    </Fragment>
  );

  return (
    <Tooltip
      down
      right
      title={(
        <Fragment>
          {userData.name}
          {userData.name && userData.email && <br />}
          {userData.email}
          {!!additionalText && <br />}
          {!!additionalText && additionalText}
        </Fragment>
      )}
      disabled={!showNameAndEmail}
    >
      <div
        className={css.UserAvatar}
        style={{ width: size, height: size }}
      >
        {inner}
      </div>
    </Tooltip>
  );
}

if (process.env.NODE_ENV !== 'production') {
  NextAvatar.propTypes = {
    id: PropTypes.string.isRequired,
    size: PropTypes.number,
    active: PropTypes.bool,
    spinner: PropTypes.bool,
    showEmails: PropTypes.bool,
    onHoverOnly: PropTypes.bool,
    disableStatus: PropTypes.bool,
    onClick: PropTypes.func,
    showTooltip: PropTypes.bool,
    showNameAndEmail: PropTypes.bool,
    additionalText: PropTypes.node,
  };
}

export default NextAvatar;
