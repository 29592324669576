/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

function TabButton({
  id,
  selected,
  children,
  ...props,
}) {
  return (
    <button
      {...props}
      id={`TabButton-${id}`}
      className={classname('TabButton', {
        'TabButton--selected': selected,
      })}
      role="tab"
      aria-selected={selected}
      aria-controls={`Tab-${id}`}
    >
      {children}
    </button>
  );
}

TabButton.propTypes = {
  selected: PropTypes.bool.isRequired,
};

export default TabButton;
