/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export function watchRect(elementOrGetElement, callback) {
  let animationFrame;
  let previousRect;

  function onAnimationFrame() {
    const element = typeof elementOrGetElement === 'function'
      ? elementOrGetElement()
      : elementOrGetElement;

    if (!element) {
      return;
    }

    const currentRect = element.getBoundingClientRect();

    if (
      !previousRect ||
      previousRect.top !== currentRect.top ||
      previousRect.left !== currentRect.left ||
      previousRect.width !== currentRect.width ||
      previousRect.height !== currentRect.height
    ) {
      previousRect = currentRect;
      callback(previousRect);
    }

    animationFrame = window.requestAnimationFrame(onAnimationFrame);
  }
  animationFrame = window.requestAnimationFrame(onAnimationFrame);

  return function unwatch() {
    animationFrame = null;
    previousRect = null;
    window.cancelAnimationFrame(animationFrame);
  };
}
