/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import FormControl, { FormControlContext } from '../FormControl';
import Checkbox from '../Checkbox';
import Switch from '../Switch';
import { Translation } from '../Text';
import { S_BREAK_MXX } from '../../util/constants';
import css from './EmailPreference.scss';

const EmailPreference = ({
  value,
  onUpdateEmailPreference,
  type,
  labelKey,
  onMessageKey,
  offMessageKey,
  batchValue,
  batchType,
  isCritical,
}) => (
  <FormControlContext.Provider value={{ minWidthBeforeCompact: S_BREAK_MXX }}>
    <FormControl
      label={<Translation value={labelKey} />}
      after={batchType && (
        <div className={css.EmailPreference__individualEmail}>
          <Checkbox
            selected={!batchValue}
            onChange={() => onUpdateEmailPreference(batchType)}
          />
          <span className={css.EmailPreference__individualEmail__label}>
            <Translation value="user.notifyme.individual-email" />
          </span>
        </div>
      )}
    >
      <Switch
        value={!!value}
        onChange={() => onUpdateEmailPreference(type)}
        message={value ? onMessageKey : offMessageKey}
        offColor={isCritical && 'red'}
      />
    </FormControl>
  </FormControlContext.Provider>
);

export default EmailPreference;
