/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Thumbnail from '../InfoPane/Thumbnail';
import css from './NavigatorThumbnail.scss';

const NavigatorThumbnail = ({ thumbnail, variant }) => (
  <div
    className={classname(css.NavigatorThumbnail, {
      [css[`NavigatorThumbnail--${variant}`]]: variant,
    })}
  >
    <Thumbnail
      url={thumbnail}
      variant={variant}
    />
  </div>
);

NavigatorThumbnail.propTypes = {
  thumbnail: PropTypes.string,
  variant: PropTypes.string,
};

export default NavigatorThumbnail;
