/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, Fragment } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import css from './OptionGroup.scss';
import { Heading } from '../PopupMenu';
import IconButton from '../Button/IconButton';
import useDarkMode from '../../hooks/useDarkMode';

const OptionGroup = ({ label, tool, isCollapsable, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsable);
  const [isDarkMode] = useDarkMode();

  return (
    <Fragment>
      <Heading
        className={css.OptionGroup__heading}
        variant={!isDarkMode && 'light'}
        label={
          <Fragment>
            <span
              className={css.OptionGroup__heading__title}
              onClick={() => isCollapsable && setIsCollapsed(!isCollapsed)}
            >
              {isCollapsable && (
                <IconButton
                  className={classname(css.OptionGroup__heading__title__arrow, {
                    [css['OptionGroup__heading__title__arrow--active']]: !isCollapsed,
                  })}
                  src="/img/interface/solid-arrow.svg"
                />
              )}
              {label}
            </span>
            {(isCollapsable && isCollapsed) || tool}
          </Fragment>
        }
      />
      {(isCollapsable && isCollapsed) || children}
    </Fragment>
  );
};

if (process.env.NODE_ENV !== 'production') {
  OptionGroup.propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]).isRequired,
    tool: PropTypes.node,
    isCollapsable: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };
}

export default OptionGroup;
