/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';

function HighlightText({children}) {
  return (
    <span className="HighlightText">{children}</span>
  );
}

if (process.env.NODE_ENV !== 'production') {
  HighlightText.propTypes = {
    children: PropTypes.node.isRequired,
  };
}

export default HighlightText;
