/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sdk } from '../../util/sdk';
import UNITS from './constantUnits';
import isBrowserTimezoneInCountry from '../../util/browser-timezone-by-country';

const DEFAULT_MEASUREMENT_UNIT_STORAGE_KEY = 'pageproof.app.default-measurement-unit.';

function getCachedMeasurementUnit(userId) {
  return window.localStorage.getItem(DEFAULT_MEASUREMENT_UNIT_STORAGE_KEY + userId);
}

function unitConvertor(originUnit, newUnit, width, height) {
  let convertedWidth = width;
  let convertedHeight = height;

  if (originUnit === newUnit) {
    return { unit: newUnit, width, height };
  }
  if (originUnit !== 'mm') {
    if (UNITS[originUnit].formula === 'multiply') {
      convertedWidth /= UNITS[originUnit].formulaNumber;
      convertedHeight /= UNITS[originUnit].formulaNumber;
    } else {
      convertedWidth *= UNITS[originUnit].formulaNumber;
      convertedHeight *= UNITS[originUnit].formulaNumber;
    }
  }

  if (UNITS[newUnit].formula === 'multiply') {
    convertedWidth *= UNITS[newUnit].formulaNumber;
    convertedHeight *= UNITS[newUnit].formulaNumber;
  } else {
    convertedWidth /= UNITS[newUnit].formulaNumber;
    convertedHeight /= UNITS[newUnit].formulaNumber;
  }

  return { unit: newUnit, width: convertedWidth, height: convertedHeight };
}

// This component exists because for some reason all the page dimensions in the Angular controllers are set
// in the proof dimensions pill, but we don't show the dimensions pill in compare mode, and I am not about
// to reimplement all of this logic in Angular.
function ProofPageInfo({
  pageDimensions,
  currentPage,
  updatePageDimensionInfo,
  originalWidth,
  originalHeight,
  measurementUnits,
}) {
  const [selectedUnit, setSelectedUnit] = useState(measurementUnits);

  const pagesSizeData = pageDimensions
    .reduce((a, v) => ({
      ...a,
      [v.PageNumber]: {
        width: v.PageWidth,
        height: v.PageHeight,
      },
    }), {});
  const getConvertedDimensionData = unit => unitConvertor(
    measurementUnits,
    unit,
    pagesSizeData[currentPage] ? pagesSizeData[currentPage].width : originalWidth,
    pagesSizeData[currentPage] ? pagesSizeData[currentPage].height : originalHeight,
  );

  useEffect(() => {
    const cachedMeasurementUnit = getCachedMeasurementUnit(sdk.session.userId);
    if (!cachedMeasurementUnit && UNITS[measurementUnits].isChangeable) {
      isBrowserTimezoneInCountry('US').then(((isUser) => {
        const initialMeasurementUnit = isUser ? UNITS.inch.unitValue : measurementUnits;
        setSelectedUnit(initialMeasurementUnit);
      }));
    } else if (UNITS[measurementUnits].isChangeable) {
      setSelectedUnit(cachedMeasurementUnit);
    } else {
      setSelectedUnit(measurementUnits);
    }
  }, []);

  useEffect(() => {
    const convertedDimensionData = getConvertedDimensionData(selectedUnit);
    if (updatePageDimensionInfo) {
      updatePageDimensionInfo({
        ...convertedDimensionData,
        isDecimal: UNITS[convertedDimensionData.unit].isDecimal,
      });
    }
  }, [selectedUnit, currentPage, originalHeight, originalWidth]);

  return (
    <span style={{ display: 'none' }} />
  );
}

if (process.env.NODE_ENV !== 'production') {
  ProofPageInfo.propTypes = {
    currentPage: PropTypes.number,
    updatePageDimensionInfo: PropTypes.func,
    originalWidth: PropTypes.number,
    originalHeight: PropTypes.number,
    measurementUnits: PropTypes.string,
  };
}

export default ProofPageInfo;
