/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './Heading.scss';

function Heading({
  label,
  variant,
  _optionListVariant,
  className,
}) {
  return (
    <div className={classname(css.Heading, css['Heading--' + (variant || _optionListVariant)], className)}>
      {label}
    </div>
  );
}

export default Heading;
