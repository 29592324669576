/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'AgreeEarthDayOutline',
  src: 'img/icons/agree-earth-day-outline.svg',
  activeColor: '#81CCF1',
  activeHoverColor: '#68B3D8',
});
