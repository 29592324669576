/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import PropTypes from 'prop-types';
import classname from 'classname';

class Input extends Component {
  componentDidUpdate(previousProps) {
    // If the component was disabled, and it just got re-enabled, and the element has the autoFocus
    // prop, manually auto-focus on the element (because the browser won't do it for us).
    if (previousProps.disabled && !this.props.disabled && this.props.autoFocus) {
      const element = findDOMNode(this);
      element.focus();
    }
  }

  render() {
    const {
      variant,
      onChange,
      inputRef,
      className,
      nativeAutoFocus,
      maxLength,
      ...props
    } = this.props;
    return (
      <input
        autoFocus={nativeAutoFocus} // Above {...props} so it can be overwritten
        {...props}
        ref={inputRef}
        maxLength={maxLength}
        className={classname('Input', `Input--${variant}`, className)}
        onChange={event => (onChange && onChange(event.target.value))}
      />
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  Input.propTypes = {
    onChange: PropTypes.func,
    // Native autoFocus is useful when you want it to autofocus ONLY when it first appears on the DOM.
    // Skips logic for after it changes from disabled to enabled (in componentDidUpdate)
    nativeAutoFocus: PropTypes.bool,
    inputRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
  };
}

export default Input;
