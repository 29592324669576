/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const getTimecodeWithHyphen = (pin, isVideo, framesPerSecond) => {
  const { duration, time } = pin;
  const startTime = Math.min(time, time + duration);
  const endTime = Math.max(time, time + duration);

  // hyphen '-' should be used instead of '—' to avoid encoding issues in CSV
  return duration && duration !== 0.5
    ? `${window.generalfunctions_getTimecode(startTime, isVideo, framesPerSecond)} - ${window.generalfunctions_getTimecode(endTime, isVideo, framesPerSecond)}`
    : window.generalfunctions_getTimecode(startTime, isVideo, framesPerSecond);
};

export default getTimecodeWithHyphen;
