/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export function create() {
  let nextId = 0;

  const worker = new window.Worker('/workers/colorsep-coverage.js');
  const callbacks = {};

  worker.onmessage = (event) => {
    const { id, ...message } = event.data;
    const callback = callbacks[id];
    if (callback) {
      callback(message);
      delete callbacks[id];
    }
  };

  function send(type, options) {
    return new Promise((resolve, reject) => {
      nextId += 1;
      const id = nextId;
      callbacks[id] = (message) => {
        if (message.type === 'error') {
          const err = new Error(message.error.message);
          err.code = message.error.code;
          err.stack = message.error.stack;
          reject(err);
        } else {
          resolve(message.result);
        }
      };
      worker.postMessage({
        id,
        type,
        ...options,
      });
    });
  }

  function destroy() {
    worker.terminate();
  }

  return {
    destroy,
    ops: {
      loadPlate(plateName, url) {
        return send('loadPlate', { plateName, url });
      },
      getCoverage(plateNames, top, left) {
        return send('getCoverage', { plateNames, top, left });
      },
    },
  };
}
