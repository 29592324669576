/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {Component, cloneElement, Children} from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import optionalChildrenFunc from '../../util/optional-children-func';

class FileDrop extends Component {
  state = {
    active: false,
  };

  onChange = (active) => {
    if (this.state.active !== active) {
      this.setState({active}, () => {
        const callback = active
          ? this.props.onEnter
          : this.props.onLeave;

        if (callback) {
          callback();
        }
      });
    }
  }

  onDragEvent = (event) => {
    this.onChange(this.isWithinBounds(event));
  }

  isWithinBounds(event) {
    let element = document.elementFromPoint(event.pageX, event.pageY);
    // eslint-disable-next-line no-cond-assign
    do {
      if (element === this.child) {
        return true;
      }
    } while (element = element.parentElement);
    return false;
  }

  render() {
    const {
      onSelect,
      multiple,
      children,
      ...props
    } = this.props;
    const child = Children.only(optionalChildrenFunc(this.props.children, this.state));
    return cloneElement(child, {
      ...props,
      ref: ref => (this.child = ref),
      className: classname(child.props.className, 'FileDrop'),
      onDragOver: event => event.preventDefault(),
      onDragEnter: this.onDragEvent,
      onDragLeave: this.onDragEvent,
      onDrop: (event) => {
        event.preventDefault();
        const files = multiple
          ? Array.prototype.slice.apply(event.dataTransfer.files)
          : event.dataTransfer.files[0];
        onSelect(files);
        this.onChange(false);
      },
    });
  }
}

FileDrop.defaultProps = {
  multiple: false,
};

if (process.env.NODE_ENV !== 'production') {
  FileDrop.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    multiple: PropTypes.bool,
    children: PropTypes.node,
  };
}

export default FileDrop;
