/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import ShyTextPill from '../ShyTextPill';
import devices from '../../resources/devices';
import {
  match as matchCustomDevice,
} from '../../util/custom-device';

const devicesById = {};
devices.forEach((device) => {
  devicesById[device.id] = device;
});

function compareDimensions(dimensions, nextDimensions) {
  return (
    dimensions && (
      dimensions.logical.width === nextDimensions.logical.width &&
      dimensions.logical.height === nextDimensions.logical.height
    ) && (
      dimensions.container.width === nextDimensions.container.width &&
      dimensions.container.height === nextDimensions.container.height
    )
  );
}

function getScrollbarWidth() {
  const stylesheet = document.createElement('style');
  document.head.appendChild(stylesheet);
  stylesheet.sheet.insertRule('::-webkit-scrollbar {visibility: visible}', 0);

  const parentDiv = document.createElement('div');
  parentDiv.style.width = '100px';
  parentDiv.style.height = '100px';
  parentDiv.style.position = 'absolute';
  document.body.appendChild(parentDiv);

  const totalWidth = parentDiv.offsetWidth;
  parentDiv.style.overflow = 'scroll';

  const childDiv = document.createElement('div');
  childDiv.style.width = '100%';
  childDiv.style.height = '150px';
  parentDiv.appendChild(childDiv);

  const widthWithScroll = childDiv.offsetWidth;

  parentDiv.parentNode.removeChild(parentDiv);
  stylesheet.parentNode.removeChild(stylesheet);

  return totalWidth - widthWithScroll;
}

class DeviceWrapper extends Component {
  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    this.isMobile = window.__pageproof_bridge__.browserService.is('mobile');
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {onDimensionsChange} = this.props;
    const dimensions = this.getDimensions();
    const nextDimensions = this.getDimensions(nextProps);
    if (onDimensionsChange && !compareDimensions(dimensions, nextDimensions)) {
      onDimensionsChange(nextDimensions);
    }
  }

  getDimensions(props = this.props) {
    const {
      width,
      height,
      orientation: _orientation,
      device: deviceId,
      contentHeight,
      isFitViewport,
    } = props;
    const device = (
      devicesById[deviceId] || // The device by id
      matchCustomDevice(deviceId) || // The device by dimensions string
      {width, height} // The device by container w/h
    );
    const scrollbarWidth = this.isMobile ? 0 : getScrollbarWidth();
    const canRotate = !!device.rotate;
    const isHorizontal = canRotate && _orientation === 'horizontal';
    const orientation = isHorizontal ? 'horizontal' : 'vertical';
    const deviceHeight = device.height === 'auto' ? height : device.height;
    const deviceWidth = contentHeight > deviceHeight ? device.width + scrollbarWidth : device.width;
    const viewportWidth = Math.round(isHorizontal ? deviceHeight : deviceWidth);
    const viewportHeight = Math.round(isHorizontal ? deviceWidth : deviceHeight);
    const fitToViewportScale = viewportWidth > 0 && viewportHeight > 0 ? Math.min(width / (isHorizontal ? deviceHeight : device.width), height / viewportHeight) : 0;
    const scale = isFitViewport ? fitToViewportScale : Math.min(1, fitToViewportScale);
    const logicalWidth = Math.ceil(viewportWidth * scale);
    const logicalHeight = Math.ceil(viewportHeight * scale);
    return {
      orientation,
      scale,
      container: {
        width,
        height,
      },
      viewport: {
        width: viewportWidth + 1,
        height: viewportHeight,
      },
      originalViewport: {
        width: Math.round(device.width),
        height: Math.round(deviceHeight),
      },
      logical: {
        width: logicalWidth,
        height: logicalHeight,
      },
    };
  }

  render() {
    const {
      children,
      background,
    } = this.props;
    const dimensions = this.getDimensions();
    const {scale, container, viewport, logical} = dimensions;
    const scalePercent = Math.round(scale * 100) + '%';
    const logicalStyle = {
      width: logical.width,
      height: logical.height,
      marginLeft: -Math.round(logical.width / 2),
      marginTop: -Math.round(logical.height / 2),
    };
    return (
      <div
        className="DeviceWrapper"
        style={{
          width: container.width,
          height: container.height,
        }}
      >
        <div
          className="DeviceWrapper__background"
          style={logicalStyle}
        >
          {background}
          <ShyTextPill
            delay={3000}
            className="DeviceWrapper__scale"
          >
            {scalePercent}
          </ShyTextPill>
        </div>
        <div
          className="DeviceWrapper__layer"
          style={logicalStyle}
        >
          <div className="DeviceWrapper__wrapper">
            <div
              className="DeviceWrapper__device"
              style={{
                width: viewport.width,
                height: viewport.height,
                transform: `scale(${scale})`,
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceWrapper;
