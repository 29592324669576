/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useImperativeHandle, useState, useRef, forwardRef } from 'react';
import css from './WorkflowChooser.scss';

import ExpandingSection from './ExpandingSection';
import randomId from '../utils/randomId';
import useUserWorkflowTemplates from '../../../hooks/useUserWorkflowTemplates';
import Translation from '../../Text/Translation';
import InputOptions from './InputOptions';
import { getWorkflowTemplateProofOwners, mapWorkflowFromSdk } from '../utils/workflows';
import { sdk } from '../../../util/sdk';

import Dropdown, {
  Option as DropdownOption,
  Separator as DropdownSeparator,
  OptionLabel as DropdownOptionLabel,
} from '../../Dropdown';
import { Search } from '../../Search';
import { TranslatedProps } from '../../Text';
import { openModal } from '../../../util/modal';
import { SkipAlreadyApprovedWorkflowUsers } from './SkipAlreadyApprovedWorkflowUsers';

const WorkflowChooser = ({ count, isEdited, previousVersionWorkflowId, currentWorkflowId, hasWorkflow, canSaveWorkflow, onWorkflow, onSaveWorkflow, setIsLoadingWorkflow, isLoadingWorkflow, workflow, onChangeWorkflow }) => {
  const workflowsDropdownRef = useRef();
  const canSkipUsersWhoApproved = !!previousVersionWorkflowId;

  const onClickSaveWorkflow = () => {
    Promise.resolve(onSaveWorkflow && onSaveWorkflow())
      .then(() => workflowsDropdownRef.current.reload());
  };

  return (
    <div className={css.WorkflowChooser}>
      <ExpandingSection.Heading
        icon="/img/icons/material/reduce_capacity-24px.svg"
        title={<Translation value="proof.setup.workflow.chooser.title" />}
        count={count}
        description={
          <React.Fragment>
            <div>
              <Translation value="proof.setup.workflow.chooser.description" />
            </div>
            <div className={css.WorkflowChooser__options}>
              <div
                className={css.WorkflowChooser__dropdown}
                onClick={event => event.stopPropagation()}
              >
                <WorkflowChooser.WorkflowsDropdown
                  ref={workflowsDropdownRef}
                  isEdited={isEdited}
                  previousVersionWorkflowId={previousVersionWorkflowId}
                  currentWorkflowId={currentWorkflowId}
                  hasWorkflow={hasWorkflow}
                  onWorkflow={onWorkflow}
                  isLoadingWorkflow={isLoadingWorkflow}
                  setIsLoadingWorkflow={setIsLoadingWorkflow}
                />
              </div>
              {(canSaveWorkflow || canSkipUsersWhoApproved) && (
                <div
                  className={css.WorkflowChooser__more}
                  onClick={event => event.stopPropagation()}
                >
                  <InputOptions
                    absolute
                    options={
                      <React.Fragment>
                        {canSaveWorkflow && (
                          <InputOptions.Option
                            label={<Translation value="proof.setup.workflow.chooser.save-new-workflow-template.icon" />}
                            onClick={onClickSaveWorkflow}
                          />
                        )}
                        {canSkipUsersWhoApproved && (
                          <InputOptions.Option
                            label={<Translation value="proof.setup.workflow.chooser.skip-previously-approved.option" />}
                            onClick={() => {
                              const { destroy: onClose } = openModal(
                                <SkipAlreadyApprovedWorkflowUsers
                                  previousVersionWorkflowId={previousVersionWorkflowId}
                                  workflow={workflow}
                                  onChangeWorkflow={onChangeWorkflow}
                                  onClose={() => onClose()}
                                />,
                                null,
                                true
                              );
                            }}
                          />
                        )}
                      </React.Fragment>
                    }
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        }
      />
    </div>
  );
};

const newEmptyWorkflow = () => ({
  _id: randomId(),
  id: null,
  name: '',
  steps: [
    {
      _id: randomId(),
      name: '',
      users: [],
    },
  ],
});


WorkflowChooser.WorkflowsDropdown = forwardRef(({
  isEdited,
  canCreateNew = true,
  defaultSelectedWorkflowName,
  previousVersionWorkflowId,
  currentWorkflowId,
  hasWorkflow,
  onWorkflow,
  isLoadingWorkflow,
  setIsLoadingWorkflow,
}, ref) => {
  const [workflowTemplatesReadyState, workflowTemplates, reloadWorkflowTemplates] = useUserWorkflowTemplates();
  const [searchTerm, setSearchTerm] = useState();

  useImperativeHandle(ref, () => ({
    reload: reloadWorkflowTemplates,
  }), []);

  const isLoading = (
    (
      workflowTemplatesReadyState === 'loading' &&
      workflowTemplates.owned.length === 0 &&
      workflowTemplates.shared.length === 0
    ) ||
    workflowTemplatesReadyState === 'reloading' ||
    isLoadingWorkflow
  );

  const selectTemplate = (id) => {
    setIsLoadingWorkflow(true);
    Promise.all([
      sdk.workflows.load(id),
      getWorkflowTemplateProofOwners(id),
    ])
      .then(([workflow, owners]) => {
        onWorkflow({
          workflow: mapWorkflowFromSdk(workflow),
          owners,
        });
      }, () => { })
      .then(() => {
        setIsLoadingWorkflow(false);
      });
  };

  let selectedWorkflowName = defaultSelectedWorkflowName || <Translation value="proof.setup.workflow.chooser.add-reviewers" />;

  if (currentWorkflowId) {
    selectedWorkflowName = <Translation value="proof.setup.workflow.chooser.new-workflow" />;
    workflowTemplates.all.forEach((template) => {
      if (template.id === currentWorkflowId) {
        selectedWorkflowName = template.name;
      }
    });
  } else if (hasWorkflow) {
    selectedWorkflowName = <Translation value="proof.setup.workflow.chooser.new-workflow" />;
  }

  const isEditedLabelText = (isEdited && !isLoading) ? <Translation value="proof.setup.workflow.chooser.modified" /> : null;

  const filteredWorkflowTemplates = ['favorites', 'owned', 'shared']
    .map(type => ({
      type,
      templates: workflowTemplates[type].filter(template => !searchTerm || template.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1),
    }))
    .filter(({ templates }) => (templates.length >= 1));

  return (
    <div className={css.WorkflowsDropdown}>
      <Dropdown
        selected={isLoading
          ? undefined
          : currentWorkflowId
        }
        placeholder={
          <DropdownOption
            label={
              <DropdownOptionLabel
                title={isLoading
                  ? <Translation value="loading.message" />
                  : selectedWorkflowName}
                aside={isEditedLabelText}
              />
            }
          />
        }
        wrap
        classNames={{ options: css.WorkflowsDropdown__options }}
      >
        {hasWorkflow && (
          <DropdownOption
            value={-2}
            label={
              <DropdownOptionLabel
                title={<Translation value="proof.setup.workflow.chooser.no-workflow" />}
                description={<Translation value="proof.setup.workflow.chooser.no-workflow.description" />}
              />
            }
            onClick={() => onWorkflow({ workflow: null, owners: [] })}
          />
        )}
        {canCreateNew && (
          <DropdownOption
            value={-1}
            label={
              <DropdownOptionLabel
                title={<Translation value="proof.setup.workflow.chooser.new-workflow" />}
                description={<Translation value="proof.setup.workflow.chooser.new-workflow.description" />}
              />
            }
            onClick={() => onWorkflow({ workflow: newEmptyWorkflow(), owners: [] })}
          />
        )}
        {previousVersionWorkflowId && (
          <DropdownOption
            value={previousVersionWorkflowId}
            label={
              <DropdownOptionLabel
                title={<Translation value="proof.setup.workflow.chooser.current-workflow" />}
                aside={previousVersionWorkflowId === currentWorkflowId
                  ? isEditedLabelText
                  : null}
                description={<Translation value="proof.setup.workflow.chooser.current-workflow.description" />}
              />
            }
            onClick={() => selectTemplate(previousVersionWorkflowId)}
          />
        )}
        {canCreateNew &&
          <DropdownSeparator fullWidth />
        }
        <div className={css.WorkflowChooser__searchHolder}>
          <TranslatedProps placeholder="workflow.search.placeholder">
            <Search
              searchTerm={searchTerm}
              onSearch={setSearchTerm}
              onClearSearch={() => setSearchTerm()}
              theme="grey"
              simple
              autoFocus
            />
          </TranslatedProps>
        </div>
        {!filteredWorkflowTemplates.length && (
          <Fragment>
            {!searchTerm && (
              <div className={css.WorkflowChooser__templateHeader}>
                <Translation value="workflow.dashboard.my-templates" />
              </div>
            )}
            <div className={css.WorkflowChooser__none}>
              {!searchTerm
                ? <Translation value="proof.setup.workflow.chooser.none-workflow" />
                : <Translation value="proof.setup.workflow.chooser.no-templates-match" />
              }
            </div>
          </Fragment>
        )}
        {filteredWorkflowTemplates.map(({ type, templates }, index) => (
          <Fragment key={type}>
            <div className={css.WorkflowChooser__templateHeader}>
              {type === 'favorites' && <Translation value="workflow.dashboard.favorites-header" />}
              {type === 'owned' && <Translation value="workflow.dashboard.my-templates" />}
              {type === 'shared' && <Translation value="workflow.dashboard.shared" />}
            </div>
            {templates.map(({ id, name }) => (
              name.trim().length >= 1
                ? (
                  <DropdownOption
                    key={id}
                    classNames={css.WorkflowsDropdown__options__option}
                    label={name}
                    value={id}
                    selected={currentWorkflowId === id}
                    onClick={() => {
                      selectTemplate(id);
                    }}
                  />
                )
                : null
            ))}
            {index < filteredWorkflowTemplates.length - 1 && (
              <DropdownSeparator
                fullWidth
              />
            )}
          </Fragment>
        ))}
      </Dropdown>
    </div>
  );
});

export default WorkflowChooser;
