/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import Translation from '../Text/Translation';

function ToggleView({
  onToggle,
  view,
  color,
  isManageMode,
  toggleManage,
}) {
  return (
    <span className={`ToggleView ToggleView--${color}`}>
      {toggleManage &&
        <Tooltip
          maxWidth={150}
          up
          center
          title={<Translation value="dashboard.manage" />}
        >
          <span
            className="ManageMenu"
            onClick={toggleManage}
          >
            <InlineSVG
              className={classname({
                'ToggleView--selected': isManageMode
              })}
              src="img/interface/settings.svg"
            />
          </span>
        </Tooltip>
      }
      <Tooltip
        maxWidth={150}
        up
        center
        title={<Translation value="dashboard.tile-view" />}
      >
        <span onClick={() => onToggle('tile')}>
          <InlineSVG
            className={classname({
              'ToggleView--selected': view === 'tile'
            })}
            src="img/interface/view-tile-2.svg"
          />
        </span>
      </Tooltip>
      <Tooltip
        maxWidth={150}
        up
        center
        title={<Translation value="dashboard.list-view" />}
      >
        <span onClick={() => onToggle('list')}>
          <InlineSVG
            className={classname({
              'ToggleView--selected': view === 'list'
            })}
            src="img/interface/view-list-2.svg"
          />
        </span>
      </Tooltip>
    </span>
  );
}

// The color of the navigation panel, not the color of the view icons
ToggleView.defaultProps = {
  color: 'grey',
  isManageMode: false,
};

if (process.env.NODE_ENV !== 'production') {
  ToggleView.propTypes = {
    onToggle: PropTypes.func.isRequired,
    view: PropTypes.oneOf([
      'tile',
      'list',
    ]).isRequired,
    color: PropTypes.oneOf([
      'grey',
      'green',
    ]),
    isManageMode: PropTypes.bool,
    toggleManage: PropTypes.func,
  };
}

export default ToggleView;
