import React from 'react';
import css from './FloatingInputDescription.scss';
import HelpBubble from '../../HelpBubble/HelpBubble';

const FloatingInputDescription = ({ description }) => (
  <div className={css.FloatingInputDescription}>
    <HelpBubble message={description} />
  </div>
);

export default FloatingInputDescription;
