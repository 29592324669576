/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'PrivateComment',
  src: 'img/icons/material/visibility_off_flipped-24px.svg',
  activeColor: '#aaa',
  activeHoverColor: '#919191',
});
