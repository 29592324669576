/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import css from './DiscardButtonOnHover.scss';

const DiscardButtonOnHover = ({ onDiscard, children }) => (
  <div className={css.DiscardButtonOnHover}>
    {children}
    <button
      type="button"
      className={css.DiscardButtonOnHover__discard}
      onClick={onDiscard}
    >
      <InlineSVG src="/img/interface/menu-close-icon.svg" />
    </button>
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  DiscardButtonOnHover.propTypes = {
    onDiscard: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };
}

export default DiscardButtonOnHover;
