/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { Translation } from '../Text';
import Tooltip from '../Tooltip';
import WrapConditionally from '../WrapConditionally';

const Switch = ({
  size = 'small',
  minimal,
  message,
  value,
  disabled,
  offColor = 'grey',
  messageColor,
  onChange,
  tooltip,
  onColor,
}) => (
  <div
    className={classname('app__on-off-toggle', `app__on-off-toggle--${size}`, {
      'app__on-off-toggle--on': value,
      'app__on-off-toggle--off': !value,
      'app__on-off-toggle--disabled': disabled,
      'app__on-off-toggle--minimal': minimal,
    })}
  >
    <WrapConditionally
      condition={!!tooltip}
      wrapper={(
        <Tooltip
          title={tooltip}
          up
          center
        />
      )}
    >
      <div
        className={classname('app__on-off-toggle__options', {
          ['app__on-off-toggle__options--' + offColor]: offColor && !value,
          ['app__on-off-toggle__options--' + onColor]: onColor && value,
        })}
        style={{ marginRight: message ? '20px' : 0 }}
        onClick={() => {
          if (!disabled) {
            onChange(!value);
          }
        }}
      >
        <div
          className="app__on-off-toggle__slider"
        />
      </div>
    </WrapConditionally>
    {message &&
      <div
        className={classname('app__on-off-toggle__message', {
          [`app__on-off-toggle__message--${messageColor}`]: messageColor,
        })}
      >
        <Translation value={message} />
      </div>
    }
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  Switch.propTypes = {
    size: PropTypes.oneOf(['medium', 'small', 'tiny']),
    minimal: PropTypes.bool,
    value: PropTypes.bool.isRequired,
    message: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    onColor: PropTypes.string,
    disabled: PropTypes.bool,
    offColor: PropTypes.string,
    messageColor: PropTypes.string,
    tooltip: PropTypes.string,
  };
}
export default Switch;
