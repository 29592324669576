/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Translation } from '../Text';

const appsToInstall = [
  {
    integrationKey: 'adobe',
    labelTranslationKey: 'integration.adobe',
    tooltip: <Translation value="integration.adobe.tooltip" />,
    availableLevels: ['team', 'user'],
  },
  {
    integrationKey: 'canva',
    labelTranslationKey: 'integration.canva',
    tooltip: <Translation value="integration.canva.tooltip" />,
    availableLevels: ['team', 'user'],
  },
  {
    integrationKey: 'ms_teams',
    labelTranslationKey: 'integration.ms-teams',
    availableLevels: ['team'],
  },
];

export default appsToInstall;
