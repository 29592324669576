/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {useEffect } from 'react';
import NotVisibleOverlay from '../../NotVisibleOverlay';
import css from './MessagePillWithAutoClick.scss';

function MessagePillWithAutoClick({
  onClick,
  label,
}) {
  useEffect(() => {
    let timer = null;
    timer = setTimeout(() => {
      onClick();
    }, 8000); // 8 Seconds timer
    return () => clearTimeout(timer);
  });

  return (
    <div
      className={css.MessagePillWithAutoClick}
      onClick={onClick}
    >
      <div>
        <NotVisibleOverlay
          label={label}
        />
      </div>
    </div>
  );
}

export default MessagePillWithAutoClick;
