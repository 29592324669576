/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import classname from 'classname';
import Popover from '../Popover';
import UserList from '../UserList';
import Mention from '../Comment/Mention';
import css from './FilterUserPopover.scss';
import Translation from '../Text/Translation';
import { Option, Separator } from '../PopupMenu';
import ImportedCommentAvatar from '../ImportedCommentAvatar';

const FilterUserPopover = ({
  id,
  label,
  icon,
  active,
  onClick,
  selectedUserId,
  onClearFilter,
  users,
}) => (
  <Popover
    variant="light"
    content={menu => (
      <div className={css.FilterUserPopover__popover}>
        <UserList
          onUserClick={(group, user) => {
            onClick(group, user);
            menu.hide();
          }}
          groups={[
            {
              id,
              name: label,
              users: users.map(user => ({
                ...user,
                avatar: user.id.startsWith('pdfAuthor:') ? <ImportedCommentAvatar avatarSize={20} /> : user.avatar,
                name: (
                  user.id === selectedUserId
                    ? <Mention id={user.id} label={user.name} />
                    : user.name
                ),
              })),
            },
          ]}
        />
        {!!selectedUserId && (
          <Fragment>
            <Separator variant="light" />
            <Option
              variant="light"
              label={<Translation value="comments-header.filter.clear-filter" />}
              onClick={() => {
                onClearFilter();
                menu.hide();
              }}
            />
          </Fragment>
        )}
      </div>
    )}
    arrow
    down
    center
    offset={20}
    padding={false}
  >
    <span
      className={classname(css.FilterUserPopover, css['FilterUserPopover--icon'], {
        [css['FilterUserPopover--active']]: active,
      })}
    >
      {icon}
    </span>
  </Popover>
);

export default FilterUserPopover;
