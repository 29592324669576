import React from 'react';
import classname from 'classname';

class IsScrolledComponent extends React.Component {
  static defaultProps = {
    topClassName: 'is-scrolled-top',
    rightClassName: 'is-scrolled-right',
    bottomClassName: 'is-scrolled-bottom',
    leftClassName: 'is-scrolled-left',
  };

  state = {
    top: false,
    right: false,
    bottom: false,
    left: false,
  };

  childRef = React.createRef();

  componentDidMount() {
    this.calculateState();
    this.childRef.current.addEventListener('scroll', this.calculateState, { passive: true });
  }

  componentDidUpdate() {
    this.calculateState();
  }

  componentWillUnmount() {
    this.childRef.current.removeEventListener('scroll', this.calculateState, { passive: true });
  }

  calculateState = () => {
    const {
      scrollTop,
      scrollLeft,
      scrollWidth,
      scrollHeight,
      clientWidth,
      clientHeight,
    } = this.childRef.current;
    const updatedState = {
      top: scrollTop > 0,
      right: (scrollLeft + clientWidth) < scrollWidth,
      bottom: (scrollTop + clientHeight) < scrollHeight,
      left: scrollLeft > 0,
    };
    if (
      this.state.top !== updatedState.top ||
      this.state.right !== updatedState.right ||
      this.state.bottom !== updatedState.bottom ||
      this.state.left !== updatedState.left
    ) {
      this.setState(updatedState);
    }
  }

  render() {
    const {
      topClassName,
      rightClassName,
      bottomClassName,
      leftClassName,
      children,
    } = this.props;

    const className = classname({
      [topClassName]: this.state.top,
      [rightClassName]: this.state.right,
      [bottomClassName]: this.state.bottom,
      [leftClassName]: this.state.left,
    });

    if (typeof children === 'function') {
      return children({
        ref: this.childRef,
        className,
      });
    }

    const child = React.Children.only(children);
    return React.cloneElement(child, {
      ref: this.childRef,
      className: classname(child.props.className),
    });
  }
}

export default IsScrolledComponent;
