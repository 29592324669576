/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// source: https://github.com/PageProofCom/sdk/blob/ad24cd4fc0a999e2afc5d1ea4853a7f0473fab95/src/util/sort.js
/* eslint-disable */

/** @private */
export function sortObjectsByKey(key, reverse, toLowerCase = false) {
  return sortObjectsBy(item => {
    return toLowerCase ? item[key].toLowerCase() : item[key];
  }, reverse);
}

/** @private */
export function sortObjectsBy(getValue, reverse) {
  const moveUp = reverse ? 1 : -1;
  const moveDown = reverse ? -1 : 1;
  return (a, b) => {
    const aValue = getValue(a);
    const bValue = getValue(b);
    if (aValue < bValue) {
      return moveUp;
    } else if (aValue > bValue) {
      return moveDown;
    } else {
      return 0;
    }
  };
}

/** @private */
export function sortObjectsByValues(key, values) {
  const indexes = {};
  values.forEach((value, index) => {
    indexes[value] = index;
  });
  const getValue = typeof key === 'function'
    ? key
    : item => item[key];
  return (a, b) => {
    const aIndex = indexes[getValue(a)];
    const bIndex = indexes[getValue(b)];
    if (aIndex < bIndex) {
      return -1;
    } else if (aIndex > bIndex) {
      return 1;
    } else {
      return 0;
    }
  };
}

/** @private */
export function combineSort(...args) {
  const fns = args.length;
  return (a, b) => {
    for (let i = 0; i < fns; i++) {
      const direction = args[i](a, b);
      if (direction) return direction;
    }
    return 0;
  };
}
