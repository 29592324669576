import React from 'react';
import Media from 'react-media';
import URLSubscription from '../URLSubscription';

function MobileDashboard({children}) {
  return (
    <Media query="(max-width: 750px)">
      {matches => (
        <URLSubscription>
          {({path}) => (
            children(matches && path.indexOf('/dashboard') === 0)
          )}
        </URLSubscription>
      )}
    </Media>
  );
}

export default MobileDashboard;
