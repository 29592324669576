/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import OptionalDateTimePicker from '../OptionalDateTimePicker';
import { Translation } from '../Text';
import css from './WorkflowStepDueDate.scss';
import Tooltip from '../Tooltip';
import FormatDate from '../Date/FormatDate';

const CalendarIcon = ({ color }) => (
  <InlineSVG
    className={classname(
      css.WorkflowStepDueDate__calendarIcon,
      css[`WorkflowStepDueDate__calendarIcon--${color}`]
    )}
    src="img/interface/calendar.svg"
  />
);

const WorkflowStepDueDate = ({ dueDate, onChange, readOnly, completeDate }) => {
  // eslint-disable-next-line consistent-return
  const color = useMemo(() => {
    if (dueDate) {
      return dueDate < (completeDate || new Date()) ? 'red' : 'green';
    }
  }, [dueDate, completeDate]);

  if (readOnly) {
    return (
      <Tooltip
        title={
          <FormatDate
            date={dueDate}
            formatTo="h:mma, Do MMMM YYYY"
          />
        }
        up
        center
        disabled={!dueDate}
      >
        <div className={css.WorkflowStepDueDate}>
          <CalendarIcon
            color={color}
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={css.WorkflowStepDueDate}>
      <OptionalDateTimePicker
        title={<Translation value="workflow.step.due-date" />}
        onChange={onChange}
        value={dueDate}
      >
        <CalendarIcon
          color={color}
        />
      </OptionalDateTimePicker>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  CalendarIcon.propTypes = {
    color: PropTypes.string,
  };

  WorkflowStepDueDate.propTypes = {
    dueDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.oneOf([null]),
    ]),
    completeDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.oneOf([null]),
    ]),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
  };
}

export default WorkflowStepDueDate;
