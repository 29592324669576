/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BulkGeneralCommentsButton from '../../components/BulkGeneralCommentsButton';
import { getInitialSelectionState, mapFileForSelection } from '../../util/proof-download-utils';
import fakeProgress from '../../components/ProofSetup/utils/fakeProgress';

const BulkGeneralCommentsContainer = ({ proofs, onCommentsCreated }) => {
  const {
    sdk,
    UserService: user,
  } = window.__pageproof_bridge__;
  const [createdCommentCount, setCreatedCommentCount] = useState(0);
  const [commentValue, setCommentValue] = useState('');
  const [progress, setProgress] = useState({});
  const [errors, setErrors] = useState({});
  const [selection, setSelection] = useState(() => getInitialSelectionState(proofs));
  const selectableProofs = proofs.map(proof => mapFileForSelection(proof, selection, progress, errors));
  const allSelected = !(selectableProofs.filter(proof => !proof.error).some(proof => !proof.selected));

  const onSelectChange = selected => (
    setSelection(prevState => ({
      ...prevState,
      ...selected,
    }))
  );

  const onSelectAll = () => {
    onSelectChange(selectableProofs.reduce((accSelection, proof) => {
      if (!proof.error) {
        accSelection[proof.id] = !allSelected; // eslint-disable-line
      }
      return accSelection;
    }, {}));
  };

  const createBulkGeneralComments = (text, selectedProofIds) => {
    const done = window.__pageproof_bridge__.backgroundService.create('Creating bulk general comments');
    selectedProofIds.forEach(id => (
      setProgress(prevState => ({ ...prevState, [id]: 0 }))
    ));

    {
      const commentPromises = [];
      const proofIds = [...selectedProofIds];
      const next = () => {
        const proofId = proofIds.shift();
        if (!proofId) {
          return;
        }
        commentPromises.push(
          fakeProgress(
            sdk.comments.create({
              proofId,
              raw: text,
              autoState: true,
            }).catch(() => {
              setSelection(prevState => ({ ...prevState, [proofId]: false }));
              setErrors(prevState => ({ ...prevState, [proofId]: true }));
            }),
            100,
            95,
            (percent) => {
              setProgress(prevState => ({ ...prevState, [proofId]: percent }));
            },
          ).then((comment) => {
            setProgress(prevState => ({ ...prevState, [proofId]: null }));
            next();
            return comment;
          })
        );
        if (!proofIds.length) {
          Promise.all(commentPromises).then((createdComments) => {
            // Filter out the undefineds from the failed comments.
            const createdCommentsProofIds = createdComments.filter(Boolean).map(comment => comment.proofId);
            onCommentsCreated(createdCommentsProofIds);
            setCommentValue('');
            setCreatedCommentCount(createdCommentCount + createdCommentsProofIds.length);
            // Setting OnBeforeUnloadTask to done when we done creating comments.
            // Letting people close tab without pop-up
          }).finally(done);
        }
      };
      let concurrentCount = 10;
      while (concurrentCount) {
        concurrentCount -= 1;
        next();
      }
    }
  };

  return (
    <BulkGeneralCommentsButton
      proofs={selectableProofs}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      allSelected={allSelected}
      user={{
        email: user.userData.userEmail,
        id: user.userData.userId,
      }}
      commentValue={commentValue}
      onCommentValueChange={setCommentValue}
      onSave={() => createBulkGeneralComments(
        commentValue,
        selectableProofs.filter(proof => proof.selected).map(({ id }) => id)
      )}
      createdCommentCount={createdCommentCount}
    />
  );
};

if (process.env.NODE_ENV !== 'production') {
  BulkGeneralCommentsContainer.propTypes = {
    proofs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    onCommentsCreated: PropTypes.func.isRequired,
  };
}

export default BulkGeneralCommentsContainer;
