/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import { InlineSVG } from '@jmshal/react-inline-svg';
import Popover from '../Popover';
import { Option, OptionList, Separator } from '../PopupMenu';
import OptionChanger from '../OptionChanger';
import DateFilterLabel from '../DateFilterLabel';
import Translation from '../Text/Translation';
import Tooltip from '../Tooltip/Tooltip';
import css from './ProofListSort.scss';

// @see DashboardFilters
const possibleStatusFilters = [
  'in proofing',
  'to-dos requested',
  'new version required',
  'approved',
  'archived',
  // 'with editor',
];

const possibleDateFilters = [
  'creation date',
  'due date',
  'approved date',
];

const possibleOrders = [
  'priority',
  'create-date',
  'due-date',
  'name',
  'comment-count',
  'version-number',
  'status',
];

const green = '#138b3b';
const gray = '#8c8c8c';

function ProofListSort({
  filters,
  dateFilters,
  onClickFilter,
  onClickDateFilter,
  order,
  onClickOrder,
  isReversed,
  onClickReversed,
  onClearFilters,
  onResetOrder,
}) {
  const isFiltered = (filters && filters.length) || (dateFilters.length);
  const [activeDateFilter, setActiveDateFilter] = useState(null);

  const orDevider = (
    <div className={css['ProofListSort__or-divider']}>
      <Translation value="dashboard.filters.or" />
    </div>
  );

  return (
    <Popover
      onBeforeOpen={() => setActiveDateFilter(null)}
      content={
        <div>
          <OptionList wrap={false}>
            {filters &&
              <OptionChanger
                label={<Translation value="dashboard.filter-by-status" />}
                options={possibleStatusFilters.map(value => ({
                  label: <Translation value={`dashboard.filters.${value}`} />,
                  value,
                }))}
                placeholder={<Translation value="dashboard.choose-a-filter" />}
                selected={filters}
                multiple
                onClick={onClickFilter}
                arrowWithOptionLabel
                insertBetweenChar={orDevider}
              />
            }
            <Separator />
            <OptionChanger
              label={<Translation value="dashboard.filter-by-date" />}
              placeholder={<Translation value="dashboard.choose-a-filter" />}
              options={possibleDateFilters.map(value => ({
                label: (
                  <DateFilterLabel
                    key={value}
                    dateFilters={dateFilters.find(dateFilter => dateFilter.type === value) || {}}
                    label={`dashboard.filters.${value}`}
                    showCalender={activeDateFilter === value}
                    onChangeActiveFilter={() => {
                      const newActiveDateFilter = activeDateFilter === value ? null : value;
                      setActiveDateFilter(newActiveDateFilter);
                    }}
                    onClick={(begin, end) => onClickDateFilter(value, begin, end)}
                  />
                ),
                value,
                className: (dateFilters.find(dateFilter => dateFilter.type === value) ? css.DateFilterOptions : ''),
              }))}
              multiple
              onUpdateChanger={() => setActiveDateFilter(null)}
              keepOpen
              selected={dateFilters}
              arrowWithOptionLabel
              insertBetweenChar={orDevider}
            />
            <Separator />
            <OptionChanger
              label={<Translation value="dashboard.order-by" />}
              options={possibleOrders.map((value, index) => ({
                label: <Translation value={`dashboard.orders.${value}`} />,
                value,
                default: index === 0,
              }))}
              selected={order || 'auto'}
              onClick={onClickOrder}
              tickWithOptionLabel
            />
            <Separator />
            <Option
              label={<Translation value="dashboard.reverse-order" />}
              checked={isReversed}
              onClick={onClickReversed}
            />
            <Separator />
            <Option
              label={<Translation value="dashboard.filters.reset-order" />}
              onClick={onResetOrder}
            />
            <Option
              label={<Translation value="dashboard.filters.clear-filters" />}
              onClick={onClearFilters}
            />
          </OptionList>
        </div>
      }
      down
      center
      arrow
      offset={15}
    >
      <div className={css.ProofListSort}>
        <div
          className={css.ProofListSort__backdrop}
          style={{ backgroundColor: (isFiltered ? 'green' : 'transparent') }}
        />
        <Tooltip
          down
          center
          title={<Translation value="dashboard.filters.tooltip" />}
          offset={15}
          maxWidth={140}
        >
          <div className={css.ProofListSort__button}>
            <InlineSVG
              src="img/interface/sort-filter.svg"
              width="13"
              height="15"
              fill={isFiltered ? green : gray}
            />
            <InlineSVG
              src="img/interface/sort-arrow.svg"
              width="7"
              height="16"
              fill={(order && order !== 'auto') || isReversed ? green : gray}
            />
          </div>
        </Tooltip>
      </div>
    </Popover>
  );
}

export default ProofListSort;
