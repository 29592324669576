/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { makeUnstableBackendRequest } from '../../../util/unstable-backend-request';
import { getUserId } from './getUserId';

export function getMembers(workflowId, type) {
  return makeUnstableBackendRequest({
    method: 'GET',
    path: `/api/workflows/permission/getlist/${workflowId}`,
  }).then(body => ({
    users: body
      .filter(user => !type || user[type] === '1')
      .map(user => ({
        id: user.UserId,
        email: user.Email,
        _memberId: user.MemberId,
      })),
  }));
}

export function addOrSetMember(workflowId, email, type) {
  // eslint-disable-next-line no-param-reassign
  email = email.toLowerCase();

  return Promise.all([
    getUserId(email),
    getMembers(workflowId), // omit the type to get all members regardless of current permissions
  ])
    .then(([userId, members]) => {
      const existing = members.users
        .find(member => member.email === email);

      if (existing) {
        const memberId = existing._memberId;

        return makeUnstableBackendRequest({
          method: 'POST',
          path: '/api/workflows/permission/memberset',
          body: {
            MemberId: memberId,
            [type]: 1,
          },
        }).then(() => ({
          userId,
          memberId,
        }));
      }

      return makeUnstableBackendRequest({
        method: 'POST',
        path: '/api/workflows/permission/add',
        body: {
          RelatedId: workflowId,
          UserId: userId,
          [type]: 1,
        },
      }).then(data => ({
        userId,
        memberId: data.MemberId,
      }));
    });
}

export function addMember(workflowId, userId, type) {
  return makeUnstableBackendRequest({
    method: 'POST',
    path: '/api/workflows/permission/add',
    body: {
      RelatedId: workflowId,
      UserId: userId,
      [type]: 1,
    },
  }).then(data => ({
    userId,
    memberId: data.MemberId,
  }));
}

export function removeMember(memberId, type) {
  return makeUnstableBackendRequest({
    method: 'POST',
    path: '/api/workflows/permission/memberset',
    body: {
      MemberId: memberId,
      [type]: 0,
    },
  });
}
