/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export function makeUnstableBackendRequest(options) {
  const method = options.method || 'GET';
  const path = options.path;

  const host = new URL(window.env('api_url')).origin;
  const query = options.query
    ? ('?' + new URLSearchParams(options.query))
    : '';

  const additionalHeaders = {};
  let body;
  if (options.body) {
    body = JSON.stringify(options.body);
    additionalHeaders['Content-Type'] = 'application/json';
  }

  const sdk = window.__pageproof_bridge__.sdk;
  return sdk.crypto().generateAuthorizationHeaders(method, path, sdk.session)
    .then(headers => sdk.rawRequest({ method, host, path, query, body, headers: { ...headers, ...additionalHeaders } }))
    .then(response => JSON.parse(response.body));
}
