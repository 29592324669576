/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useState, useEffect } from 'react';

const getFeatureFlags = () => ({ ...window.__pageproof_bridge__.features.flags });

export const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState(getFeatureFlags);
  useEffect(() => (window.__pageproof_bridge__.features.onUpdate(setFeatureFlags)), []);
  return featureFlags;
};
