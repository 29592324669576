/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export function getMediaTimeAndDuration(comment, firstMediaPin) {
  if (firstMediaPin) {
    return firstMediaPin;
  } else if (comment && comment.metadata && typeof comment.metadata.mediaTime !== 'undefined') {
    return comment.metadata.mediaTime;
  }
  return null;
}
