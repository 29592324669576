/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import Ellipsis from '../Text/Ellipsis';
import Avatar from '../../containers/NextAvatar';
import {
  PopupMenu,
  ConfirmActionOption,
} from '../PopupMenu';
import Translation from '../Text/Translation';
import css from './UserAvatarsList.scss';

class UserAvatarsList extends Component {
  state = {
    isClicked: false,
  };

  updateClick = (bool) => {
    this.setState({
      isClicked: bool,
    });
  }

  render() {
    const {
      userIds,
      showEmails,
      size,
      space,
      canRemove,
      onRemove,
      showTooltip,
    } = this.props;

    const {isClicked} = this.state;

    return (
      <Fragment>
        {userIds.map(user => (
          <div
            key={user.userId}
            className={css.UserAvatarsList__avatar}
            style={{marginLeft: space, marginRight: space}}
          >
            {canRemove &&
              <PopupMenu
                up
                maxWidth={150}
                options={menu => (
                  <ConfirmActionOption
                    onClick={() => {
                      this.updateClick(true);
                      onRemove(user.userId)
                        .then(() => {
                          this.updateClick(false);
                          return menu.hide;
                        });
                      return false;
                    }}
                    iconSrc="img/content/proof/icons/delete.svg"
                    label={
                      <Fragment>
                        <Translation
                          value={isClicked
                            ? 'button.remove.active'
                            : 'button.remove'
                          }
                        />
                        &nbsp;
                        {isClicked && <Ellipsis />}
                      </Fragment>
                    }
                    confirmLabel={<Translation value="button.are-you-sure" />}
                  />
                )}
              >
                <Avatar
                  id={user.userId}
                  size={size}
                  onHoverOnly
                  showEmails={showEmails}
                  showTooltip={showTooltip}
                />
              </PopupMenu>
            }
            {!canRemove &&
              <Avatar
                id={user.userId}
                size={size}
                onHoverOnly
                showEmails={showEmails}
                showTooltip={showTooltip}
              />
            }
          </div>
        ))}
      </Fragment>
    );
  }
}


UserAvatarsList.defaultProps = {
  space: 10,
  size: 40,
};

if (process.env.NODE_ENV !== 'production') {
  UserAvatarsList.propTypes = {
    userIds: PropTypes.arrayOf(PropTypes.object),
    showEmails: PropTypes.bool,
    canRemove: PropTypes.bool,
    onRemove: PropTypes.func,
    space: PropTypes.number,
    size: PropTypes.number,
  };
}

export default UserAvatarsList;
