/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ThumbsUp from '../../components/Decisions/Icons/ThumbsUp';
import ThumbsUpOrange from '../../components/Decisions/Icons/ThumbsUpOrange';
import ThumbsDown from '../../components/Decisions/Icons/ThumbsDown';
import Ellipsis from '../../components/Text/Ellipsis';
import { Translation } from '../../components/Text';
import SideBySide from '../../components/Transitions/SideBySide';
import {
  PopupMenu,
  Option,
  Separator,
} from '../../components/PopupMenu';
import css from './ChangeDecisionButtonContainer.scss';

function parseDecision(decision, { readOnly = false, activeColor = true }) {
  const decisionState = window.__pageproof_quark__.sdk.Enum.Decision;
  switch (decision) {
  case decisionState.APPROVED:
    return {
      decisionType: 'approve',
      icon: <ThumbsUp
        active
        activeColor={activeColor && '#138b3b'}
        activeHoverColor={activeColor && '#0d5e28'}
        readOnly={readOnly}
      />,
    };
  case decisionState.APPROVED_WITH_CHANGES:
    return {
      decisionType: 'approveWithChanges',
      icon: <ThumbsUpOrange
        active
        activeColor={activeColor && '#fe8125'}
        activeHoverColor={activeColor && '#ef6601'}
        readOnly={readOnly}
      />,
    };
  case decisionState.SEND_CHANGES:
    return {
      decisionType: 'changes',
      icon: <ThumbsDown
        active
        activeColor={activeColor && '#e51c23'}
        activeHoverColor={activeColor && '#b9151b'}
        readOnly={readOnly}
      />,
    };
  default:
    return null;
  }
}

function getOptions(userType, decision, isOwner, allowedDecisions, commentCount) {
  const { decisionType, icon } = parseDecision(decision, { readOnly: true, activeColor: false });
  const owned = isOwner ? '.owned' : '';
  switch (userType) {
  case 'reviewer':
  case 'view-only':
    return [
      {
        id: 'approved',
        canConfirm: decisionType !== 'approve',
        label: 'proof.change-decision.approved',
        confirmLabel: 'proof.change-decision.are-you-sure',
        activeLabel: 'proof.change-decision.approved.active',
        checked: true,
        checkedIcon: decisionType === 'approve'
          ? icon
          : <ThumbsUp
            active
            readOnly
            size={20}
          />,
      },
      {
        id: 'approved_with_changes',
        canConfirm: decisionType !== 'approveWithChanges',
        label: 'proof.change-decision.approved-with-changes',
        confirmLabel: 'proof.change-decision.are-you-sure',
        activeLabel: 'proof.change-decision.approved-with-changes.active',
        isHidden: (!commentCount && decisionType !== 'approveWithChanges') || !allowedDecisions.includes(window.__pageproof_quark__.sdk.Enum.Decision.APPROVED_WITH_CHANGES),
        checked: true,
        checkedIcon: decisionType === 'approveWithChanges'
          ? icon
          : <ThumbsUpOrange
            active
            readOnly
            size={20}
          />,
      },
      {
        id: 'send_changes',
        canConfirm: decisionType !== 'changes',
        label: 'proof.change-decision.changes',
        confirmLabel: 'proof.change-decision.are-you-sure',
        activeLabel: 'proof.change-decision.changes.active',
        isHidden: !commentCount && decisionType !== 'changes',
        checked: true,
        checkedIcon: decisionType === 'changes'
          ? icon
          : <ThumbsDown
            active
            readOnly
            size={20}
          />,
      },
    ];
  case 'approver':
    return [
      {
        label: `proof.change-decision.approver.${decisionType}`,
        checked: true,
        checkedIcon: icon,
      },
      {
        disabled: true,
        label: decisionType === 'approve' ? `proof.change-decision.final-decision.contact-owner${owned}` : `proof.change-decision.decision.contact-owner${owned}`,
      },
    ];
  case 'gatekeeper':
    return [
      {
        label: `proof.change-decision.gatekeeper.${decisionType}`,
        checked: true,
        checkedIcon: icon,
      },
      {
        disabled: true,
        label: decisionType === 'changes' && `proof.change-decision.decision.contact-owner${owned}`,
      },
    ];
  default:
    return [];
  }
}


function ChangeDecisionButtonContainer({
  proof,
  onDecisionChange,
}) {
  const [isClicked, setClick] = useState(false);
  const [selectedDecision, setSelectedDecision] = useState(null);

  const { isOwnerOrCoOwner: isOwner, decisionUserType: userType, decisionValue: decision, allowedDecisions, commentCount } = proof;
  const options = getOptions(userType, decision, isOwner, allowedDecisions, commentCount)
    .filter(({ isHidden, canConfirm }) => !isHidden && !(userType === 'view-only' && canConfirm));
  options.sort((a, b) => (a.canConfirm - b.canConfirm));

  // Add a line and change-your-decision heading into ordered array for reviewers
  if (userType === 'reviewer') {
    options.splice(1, 0,
      { separator: true },
      {
        label: 'proof.change-decision.change-your-decision',
        disabled: true,
      });
  }

  const clear = () => {
    setClick(false);
    setSelectedDecision(null);
  };

  return (
    <PopupMenu
      up
      onHide={() => clear()}
      disabled={!commentCount && decision === window.__pageproof_quark__.sdk.Enum.Decision.APPROVED}
      options={menu => (
        <SideBySide
          id={selectedDecision}
          reverse={false}
        >
          {selectedDecision
            ? (
              <Fragment>
                <Option
                  label={<Translation value="proof.change-decision.change-heading" />}
                  disabled
                />
                {options.map((option, index) => (
                  <Fragment key={index}>
                    {option.id === selectedDecision &&
                      <Option
                        className={css['ChangeDecision__option-confirm']}
                        onClick={() => {
                          setClick(true);
                          onDecisionChange(option)
                            .then(menu.hide);
                        }}
                        checked
                        checkedIcon={option.checkedIcon}
                        label={
                          <Fragment>
                            <Translation
                              value={isClicked
                                ? option.activeLabel
                                : option.confirmLabel
                              }
                            />
                            &nbsp;
                            {isClicked && <Ellipsis />}
                          </Fragment>
                        }
                      />
                    }
                  </Fragment>
                ))}
              </Fragment>
            )
            : (
              <Fragment>
                <Option
                  label={<Translation value={`proof.change-decision.heading.${userType}`} />}
                  disabled
                />
                {options.map((option, index) => (
                  <Fragment key={index}>
                    {option.label &&
                      <Option
                        disabled={option.disabled}
                        className={css.ChangeDecision__option}
                        label={<Translation value={option.label} />}
                        checked={option.checked}
                        checkedIcon={option.checkedIcon}
                        readOnly={!option.canConfirm}
                        onClick={() => {
                          if (option.canConfirm) {
                            setSelectedDecision(option.id);
                          }
                        }}
                      />
                    }
                    {option.separator &&
                      <Separator />
                    }
                  </Fragment>
                ))}
              </Fragment>
            )
          }
        </SideBySide>
      )}
    >
      <span className={css.ChangeDecision__button}>
        {parseDecision(decision, {}).icon}
      </span>
    </PopupMenu>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ChangeDecisionButtonContainer.propTypes = {
    proof: PropTypes.objectOf(PropTypes.any).isRequired,
    onDecisionChange: PropTypes.func.isRequired,
  };
}

export default ChangeDecisionButtonContainer;
