/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import InputOptions from '../../components/ProofSetup/components/InputOptions';
import { Translation } from '../../components/Text';
import css from './InfoPaneWorkflowOptions.scss';

const InfoPaneWorkflowOptions = ({ onNudgeAll, onSkipAll, onSaveWorkflowTemplate }) => (
  <div className={css.InfoPaneWorkflowOptions}>
    <InputOptions
      options={
        <Fragment>
          {onNudgeAll && (
            <InputOptions.Option
              label={<Translation value="button.nudge-all" />}
              onClick={() => onNudgeAll()}
            />
          )}
          {onSkipAll && (
            <InputOptions.Option
              label={<Translation value="proof-info.workflow-details.skip-all" />}
              onClick={() => onSkipAll()}
            />
          )}
          {onSaveWorkflowTemplate && (
            <InputOptions.Option
              label={<Translation value="proof.setup.workflow.chooser.save-new-workflow-template.icon" />}
              onClick={() => onSaveWorkflowTemplate()}
            />
          )}
        </Fragment>
      }
    />
  </div>
);

export default InfoPaneWorkflowOptions;
