/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';

const definitions = [
  ['timeouts', 'setTimeout', 'clearTimeout'],
  ['intervals', 'setInterval', 'clearInterval'],
  ['immediates', 'setImmediate', 'clearImmediate'],
  ['raf', 'requestAnimationFrame', 'cancelAnimationFrame'],
];

function withTimers(ChildComponent) {
  return class extends Component {
    static displayName = `withTimers(${ChildComponent.displayName || ChildComponent.name})`;

    constructor(props) {
      super(props);

      const cleanup = this.cleanup = [];
      const timers = this.timers = {};
      definitions.forEach(([ids, create, destroy]) => { // eslint-disable-line
        const registry = [];
        const _destroy = timers[destroy] = (id) => {
          window[destroy](id);
        };
        timers[create] = (...args) => {
          const id = window[create](...args);
          registry.push(id);
          const off = _destroy.bind(null, id);
          off.id = id;
          return off;
        };
        cleanup.push(() => {
          registry.forEach(_destroy);
        });
      });
    }

    componentWillUnmount() {
      this.cleanup.forEach(fn => fn());
    }

    render() {
      return (
        <ChildComponent
          ref={(ref) => {
            if (this.props.innerRef) {
              this.props.innerRef.current = ref;
            }
          }}
          {...this.props}
          {...this.timers}
        />
      );
    }
  };
}

export {
  withTimers,
};
