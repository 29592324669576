/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Range from '../../components/Range/Range';
import css from './ZoomControl.scss';
import JumpToInput from '../../components/JumpToInput';

const MAXIMUM_ZOOM_PERCENTAGE = 1000;
const MINIMUM_ZOOM_PERCENTAGE = 1;
const FORMULA_OF_PERCENTAGE_CONVERTING = (MAXIMUM_ZOOM_PERCENTAGE - MINIMUM_ZOOM_PERCENTAGE) / 100;

function zoomLevelToPercentageConvertor(zoomLevel) {
  return (zoomLevel * 100 - MINIMUM_ZOOM_PERCENTAGE) / FORMULA_OF_PERCENTAGE_CONVERTING;
}

function percentageToZoomLevelConvertor(percentage) {
  return (percentage * FORMULA_OF_PERCENTAGE_CONVERTING) + MINIMUM_ZOOM_PERCENTAGE;
}

function ZoomControl({
  zoomLevel,
  onChange,
}) {
  return (
    <div className={css.ZoomControl}>
      <span>
        <JumpToInput
          currentValue={(zoomLevel * 100).toFixed(0)}
          min={MINIMUM_ZOOM_PERCENTAGE}
          max={MAXIMUM_ZOOM_PERCENTAGE}
          onChange={onChange}
        />
        %
      </span>
      <Range
        className={css.ZoomControl__slider}
        size={0}
        orientation="vertical"
        value={zoomLevelToPercentageConvertor(zoomLevel)}
        onChange={percent => onChange(percentageToZoomLevelConvertor(percent))}
      />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ZoomControl.propTypes = {
    zoomLevel: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  };
}

export default ZoomControl;
