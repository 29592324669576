/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-nested-ternary, no-param-reassign */
import React, { Fragment, useMemo, useState } from 'react';
import Input from '../../../components/Input';
import InputOptions from '../../../components/ProofSetup/components/InputOptions';
import { FavoriteButton } from './FavoriteButton';
import { duplicateWorkflowTemplate } from '../../../features/duplicateWorkflowTemplate';
import { ConfirmDeleteOption } from '../../../components/PopupMenu';
import { useText } from '../../../components/Text';

export const NameInput = ({ workflowTemplate, isReadOnly, onNameChange, onFavoriteChange, onDelete }) => {
  const [temporaryName, setTemporaryName] = useState(null);
  const value = typeof temporaryName === 'string' ? temporaryName : (workflowTemplate ? workflowTemplate.name : '');

  const text = useText();

  const isDefaultName = useMemo(() => {
    const translatedDefaultWorkflowName = text('workflow.structure.needs-a-name');
    return value === translatedDefaultWorkflowName || value === '* This workflow needs a name';
  }, [text]);

  return (
    <div style={{ position: 'relative' }}>
      <FavoriteButton
        isFavorite={workflowTemplate ? workflowTemplate.isFavorite : false}
        onClick={() => onFavoriteChange(!workflowTemplate.isFavorite)}
      />
      <InputOptions
        absolute
        options={
          <Fragment>
            <InputOptions.Option
              label="Duplicate"
              onClick={() => duplicateWorkflowTemplate(workflowTemplate.id, workflowTemplate.name)}
            />
            <ConfirmDeleteOption
              variant={isReadOnly ? 'remove' : 'delete'}
              onClick={onDelete}
            />
          </Fragment>
        }
      />
      <Input
        value={isDefaultName ? '' : value}
        onChange={setTemporaryName}
        placeholder={text('workflow.structure.workflow-name')}
        onBlur={() => {
          if (typeof temporaryName === 'string') {
            const isValidName = temporaryName.trim() !== '';

            setTemporaryName(null);

            if (!isValidName) {
              return;
            }

            workflowTemplate.name = temporaryName;
            onNameChange(temporaryName);
          }
        }}
        style={{
          width: '100%',
          // textAlign: 'center',
          paddingLeft: 0,
          paddingRight: 50,
        }}
        readOnly={isReadOnly}
      />
    </div>
  );
};
