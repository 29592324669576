export default function guardOnChangeLength(currentValue, maxLength, callback) {
  const currentValueLength = (currentValue && currentValue.length) || 0;

  return (newValue) => {
    const newValueLength = (newValue && newValue.length) || 0;

    if (
      (
        currentValueLength < maxLength ||
        newValueLength < currentValueLength
      ) &&
      newValueLength <= maxLength
    ) {
      callback(newValue);
    }
  };
}
