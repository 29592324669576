/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import { Translation } from '../Text';
import { Button } from '../Button';

export default function CopyLinkButton({
  link,
  className,
}) {
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = () => {
    window.generalfunction_copyToClipboard(link);
    setIsCopied(true);
  };

  return (
    <Button
      variant="text"
      className={className}
      autoWidth
      label={<Translation value={
        isCopied
          ? 'proof.utilities.share.copied'
          : 'proof.utilities.share.share-link'
      }
      />}
      onClick={onCopy}
    />
  );
}
