/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Children} from 'react';
import insertBetweenItems from '../../util/insert-between-items';
import css from './HorizontalDelimiter.scss';

function HorizontalDelimiter({
  children,
  delimiter,
}) {
  return (
    <div className={css.HorizontalDelimiter}>
      {insertBetweenItems(Children.toArray(children).filter(Boolean), delimiter)}
    </div>
  );
}

export default HorizontalDelimiter;
