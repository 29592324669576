export const supportedFileTypes = []
  .concat(window._supportedAudioFileTypes)
  .concat(window._supportedDocumentFileTypes)
  .concat(window._supportedVideoFileTypes)
  .concat(window._supportedWebFileTypes);

export const briefSupportedFileTypes = []
  .concat(window._supportedDocumentFileTypes)
  .concat(window._supportedVideoFileTypes)
  .concat(window._supportedWebFileTypes);

export const supportedFileTypesAfterTransform = ['html', 'htm'];
