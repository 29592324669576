import { emojiRegex } from './emoji';

const justEmojisRegex = new RegExp('^((' + emojiRegex.source + ')*\\s?)*$');
export const jumbojiStrategy = (contentBlock, callback) => {
  const text = contentBlock.get('text');
  const matches = text.match(justEmojisRegex);
  if (matches) {
    callback(0, text.length);
  }
};
