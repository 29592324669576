/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useCallback, useRef } from 'react';
import { createStitchEmbed } from '../util/createStitchEmbed';

/**
 * Creates a react component that embeds a Stitch embedded page.
 *
 * @param {string} name The name of the embedded page.
 * @param {{ iframeProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>; observeHeight?: boolean }} [settings] The stitch embed settings.
 * @returns React.FC
 */
export const useStitchEmbed = (name, settings) => {
  const settingsRef = useRef(settings);
  settingsRef.current = settings;

  return useCallback(createStitchEmbed(name, settingsRef), []);
};
