/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import getProofEditedFields from '../../utils/finalize/getProofEditedFields';

export const getModifiedFields = (currentTemplateSnapshot, currentState, currentProofIndex, userPreferences) => {
  const sharedProofsModifiedFields = [];
  const modifiedFields = [];
  const isShared = currentProofIndex === -1;
  const currentStateProof = isShared ? currentState.shared : currentState.proofs[currentProofIndex];
  const snapshotTemplateProof = isShared ? currentTemplateSnapshot.shared : currentTemplateSnapshot.proofs[currentProofIndex];

  const hasMovedFromSharedToIndividualProof = currentTemplateSnapshot.proofs.length > 1 && currentState.proofs.length === 1 && currentProofIndex === 0;

  if (isShared) {
    currentState.proofs.forEach((proof, index) => {
      if (!proof._isEdited) {
        return;
      }

      const proofName = proof.name;
      const modifiedFieldsForProof = getProofEditedFields(proof, currentState.shared, currentState.parameters, userPreferences);

      if (modifiedFieldsForProof.length) {
        sharedProofsModifiedFields.push({
          proofNumber: index + 1,
          proofName,
          fields: modifiedFieldsForProof,
        });
      }
    });
  }

  if (currentTemplateSnapshot.proofs.length > 1) {
    if (currentState.proofs.length !== currentTemplateSnapshot.proofs.length) {
      modifiedFields.push('Number of proofs has changed');
    }
  }

  if (currentState.shared.collection !== currentTemplateSnapshot.shared.collection) {
    modifiedFields.push('Collection');
  }

  if (!isShared && !hasMovedFromSharedToIndividualProof) {
    if (currentStateProof.name !== snapshotTemplateProof.name) {
      modifiedFields.push('Name');
    }
  }

  if (currentStateProof.messageToReviewers !== snapshotTemplateProof.messageToReviewers) {
    modifiedFields.push('Message to reviewers');
  }

  if (currentStateProof.dueDate !== snapshotTemplateProof.dueDate) {
    modifiedFields.push('Due date');
  }

  if (currentStateProof.canDownload !== snapshotTemplateProof.canDownload) {
    modifiedFields.push('Can download');
  }

  if (currentStateProof.isPublic !== snapshotTemplateProof.isPublic) {
    modifiedFields.push('Can share link');
  }

  if (currentStateProof.reference !== snapshotTemplateProof.reference || JSON.stringify(snapshotTemplateProof.integrationReferences) !== JSON.stringify(currentStateProof.integrationReferences)) {
    modifiedFields.push('Reference');
  }

  if (snapshotTemplateProof.tags && !snapshotTemplateProof.tags.every(tag => currentStateProof.tags.indexOf(tag) !== -1)) {
    modifiedFields.push('Tags');
  }

  if (!currentState.owners.every(owner => currentTemplateSnapshot.owners.indexOf(owner) !== -1)) {
    modifiedFields.push('Owners');
  }

  if (currentState.checklist && currentTemplateSnapshot.checklist && currentState.checklist.templateId !== currentTemplateSnapshot.checklist.templateId) {
    modifiedFields.push('Checklist');
  }

  if (currentState.workflow && currentTemplateSnapshot.workflow && currentState.workflow.id !== currentTemplateSnapshot.workflow.id) {
    modifiedFields.push('Workflow');
  }


  return {
    modifiedFields,
    sharedProofsModifiedFields,
  };
};
