/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { Translation, TranslatedProps } from '../../components/Text';
import { Button } from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import TextareaWithTools from '../../components/TextareaWithTools';
import CollectionManageConfirmBox from './CollectionManageConfirmBox';
import styles from './CollectionManageStyles';
import CollectionManageBackButton from './CollectionManageBackButton';
import InputLimitCounter from '../../components/InputLimitCounter';

function CollectionManageBox({
  id,
  onChange,
  onUpdate,
  onCancel,
  heading,
  message,
  show,
  buttonLabel,
  onClick,
  placeholder,
  value,
  confirmButtons,
  removeButtonLabel,
  onRemove,
  textAreaTools,
  alwaysShowButton,
  maxLength,
}) {
  const canShowConfirm = confirmButtons && confirmButtons.length;
  const [showConfirm, setShowConfirm] = useState(false);
  const textAreaRef = useRef();
  const [textInput, setTextInput] = useState(value);

  const onClickHandler = () => {
    if (canShowConfirm) {
      setShowConfirm(true);
    } else {
      if (onChange) {
        onChange(textInput);
        if (onUpdate && value !== '') {
          onUpdate();
        }
      }
      onClick();
    }
  };

  useEffect(() => {
    setTextInput(value);
  }, [value]);

  useEffect(() => {
    if (!show) {
      setShowConfirm(false);
    } else if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [show]);

  return (
    <div
      className={classname(
        styles.CollectionManage__confirm,
        { [styles.CollectionManage__confirm__show]: show }
      )}
    >
      <CollectionManageBackButton onClick={onCancel} />
      {!showConfirm &&
        <div className={styles.CollectionManage__confirm__box}>
          <div className={styles.CollectionManage__title}>
            {heading &&
              <h3><Translation value={heading} /></h3>
            }
            {message &&
              <p>
                <Translation value={message} />
              </p>
            }
            {onChange &&
              <Fragment>
                <TranslatedProps placeholder={placeholder}>
                  <TextareaWithTools
                    ref={textAreaRef}
                    tools={textAreaTools}
                    type="text"
                    maxLength={maxLength}
                    value={textInput || ''}
                    onChange={(val) => {
                      if (val.trim().length === 0) {
                        setTextInput(null);
                      } else {
                        setTextInput(val);
                      }
                    }}
                  />
                </TranslatedProps>
                <InputLimitCounter
                  value={textInput}
                  limit={maxLength}
                />
              </Fragment>
            }
          </div>
          <ButtonGroup>
            {removeButtonLabel && onRemove && (
              <Button
                variant="orange"
                label={<Translation value={removeButtonLabel} />}
                onClick={onRemove}
              />
            )}
            {buttonLabel && (alwaysShowButton || (value !== textInput && textInput !== null)) && (
              <Button
                variant="primary"
                label={<Translation value={buttonLabel} />}
                onClick={onClickHandler}
              />
            )}
          </ButtonGroup>
        </div>
      }
      <CollectionManageConfirmBox
        id={id}
        show={showConfirm}
        buttons={confirmButtons}
      />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  CollectionManageBox.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onChange: PropTypes.func,
    onUpdate: PropTypes.func,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    heading: PropTypes.string,
    message: PropTypes.string,
    buttonLabel: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    confirmButtons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      label: PropTypes.string,
      activeLabel: PropTypes.string,
      onClick: PropTypes.func,
    })),
    removeButtonLabel: PropTypes.string,
    onRemove: PropTypes.func,
    textAreaTools: PropTypes.node,
    alwaysShowButton: PropTypes.bool,
    maxLength: PropTypes.number,
  };
}

export default CollectionManageBox;
