/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'AgreeCoronation',
  src: 'img/icons/agree-coronation.svg',
  activeColor: '#002F6C',
  activeHoverColor: '#01285C',
});
