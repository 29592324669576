/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Media from 'react-media';
import FormControlContext from './FormControlContext';
import ValidationToast from '../ValidationToast';

function FormControl({
  label,
  message,
  validation,
  compact,
  children,
  before,
  after,
  fieldOutsideLabel,
  onDismissValidation,
}) {
  const field = (
    <div className="FormControl__field">
      {children}
    </div>
  );

  const { minWidthBeforeCompact } = useContext(FormControlContext);

  return (
    // Minus 1 to the min width as we need to use max-width here.
    // This is because FormControl--compact stops properties being added rather than adding properties
    <Media query={`(max-width: ${minWidthBeforeCompact - 1}px)`}>
      {matches => (
        <div
          className={classname('FormControl', 'FormControl--disableAutoCompact', {
            'FormControl--compact': compact || matches,
          })}
        >
          {before}
          <label className="unstyled">
            {label &&
              <span className="FormControl__label">
                {label}
              </span>
            }
            {!fieldOutsideLabel && field}
          </label>
          {fieldOutsideLabel && field}
          {message
            ? <span className="FormControl__message">{message}</span>
            : null}
          {validation && (
            <ValidationToast
              message={validation}
              onDismiss={onDismissValidation}
              shadow="small"
            />
          )}
          {after}
        </div>
      )}
    </Media>
  );
}

FormControl.defaultProps = {
  compact: false,
};

if (process.env.NODE_ENV !== 'production') {
  FormControl.propTypes = {
    fieldOutsideLabel: PropTypes.bool,
    label: PropTypes.node,
    message: PropTypes.node,
    validation: PropTypes.node,
    compact: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    onDismissValidation: PropTypes.func,
  };
}

export default FormControl;
