import { useEffect, useRef } from 'react';

function useEventListener(target, event, callback) {
  const callbackRef = useRef();
  callbackRef.current = callback;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (target) {
      const handler = (...args) => callbackRef.current(...args);
      window.addEventListener(event, handler);
      return () => window.removeEventListener(event, handler);
    }
  }, [target, event]);
}

export default useEventListener;
