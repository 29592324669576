/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { createTransitionComponent } from './factory';
import css from './ScaledDownAppearing.scss';

const styles = {
  enter: css.ScaledDownAppearing__enter,
  enterActive: css['ScaledDownAppearing__enter--active'],
  leave: css.ScaledDownAppearing__leave,
  leaveActive: css['ScaledDownAppearing__leave--active'],
};

export default createTransitionComponent(styles);
