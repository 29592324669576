/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';
import ProfilePasswordAndSecurity from '../ProfilePasswordAndSecurity';

const ProfilePasswordAndSecurityContainer = () => {
  const [hasTOTP, setHasTOTP] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { userService, sdk, $location, appService } = window.__pageproof_bridge__;

  useEffect(() => {
    userService.populateUser()
      .then((user) => {
        setHasTOTP(user.hasTOTP);
        setIsLoading(false);
      });
  }, []);

  const onChangePassword = (newPassword) => {
    appService.showTranslatedLoaderMessage('loader-message.updating-profile');
    appService.showLoader();

    sdk.profile.changePassword('', newPassword)
      .then(() => {
        appService.hideLoader();
        $location.path('dashboard');
      })
      .catch(() => {
        appService.hideLoader();
      });
  };

  if (isLoading) {
    return null;
  }

  return (
    <ProfilePasswordAndSecurity
      hasTOTP={hasTOTP}
      onUpdateHasTOTP={updatedTOTP => setHasTOTP(updatedTOTP)}
      onChangePassword={onChangePassword}
    />
  );
};

export default ProfilePasswordAndSecurityContainer;
