/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './Pill.scss';

function Pill({ label, variant }) {
  return (
    <span
      className={classname(css.Pill, {
        [css[`Pill--${variant}`]]: variant,
      })}
    >
      {label}
    </span>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Pill.propTypes = {
    label: PropTypes.node.isRequired,
    variant: PropTypes.string,
  };
}

export default Pill;
