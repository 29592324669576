/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */
export function createProgress({ onProgress }) {
  let numberOfTasks = 0;
  let completedTasks = 0;
  const progressTasks = [];

  const updateProgressCallback = () => {
    const totalNumberOfTasks = numberOfTasks + (progressTasks.length * 2);
    const totalCompletedTasks = completedTasks + (progressTasks.reduce((total, current) => total + (current / 100), 0) * 2);
    console.log(totalCompletedTasks.toFixed(2), totalNumberOfTasks, Math.floor(100 / (totalNumberOfTasks / totalCompletedTasks)));
    onProgress(100 / (totalNumberOfTasks / totalCompletedTasks));
  };

  const addProgressTask = () => {
    const index = progressTasks.push(0) - 1;
    return (percent) => {
      progressTasks[index] = percent;
      updateProgressCallback();
    };
  };

  const didCompleteTask = (returnValue) => {
    completedTasks += 1;
    updateProgressCallback();
    return returnValue;
  };

  return {
    addTask: () => (numberOfTasks += 1),
    addTasks: (additionalTasks = 1) => (numberOfTasks += additionalTasks),
    completeTask: didCompleteTask,
    addProgressTask,
  };
}
