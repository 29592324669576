/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
* Unauthorized copying of this file, via any medium is strictly prohibited.
* Proprietary and confidential.
*/
/* eslint-disable quote-props */
const translationKeys = {
  'no team': 'profile.edit.account.reviewer',

  'team': 'profile.edit.account.team',
  'team trial': 'profile.edit.account.team.trial',
  'team expired trial': 'profile.edit.account.team.trial-expired',

  'team plus': 'profile.edit.account.team-plus',
  'team plus trial': 'profile.edit.account.team-plus.trial',
  'team plus expired trial': 'profile.edit.account.team-plus.trial-expired',

  'enterprise': 'profile.edit.account.enterprise',
  'enterprise trial': 'profile.edit.account.enterprise.trial',
  'enterprise expired trial': 'profile.edit.account.enterprise.trial-expired',

  'enterprise plus': 'profile.edit.account.enterprise-plus',
  'enterprise plus trial': 'profile.edit.account.enterprise-plus.trial',
  'enterprise plus expired trial': 'profile.edit.account.enterprise-plus.trial-expired',
};

const getPlanName = (teamType, teamIsPlus, teamIsTrial, teamIsExpiredTrial) => {
  if (teamType === 0) {
    return translationKeys['no team'];
  }

  const translationKeyParts = [];

  switch (teamType) {
  case 1: {
    translationKeyParts.push('team');
    break;
  }
  case 2: {
    translationKeyParts.push('enterprise');
    break;
  }
  default: {
    throw new Error(`Invalid team type: ${teamType}`);
  }
  }

  if (teamIsPlus) {
    translationKeyParts.push('plus');
  }

  if (teamIsTrial) {
    if (teamIsExpiredTrial) {
      translationKeyParts.push('expired');
    }

    translationKeyParts.push('trial');
  }

  const translationKeyId = translationKeyParts.join(' ');
  return translationKeys[translationKeyId];
};


export default getPlanName;
