/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

export function approximateCommentHeight(comment) {
  return (
    (comment.isPrivate ? 30 : 0) +
    160 +
    (comment.attachments && comment.attachments.length > 0 ? 50 : 0) +
    (comment.replies.length * 100)
  );
}

export function approximatePageHeight(comments) {
  return (
    80 +
    (!comments.length ? 45 : 0)
  );
}
