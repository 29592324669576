/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import styles from './Pin.scss';

const PinLine = ({
  colour,
  direction,
  width,
  selected,
}) => (
  <span>
    <span
      className={classname(
        styles.Line,
        styles[`Line--${direction}`],
        { [styles['Line--selected']]: selected },
      )}
      style={{
        width: width + 'px',
        backgroundColor: colour,
      }}
    />
    <span
      className={classname(
        styles.LineEnd,
        { [styles['LineEnd--selected']]: selected },
      )}
      style={{
        left: (direction === 'right' ? width : (width * -1)) + 'px',
        backgroundColor: colour,
      }}
    />
  </span>
);

if (process.env.NODE_ENV !== 'production') {
  PinLine.propTypes = {
    colour: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    selected: PropTypes.bool,
  };
}

export default PinLine;
