/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import Avatar from './Avatar';
import EmailAvatar from './EmailAvatar';

export {
  Avatar,
  EmailAvatar,
};
