/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './Property.scss';

function Property({
  name,
  value
}) {
  return (
    <div className={css.Property}>
      <div className={css.Name}>
        {name}
      </div>
      <div className={css.Value}>
        {value}
      </div>
    </div>
  );
}

export default Property;
