/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './RaisedSurface.scss';

export function RaisedSurface({ children }) {
  return (
    <div
      style={{
        '--shadow-color': '0deg 0% 20%',
        // eslint-disable-next-line quotes
        boxShadow: `0 2px 2px hsl(var(--shadow-color) / 0.05), 0 4px 4px hsl(var(--shadow-color) / 0.05), 0 8px 8px hsl(var(--shadow-color) / 0.05), 0 16px 16px hsl(var(--shadow-color) / 0.05), 0 32px 32px hsl(var(--shadow-color) / 0.05)`,
      }}
      className={css.RaisedSurface}
    >
      {children}
    </div>
  );
}
