/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useContext } from 'react';
import { TabsContext } from './TabsContext';

export const useIsWithinTabs = () => {
  const value = useContext(TabsContext);
  return value !== null;
};
