/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect } from 'react';

const useKeyboardShortcut = (shortcut, callback) => {
  useEffect(() => {
    let isEnabled = true;

    if (shortcut) {
      window.Mousetrap.bind(shortcut, (event) => {
        if (isEnabled) {
          callback(event);
        }
      });
    }

    return () => {
      isEnabled = false;
    };
  }, [
    shortcut,
    callback,
  ]);
};

export default useKeyboardShortcut;
