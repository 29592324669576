/* eslint-disable no-nested-ternary */

import classname from 'classname';
import React, { Fragment } from 'react';
import NoComments from '../../../components/NoComments';
import { Translation } from '../../../components/Text';
import Tooltip from '../../../components/Tooltip';

export function CommentsPage({
  displayHeader,
  proofCtrl,
  whenGoToPage,
  isCurrentPage,
  isPage,
  proofName,
  page,
  comments,
  atLeastOnePageHasCommentsAfterFiltering,
}) {
  const { canViewChanged, canViewDone } = proofCtrl.permissions.proofer.commentLevel;

  return (
    <div
      className="app__comments-page"
      data-page-number={page.pageNumber}
    >
      {displayHeader && (
        <div
          className={classname('app__comments-page__heading app__comments-page__heading--opaque app__comments-page__heading__pages', {
            'app__comments-page__heading--current-page': isCurrentPage,
          })}
          onClick={whenGoToPage}
        >
          {page.version >= 0 && (
            <span className="app__comments-page__heading__text">
              <Translation
                value={page.version >= 1
                  ? 'comments-page.heading.version'
                  : 'comments-page.heading.brief'}
              />
            </span>
          )}

          {isPage && (
            <span className="app__comments-page__heading__text">
              {!isCurrentPage && (
                <span className="app__comments-page__heading__reveal">
                  <Translation value="comments-page.heading.goto" />
                  {' '}
                </span>
              )}
              {' '}
              <strong>
                <Translation value="comments-page.heading.page" />
                {' '}
                {page.pageNumber}
              </strong>
            </span>
          )}

          {!!proofName && (
            <Tooltip title={proofName}>
              <span className="app__comments-page__heading__text app__comments-page__heading__text--name">
                {proofName}
              </span>
            </Tooltip>
          )}

          {!!(page.commentCount && (proofCtrl.filter.name === null || proofCtrl.filter.name === 'pages')) && (
            <span className="app__comments-page__heading__text">
              {!!(page.commentCount - page.privateCount) && (
                <Pluralize
                  count={
                    (proofCtrl.filter.name === null || proofCtrl.filter.name === 'pages')
                      ? (page.commentCount - page.privateCount)
                      : page.filteredComments.length
                  }
                  zero="comments-page.heading.comments.zero"
                  one="comments-page.heading.comments.one"
                  other="comments-page.heading.comments.other"
                />
              )}
              {!!page.privateCount && (
                <Fragment>
                  {' '}
                  <div className="app__comments-page__heading__text__private">
                    {page.commentCount !== page.privateCount ? '+ ' : ''}
                    {page.privateCount}
                    {' '}
                    private
                  </div>
                </Fragment>
              )}
            </span>
          )}

          {!!((canViewChanged || canViewDone) && page.unmarkedCount && proofCtrl.filter.name === 'unmarked') && (
            <span className="app__comments-page__heading__text">
              <Pluralize
                count={page.unmarkedCount || page.proofData.unmarkedCount}
                one="comments-page.heading.unmarked.one"
                other="comments-page.heading.unmarked.other"
              />
            </span>
          )}

          {!!(canViewChanged && page.todoCount && proofCtrl.filter.name === 'todo') && (
            <span className="app__comments-page__heading__text">
              <Pluralize
                count={page.todoCount || page.proofData.todoCount}
                one="comments-page.heading.todos.one"
                other="comments-page.heading.todos.other"
              />
            </span>
          )}

          {!!(canViewDone && page.doneCount && proofCtrl.filter.name === 'done') && (
            <span className="app__comments-page__heading__text">
              <Pluralize
                count={page.doneCount || page.proofData.doneCount}
                one="comments-page.heading.dones.one"
                other="comments-page.heading.dones.other"
              />
            </span>
          )}

          {!!(page.privateCount && proofCtrl.filter.name === 'private') && (
            <span className="app__comments-page__heading__text">
              <div className="app__comments-page__heading__text__private">
                {page.privateCount}
                {' '}
                private
              </div>
            </span>
          )}

          {!!(page.agreeCount && proofCtrl.filter.name === 'agrees') && (
            <span className="app__comments-page__heading__text">
              <Pluralize
                count={page.agreeCount || page.proofData.agreeCount}
                one="comments-page.heading.agrees.one"
                other="comments-page.heading.agrees.other"
              />
            </span>
          )}

          {!!(page.attachmentCount && proofCtrl.filter.name === 'attachments') && (
            <span className="app__comments-page__heading__text">
              <Pluralize
                count={page.attachmentCount || page.proofData.attachmentCount}
                one="comments-page.heading.attachments.one"
                other="comments-page.heading.attachments.other"
              />
            </span>
          )}
        </div>
      )}
      <div className="app__comments-page__comments">
        {!comments.length && (
          <NoComments
            filter={
              page.comments.length
                ? proofCtrl.filter
                : 'comments'
            }
            noCommentsOnPages={!atLeastOnePageHasCommentsAfterFiltering}
          />
        )}
      </div>
    </div>
  );
}

function Pluralize({ count, zero, one, other }) {
  return (
    <Translation
      value={
        count === 0
          ? zero
          : count === 1
            ? one
            : other
      }
      params={{
        _: count,
      }}
      naked={false}
    />
  );
}
