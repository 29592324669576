/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import css from './IntegrationReferences.scss';
import TrelloReferenceDisplay from './TrelloReferenceDisplay';
import { PopupMenu, Option, ConfirmDeleteOption, Separator } from '../PopupMenu';
import getIntegrationFileUrl from '../../util/integration-service-static-files';
import { Translation } from '../Text';
import useDarkMode from '../../hooks/useDarkMode';

const INTEGRATION_DISPLAY_DATA = {
  monday: {
    linkText: <Translation value="integration.reference.view-on-monday" />,
    integrationKey: 'monday.com',
    logoAlt: 'monday.com',
  },
  slack: {
    linkText: <Translation value="integration.reference.open-in-slack" />,
    integrationKey: 'slack',
    logoAlt: 'Slack',
  },
  asana: {
    linkText: <Translation value="integration.reference.view-in-asana" />,
    integrationKey: 'asana',
    logoAlt: 'Asana',
  },
  airtable: {
    linkText: <Translation value="integration.reference.view-in-airtable" />,
    integrationKey: 'airtable',
    logoAlt: 'Airtable',
  },
  trello: {
    linkText: <Translation value="integration.reference.view-in-trello" />,
    integrationKey: 'trello',
    logoAlt: 'Trello',
    Component: TrelloReferenceDisplay,
  },
  canva: {
    linkText: <Translation value="integration.reference.view-in-canva" />,
    integrationKey: 'canva',
    logoAlt: 'Canva',
  },
  clickup: {
    linkText: <Translation value="integration.reference.view-in-clickup" />,
    integrationKey: 'clickup',
    logoAlt: 'ClickUp',
  },
};

const getOptionData = ({ referenceType, referenceId, metadata }) => {
  switch (referenceType) {
  case 'mondayItem':
    return { ...INTEGRATION_DISPLAY_DATA.monday,
      infoText: <Translation
        value="integration.reference.monday-item-id"
        params={{ referenceId }}
      /> };
  case 'mondayBoard':
    return { ...INTEGRATION_DISPLAY_DATA.monday,
      infoText: <Translation
        value="integration.reference.monday-board-id"
        params={{ referenceId }}
      /> };
  case 'mondayGroup':
    return { ...INTEGRATION_DISPLAY_DATA.monday, infoText: <Translation value="integration.reference.monday-group" /> };
  case 'slackPublicChannel':
  case 'slackPrivateChannel':
    return { ...INTEGRATION_DISPLAY_DATA.slack,
      infoText: <Translation
        value="integration.reference.slack-channel-id"
        params={{ referenceId }}
      /> };
  case 'asanaTask':
    return { ...INTEGRATION_DISPLAY_DATA.asana,
      infoText: <Translation
        value="integration.reference.asana-task-id"
        params={{ referenceId }}
      /> };
  case 'asanaProject':
    return { ...INTEGRATION_DISPLAY_DATA.asana,
      infoText: <Translation
        value="integration.reference.asana-project-id"
        params={{ referenceId }}
      /> };
  case 'trelloCard':
    return { ...INTEGRATION_DISPLAY_DATA.trello,
      infoText: <Translation
        value="integration.reference.trello-card-id"
        params={{ referenceId }}
      /> };
  case 'trelloBoard':
    return { ...INTEGRATION_DISPLAY_DATA.trello,
      infoText: <Translation
        value="integration.reference.trello-board-id"
        params={{ referenceId }}
      /> };
  case 'canvaDesign':
    return { ...INTEGRATION_DISPLAY_DATA.canva,
      infoText: <Translation
        value="integration.reference.canva-design-id"
        params={{ referenceId }}
      /> };
  case 'clickupTask': {
    const parsedMetadata = metadata && JSON.parse(metadata);
    const customId = parsedMetadata && parsedMetadata.customId;

    const infoTextTranslationKey = customId ? 'integration.reference.clickup-task-custom-id' : 'integration.reference.clickup-task-id';
    return { ...INTEGRATION_DISPLAY_DATA.clickup,
      infoText: <Translation
        value={infoTextTranslationKey}
        params={{ referenceId, customId }}
      /> };
  }
  case 'airtableRecord':
    return { ...INTEGRATION_DISPLAY_DATA.airtable,
      infoText: <Translation
        value="integration.reference.airtable-record-id"
        params={{ referenceId }}
      /> };
  case 'airtableBase':
    return { ...INTEGRATION_DISPLAY_DATA.airtable,
      infoText: <Translation
        value="integration.reference.airtable-base-id"
        params={{ referenceId }}
      /> };
  default:
    return {
      linkText: <Translation value="integration.reference.default-open" />,
      infoText: <Translation
        value="integration.reference.default-id"
        params={{ referenceId }}
      />,
      // TODO: Add placeholder logoUrl
    };
  }
};

const IntegrationReferences = ({ integrationReferences, removeReference, onViewClick, readOnly }) => {
  if (!integrationReferences || !integrationReferences.length) {
    return null;
  }

  const [isDarkMode] = useDarkMode();

  return (
    <div className={css.Holder}>
      {integrationReferences.map((option) => {
        const { infoText, linkText, logoUrl, integrationKey, logoAlt, Component } = getOptionData(option);
        return (
          <PopupMenu
            key={option.referenceId}
            maxWidth={280}
            options={
              <Fragment>
                {Component ? <Component {...option} /> : null}
                <Option
                  className={css.Owners__inheritedDescription}
                  label={infoText}
                  disabled
                />
                <Separator />
                <Option
                  label={<div className={css.Owners__email}>{linkText}</div>}
                  onClick={() => {
                    if (onViewClick) {
                      onViewClick(option);
                    } else {
                      window.open(option.referenceUrl, '_blank');
                    }
                  }}
                />
                {!readOnly && removeReference &&
                  <ConfirmDeleteOption
                    variant="remove"
                    onClick={() => removeReference(option.referenceId)}
                  />
                }
              </Fragment>
            }
          >
            <button
              type="button"
              className={css.Holder__externalReference}
            >
              <img
                alt={logoAlt}
                className={css.Holder__externalReference__icon}
                src={logoUrl || getIntegrationFileUrl('icon', integrationKey, isDarkMode ? ['dark'] : undefined)}
              />
              <div className={css.Holder__externalReference__label}>
                {option.referenceLabel}
              </div>
            </button>
          </PopupMenu>
        );
      })}
    </div>
  );
};

export default IntegrationReferences;
