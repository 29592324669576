/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import ToggleView from './ToggleView';
import StaticTab from './StaticTab';
import SearchTab from './SearchTab';
import LinkTab from './LinkTab';
import NavigationPanel from './NavigationPanel';

export {
  ToggleView,
  StaticTab,
  SearchTab,
  LinkTab,
  NavigationPanel,
};
