/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import classname from 'classname';
import Translation from './Translation';
import Text from '../Comment/Text';
import styles from './ShowMoreText';

class ShowMoreText extends Component {
  state = {
    showLess: false,
  };

  componentDidMount() {
    this.setText();
  }

  setText() {
    const height = this.textPara ? this.textPara.getClientRects()[0].height : 0;
    this.setState({
      showLess: height > 60,
    });
  }

  showMore() {
    this.setState({
      showLess: false,
    });
  }

  render() {
    const {
      text,
      onDbClick,
    } = this.props;

    const {showLess} = this.state;

    return (
      <div className={styles.ShowMoreText} onDoubleClick={onDbClick}>
        <div
          ref={ref => (this.textPara = ref)}
          className={classname({[styles.ShowMoreText__less]: showLess})}
        >
          <Text value={text} />
        </div>
        {showLess &&
          <button
            className={styles.ShowMoreText__button}
            onClick={() => this.showMore()}
          >
            <Translation value="button.show-more" />
          </button>
        }
      </div>
    );
  }
}

export default ShowMoreText;
