/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, Fragment } from 'react';
import { Button } from '../../Button';
import { addLocalFile } from '../utils/addFile';
import css from './CombineFilesModal.scss';
import Translation from '../../Text/Translation';
import { Ellipsis } from '../../Text';

// $files is used here to prevent angular from deeply checking object state changes
const CombineFilesModal = ({ $files: files, initialize, parameters, onComplete, onCancel, canAddMultiple }) => {
  const [isCombiningFiles, setIsCombiningFiles] = useState(false);

  const createAsSingleFile = () => {
    setIsCombiningFiles(true);
    window.__pageproof_bridge__.zipService.prepare()
      .then(() => {
        const filesByNameObject = files.reduce((acc, file) => {
          acc[file.name] = file;
          return acc;
        }, {});

        window.__pageproof_bridge__.zipService.compress(filesByNameObject)
          .then((compressedFiles) => {
            addLocalFile(compressedFiles, 'Combined.zip', initialize, parameters, 'zip-Static');
            onComplete(1);
          });
      });
  };

  const createAsIndividualProofs = () => {
    const successCount = files.filter(file => addLocalFile(file, file.name, initialize, parameters)).length;

    onComplete(successCount);
  };

  return (
    <div>
      <h1 className={css.CombineFilesModal__heading}>
        <Translation value="proof.combine-files.title.combine" />
      </h1>
      <div>
        <Translation
          value={canAddMultiple
            ? 'proof.combine-files.body.combine-or-individual'
            : 'proof.combine-files.body.combine-or-select-one'
          }
          params={{ count: files.length }}
        />
      </div>

      <div className={css.CombineFilesModal__footer}>
        <Fragment>
          {canAddMultiple
            ? (
              <Button
                className={css.CombineFilesModal__footer__button}
                variant="text"
                label={<Translation value="proof.combine-files.button.individual" />}
                onClick={createAsIndividualProofs}
              />
            )
            : (
              <Button
                className={css.CombineFilesModal__footer__button}
                variant="text"
                label={<Translation value="proof.combine-files.button.select-one" />}
                onClick={onCancel}
              />
            )
          }
          <Button
            className={css.CombineFilesModal__footer__button}
            variant="primary"
            label={!isCombiningFiles
              ? <Translation value="proof.combine-files.button.combine" />
              : (
                <Fragment>
                  <Translation value="proof.combine-files.button.combining" />
                  <Ellipsis />
                </Fragment>
              )
            }
            onClick={createAsSingleFile}
            disabled={isCombiningFiles}
          />
        </Fragment>
      </div>
    </div>
  );
};

export default CombineFilesModal;
