/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable arrow-body-style */

export function drag({
  startEvent,
  targetElement,
  containerElement,
  cursor = 'grabbing',
  onMove,
  onEnd,
}) {
  const overlayRoot = document.fullscreenElement || document.body;
  let overlayElement;

  const init = () => {
    if (overlayElement) {
      return;
    }

    overlayElement = document.createElement('div');
    Object.assign(overlayElement.style, {
      position: 'fixed',
      inset: 0,
      zIndex: 1000000000,
      cursor,
    });
    overlayRoot.appendChild(overlayElement);
  };

  const cleanup = () => {
    window.removeEventListener('pointermove', onPointerMove);
    window.removeEventListener('pointerup', onPointerUp);
    if (overlayElement) {
      overlayRoot.removeChild(overlayElement);
    }
  };

  const getPosition = (x, y, rect) => {
    return {
      x: {
        px: Math.min(rect.width, Math.max(0, x - rect.left)),
        percent: Math.min(1, Math.max(0, (x - rect.left) / rect.width)),
      },
      y: {
        px: Math.min(rect.height, Math.max(0, y - rect.top)),
        percent: Math.min(1, Math.max(0, (y - rect.top) / rect.height)),
      },
    };
  };

  const targetRect = targetElement.getBoundingClientRect();
  const startPosition = getPosition(startEvent.pageX, startEvent.pageY, targetRect);

  const onPointerMove = (moveEvent) => {
    init();

    const containerRect = containerElement.getBoundingClientRect();

    const xOffset = targetRect.width * startPosition.x.percent;
    const yOffset = targetRect.height * startPosition.y.percent;

    onMove({
      pointer: getPosition(moveEvent.pageX, moveEvent.pageY, containerRect),
      targetLeft: getPosition(moveEvent.pageX - xOffset, moveEvent.pageY - yOffset, containerRect),
      targetRight: getPosition(moveEvent.pageX - xOffset + targetRect.width, moveEvent.pageY - yOffset + targetRect.height, containerRect),
    });
  };

  const onPointerUp = () => {
    if (onEnd) {
      onEnd();
    }
    cleanup();
  };

  window.addEventListener('pointermove', onPointerMove);
  window.addEventListener('pointerup', onPointerUp);

  return cleanup;
}
