/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

function getLabel(value) {
  return window.generalfunctions_getCommentLabel(value);
}

export default getLabel;
