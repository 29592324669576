/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Fragment } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import ConfirmOption from './ConfirmOption';
import Translation from '../Text/Translation';
import css from './ConfirmDeleteOption.scss';
import { Ellipsis } from '../Text';

const variantIsDeletingLabelTextMap = {
  'remove.true': <Translation value="option.removing" />,
  'remove.false': <Translation value="option.remove" />,
  'delete.true': <Translation value="option.deleting" />,
  'delete.false': <Translation value="option.delete" />,
};

function ConfirmDeleteOption({
  variant = 'delete',
  onClick,
  isDeleting,
}) {
  return (
    <ConfirmOption
      disabled={isDeleting}
      label={(
        <Fragment>
          {variantIsDeletingLabelTextMap[`${variant}.${!!isDeleting}`]}
          {isDeleting && <Ellipsis />}
        </Fragment>
      )}
      className={css.ConfirmDeleteOption}
      confirmLabel={
        <Fragment>
          <InlineSVG
            src="img/content/proof/icons/delete.svg"
            className={css.ConfirmDeleteOption__icon}
          />
          <div className={css.ConfirmDeleteOption__message}>
            <Translation value="option.delete.confirm" />
          </div>
        </Fragment>
      }
      onClick={onClick}
    />
  );
}

export default ConfirmDeleteOption;
