/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { } from 'react';
import classname from 'classname';
import css from './Message.scss';
import Translation from '../../Text/Translation';

const Message = ({
  translation,
  translations,
  minimal,
  params,
  children,
}) => {
  const lines = [].concat(
    translation
      ? [
        `${translation}.title`,
        `${translation}.body`,
      ]
      : translations
  ).map((key, index) => {
    const translated = <Translation
      value={key}
      params={params}
    />;
    if (index === 0) {
      return <div key={key}><strong>{translated}</strong></div>;
    } else {
      return <div key={key}>{translated}</div>;
    }
  });
  return (
    <div
      className={classname(
        css.Message,
        minimal && css['Message--minimal'],
      )}
    >
      {(translation || translations)
        ? lines
        : children
      }
    </div>
  );
};

export default Message;
