/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Translation} from '../../components/Text';

function LogoBrandingPreview({url}) {
  return (
    <div className="LogoBrandingPreview">
      <img
        alt={Translation.text('domain-admin.menu.branding.preview.banner.alt')}
        className="LogoBrandingPreview__image"
        src={url}
      />
      <img
        alt={Translation.text('domain-admin.menu.branding.preview.proofing.alt')}
        className="LogoBrandingPreview__background"
        src="/img/interface/teams/branding/proof-page-with-logo_2x.png"
      />
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  LogoBrandingPreview.propTypes = {
    url: PropTypes.string.isRequired,
  };
}

export default LogoBrandingPreview;
