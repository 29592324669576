/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Translation } from '../components/Text';

const devices = [
  {
    icon: 'desktop',
    id: 'desktop',
    name: <Translation value="device.desktop" />,
    width: 1920,
    height: 1080,
    rotate: false,
    category: 'desktop',
  },
  {
    icon: 'apple-laptop',
    id: 'laptop-hidpi',
    name: <Translation value="device.laptop" />,
    width: 1440,
    height: 900,
    rotate: false,
    category: 'desktop',
  },
  {
    icon: 'apple-tablet',
    id: 'ipad-large',
    name: <Translation value="device.ipad-large" />,
    width: 1024,
    height: 1366,
    rotate: true,
    category: 'tablet',
  },
  {
    icon: 'apple-tablet',
    id: 'ipad',
    name: <Translation value="device.ipad" />,
    width: 768,
    height: 1024,
    rotate: true,
    category: 'tablet',
  },
  {
    icon: 'apple-phone',
    id: 'iphone-large',
    name: <Translation value="device.iphone-large" />,
    width: 414,
    height: 736,
    rotate: true,
    category: 'mobile',
  },
  {
    icon: 'apple-phone',
    id: 'iphone',
    name: <Translation value="device.iphone" />,
    width: 375,
    height: 667,
    rotate: true,
    category: 'mobile',
  },
  {
    icon: 'apple-phone',
    id: 'iphone-small',
    name: <Translation value="device.iphone-small" />,
    width: 320,
    height: 568,
    rotate: true,
    category: 'mobile',
  },
  {
    icon: 'android-phone',
    id: 'android-phone-large',
    name: <Translation value="device.android-phone-large" />,
    width: 412,
    height: 732,
    rotate: true,
    category: 'mobile',
  },
  {
    icon: 'android-phone',
    id: 'android-phone-small',
    name: <Translation value="device.android-phone-small" />,
    width: 360,
    height: 640,
    rotate: true,
    category: 'mobile',
  },
];

export default devices;
