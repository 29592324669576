/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Form from '../Form';
import { Translation } from '../Text';
import { Tabs, Tab } from '../Tabs';
import UserProfileAvatarContainer from '../../containers/UserProfileAvatarContainer';
import css from './UserProfile.scss';
import FormatDate from '../Date/FormatDate';

const UserProfile = ({ userId, lastLogin, profileTabs, currentTab, switchTab }) => (
  <div>
    <div className={css.UserProfile__userDetails}>
      <UserProfileAvatarContainer userId={userId} />
      <p className={css.UserProfile__userDetails__lastLogin}>
        <Translation
          value="profile.last-active"
          params={{
            lastLoginDate: (
              <FormatDate
                date={lastLogin}
                formatTo="h:mma, Do MMMM YYYY"
              />
            ),
          }}
        />
      </p>
    </div>
    <Tabs selected={currentTab}>
      {Object.keys(profileTabs).map(tab => (
        <Tab
          key={tab}
          id={tab}
          name={profileTabs[tab].label}
          onClick={() => switchTab(tab)}
        >
          <div className={css.UserProfile__tab}>
            <Form>
              {profileTabs[tab].component}
            </Form>
          </div>
        </Tab>
      ))}
    </Tabs>
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  UserProfile.propTypes = {
    userId: PropTypes.string.isRequired,
    lastLogin: PropTypes.instanceOf(Date).isRequired,
    profileTabs: PropTypes.objectOf(
      PropTypes.shape({
        component: PropTypes.node.isRequired,
        label: PropTypes.node.isRequired,
      })
    ),
    currentTab: PropTypes.string.isRequired,
    switchTab: PropTypes.func.isRequired,
  };
}

export default UserProfile;
