/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import classname from 'classname';
import React, { Fragment } from 'react';
import InputOptions from '../InputOptions';
import css from './IntegrationReferenceSelector.scss';

const MINIMUM_ID_SEARCH_LENGTH = 3;

function SelectorSection({ breadcrumb, options, searchTerm, updateSearchTerm, selectOption, selectedOptions, hasSearchableBreadcrumbs, showOptionsUnderBreadcrumbsIndented }) {
  const loweredSearchTerm = searchTerm.toLowerCase();
  const filteredOptions = breadcrumb && breadcrumb.some(crumb => crumb.toLowerCase().includes(loweredSearchTerm))
    ? options
    : options
      .filter(option => option.referenceLabel.toLowerCase().includes(loweredSearchTerm) ||
        (loweredSearchTerm.length >= MINIMUM_ID_SEARCH_LENGTH && option.referenceId.toLowerCase().includes(loweredSearchTerm)));

  if (!filteredOptions.length) {
    return null;
  }

  const optionOptions = filteredOptions.map((option) => {
    const selected = selectedOptions.some(so => so.referenceId === option.referenceId);

    return (
      <InputOptions.Option
        key={option.referenceId}
        checked={selected}
        disabled={selected}
        label={
          loweredSearchTerm.length >= MINIMUM_ID_SEARCH_LENGTH && option.referenceId.toLowerCase().includes(loweredSearchTerm)
            ? (
              <Fragment>
                {option.referenceLabel}
                <span className={css.Reference}>
                  {`(${option.referenceId})`}
                </span>
              </Fragment>
            )
            : option.referenceLabel
        }
        onClick={() => {
          selectOption(option);
        }}
      />
    );
  });

  if (!breadcrumb || !breadcrumb.length) {
    return optionOptions;
  }

  return (
    <Fragment>
      <div className={css.Section__header}>
        {breadcrumb.map(crumb => (
          hasSearchableBreadcrumbs
            ? (
              <button
                key={`crumb-${crumb}`}
                type="button"
                className={classname(css.Section__header__crumb, { [css['Section__header__crumb--searchable']]: hasSearchableBreadcrumbs })}
                onClick={() => updateSearchTerm(crumb)}
              >
                {crumb}
              </button>
            )
            : (
              <div
                key={`crumb-${crumb}`}
                className={css.Section__header__crumb}
              >
                {crumb}
              </div>
            )
        ))
          .reduce((arr, b) => [...arr, '   >   ', b], [])
          .slice(1)
        }
      </div>
      <div className={classname({ [css['Section__options--indented']]: showOptionsUnderBreadcrumbsIndented })}>
        {optionOptions}
      </div>
    </Fragment>
  );
}

export default SelectorSection;
