/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'AgreeOutline',
  src: 'img/icons/agree-outline.svg',
  activeColor: '#f6a500',
  activeHoverColor: '#c38300',
});
