/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import PageProof from '@pageproof/sdk';
import XHRRequestAdapter from '@pageproof/sdk/lib/xhr-request-adapter';
import WorkerCryptoAdapter from '@pageproof/sdk/lib/worker-crypto-adapter';
import AngularEnhancementAdapter from '@pageproof/sdk/lib/angular-enhancement-adapter';
import IndexedDBCacheAdapter from '@pageproof/sdk/lib/indexeddb-cache-adapter';
import ProtobufAdapter from '@pageproof/sdk/lib/protobuf-adapter';
import env from './env';
import {getSavedSession} from './session';

function createClient(restoreSession = false) {
  const [endpoint] = String(env.api_url).split('/api/');
  const applicationId = env.application_id || undefined;
  const client = new PageProof({
    adapters: [
      new XHRRequestAdapter({
        endpoint,
        applicationId,
      }),
      new WorkerCryptoAdapter({
        url: '/dist/lib',
        limit: window.navigator.hardwareConcurrency || 2,
      }),
      window.angular
        ? new AngularEnhancementAdapter({
          module: window.angular.module('createProof'), // This is the only angular app we have
        })
        : null,
      new IndexedDBCacheAdapter(),
      new ProtobufAdapter(),
    ].filter(Boolean),
  });
  if (restoreSession) {
    client.setSession(getSavedSession());
  }
  return client;
}

const createOrUsedCachedClient = (() => {
  let client = null;
  return (restoreSession = false) => {
    if (!client) {
      client = createClient();
    }
    if (restoreSession && !client.session) {
      client.setSession(getSavedSession());
    }
    return client;
  };
})();

export {
  createClient,
  createOrUsedCachedClient,
};
