/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */

import classname from 'classname';
import React, { useState, Fragment } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import css from './AudioBookmarkButton.scss';
import Tooltip from '../Tooltip/Tooltip';
import videoTime from '../../util/video-time';
import { PopupMenu, Option } from '../PopupMenu';
import { Translation } from '../Text';


const AudioBookmarkButton = ({ getTimeStamp, isProofBookmarked, bookMarkTime, removeBookmarkTime, scrubToTime }) => {
  const [isPopupMenuOpened, setIsPopupMenuOpened] = useState(false);

  return isProofBookmarked ? (
    <PopupMenu
      up
      onShow={() => setIsPopupMenuOpened(true)}
      onHide={() => setIsPopupMenuOpened(false)}
      options={() => (
        <Fragment>
          <Option
            label={<Translation
              value="proof.utilities.bookmark.tooltip"
            />}
            disabled
          />
          <Option
            label={
              <div className={css.labelIcon}>
                <Translation value="proof.utilities.bookmark.go-to-bookmark" />
                <InlineSVG
                  src="img/icons/material/bookmark_add.svg"
                  className={css.labelIcon__activeIcon}
                />
              </div>
            }
            onClick={() => scrubToTime()}
          />
          <Option
            label={
              <div className={css.labelIcon}>
                <Translation value="proof.utilities.bookmark.unset" />
                <InlineSVG
                  src="img/icons/material/bookmark_add.svg"
                  className={css.labelIcon__unsetIcon}
                />
              </div>
            }
            onClick={() => removeBookmarkTime()}
          />
        </Fragment>
      )}
      offset={10}
    >
      <Bookmark
        showTooltip={!isPopupMenuOpened}
        isProofBookmarked={isProofBookmarked}
        onClick={getTimeStamp}
        bookMarkTime={bookMarkTime}
      />
    </PopupMenu>
  ) : (
    <Bookmark
      showTooltip={!isPopupMenuOpened}
      isProofBookmarked={isProofBookmarked}
      onClick={getTimeStamp}
      bookMarkTime={bookMarkTime}
    />
  );
};

const Bookmark = ({ onClick, isProofBookmarked, bookMarkTime }) => (
  <div style={{ marginLeft: '23px' }}>
    <Tooltip
      up
      center
      title={<Translation
        value="proof.utilities.bookmark.tooltip"
      />}
      maxWidth={150}
      disabled={isProofBookmarked}
    >
      <button
        className={classname(css.AudioBookmarkButton_icon, {
          [css.AudioBookmarkButton_icon_active]: isProofBookmarked,
        })}
        onClick={onClick}
        aria-label="Bookmark"
      >
        <InlineSVG src="img/icons/material/bookmark_add.svg" />
        <div className={css.timeStamp}>{bookMarkTime || bookMarkTime === 0 ? videoTime(bookMarkTime) : ''}</div>
      </button>
    </Tooltip>
  </div>
);

export default AudioBookmarkButton;

export {
  Bookmark,
};
