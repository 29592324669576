/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import FileThumbnail from '../../../containers/FileThumbnail';
import Reveal from '../../Reveal';
import css from './AttachmentThumbnail.scss';

class AttachmentThumbnail extends Component {
  state = {
    loaded: false,
  };

  render() {
    return (
      <div style={{width: 150}}>
        <Reveal
          align="top"
          visible={this.state.loaded}
          preRender
        >
          <div className={css.AttachmentThumbnail}>
            <FileThumbnail
              {...this.props}
              width={150}
              height={150}
              delay={300}
              onLoad={() => this.setState({loaded: true})}
            />
          </div>
        </Reveal>
      </div>
    );
  }
}

export default AttachmentThumbnail;
