/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import PropTypes from 'prop-types';
import React from 'react';
import WorkflowTemplateDownload from '../../components/WorkflowTemplateDownload';
import { Translation, withTranslations } from '../../components/Text';
import { makeUnstableBackendRequest } from '../../util/unstable-backend-request';

const getStepRole = (row) => {
  // Handle workflow templates that have no steps
  if (!row.position) {
    return '';
  }

  if (row.position === 1000) {
    return Translation.text('workflow.role.approver');
  }

  if (row.isViewOnlyReviewer) {
    return Translation.text('workflow.role.view-only');
  }

  if (row.mandatory) {
    if (row.approver) {
      return Translation.text('workflow.role.gatekeeper');
    }
    return Translation.text('workflow.role.mandatory');
  }

  return Translation.text('workflow.role.reviewer');
};

const getStepName = (row, stepNumber) => {
  // Handle workflow templates that have no steps
  if (!row.position) {
    return '';
  }

  if (row.position === 1000) {
    return (!row.stepName || !row.stepName.trim() || row.stepName === 'primary_email') ? Translation.text('workflow.final-step') : row.stepName;
  }

  if (!row.stepName || row.stepName.trim() === '') {
    return Translation.text('workflow.step-position', { position: stepNumber });
  }

  return row.stepName;
};

const getMandatoryDecisionThreshold = (mandatoryDecisionThreshold, mandatoryUserCount) => {
  // Don't show a value when it isn't applicable (there are none, or only one mandatory user)
  if (mandatoryUserCount < 2) {
    return '';
  }

  // Show the number of mandatory users if the threshold is set to 'all'
  if (mandatoryDecisionThreshold === '' || mandatoryDecisionThreshold === '0') {
    return mandatoryUserCount.toString();
  }

  // Otherwise show the value that has been set in the database
  return mandatoryDecisionThreshold;
};

const dataToReportRow = (row, stepNumber, mandatoryUserCount) => ({
  [Translation.text('workflow.template.export.column.template-title')]: row.title,
  [Translation.text('workflow.template.export.column.created-by')]: row.createdBy,
  [Translation.text('workflow.template.export.column.proof-owners')]: row.proofOwners.join(', '),
  [Translation.text('workflow.template.export.column.step-name')]: getStepName(row, stepNumber),
  [Translation.text('workflow.template.export.column.reviewer')]: row.reviewer,
  [Translation.text('workflow.template.export.column.role')]: getStepRole(row),
  [Translation.text('workflow.template.export.column.mandatory-decision-threshold')]: getMandatoryDecisionThreshold(row.mandatoryDecisionThreshold, mandatoryUserCount),
  [Translation.text('workflow.template.export.column.inviter')]: row.inviter
    ? Translation.text('workflow.template.export.column.inviter-yes')
    : Translation.text('workflow.template.export.column.inviter-no'),
  [Translation.text('workflow.template.export.column.shared')]: row.shared
    ? Translation.text('workflow.template.export.column.shared-yes')
    : Translation.text('workflow.template.export.column.shared-no'),
  [Translation.text('workflow.template.export.column.shared-with')]: row.workflowShares.join(', '),
  [Translation.text('workflow.template.export.column.can-edit')]: row.workflowEditors.join(', '),
});

const modeOptions = {
  dashboard: {
    filenameKey: 'workflow.template.export.filename.owned',
    path: '/api/workflows/templates/export/owned',
  },
  'dashboard-shared': {
    filenameKey: 'workflow.template.export.filename.shared',
    path: '/api/workflows/templates/export/shared',
  },
  'team-workflows': {
    filenameKey: 'workflow.template.export.filename.team',
    path: '/api/admin/workflows/templates/export',
  },
};

const WorkflowTemplateDownloadContainer = ({ mode, filteredWorkflowIds: templateIds, searchQuery }) => {
  const onDownload = async () => {
    const { filenameKey, path } = modeOptions[mode];
    const data = await makeUnstableBackendRequest({
      method: 'POST',
      path,
      body: { templateIds: searchQuery ? templateIds : undefined },
    });

    const positionToStepNumber = data
      .sort((a, b) => a.position - b.position)
      .reduce((acc, row) => {
        if (!acc[row.workflowId]) {
          acc[row.workflowId] = {};
        }
        if (!acc[row.workflowId][row.position]) {
          acc[row.workflowId][row.position] = {
            stepNumber: Object.keys(acc[row.workflowId]).length + 1,
            mandatoryUserCount: 0,
          };
        }
        if (row.mandatory) {
          acc[row.workflowId][row.position].mandatoryUserCount += 1;
        }

        return acc;
      }, {});

    const workflowTemplates = data
      .sort((a, b) => a.position - b.position)
      .sort((a, b) => (a.workflowId < b.workflowId ? -1 : 1))
      .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
      .map((row) => {
        const { stepNumber, mandatoryUserCount } = positionToStepNumber[row.workflowId][row.position];
        return dataToReportRow(row, stepNumber, mandatoryUserCount);
      });
    const csvFile = window.generalfunctions_csv(workflowTemplates);

    window.downloadFile(`${Translation.text(filenameKey)}.csv`, new window.Blob([csvFile]));
  };

  return <WorkflowTemplateDownload onDownload={onDownload} />;
};

if (process.env.NODE_ENV !== 'production') {
  WorkflowTemplateDownloadContainer.propTypes = {
    searchQuery: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['team-workflows', 'dashboard', 'dashboard-shared']).isRequired,
    filteredWorkflowIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  };
}

export default withTranslations(WorkflowTemplateDownloadContainer);
