/* eslint-disable indent */

import React, { useState, useEffect, useMemo } from 'react';
import { approximateCommentHeight, approximatePageHeight } from './utils/approximateHeights';
import { getVirtualizedData } from './utils/getVirtualizedData';
import { useAngularWatcher } from './hooks/useAngularWatcher';
import VirtualList from './components/VirtualList';
import SpacingWrapper from './components/SpacingWrapper';
import { getProofHelper } from './utils/getProofHelper';
import CommentSpacing from '../../components/Comment/CommentSpacing';

export default function CommentsPane({ getProofCtrl, virtualListRef, deferredScroll, setDeferredScroll }) {
  const [proofCtrl] = useState(getProofCtrl);

  useAngularWatcher(proofCtrl.$$.$scope, () => [Math.random]);

  const { items } = getVirtualizedData(proofCtrl);

  useEffect(() => {
    if (deferredScroll !== null) {
      const { key, align } = deferredScroll;
      if (key) {
        virtualListRef.current.scrollToKey(key, align);
      } else {
        virtualListRef.current.scrollToTop();
      }
      setDeferredScroll(null);
    }
  }, [deferredScroll]);

  // Yes, this useEffect is not meant to have any dependencies...
  // After each render, update the "priority comment ids" on the proof controller, so any pending decryption tasks can
  // prioritize the comments that are currently visible to the user.
  useEffect(() => {
    // Get all the comment items in the virtual list that are currently mounted.
    const commentItems = virtualListRef.current.getVirtualItems()
      .filter(item => item.type === 'comment');

    // Flatten the comment items into a list of parent comment IDs and the IDs of all replies to those comments.
    const commentIds = commentItems.flatMap(item => [
      item.props.comment.id,
      ...item.props.comment.replies.map(reply => reply.id),
    ]);

    // Shuffle the IDs into a new array where the center-most IDs are at the beginning of the array.
    const centerPriorityCommentIds = [];
    while (commentIds.length) {
      const centerIndex = Math.floor(commentIds.length / 2);
      centerPriorityCommentIds.push(commentIds[centerIndex]);
      commentIds.splice(centerIndex, 1);
    }

    // Assign the "priority comment ids" on the proof controller, so any pending decryption can prioritize these.
    proofCtrl.priorityCommentIds = centerPriorityCommentIds;
  });

  const proofHelper = useMemo(() => getProofHelper(proofCtrl), [proofCtrl.permissions]);

  return (
    <SpacingWrapper commentPaneWidth={proofCtrl.commentPaneWidth}>
      <CommentSpacing.Consumer>
        {spacing => (
          <VirtualList
            ref={virtualListRef}
            style={{
              position: 'absolute',
              top: proofCtrl.canShowCommentHeading() ? 0 : 20,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'auto',
            }}
            items={items}
            defaultProps={{
              proofCtrl,
              proofHelper,
              spacing,
            }}
            defaultSize={(item) => {
              switch (item.type) {
                case 'spacer': return window.innerHeight / 2;
                case 'page': return approximatePageHeight(item.props.comments);
                case 'comment': return approximateCommentHeight(item.props.comment);
                default: return 1;
              }
            }}
            alwaysMountedItemKeys={[
              'new', // Always mount the "new comment" item, so it can be scrolled to even if it's not visible initially.
            ]}
          />
        )}
      </CommentSpacing.Consumer>
    </SpacingWrapper>
  );
}
