import React, { useState, useMemo } from 'react';

const Favicon = ({ url, fallback, ...props }) => {
  const [showFallback, setShowFallback] = useState(false);
  const faviconUrl = useMemo(() => (
    'https://favicons-via.pageproof.com/' +
    new URL(url).hostname
  ), [url]);

  if (showFallback) {
    return fallback;
  }

  return (
    <img
      alt={`${url} Favicon`}
      {...props}
      src={faviconUrl}
      onError={() => {
        setShowFallback(true);
      }}
    />
  );
};

export default Favicon;
