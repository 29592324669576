/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable consistent-return */

export function create() {
  let canvases = {};
  let isDestroyed = false;

  function checkIsNotDestroyed() {
    if (isDestroyed) {
      throw new Error('Coverage destroyed, aborting.');
    }
  }

  function loadImage(url) {
    return window.fetch(url)
      .then((response) => {
        checkIsNotDestroyed();
        return response.blob();
      })
      .then((blob) => {
        checkIsNotDestroyed();
        return window.createImageBitmap(blob);
      })
      .then((image) => {
        checkIsNotDestroyed();

        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d', {
          willReadFrequently: true,
        });
        ctx.drawImage(image, 0, 0);

        return {
          canvas,
          ctx,
          width: image.width,
          height: image.height,
        };
      });
  }

  const ops = {
    loadPlate(plateName, url) {
      checkIsNotDestroyed();
      if (canvases[plateName]) {
        return Promise.resolve();
      }
      const canvasPromise = loadImage(url);
      canvases[plateName] = canvasPromise;
      return canvasPromise.then((canvas) => {
        canvases[plateName] = canvas;
      });
    },
    getCoverage(plateNames, top, left) {
      checkIsNotDestroyed();
      let total = 0;
      const plates = {};
      plateNames.forEach((plateName) => {
        const canvasPromiseOrCanvas = canvases[plateName];
        if (
          canvasPromiseOrCanvas &&
          typeof canvasPromiseOrCanvas.then !== 'function'
        ) {
          const { ctx, width, height } = canvasPromiseOrCanvas;
          const x = Math.round(left * (width - 1));
          const y = Math.round(top * (height - 1));
          const imageData = ctx.getImageData(x, y, 1, 1);
          const value = 1 - (imageData.data[0] / 255);
          const percent = Math.round(value * 100);
          plates[plateName] = percent;
          if (total !== null) {
            total += percent;
          }
        } else {
          plates[plateName] = null;
          total = null;
        }
      });
      return Promise.resolve({
        plates,
        total,
      });
    },
  };

  function destroy() {
    canvases = null;
    isDestroyed = true;
  }

  return {
    destroy,
    ops,
  };
}
