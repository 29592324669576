/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {MultiEmailForm} from '../../components/MultiEmailForm';
import {Translation} from '../../components/Text';
import {Button} from '../../components/Button';
import {unique} from '../../util/object-array-utils';
import {sdk} from '../../util/sdk';

class DomainAdminInviteContainer extends Component {
  state = {
    emails: [],
    hasSubmitted: false,
    type: '',
    isMulti: false,
    invitedEmail: null,
    invitedEmails: [],
    userInOtherTeamEmails: [],
    userInTeamEmails: [],
  }

  onValidateDomain = email => window.validateEmail(email)

  updateEmails = (value) => {
    // remove empty strings
    const emails = value.filter(string => string);
    // remove duplicate emails
    const uniqueEmails = unique(emails);
    this.setState({
      emails: uniqueEmails,
    });
  }

  transformResponse = (data) => {
    const result = {
      invitedEmail: '',
      invitedEmails: [],
      userInTeamEmails: [],
      userInOtherTeamEmails: [],
    };
    result.isMulti = data.length > 1;
    data.map((item) => {
      result.invitedEmail = item.Email;
      switch (item.Message) {
      case 'ERROR_EXISTING_USER_TEAM_EXISTED':
      case 'ERROR_BRAND_NEW_USER_DOMAIN_NOT_MATCH':
        result.type = 'user-in-a-team';
        result.userInOtherTeamEmails.push(item.Email);
        break;
      case 'ERROR_USER_ALREADY_IN_SAME_TEAM':
        result.type = 'user-in-your-team';
        result.userInTeamEmails.push(item.Email);
        break;
      case 'BRAND_NEW_USER_INVITED':
      case 'EXISTING_USER_INVITED':
      case 'BRAND_NEW_USER_ADDED':
      default:
        result.invitedEmails.push(item.Email);
        result.type = 'user-invited';
      }
      return result;
    });
    return result;
  }

  submitEmails = () => {
    const {emails} = this.state;
    sdk.admin()
      .then(admin => admin.team.users.invite(emails))
      .then((response) => {
        const {
          type,
          isMulti,
          invitedEmail,
          invitedEmails,
          userInOtherTeamEmails,
          userInTeamEmails
        } = this.transformResponse(response.data);
        this.setState({
          hasSubmitted: true,
          type,
          isMulti,
          invitedEmail,
          invitedEmails,
          userInOtherTeamEmails,
          userInTeamEmails,
        });
      });
  }

  render() {
    const {
      emails,
      hasSubmitted,
      type,
      isMulti,
      invitedEmail,
      invitedEmails,
      userInOtherTeamEmails,
      userInTeamEmails,
    } = this.state;
    const {onSelectTab} = this.props;
    let multiHeader = 'mix';
    if (invitedEmails.length && !userInOtherTeamEmails.length && !userInTeamEmails.length) {
      multiHeader = 'user-invited';
    } else if (!invitedEmails.length && userInOtherTeamEmails.length && !userInTeamEmails.length) {
      multiHeader = 'user-in-a-team';
    } else if (!invitedEmails.length && !userInOtherTeamEmails.length && userInTeamEmails.length) {
      multiHeader = 'user-in-your-team';
    }
    return (
      <div className="DomainAdminInviteTeam">
        {hasSubmitted
          ? (
            <div className="DomainAdminInviteTeam__sent">
              <h1>
                {isMulti
                  ? <Translation value={`team-dashboard.invite-sent.heading.${multiHeader}.multi`} />
                  : <Translation value={`team-dashboard.invite-sent.heading.${type}`} />
                }
              </h1>
              <div className="DomainAdminInviteTeam__sent__description">
                {isMulti
                  ? (
                    <Fragment>
                      {userInOtherTeamEmails.length > 0 &&
                        <div style={{marginBottom: 20}}>
                          <Translation
                            value={`team-dashboard.invite-sent.message.user-in-a-team${userInOtherTeamEmails.length > 1 ? '.multi' : ''}`}
                            params={{
                              emails: <b>{userInOtherTeamEmails.slice(0, -1).join(', ')}</b>,
                              email: <b>{userInOtherTeamEmails[0]}</b>,
                              lastEmail: <b>{userInOtherTeamEmails[userInOtherTeamEmails.length - 1]}</b>,
                            }}
                          />
                        </div>
                      }
                      {userInTeamEmails.length > 0 &&
                        <div style={{marginBottom: 20}}>
                          <Translation
                            value={`team-dashboard.invite-sent.message.user-in-your-team${userInTeamEmails.length > 1 ? '.multi' : ''}`}
                            params={{
                              emails: <b>{userInTeamEmails.slice(0, -1).join(', ')}</b>,
                              email: <b>{userInTeamEmails[0]}</b>,
                              lastEmail: <b>{userInTeamEmails[userInTeamEmails.length - 1]}</b>,
                            }}
                          />
                        </div>
                      }
                      {invitedEmails.length > 0 &&
                        <div>
                          <Translation
                            value={`team-dashboard.invite-sent.message.user-invited${invitedEmails.length > 1 ? '.multi' : ''}`}
                            params={{
                              emails: <b>{invitedEmails.slice(0, -1).join(', ')}</b>,
                              email: <b>{invitedEmails[0]}</b>,
                              lastEmail: <b>{invitedEmails[invitedEmails.length - 1]}</b>,
                            }}
                          />
                        </div>
                      }
                    </Fragment>
                  )
                  : <Translation
                    value={`team-dashboard.invite-sent.message.${type}`}
                    params={{
                      email:
                    <b>
                      {invitedEmail}
                    </b>
                    }}
                  />
                }
              </div>
              <Button
                variant="primary"
                onClick={() => onSelectTab('active')}
                label={<Translation value="button.ok-got-it" />}
              />
            </div>
          )
          : (
            <div>
              <h1>
                <Translation value="team-dashboard.invite.header" />
              </h1>
              <MultiEmailForm
                emails={emails}
                updateEmails={this.updateEmails}
                onValidate={this.onValidateDomain}
                message="team-dashboard.invalid-email"
                onSubmit={this.submitEmails}
              />
            </div>
          )
        }
      </div>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  DomainAdminInviteContainer.propTypes = {
    // domain: PropTypes.string.isRequired,
    onSelectTab: PropTypes.func.isRequired,
  };
}

export default DomainAdminInviteContainer;
