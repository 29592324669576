/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const roundToNearestQuarter = (minutes) => {
  if (minutes % 15 <= 7) {
    return (minutes % 15) * -1;
  } else {
    return 15 - (minutes % 15);
  }
};

const parseDate = timeValue => (
  window.__pageproof_bridge__.dateService.parse(timeValue)
);

const parseDateRoundedToNearestQuarter = (timeValue) => {
  const parsedDate = parseDate(timeValue);
  const remainder = roundToNearestQuarter(parsedDate.minute()) || 0;
  return parsedDate.add('minutes', remainder).toDate();
};

const getFormattedDate = (timeValue) => {
  const date = window.moment(parseDateRoundedToNearestQuarter(timeValue));
  return date.format('h:mma, Do MMMM YYYY');
};

const formatDate = (dateObj) => {
  const date = window.moment(dateObj);
  return date.format('h:mma, Do MMMM YYYY');
};

const normaliseUTCToISO = (dateString) => {
  if (dateString && dateString.indexOf('Z') === -1) {
    return dateString + 'Z';
  }
  return dateString;
};

const getTwelveHourTime = (dueTime) => {
  const hours = window.moment(dueTime).hours();
  const twelveHourTime = hours <= 12 ? hours : hours - 12;
  return twelveHourTime > 9 ? window.moment(dueTime).format('hh:mmA') : window.moment(dueTime).format('h:mmA');
};

/**
 * Calculates if a lifetime of entity is higher than it supposed to be by taking the maximum
 * lifetime value in seconds and compare it with delta, produced from the current unix time in ms
 * and a timestamp of entity in ms.
 *
 * @param {Number} maxAgeInSeconds
 * @param {Number} wasCreatedInMilliseconds
 * @returns {Boolean}
 */
const calculateExpiration = (maxAgeInSeconds, wasCreatedInMilliseconds) => {
  const delta = Math.abs(new Date().getTime() - wasCreatedInMilliseconds) / 1000;
  return delta > maxAgeInSeconds;
};

export {
  calculateExpiration,
  getFormattedDate,
  parseDate,
  parseDateRoundedToNearestQuarter,
  formatDate,
  normaliseUTCToISO,
  getTwelveHourTime,
};
