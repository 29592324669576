/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';

function Spinner({
  size,
  color,
  style = {},
  center,
  ...props
}) {
  const scale = size / Spinner.defaultProps.size;
  return (
    <div
      {...props}
      className={classname('Spinner', {
        'Spinner--center': center,
      })}
      style={{
        width: size,
        height: size,
        ...style,
      }}
    >
      <div className="spinner__outer spinner--center">
        <div
          className="spinner"
          style={{
            transform: scale !== 1 ? `scale(${scale})` : '',
          }}
        >
          <div
            className="spinner__inner"
            style={{
              '--spinner-color': color,
            }}
          />
        </div>
      </div>
    </div>
  );
}

Spinner.defaultProps = {
  size: 60,
  center: false,
};

export default Spinner;
