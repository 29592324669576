/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import useUserPreferenceColor from '../../../hooks/useUserPreferenceColor';
import Popover from '../../Popover';
import CommentTooltipNavigation from '../CommentTooltipNavigation';
import GeneralCommentIcon from '../Icon/GeneralCommentIcon';
import css from './GeneralCommentIconButton';

const MAX_WIDTH = 300;

const GeneralCommentIconButton = ({
  commentMarkType,
  comments,
  isFlashing,
  targetOffset,
  selectComment,
}) => {
  const { normal: color, dark: hoverColor } = useUserPreferenceColor(commentMarkType);

  return (
    <Popover
      right
      down
      variant="yellow"
      maxWidth={MAX_WIDTH}
      offset={targetOffset}
      arrow
      content={
        <CommentTooltipNavigation
          comments={comments}
          selectComment={selectComment}
        />
      }
    >
      {popover => (
        <div
          className={classname(css.GeneralCommentIconButton, {
            [css['GeneralCommentIconButton--flash']]: isFlashing && !popover.isVisible,
          })}
        >
          {comments.length > 1 && (
            <div
              className={css.GeneralCommentIconButton__count}
              style={{
                '--countBackgroundColor': color,
                '--countBackgroundHoverColor': hoverColor,
              }}
            >
              {comments.length}
            </div>
          )}
          <GeneralCommentIcon
            active
            activeColor={color}
            activeHoverColor={hoverColor}
            size={28}
          />
        </div>
      )}
    </Popover>
  );
};

if (process.env.NODE_ENV !== 'production') {
  GeneralCommentIconButton.propTypes = {
    commentMarkType: PropTypes.string,
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        $selected: PropTypes.bool,
        id: PropTypes.string,
        ownerId: PropTypes.string,
        ownerEmail: PropTypes.string,
        comment: PropTypes.string,
        decryptedComment: PropTypes.string,
        isTodo: PropTypes.bool,
        isDone: PropTypes.bool,
      })
    ),
    isFlashing: PropTypes.bool,
    targetOffset: PropTypes.number,
    selectComment: PropTypes.func,
  };
}

export default GeneralCommentIconButton;
