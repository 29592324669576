/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const generateInitialTags = filename => (
  filename.replace(/(\.)/g, '')
    .replace(/([-_])/g, ' ')
    .replace(/\s+/g, ' ')
    .split(' ')
    .filter(tag => tag && tag.trim().length)
);

export default generateInitialTags;
