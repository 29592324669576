/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';

const HoverCard = ({
  content,
  children,
  ...props
}) => (
  <Tooltip
    {...props}
    hoverCard
    arrow
    title={content}
  >
    {children}
  </Tooltip>
);

HoverCard.defaultProps = {
  delay: 0,
  offset: 0,
};

const dynamicNode = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.node,
]);

HoverCard.propTypes = {
  content: dynamicNode.isRequired,
  children: dynamicNode.isRequired,
  top: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  bottom: PropTypes.bool,
  delay: PropTypes.number,
  offset: PropTypes.number,
  arrow: PropTypes.bool,
};

export default HoverCard;
