import React from 'react';
import css from './Link.scss';

function Link({
  url,
  children,
}) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={css.Link}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {children}
    </a>
  );
}

export default Link;
