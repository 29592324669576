/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import CommentTooltipContent from '../Comment/CommentTooltipContent';
import css from './CommentPinTooltip';

const HEADER_HEIGHT = 100;
const FOOTER_HEIGHT = 100;
const TARGET_OFFSET = 10;
const MAX_WIDTH = 300;
const MAX_TOOLTIP_OFFSET_HEIGHT = 118;

function CommentPinTooltip({
  commentData,
  box,
  hover,
}) {
  const boxTop = box.top - HEADER_HEIGHT;
  const targetPositionStyles = {
    top: boxTop,
    left: box.left,
    width: box.width,
    height: box.height,
  };
  const maxHeight = document.documentElement.clientHeight;
  const maxTooltipY = box.top + box.height + TARGET_OFFSET + MAX_TOOLTIP_OFFSET_HEIGHT + (box.surfaceTop ? box.surfaceTop : 0);
  const verticalDirectionIsUp = maxTooltipY > (maxHeight - FOOTER_HEIGHT);
  const importMetadata = commentData.sourceMetadata && commentData.sourceMetadata.pdfImport && {
    type: 'pdf',
    author: commentData.sourceMetadata.pdfImport.author,
  };
  return (
    <Tooltip
      title={() => (
        <CommentTooltipContent
          commentData={commentData}
          isPdfImport={importMetadata && importMetadata.type === 'pdf'}
          name={importMetadata && importMetadata.author}
        />
      )}
      right
      up={verticalDirectionIsUp}
      down={!verticalDirectionIsUp}
      disabled={window.__pageproof_bridge__.browserService.is('mobile')}
      variant={commentData.$selected ? 'yellow' : 'light'}
      maxWidth={MAX_WIDTH}
      arrow
      offset={TARGET_OFFSET}
      visible={hover}
    >
      <div
        className={css.CommentPinTooltip}
        style={targetPositionStyles}
      />
    </Tooltip>
  );
}

if (process.env.NODE_ENV !== 'production') {
  CommentPinTooltip.propTypes = {
    commentData: PropTypes.shape({
      ownerId: PropTypes.string,
      ownerEmail: PropTypes.string,
      comment: PropTypes.string,
      decryptedComment: PropTypes.string,
    }),
    box: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
      width: PropTypes.number,
    }),
    hover: PropTypes.bool,
  };
}

export default CommentPinTooltip;
