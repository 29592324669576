/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import ExportIcon from '../ExportIcon';
import css from './ProofDownloadPopover.scss';
import { Button } from '../Button';
import OptionGroup from '../OptionGroup';
import { Option } from '../PopupMenu';
import Tooltip from '../Tooltip';
import Cross from '../Icon/Cross';
import ProgressCircle from '../ProgressCircle';
import { onlyWhenTruncated } from '../Tooltip/Tooltip';
import { getDownloadButtonLabel } from '../../util/proof-download-utils';

const ProofDownloadPopover = ({
  status,
  downloadGroups,
  downloadCount,
  onSelectChange,
  onDownload,
  isExternalWeb,
  importUrl,
}) => {
  const [proof] = downloadGroups;
  return (
    <div className={css.ProofDownloadPopover}>
      {!proof.files.length &&
        <div className={css.ProofDownloadPopover__warning}>
          <Translation value={isExternalWeb ? 'proof.download.external-web' : 'proof.download.no-files'} />
          {isExternalWeb && (
            <div className={css.ProofDownloadPopover__warning__openUrl}>
              <Translation value="proof.download.external-web.open-url" />
              <ExportIcon
                active
                activeColor="#138b3b"
                activeHoverColor="#0d5e28"
                size={18}
                onClick={() => window.open(importUrl, '_blank')}
              />
            </div>
          )}
        </div>
      }
      {downloadGroups.map(({ files, heading, isCollapsable, allSelected }) => (
        !!files.length && (
          <OptionGroup
            key={heading}
            label={heading}
            isCollapsable={isCollapsable}
            tool={files.length > 1 && (
              <Button
                variant="unstyled"
                className={css.ProofDownloadPopover__tool}
                label={
                  <Translation
                    value={allSelected
                      ? 'proof.download.deselect-all'
                      : 'proof.download.select-all'
                    }
                  />
                }
                disabled={status}
                onClick={() => (
                  onSelectChange(files.reduce((selection, file) => {
                    selection[file.id] = !allSelected; // eslint-disable-line
                    return selection;
                  }, {}))
                )}
                autoWidth
              />
            )}
          >
            <div className={css.ProofDownloadPopover__group}>
              {files.map(file => (
                <Option
                  key={file.id}
                  className={css.ProofDownloadPopover__group__option}
                  variant="light"
                  label={
                    <Tooltip
                      middle
                      right
                      title={file.name}
                      onBeforeOpen={onlyWhenTruncated(`.${css.ProofDownloadPopover__group__option__label}`)}
                      offset={60}
                      delay={1000}
                    >
                      <div className={css.ProofDownloadPopover__group__option__label}>
                        {file.name}
                      </div>
                    </Tooltip>
                  }
                  onClick={() => {
                    if (!status) {
                      onSelectChange({ [file.id]: !file.selected });
                    }
                  }}
                  checked={file.selected}
                  checkedIcon={file.progress && (
                    file.progress > 0
                      ? (
                        <ProgressCircle
                          size={20}
                          width={3}
                          value={file.progress}
                        />
                      )
                      : (
                        <Cross
                          active
                          readOnly
                          activeColor="#e51c23"
                          size={20}
                        />
                      )
                  )}
                  disabled={!!status}
                />
              ))}
            </div>
          </OptionGroup>
        )
      ))}
      {!!downloadGroups.flatMap(({ files }) => files).length && (
        <Button
          variant="primary"
          label={getDownloadButtonLabel(status, downloadCount)}
          disabled={status || !downloadCount}
          className={css.ProofDownloadPopover__download}
          onClick={onDownload}
        />
      )}
    </div>
  );
};

export default ProofDownloadPopover;

if (process.env.NODE_ENV === 'development') {
  ProofDownloadPopover.propTypes = {
    status: PropTypes.oneOfType([
      PropTypes.oneOf([null]),
      PropTypes.string,
    ]),
    downloadGroups: PropTypes.arrayOf(PropTypes.shape({
      files: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        selected: PropTypes.bool,
        progress: PropTypes.oneOfType([
          PropTypes.oneOf([null]),
          PropTypes.number,
        ]),
      })).isRequired,
      heading: PropTypes.node.isRequired,
      isCollapsable: PropTypes.bool.isRequired,
      allSelected: PropTypes.bool.isRequired,
    })).isRequired,
    downloadCount: PropTypes.number.isRequired,
    onSelectChange: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    isExternalWeb: PropTypes.bool.isRequired,
    importUrl: PropTypes.string,
  };
}
