import React, { Fragment, useState } from 'react';
import { Button } from '../Button';
import { addLocalFile, addExternalFile } from '../ProofSetup/utils/addFile';
import css from './CombineExternalFilesModal.scss';
import Translation from '../Text/Translation';
import { Ellipsis } from '../Text';
import Close from '../Comment/Close';
import requestProofSetup from '../ProofSetup/utils/requestProofSetup';

const CombineExternalFilesModal = ({ files, importProvider, initialize, parameters, onComplete, onCancel, canAddMultiple }) => {
  const [isCombining, setIsCombining] = useState(false);

  const createProofSetup = (callback) => {
    requestProofSetup(
      {
        parameters,
        initialize,
      },
      () => {
        callback();
      }
    );
  };

  const donwloadAndCombineFiles = () => {
    setIsCombining(true);

    const fetchPromises = files.map(file => (
      window.fetch(file.url, {
        headers: file.meta && file.meta.accessToken
          ? { Authorization: 'Bearer ' + file.meta.accessToken }
          : {},
      })
        .then(response => response.blob())
        // eslint-disable-next-line no-undef
        .then(blob => new File([blob], file.name + '.' + file.extension, { type: blob.type }))));

    window.__pageproof_bridge__.zipService.prepare()
      .then(() => Promise.all(fetchPromises))
      .then((blobs) => {
        const filesByNameObject = blobs.reduce((acc, file) => {
          acc[file.name] = file;
          return acc;
        }, {});

        return window.__pageproof_bridge__.zipService.compress(filesByNameObject);
      })
      .then((compressedFiles) => {
        addLocalFile(compressedFiles, 'Combined.zip', initialize, parameters, 'zip-Static');
        setIsCombining(false);
        onComplete('zip-Static');
      });
  };

  const createAsIndividualProofs = () => {
    files.forEach((file) => {
      addExternalFile(
        importProvider,
        file.url,
        file.name + '.' + file.extension,
        file.size,
        (file.meta && file.meta.accessToken) || null,
        initialize,
        parameters
      );
    });

    onComplete();
  };

  return (
    <Fragment>
      <div className={css.CombineExternalFilesModal__closeButton}>
        <Close
          onClose={onCancel}
        />
      </div>
      <div>
        <h1 className={css.CombineExternalFilesModal__heading}>
          <Translation value="proof.combine-files.title.combine" />
        </h1>
        <div>
          <Translation
            value={canAddMultiple
              ? 'proof.combine-files.body.combine-or-individual'
              : 'proof.combine-files.body.combine-or-select-one'
            }
            params={{ count: files.length }}
          />
        </div>

        <div className={css.CombineExternalFilesModal__footer}>
          <Fragment>
            {canAddMultiple
              ? (
                <Button
                  className={css.CombineExternalFilesModal__footer__button}
                  variant="text"
                  label={<Translation value="proof.combine-files.button.individual" />}
                  onClick={() => createProofSetup(createAsIndividualProofs)}
                  disabled={isCombining}
                />
              )
              : (
                <Button
                  className={css.CombineExternalFilesModal__footer__button}
                  variant="text"
                  label={<Translation value="proof.combine-files.button.select-one" />}
                  onClick={onCancel}
                  disabled={isCombining}
                />
              )
            }
            <Button
              className={css.CombineExternalFilesModal__footer__button}
              variant="primary"
              label={!isCombining
                ? <Translation value="proof.combine-files.button.combine" />
                : (
                  <Fragment>
                    <Translation value="proof.combine-files.button.combining" />
                    <Ellipsis />
                  </Fragment>
                )
              }
              onClick={() => createProofSetup(donwloadAndCombineFiles)}
              disabled={isCombining}
            />
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

export default CombineExternalFilesModal;
