/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Prompt.scss';
import Checkbox from '../Checkbox';
import {Translation} from '../Text';

function Prompt({
  title,
  message,
  action,
  onClose,
  skipDialogCheckboxProps,
}) {
  const {selected, onChange} = skipDialogCheckboxProps;
  return (
    <div className={styles.Prompt}>
      <div className={styles.Prompt__text}>
        <div className={styles.Prompt__text__title}>
          {title}
        </div>
        {message}
        {skipDialogCheckboxProps &&
          <div className={styles.Prompt__skipbox}>
            <Checkbox
              selected={selected}
              onChange={onChange}
            />
            <span className={styles.Prompt__skipbox__text}>
              <Translation value="proof.dialog.message.dont-show" />
            </span>
          </div>
        }
      </div>
      {action &&
        <div className={styles.Prompt__action}>
          {action}
        </div>
      }
      {onClose &&
        <span
          className={styles.Prompt__close}
          onClick={onClose}
        />
      }
    </div>
  );
}

Prompt.defaultProps = {
  message: '',
};

if (process.env.NODE_ENV !== 'production') {
  Prompt.propTypes = {
    title: PropTypes.node.isRequired,
    message: PropTypes.node,
    action: PropTypes.node,
    onClose: PropTypes.func,
    skipDialogCheckboxProps: PropTypes.shape({
      selected: PropTypes.bool.isRequired,
      onChange: PropTypes.func.isRequired,
    }),
  };
}

export default Prompt;
