/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import { Button } from '../../components/Button';
import { ProofTile } from '../../components/InfoPane';
import { Translation } from '../../components/Text';
import Tooltip from '../../components/Tooltip';
import styles from './GroupProofsPopupStyles.scss';
import { HasFeature } from '../../components/FeatureFlags/HasFeature';
import ExitModeButton from '../../components/Button/ExitModeButton';
import CollectionProofDownloadContainer from '../CollectionProofDownload';
import CollectionProofDownloadButton from '../../components/CollectionProofDownloadButton';
import BulkGeneralCommentsContainer from '../BulkGeneralCommentsContainer';
import { openProofInfo } from '../Dashboard/ActionsInternal';

class GroupProofsPopupContainer extends Component {
  getOverlay = (proof) => {
    let overlay = 'yellow'; // status 10/30
    if ((!proof.status && proof.fileStatus !== 'ripping') || proof.status === 100) {
      overlay = 'grey';
    }
    if (proof.status === 50 || proof.status === 60 || proof.status === 70 || proof.fileStatus === 'ripping') {
      overlay = 'green';
    }
    return overlay;
  }

  sortProofs = (group) => {
    group.proofs.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      return 0;
    });
  }

  render() {
    const {
      group,
      onClose,
      onRedirection,
      onOpenCollectionManage,
      userId,
      footerOptions,
      currentProofId,
      collectionProofDownloadProps,
      bulkGeneralCommentsProps,
      showTools,
    } = this.props;

    this.sortProofs(group);

    const showCollectionDownload = showTools;
    const showManageCollection = showTools && group.canManageCollection;


    return (
      <div className={styles.GroupProofPopup}>
        <div className={styles.GroupProofPopup__sideTool}>
          <BulkGeneralCommentsContainer {...bulkGeneralCommentsProps} />
        </div>
        <div className={styles.GroupProofPopup__container}>
          {group.proofs.map(proof => (
            <div
              className={classname(styles.GroupProofPopup__proof, {
                [styles['GroupProofPopup__proof--current']]: proof.id === currentProofId,
              })}
              key={proof.id}
              onClick={() => {
                if (proof.id === currentProofId) {
                  onClose();
                }
              }}
            >
              <ProofTile
                proofData={proof}
                url={proof.thumbnail}
                userId={userId}
                footerOptions={footerOptions}
                from="collection"
                onOpenProofInfo={() => openProofInfo(proof, 'collection', userId)}
              />
            </div>
          ))}
        </div>
        <div className={styles.GroupProofPopup__footer}>
          <div className={styles.GroupProofPopup__footer__left}>
            <div className={styles.GroupProofPopup__footer__left__button}>
              <ExitModeButton
                className={styles.GroupProofPopup__closeButton}
                onExit={onClose}
                tooltip={<Translation value="button.exit-collection" />}
              />
            </div>
            { showCollectionDownload && !!collectionProofDownloadProps.collectionFiles.length && (
              <div className={styles.GroupProofPopup__footer__left__button}>
                <CollectionProofDownloadContainer {...collectionProofDownloadProps}>
                  <CollectionProofDownloadButton />
                </CollectionProofDownloadContainer>
              </div>
            )}
          </div>
          <HasFeature flag="dashboard">
            <Button
              variant="primary"
              label={<Translation value="button.view-collection" />}
              onClick={() => onRedirection('group')}
              aria-label="View collection"
            />
          </HasFeature>
          <div className={styles.GroupProofPopup__footer__right}>
            {(showManageCollection &&
              <Tooltip
                title={<Translation value="group.footer.options.settings" />}
                up
                center
              >
                <div
                  role="presentation"
                  onClick={onOpenCollectionManage}
                >
                  <InlineSVG src="img/interface/settings.svg" />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  GroupProofsPopupContainer.propTypes = {
    group: PropTypes.objectOf(PropTypes.any).isRequired,
    onClose: PropTypes.func.isRequired,
    onRedirection: PropTypes.func.isRequired,
    onOpenCollectionManage: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    footerOptions: PropTypes.objectOf(PropTypes.object),
    currentProofId: PropTypes.string,
    collectionProofDownloadProps: PropTypes.shape({
      status: PropTypes.string,
      downloadProgress: PropTypes.objectOf(PropTypes.number).isRequired,
      collectionFiles: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })).isRequired,
      onDownload: PropTypes.func.isRequired,
      proofType: PropTypes.string.isRequired,
    }).isRequired,
    bulkGeneralCommentsProps: PropTypes.shape({
      proofs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
  };
}

export default GroupProofsPopupContainer;
