/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Switch from '../Switch';
import FormControl from '../FormControl';
import { Translation } from '../Text';

function ProfileAccess({
  user,
  onToggleDomainAdmin,
  onToggleUserEnabled,
}) {
  return (
    <Fragment>
      <div className="DomainAdminProfile__access__subheading">
        <Translation value="domain-admin.user-profile.access.header" />
      </div>
      <FormControl
        label={<Translation value="domain-admin.user-profile.admin" />}
        compact
      >
        <div className="DomainAdminProfile__access__switch">
          <Switch
            value={user.IsAdmin}
            onChange={() => onToggleDomainAdmin(!user.IsAdmin)}
            message={user.IsAdmin ? 'domain-admin.user-profile.admin.on' : 'domain-admin.user-profile.admin.off'}
          />
        </div>
      </FormControl>
      <FormControl
        label={<Translation value="domain-admin.user-profile.login" />}
        compact
      >
        <div className="DomainAdminProfile__access__switch">
          <Switch
            value={user.enabled}
            onChange={() => onToggleUserEnabled(!user.enabled)}
            message={user.enabled ? 'domain-admin.user-profile.login.on' : 'domain-admin.user-profile.login.off'}
          />
        </div>
      </FormControl>
    </Fragment>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ProfileAccess.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    onToggleDomainAdmin: PropTypes.func.isRequired,
    onToggleUserEnabled: PropTypes.func.isRequired,
  };
}
export default ProfileAccess;
