/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import HeaderTrayTunnel from './HeaderTrayTunnel';

function HeaderTrayChild({ children }) {
  return (
    <HeaderTrayTunnel.Tunnel>
      {children}
    </HeaderTrayTunnel.Tunnel>
  );
}

export default HeaderTrayChild;
