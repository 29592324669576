/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormControl from '../FormControl';
import MultiEmailFormField from './MultiEmailFormField';
import {Button} from '../Button';
import {Translation, withTranslations} from '../Text';

class MultiEmailForm extends Component {
  inputs = [];

  onChange = (value, index) => {
    const {
      emails,
      updateEmails,
    } = this.props;
    const updatedEmails = emails;
    const splitValue = value.split(/\s*,\s*/);
    for (let i = 0; i < splitValue.length; i += 1) {
      updatedEmails[index + i] = splitValue[i];
    }
    updateEmails(updatedEmails);
    if (value.substr(-1) === ',') {
      this.changeFocus(index + 1);
    }
  }

  getMappedEmails = () => {
    const {
      emails,
      message,
      compact,
    } = this.props;
    this.complete = true;
    return emails.concat('').map((email, index, allEmails) => {
      if (!email && index < allEmails.length - 2) {
        return null;
      }
      if (!this.checkEmail(email) || !emails[0]) {
        this.complete = false;
      }
      return (
        <MultiEmailFormField
          placeholder={index ? Translation.text('workflow.structure.anyone-else') : Translation.text('workflow.structure.recipient')}
          value={email}
          onChange={value => this.onChange(value, index)}
          compact={compact}
          message={message}
          valid={this.checkEmail(email)}
          key={index}
          inputRef={(element) => {
            this.inputs[index] = element;
          }}
        />
      );
    });
  }

  changeFocus = (index) => {
    this.inputs[index].focus();
  }

  checkEmail = (string) => {
    const {onValidate} = this.props;
    let result = true;
    if (string) {
      if (onValidate) {
        result = onValidate(string) && window.validateEmail(string);
      } else {
        result = window.validateEmail(string);
      }
    }
    return result;
  }

  render() {
    const {
      onSubmit,
      compact,
      emails,
    } = this.props;
    return (
      <div>
        <FormControl
          label={<Translation value="label.email" />}
          compact={compact}
        >
          {this.getMappedEmails()}
        </FormControl>
        <div className="form-actions">
          <Button
            variant="primary"
            onClick={onSubmit}
            label={<Translation value={emails.length > 1 ? 'team-dashboard.invite.send-many' : 'team-dashboard.invite.send-one'} />}
            disabled={!this.complete}
          />
        </div>
      </div>
    );
  }
}

MultiEmailForm.defaultProps = {
  message: 'workflow.error.invalid-email',
};

if (process.env.NODE_ENV !== 'production') {
  MultiEmailForm.propTypes = {
    updateEmails: PropTypes.func.isRequired,
    emails: PropTypes.arrayOf(PropTypes.string).isRequired,
    compact: PropTypes.bool,
    onValidate: PropTypes.func,
    message: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };
}

export default withTranslations(MultiEmailForm);
