/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// Source - https://github.com/mozilla/pdf.js/blob/33f5d94f23f16073dd35cecebe88065b0d4bc43d/src/shared/util.js#L116-L143
// Commented out types are not supported by the PDF Comment Importer
// Annotation type names match the Adobe UI.
const annotationTypeToName = {
  1: 'Note',
  // 2: 'LINK', // Hyperlink on the pdf, doesn't create a comment in Acrobat
  3: 'Text Box',
  4: 'Line',
  5: 'Rectangle',
  6: 'Circle',
  7: 'Polygon',
  8: 'Connected Lines',
  9: 'Highlighted Text',
  10: 'Underlined Text',
  11: 'Underlined Text',
  12: 'Strikethough Text',
  13: 'Applied Stamp',
  14: 'Insert Text',
  15: 'Pencil',
  // 16: 'POPUP', // This is the UI component that appears in Acrobat when you mouse over or select an annotation
  // 17: 'FILEATTACHMENT', // This comment text is the name of the attachment
  18: 'Sound Clip', // Acrobat shows the file size in the UI, eg. "Sound Clip (1 KB)"
  // 19: 'MOVIE', // Embedded video
  // 20: 'WIDGET', // Parts of an interactive form
  // 21: 'SCREEN', // The area of the pdf where media is played
  // 22: 'PRINTERMARK', // Visual mark on the page
  // 23: 'TRAPNET', // Defines the trapping characteristics for the page
  // 24: 'WATERMARK', // Does not create a 'comment' in Acrobat
  // 25: 'THREED', // Three dimensional artwork
  26: 'Redact',
};

// Some annotation types cover multiple uses, the intent is used to differentiate them
const intentToName = {
  FreeTextCallout: 'Callout',
  LineArrow: 'Arrow',
  Replace: 'Replace',
};

export const getAnnotationName = annotation => (annotation.intent && intentToName[annotation.intent]) || annotationTypeToName[annotation.annotationType];
