/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import AutoDeviceWrapper from '../../components/AutoDeviceWrapper';
import {
  deferUpdate as deferUpdateResize,
} from '../../util/resize';

const SANDBOX = [
  'allow-forms',
  'allow-popups',
  'allow-pointer-lock',
  'allow-same-origin',
  'allow-scripts',
  'allow-popups-to-escape-sandbox',
];

class WebProof extends Component {
  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.commentPaneWidth !== this.props.commentPaneWidth) {
      deferUpdateResize();
    }
  }

  render() {
    const {
      device,
      contentHeight,
      orientation,
      url,
      commentPaneWidth,
      onFrameCreate,
      onDimensionsChange,
      focusMode,
      isFitViewport,
    } = this.props;
    return (
      <div
        className="WebProof"
        style={{ right: commentPaneWidth }}
      >
        <AutoDeviceWrapper
          orientation={orientation}
          device={device}
          onDimensionsChange={onDimensionsChange}
          contentHeight={contentHeight}
          focusMode={focusMode}
          isFitViewport={isFitViewport}
        >
          <div className="WebProof__dropdownOverlay" />
          <iframe
            title="web-proof"
            ref={onFrameCreate}
            className="WebProof__frame"
            src={url}
            sandbox={SANDBOX.join(' ')}
          />
        </AutoDeviceWrapper>
      </div>
    );
  }
}

export default WebProof;
