/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import gql from 'graphql-tag';
import { useSubscription, ApolloProvider } from '@apollo/react-hooks';
import { getGraphiteClient } from '../../util/gql/getGraphiteClient';

function CommentEvents({ proofId, onEvent }) {
  useSubscription(gql`
    subscription example($proofId: String!) {
      onCommentEvent(proofId: $proofId) {
        ...on CommentCreatedEvent {
          commentId
        }
        ...on CommentEditedEvent {
          commentId
        }
        ...on ReplyCreatedEvent {
          replyId
        }
        ...on ReplyEditedEvent {
          replyId
        }
        ...on CommentDeletedEvent {
          commentId
        }
        ...on ReplyDeletedEvent {
          replyId
        }
        ...on CommentUpdatedEvent {
          commentId
        }
        ...on CommentsUpdatedEvent {
          proofId
        }
      }
    }
  `, {
    variables: {
      proofId,
    },
    onSubscriptionData(options) {
      onEvent(options.subscriptionData.data.onCommentEvent);
    },
    shouldResubscribe: true,
  });

  return null;
}

export default function (props) {
  return (
    <ApolloProvider client={getGraphiteClient()}>
      <CommentEvents {...props} />
    </ApolloProvider>
  );
}
