/* eslint-disable */

import React, { useRef, useEffect } from 'react';

export default function RevealTextHorizontal({
  show = false,
  duration = 300,
  easing = 'ease-in-out',
  children,
}) {
  const ref = useRef();
  const isInitialRef = useRef(true);

  useEffect(() => {
    const isInitial = isInitialRef.current;
    isInitialRef.current = false;

    const element = ref.current;
    element.style.overflow = 'hidden';
    element.style.whiteSpace = 'nowrap';

    if (show) {
      if (!isInitial) {
        element.style.transition = '';
        element.style.width = 'auto';
        const width = element.clientWidth;
        element.style.width = '0px';
        const _ = element.clientWidth;
        element.style.transition = `width ${duration}ms ${easing}`;
        element.style.width = width + 'px';
        const timeout = setTimeout(() => {
          element.style.transition = '';
          element.style.width = '';
        }, duration);
        return () => {
          clearTimeout(timeout);
        };
      }
    } else {
      element.style.transition = '';
      if (!isInitial) {
        element.style.width = 'auto';
        const width = element.clientWidth;
        element.style.width = width + 'px';
        const _ = element.clientWidth;
        element.style.transition = `width ${duration}ms ${easing}`;
      }
      element.style.width = 0;
      const timeout = setTimeout(() => {
        element.style.transition = '';
      }, duration);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [show]);

  return (
    <div ref={ref}>
      {children}
    </div>
  );
}
