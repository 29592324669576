/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import Spinner from './Spinner';
import InlineSpinner from './InlineSpinner';

export {
  Spinner,
  InlineSpinner,
};
