/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useMemo } from 'react';
import { useWorkflow } from './internals/useWorkflow';
import Workflow from '../../components/ProofSetup/components/Workflow';
import { Spinner } from '../../components/Spinner';

export const ProofInfoWorkflowSteps = ({ workflowId, isReadOnly, proof, onWorkflowUpdate }) => {
  const { workflow, mutations, proofCommentCounts, proofLocker, isLoading } = useWorkflow(workflowId, proof.id, proof.lockerUserId, onWorkflowUpdate || (() => {}));

  const onManuallyApprove = useMemo(() => {
    if (!proof.currentUserCanManage) {
      return undefined;
    }

    return () => {
      const { proofInfoService, $rootScope } = window.__pageproof_bridge__;

      const options = {
        canManage: true,
        approve: true,
      };

      proofInfoService.$$emit('openManageProof', options);

      if (!$rootScope.$$phase) {
        $rootScope.$apply();
      }
    };
  }, [proof.currentUserCanManage]);

  if (!workflow) {
    if (isLoading) {
      return <Spinner size={36} />;
    }
    return null;
  }

  return (
    <div style={{ textAlign: 'left' }}>
      <Workflow
        workflow={workflow}
        proof={{
          ...proof,
          approvedDate: proof.approvedDate && proof.approvedDate.toDate(),
          commentCounts: proofCommentCounts,
          locker: proofLocker,
        }}
        owners={proof.owners}
        events={{
          onAddStep: mutations.addWorkflowStep,
          onDeleteStep: mutations.deleteWorkflowStep,
          onAddUsers: mutations.addWorkflowUsers,
          onRemoveUser: mutations.removeWorkflowUser,
          onUpdateUserRole: mutations.updateWorkflowUserRole,
          onUpdateUserPermissions: mutations.updateWorkflowUserPermissions,
          onUpdateStepName: mutations.updateWorkflowStepName,
          onUpdateStepDueDate: mutations.updateWorkflowStepDueDate,
          onUpdateStepMandatoryDecisionThreshold: mutations.updateWorkflowStepMandatoryDecisionThreshold,
          onNudgeUser: mutations.nudgeUser,
          onSkipUser: mutations.skipUser,
          onManuallyApprove,
        }}
        isReadOnly={isReadOnly}
        type="proof"
        // All changes for type="proof" are handled by `events` so there's no need to do anything here
        onChange={() => { }}
      />
    </div>
  );
};
