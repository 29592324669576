/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import BrandingUploadFile from './BrandingUploadFile';
import LogoBrandingPreview from './LogoBrandingPreview';
import FormControl from '../../components/FormControl';
import Switch from '../../components/Switch';
import {Translation} from '../../components/Text';
import {InlineSpinner} from '../../components/Spinner';
import {Subheading} from '../../components/Page';
import {getDescription, validateFile} from '../../util/validation';

const constraints = {
  fileTypes: [
    'png',
    'jpeg',
    'jpg',
    'gif',
  ],
  maxWidth: 120,
  height: 120,
  maxFileSize: 150e3,
};

const spinner = display => (
  display &&
    <InlineSpinner
      size={26}
      style={{marginLeft: 20}}
    />
);

function Logo({
  uploadFile,
  toggleBranding,
  logo,
  loading,
}) {
  return (
    <div className="EmailBrandingSettings">
      <Subheading
        title={
          <span>
            <Translation value="domain-admin.menu.branding.logo.upload" />
            {spinner(loading.upload)}
          </span>
        }
        description={
          <Translation
            value="domain-admin.menu.branding.logo.must-be"
            params={{description: getDescription(constraints)}}
          />
        }
      />
      <BrandingUploadFile
        title={<Translation value="domain-admin.menu.branding.logo.drag" />}
        onValidate={(file, callback) => {
          validateFile(file, constraints, callback);
        }}
        onSelect={(file) => {
          uploadFile('logo', file);
        }}
      />
      <br />
      {logo.exists &&
        <div>
          <Subheading
            title={
              <span>
                <Translation value="domain-admin.menu.branding.look" />
                {spinner(loading.alignment)}
              </span>
            }
            description={
              <span>
                <Translation value="domain-admin.menu.branding.logo.proofing-preview" />
              </span>
            }
          />
          <LogoBrandingPreview
            url={logo.url}
          />
          <br />
          <Subheading
            title={
              <span>
                <Translation value="domain-admin.menu.branding.ready" />
                {spinner(loading.toggle)}
              </span>
            }
            description={
              <span>
                <Translation value="domain-admin.menu.branding.logo.enable" />
              </span>
            }
          />
          <FormControl
            compact
            message={<Translation value="domain-admin.menu.branding.logo.note" />}
          >
            <Switch
              value={logo.enabled}
              onChange={value => toggleBranding('logo', value)}
            />
          </FormControl>
        </div>
      }
    </div>
  );
}

export default Logo;
