/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Translation } from '../components/Text';

export const createReactTranslationElement = (value, params) => (
  <Translation
    value={value}
    params={params}
  />
);
