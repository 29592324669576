/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import Hashtag from '../Hashtag';
import css from './CommentNumber.scss';
import { useCommentSpacing } from '../CommentSpacing';

function CommentNumber({ value }) {
  const spacing = useCommentSpacing();

  return (
    <div className={classname(css.CommentNumber, css[`CommentNumber--${spacing}-spacing`])}>
      <Hashtag always>
        #
        {value}
      </Hashtag>
    </div>
  );
}

export default CommentNumber;
