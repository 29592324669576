/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { render } from 'react-dom';
import { I18nProvider } from '../components/I18n/I18nProvider';
import BarcodeScannerOverlay from '../containers/BarcodeScanner/BarcodeScannerOverlay';

export function renderBarcodeScannerOverlay(container, props) {
  render(
    (
      <I18nProvider>
        <BarcodeScannerOverlay {...props} />
      </I18nProvider>
    ),
    container,
  );
}
