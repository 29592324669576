/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './ModalHeading.scss';

export const ModalHeading = (props) => {
  const { heading, subheading } = props;

  return (
    <div className={css.ModalHeading}>
      <h1 className="modal-title">
        {heading}
      </h1>
      {subheading && (
        <div className="modal-message">
          {subheading}
        </div>
      )}
    </div>
  );
};
