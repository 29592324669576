/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import FileThumbnail from '../../containers/FileThumbnail';
import { goToUrl } from '../../util/location';
import { getProofLink } from '../../util/proof-utils';
import css from './NextProofTile.scss';
import Switch from '../Switch';
import DiscardButtonOnHover from '../DiscardButtonOnHover';
import { useLocalStorage } from '../LocalStorage';
import { Translation } from '../Text';
import Truncate from '../Truncate';

const COUNTDOWN_TIME = 5;

const getSearchParamsWithoutPage = () => {
  const searchParamsObject = {
    ...window.__pageproof_bridge__.$location.search(),
  };
  delete searchParamsObject.page;

  return Object.keys(searchParamsObject).length ? `?${new URLSearchParams(searchParamsObject)}` : '';
};

const NextProofTile = ({
  proof,
  visible,
  onClose,
}) => {
  const [countdown, setCountdown] = useState(COUNTDOWN_TIME);
  const [autoLoad, setAutoLoad] = useLocalStorage('pageproof.app.load-next-proof.auto-load', true);
  const goToNextProof = () => goToUrl(getProofLink(proof) + getSearchParamsWithoutPage());
  let timer = null;

  useEffect(() => {
    if (visible && countdown > 0 && autoLoad) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown <= 0 && autoLoad) {
      goToNextProof();
    }

    return () => clearTimeout(timer);
  }, [visible, countdown, autoLoad]);

  const handleSwitchChange = () => {
    if (autoLoad) {
      setCountdown(COUNTDOWN_TIME);
    } else {
      clearTimeout(timer);
    }
    setAutoLoad(!autoLoad);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={classname(
      css.NextProofTile,
      { [css.NextProofTile__slideIn]: visible },
    )}
    >
      <DiscardButtonOnHover onDiscard={() => {
        clearTimeout(timer);
        onClose(false);
      }}
      >
        <div className={css.NextProofTile__wrapper}>
          <div className={css.NextProofTile__content}>
            <div className={css.NextProofTile__header}>
              <Translation value="proof.next-proof.next-proof-in-collection" />
            </div>
            <div className={css.NextProofTile__thumbnail}>
              <FileThumbnail
                width={180}
                height={160}
                id={proof.fileId}
                onClick={goToNextProof}
              />
            </div>
            <div className={css.NextProofTile__proofName}>
              <Truncate
                maxWidth={200}
                showTooltipWhenTruncated
              >
                {proof.name}
              </Truncate>
            </div>
            <div className={css.NextProofTile__switch}>
              {autoLoad && (
                <div className={css.NextProofTile__switch__countdown}>{countdown}</div>
              )}
              <div className={css.NextProofTile__switch__container}>
                <div className={css.NextProofTile__switch__container__label}>
                  <Translation value="proof.next-proof.auto-open" />
                </div>
                <Switch
                  size="tiny"
                  value={autoLoad}
                  onChange={() => handleSwitchChange()}
                />
              </div>
            </div>
          </div>
        </div>
      </DiscardButtonOnHover>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  NextProofTile.propTypes = {
    proof: PropTypes.objectOf(PropTypes.any).isRequired,
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  };
}

export default NextProofTile;
