/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './ItemBorderWrapper.scss';

const ItemBorderWrapper = ({ children, items, currentItem, outlineItems }) => {
  const index = items.indexOf(currentItem);
  const centerBorder = outlineItems.indexOf(currentItem) !== -1;
  const openingBorder = (
    items[index - 1] === undefined ||
    outlineItems.indexOf(items[index - 1]) === -1
  );
  const closingBorder = (
    items[index + 1] === undefined ||
    outlineItems.indexOf(items[index + 1]) === -1
  );

  return (
    <div
      className={classname(css.ItemBorderWrapper, {
        [css['ItemBorderWrapper--centerBorder']]: centerBorder,
        [css['ItemBorderWrapper--openingBorder']]: centerBorder && openingBorder,
        [css['ItemBorderWrapper--closingBorder']]: centerBorder && closingBorder,
      })}
    >
      {children}
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  ItemBorderWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    items: PropTypes.array.isRequired,
    currentItem: PropTypes.any.isRequired,
    outlineItems: PropTypes.array.isRequired,
  };
}

export default ItemBorderWrapper;
