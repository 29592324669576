/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import ZoomSlider from '../../components/ZoomSlider';
import { usePageGridSettings } from '../../hooks/usePageGridSettings';

export function PageGridZoomContainer() {
  const [{ zoomLevel }, setSettings] = usePageGridSettings();

  return (
    <div style={{ maxWidth: 300 }}>
      <ZoomSlider
        zoomLevel={zoomLevel}
        minZoomLevel={1}
        maxZoomLevel={4}
        onZoomChange={value => setSettings({ zoomLevel: value })}
        onReset={() => setSettings({ zoomLevel: 1 })}
      />
    </div>
  );
}
