/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
// Disable no-param-reassign globally as we make frequent use of reassigning fields in parameters in the proof setup page
/* eslint-disable no-param-reassign */
import { useLocalStorage } from '../../LocalStorage';
import { deleteBlob } from './temporaryReferences';
import Toaster from '../components/Toaster';
import { sdk } from '../../../util/sdk';
import { mapWorkflowFromSdk } from './workflows';
import { getWorkflowWithAvailableRoles } from './getWorkflowWithAvailableRoles';

export const proofSetupKey = 'pageproof.app.proof-setup';

function getLocalProofSetup() {
  const localProofSetupString = window.localStorage.getItem(proofSetupKey);
  if (localProofSetupString) {
    try {
      return JSON.parse(localProofSetupString);
    } catch (err) {
      console.error(err);
    }
  }
  return null;
}

export function getProofSetup() {
  const localProofSetup = getLocalProofSetup();
  return localProofSetup || startProofSetup();
}

function buildParameters(parameters) {
  return {
    proofType: 'proof', // 'proof' or 'brief'
    addCurrentUserAsOwner: true, // typeof boolean
    defaultMessageToReviewers: null, // typeof string
    addToCollection: null, // null or true or { id: <group-id>, name: <group-name> }
    attachNewVersion: null, // null or { proofId: <proof-id>, workflowId: <workflow-id> }
    updateProof: null, // null or { id: <proofId> }
    backUrl: null, // typeof string
    redirectUrl: null, // typeof string
    defaults: { ...(parameters && parameters.defaults) }, // all optional: { name: string, reference: string, tags: string[], messageToReviewers: string, canDownload: boolean }
    ...parameters,
  };
}

export function getDefaults() {
  if (!hasProofSetup()) {
    return {};
  }
  return getProofSetup().parameters.defaults;
}

export function hasProofSetup() {
  return !!window.localStorage.getItem(proofSetupKey);
}

export function getProofSetupProofCount() {
  const localProofSetup = getLocalProofSetup();
  return localProofSetup && localProofSetup.proofs && localProofSetup.proofs.length;
}

function initializeProofSetup(proofSetup, initialize) {
  if (typeof initialize === 'string') {
    return; // replace existing proof in proof setup (by id)
  }

  const { collection, proof, owners, workflow, checklist } = initialize;
  if (proof) {
    proofSetup.proofs = [
      {
        ...proof,
        _id: proof._id || proof.id,
      },
    ];
  }
  if (owners) {
    proofSetup.owners = owners;
  }
  if (workflow) {
    if (workflow.id || workflow.steps) {
      proofSetup.workflow = getWorkflowWithAvailableRoles(workflow, proofSetup.parameters);
    }
    if (workflow.id) {
      loadProofSetupWorkflow(workflow.id);
    }
  }
  if (collection) {
    proofSetup.shared.collection = collection.name;
  }
  if (checklist) {
    proofSetup.checklist = checklist;
  }
}

export function loadProofSetupWorkflow(workflowId) {
  if (!workflowId) {
    workflowId = getProofSetup().workflow.id;
  }

  sdk.workflows.load(workflowId)
    .then(mapWorkflowFromSdk)
    .then((workflow) => {
      updateProofSetup((proofSetup) => {
        if (proofSetup) {
          if (proofSetup.workflow && proofSetup.workflow.id === workflowId) {
            if (!proofSetup.parameters.updateProof) {
              proofSetup.workflow = {
                ...workflow,
                steps: workflow.steps.map(step => ({
                  ...step,
                  dueDate: null,
                })),
              };
            } else {
              proofSetup.workflow = workflow;
            }

            proofSetup.workflow = getWorkflowWithAvailableRoles(proofSetup.workflow);
          }
        }
      });
    });
}

export function prepareProofSetup({ parameters, initialize }, callback) {
  if (!hasProofSetup()) {
    callback(null, startProofSetup(parameters, initialize));
    return;
  }

  const proofSetup = getProofSetup();

  if (proofSetup.parameters.attachNewVersion && proofSetup.proofs.length !== 0) {
    callback(prepareProofSetup.NewVersionInProgressError, null);
    return;
  }

  const serializedProofSetupParameters = JSON.stringify(proofSetup.parameters);
  const serializedNewParameters = JSON.stringify(buildParameters(parameters));

  if (serializedProofSetupParameters !== serializedNewParameters) {
    callback(prepareProofSetup.ParameterMismatchError, null);
    return;
  }

  if (initialize) {
    initializeProofSetup(proofSetup, initialize);
    saveProofSetup(proofSetup);
  }

  callback(null, proofSetup);
}

prepareProofSetup.ParameterMismatchError = 1;
prepareProofSetup.NewVersionInProgressError = 2;

function emptyProofSetup(customParameters) {
  const parameters = buildParameters(customParameters);

  if (!parameters.addToCollection && parameters.attachNewVersion && parameters.attachNewVersion.collectionId) {
    parameters.addToCollection = {
      id: parameters.attachNewVersion.collectionId,
    };
  }

  return {
    parameters,
    shared: {
      collection: parameters.addToCollection
        ? parameters.addToCollection.name
        : undefined,
      addToCollection: parameters.addToCollection,
    },
    proofs: [],
    workflowTemplateOwners: [],
    owners: parameters.addCurrentUserAsOwner
      ? [sdk.session.user.email]
      : [],
    workflow: null,
    checklist: null,
    validation: [],
  };
}

export function clearProofSetup() {
  window.localStorage.removeItem(proofSetupKey);
}

export function startProofSetup(parameters, initialize) {
  const proofSetup = emptyProofSetup(parameters);
  if (initialize) {
    initializeProofSetup(proofSetup, initialize);
  }
  window.localStorage.setItem(proofSetupKey, JSON.stringify(proofSetup));
  return proofSetup;
}

export function saveProofSetup(proofSetup) {
  window.localStorage.setItem(proofSetupKey, JSON.stringify(proofSetup));
}

export function useProofSetup() {
  return useLocalStorage(proofSetupKey, emptyProofSetup);
}

export function updateProofSetup(callback) {
  if (!hasProofSetup()) {
    callback(null);
    return null;
  }
  let proofSetup = getProofSetup();
  const returnValue = callback(proofSetup);
  if (returnValue != null) {
    proofSetup = returnValue;
  }
  saveProofSetup(proofSetup);
  return proofSetup;
}

export function getProofSetupProof(id, callback) {
  return updateProofSetup((proofSetup) => {
    if (proofSetup) {
      const proof = proofSetup.proofs.filter(p => p._id === id)[0] || null;
      callback(proof);
    } else {
      callback(null);
    }
  });
}

export function updateProofSetupProof(id, callback) {
  return updateProofSetup((proofSetup) => {
    if (proofSetup) {
      const proof = proofSetup.proofs.filter(p => p._id === id)[0] || null;
      const returnValue = callback(proof);
      if (returnValue === null) {
        // Delete if null is returned
        proofSetup.proofs = proofSetup.proofs.filter(p => p._id !== id);
      }
    } else {
      callback(null);
    }
  });
}

export function deleteProofSetupProofFile(id) {
  const tasks = [];

  updateProofSetupProof(id, (proof) => {
    if (proof && proof.file) {
      if (proof.file.fileReference) {
        tasks.push(deleteBlob(proof.file.fileReference));
      }
      if (proof.file.thumbnailReference) {
        tasks.push(deleteBlob(proof.file.thumbnailReference));
      }
      if (proof.file.id) {
        // TODO call the API to delete the file directly
      }
      delete proof.file;
    }
  });

  return Promise.all(tasks);
}

export function deleteProofSetupProof(id) {
  return deleteProofSetupProofFile(id)
    .then(() => updateProofSetupProof(id, () => null));
}

export function addProofSetupValidation(type, props) {
  updateProofSetup((proofSetup) => {
    proofSetup.validation = Toaster.addToast(proofSetup.validation, type, props);
  });
}

export function addOrUpdateProofSetupValidation(type, props) {
  updateProofSetup((proofSetup) => {
    proofSetup.validation = Toaster.addOrUpdateToast(proofSetup.validation, type, props);
  });
}

export function removeProofSetupValidation(type) {
  updateProofSetup((proofSetup) => {
    proofSetup.validation = Toaster.removeToast(proofSetup.validation, type);
  });
}
