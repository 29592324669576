/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import {
  VideoScrubber,
  ScrubberIcons
} from '../../components/VideoScrubber';

function VideoScrubberContainer({
  isLoading,
  scrubber,
  icons,
}) {
  return (
    <div className={classname('VideoScrubberContainer', {
      'VideoScrubberContainer--loading': isLoading,
    })}
    >
      {scrubber && <VideoScrubber {...scrubber} />}
      {icons && <ScrubberIcons {...icons} />}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  VideoScrubberContainer.propTypes = {
    isLoading: PropTypes.bool,
  };
}

export default VideoScrubberContainer;
