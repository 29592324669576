/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './PillsListWithDiscard.scss';
import DiscardButtonOnHover from '../DiscardButtonOnHover';

const PillsListWithDiscard = ({ list }) => (
  <div className={css.PillsListWithDiscard}>
    {list.map(({ id, type, onRemove }) => (
      <div
        key={id}
        className={css.PillsListWithDiscard__filter}
      >
        <DiscardButtonOnHover onDiscard={onRemove}>
          <div className={css.PillsListWithDiscard__filter__pill}>
            {type}
          </div>
        </DiscardButtonOnHover>
      </div>
    ))}
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  PillsListWithDiscard.propTypes = {
    list: PropTypes.arrayOf(PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.node.isRequired,
        PropTypes.func.isRequired,
      ]),
    )).isRequired,
  };
}

export default PillsListWithDiscard;
