import React from 'react';

export default function ContainOverscrollBehaviour() {
  return (
    <style>
      {`
        body {
          overscroll-behavior: contain;
        }
      `}
    </style>
  );
}
