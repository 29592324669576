/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Children, cloneElement } from 'react';
import useText from './useText';

const TranslatedProps = ({ children, ...props }) => {
  const text = useText();
  const translatedProps = {};
  Object.keys(props).forEach((key) => {
    // If no value is provided we do not want to override the prop for that key
    if (typeof props[key] === 'string') {
      translatedProps[key] = text(props[key]);
    } else if (typeof props[key] === 'object' && props[key].value && props[key].params) {
      translatedProps[key] = text(props[key].value, props[key].params);
    }
  });
  return cloneElement(Children.only(children), translatedProps);
};

export default TranslatedProps;
