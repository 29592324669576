import { sdk } from './sdk';

const SHARED_WITH_TEAM_LOCAL_KEY_PREFIX = 'pageproof.app.collection.share-with-team.';

export function setSharedWithTeamLocalPreference(value) {
  window.localStorage.setItem(SHARED_WITH_TEAM_LOCAL_KEY_PREFIX + sdk.session.userId, value);
}

export function getSharedWithTeamLocalPreference() {
  return window.localStorage.getItem(SHARED_WITH_TEAM_LOCAL_KEY_PREFIX + sdk.session.userId) === 'true';
}
