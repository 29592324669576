/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { createStitchEmbed } from '../../util/createStitchEmbed';

export const ProofTemplatesDashboard = createStitchEmbed('ProofTemplatesDashboard', {
  iframeProps: {
    style: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
  },
});
