/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { sdk } from '../../../../util/sdk';

export default function createWorkflow(workflow, { name, owners } = {}) {
  const reviewerSteps = [...workflow.steps];
  const approverStep = reviewerSteps.pop();

  return sdk.workflows.create({
    name,
    steps: [
      ...reviewerSteps.map(step => ({
        name: step.name,
        start: step.start,
        dueDate: step.dueDate,
        mandatoryDecisionThreshold: step.mandatoryDecisionThreshold,
        users: step.users.map(user => ({
          email: user.email,
          role: user.role,
          permissions: user.permissions,
        })),
      })),
      {
        position: 1000,
        name: approverStep.name,
        start: approverStep.start,
        dueDate: approverStep.dueDate,
        mandatoryDecisionThreshold: approverStep.mandatoryDecisionThreshold,
        users: approverStep.users.map(user => ({
          email: user.email,
          permissions: user.permissions,
        })),
      },
    ],
    owners: owners && owners.map(email => ({ email })),
  });
}
