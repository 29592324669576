/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { Option } from '../PopupMenu';
import css from './SubLabel.scss';

function SubLabel({
  label,
  subOptionCount,
  onClick,
}) {
  return (
    <Fragment>
      {subOptionCount > 0
        ? (
          <Option
            label={
              <div className={css.SubLabel}>
                <span className={css.SubLabel__name}>
                  {label}
                </span>
                <span className={css.SubLabel__arrow}>&#x276e;</span>
              </div>
            }
            onClick={onClick}
          />
        ) : (
          <Option
            key={label}
            label={label}
            readOnly
          />
        )
      }
    </Fragment>
  );
}


export default SubLabel;
