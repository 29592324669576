/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* This is a patch HOC translating the 'Cancel' label for 'material-ui/DatePicker/DatePickerDialog' and 'material-ui/TimePicker/TimePickerDialog', then use them with 'material-ui-datetimepicker',
 * as the implementation of 'material-ui-datetimepicker' do not allow us to pass 'cancelLabel' directly into them.
 */
import React from 'react';
import { useText } from '../Text';

export default function translateDateTimePickerDialog(WrappedComponent) {
  return React.forwardRef((props, ref) => {
    const text = useText(); // Material UI v0 is using `unstable_renderSubtreeIntoContainer` which have an issue(https://github.com/facebook/react/issues/16721) that does not transfer context, so not able to pass in a Translation component here.

    return (
      <WrappedComponent
        ref={ref}
        cancelLabel={text('button.cancel')}
        {...props}
      />
    );
  });
}
