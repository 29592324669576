/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useMemo } from 'react';
import classname from 'classnames';
import moment from 'moment';
import { Enum } from '@pageproof/sdk';
import ThumbsUp from '../Decisions/Icons/ThumbsUp';
import ThumbsUpOrange from '../Decisions/Icons/ThumbsUpOrange';
import ThumbsDown from '../Decisions/Icons/ThumbsDown';
import css from './WorkflowUserStatus';
import Tooltip from '../Tooltip';
import createIcon from '../Icon/createIcon';
import { useI18n } from '../../hooks/useI18n';
import { Translation } from '../Text';

const Lock = createIcon({
  displayName: 'Lock',
  src: 'img/interface/padlock.svg',
  defaultSize: 14,
  activeColor: '#777',
  activeHoverColor: '#777',
});

const WorkflowUserStatusIcon = ({
  decisionId,
  status,
}) => {
  if (status === 'decisionLeft') {
    // eslint-disable-next-line default-case
    switch (decisionId) {
    case Enum.Decision.APPROVED:
      return (
        <ThumbsUp
          activeColor="#138b3b"
          size={14}
          active
          readOnly
        />
      );
    case Enum.Decision.APPROVED_WITH_CHANGES:
      return (
        <ThumbsUpOrange
          activeColor="#fe8125"
          size={14}
          active
          readOnly
        />
      );
    case Enum.Decision.SEND_CHANGES:
      return (
        <ThumbsDown
          activeColor="#e51c23"
          size={14}
          active
          readOnly
        />
      );
    }
  }

  if (status === 'locker') {
    return <Lock active readOnly />;
  }

  return (
    <span
      className={classname(css.WorkflowUserStatus__dot, css[`WorkflowUserStatus__dot--${status}`])}
    />
  );
};

function WorkflowUserStatus({
  isSkipped,
  isLocker,
  hasSeen,
  decisionId,
  decisionDate,
  firstViewedDate,
  isFinishedWithoutDecisionsEnabled,
}) {
  const { locale } = useI18n();

  const status = useMemo(() => {
    if (isFinishedWithoutDecisionsEnabled) {
      return 'finishedWithoutDecisionsEnabled';
    }

    if (decisionId) {
      return 'decisionLeft';
    }

    if (isLocker) {
      return 'locker';
    }

    if (isSkipped) {
      return 'skipped';
    }

    if (hasSeen) {
      return 'seen';
    }

    return 'unseen';
  }, [decisionId, isFinishedWithoutDecisionsEnabled, isLocker, isSkipped, hasSeen]);

  const tooltipTitleTranslationProps = useMemo(() => {
    switch (status) {
    case 'decisionLeft': {
      const date = decisionDate && moment(decisionDate).locale(locale).format('LLLL');
      switch (decisionId) {
      case Enum.Decision.APPROVED:
        return date
          ? { value: 'workflow.user.status.approved.with-date', params: { date } }
          : { value: 'workflow.user.status.approved' };
      case Enum.Decision.APPROVED_WITH_CHANGES:
        return date
          ? { value: 'workflow.user.status.approved-with-changes.with-date', params: { date } }
          : { value: 'workflow.user.status.approved-with-changes' };
      case Enum.Decision.SEND_CHANGES:
        return date
          ? { value: 'workflow.user.status.send-changes.with-date', params: { date } }
          : { value: 'workflow.user.status.send-changes' };
      default:
        return { value: 'workflow.user.status.decision-left' };
      }
    }
    case 'locker':
      if (firstViewedDate) {
        return { value: 'workflow.user.status.seen-and-locked', params: { date: moment(firstViewedDate).locale(locale).format('LLLL') } };
      }

      return { value: 'workflow.user.status.locked' };
    case 'skipped':
      return { value: 'workflow.user.status.skipped' };
    case 'seen':
      return firstViewedDate
        ? { value: 'workflow.user.status.seen.with-date', params: { date: moment(firstViewedDate).locale(locale).format('LLLL') } }
        : { value: 'workflow.user.status.seen' };
    case 'unseen':
      return { value: 'workflow.user.status.unseen' };
    case 'finishedWithoutDecisionsEnabled':
      return { value: 'workflow.user.status.finished-without-decisions-enabled' };
    default:
      break;
    }

    return null;
  }, [decisionId, decisionDate, firstViewedDate, status]);

  return (
    <Tooltip
      title={tooltipTitleTranslationProps && <Translation {...tooltipTitleTranslationProps} />}
      up
      center
      disablePointerEvents
      disabled={!tooltipTitleTranslationProps}
    >
      <span className={css.WorkflowUserStatus}>
        <WorkflowUserStatusIcon
          status={status}
          decisionId={decisionId}
        />
      </span>
    </Tooltip>
  );
}

export default WorkflowUserStatus;
