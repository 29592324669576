/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import ShyText from '../ShyText';
import css from './ShyTextPill.scss';

function ShyTextPill({
  className,
  children,
  ...props,
}) {
  return (
    <ShyText
      {...props}
      className={classname(css.ShyTextPill, className)}
    >
      {children}
    </ShyText>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ShyTextPill.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
  };
}

export default ShyTextPill;
