/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Subheading } from '../../components/Page';
import { Translation } from '../../components/Text';
import FormControl from '../../components/FormControl';
import Form from '../../components/Form';
import Switch from '../../components/Switch';
import HelpBubble from '../../components/HelpBubble/HelpBubble';
import Reveal from '../../components/Reveal';
import { Tabs, Tab } from '../../components/Tabs';
import TeamSenderEmail from '../../components/TeamSenderEmail';
import css from './TeamSettings';
import getPlanName from '../../util/getPlanName';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox';
import ProfileDropdown from '../../components/Profile/ProfileDropdown';


function TeamSettings({
  account,
  settings,
  onChange,
  notifications,
  onUpdateNotification,
  onUpdateSenderEmail,
}) {
  const [selectedTab, setSelectedTab] = useState('account');
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const { teamType, teamIsPlus, teamIsTrial } = account;

  const plan = getPlanName(teamType, teamIsPlus, teamIsTrial);

  return (
    <Form>
      <Heading
        title={<Translation value="domain-admin.settings.title" />}
        description={<Translation value="domain-admin.settings.description" />}
      />
      <Tabs selected={selectedTab}>
        <Tab
          id="account"
          name={<Translation value="domain-admin.settings.subheading.account" />}
          onClick={() => setSelectedTab('account')}
        >
          <FormControl label={<Translation value="domain-admin.settings.team-name" />}>
            <div className={css['TeamSettings__team-account']}>
              <span>{account.teamName}</span>
              <HelpBubble
                className={css['TeamSettings__team-account__tooltip']}
                message={<Translation value="domain-admin.settings.team-name.tooltip" />}
              />
            </div>
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.team-plan" />}>
            <div className={css['TeamSettings__team-account']}>
              <Translation value={plan} />
            </div>
          </FormControl>
        </Tab>
        <Tab
          id="intelligence"
          name={<Translation value="domain-admin.settings.intelligence.heading" />}
          onClick={() => setSelectedTab('intelligence')}
        >
          <Subheading
            title={<Translation value="domain-admin.settings.intelligence.subheading" />}
            description={<Translation value="domain-admin.settings.intelligence.description" />}
            helpMessage={<Translation value="domain-admin.settings.intelligence.tooltip" />}
            helpUrl="https://go.pageproof.com/pageproof-intelligence"
          />
          {settings.enableIntelligence
            ? (
              <Fragment>
                <FormControl label={<Translation value="domain-admin.settings.intelligence" />}>
                  <Switch
                    value={settings.enableIntelligence}
                    onChange={() => onChange('intelligence', 'enableIntelligence')}
                    message={settings.enableIntelligence ? 'domain-admin.settings.intelligence.on' : 'domain-admin.settings.intelligence.off'}
                  />
                </FormControl>
                <FormControl label={<Translation value="domain-admin.settings.intelligence.smart-tags" />}>
                  <Switch
                    value={settings.enableSmartTags}
                    onChange={() => onChange('intelligence', 'enableSmartTags')}
                    message={settings.enableSmartTags ? 'domain-admin.settings.intelligence.smart-tags.on' : 'domain-admin.settings.intelligence.smart-tags.off'}
                  />
                </FormControl>
                <FormControl label={<Translation value="domain-admin.settings.intelligence.smart-check-cr" />}>
                  <Switch
                    value={settings.enableSmartCheckCr}
                    onChange={() => onChange('intelligence', 'enableSmartCheckCr')}
                    message={settings.enableSmartCheckCr ? 'domain-admin.settings.intelligence.smart-check-cr.on' : 'domain-admin.settings.intelligence.smart-check-cr.off'}
                  />
                </FormControl>
              </Fragment>
            )
            : (
              <Fragment>
                <Subheading
                  title={<Translation value="domain-admin.settings.intelligence.ai-commitment.subheading" />}
                  description={<Translation value="domain-admin.settings.intelligence.ai-commitment.description" />}
                />
                <div className={css['TeamSettings__team-intelligence__checkbox']}>
                  <Checkbox
                    selected={isPolicyAccepted}
                    onChange={() => setIsPolicyAccepted(!isPolicyAccepted)}
                  />
                  <span className={css['TeamSettings__team-intelligence__checkbox__label']}>
                    <Translation value="domain-admin.settings.intelligence.accept-policy" />
                  </span>
                </div>
                <br />
                <ButtonGroup align="left">
                  <Button
                    onClick={() => onChange('intelligence', 'enableIntelligence')}
                    type="submit"
                    className={css.ProfileChangePassword__submitChangeButton}
                    label={<Translation value="domain-admin.settings.intelligence.enable-intelligence" />}
                    disabled={!isPolicyAccepted}
                  />
                </ButtonGroup>
              </Fragment>
            )
          }
        </Tab>
        <Tab
          id="features"
          name={<Translation value="domain-admin.settings.subheading.features" />}
          onClick={() => setSelectedTab('features')}
        >
          <FormControl label={<Translation value="domain-admin.settings.checklists" />}>
            <Switch
              value={settings.enableChecklists}
              onChange={() => onChange('proofs', 'enableChecklists')}
              message={settings.enableChecklists ? 'domain-admin.settings.checklists.on' : 'domain-admin.settings.checklists.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.webhooks" />}>
            <Switch
              disabled={!settings.configureWebhooks}
              tooltip={settings.configureWebhooks ? null : <Translation value="domain-admin.settings.license-required" />}
              value={settings.enableWebhooks}
              onChange={() => onChange('webhooks', 'enableWebhooks')}
              message={settings.enableWebhooks ? 'domain-admin.settings.webhooks.on' : 'domain-admin.settings.webhooks.off'}
            />
          </FormControl>
          <br />
          <Subheading title={<Translation value="domain-admin.settings.address-book.heading" />} />
          <FormControl label="Include team members">
            <Switch
              disabled={!settings.configureSuggestionsIncludeTeamMembers}
              tooltip={settings.configureSuggestionsIncludeTeamMembers ? null : <Translation value="domain-admin.settings.license-required" />}
              value={settings.suggestionsIncludeTeamMembers}
              onChange={() => onChange('addressBook', 'suggestionsIncludeTeamMembers')}
              message={settings.suggestionsIncludeTeamMembers ? 'domain-admin.settings.suggestions-include-team-members.on' : 'domain-admin.settings.suggestions-include-team-members.off'}
            />
          </FormControl>
        </Tab>
        <Tab
          id="proofs"
          name={<Translation value="domain-admin.settings.subheading.proofs" />}
          onClick={() => setSelectedTab('proofs')}
        >
          <Subheading title={<Translation value="domain-admin.settings.proof.heading" />} />
          <FormControl label={<Translation value="domain-admin.settings.use-previous-version-due-date" />}>
            <Switch
              value={settings.usePreviousVersionDueDate}
              onChange={() => onChange('proofs', 'usePreviousVersionDueDate')}
              message={settings.usePreviousVersionDueDate ? 'domain-admin.settings.use-previous-version-due-date.on' : 'domain-admin.settings.use-previous-version-due-date.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.public-proofs" />}>
            <Switch
              value={settings.publicProofs}
              onChange={() => onChange('proofs', 'publicProofs')}
              message={settings.publicProofs ? 'domain-admin.settings.public-proofs.on' : 'domain-admin.settings.public-proofs.off'}
            />
            <Reveal
              align="bottom"
              visible={settings.publicProofs}
            >
              <br />
              <Switch
                value={settings.defaultProofIsPublic}
                onChange={() => onChange('proofs', 'defaultIsPublic')}
                message={settings.defaultProofIsPublic ? 'domain-admin.settings.default-proof-is-public.on' : 'domain-admin.settings.default-proof-is-public.off'}
              />
            </Reveal>
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.general-comments" />}>
            <Switch
              value={settings.generalComments}
              onChange={() => onChange('comments', 'generalComments')}
              message={settings.generalComments ? 'domain-admin.settings.general-comments.on' : 'domain-admin.settings.general-comments.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.private-comments" />}>
            <Switch
              disabled={!settings.configurePrivateComments}
              tooltip={settings.configurePrivateComments ? null : <Translation value="domain-admin.settings.private-comments.license-required" />}
              value={settings.privateComments}
              onChange={() => onChange('comments', 'privateComments')}
              message={settings.privateComments ? 'domain-admin.settings.private-comments.on' : 'domain-admin.settings.private-comments.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.edit-comments" />}>
            <Switch
              value={settings.editComments}
              onChange={() => onChange('comments', 'editComments')}
              message={settings.editComments ? 'domain-admin.settings.edit-comments.on' : 'domain-admin.settings.edit-comments.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.delete-comments" />}>
            <Switch
              value={settings.ownerCanDeleteComments}
              onChange={() => onChange('comments', 'ownerCanDeleteComments')}
              message={settings.ownerCanDeleteComments ? 'domain-admin.settings.delete-comments.on' : 'domain-admin.settings.delete-comments.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.add-replies" />}>
            <Switch
              value={settings.canAddRepliesInStatusTodosRequested}
              onChange={() => onChange('comments', 'canAddRepliesInStatusTodosRequested')}
              message={settings.canAddRepliesInStatusTodosRequested ? 'domain-admin.settings.add-replies.on' : 'domain-admin.settings.add-replies.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.message-to-owners" />}>
            <Switch
              value={settings.messageToOwners}
              onChange={() => onChange('proofs', 'messageToOwners')}
              message={settings.messageToOwners ? 'domain-admin.settings.message-to-owners.on' : 'domain-admin.settings.message-to-owners.off'}
            />
          </FormControl>
          <ProfileDropdown
            label={<Translation value="profile.settings.gif-output.label" />}
            options={[
              { value: 'video', label: Translation.text('profile.settings.gif-output.video') },
              { value: 'html', label: Translation.text('profile.settings.gif-output.html') },
            ]}
            onClick={value => onChange('proofs', 'defaultGifOutput', value)}
            selected={settings.defaultGifOutput}
          />
          <FormControl label={<Translation value="domain-admin.settings.reference" />}>
            <Switch
              value={settings.reference}
              onChange={() => onChange('proofs', 'reference')}
              message={settings.reference ? 'domain-admin.settings.reference.on' : 'domain-admin.settings.reference.off'}
            />
            <Reveal
              align="bottom"
              visible={settings.reference}
            >
              <br />
              <Switch
                value={settings.referenceRequired}
                onChange={() => onChange('proofs', 'referenceRequired')}
                message={settings.referenceRequired ? 'domain-admin.settings.reference-required-on' : 'domain-admin.settings.reference-required-off'}
              />
            </Reveal>
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.approved-with-changes" />}>
            <Switch
              value={settings.canLeaveApprovedWithChanges}
              onChange={() => onChange('proofs', 'canLeaveApprovedWithChanges')}
              message={settings.canLeaveApprovedWithChanges ? 'domain-admin.settings.approved-with-changes.on' : 'domain-admin.settings.approved-with-changes.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.comment-visibility" />}>
            <Switch
              disabled={!settings.configureCommentVisibility}
              tooltip={settings.configureCommentVisibility ? null : <Translation value="domain-admin.settings.comment-visibility.license-required" />}
              value={settings.enableCommentVisibility}
              onChange={() => onChange('comments', 'enableCommentVisibility')}
              message={settings.enableCommentVisibility ? 'domain-admin.settings.comment-visibility.on' : 'domain-admin.settings.comment-visibility.off'}
            />
            <Reveal
              align="bottom"
              visible={settings.enableCommentVisibility}
            >
              <br />
              <Switch
                value={settings.defaultCommentVisibility}
                onChange={() => onChange('comments', 'defaultCommentVisibility')}
                message={settings.defaultCommentVisibility ? 'domain-admin.settings.default-comment-visibility.on' : 'domain-admin.settings.default-comment-visibility.off'}
              />
            </Reveal>
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.proof-magic-access" />}>
            <Switch
              value={settings.enableProofMagicAccess}
              onChange={() => onChange('proofs', 'enableProofMagicAccess')}
              message={settings.enableProofMagicAccess ? 'domain-admin.settings.proof-magic-access.on' : 'domain-admin.settings.proof-magic-access.off'}
            />
          </FormControl>
        </Tab>
        <Tab
          id="workflows"
          name={<Translation value="domain-admin.settings.subheading.workflows" />}
          onClick={() => setSelectedTab('workflows')}
        >
          <FormControl label={<Translation value="domain-admin.settings.view-only-reviewers" />}>
            <Switch
              disabled={!settings.configureViewOnlyReviewers}
              tooltip={settings.configureViewOnlyReviewers ? null : <Translation value="domain-admin.settings.view-only-reviewers.license-required" />}
              value={settings.enableViewOnlyReviewers}
              onChange={() => onChange('workflows', 'enableViewOnlyReviewers')}
              message={settings.enableViewOnlyReviewers ? 'domain-admin.settings.view-only-reviewers.on' : 'domain-admin.settings.view-only-reviewers.off'}
            />
          </FormControl>
        </Tab>
        <Tab
          id="emails"
          name={<Translation value="domain-admin.settings.subheading.emails" />}
          onClick={() => setSelectedTab('emails')}
        >
          <Subheading title={<Translation value="domain-admin.settings.email.heading" />} />
          <FormControl label={<Translation value="domain-admin.settings.displayTeamName" />}>
            <Switch
              value={settings.displayTeamName}
              onChange={() => onChange('emails', 'displayTeamName')}
              message={settings.displayTeamName ? 'domain-admin.settings.displayTeamName.on' : 'domain-admin.settings.displayTeamName.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.show-comments" />}>
            <Switch
              value={settings.displayComments}
              onChange={() => onChange('emails', 'displayComments')}
              message={settings.displayComments ? 'domain-admin.settings.show-comments.on' : 'domain-admin.settings.show-comments.off'}
            />
          </FormControl>
          <FormControl label={<Translation value="domain-admin.settings.notifications.owner-email" />}>
            <Switch
              value={notifications.ownerAddedByAdmin}
              onChange={() => onUpdateNotification('ownerAddedByAdmin')}
              message={notifications.ownerAddedByAdmin ? 'domain-admin.settings.notifications.owner-email.on' : 'domain-admin.settings.notifications.owner-email.off'}
            />
          </FormControl>
          <br />
          <TeamSenderEmail
            isFeatureAvailable={account.teamType === 2}
            email={settings.senderEmail}
            onSubmit={onUpdateSenderEmail}
          />
        </Tab>
      </Tabs>
    </Form>
  );
}

if (process.env.NODE_ENV !== 'production') {
  TeamSettings.propTypes = {
    account: PropTypes.shape({
      teamName: PropTypes.string,
      teamType: PropTypes.number,
      teamIsPlus: PropTypes.bool,
      teamIsTrial: PropTypes.bool,
    }),
    settings: PropTypes.shape({
      displayComments: PropTypes.bool,
      displayTeamName: PropTypes.bool,
      senderEmail: PropTypes.string,
      editComments: PropTypes.bool,
      ownerCanDeleteComments: PropTypes.bool,
      reference: PropTypes.bool,
      referenceRequired: PropTypes.bool,
      canAddRepliesInStatusTodosRequested: PropTypes.bool,
      messageToOwners: PropTypes.bool,
      publicProofs: PropTypes.bool,
      defaultProofIsPublic: PropTypes.bool,
      canLeaveApprovedWithChanges: PropTypes.bool,
      suggestionsIncludeTeamMembers: PropTypes.bool,
      enableSmartTags: PropTypes.bool,
      enableSmartCheckCr: PropTypes.bool,
      enableIntelligence: PropTypes.bool,
    }),
    onChange: PropTypes.func.isRequired,
    notifications: PropTypes.objectOf(PropTypes.bool),
    onUpdateNotification: PropTypes.func.isRequired,
    onUpdateSenderEmail: PropTypes.func.isRequired,
  };
}

export default TeamSettings;
