/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Translation from '../../Text/Translation';
import Comment from '../Comment';

function Reply({
  ...props
}) {
  return (
    <Comment
      {...props}
      placeholder={
        <Translation value="comment-reply-create.placeholder" />
      }
      isReply
    />
  );
}

export default Reply;
