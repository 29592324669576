/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'AgreeValentinesOutline',
  src: 'img/icons/agree-valentines-outline.svg',
  activeColor: '#e51c23',
  activeHoverColor: '#b9151b',
});
