/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export function scheduleBackgroundTask(getTask, overallTimeoutMs, ms = 2000) {
  let timeout;
  let isCancelled = false;
  const overallTimeout = overallTimeoutMs && setTimeout(() => {
    cancel();
  }, overallTimeoutMs);

  function cancel() {
    isCancelled = true;
    clearTimeout(timeout);
    clearTimeout(overallTimeout);
    window.removeEventListener('focus', onFocus);
  }

  function schedule() {
    if (!isCancelled) {
      clearTimeout(timeout);
      timeout = setTimeout(tick, ms);
    }
  }

  function tick() {
    if (document.hasFocus()) {
      const task = getTask();

      if (task) {
        task.catch(() => { }).then(schedule);
      }
    } else {
      schedule();
    }
  }

  function onFocus() {
    clearTimeout(timeout);
    tick();
  }

  window.addEventListener('focus', onFocus);

  schedule();
  return cancel;
}
