import React from 'react';
import { Flag } from '../../../components/Flags';
import CommentSpacing from '../../../components/Comment/CommentSpacing';

export default function SpacingWrapper({ commentPaneWidth, children }) {
  return (
    <Flag id="commentPaneSpacing">
      {(customSpacing) => {
        const spacing = customSpacing || (commentPaneWidth <= 420 ? 'condensed' : 'spacious');
        return (
          <CommentSpacing.Provider value={spacing}>
            {children}
          </CommentSpacing.Provider>
        );
      }}
    </Flag>
  );
}
