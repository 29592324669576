/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Enum } from '@pageproof/sdk';
import React from 'react';
import ReminderContainer from '../../containers/ReminderPopUp';
import { useUserPreferences } from '../../hooks/useUserPreferences';

const DefaultReminderPreference = () => {
  const [, userPreferences, { updateUserPreference }] = useUserPreferences();
  const {
    defaultValue: defaultReminders,
    value: reminders,
  } = (userPreferences && userPreferences.proofReminders) || {};
  // if 'reminders.value' is null or array(not empty), it means being turned on the 'Reminder on due date' on the preference page.

  const updateDefaultReminders = updatedReminders => updateUserPreference(Enum.Preference.DEFAULT_REMINDER, updatedReminders);

  return (
    <ReminderContainer
      variant="profile"
      isOwner
      inCreateProofProccess
      proof={{ reminders: (reminders || defaultReminders) }}
      timeLeft={100}
      passReminderValue={updateDefaultReminders}
    />
  );
};

export default DefaultReminderPreference;
