/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable react/prop-types */
import React, { Fragment, Component } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Checkbox from '../Checkbox';
import ButtonGroup from '../ButtonGroup';
import { Button } from '../Button';
import { ProfileDropdown } from '../Profile';
import { Heading, Subheading } from '../Page';
import { Translation, withTranslations } from '../Text';
import css from './PrintOptions.scss';
import { Spinner } from '../Spinner';
import Delay from '../Delay';
import Ellipsis from '../Text/Ellipsis';
import HelpBubble from '../HelpBubble';

const VERSION = {
  all: 'all-versions',
};

const LAYOUT = {
  hero: 'hero',
  inline: 'inline',
  condensed: 'condensed',
};

const LAYOUT_IMAGE_SRC = {
  hero: 'img/interface/hero.svg',
  inline: 'img/interface/inline.svg',
  condensed: 'img/interface/condensed.svg',
};

const GROUPING = {
  page: 'page',
  device: 'device',
};

const CONTENT = {
  all: 'all',
  todos: 'todos',
  none: 'none',
  proof: 'proof',
  comments: 'comments',
};

const PAGE = {
  A4: 'A4',
  letter: 'LETTER',
};

const isCommentContent = content => [CONTENT.all, CONTENT.todos, CONTENT.comments].includes(content);

class PrintOptions extends Component {
  state = {
    version: this.props.proofId,
    content: CONTENT.all,
    layout: LAYOUT.inline, // Inline is always a possible option, therefore default
    groupBy: GROUPING.page, // Page is always a possible option, therefore default
    generating: false,
    pageSize: this.props.defaultPageSize,
    includeAllPages: false,
    isAnonymized: false,
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps = (nextProps) => { // When the versions load, update state appropriately
    if (this.props.versions !== nextProps.versions && nextProps.versions !== null) {
      this.setState({ content: nextProps.versions.filter(version => version.id === nextProps.proofId)[0].content[0] });
    }
  }

  translationKeyForContentType = (type) => {
    const isVersionAll = this.state.version === VERSION.all;
    const isBrief = this.props.proofType === 2 && !isVersionAll;
    if (isVersionAll) {
      switch (type) {
      case CONTENT.all:
        return 'print-options.all-versions.proof-with-comments';
      case CONTENT.todos:
        return (isBrief ? 'print-options.all-versions.brief-todos' : 'print-options.all-versions.proof-todos');
      case CONTENT.none:
        return (isBrief ? 'print-options.all-versions.brief' : 'print-options.all-versions.proof');
      case CONTENT.proof:
        return (isBrief ? 'print-options.all-versions.brief-only' : 'print-options.all-versions.proof-only');
      case CONTENT.comments:
        return (isBrief ? 'print-options.all-versions.brief-comments' : 'print-options.all-versions.proof-comments');
      default: return '';
      }
    } else {
      switch (type) {
      case CONTENT.all:
        return 'print-options.proof-with-comments';
      case CONTENT.todos:
        return (isBrief ? 'print-options.brief-todos' : 'print-options.proof-todos');
      case CONTENT.none:
        return (isBrief ? 'print-options.brief' : 'print-options.proof');
      case CONTENT.proof:
        return (isBrief ? 'print-options.brief-only' : 'print-options.proof-only');
      case CONTENT.comments:
        return (isBrief ? 'print-options.brief-comments' : 'print-options.proof-comments');
      default: return '';
      }
    }
  }

  getVersion = (version) => {
    if (version === VERSION.all) {
      return {
        layout: [...new Set(this.props.versions.reduce((layouts, next) => layouts.concat(next.layout), []))],
        grouping: [...new Set(this.props.versions.reduce((groupings, next) => groupings.concat(next.grouping), []))],
        content: [CONTENT.all, ...new Set(this.props.versions.reduce((content, next) => content.concat(next.content), []))],
        id: VERSION.all,
      };
    }
    return this.props.versions.filter(v => v.id === version)[0];
  }

  selectedVersion = () => this.getVersion(this.state.version);

  contentDropDownOptions = () => {
    const version = this.selectedVersion();
    return version.content.map(type => ({ value: type, label: Translation.text(this.translationKeyForContentType(type)) }));
  }

  handleClickContent = (contentChosen) => {
    this.setState(prevState => ({
      content: contentChosen,
      isAnonymized: isCommentContent(contentChosen) && prevState.isAnonymized,
    }));
  }

  handleClickProofVersion = (version) => {
    const newState = { version };
    const { content } = this.getVersion(version);
    const [firstContentOption] = content;

    if (!content.includes(this.state.content)) {
      newState.content = firstContentOption;
    }

    this.setState(newState);
  }

  shouldShowVersionDropDown() {
    const { versions } = this.props;
    return versions.length > 1;
  }

  shouldShowGroupOptions() {
    const version = this.selectedVersion();
    return isCommentContent(this.state.content) && version.grouping.length > 1;
  }

  shouldShowLayoutOptions() {
    const version = this.selectedVersion();
    return isCommentContent(this.state.content) && version.layout.length > 1;
  }

  layoutOptions() {
    const version = this.selectedVersion();
    return version.layout.map(layoutType => (
      <Fragment key={layoutType}>
        <Checkbox
          selected={this.state.layout === layoutType}
          onChange={() => this.setState({ layout: layoutType })}
        />
        <div
          tabIndex={0}
          role="button"
          onClick={() => this.setState({ layout: layoutType })}
          onKeyDown={() => this.setState({ layout: layoutType })}
          className={css.PrintOptions__icons}
        >
          <InlineSVG
            className={css.PrintOptions__icons__image}
            src={LAYOUT_IMAGE_SRC[layoutType]}
          />
        </div>
      </Fragment>
    ));
  }

  shouldShowIncludeAllPagesOption() {
    const heroLayoutWithComments = this.state.layout === LAYOUT.hero && isCommentContent(this.state.content);
    return heroLayoutWithComments;
  }

  groupOptions() {
    const version = this.selectedVersion();
    const options = [this.renderPageOption()];
    if (version.grouping.indexOf(GROUPING.page) !== -1) {
      options.push(this.renderDeviceOption());
    }
    return options;
  }

  versionDropDownOptions() {
    const { versions } = this.props;
    const currentProof = versions.filter(v => v.id === this.props.proofId)[0];
    const config = versions.filter(v => v.id !== currentProof.id).map(v => ({ value: v.id, label: Translation.text('print-options.version', { number: v.number }) }));

    config.unshift({ value: VERSION.all, label: Translation.text('print-options.all-versions') });
    config.unshift({ value: currentProof.id, label: Translation.text('print-options.version', { number: currentProof.number }) });

    return config;
  }

  renderPageOption = () => (
    <Fragment key={GROUPING.page}>
      <Checkbox
        selected={this.state.groupBy === GROUPING.page}
        onChange={() => this.setState({ groupBy: GROUPING.page })}
      />
      <span className={css.PrintOptions__label}><Translation value="print-options.group-by.page" /></span>
    </Fragment>
  )

  renderDeviceOption = () => (
    <Fragment key={GROUPING.device}>
      <Checkbox
        selected={this.state.groupBy === GROUPING.device}
        onChange={() => this.setState({ groupBy: GROUPING.device })}
      />
      <span className={css.PrintOptions__label}><Translation value="print-options.group-by.device" /></span>
    </Fragment>
  )

  render() {
    const { version, content, pageSize } = this.state;

    if (this.props.isLoading) {
      return (
        <Delay ms={1000}>
          <div className={css.PrintOptions__loading}>
            <Spinner size={60} />
            <h3 className={css.PrintOptions__loading__message}>
              <Translation value="print-options.loading" />
            </h3>
          </div>
        </Delay>
      );
    }

    return (
      <Fragment>
        <Heading
          title={<Translation value="print-options.title" />}
          description={<Translation value="print-options.subtitle" />}
          compact
        />

        {this.shouldShowVersionDropDown() &&
          <ProfileDropdown
            options={this.versionDropDownOptions()}
            placeholder={this.versionDropDownOptions()[0].label}
            onClick={this.handleClickProofVersion}
            selected={version}
          />
        }

        <ProfileDropdown
          options={this.contentDropDownOptions()}
          placeholder={this.contentDropDownOptions()[0].label}
          onClick={this.handleClickContent}
          selected={content}
        />

        {this.shouldShowLayoutOptions() &&
          <Fragment>
            <Subheading
              title={<Translation value="print-options.layout" />}
            />
            <div
              className={css.PrintOptions__horizontalBox}
            >
              {this.layoutOptions()}
            </div>

            {this.shouldShowIncludeAllPagesOption() &&
              <div className={css.PrintOptions__allPagesOption}>
                <Checkbox
                  selected={this.state.includeAllPages}
                  onChange={(value) => { this.setState({ includeAllPages: value }); }
                  }
                />
                <span className={css.PrintOptions__label}>
                  <Translation value="print-options.include-all-pages" />
                </span>
              </div>
            }
          </Fragment>
        }

        {this.shouldShowGroupOptions() &&
          <Fragment>
            <Subheading
              title={<Translation value="print-options.group-by" />}
            />
            <div
              className={css.PrintOptions__horizontalBox}
            >
              {this.groupOptions()}
            </div>
          </Fragment>
        }

        {isCommentContent(this.state.content) &&
          <Fragment>
            <Subheading
              title={<Translation value="print-options.comments" />}
            />
            <div className={css.PrintOptions__horizontalBox}>
              <Checkbox
                selected={this.state.isAnonymized}
                onChange={() => this.setState(prevState => ({ isAnonymized: !prevState.isAnonymized }))}
              />
              <span className={css.PrintOptions__label}>
                <Translation value="print-options.comments.anonymize" />
                <HelpBubble
                  className={css.PrintOptions__helpBubble}
                  size="small"
                  message={<Translation value="print-options.comments.anonymize.tooltip" />}
                  url="https://help.pageproof.com/en/articles/6057720-generating-an-anonymous-print-sheet-of-comments"
                />
              </span>
            </div>
          </Fragment>
        }

        <Fragment>
          <Subheading
            title={<Translation value="print-options.page-size" />}
          />
          <div className={css.PrintOptions__horizontalBox}>
            <Checkbox
              selected={pageSize === PAGE.letter}
              onChange={() => this.setState({ pageSize: PAGE.letter })}
            />
            <span className={css.PrintOptions__label}><Translation value="print-options.page-size.letter" /></span>

            <Checkbox
              selected={pageSize === PAGE.A4}
              onChange={() => this.setState({ pageSize: PAGE.A4 })}
            />
            <span className={css.PrintOptions__label}><Translation value="print-options.page-size.a4" /></span>
          </div>
        </Fragment>

        <div className={css.PrintOptions__buttonGroup}>
          <ButtonGroup>
            <Button
              variant="text"
              label={<Translation value="print-options.cancel" />}
              onClick={() => this.props.onCancel()}
            />
            <Button
              variant="primary"
              label={
                <Fragment>
                  <Translation value={this.state.generating ? 'print-options.generating' : 'print-options.generate'} />
                  {this.state.generating && <Ellipsis />}
                </Fragment>
              }
              onClick={() => {
                this.setState({ generating: true });
                this.props.onGenerate(this.state);
              }}
            />
          </ButtonGroup>
        </div>
      </Fragment>
    );
  }
}

export default withTranslations(PrintOptions);
