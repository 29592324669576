/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useRef, useState, useMemo } from 'react';
import classname from 'classname';
import Tooltip from '../Tooltip';
import css from './RangeInput.scss';

export const RangeInput = (props) => {
  const {
    value,
    onChange,
    onChanged,
    min = 0,
    max = 100,
    step,
    tooltip,
  } = props;

  const trackRef = useRef();
  const [tempValue, setTempValue] = useState(null);

  const startDrag = (startEvent) => {
    startEvent.preventDefault();
    onMove(startEvent);

    function onMove(event) {
      const rect = trackRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      let percent = Math.min(1, Math.max(0, x / rect.width));
      if (step) {
        percent = Math.round(percent * ((max - min) / step)) / ((max - min) / step);
      }
      const newValue = (percent * (max - min)) + min;
      setTempValue((previousValue) => {
        if (previousValue !== newValue) {
          if (onChange) onChange(newValue);
        }
        return newValue;
      });
    }

    function onUp() {
      setTempValue((newValue) => {
        if (onChange) onChange(newValue);
        if (onChanged) onChanged(newValue);
        return null;
      });

      window.removeEventListener('pointermove', onMove);
      window.removeEventListener('pointerup', onUp);
    }

    window.addEventListener('pointermove', onMove);
    window.addEventListener('pointerup', onUp);
  };

  const currentValue = tempValue !== null ? tempValue : value;
  const percent = ((currentValue - min) / (max - min)) * 100;

  const stepCount = step ? ((max - min) / step) + 1 : 0;
  const stepPoints = useMemo(() => (
    stepCount < 50
      ? Array.from({ length: stepCount }).map((_, index) => (
        <div
          key={index}
          className={css.RangeInput__track__steps__point}
        />
      ))
      : []
  ), [stepCount]);

  return (
    <div
      className={classname(css.RangeInput, {
        [css['RangeInput--active']]: tempValue !== null,
      })}
      style={{
        '--RangeInput-progress': percent + '%',
      }}
      onPointerDown={startDrag}
    >
      <div
        ref={trackRef}
        className={css.RangeInput__track}
      >
        <div className={css.RangeInput__track__steps}>
          {stepPoints}
        </div>
        <div className={css.RangeInput__track__progress} />
      </div>
      <div className={css.RangeInput__buttonBounds}>
        <Tooltip
          up
          center
          arrow
          offset={14}
          title={() => (
            <div style={{ fontSize: 14 }}>
              {typeof tooltip === 'function'
                ? tooltip(currentValue)
                : currentValue}
            </div>
          )}
          {...(tempValue !== null
            ? { visible: !!tooltip }
            : {})}
          // visible={!!tooltip && tempValue !== null}
          delay={0}
        >
          <div className={css.RangeInput__buttonBounds__button} />
        </Tooltip>
      </div>
    </div>
  );
};
