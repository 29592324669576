/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable consistent-return */

import React, { useEffect, useState } from 'react';
import { sdk } from '../../util/sdk';
import { loadImage } from './utils';
import { DEFAULT_SPRITE_HEIGHT, DEFAULT_SPRITE_WIDTH } from '../../util/constants';

function downloadAndDecrypt(fileId) {
  return sdk.files.preview(fileId, 1, 'ss')
    .then((blob) => {
      console.log({ fileId, blob });
      return blob;
    });
}

export const EncryptedSpriteThumbnail = ({ fileId, pageNumber, scale = 1, fallback = null, imageProps = {} }) => {
  const [imageUrl, setImageUrl] = useState(null);

  const cacheKey = `sprite file:${fileId}`;

  useEffect(() => {
    const previousImageUrl = imageUrl;
    return loadImage(cacheKey, () => downloadAndDecrypt(fileId), setImageUrl, previousImageUrl);
  }, [cacheKey, fileId]);

  if (!imageUrl) {
    return fallback;
  }

  const pagePosition = window.getSpriteSheetPosition(pageNumber, 10);
  const width = ((imageProps.style && imageProps.style.width) || DEFAULT_SPRITE_WIDTH) * scale;
  const height = ((imageProps.style && imageProps.style.height) || DEFAULT_SPRITE_HEIGHT) * scale;
  const backgroundSize = width * 10;

  return (
    <div
      {...imageProps}
      style={{
        width,
        height,
        ...imageProps.style,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          width,
          height,
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: `-${pagePosition.x * width}px -${pagePosition.y * height}px`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: `${backgroundSize}px`,
        }}
      />
    </div>
  );
};
