/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* disabling es-lint indent because of bad switch rule */
/* eslint-disable indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from '../../components/Switch';
import css from './IntegrationOptionsContainer.scss';
import getIntegrationFileUrl from '../../util/integration-service-static-files';
import Reveal from '../../components/Reveal';
import useDarkMode from '../../hooks/useDarkMode';
import { OAuthInput } from './inputs/OAuthInput';
import { InputWrapper } from './InputWrapper';
import useOnMessageEventListener from '../../hooks/useOnMessageEventListener';
import useIntegrationsClientRef from '../../hooks/useIntegrationsClientRef';
import { TextInput } from './inputs/TextInput';
import { DropdownInput } from './inputs/DropdownInput';
import { Translation } from '../../components/Text';
import { BooleanInput } from './inputs/BooleanInput';
import { ItemListInput } from './inputs/ItemListInput';

function OptionInput(props) {
  switch (props.option.type) {
    case 'oauth':
      return <OAuthInput {...props} />;
    case 'text':
      return <TextInput {...props} />;
    case 'dropdown':
      return <DropdownInput {...props} />;
    case 'boolean':
      return <BooleanInput {...props} />;
    case 'itemList':
      return <ItemListInput {...props} />;
    default:
      return null;
  }
}

function IntegrationOptionsContainer({ integration, level, onIntegrationConfigurationUpdate, onCancel }) {
  const [isDarkMode] = useDarkMode();
  const { configuration, label: integrationLabel, integrationKey } = integration;
  const [fieldsBeingUpdated, setFieldsBeingUpdated] = useState([]);
  // The var not being used is indicated by _
  // eslint-disable-next-line no-unused-vars
  const [_, integrationsClientRef] = useIntegrationsClientRef(level);

  const removeFieldsFromBeingUpdated = (fields) => {
    setFieldsBeingUpdated(fieldsBeingUpdated.filter(field => !fields.includes(field)));
  };

  const reloadOptions = (fields) => {
    if (!fields.length) {
      return;
    }

    integrationsClientRef.current.configurations([integrationKey])
      .then(([{ configuration: refreshedConfiguration }]) => {
        const options = { ...refreshedConfiguration.options };

        Object.entries(options).forEach(([key, value]) => {
          // If we hadn't disabled the field, we shouldn't overwrite the current value
          if (!fields.includes(key)) {
            options[key] = configuration.options[key] || value;
          }
        });

        const updatedConfiguration = {
          ...refreshedConfiguration,
          options,
        };

        onIntegrationConfigurationUpdate(integrationKey, updatedConfiguration);
        removeFieldsFromBeingUpdated(fields);
      });
  };

  const onMessageReceived = (event) => {
    const message = event.data;
    if (message.integrationKey === integrationKey && message.success && message.enabled) {
      const optionsToRefresh = [];
      Object.values(configuration.options).forEach((option) => {
        if (option.type === 'oauth') {
          optionsToRefresh.push(option.key);
          optionsToRefresh.push(...(option.optionsAffectedByUpdate || []));
        }
      });

      setFieldsBeingUpdated([...fieldsBeingUpdated, ...optionsToRefresh]);
      reloadOptions(optionsToRefresh);
    }
  };
  useOnMessageEventListener(onMessageReceived);

  const handleConfigurationOptionInput = (key, value, tempValue) => {
    const option = configuration.options[key];

    // Don't update the value if the field is already by updated or the value has not changed
    if (fieldsBeingUpdated.includes(key) || JSON.stringify(value) === JSON.stringify(option.value)) {
      return;
    }


    const updatedOptions = {
      ...configuration.options,
      [key]: {
        ...configuration.options[key],
        value: tempValue || value, // Display temp value until request is completed
      },
    };

    onIntegrationConfigurationUpdate(integrationKey, {
      ...configuration,
      options: updatedOptions,
    });

    const fieldsToRefresh = option.optionsAffectedByUpdate || [];
    const updatedFieldsBeingUpdated = [...fieldsBeingUpdated, ...fieldsToRefresh];

    if (!fieldsToRefresh.includes(key)) {
      updatedFieldsBeingUpdated.push(key);
    }

    setFieldsBeingUpdated(updatedFieldsBeingUpdated);

    integrationsClientRef.current.updateConfiguration(integrationKey, key, value)
      .then(() => {
        if (!fieldsToRefresh.includes(key)) {
          removeFieldsFromBeingUpdated([key]);
        }
        reloadOptions(fieldsToRefresh);
      });
  };

  const updateEnabled = (value) => {
    const key = 'enabled';
    if (fieldsBeingUpdated.includes(key)) {
      return;
    }

    onIntegrationConfigurationUpdate(integrationKey, { ...configuration, enabled: value });
    setFieldsBeingUpdated([...fieldsBeingUpdated, key]);
    integrationsClientRef.current.updateConfiguration(integrationKey, key, value)
      .then(() => {
        setFieldsBeingUpdated([...fieldsBeingUpdated.filter(field => field !== key)]);
        if (!value && configuration.enableType !== 'direct') {
          onCancel();
        }
      });
  };

  return (
    <div className={css.OptionsContainer}>
      <img
        className={css.OptionsContainer__logo}
        alt={integrationLabel}
        src={getIntegrationFileUrl('logo-horizontal', integrationKey, isDarkMode ? ['dark'] : undefined)}
      />
      {configuration.warning &&
        <div className={css.OptionsContainer__warning}>
          {configuration.warning}
        </div>
      }
      <InputWrapper
        fieldOutsideLabel
        label={<Translation value="domain-admin.integrations.config.enabled" />}
        isUpdating={fieldsBeingUpdated.includes('enabled')}
      >
        <Switch
          value={configuration.enabled}
          onChange={() => {
            updateEnabled(!configuration.enabled);
          }}
        />
      </InputWrapper>

      <Reveal visible={configuration.enabled}>
        {Object.values(configuration.options).map((option) => {
          const isUpdating = fieldsBeingUpdated.includes(option.key);

          return (
            <InputWrapper
              key={option.key}
              fieldOutsideLabel
              label={option.label}
              tooltip={option.tooltip}
              tooltipUrl={option.tooltipUrl}
              isUpdating={isUpdating}
            >
              <OptionInput
                integrationsClient={integrationsClientRef}
                onConfigurationOptionInput={handleConfigurationOptionInput}
                integrationKey={integrationKey}
                integrationLabel={integrationLabel}
                option={option}
                isUpdating={isUpdating}
              />
            </InputWrapper>
          );
        })}
      </Reveal>
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  IntegrationOptionsContainer.propTypes = {
    integration: PropTypes.objectOf(PropTypes.any).isRequired,
    level: PropTypes.string.isRequired,
    onIntegrationConfigurationUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };
}

export default IntegrationOptionsContainer;
