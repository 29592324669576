/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import MarkDone from '../Comment/Icon/MarkDone';
import MarkTodo from '../Comment/Icon/MarkTodo';
import MarkUnmarked from '../Comment/Icon/MarkUnmarked';
import css from './CommentStatusCountBar.scss';
import { Translation } from '../Text';
import HorizontalDelimiter from '../HorizontalDelimiter';
import Tooltip from '../Tooltip';

const CommentIcon = ({ commentCount, iconType: IconType, size, showTooltip, title }) => (
  <Tooltip
    up
    center
    title={<Translation value={title} />}
    disabled={!showTooltip}
    offset={10}
  >
    <IconType
      label={commentCount}
      readOnly
      size={size === 'small' ? 15 : 20}
      active
    />
  </Tooltip>
);

const CommentStatusCountBar = ({
  todos,
  dones,
  notMarked,
  size,
  showTooltip,
  showTotal,
  hasMargin,
}) => {
  const total = todos + dones + notMarked;
  return (
    <div className={css.CommentStatus}>
      <div
        className={classname(css.CommentStatus__bar, {
          [css['CommentStatus__bar--small']]: size === 'small',
          [css['CommentStatus__bar--hasMargin']]: hasMargin,
        })}
      >
        <HorizontalDelimiter
          delimiter={<div className={css.CommentStatus__delimiter} />}
        >
          {showTotal && (
            <span className={css.CommentStatus__bar__total}>
              {total > 1 && <Translation value="comments.count.other" params={{ count: total }} />}
              {total === 1 && <Translation value="comments.count.one" params={{ count: total }} />}
              {total === 0 && <Translation value="comments.count.zero" />}
            </span>
          )}
          {notMarked > 0 && (
            <CommentIcon
              commentCount={notMarked}
              iconType={MarkUnmarked}
              size={size}
              showTooltip={showTooltip}
              title="comments-header.unmarked.tooltip"
            />
          )}
          {todos > 0 && (
            <CommentIcon
              commentCount={todos}
              iconType={MarkTodo}
              size={size}
              showTooltip={showTooltip}
              title="comments-header.todo.tooltip"
            />
          )}
          {dones > 0 && (
            <CommentIcon
              commentCount={dones}
              iconType={MarkDone}
              size={size}
              showTooltip={showTooltip}
              title="comments-header.done.tooltip"
            />
          )}
        </HorizontalDelimiter>
      </div>
    </div>
  );
};

CommentStatusCountBar.defaultProps = {
  showTooltip: false,
  showTotal: false,
  hasMargin: true,
};

if (process.env.NODE_ENV !== 'production') {
  CommentIcon.propTypes = {
    commentCount: PropTypes.number.isRequired,
    iconType: PropTypes.func.isRequired,
    size: PropTypes.oneOf([
      'small',
    ]),
    showTooltip: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  };

  CommentStatusCountBar.propTypes = {
    todos: PropTypes.number.isRequired,
    dones: PropTypes.number.isRequired,
    notMarked: PropTypes.number.isRequired,
    size: PropTypes.oneOf([
      'small',
    ]),
    showTooltip: PropTypes.bool,
    showTotal: PropTypes.bool,
    hasMargin: PropTypes.bool,
  };
}

export default CommentStatusCountBar;
