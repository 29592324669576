/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {Component} from 'react';
import {createTarget} from './Target';

class Hover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };

    this.Target = createTarget(this);
  }

  render() {
    return this.props.children({
      Target: this.Target,
      isActive: this.state.isActive,
    });
  }
}

export default Hover;
