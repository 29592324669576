/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useRef, useState } from 'react';
import Tooltip from '../Tooltip';
import { VideoPlayerBuffer } from './VideoPlayerBuffer';
import { drag } from './utils/drag';
import css from './VideoPlayerScrubber.scss';
import { VideoPlayerTimeline } from './VideoPlayerTimeline';
import { useHighPrecisionCurrentTime } from './utils/useHighPrecisionCurrentTime';

function hasElementParent(element, parent) {
  let cursor = element;
  while (cursor) {
    if (cursor === parent) {
      return true;
    }
    cursor = cursor.parentElement;
  }
  return false;
}

function getPointerPercent(rootElement, event) {
  const rect = rootElement.getBoundingClientRect();
  return Math.min(1, Math.max(0, (event.pageX - rect.left) / rect.width));
}

export const VideoPlayerScrubber = ({
  isPlaying,
  currentTime: throttledCurrentTime,
  getCurrentTime,
  durationTime,
  buffer,
  timePresentation,
  scrub,
  scrubEnd,
  comments,
  getPinColor,
  canUpdateComment,
  selectedCommentId,
  selectComment,
  updatePin,
}) => {
  const rootRef = useRef();
  const timelineRef = useRef();

  const [hoverPercent, setHoverPercent] = useState(-1);
  const isScrubbingRef = useRef(false);

  const [currentTime, setCurrentTime] = useHighPrecisionCurrentTime({
    isPlaying,
    currentTime: throttledCurrentTime,
    getCurrentTime,
  });

  return (
    // eslint-disable-next-line
    <div
      ref={rootRef}
      className={css.VideoPlayerScrubber}
      onPointerMove={(event) => {
        const percent = getPointerPercent(rootRef.current, event);

        if (event.target !== timelineRef.current && hasElementParent(event.target, timelineRef.current)) {
          setHoverPercent(-1);
          return;
        }

        setHoverPercent(percent);
      }}
      onPointerLeave={() => {
        if (!isScrubbingRef.current) {
          setHoverPercent(-1);
        }
      }}
      onPointerDown={(event) => {
        event.preventDefault();

        if (event.button !== 0) {
          return;
        }

        drag({
          startEvent: event,
          targetElement: rootRef.current,
          containerElement: rootRef.current,
          onMove: ({ pointer }) => {
            isScrubbingRef.current = true;
            setHoverPercent(pointer.x.percent);
            scrub(pointer.x.percent * durationTime, true);
            setCurrentTime(pointer.x.percent * durationTime);
          },
          onEnd: () => {
            isScrubbingRef.current = false;
            scrubEnd();
          },
          cursor: 'grabbing',
        });
      }}
      onClick={() => {
        if (hoverPercent !== -1) {
          scrub(durationTime * hoverPercent);
        }
      }}
    >
      <div className={css.VideoPlayerScrubber__header}>
        <VideoPlayerBuffer buffer={buffer} />
        <div
          style={{ width: `${Math.min(1, (currentTime / durationTime)) * 100}%` }}
          className={css.VideoPlayerScrubber__currentTime}
        />
      </div>
      <div className={css.VideoPlayerScrubber__timeline} ref={timelineRef}>
        <VideoPlayerTimeline
          currentTime={currentTime}
          durationTime={durationTime}
          comments={comments}
          timePresentation={timePresentation}
          getPinColor={getPinColor}
          canUpdateComment={canUpdateComment}
          scrub={scrub}
          scrubEnd={scrubEnd}
          selectedCommentId={selectedCommentId}
          selectComment={selectComment}
          updatePin={updatePin}
          rootRef={rootRef}
        />
      </div>
      {hoverPercent !== -1 && (
        <Tooltip
          visible
          title={() => (
            <div style={{ fontVariantNumeric: 'tabular-nums' }}>
              {timePresentation.fn(durationTime * hoverPercent, durationTime)}
            </div>
          )}
          up
          center
          delay={0}
        >
          <div
            style={{ left: `calc(${hoverPercent * 100}% - 1px)` }}
            className={css.VideoPlayerScrubber__hoverIndicator}
          />
        </Tooltip>
      )}
    </div>
  );
};
