import React from 'react';
import { useLocalStorage } from '../../LocalStorage';
import ProgressCircle from '../../ProgressCircle';

const UploadProgressCircle = ({ id, size = 25, trackColor, progressColor }) => {
  const [{ percent }] = useLocalStorage('pageproof.app.upload-progress.' + id, { percent: 0 });

  return (
    <ProgressCircle
      size={size}
      width={Math.ceil(size * 0.12)}
      trackColor={trackColor || 'rgba(0, 0, 0, .08)'}
      color={progressColor}
      value={percent}
    />
  );
};


export default UploadProgressCircle;
