/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// Source: https://github.com/grrowl/react-keyed-flatten-children/blob/master/index.ts
import {
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import fragmentSymbol from './fragment-symbol';

export default function getChildrenArray(
  children,
  keys = []
) {
  return Children.toArray(children).reduce(
    (acc, node, nodeIndex) => {
      if (node && node.type === fragmentSymbol) {
        acc.push(...getChildrenArray(
          node.props.children,
          keys.concat(node.key || nodeIndex)
        ));
      } else if (isValidElement(node)) {
        acc.push(
          cloneElement(node, {
            key: keys.concat(String(node.key)).join('.'),
          })
        );
      } else if (typeof node === 'string' || typeof node === 'number') {
        acc.push(node);
      }
      return acc;
    },
    []
  );
}
