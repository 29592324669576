/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sdk } from '../../util/sdk';

class FileThumbnail extends Component {
  static propTypes = {
    delay: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    delay: 0,
    width: 100,
    height: 100,
  };

  state = {
    url: null,
    loaded: false,
  };

  componentDidMount() {
    this.load(this.props.id);
  }

  componentWillUnmount() {
    if (this.state.loaded) {
      URL.revokeObjectURL(this.state.url);
    }
  }

  load(fileId) {
    setTimeout(() => {
      sdk.files.thumbnail(fileId)
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          window.generalfunctions_preloadImage(url, (err) => {
            if (err) {
              return;
            }
            this.setState({
              url,
              loaded: true,
            }, () => {
              if (this.props.onLoad) {
                this.props.onLoad();
              }
            });
          });
        });
    }, this.props.delay);
  }

  render() {
    const { onClick } = this.props;
    const style = {
      width: this.props.width,
      height: this.props.height,
      backgroundImage: 'none',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    };
    if (!this.state.loaded) {
      return <div
        role="presentation"
        style={style}
        onClick={onClick}
      />;
    }
    /* eslint-disable jsx-a11y/alt-text */
    return (
      <div
        role="presentation"
        onClick={onClick}
        style={{
          ...style,
          backgroundImage: `url(${this.state.url})`,
        }}
      />
    );
  }
}

export default FileThumbnail;
