/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Option from './Option';
import getChildrenArray from '../../util/children-arrays';

let current = null;

document.body.addEventListener('click', (event) => {
  if (current && !event.composedPath().some(isDropdownElement)) {
    current();
  }
});

function isDropdownElement(element) {
  return (
    element &&
    element.nodeType === 1 &&
    typeof element.className === 'string' &&
    element.className.indexOf('Dropdown') !== -1
  );
}

class Dropdown extends Component {
  state = {
    open: false,
  };

  getSelectedOption() {
    const { children, selected } = this.props;
    return Children.toArray(children).filter(child => (
      child &&
        child.type === Option &&
        child.props.value === selected
    ))[0];
  }

  getLabel() {
    const selected = this.getSelectedOption();
    if (selected) {
      return cloneElement(selected, { onClick: null });
    } else {
      return this.props.placeholder;
    }
  }

  getOptions() {
    return getChildrenArray(this.props.children).map((child) => {
      if (child && child.type === Option) {
        const option = child;
        const additionalProps = { wrap: this.props.wrap };
        if (this.isSelectedValue(option.props.value)) {
          // Give the option the selected prop
          additionalProps.selected = true;
        }
        const { onClick } = option.props;
        additionalProps.onClick = (...args) => {
          // Intercept the onClick handler to automatically close the dropdown
          this.close();
          if (onClick) onClick(...args);
        };
        return cloneElement(option, additionalProps);
      }
      return child;
    });
  }

  open = () => {
    if (current) {
      current();
    }
    this.setState({ open: true });
    current = this.close;
    document.documentElement.classList.add('dropdown-is-open');
  }

  close = () => {
    this.setState({ open: false });
    if (current === this.close) {
      current = null;
    }
    document.documentElement.classList.remove('dropdown-is-open');
  }

  toggle = () => {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  onKeyDown = (event) => {
    const { keyDownScroll, regex } = this.props;
    if (keyDownScroll) {
      event.preventDefault();
      const key = String.fromCharCode(event.keyCode).toLowerCase();
      const filter = this.getOptions().filter(option => key === option.props.label.props.title[0].toLowerCase());
      const firstItem = filter[0];
      if (filter.length && firstItem.props.name) {
        const element = document.querySelector(`.DropdownOption--${firstItem.props.name.replace(regex, '')}`);
        const optionElements = document.getElementsByClassName('DropdownOption');
        if (element) {
          // Remove 'hover' styling from previously scrolled to element
          for (let i = 0; i < optionElements.length; i += 1) {
            if (optionElements[i].classList.contains('hover')) {
              optionElements[i].classList.remove('hover');
              break;
            }
          }
          element.scrollIntoView({ behavior: 'smooth' });
          // Apply 'hover' styling to the first item
          element.classList.add('hover');
        }
      }
    }
  }

  isSelectedValue(value) {
    const selectedOption = this.getSelectedOption();
    if (!selectedOption) {
      return false;
    }
    return selectedOption.props.value === value;
  }

  render() {
    const { open } = this.state;
    const {
      direction,
      classNames,
      variant,
      target,
      wrap,
    } = this.props;

    return (
      <div
        className={classname(
          classNames && classNames.dropdown,
          'Dropdown',
          `Dropdown--${direction}`,
          `Dropdown--${variant}`,
          {
            'Dropdown--open': open,
            'Dropdown--nowrap': !wrap,
          },
        )}
      >
        {target
          ? cloneElement(target, {
            onClick: this.toggle,
          })
          : (
            <div
              className="Dropdown__label"
              onClick={this.toggle}
              onKeyDown={this.onKeyDown}
            >
              {this.getLabel()}
              <div className="Dropdown__arrow" />
            </div>
          )
        }
        {open &&
          <div
            className={classname(
              classNames && classNames.options,
              'Dropdown__options',
            )}
          >
            {this.getOptions()}
          </div>}
      </div>
    );
  }
}

Dropdown.defaultProps = {
  direction: 'down',
  variant: 'default',
  classNames: {},
};

Dropdown.propTypes = {
  selected: PropTypes.any, // eslint-disable-line
  placeholder: PropTypes.element,
  direction: PropTypes.oneOf([
    'down',
    'up',
  ]),
  variant: PropTypes.oneOf([
    'light',
    'default',
    'icon-based',
    'video-quality',
    'tool',
    'comment-options',
  ]),
  classNames: PropTypes.objectOf(PropTypes.string),
  keyDownScroll: PropTypes.bool,
  wrap: PropTypes.bool,
};

export default Dropdown;
