import React from 'react';
import css from './CRPopover.scss';

const CRPopover = ({ crData }) => {
  // Create an array of keys to check
  const keys = [
    { label: 'Insights', value: crData.insights },
    { label: 'Issued by', value: crData.issuer },
    { label: 'Author', value: crData.author },
    { label: 'Creator', value: crData.creator },
    { label: 'Copyright', value: crData.copyrightNotice },
    { label: 'Software', value: crData.softwareAgent },
    { label: 'Generator', value: crData.claimGenerator },
    { label: 'Status', value: crData.validationStatus },
  ];

  // Filter the keys to get only valid items
  const validKeys = keys.filter(item => item.value !== null && item.value !== '');

  return (
    <div className={css.CRPopover}>
      {validKeys.map((item, index) => {
        // Check if it is the last valid item in the list
        const isLastItem = index === validKeys.length - 1;

        return (
          <div style={{ paddingBottom: isLastItem ? '0' : '8px' }} key={item.label}>
            <div>{item.label}</div>
            <span>{item.value}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CRPopover;
