/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import truncateStringWithMiddleEllipsis from '../../util/truncateStringWithMiddleEllipsis';
import css from './TruncateStringWithMiddleEllipsis.scss';
import useWatchRect from '../../hooks/useWatchRect';

function TruncateStringWithMiddleEllipsis({ children }) {
  const textElementRef = useRef(null);
  const [truncatedText, setTruncText] = useState([]);
  const [leftText, hiddenText, ellipsis, rightText] = truncatedText;
  const elementWidth = textElementRef.current && textElementRef.current.offsetWidth;
  useWatchRect(textElementRef);

  useEffect(() => {
    const text = truncateStringWithMiddleEllipsis(children, textElementRef.current);
    setTruncText(text);
  }, [elementWidth]);

  return (
    <Tooltip
      up
      center
      maxWidth={false}
      title={children}
      disabled={!hiddenText}
    >
      <div
        ref={textElementRef}
        className={css.TruncateStringWithMiddleEllipsis}
      >
        {truncatedText.length < 2
          ? (<span>{truncatedText && leftText}</span>)
          : (
            <React.Fragment>
              <span>{leftText}</span>
              <span className={css.TruncateStringWithMiddleEllipsis__hiddenText}>{hiddenText}</span>
              <span className={css.TruncateStringWithMiddleEllipsis__ellipsis}>{ellipsis}</span>
              <span>{rightText}</span>
            </React.Fragment>
          )
        }
      </div>
    </Tooltip>
  );
}

if (process.env.NODE_ENV !== 'production') {
  TruncateStringWithMiddleEllipsis.propTypes = {
    children: PropTypes.string,
  };
}

export default TruncateStringWithMiddleEllipsis;
