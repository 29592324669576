/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable arrow-body-style, jsx-a11y/mouse-events-have-key-events */

import React, { useState, useRef, useEffect, Fragment } from 'react';
import { loadEncryptedColorSeparation } from '../Encrypted/EncryptedColorSeparation';
import Tooltip from '../Tooltip';
import HexColor from './HexColor';
import { Flex } from '../Flex';
import { create as createLegacyDriver } from './coverage/legacy';
import { create as createWorkerDriver } from './coverage/worker';
import css from './PlateCoverageLayer.scss';

function doesSupportWorkerDriver() {
  if (typeof window.OffscreenCanvas === 'undefined' || typeof window.createImageBitmap === 'undefined') {
    return false;
  }

  if (window.__pageproof_bridge__.browserService.is('safari')) {
    const [majorVersion, minorVersion] = window.__pageproof_bridge__.browserService.version.split('.');
    const version = parseFloat(`${majorVersion}.${minorVersion}`);
    return version >= 16.5; // Tested on 16.5 and above
  }

  return true;
}

function PlateCoverageLayer({
  proofId,
  colorSeparations,
  pageNumber,
  selectedPlates: _selectedPlates,
  customColors,
  pagePlates,
  isDragging,
}) {
  const selectedPlates = _selectedPlates.length
    ? _selectedPlates
    : pagePlates.map(plate => plate.name);

  const [position, setPosition] = useState(null);

  const driverRef = useRef();
  const [coverage, setCoverage] = useState(null);

  const [forceLoadCoverage, setForceLoadCoverage] = useState({});
  useEffect(() => {
    if (!driverRef.current || !position) {
      return;
    }

    driverRef.current.ops
      .getCoverage(selectedPlates, position.top, position.left)
      .then(setCoverage);
  }, [
    forceLoadCoverage,
    selectedPlates.join(', '),
    position ? position.top : null,
    position ? position.left : null,
  ]);

  useEffect(() => {
    const driver = doesSupportWorkerDriver()
      ? createWorkerDriver()
      : createLegacyDriver();
    driverRef.current = driver;

    const cleanups = [];
    pagePlates.forEach((plate) => {
      const { promise, destroy } = loadEncryptedColorSeparation({
        proofId,
        colorSeparations,
        pageNumber,
        plateName: plate.name,
      });
      cleanups.push(destroy);
      promise.then(url => (
        driver.ops.loadPlate(plate.name, url)
      )).then(() => {
        setForceLoadCoverage({});
      });
    });

    return () => {
      driver.destroy();
      cleanups.forEach(cleanup => cleanup());
    };
  }, []);

  return (
    <div
      className={css.PlateCoverageLayer}
      onMouseMove={(event) => {
        const rect = event.target.getBoundingClientRect();
        setPosition({
          top: Math.min(1, Math.max(0, (event.clientY - rect.top) / rect.height)),
          left: Math.min(1, Math.max(0, (event.clientX - rect.left) / rect.width)),
        });
      }}
      onMouseLeave={() => {
        setPosition(null);
      }}
    >
      <Tooltip
        disablePointerEvents
        visible={!!(position && coverage && !isDragging)}
        arrow
        offset={26}
        down
        center
        delay={0}
        title={() => (
          <Flex container direction="vertical" gap={6} style={{ fontSize: 14 }}>
            {Object.entries(coverage.plates).map(([plateName, percent]) => (
              <Flex key={plateName} container alignItems="center" justifyContent="center" direction="horizontal">
                <div style={{ marginRight: 6 }}>
                  <PlateColorIcon
                    plateName={plateName}
                    customColors={customColors}
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  {plateName}
                </div>
                <div style={{ width: 60, textAlign: 'right' }}>
                  <PlateCoverageValue
                    percent={percent}
                    truncateZero={selectedPlates.length > 1}
                  />
                </div>
              </Flex>
            ))}
            {selectedPlates.length > 1 && (
              <Flex container alignItems="center" justifyContent="center" direction="horizontal" style={{ marginTop: 5, width: '100%' }}>
                <Flex grow={1} style={{ paddingLeft: 20 }}>
                  Total
                </Flex>
                <PlateCoverageValue percent={coverage.total} />
              </Flex>
            )}
          </Flex>
        )}
      >
        <div
          style={{
            top: `${position ? (position.top * 100) : 0}%`,
            left: `${position ? (position.left * 100) : 0}%`,
          }}
          className={css.PlateCoverageLayer__tooltipTarget}
        />
      </Tooltip>
    </div>
  );
}

// TODO: merge with the one in PlateOption.js
function PlateColorIcon({ plateName, customColors }) {
  const color = customColors[plateName];
  return (
    <div style={{ borderRadius: 14, overflow: 'hidden', fontSize: 0 }}>
      <HexColor name={plateName}>
        {hexColor => (
          <Fragment>
            {(color || hexColor)
              ? <div style={{ backgroundColor: color || hexColor, width: 14, height: 14 }} />
              : <img src="/img/icons/rainbow-wheel.png" alt={plateName} style={{ width: 14, height: 14 }} />}
          </Fragment>
        )}
      </HexColor>
    </div>
  );
}

function PlateCoverageValue({
  percent,
  truncateZero = false,
}) {
  let display = `${percent}%`;
  if (percent === 0) {
    if (truncateZero) {
      display = '-';
    } else {
      display = '0%';
    }
  } else if (percent === null) {
    display = <Fragment>&hellip;</Fragment>;
  }
  return (
    <div className={css.PlateCoverageLayer__coverageValue}>
      {display}
    </div>
  );
}

export default PlateCoverageLayer;
