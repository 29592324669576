/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import gql from 'graphql-tag';
import { useSubscription, ApolloProvider } from '@apollo/react-hooks';
import { getSpiderClient } from '../../util/gql/getSpiderClient';

// The results of this query are not currently used, we just
// need the subscription to trigger the front end to update
function ProofPermissionsEvents({ proofId, onEvent }) {
  useSubscription(gql`
      subscription onProofPermissionsChanged ($proofId: ID!) {
        onProofPermissionsChanged(id: $proofId) {
          canMarkDone {
            value
          }
        }
      }
    `, {
    variables: {
      proofId,
    },
    onSubscriptionData(options) {
      // Results are passed but not used
      // TODO: wire up the results to the UI
      onEvent(options.subscriptionData.data);
    },
    shouldResubscribe: true,
  });

  return null;
}

export default function (props) {
  return (
    <ApolloProvider client={getSpiderClient()}>
      <ProofPermissionsEvents {...props} />
    </ApolloProvider>
  );
}
