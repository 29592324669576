/* eslint-disable */

import React from 'react';
import CustomTool from '../CustomTool';
import { CustomToolsNavigator } from '../CustomToolsNavigator';

function oForEach(obj, callback) {
  return Object.keys(obj).map((key) => {
    return callback(obj[key], key, obj);
  });
}

export default class CustomTools extends React.Component {
  state = {
    value: null,
    selected: false,
  };

  navigatorRef = React.createRef();

  componentDidMount() {
    this.navigatorRef.current.onNavigate(() => {
      this.setState({
        selected: this.navigatorRef.current.isOpen,
      });
    });
  }

  onChange = ({value, selected}) => {
    if (this.navigatorRef.current.isOpen) {
      this.navigatorRef.current.clear();
    } else {
      const customTool = this.props.tools[value];
      this.navigatorRef.current.start(customTool.action);
    }
    this.setState({value, selected});
  }

  render() {
    return (
      <CustomToolsNavigator ref={this.navigatorRef}>
        <CustomTool
          {...this.state}
          {...this.state.selected ? {open: false} : {}}
          options={oForEach(this.props.tools, (tool, id) => ({
            id: id,
            tooltip: this.state.selected ? null : tool.label,
            icon: tool.icon[Object.keys(tool.icon)[0]],
          }))}
          constraints={{
            width: 60,
            height: 60,
          }}
          onChange={this.onChange}
        />
      </CustomToolsNavigator>
    );
  }
}
