/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import useDebounce from '../../hooks/useDebounce';
import FormControl from '../FormControl/FormControl';
import Input from '../Input/Input';
import FloatingLabelDescription from '../ProofSetup/components/FloatingLabelDescription';
import ButtonGroup from '../ButtonGroup';
import { Button } from '../Button';
import { Translation, TranslatedProps } from '../Text';
import css from './ProfileAboutMe.scss';
import getPlanName from '../../util/getPlanName';


const ProfileAboutMe = ({
  name,
  email,
  teamName,
  teamType,
  accountType,
  teamIsTrial,
  teamIsExpiredTrial,
  onUpgrade,
  onNameChange,
  teamIsPlus,
}) => {
  const [editedName, setEditedName] = useState('');

  useEffect(() => {
    if (name !== editedName) {
      setEditedName(name);
    }
  }, [name]);

  const onNameChangeDebounce = useDebounce((value) => {
    onNameChange(value);
  }, 1000);

  const handleChange = (value) => {
    setEditedName(value);
    onNameChangeDebounce(value);
  };

  const plan = getPlanName(teamType, teamIsPlus, teamIsTrial, teamIsExpiredTrial);

  return (
    <div>
      <FormControl label={<Translation value="profile.edit.name" />}>
        <TranslatedProps placeholder="profile.edit.name.placeholder">
          <Input
            id="name"
            type="text"
            placeholder=""
            value={editedName}
            onChange={handleChange}
          />
        </TranslatedProps>
      </FormControl>

      <FormControl label={<Translation value="label.email" />}>
        <Input
          id="email"
          type="text"
          value={email}
          disabled
        />
      </FormControl>

      {!!teamType && (
        <FormControl label={<Translation value="profile.edit.team-name" />}>
          <Input
            id="teamName"
            type="text"
            value={teamName}
            disabled
          />
        </FormControl>
      )}

      <FormControl
        label={
          <Fragment>
            <Translation value="profile.edit.account" />
            <FloatingLabelDescription
              description={<Translation value="profile.edit.learn-about-plans" />}
              url="https://pageproof.com/pricing"
            />
          </Fragment>
        }
      >
        <TranslatedProps value={plan}>
          <Input
            id="account"
            type="text"
            value=""
            disabled
          />
        </TranslatedProps>
      </FormControl>

      <ButtonGroup className={css.ProfileAboutMe__buttonGroup}>
        {(teamIsTrial || !accountType || !teamType) && (
          <Button
            variant="primary"
            onClick={() => onUpgrade(teamIsTrial, teamType)}
            label={<Translation value="profile.edit.upgrade" />}
          />
        )}
      </ButtonGroup>
    </div>
  );
};

ProfileAboutMe.propTypes = {
  onUpgrade: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  teamType: PropTypes.number.isRequired,
  accountType: PropTypes.number.isRequired,
  teamIsTrial: PropTypes.bool.isRequired,
  teamIsExpiredTrial: PropTypes.bool.isRequired,
  onNameChange: PropTypes.func.isRequired,
  teamIsPlus: PropTypes.bool.isRequired,
};

export default ProfileAboutMe;
