/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import EmailPreference from './EmailPreference';
import EmailPreferences from './EmailPreferences';

export {
  EmailPreference,
  EmailPreferences,
};
