/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './Loading.scss';

function Loading() {
  return (
    <div className={css.Loading}>
      Loading...
    </div>
  );
}

export default Loading;
