import getPSDJS from '../../../util/psdjs';
import renderImage from './renderImage';

export default function renderPDF(blob, size) {
  return getPSDJS()
    .then(psdjs => psdjs.fromDroppedFile(blob))
    .then((psd) => {
      const png = psd.image.toPng();
      const rendered = window.dataURLToBlob(png.src);
      return renderImage(rendered, size);
    });
}
