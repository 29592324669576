/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {curry} from 'rambda';

const trace = curry((tag, x) => {
  console.log(tag, x);
  return x;
});

// Rambda's reduce functional argument doesn't contain index and array arguments
const reduce = curry((f, acc, arr) => arr.reduce(f, acc));

export {
  trace,
  reduce,
};
