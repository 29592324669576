/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import NextAvatar from '../../../containers/NextAvatar';
import ImportedCommentAvatar from '../../ImportedCommentAvatar';
import css from './CommentTooltipContent.scss';

function CommentTooltipContent({
  commentData,
  avatarSize,
  textLines,
  textCharacters,
  isPdfImport,
  name,
}) {
  const {
    ownerId,
    ownerEmail,
    comment,
    decryptedComment,
  } = commentData;
  return (
    <Fragment>
      <div className={css.CommentTooltipContent__userDetails}>
        {isPdfImport
          ? <ImportedCommentAvatar avatarSize={avatarSize} />
          : (
            <NextAvatar
              id={ownerId}
              size={avatarSize}
            />
          )
        }
        <span className={css.CommentTooltipContent__email}>
          {name || ownerEmail}
        </span>
      </div>
      <Text
        value={comment || decryptedComment || ''}
        truncate={{ lines: textLines, characters: textCharacters }}
        isNotDarkModeStyle
      />
    </Fragment>
  );
}

if (process.env.NODE_ENV !== 'production') {
  CommentTooltipContent.propTypes = {
    commentData: PropTypes.shape({
      id: PropTypes.string,
      ownerId: PropTypes.string,
      ownerEmail: PropTypes.string,
      comment: PropTypes.string,
      decryptedComment: PropTypes.string,
    }),
    isPdfImport: PropTypes.bool,
    name: PropTypes.string,
    avatarSize: PropTypes.number,
    textLines: PropTypes.number,
    textCharacters: PropTypes.number,
  };
}

CommentTooltipContent.defaultProps = {
  avatarSize: 20,
  textLines: 3,
  textCharacters: 120,
};

export default CommentTooltipContent;
