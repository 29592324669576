import React from 'react';
import {Avatar} from '../Avatar';
import Tooltip from '../Tooltip';
import css from './Avatars.scss';

function Avatars({
  avatars,
  size,
  color,
  outline = (color ? (size * 0.15) : 0),
  overlap = ((size * 0.5) - outline),
  compact,
}) {
  return (
    <div
      className={css.Avatars}
      style={{margin: compact ? -outline : 0}}
    >
      {avatars.map(({url, tooltip}, index) => (
        <div
          key={url}
          className={css.Avatars__avatar}
          style={{
            marginLeft: index === 0 ? 0 : -overlap,
            border: outline ? `${outline}px solid ${color}` : '',
          }}
        >
          <Tooltip
            title={tooltip}
            up
            center
            arrow
            delay={0}
            offset={10 + (outline || 2)}
          >
            <div>
              <Avatar
                url={url}
                size={size}
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

export default Avatars;
