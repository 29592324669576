/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import HelpBubble from '../HelpBubble';

function PageSubheading({
  title,
  description,
  center,
  helpMessage,
  helpUrl,
}) {
  return (
    <div
      className={classname('PageSubheading', {
        'PageSubheading--center': center,
      })}
    >
      <h2 className="PageSubheading__title">
        {title}
        {helpMessage &&
          <HelpBubble
            className="PageSubheading__title__helpBubble"
            message={helpMessage}
            url={helpUrl}
          />
        }
      </h2>
      {description &&
        <div>{description}</div>}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  PageSubheading.propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.node,
    center: PropTypes.bool,
    helpMessage: PropTypes.node,
    helpUrl: PropTypes.string,
  };
}

export default PageSubheading;
