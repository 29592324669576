/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { VideoPlayerTimelinePin } from './VideoPlayerTimelinePin';

export const VideoPlayerTimelineComment = ({
  currentTime,
  durationTime,
  comment,
  timePresentation,
  getPinColor,
  selectedPinIndex,
  selectedCommentId,
  rootRef,
  scrub,
  scrubEnd,
  selectComment,
  canUpdate,
  updatePin,
}) => {
  const pinColor = getPinColor(comment);

  const pinNumbers = comment.pins
    .map((pin, index) => [index, Math.min(pin.time, pin.time + pin.duration)])
    .sort((pinA, pinB) => pinA[1] - pinB[1])
    .reduce((acc, pin, index) => ({ ...acc, [pin[0]]: index }), {});

  return (
    <Fragment>
      {comment.pins.map((pin, index) => (
        <VideoPlayerTimelinePin
          key={index}
          currentTime={currentTime}
          durationTime={durationTime}
          comment={comment}
          pin={pin}
          pinIndex={index}
          pinNumber={pinNumbers[index] + 1}
          pinColor={pinColor}
          timePresentation={timePresentation}
          selectedPinIndex={selectedPinIndex}
          selectedCommentId={selectedCommentId}
          rootRef={rootRef}
          scrub={scrub}
          scrubEnd={scrubEnd}
          selectComment={selectComment}
          canUpdate={canUpdate}
          updatePin={updatePin}
          isDisabled={!!selectedCommentId && selectedCommentId !== comment.id}
        />
      ))}
    </Fragment>
  );
};
