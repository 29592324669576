/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProofDownloadButton from '../../components/ProofDownloadButton';
import { getInitialSelectionState, mapFileForSelection, getSelectedIdsToDownload } from '../../util/proof-download-utils';
import { Translation } from '../../components/Text';

class ProofDownloadButtonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: getInitialSelectionState(props.files),
      showAttachments: false,
    };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Initially set the proof file(s) selection status to true
    if (this.props.files.length !== nextProps.files.length) {
      this.setState(state => ({
        selection: {
          ...state.selection,
          ...getInitialSelectionState(nextProps.files),
        },
      }));
    }
    const allAttachmentsSelected = !!this.props.attachments.length &&
      this.props.attachments.every(file => this.state.selection[file.id]);
    // Automatically set the selection status of attachments to false
    nextProps.attachments.forEach((attachment) => {
      if (!(attachment.id in this.state.selection)) {
        this.setState(state => ({
          selection: {
            ...state.selection,
            [attachment.id]: allAttachmentsSelected,
          },
        }));
      }
    });
    // Remove any ids from the selection if the file has been removed
    const availableIds = [
      ...nextProps.files,
      ...nextProps.attachments,
    ].map(f => f.id);
    this.setState(state => ({
      selection: Object.keys(state.selection).reduce((selection, id) => {
        if (availableIds.indexOf(id) === -1) {
          delete selection[id]; // eslint-disable-line
        }
        return selection;
      }, {
        ...state.selection,
      }),
    }));
  }

  render() {
    const files = this.props.files.map(file => mapFileForSelection(file, this.state.selection, this.props.downloadProgress));
    const attachments = this.props.attachments.map(attachment => mapFileForSelection(attachment, this.state.selection, this.props.downloadProgress));

    return (
      <ProofDownloadButton
        isMobile={this.props.isMobile}
        status={this.props.status}
        downloadGroups={[
          {
            files,
            heading: this.props.type === 'brief'
              ? <Translation value="brief.download.file.heading" />
              : <Translation value="proof.download.file.heading" />,
            isCollapsable: false,
            allSelected: files && !files.some(a => !a.selected),
          },
          {
            files: attachments,
            heading: <Translation value="proof.download.attachments.heading" />,
            isCollapsable: true,
            allSelected: attachments && !attachments.some(a => !a.selected),
          },
        ]}
        showAttachments={this.state.showAttachments}
        downloadCount={window.objectValues(this.state.selection).filter(Boolean).length}
        isExternalWeb={this.props.isExternalWeb}
        importUrl={this.props.importUrl}
        onSelectChange={(selection) => {
          this.setState(state => ({
            selection: {
              ...state.selection,
              ...selection,
            },
          }));
        }}
        onDownload={() => {
          this.props.onDownload(getSelectedIdsToDownload(this.state.selection));
        }}
      />
    );
  }
}

if (process.env.NODE_ENV === 'development') {
  ProofDownloadButtonContainer.propTypes = {
    status: PropTypes.oneOf([
      null,
      'downloading',
      'archiving',
    ]),
    downloadProgress: PropTypes.objectOf(PropTypes.number).isRequired,
    files: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    type: PropTypes.string,
    onDownload: PropTypes.func.isRequired,
    isExternalWeb: PropTypes.bool,
    importUrl: PropTypes.string,
    isMobile: PropTypes.bool,
  };
}

export default ProofDownloadButtonContainer;
