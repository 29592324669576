/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Timecode from '../Timecode';
import css from './MediaTime';

function MediaTime({
  time,
  duration,
  detailed,
  framesPerSecond,
}) {
  const first = time;
  const last = time + duration;
  return (
    <span className={css.MediaTime}>
      <Timecode
        value={Math.min(first, last)}
        detailed={detailed}
        framesPerSecond={framesPerSecond}
      />
      {!!(duration && duration !== 0.5) &&
        <span>
          {' — '}
          <Timecode
            value={Math.max(first, last)}
            detailed={detailed}
            framesPerSecond={framesPerSecond}
          />
        </span>
      }
    </span>
  );
}

export default MediaTime;
