/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import FromNow from '../../Date/FromNow';
import Verbose from '../../Date/Verbose';
import Tooltip from '../../Tooltip';
import LocalStorage from '../../LocalStorage';
import css from './Date';
import Translation from '../../Text/Translation';

const STORAGE_NAME = 'pageproof.app.comments.dateFormat';
const FROM_NOW_FORMAT = 'FromNow';
const VERBOSE_FORMAT = 'Verbose'; // eslint-disable-line no-unused-vars
const DEFAULT_DATE_FORMAT = FROM_NOW_FORMAT;

function Date({
  date,
  isImported,
}) {
  const verbose = isImported
    ? <Translation
      value="comment.imported.date.timestamp"
      params={{ date: <Verbose date={date} /> }}
    />
    : <Verbose date={date} />;
  const fromNow = isImported
    ? <Translation
      value="comment.imported.date.timespan"
      params={{ date: <FromNow date={date} /> }}
    />
    : <FromNow date={date} />;

  return (
    <LocalStorage.Value
      name={STORAGE_NAME}
      defaultValue={DEFAULT_DATE_FORMAT}
    >
      {({value}) => (
        <Tooltip
          title={value === FROM_NOW_FORMAT ? verbose : fromNow}
        >
          <span
            className={css.Date}
            onClick={() => {
              const newFormat = value === FROM_NOW_FORMAT ? VERBOSE_FORMAT : FROM_NOW_FORMAT;
              window.localStorage.setItem(STORAGE_NAME, newFormat);
            }}
          >
            {value === FROM_NOW_FORMAT ? fromNow : verbose}
          </span>
        </Tooltip>
      )}
    </LocalStorage.Value>
  );
}

export default Date;
