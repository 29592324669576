/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect, useState } from 'react';
import { gql } from '../util/gql/tag';
import { sdk } from '../util/sdk';
import { useLocalStorage } from '../components/LocalStorage';

const teamProofTemplatesQuery = gql`
  query ppxapp_getTeamProofTemplates {
      me {
        team {
          proofTemplates {
            id
            name
          }
        }
      }
  }
`;

const useTeamProofTemplates = () => {
  const teamId = sdk.session ? sdk.session.user && sdk.session.user.teamId : null;

  const [readyState, setReadyState] = useState('loading');
  const [proofTemplates, setProofTemplates] = useLocalStorage(`pageproof.cache.team-proof-templates.${teamId}`, () => ([]));

  const loadTeamProofTemplates = (isReload) => {
    setReadyState(isReload ? 'reloading' : 'loading');

    sdk.graphql(teamProofTemplatesQuery.toString(), {}, { throwOnError: false })
      .then((result) => {
        setProofTemplates(result.data.me.team.proofTemplates);
        setReadyState('loaded');
      }, () => { setReadyState('loaded'); });
  };

  function reloadProofTemplates() {
    loadTeamProofTemplates(true);
  }

  useEffect(() => {
    loadTeamProofTemplates(false);
  }, [teamId]);


  return [readyState, proofTemplates, reloadProofTemplates];
};

export default useTeamProofTemplates;
