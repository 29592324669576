/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import firstNotNullOrUndefined from '../firstNotNullOrUndefined';
import firstNotUndefined from '../firstNotUndefined';
import getFallbackDueDateAndTime from '../getFallbackDueDateAndTime';
import { getFallbackCanDownload } from '../canDownload';
import getFallbackReminders from '../getFallbackReminders';
import getFallbackIsPublic from '../getFallbackIsPublic';
import getFallbackCommentVisibility from '../getFallbackCommentVisibility';

export default function getProof(proof, shared, parameters, userPreferences) {
  if (proof._isEdited) {
    return {
      ...proof,
    };
  }
  return {
    ...proof,
    reference: firstNotNullOrUndefined(
      proof.reference,
      shared.reference,
      '',
    ),
    tags: [
      ...(shared.tags || []),
      ...(proof.tags || []),
    ],
    integrationReferences: [
      ...(shared.integrationReferences || []),
      ...(proof.integrationReferences || []),
    ],
    messageToReviewers: firstNotNullOrUndefined(
      proof.messageToReviewers,
      shared.messageToReviewers,
      parameters.defaultMessageToReviewers,
      (
        userPreferences &&
        userPreferences.messageToReviewers &&
        userPreferences.messageToReviewers.value
      ),
      '',
    ),
    dueDate: firstNotNullOrUndefined(
      proof.dueDate,
      shared.dueDate,
      getFallbackDueDateAndTime(userPreferences),
    ),
    reminders: firstNotNullOrUndefined(
      proof.reminders,
      shared.reminders,
      getFallbackReminders(userPreferences),
    ),
    canDownload: firstNotNullOrUndefined(
      proof.canDownload,
      shared.canDownload,
      getFallbackCanDownload(),
    ),
    isPublic: firstNotNullOrUndefined(
      proof.isPublic,
      shared.isPublic,
      getFallbackIsPublic(),
    ),
    commentVisibility: firstNotUndefined(
      proof.commentVisibility,
      shared.commentVisibility,
      getFallbackCommentVisibility(),
    ),
  };
}
