/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { MAX_AVATAR_FILE_SIZE, AVATAR_FILE_TYPES, S_BREAK_M } from '../../util/constants';
import NextAvatar from '../NextAvatar';
import { Translation, Muted } from '../../components/Text';
import FileDrop from '../../components/FileDrop';
import { Button } from '../../components/Button';
import { PopupMenu, Option } from '../../components/PopupMenu';
import { getDescription, validateFile } from '../../util/validation';
import { openModal } from '../../util/modal';
import CropAvatarModal from './CropAvatarModal';
import css from './ProfileAvatar.scss';

const constraints = {
  fileTypes: AVATAR_FILE_TYPES,
  maxFileSize: MAX_AVATAR_FILE_SIZE,
};

const onValidate = (file, callback) => validateFile(file, constraints, callback);

const AvatarWithMediaQuery = ({ userId }) => (
  <Media query={`(max-width: ${S_BREAK_M}px)`}>
    {matches => (
      <NextAvatar
        size={matches ? 70 : 100}
        id={userId}
        active
        spinner
      />
    )}
  </Media>
);

function ProfileAvatar({ userId, onAddAvatar, onResetAvatar, isReadOnly }) {
  const [hasAvatar, setHasAvatar] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hasError, setHasError] = useState(false);
  const fileInputRef = useRef(null);

  const bridge = window.__pageproof_bridge__;

  const onSelectFile = (file) => {
    onValidate(file, (error) => {
      setHasError(!!error);
      if (!error) {
        setSelectedFile(URL.createObjectURL(file));
      }
    });
  };

  const cleanUp = () => {
    URL.revokeObjectURL(selectedFile);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleOnAddAvatar = file => onAddAvatar(file)
    .then(() => {
      bridge.avatarRepositoryService.update({ userId });
      setHasAvatar(true);
    });

  const handleOnResetAvatar = () => onResetAvatar()
    .then(() => {
      bridge.avatarRepositoryService.update({ userId });
      setHasAvatar(false);
    });

  // When the user has selected a file, open a modal for them to crop it.
  useEffect(() => {
    if (selectedFile) {
      const { destroy: close } = openModal(
        <CropAvatarModal
          url={selectedFile}
          onCropped={(file) => {
            Promise.resolve(handleOnAddAvatar(file))
              .then(() => close());
          }}
          onCancel={() => close()}
        />,
        () => cleanUp()
      );
    }
  }, [selectedFile]);

  useEffect(() => {
    bridge.avatarRepositoryService.get({ userId }).then(avatar => setHasAvatar(avatar.hasAvatar));
  }, [userId]);

  if (isReadOnly) {
    return (
      <div className={css.ProfileAvatar}>
        <AvatarWithMediaQuery userId={userId} />
      </div>
    );
  }

  return (
    <FileDrop onSelect={onSelectFile}>
      <div>
        <div className={css.ProfileAvatar}>
          <AvatarWithMediaQuery userId={userId} />
          <PopupMenu
            down
            options={(
              <Fragment>
                <Option
                  label={<Translation value="profile.upload-new-file" />}
                  onClick={() => fileInputRef.current.click()}
                />
                {hasAvatar &&
                  <Option
                    label={<Translation value="profile.use-default" />}
                    onClick={handleOnResetAvatar}
                  />
                }
              </Fragment>
            )}
          >
            <Button
              className={css['ProfileAvatar__file-button']}
              label={<Translation value={hasAvatar ? 'profile.change-avatar' : 'profile.add-avatar'} />}
              variant="text"
            />
          </PopupMenu>
          <input
            type="file"
            ref={fileInputRef}
            className={css['ProfileAvatar__file-input']}
            onChange={({ target }) => onSelectFile(target.files[0])}
          />
          {hasError && (
            <div className={css.ProfileAvatar__error}>
              <Muted>
                <Translation
                  value="profile.upload-new-file.error"
                  params={{ description: getDescription(constraints) }}
                />
              </Muted>
            </div>
          )}
        </div>
      </div>
    </FileDrop>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ProfileAvatar.propTypes = {
    userId: PropTypes.string.isRequired,
    onAddAvatar: PropTypes.func.isRequired,
    onResetAvatar: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool,
  };
}

export default ProfileAvatar;
