import {Translation} from '../components/Text';

/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function validateFile(file, validations, callback) {
  const {
    fileTypes,
    maxFileSize,
    width,
    height,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
  } = validations;
  if (!file) {
    callback(Error(Translation.text('validation.file.select')));
    return;
  }
  const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
  if (fileTypes.indexOf(extension) === -1) {
    callback(Error(Translation.text('validation.file.unsupported')));
    return;
  }
  if (file.size > maxFileSize) {
    callback(Error(Translation.text('validation.file.large')));
    return;
  }
  if (!width && !height && !minWidth && !minHeight && !maxWidth && !maxHeight) {
    // If there are no dimension restrictions enforced, file is all good
    callback(null, file);
    return;
  }
  const url = URL.createObjectURL(file);

  window.calculateImageDimensions(url, (err, dimensions) => {
    URL.revokeObjectURL(url);
    if (err) {
      callback(err);
      return;
    }
    if (width && dimensions.width !== width) {
      callback(Error(Translation.text('validation.image.exactly.width', {imageWidth: width})));
      return;
    }
    if (height && dimensions.height !== height) {
      callback(Error(Translation.text('validation.image.exactly.height', {imageHeight: height})));
      return;
    }
    if (minWidth && dimensions.width < minWidth) {
      callback(Error(Translation.text('validation.image.at-least.width', {imageWidth: minWidth})));
      return;
    }
    if (minHeight && dimensions.height < minHeight) {
      callback(Error(Translation.text('validation.image.at-least.height', {imageHeight: minHeight})));
      return;
    }
    if (maxWidth && dimensions.width > maxWidth) {
      callback(Error(Translation.text('validation.image.not-exceed.width', {imageWidth: maxWidth})));
      return;
    }
    if (maxHeight && dimensions.height > maxHeight) {
      callback(Error(Translation.text('validation.image.not-exceed.height', {imageHeight: maxHeight})));
      return;
    }
    callback(null, file);
  });
}

function getDescription(validations) {
  const descriptions = [];
  const {
    minWidth,
    minHeight,
    width,
    height,
    maxWidth,
    maxHeight,
    maxFileSize,
    fileTypes,
  } = validations;
  if (minWidth) {
    descriptions.push(Translation.text('validation.at-least.width', {imageWidth: minWidth}));
  }
  if (minHeight) {
    descriptions.push(Translation.text('validation.at-least.height', {imageHeight: minHeight}));
  }
  if (width && height) {
    descriptions.push(Translation.text('validation.exactly.width-height', {imageWidth: width, imageHeight: height}));
  } else {
    if (width) {
      descriptions.push(Translation.text('validation.exactly.width', {imageWidth: width}));
    }
    if (height) {
      descriptions.push(Translation.text('validation.exactly.height', {imageHeight: height}));
    }
  }
  if (maxWidth) {
    descriptions.push(Translation.text('validation.no-more.width', {imageWidth: maxWidth}));
  }
  if (maxHeight) {
    descriptions.push(Translation.text('validation.no-more.height', {imageHeight: maxHeight}));
  }
  if (maxFileSize) {
    descriptions.push(Translation.text('validation.no-larger', {description: window.bytesToSize(maxFileSize)}));
  }
  if (fileTypes) {
    descriptions.push(Translation.text('validation.following.formats', {formats: fileTypes.join(', ')}));
  }
  if (descriptions.length > 1) {
    const index = descriptions.length - 1;
    descriptions[index] = Translation.text('validation.and') + ' ' + descriptions[index];
  }
  return descriptions.join(', ');
}

export {
  validateFile,
  getDescription,
};
