/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export default function firstNotUndefined(...values) {
  let value;
  for (let i = 0; i < values.length; i += 1) {
    if (values[i] !== undefined) {
      value = values[i];
      break;
    }
  }
  return value;
}
