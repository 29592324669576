/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TranslatedProps } from '../../components/Text';
import Radio from '../../components/Radio';
import EmailBrandingPreviewEmail from './EmailBrandingPreviewEmail';

const alignments = {
  L: 'leftAlign',
  C: 'centerAlign',
  R: 'rightAlign',
};

function EmailBrandingPreview({
  email,
  emailFooter,
  updateAlignment,
  titleTextColor,
  linkTextColor,
  buttonBackgroundColor,
  buttonTextColor,
}) {
  return (
    <div className="EmailBrandingPreview">
      {email.exists &&
        <EmailBrandingBanner
          url={email.url}
          position="top"
          alignment={alignments[email.alignment]}
          onChange={alignment => updateAlignment(email.typeKey, alignment)}
        />
      }
      <EmailBrandingPreviewEmail
        titleTextColor={titleTextColor}
        linkTextColor={linkTextColor}
        buttonBackgroundColor={buttonBackgroundColor}
        buttonTextColor={buttonTextColor}
        emailFooter={!emailFooter.exists}
      />
      {emailFooter.exists &&
        <EmailBrandingBanner
          url={emailFooter.url}
          position="bottom"
          alignment={alignments[emailFooter.alignment]}
          onChange={alignment => updateAlignment(emailFooter.typeKey, alignment)}
        />
      }
    </div>
  );
}

function EmailBrandingBanner({
  url,
  position,
  alignment,
  onChange,
}) {
  return (
    <div className="EmailBrandingPreview__banner">
      <TranslatedProps alt="domain-admin.menu.branding.preview.banner.alt">
        <img
          className={`EmailBrandingPreview__image EmailBrandingPreview__image--${position} EmailBrandingPreview--${alignment}`}
          src={url}
          alt=""
        />
      </TranslatedProps>
      <div className="EmailBrandingPreview__options">
        {Object.keys(alignments).map(key => (
          <Radio
            key={key}
            selected={alignment === key}
            onChange={() => onChange(key)}
          />
        ))}
      </div>
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  EmailBrandingPreview.propTypes = {
    email: PropTypes.objectOf(PropTypes.any),
    emailFooter: PropTypes.objectOf(PropTypes.any),
    updateAlignment: PropTypes.func.isRequired,
    titleTextColor: PropTypes.string,
    linkTextColor: PropTypes.string,
    buttonBackgroundColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
  };
}

export default EmailBrandingPreview;
