/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';
import ProfileAboutMe from '../../components/ProfileAboutMe/ProfileAboutMe';

const ProfileAboutMeContainer = () => {
  const { userService, SegmentIo } = window.__pageproof_bridge__;
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    userService.populateUser()
      .then((user) => {
        setUserData({
          name: user.name,
          email: user.email,
          teamName: user.teamName,
          teamType: user.teamType,
          accountType: user.type,
          teamIsTrial: user.teamIsTrial,
          teamIsExpiredTrial: user.teamIsTrial && !user.features.createNewProofs,
          teamIsPlus: user.teamIsPlus,
        });
      });
  }, []);

  if (!userData) {
    return null;
  }

  return (
    <ProfileAboutMe
      {...userData}
      onNameChange={(name) => {
        userService.apiService.users.update.fetch({ Name: name });
        setUserData({
          ...userData,
          name,
        });
      }}
      onUpgrade={(teamIsTrial, teamType) => {
        SegmentIo.track(52);

        if (teamIsTrial && teamType === 2) {
          window.location.href = 'mailto:hello@pageproof.com?subject=I would like to upgrade to the enterprise plan';
        } else {
          window.location.href = window.env('marketing_url') + '/pricing#pricing-currency';
        }
      }}
    />
  );
};

export default ProfileAboutMeContainer;
