/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Translation from '../Text/Translation';
import FormatDate from '../Date/FormatDate';
import css from './FilterLabel.scss';

const DATE_FILTER_FORMAT_TYPE = 'MMM D, YYYY';

function FilterLabel({
  label,
  startDate,
  endDate,
  isCentered,
}) {
  const isSameDay = window.__pageproof_bridge__.moment(startDate).isSame(endDate, 'day');
  return (
    <div className={css.FilterLabel}>
      <Translation value={label} />
      <div className={
        classname(
          css.FilterLabel__dates,
          { [css['FilterLabel__dates--active']]: startDate && endDate,
            [css['FilterLabel__dates--center']]: isCentered,
          },
        )}
      >
        {startDate && endDate &&
          <span>
            <FormatDate
              date={startDate}
              formatTo={DATE_FILTER_FORMAT_TYPE}
            />
            {!isSameDay &&
              <Fragment>
                {' - '}
                <FormatDate
                  date={endDate}
                  formatTo={DATE_FILTER_FORMAT_TYPE}
                />
              </Fragment>
            }
          </span>
        }
      </div>
    </div>
  );
}

FilterLabel.propTypes = {
  label: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  isCentered: PropTypes.bool,
};

export default FilterLabel;
