/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {
  cloneElement,
  Children,
  Fragment,
  Component,
} from 'react';
import Portal from '../Portal';

class Mask extends Component {
  static defaultProps = {
    visible: true,
  };

  state = {
    position: null,
  };

  componentDidMount() {
    if (this.props.target) {
      this.childrenRef(this.props.target);
    } else if (this.props.visible) {
      this.requestRaf();
    }
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.target !== this.props.target) {
      this.childrenRef(nextProps.target);
    }
    if (nextProps.visible !== this.props.visible) {
      if (nextProps.visible) {
        this.requestRaf();
      } else {
        this.cancelRaf();
      }
    }
  }

  childrenRef = (ref) => {
    this.element = ref;
    this.requestRaf();
  }

  rafCallback = () => {
    const previousPosition = this.state.position;
    const currentPosition = this.element.getBoundingClientRect();
    if (
      !previousPosition ||
      (
        previousPosition.top !== currentPosition.top ||
        previousPosition.left !== currentPosition.left ||
        previousPosition.width !== currentPosition.width ||
        previousPosition.height !== currentPosition.height
      )
    ) {
      this.setState({
        position: {
          top: currentPosition.top,
          left: currentPosition.left,
          width: currentPosition.width,
          height: currentPosition.height,
        },
      });
    }
    this.raf = null;
    this.requestRaf();
  }

  requestRaf() {
    if (!this.element && this.raf) {
      this.cancelRaf();
      return;
    }
    if (this.raf) {
      return;
    }
    this.raf = window.requestAnimationFrame(this.rafCallback);
  }

  cancelRaf() {
    window.cancelAnimationFrame(this.raf);
    this.raf = null;
  }

  renderChildren() {
    if (this.props.children) {
      return cloneElement(Children.only(this.props.children), {
        ref: this.childrenRef,
      });
    }
    return null;
  }

  renderPortal() {
    if (!this.state.position || !this.props.visible) {
      return null;
    }
    const {children, visible, render, mountElement, ...props} = this.props;
    return (
      <Portal root={mountElement}>
        {render
          ? render(this.state.position)
          : <div
            {...props}
            style={{
              zIndex: 10000, // zIndex can be overwriten
              ...(props.style || {}),
              position: 'absolute',
              ...this.state.position,
            }}
          />
        }
      </Portal>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderChildren()}
        {this.renderPortal()}
      </Fragment>
    );
  }
}

export default Mask;
