/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
export default function getImportProviderTranslationKey(provider) {
  const providerKeyMap = {
    dropbox: 'import.dropbox',
    drive: 'import.google-drive',
    box: 'import.box',
    onedrive: 'import.onedrive',
    canva: 'import.canva',
    url: 'import.url',
  };

  if (!providerKeyMap[provider]) {
    throw new Error(`Translation key not found for import provider: ${provider}.`);
  }

  return providerKeyMap[provider];
}
