/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import {useDrag} from 'react-dnd';

function DragSourceWrapper({children, data, type, isDraggable}) {
  const [{opacity}, drag] = useDrag({
    type,
    item: {id: data.id, type},
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
    canDrag: isDraggable,
  });

  const style = {
    opacity,
    cursor: isDraggable ? 'move' : 'pointer',
  };

  return (
    <div ref={drag} style={style}>
      {children}
    </div>
  );
}

export default DragSourceWrapper;
