/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import Tooltip from '../../Tooltip';
import css from './Hashtag.scss';

function Hashtag({
  children,
  onClick,
  tooltip,
  always,
}) {
  const tooltipContent = tooltip && tooltip();

  if (!tooltipContent && !always) {
    return children;
  }

  return (
    <Tooltip
      title={() => (
        <div>
          {tooltipContent}
        </div>
      )}
      center
      up
      disabled={!tooltipContent || window.__pageproof_bridge__.browserService.is('mobile')}
      variant="light"
      maxWidth={300}
      arrow
      offset={10}
    >
      <span
        role="button"
        tabIndex={-1}
        className={classname(css.Hashtag, {
          [css['Hashtag--clickable']]: !!onClick,
        })}
        onClick={onClick
          ? (event) => {
            onClick();
            event.stopPropagation();
          }
          : undefined
        }
      >
        {children}
      </span>
    </Tooltip>
  );
}

export default Hashtag;
