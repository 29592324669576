/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './CollectionProofDownloadPopover.scss';
import { Button } from '../Button';
import OptionGroup from '../OptionGroup';
import { Option } from '../PopupMenu';
import { Translation } from '../Text';
import { getDownloadButtonLabel, selectAllIds } from '../../util/proof-download-utils';
import ProgressCircle from '../ProgressCircle';
import ButtonGroup from '../ButtonGroup';
import Cross from '../Icon/Cross';
import Tooltip, { onlyWhenTruncated } from '../Tooltip/Tooltip';
import TextButtonTool from '../OptionGroup/TextButtonTool';

const CollectionProofDownloadPopover = ({
  status,
  files,
  downloadCount,
  onSelectChange,
  allSelected,
  onDownload,
  proofType,
}) => {
  const getHeading = () => {
    const isBrief = proofType === 'brief';
    const isSingleFile = files.length <= 1;

    if (isSingleFile) {
      return isBrief ? 'brief.download.file.heading' : 'proof.download.file.heading';
    }

    return isBrief ? 'brief.download.files.heading' : 'proof.download.files.heading';
  };

  return (
    <div className={css.CollectionProofDownloadPopover}>
      <OptionGroup
        label={<Translation value={getHeading} />}
        tool={files.length > 1 && (
          <TextButtonTool
            disabled={!!status}
            label={
              <Translation
                value={allSelected
                  ? 'proof.download.deselect-all'
                  : 'proof.download.select-all'
                }
              />
            }
            onClick={() => selectAllIds(onSelectChange, files.map(file => file.id), allSelected)}
          />
        )}
      >
        <div className={css.CollectionProofDownloadPopover__group}>
          {files.map(file => (
            <Option
              key={file.name}
              className={css.CollectionProofDownloadPopover__group__option}
              variant="light"
              label={
                <Tooltip
                  middle
                  right
                  title={file.name}
                  onBeforeOpen={onlyWhenTruncated(`.${css.CollectionProofDownloadPopover__group__option__label}`)}
                  offset={60}
                  delay={1000}
                >
                  <div className={css.CollectionProofDownloadPopover__group__option__label}>
                    {file.name}
                  </div>
                </Tooltip>
              }
              onClick={() => onSelectChange({ [file.id]: !file.selected })}
              checked={file.selected}
              checkedIcon={file.progress && (
                file.progress > 0
                  ? (
                    <ProgressCircle
                      size={20}
                      width={3}
                      value={file.progress}
                    />
                  )
                  : (
                    <Cross
                      active
                      readOnly
                      activeColor="#e51c23"
                      size={20}
                    />
                  )
              )}
              disabled={!!status}
            />
          ))}
        </div>
      </OptionGroup>
      <ButtonGroup>
        <Button
          variant="primary"
          className={css.CollectionProofDownloadPopover__download}
          label={getDownloadButtonLabel(status, downloadCount)}
          disabled={!!status || !downloadCount}
          onClick={onDownload}
        />
      </ButtonGroup>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  CollectionProofDownloadPopover.propTypes = {
    status: PropTypes.oneOf([
      'downloading',
      'archiving',
    ]),
    files: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      progress: PropTypes.number,
    })).isRequired,
    downloadCount: PropTypes.number.isRequired,
    onSelectChange: PropTypes.func.isRequired,
    allSelected: PropTypes.bool.isRequired,
    onDownload: PropTypes.func.isRequired,
    proofType: PropTypes.string.isRequired,
  };
}

export default CollectionProofDownloadPopover;
