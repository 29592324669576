/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './GridRuler.scss';

const PANEL_GAP = 15;

const GridRulerPanel = ({
  top,
  left,
  label,
  isShown,
}) => (
  <div
    className={css.GridRuler__panel}
    style={{
      display: isShown ? 'block' : 'none',
      top: top + PANEL_GAP,
      left: left + PANEL_GAP
    }}
  >
    {label}
  </div>
);

const GridRuler = ({
  rulerPosition: {
    height,
    width,
    top,
    left,
    direction,
  },
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onBlur,
  color,
  zIndex,
}) => (
  <div
    className={`
      ${css.GridRuler}
      ${css[`GridRuler--${direction}`]}
    `}
    style={{ top, left, height, width, zIndex }}
    onMouseDown={onMouseDown}
    onMouseMove={onMouseMove}
    onMouseOut={onMouseOut}
    onBlur={onBlur}
  >
    <div style={{ backgroundColor: color }} />
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  GridRulerPanel.propTypes = {
    top: PropTypes.number,
    left: PropTypes.number,
    label: PropTypes.string,
    isShown: PropTypes.bool,
  };

  GridRuler.propTypes = {
    rulerPosition: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
      direction: PropTypes.string,
    }),
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseOut: PropTypes.func,
    onBlur: PropTypes.func,
    color: PropTypes.string,
    zIndex: PropTypes.number,
  };
}

export {
  GridRulerPanel,
  GridRuler,
};
