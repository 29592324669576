/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { parseDate } from '../../../util/date-time-utils';

export default function getFallbackDueDateAndTime(userPreferences) {
  if (!userPreferences) {
    return undefined;
  }

  const time = userPreferences.dueTime.value ? ` at ${userPreferences.dueTime.value}` : '';

  return parseDate(`in ${userPreferences.dueDate.value}${time}`)
    .milliseconds(0)
    .toISOString();
}
