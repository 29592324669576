/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'Replies',
  src: 'img/icons/replies.svg',
  activeColor: '#aaa',
  activeHoverColor: '#777',
});
