/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
window.Array.from = require('core-js/library/fn/array/from');
window.fetch = window.fetch || require('unfetch').default;
window.Symbol = require('core-js/es6/symbol.js');
window.Array.findIndex = require('array-findindex-polyfill');
const urlPolyfill = require('url-polyfill');
require('core-js/fn/symbol/iterator');

if (!String.prototype.endsWith) {
  /* eslint no-extend-native: ["error", { "exceptions": ["String", "Array", "Promise"] }] */
  String.prototype.endsWith = function (search, thisLen) {
    if (thisLen === undefined || thisLen > this.length) {
      thisLen = this.length; // eslint-disable-line
    }
    return this.substring(thisLen - search.length, thisLen) === search;
  };
}

if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    if (start === undefined) {
      start = 0; // eslint-disable-line
    }
    return this.indexOf(search, start) !== -1;
  };
}

if (!Array.prototype.includes) {
  Array.prototype.includes = function (search, fromIndex) {
    if (typeof fromIndex !== 'number') {
      fromIndex = 0; // eslint-disable-line
    }
    if (fromIndex >= this.length) {
      return false;
    } else {
      return this.indexOf(search, fromIndex) !== -1;
    }
  };
}

if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) { // eslint-disable-line
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw TypeError('"this" is null or not defined');
      }

      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length > 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      const thisArg = arguments[1]; //eslint-disable-line

      // 5. Let k be 0.
      let k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        const kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++; //eslint-disable-line
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true,
  });
}

if (!Promise.prototype.finally) {
  Promise.prototype.finally = function (onFinally) {
    const callbackFn = callback => Promise.resolve(onFinally()).then(callback);
    return this.then(
      value => callbackFn(() => value),
      reason => callbackFn(() => Promise.reject(reason))
    );
  };
}

window.URL = typeof window !== 'undefined' && window.URL && typeof window.URL === 'function'
  ? window.URL
  : urlPolyfill.URL;
