/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import css from './ProfileMFA.scss';
import PageSubheading from '../Page/Subheading';
import { Translation } from '../Text';
import ButtonGroup from '../ButtonGroup';
import { Button } from '../Button';
import { openModal } from '../../util/modal';
import { SetupMFA, RemoveMFA } from '../MultiFactorAuthentication';
import { sdk } from '../../util/sdk';
import { Spinner } from '../Spinner';

const ProfileMFA = ({ hasTOTP, onUpdateHasTOTP }) => {
  const [isLoading, setIsLoading] = useState(false);

  const setupMFA = () => {
    setIsLoading(true);
    sdk.accounts.mfa.setup()
      .then(({ qrCodeImage }) => {
        setIsLoading(false);
        const { destroy: close } = openModal(
          <SetupMFA
            qrCodeImage={qrCodeImage}
            onComplete={() => {
              onUpdateHasTOTP(true);
              close();
            }}
          />,
          null,
          true
        );
      });
  };

  const removeMFA = () => {
    const { destroy: close } = openModal(
      <RemoveMFA
        onComplete={() => {
          onUpdateHasTOTP(false);
          close();
        }}
      />,
      null,
      true,
    );
  };

  return (
    <Fragment>
      {isLoading && (
        <div className={css.ProfileMFA__spinner}>
          <Spinner size={80} />
        </div>
      )}
      <PageSubheading
        title={<Translation value="profile.mfa.header" />}
        description={<Translation value="profile.mfa.description" />}
      />
      <div className={css.ProfileMFA__buttonWrapper}>
        <ButtonGroup>
          {hasTOTP
            ? (
              <Button
                variant="orange"
                label={<Translation value="profile.mfa.remove.button" />}
                className={css.ProfileMFA__button}
                onClick={() => removeMFA()}
              />
            )
            : (
              <Button
                variant="primary"
                label={<Translation value="profile.mfa.set-up.button" />}
                className={css.ProfileMFA__button}
                onClick={() => setupMFA()}
              />
            )
          }
        </ButtonGroup>
      </div>
    </Fragment>
  );
};

if (process.env.NODE_ENV !== 'production') {
  ProfileMFA.propTypes = {
    hasTOTP: PropTypes.bool.isRequired,
    onUpdateHasTOTP: PropTypes.func.isRequired,
  };
}

export default ProfileMFA;
