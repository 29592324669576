/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable */

import React, { Component } from 'react';
import Wrapper from '../../../components/Comment/Wrapper';
import Comment from '../../../components/Comment/Comment/Container';
import Reply from '../../../components/Comment/Reply/Container';
import CreateReply from '../../../components/Comment/CreateReply/Container';
import Replies from '../../../components/Comment/Replies';
import getMetadata from '../../../components/Comment/Metadata/getMetadata';
import { getAnnotationName } from '../utils/pdfCommentImportUtils';
import { getMediaTimeAndDuration } from '../utils/getMediaTimeAndDuration';
class SingleComment extends Component {
  state = {
    isReplying: false,
  };

  render() {
    const { $rootScope } = window.__pageproof_bridge__;
    const { proofCtrl, comment, canAddCommentReplies, proofHelper } = this.props;

    const {
      proof,
      permissions,
      isVideo,
      getMentionSuggestions,
      getTimecodes,
      updateAttachments,
      validateAttachmentType,
      validateAttachmentSize,
      getAttachmentProps,
      getMediaPin,
      canEditComment,
      safeApply,
    } = proofHelper;

    const canReply = (
      permissions.proofer.commentLevel.canReply &&
      canAddCommentReplies
    );

    const canDeleteComment = (commentOwnerId) =>
      permissions.proofer.commentLevel.canCreate &&
      (
        commentOwnerId === proofCtrl.user.id ||
        (proof.ownerCanDeleteComments && proof.isOwnerOrCoOwner)
      );
    
    function hasCommentError(comment) {
      return (
        !comment.isSaved ||
        (
          !$rootScope.online &&
          (
            !comment.id ||
            (comment.snapshot && !comment.snapshot.id) ||
            (comment.attachment && !comment.attachment.id)
          )
        )
      );
    }

    const sourceMetadata = comment.getSourceMetadata();
    const metadata = {
      ...comment.metadata,
      importSource: sourceMetadata && sourceMetadata.pdfImport && {
        importDate: comment.createdAt.toDate(),
        createdDate: sourceMetadata.pdfImport.createdDate && new Date(sourceMetadata.pdfImport.createdDate),
        lastModifiedDate: sourceMetadata.pdfImport.lastModifiedDate && new Date(sourceMetadata.pdfImport.lastModifiedDate),
        fileName: sourceMetadata.pdfImport.fileName,
        importer: {
          id: comment.ownerId,
          email: comment.ownerEmail,
        }
      }
    };
    const hasMetadata = Object.values(metadata).some(Boolean);
    const importMetadata = sourceMetadata && sourceMetadata.pdfImport && {
      type: 'pdf',
      author: sourceMetadata.pdfImport.author,
      // PDF comment import sets the comment text to a single space for empty annotations.
      annotationDisplayName: getAnnotationName(sourceMetadata.pdfImport.annotation),
    };

    const nextMarkOptions = proofCtrl.$getNextCommentStatusMarkOptions(permissions, comment)
      .map(mark => ({
        ...mark,
        icon: mark.state ? mark.type : null
      }));

    const firstMediaPin = getMediaPin(comment);
    const canEditParentComment = canEditComment(comment);
    const mediaTime = getMediaTimeAndDuration(comment, firstMediaPin);

    return (
      <Wrapper>
        <Comment
          id={`comment-${comment.id || 'creating'}`}
          user={{
            id: comment.ownerId,
            email: comment.ownerEmail,
          }}
          importMetadata={importMetadata}
          hasError={hasCommentError(comment)}
          pinCount={comment.pins.length}
          timecodes={getTimecodes(comment)}
          isLoading={!comment.decryptedComment && !comment.comment}
          mentionSuggestions={getMentionSuggestions}
          value={comment.comment || ''}
          date={comment.createdAt.toDate()}
          mediaTime={mediaTime ? mediaTime.time : undefined}
          mediaDuration={mediaTime ? mediaTime.duration : undefined}
          mediaDetailed={isVideo}
          editedDate={comment.editedDate}
          number={comment.number}
          editedUserId={comment.editedByUserId}
          showMark={this.props.showMark}
          canMark={nextMarkOptions.length > 0}
          isPrivate={comment.isPrivate}
          mark={proofCtrl.$getCommentStatusMark(comment)}
          nextMarkOptions={nextMarkOptions}
          hasSnapshot={!!comment.snapshot}
          metadata={hasMetadata && getMetadata(metadata)}
          agrees={comment.agrees}
          canAgree={!!(
            permissions.proofer.commentLevel.canAgree &&
            comment.ownerId !== proofCtrl.user.id
          )}
          showAgrees
          doesAgree={comment.agrees.indexOf(proofCtrl.user.id) !== -1}
          agreeCount={comment.agrees.length}
          attachments={comment.attachments && comment.attachments.map(attachment => getAttachmentProps(comment, attachment))}
          canDelete={canDeleteComment(comment.ownerId)}
          canEdit={canEditParentComment}
          canLabel={comment.pins.some(pin => !pin.fill && pin.x2 !== null && pin.y2 !== null)}
          isSelected={comment.$selected}
          canAttach={canEditParentComment}
          canSelect
          canFlash
          onSelect={() => {
            proofCtrl.selectComment(comment, false, this.compareType, true);
            safeApply();
          }}
          onFlash={() => {
            proofCtrl.flashComment(comment, this.compareType);
            safeApply();
          }}
          onAgree={() => {
            proofCtrl.agreeComment(comment);
            safeApply();
          }}
          onMark={(type, state) => {
            proofCtrl.markComment(comment, type, state);
            safeApply();
          }}
          onOpenProfile={() => {
            $rootScope.$broadcast('openUserDetails', {
              proofId: proof.id,
              userId: comment.ownerId,
            });
          }}
          onValidateAttachmentType={validateAttachmentType}
          onValidateAttachmentSize={validateAttachmentSize}
          onAttach={(attachments) => updateAttachments(comment, attachments)}
          onSave={(value) => {
            comment.decryptedComment = comment.comment = value;
            proofCtrl.updateComment(comment, null);
          }}
          onDelete={() => {
            proofCtrl.deleteComment(comment);
            safeApply();
          }}
          onRestoreMetadata={proofCtrl.restoreMetadata && (() => {
            proofCtrl.restoreMetadata(comment.getMetadata());
            safeApply();
          })}
          onHashtagClick={(number) => proofCtrl.selectCommentByNumber(number, proof)}
          onHashtagHover={(number) => proofCtrl.getCommentDataByNumber(number, proof)}
          onReply={() => this.setState({ isReplying: true })}
          framesPerSecond={proofHelper.proof.framesPerSecond}
        />
        {comment.replies.length > 0 &&
          <Replies
            replyCount={comment.replies.length}
            onRemoveNoParentComment={() => {
              proofCtrl.removeComment(comment);
              proofCtrl.handleChangesAfterCommentRemoval();
              safeApply();
            }}
            haveNoParentComment={comment.replies[0].haveNoParentComment}
          >
            {(index) => {
              const reply = comment.replies[index];

              const replySourceMetadata = reply.getSourceMetadata();
              const replyMetadata = {
                importSource: replySourceMetadata && replySourceMetadata.pdfImport && {
                  importDate: reply.createdAt.toDate(),
                  createdDate: replySourceMetadata.pdfImport.createdDate && new Date(replySourceMetadata.pdfImport.createdDate),
                  lastModifiedDate: replySourceMetadata.pdfImport.lastModifiedDate && new Date(replySourceMetadata.pdfImport.lastModifiedDate),
                  fileName: replySourceMetadata.pdfImport.fileName,
                  importer: {
                    id: reply.ownerId,
                    email: reply.ownerEmail,
                  }
                }
              }
              const replyHasMetadata = Object.values(replyMetadata).some(Boolean);
              const replyImportMetadata = replySourceMetadata && replySourceMetadata.pdfImport && {
                type: 'pdf',
                author: replySourceMetadata.pdfImport.author,
                // PDF comment import sets the comment text to a single space for empty annotations.
                annotationDisplayName: getAnnotationName(replySourceMetadata.pdfImport.annotation),
              };

              const canEditReplyComment = canEditComment(reply);
              return (
                <Reply
                  id={`reply-${reply.id || 'creating'}`}
                  key={reply.id || index}
                  user={{
                    id: reply.ownerId,
                    email: reply.ownerEmail,
                  }}
                  canDelete={canDeleteComment(reply.ownerId)}
                  onDelete={() => {
                    proofCtrl.deleteComment(reply);
                    safeApply();
                  }}
                  date={reply.createdAt.toDate()}
                  importMetadata={replyImportMetadata}
                  metadata={replyHasMetadata && getMetadata(replyMetadata)}
                  editedDate={reply.editedDate}
                  editedUserId={reply.editedByUserId}
                  hasError={hasCommentError(reply)}
                  isLoading={!reply.decryptedComment && !reply.comment}
                  mentionSuggestions={getMentionSuggestions}
                  value={reply.comment || ''}
                  canEdit={canEditReplyComment}
                  agrees={reply.agrees}
                  canAgree={!!(
                    permissions.proofer.commentLevel.canAgree &&
                    reply.ownerId !== proofCtrl.user.id
                  )}
                  showAgrees
                  doesAgree={!!(
                    reply.agrees.indexOf(proofCtrl.user.id) !== -1
                  )}
                  canSelect
                  agreeCount={reply.agrees.length}
                  canAttach={canEditReplyComment}
                  attachments={reply.attachments && reply.attachments.map(attachment => getAttachmentProps(reply, attachment))}
                  onOpenProfile={() => {
                    $rootScope.$broadcast('openUserDetails', {
                      proofId: proof.id,
                      userId: reply.ownerId,
                    });
                  }}
                  onSelect={() => {
                    if (comment.$selected) {
                      proofCtrl.flashComment(comment, this.compareType);
                    } else {
                      proofCtrl.selectComment(comment, false, this.compareType, true);
                    }
                    safeApply();
                  }}
                  onAgree={() => {
                    proofCtrl.agreeComment(reply);
                    safeApply();
                  }}
                  onValidateAttachmentType={validateAttachmentType}
                  onValidateAttachmentSize={validateAttachmentSize}
                  onAttach={(attachments) => updateAttachments(reply, attachments)}
                  onSave={(value) => {
                    reply.decryptedComment = reply.comment = value;
                    proofCtrl.updateComment(reply, null);
                  }}
                  onHashtagClick={(number) => proofCtrl.selectCommentByNumber(number, proof)}
                  onHashtagHover={(number) => proofCtrl.getCommentDataByNumber(number, proof)}
                  isPrivate={reply.isPrivate}
                />
              );
            }}
          </Replies>
        }
        {canReply &&
          <CreateReply
            id={`create-reply-${comment.id || 'creating'}`}
            mentionSuggestions={getMentionSuggestions}
            initialValue=""
            canAttach
            onValidateAttachmentType={validateAttachmentType}
            onValidateAttachmentSize={validateAttachmentSize}
            canTogglePrivate={!comment.isPrivate && proofCtrl.$getCanCreatePrivateComment(proof, permissions, comment)}
            defaultIsPrivate={comment.isPrivate}
            onCreate={({value, attachments, isPrivate}) => {
              this.setState({ isReplying: false });
              const reply = new (proofCtrl.$$.PPProofComment)();
              reply.comment = value;
              let newAttachments = [];
              if (attachments && attachments.length) {
                attachments.forEach(attachment => {
                  const newAttachment = new (proofCtrl.$$.PPProofCommentAttachment)();
                  newAttachment.updateFromFile(attachment);
                  newAttachments = [...newAttachments, newAttachment];
                });
                reply.attachments = newAttachments;
              }
              reply.isPrivate = isPrivate;
              proofCtrl.replyComment(comment, reply);
              safeApply();
              return true;
            }}
          />
        }
      </Wrapper>
    );
  }
}

export default SingleComment;
