/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment, useState } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import css from './SelectFileOptions.scss';

import Popover from '../../Popover';
import Option from '../../PopupMenu/Option';
import Separator from '../../PopupMenu/Separator';
import { addEmail } from '../utils/addFile';
import requestProofSetup from '../utils/requestProofSetup';
import {supportedFileTypes, briefSupportedFileTypes, supportedFileTypesAfterTransform} from '../utils/supportedFileTypes';
import useDarkMode from '../../../hooks/useDarkMode';
import beginBriefSetup from '../utils/beginBriefSetup';
import { Translation } from '../../Text';
import addLocalFiles from '../utils/addLocalFiles';
import Tooltip from '../../Tooltip';

const redirectToCreateOr = (fallback) => {
  const { $location, $rootScope } = window.__pageproof_bridge__;
  if ($location.path() !== '/create') {
    $location.url('/create');
    if (!$rootScope.$$phase) $rootScope.$apply();
  } else {
    if (fallback) fallback();
  }
};

const FileInput = ({ onFiles, onChange, ...props }) => {
  const [key, setKey] = useState(0);

  return (
    <input
      key={key}
      type="file"
      {...props}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        if (onFiles) {
          onFiles([...event.target.files]);
        }
        setKey(key + 1);
      }}
    />
  );
};

const native = {
  'computer': {
    icon: '/img/interface/3rd-party/folder.svg',
    label: <Translation value="import.computer" />,
    optionProps: {
      component: 'label',
      className: classname('unstyled', css.SelectFileOptions__filePicker),
    },
    appendToOption({ type, multiple, parameters, initialize, popover, onSelect }) {
      return (
        <FileInput
          className={css.SelectFileOptions__fileInput}
          // Always allow selecting multiple files for a proof. multiple=false is handled in addLocalFiles
          multiple={type !== 'brief'} 
          accept={
            (type === 'brief' ? briefSupportedFileTypes : (supportedFileTypes.concat(supportedFileTypesAfterTransform)))
              .map(e => `.${e}`).join(',')
          }
          onFiles={(files) => {
            popover.hide();

            if (type === 'brief') {
              beginBriefSetup(files[0], parameters && parameters.updateProof);
            } else {
              requestProofSetup({ parameters, initialize }, () => {
                addLocalFiles(files, initialize, parameters, multiple, (fileCount) => {
                  redirectToCreateOr(() => {
                    if (onSelect) {
                      onSelect(fileCount);
                    }
                  });
                });
              });
            }
          }}
        />
      );
    },
    availability: ['proof', 'brief'],
  },
  'url': {
    icon: '/img/interface/3rd-party/url.svg',
    label: <Translation value="import.website-url" />,
    url: '/import/url',
    availability: ['proof'],
  },
  'email': {
    icon: '/img/interface/3rd-party/email.svg',
    label: <Translation value="import.email" />,
    onClick({ event, parameters, initialize, popover, onSelect }) {
      if (!event.altKey) {
        popover.hide();
      }

      requestProofSetup({ parameters, initialize }, () => {
        addEmail(initialize);

        redirectToCreateOr(() => {
          if (onSelect) {
            onSelect(1);
          }
        });
      })
    },
    availability: ['proof'],
  },
};

const thirdParty = {
  'dropbox': {
    icon: '/img/interface/3rd-party/dropbox.svg',
    label: <Translation value="import.dropbox" />,
    url: '/import/dropbox',
    availability: ['proof'],
  },
  'google-drive': {
    icon: '/img/interface/3rd-party/drive.svg',
    label: <Translation value="import.google-drive" />,
    url: '/import/drive',
    availability: ['proof'],
  },
  'box': {
    icon: '/img/interface/3rd-party/box.svg',
    label: <Translation value="import.box" />,
    url: '/import/box',
    availability: ['proof'],
  },
  'onedrive': {
    icon: '/img/interface/3rd-party/onedrive.svg',
    label: <Translation value="import.onedrive" />,
    url: '/import/onedrive',
    availability: ['proof'],
  },
};

const map = (obj, callback) => {
  return Object.keys(obj).map((key) => {
    return callback(obj[key], key, obj);
  });
};

const SelectFileOptions = ({
  type = 'proof', // or 'brief'
  direction = 'right',
  multiple = false,
  parameters,
  initialize,
  children,
  onSelect,
  disabled,
  tooltipTitle,
}) => {
  const [isDarkMode] = useDarkMode();
  const variant = isDarkMode ? 'dark' : 'light';

  return (
    <Popover
      padding={false}
      variant={variant}
      arrow
      disabled={disabled}
      offset={16}
      {...(() => {
        switch (direction) {
          case 'right': return { right: true, middle: true };
          case 'down': return { down: true, center: true };
          case 'up': return { up: true, center: true };
          case 'left': return { left: true, middle: true };
        }
      })()}
      content={popover => (
        <SelectFileOptions.Options
          type={type}
          popover={popover}
          multiple={multiple}
          parameters={parameters}
          initialize={initialize}
          variant={variant}
          onSelect={onSelect}
        />
      )}
    >
      {popover => (
        <div>
          <Tooltip
            up
            center
            title={tooltipTitle}
            disabled={popover.isVisible || tooltipTitle === undefined}
          >
            <div>
              {children}
            </div>
          </Tooltip>
        </div>
      )}
    </Popover>
  );
};

SelectFileOptions.Options = ({ type, popover, multiple, parameters, initialize, variant, onSelect }) => {
  return (
    <div className={css.SelectFileOptions}>
      {[native, thirdParty].map((options, index, arr) => (
        <Fragment key={index}>
          {map(options, ({ icon, label, url, onClick, optionProps = {}, appendToOption, availability }, key) => {
            const isAvailable = availability.indexOf(type) !== -1;
            return (
              <Option
                disabled={!isAvailable}
                variant={variant}
                key={key}
                label={
                  <div className={css.SelectFileOptions__option}>
                    <InlineSVG
                      src={icon}
                      className={classname(css.SelectFileOptions__option__icon, {
                        [css['SelectFileOptions__option__icon--disabled']]: !isAvailable,
                      })}
                    />
                    <span>
                      {label}
                    </span>
                    {(appendToOption && isAvailable) && appendToOption({ type, multiple, parameters, initialize, popover, onSelect })}
                  </div>
                }
                onClick={(event) => {
                  if (!isAvailable) {
                    return;
                  }
                  if (url) {
                    const { $location, $rootScope } = window.__pageproof_bridge__;
                    const currentUrl = $location.url();
                    $location
                      .url(url)
                      .search('proofType', type)
                      .search('back', currentUrl)
                      .search('multiple', multiple)
                      .search('parameters', parameters && JSON.stringify(parameters))
                      .search('initialize', initialize && JSON.stringify(initialize));
                    $rootScope.$apply();
                  } else if (onClick) {
                    onClick({ event, type, multiple, parameters, initialize, popover, onSelect });
                  }
                }}
                {...optionProps}
              />
            );
          })}
          {(index !== arr.length - 1) && (
            <Separator variant={variant} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

SelectFileOptions.drop = ({ type = 'proof', multiple, parameters, initialize }, files) => {
  if (type === 'proof') {
    requestProofSetup({ parameters, initialize }, () => {
      addLocalFiles(files, initialize, parameters, multiple, redirectToCreateOr);
    });
  }
  else if (type === 'brief') {
    beginBriefSetup(files[0], parameters && parameters.updateProof);
  }
};

export default SelectFileOptions;
