/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState, useRef } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import { useHighPrecisionCurrentTime } from './utils/useHighPrecisionCurrentTime';
import css from './VideoPlayerTime.scss';
import Tooltip from '../Tooltip';
import { PopupMenu, Option } from '../PopupMenu';

function parseInputToSeconds(input) {
  input.trim();

  let seconds = 0;
  const parts = input.split(':');

  const secondsString = parts.pop();
  if (secondsString) {
    seconds += +secondsString;
  }

  const minutesString = parts.pop();
  if (minutesString) {
    seconds += minutesString * 60;
  }

  const hoursString = parts.pop();
  if (hoursString) {
    seconds += hoursString * 3600;
  }

  return seconds;
}

export const VideoPlayerTime = ({
  isPlaying,
  currentTime: throttledCurrentTime,
  getCurrentTime,
  durationTime,
  timePresentation,
  setTimePresentation,
  canChangeTimePresentation,
  scrub,
  pause,
  play,
}) => {
  const lockRef = useRef(null);
  const [input, setInput] = useState();

  const [currentTime] = useHighPrecisionCurrentTime({
    isPlaying,
    currentTime: throttledCurrentTime,
    getCurrentTime,
  });

  function onSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (lockRef.current && lockRef.current.hasEdited) {
      scrub(parseInputToSeconds(input));
    }

    if (lockRef.current && lockRef.current.wasPlaying) {
      play();
    }

    lockRef.current = null;
    setInput(undefined);
  }

  function onFocus(event) {
    setInput(event.target.value);
    event.target.select();
    lockRef.current = {
      ...lockRef.current,
      wasPlaying: isPlaying,
    };
    pause();
  }

  function onChange(event) {
    if (isPlaying) {
      lockRef.current = {
        ...lockRef.current,
        wasPlaying: true,
      };
      pause();
    }

    lockRef.current = {
      ...lockRef.current,
      hasEdited: true,
    };

    setInput(event.target.value);
  }

  const renderedValue = typeof input !== 'undefined'
    ? input
    : timePresentation.fn(currentTime, durationTime);

  return (
    <div className={css.VideoPlayerTime}>
      <form className={css.VideoPlayerTime__form} onSubmit={onSubmit}>
        <Tooltip
          title="Jump to time"
          up
          center
          disabled={typeof input !== 'undefined'}
        >
          <input
            value={renderedValue}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={() => onSubmit()}
            className={classname(css.VideoPlayerTime__input, 'unstyled')}
            style={{ width: `calc(${renderedValue.length}ch + 8px)`, color: '#616161' }}
            disabled={timePresentation.type !== 'simple'}
            aria-label="Jump to time"
          />
        </Tooltip>
        /
        <span style={{color: '#616161'}}>
          {timePresentation.fn(durationTime)}
        </span>
      </form>
      {canChangeTimePresentation && (
        <PopupMenu
          options={
            <Fragment>
              <Option
                label="Basic"
                onClick={() => setTimePresentation('simple')}
                checked={timePresentation.type === 'simple'}
              />
              <Option
                label="SMPTE timecode"
                onClick={() => setTimePresentation('smpte')}
                checked={timePresentation.type === 'smpte'}
              />
            </Fragment>
          }
        >
          {menu => (
            <div>
              <Tooltip
                title="Change time format"
                up
                center
                disabled={menu.isVisible}
              >
                <button
                  className={css.VideoPlayerTime__timePresentationButton}
                  aria-label="Change time format"
                >
                  <InlineSVG
                    src="img/icons/material/unfold_more_black_24dp.svg"
                    className={css.VideoPlayerTime__timePresentationButton__icon}
                  />
                </button>
              </Tooltip>
            </div>
          )}
        </PopupMenu>
      )}
    </div>
  );
};
