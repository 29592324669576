/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useCallback } from 'react';
import { useLocalStorage } from '../components/LocalStorage';

export function usePageGridSettings() {
  const [settings, setSettingsLocal] = useLocalStorage('pageproof.app.page-grid-settings', {
    zoomLevel: 1,
  });

  const setSettings = useCallback(changes => (
    setSettingsLocal(previousSettings => ({
      ...previousSettings,
      ...changes,
    }))
  ), []);

  return [
    settings,
    setSettings,
  ];
}
