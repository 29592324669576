/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { PureComponent } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import PinLine from './PinLine';
import styles from './Pin.scss';

class Pin extends PureComponent {
  render() {
    const {
      leftPosition,
      pinColour,
      isSelected,
      hasDuration,
      isEditingAllowed,
      onMouseDown,
      onBlur,
      onClick,
      direction,
      lineWidth,
      onPinHover,
    } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <span
        className={classname(styles.PinContainer, 'js-pin-on-hover')}
        style={{ left: leftPosition + '%' }}
        onMouseOver={onPinHover}
      >
        <span
          className={classname(
            styles.Pin,
            {
              [styles['Pin--selected']]: isSelected,
              [styles['Pin--with-arrows']]: isEditingAllowed && isSelected,
            },
          )}
          style={{ backgroundColor: pinColour }}
          onMouseDown={onMouseDown}
          onBlur={onBlur}
          onClick={onClick}
          tabIndex="0"
        />
        {hasDuration &&
          <PinLine
            colour={pinColour}
            direction={direction}
            width={lineWidth}
            selected={isSelected}
          />}
      </span>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  Pin.propTypes = {
    leftPosition: PropTypes.number.isRequired,
    pinColour: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    hasDuration: PropTypes.bool,
    isEditingAllowed: PropTypes.bool,
    onMouseDown: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    direction: PropTypes.string,
    lineWidth: PropTypes.number,
    onPinHover: PropTypes.func.isRequired,
  };
}

export default Pin;
