/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import scaledUpAppearing from './ScaledUpAppearing.scss';
import ScaledUpAppearing from './ScaledUpAppearing';
import slideUp from './SlideUp.scss';
import SlideUp from './SlideUp';
import scaledDownAppearing from './ScaledDownAppearing.scss';
import ScaledDownAppearing from './ScaledDownAppearing';

export {
  scaledUpAppearing,
  ScaledUpAppearing,
  slideUp,
  SlideUp,
  scaledDownAppearing,
  ScaledDownAppearing,
};
