/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import TagsComponent from '../../components/Tags';
import { TranslatedProps } from '../../components/Text';

const Tags = (props) => {
  const placeholderTranslationKey = props.tags.length === 0 ? 'default.placeholder.add-tags' : '';

  return (
    <TranslatedProps placeholder={placeholderTranslationKey}>
      <TagsComponent {...props} />
    </TranslatedProps>
  );
};

Tags.propTypes = {
  ...TagsComponent.propTypes,
};

export default Tags;
