/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { sdk } from '../../../../util/sdk';

export default function ensureCollection(proofSetup) {
  if (proofSetup.shared.addToCollection) {
    return Promise.resolve({ id: proofSetup.shared.addToCollection.id });
  } else if (proofSetup.shared.collection) {
    return sdk.proofs.groups.create({
      name: proofSetup.shared.collection,
      isOwnedByTeam: proofSetup.shared.isOwnedByTeam,
    });
  // TODO: PP-6808 remove this condition
  // This handles existing proof setups that didn't have `shared.addToCollection` populated from `parameters.attachNewVersion`
  } else if (proofSetup.parameters.attachNewVersion && proofSetup.parameters.attachNewVersion.collectionId) {
    return Promise.resolve({ id: proofSetup.parameters.attachNewVersion.collectionId });
  } else {
    return Promise.resolve(null);
  }
}
