/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { createStitchEmbed } from '../../util/createStitchEmbed';

export const ChecklistTemplatePreview = createStitchEmbed('ChecklistTemplatePreview', {
  iframeProps: {
    style: {
      minHeight: 120,
    },
  },
  observeHeight: true,
});
