/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Desktop from '../Comment/Icon/Desktop';
import Mobile from '../Comment/Icon/Mobile';
import Responsive from '../Comment/Icon/Responsive';
import Tablet from '../Comment/Icon/Tablet';

const categoryIcons = {
  desktop: Desktop,
  mobile: Mobile,
  tablet: Tablet,
  responsive: Responsive,
};

function DeviceCategoryIcon({
  deviceCategory,
  ...props,
}) {
  const Icon = categoryIcons[deviceCategory];
  return (
    <Icon {...props} />
  );
}

export default DeviceCategoryIcon;
