/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';
import ExpandingSection from './ExpandingSection';

export const PeopleSection = ({ open, canOpen, preview, readOnly, children, walkthroughHook }) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  const middleware = elementOrCallback => (
    typeof elementOrCallback === 'function'
      ? elementOrCallback(setIsOpen)
      : elementOrCallback
  );

  return (
    <ExpandingSection
      walkthroughHook={walkthroughHook}
      preview={middleware(preview)}
      expand={children && isOpen && canOpen}
      onPreviewClick={() => {
        if (!isOpen && !canOpen) {
          return;
        }
        setIsOpen(!isOpen);
      }}
      canOpen={canOpen}
      readOnly={readOnly}
    >
      {children && middleware(children)}
    </ExpandingSection>
  );
};
