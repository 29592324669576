/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import PropTypes from 'prop-types';
import css from './LinkSummaryCounts.scss';
import LinkSummaryDot from './LinkSummaryDot';

const LinkSummaryCounts = ({ ok, warning, error }) => (
  <div className={css.LinkSummaryCounts__container}>
    {ok > 0 &&
    <LinkSummaryDot
      status="OK"
      count={ok}
      showCount
      hidePopover
    />
    }
    {warning > 0 &&
    <LinkSummaryDot
      status="WARNING"
      count={warning}
      showCount
      hidePopover
    />
    }
    {error > 0 &&
    <LinkSummaryDot
      status="ERROR"
      count={error}
      showCount
      hidePopover
    />
    }
  </div>
);

if (process.env.NODE_ENV === 'development') {
  LinkSummaryCounts.propTypes = {
    ok: PropTypes.number.isRequired,
    error: PropTypes.number.isRequired,
    warning: PropTypes.number.isRequired,
  };
}

export default LinkSummaryCounts;
