/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import EncryptedThumbnail from '../EncryptedThumbnail';
import css from './ProofShortcut.scss';

function ProofShortcut({ fileId, title, href, onClick }) {
  return (
    <a
      href={href}
      className={css.ProofShortcut}
      onClick={onClick}
    >
      <div className={css.ProofShortcut__thumb}>
        <EncryptedThumbnail
          className={css.ProofShortcut__thumb__img}
          id={fileId}
          alt={title}
        />
      </div>
      <div className={css.ProofShortcut__title}>
        {title}
      </div>
    </a>
  );
}

if (process.env.NODE_ENV !== 'production') {
  ProofShortcut.propTypes = {
    fileId: PropTypes.string.isRequired,
    title: PropTypes.string,
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };
}

export default ProofShortcut;
