/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
* Unauthorized copying of this file, via any medium is strictly prohibited.
* Proprietary and confidential.
*/
/* eslint-env browser */
import { useEffect, useLayoutEffect, useState } from 'react';

export const useSize = (ref) => {
  const [size, setSize] = useState(null);

  const updateSize = () => {
    const clientRect = ref.current.getBoundingClientRect();
    setSize({
      width: clientRect.width,
      height: clientRect.height,
    });
  };

  useLayoutEffect(updateSize, [ref]);

  useEffect(() => {
    const observer = new ResizeObserver(updateSize);
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref]);

  return size;
};
