import { useRef, useCallback } from 'react';

const useDebounce = (func, wait) => {
  const funcRef = useRef();
  funcRef.current = func;

  return useCallback(window.debounce((...args) => funcRef.current(...args), wait), []);
};

export default useDebounce;
