/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './Metadata.scss';

function Metadata({
  children,
  variant,
  compact,
}) {
  return (
    <div
      className={classname(css.Metadata, {
        [css['Metadata--' + variant]]: variant,
        [css['Metadata--compact']]: compact,
      })}
    >
      {children}
    </div>
  );
}
Metadata.defaultProps = {
  variant: 'default',
};

if (process.env.NODE_ENV !== 'production') {
  Metadata.propTypes = {
    variant: PropTypes.string,
  };
}

export default Metadata;
