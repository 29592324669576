/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { sdk } from '../../util/sdk';
import ProfileAvatar from '../ProfileAvatar';

function UserProfileAvatarContainer({ userId }) {
  const onAddAvatar = file => sdk.profile.uploadAvatar(file);
  const onResetAvatar = () => sdk.profile.deleteAvatar();

  return (
    <ProfileAvatar
      userId={userId}
      onAddAvatar={onAddAvatar}
      onResetAvatar={onResetAvatar}
    />
  );
}

if (process.env.NODE_ENV !== 'production') {
  UserProfileAvatarContainer.propTypes = {
    userId: PropTypes.string.isRequired,
  };
}

export default UserProfileAvatarContainer;
