/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* global localStorage */
import cookies from './cookies';
import { onSessionSaved } from './accounts';
import { normaliseUTCToISO } from '../quark/src/util/date-time-utils';

function getSavedSession() {
  const sessionData = localStorage.getItem('pageproof.app.userData');
  if (sessionData === null) {
    return null;
  }
  const sessionObj = JSON.parse(sessionData);
  const defaultFeatures = {
    supportChat: true,
    createNewProofs: true,
    proofReference: false,
    publicProofs: false,
    defaultProofIsPublic: false,
  };
  return {
    privateKeyPEM: sessionObj.encObj.privateKeyUserPEM,
    privateKeyPEM$: sessionObj.encObj.privateKeyUserSendable,
    publicKeyPEM: sessionObj.encObj.publicKeyUserPEM,
    serverPublicKeyPEM: sessionObj.encObj.publicKeyServerPEM,
    sharedKey: sessionObj.encObj.userSharedSecretKey,
    token: sessionObj.encObj.userToken,
    user: {
      accountType: sessionObj.accountType,
      email: sessionObj.userEmail,
      hasActivated: sessionObj.isActivated,
      id: sessionObj.userId,
      isAdmin: sessionObj.isDomainAdmin,
      lastLoginDate: new Date(normaliseUTCToISO(sessionObj.lastLogin)),
      lastUploadDate: new Date(normaliseUTCToISO(sessionObj.lastUpload)),
      name: '',
      teamLogo: sessionObj.brandingUrl,
      teamType: sessionObj.teamType,
      features: sessionObj.features || defaultFeatures,
      teamId: sessionObj.teamId,
    },
    userId: sessionObj.userId,
    redirectUrl: sessionObj.redirectUrl,
  };
}

function saveSession(session) {
  const sessionObj = {
    userId: session.userId,
    userEmail: session.user.email,
    encObj: {
      userToken: session.token,
      userSharedSecretKey: session.sharedKey,
      publicKeyServerPEM: session.serverPublicKeyPEM,
      publicKeyUserPEM: session.publicKeyPEM,
      privateKeyUserPEM: session.privateKeyPEM,
      privateKeyUserSendable: session.privateKeyPEM$,
    },
    userAvatar: '',
    accountType: session.user.accountType,
    lastUpload: session.user.lastUploadDate,
    isActivated: session.user.hasActivated,
    isDomainAdmin: session.user.isAdmin,
    lastLogin: session.user.lastLoginDate,
    brandingUrl: session.user.teamLogo,
    teamType: session.user.teamType,
    features: session.user.features,
    redirectUrl: session.redirectUrl,
    teamId: session.user.teamId,
  };
  const sessionData = JSON.stringify(sessionObj);
  localStorage.setItem('pageproof.app.userData', sessionData);
  onSessionSaved(session.userId);
  try {
    // set userId in cookies for other subdomains to use
    cookies.set('userId', session.userId + '; domain=.' + window.env('root_domain', 'pageproof.com'));
  } catch (err) {
    console.log(err);
  }
}

function removeSavedSession() {
  localStorage.removeItem('pageproof.app.userData');
}

export {
  getSavedSession,
  saveSession,
  removeSavedSession,
};
