/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import classname from 'classname';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import SearchIcon from '../Icon/SearchIcon';
import css from './CollapsibleSearch.scss';
import Search from './Search';

const CollapsibleSearch = ({
  onSearch,
  onToggleDisplay,
  placeholder,
  canClose,
  theme,
}, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [value, setValue] = useState('');
  const searchInput = useRef(null);
  const updateSearch = useCallback(
    window.debounce((keyword) => {
      onSearch(keyword);
    }, 500),
    []
  );

  const onSearchChange = (val) => {
    setValue(val);
    updateSearch(val);
  };

  const onClearSearch = () => {
    onSearchChange('');
    focusSearch();
  };

  const focusSearch = () => searchInput.current.focus();

  const toggleDisplay = (bool) => {
    setIsVisible(bool);
    if (bool) {
      focusSearch();
    }
    onToggleDisplay(bool);
  };

  useImperativeHandle(ref, () => ({
    forceClear: () => {
      onSearchChange('');
      toggleDisplay(false);
    },
  }));

  return (
    <div className={classname(css.CollapsibleSearch, [css['CollapsibleSearch--' + theme]])}>
      {!isVisible &&
        <SearchIcon
          size={20}
          className={css.CollapsibleSearch__icon}
          onClick={() => toggleDisplay(true)}
        />
      }
      <div className={classname(
        css.CollapsibleSearch__box,
        { [css['CollapsibleSearch__box--show']]: isVisible },
      )}
      >
        <Search
          theme={theme}
          searchTerm={value}
          searchRef={searchInput}
          placeholder={placeholder}
          onSearch={onSearchChange}
          onBlur={() => {
            if (!value) {
              toggleDisplay(false);
            }
          }}
          onClearSearch={canClose && onClearSearch}
        />
      </div>
    </div>
  );
};

const CollapsibleSearchWithRef = forwardRef(CollapsibleSearch);

CollapsibleSearch.defaultProps = {
  placeholder: 'default.placeholder.search',
  canClose: false,
};

if (process.env.NODE_ENV !== 'production') {
  CollapsibleSearch.propTypes = {
    onSearch: PropTypes.func,
    canClose: PropTypes.bool,
    placeholder: PropTypes.string,
    onToggleDisplay: PropTypes.func,
  };
}

export default CollapsibleSearchWithRef;
