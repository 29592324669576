/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/**
 * Return the truncated string with middle ellipsis
 * @param {string} text The text to truncate
 * @param {HTMLElement} element The parent element of the text to calculate the width
 * @param {string} ellipsis Default is '...'
 * @returns {[string, string, string, string] | [string]}
 */
const truncateStringWithMiddleEllipsis = (text, element, ellipsis = '...') => {
  if (!text || !element) {
    return [''];
  }

  const canvas = document.createElement('canvas');
  canvas.width = element.offsetWidth;
  const maxWidth = element.offsetWidth;
  const ctx = canvas.getContext('2d');
  ctx.font = window.getComputedStyle(element).font;
  const metrics = ctx.measureText(text);
  const needsTruncation = metrics.width > maxWidth;

  if (!needsTruncation) {
    return [text];
  }

  let leftIndex = 0;
  while (ctx.measureText(text.slice(0, leftIndex + 1) + ellipsis).width < maxWidth / 2) {
    leftIndex += 1;
  }

  const remaining = text.slice(leftIndex).split('').reverse().join('');

  let rightIndex = 0;
  while (ctx.measureText(remaining.slice(0, rightIndex + 1)).width < maxWidth / 2) {
    rightIndex += 1;
  }

  const left = text.slice(0, leftIndex);
  const middle = text.slice(leftIndex, text.length - rightIndex);
  const right = text.slice(text.length - rightIndex);

  return [left, middle, ellipsis, right];
};

export default truncateStringWithMiddleEllipsis;
