/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, Fragment } from 'react';
import { sdk } from '../../util/sdk';
import { MAX_SHARE_LINK_MESSAGE_LENGTH } from '../../util/constants';
import guardOnChangeLength from '../../util/guardOnChangeLength';
import commaSeparatedEmails from '../../util/commaSeparatedEmails';
import { Translation, TranslatedProps, Ellipsis, useText } from '../Text';
import Textarea from '../Textarea';
import EmailAddressEdit from '../EmailAddressEdit';
import InputLimitCounter from '../InputLimitCounter';
import Suggestions, { addEmail } from '../Suggestions';
import { Button } from '../Button';
import CopyLink from './CopyLink';
import css from './ShareLink.scss';

const ShareLink = ({ proofId, url, title, onSend, heading }) => {
  const text = useText();
  const [inputEmail, setInputEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const filterNewEmails = targetEmails => (
    commaSeparatedEmails(targetEmails, null)
      .filter((email) => {
        const isNewEmail = emails.indexOf(email) === -1;
        if (isNewEmail) addEmail(email);
        return isNewEmail;
      })
  );

  const onSubmit = (submittedEmail) => {
    setEmails(prevState => [...prevState, ...filterNewEmails(submittedEmail)]);
    setInputEmail('');
  };

  const onUpdateEmail = (value, previousValue) => {
    const index = emails.indexOf(previousValue);
    const updatedEmails = [...emails];

    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const onRemoveEmail = (targetEmail) => {
    setEmails(prevState => prevState.filter(email => email !== targetEmail));
  };

  const sendShareLink = () => {
    setIsSending(true);

    sdk.proofs.sendShareLink({
      proofId,
      message,
      emailAddresses: [...emails, ...filterNewEmails(inputEmail)],
    }).then(() => {
      setInputEmail('');
      setEmails([]);
      setMessage('');
      setIsSending(false);

      if (onSend) {
        onSend();
      }
    });
  };

  return (
    <div className={css.ShareLink}>
      <div className={css.ShareLink__heading}>
        {heading}
      </div>
      <div className={css.ShareLink__reviewerEmails}>
        <div className={css.ShareLink__reviewerEmails__emails}>
          {emails.map(email => (
            <EmailAddressEdit
              key={email}
              email={email}
              onDelete={onRemoveEmail}
              onUpdate={onUpdateEmail}
            />
          ))}
        </div>
        <Suggestions
          exclusions={emails}
          onSelect={onSubmit}
        >
          <input
            autoFocus
            placeholder={text('proof.utilities.share.email.placeholder')} // The Suggestions component requires <input /> as a direct child for attaching events, so we can't use TranslatedProps component here as it will break Suggestions.
            className={css.ShareLink__reviewerEmails__inputEmail}
            value={inputEmail}
            onChange={event => setInputEmail(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onSubmit(inputEmail);
              }
            }}
            onBlur={() => onSubmit(inputEmail)}
          />
        </Suggestions>
      </div>
      <TranslatedProps placeholder="proof.utilities.share.message.placeholder">
        <Textarea
          variant="compact"
          className={css.ShareLink__message}
          value={message}
          onChange={guardOnChangeLength(message, MAX_SHARE_LINK_MESSAGE_LENGTH, setMessage)}
        />
      </TranslatedProps>
      <InputLimitCounter
        value={message}
        limit={MAX_SHARE_LINK_MESSAGE_LENGTH}
      />
      <div className={css.ShareLink__buttons}>
        <CopyLink
          url={url}
          title={title}
        />
        <Button
          variant="primary"
          autoWidth
          disabled={emails.length === 0 || isSending}
          label={isSending
            ? (
              <Fragment>
                <Translation value="proof.utilities.share.button.send.active" />
                <Ellipsis />
              </Fragment>
            )
            : <Translation value="proof.utilities.share.button.send" />
          }
          onClick={sendShareLink}
        />
      </div>
    </div>
  );
};

export default ShareLink;
