/* eslint-disable react/button-has-type */
/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, Fragment } from 'react';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import { useLocalStorage } from '../LocalStorage';
import Tooltip from '../Tooltip';
import css from './VideoBookmarkButton.scss';
import { Translation } from '../Text';
import { PopupMenu, Option } from '../PopupMenu';

export const VideoBookmarkButton = ({
  currentTime,
  proofId,
  scrub,
  pause,
  timePresentation,
}) => {
  const [bookMarkTime, _setBookmarkTime] = useLocalStorage('pageproof.app.videoBookmark', null);
  const [isPopupMenuOpened, setIsPopupMenuOpened] = useState(false);


  const getTimeStamp = () => {
    setBookmarkTime(currentTime);
  };

  const setBookmarkTime = (time) => {
    if (!bookMarkTime || !bookMarkTime[proofId]) {
      const newBookmarkTimes = Object.assign({}, bookMarkTime);
      newBookmarkTimes[proofId] = time;
      _setBookmarkTime(newBookmarkTimes);
    }
  };

  const removeBookmarkTime = () => {
    if (bookMarkTime && (bookMarkTime[proofId] || bookMarkTime[proofId] === 0)) {
      const newBookmarkTimes = Object.assign({}, bookMarkTime);
      delete newBookmarkTimes[proofId];
      _setBookmarkTime(newBookmarkTimes);
    }
  };

  const scrubToTime = () => {
    scrub(bookMarkTime[proofId]);
    pause();
  };
  const isProofBookmarked = bookMarkTime && bookMarkTime.hasOwnProperty(proofId);

  return isProofBookmarked ? (
    <PopupMenu
      up
      onShow={() => setIsPopupMenuOpened(true)}
      onHide={() => setIsPopupMenuOpened(false)}
      options={() => (
        <Fragment>
          <Option
            label={<Translation
              value="proof.utilities.bookmark.tooltip"
            />}
            disabled
          />
          <Option
            label={
              <div className={css.labelIcon}>
                <Translation value="proof.utilities.bookmark.go-to-bookmark" />
                <InlineSVG
                  src="img/icons/material/bookmark_add.svg"
                  className={css.labelIcon__activeIcon}
                />
              </div>
            }
            onClick={() => scrubToTime()}
          />
          <Option
            label={
              <div className={css.labelIcon}>
                <Translation value="proof.utilities.bookmark.unset" />
                <InlineSVG
                  src="img/icons/material/bookmark_add.svg"
                  className={css.labelIcon__unsetIcon}
                />
              </div>
            }
            onClick={() => {
              removeBookmarkTime();
              setIsPopupMenuOpened(false);
            }}
          />
        </Fragment>
      )}
      offset={10}
    >
      <Bookmark
        showTooltip={!isPopupMenuOpened}
        isProofBookmarked={isProofBookmarked}
        onClick={getTimeStamp}
        bookMarkTime={bookMarkTime}
        timePresentation={timePresentation}
        proofId={proofId}
        isPopupMenuOpened={isPopupMenuOpened}
      />
    </PopupMenu>
  ) : (
    <Bookmark
      showTooltip
      isProofBookmarked={isProofBookmarked}
      onClick={getTimeStamp}
      bookMarkTime={bookMarkTime}
      timePresentation={timePresentation}
      proofId={proofId}
      isPopupMenuOpened={isPopupMenuOpened}
    />
  );
};

const Bookmark = ({ onClick, isProofBookmarked, bookMarkTime, timePresentation, proofId, isPopupMenuOpened }) => (
  <Tooltip
    up
    center
    title={<Translation
      value={isProofBookmarked ? 'proof.media-player.video.bookmark-video' : 'proof.utilities.bookmark.tooltip'}
      params={{ time: bookMarkTime ? timePresentation.fn(bookMarkTime[proofId]) : null }}
    />}
    maxWidth={150}
    disabled={isPopupMenuOpened}
  >
    <button
      className={classname(css.VideoBookmarkButton, {
        [css.Icon_active]: isProofBookmarked,
      })}
      onClick={onClick}
      aria-label="Bookmark"
    >
      <InlineSVG
        src="img/icons/material/bookmark_add.svg"
        className={classname(css.Icon__icon, css['VideoPlayerButton__icon--pop'])}
      />
    </button>
  </Tooltip>
);

export {
  Bookmark,
};
