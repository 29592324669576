/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import { PopupMenu, Option, Separator } from '../../PopupMenu';
import css from './InputOptions.scss';

export default function InputOptions({ absolute, options, popoverProps, maxHeight, color, buttonStyle }) {
  return (
    <PopupMenu
      down
      popoverProps={popoverProps}
      maxHeight={maxHeight}
      options={options}
    >
      <button
        type="button"
        className={classname(css.InputOptions, css[`InputOptions--${color}`], {
          [css['InputOptions--absolute']]: absolute,
        })}
        style={buttonStyle}
        aria-label="More options"
      >
        <InlineSVG
          className={css.InputOptions__icon}
          src="img/content/proof/icons/metadata/more.svg"
        />
      </button>
    </PopupMenu>
  );
}

InputOptions.Option = Option;
InputOptions.Separator = Separator;
