/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
// TODO(jacob) I think this HoC could probably be cleaned up
import React, {Component} from 'react';

const noop = () => {};

function withEventListeners(ChildComponent) {
  return class extends Component {
    static displayName = `withEventListeners(${ChildComponent.displayName || ChildComponent.name})`;

    constructor(props) {
      super(props);

      const cleanup = this.cleanup = [];
      const removeEventListener = (node, event, handler, capture) => {
        node.removeEventListener(event, handler, capture);
      };
      this.removeEventListener = removeEventListener;
      this.addEventListener = (node, event, handler, capture) => {
        if (node && node.addEventListener) {
          const off = removeEventListener.bind(null, node, event, handler, capture);
          off.handler = handler;
          cleanup.push(off);
          node.addEventListener(event, handler, capture);
          return off;
        } else {
          return noop;
        }
      };
    }

    componentWillUnmount() {
      this.cleanup.forEach(fn => fn());
    }

    render() {
      return (
        <ChildComponent
          {...this.props}
          addEventListener={this.addEventListener}
          removeEventListener={this.removeEventListener}
        />
      );
    }
  };
}

export {
  withEventListeners,
};
