/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import {Translation} from '../../components/Text';
import {Button} from '../../components/Button';
import css from './ShowMoreStyles.scss';

function ShowMore({
  showMore,
  showAll,
  onShowMore,
  onShowAll,
}) {
  return (
    <div className={css.ShowMore}>
      {showMore &&
        <Button
          label={<Translation value="show-more" />}
          onClick={onShowMore}
        />
      }
      {showAll &&
        <Button
          label={<Translation value="show-all" />}
          onClick={onShowAll}
        />
      }
    </div>
  );
}

export default ShowMore;
