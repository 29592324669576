/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { useState } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import { Translation, useText } from '../Text';
import { Button } from '../Button';
import FormControl from '../FormControl';
import Input from '../Input';
import Switch from '../Switch';
import { MAX_COLLECTION_NAME_LENGTH } from '../../util/constants';
import css from './ManageBox';

const ManageBox = ({
  tabData,
  onCancel,
  color,
  onAction,
  disabled,
}) => {
  const text = useText();
  const [isValid, setIsValid] = useState(false);
  const [input, setInput] = useState('');

  const handleClick = () => onAction(input);

  const updateInput = (value) => {
    setInput(value);
    setIsValid(value && value.length <= MAX_COLLECTION_NAME_LENGTH);
  };

  return (
    <div className={classname(css.ManageBox, css[`ManageBox--${color}`])}>
      <FormControl>
        <Input
          placeholder={text(tabData.placeholder)}
          type="text"
          compact
          value={input}
          maxLength={MAX_COLLECTION_NAME_LENGTH}
          autoFocus
          onChange={val => updateInput(val)}
        />
      </FormControl>
      {tabData.switchEnabled &&
        <div className={css.ManageBox__switch}>
          <span className={css.ManageBox__switch__label}>
            <Translation value={tabData.switchLabel} />
          </span>
          <Switch
            value={tabData.switchValue}
            onChange={tabData.onToggleSwitch}
          />
        </div>
      }
      <Button
        className={css['ManageBox__action-button']}
        variant="outline"
        size="small"
        disabled={!isValid || disabled}
        label={<Translation value={tabData.buttonLabel} />}
        onClick={handleClick}
      />
      <Button
        className={css['ManageBox__cancel-button']}
        variant="text"
        label={<Translation value={tabData.cancelButton} />}
        onClick={onCancel}
      />
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  ManageBox.propTypes = {
    tabData: PropTypes.objectOf(PropTypes.any).isRequired,
    onCancel: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };
}

export default ManageBox;
