/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Fragment } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import { Enum } from '@pageproof/sdk';
import classname from 'classname';
import moment from 'moment';
import { sdk } from '../../util/sdk';
import Tooltip from '../Tooltip';
import Ellipsis from '../Text/Ellipsis';
import ProgressCircle from '../ProgressCircle';
import { PopupMenu, Option, ConfirmActionOption } from '../PopupMenu';
import Translation from '../Text/Translation';
import UserAvatarsList from '../UserAvatarsList';
import styles from './ProofTileStyles.scss';
import FormatDate from '../Date/FormatDate';
import FromNow from '../Date/FromNow';

class TileFooter extends Component {
  state = {
    isClicked: false,
    isPopupMenuOpened: false,
  };

  updateHover = (bool) => {
    this.setState({
      isPopupMenuOpened: bool,
    });
  }

  updateClick = () => {
    this.setState({
      isClicked: true,
    });
  }

  hidePopup = (menu) => {
    menu.hide();
    return false;
  }

  render() {
    const { isClicked, isPopupMenuOpened } = this.state;
    const {
      footerOptions,
      proof,
      proofType,
      printType,
      userId,
      isActive,
      status,
      editorUserIds,
    } = this.props;
    const isHovered = isActive || isPopupMenuOpened;

    const isUserAdmin = proof.isUserAdminOfProofTeam(sdk.session.user);
    const canManage = (
      footerOptions.manage.enabled &&
      (
        proof.isOwner(userId) ||
        isUserAdmin ||
        (proof.type === Enum.ProofType.BRIEF && userId === proof.recipient)
      )
    );
    const canPrint = footerOptions.print.enabled && proof.fileStatus === 'OK';
    const canDownload = footerOptions.download.enabled && proof.canDownload && proof.fileStatus === 'OK';
    const showViewLinkOption = footerOptions.collection.viewLink.enabled;

    return (
      <div
        className={classname(
          styles.ProofTile__footer,
          { [styles.ProofTile__footer__hovered]: isHovered },
          { [styles.ProofTile__footer__editor]: editorUserIds },
        )}
      >
        <div className={styles['ProofTile__footer__status-box']}>
          {editorUserIds && (
            <div className={styles.ProofTile__footer__avatar}>
              <UserAvatarsList
                userIds={editorUserIds}
                size={20}
                showEmails={false}
                space={-2}
                showTooltip
              />
            </div>
          )}
          <div className={styles.ProofTile__footer__status}>
            <Translation
              value={`dashboard.proof.state.${status}`}
              params={{ dueDate: (status === 'active' && !!proof.dueDate && <DueDateMessage dueDate={proof.dueDate} />) }}
            />
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <ul
          className={styles.ProofTile__footer__options}
          onClick={(event) => {
            if (!event.target.classList.contains(styles.ProofTile__footer__options)) {
              event.stopPropagation();
            }
          }}
        >
          {footerOptions.info.enabled &&
            <Tooltip
              title={<Translation value={`dashboard.proof.options.info.${proofType}`} />}
              maxWidth={150}
              up
              center
            >
              <li
                role="presentation"
                className={styles.ProofTile__footer__options__info}
                onClick={(event) => {
                  event.preventDefault();
                  footerOptions.info.onClick(proof);
                }}
              >
                <InlineSVG src="img/interface/info.svg" />
              </li>
            </Tooltip>
          }
          {footerOptions.collection.enabled && proof.groupId &&
            <PopupMenu
              up
              center
              onShow={() => this.updateHover(true)}
              onHide={() => this.updateHover(false)}
              options={menu => (
                <Fragment>
                  {(showViewLinkOption) &&
                    <Option
                      key="collection.view-link"
                      label={<Translation value="dashboard.proof.options.collection.view-link" />}
                      onClick={(event) => {
                        event.stopPropagation();
                        footerOptions.collection.viewLink.onClick(proof.groupId);
                        return this.hidePopup(menu);
                      }}
                    />
                  }
                  <Option
                    key="collection.copy-link"
                    label={<Translation value="dashboard.proof.options.collection.copy-link" />}
                    onClick={(event) => {
                      event.stopPropagation();
                      footerOptions.collection.onCopy(proof.groupId);
                      return this.hidePopup(menu);
                    }}
                  />
                  {(proof.isOwner(userId) || isUserAdmin) &&
                    <Fragment>
                      <ConfirmActionOption
                        onClick={() => {
                          this.updateClick();
                          footerOptions.collection.onRemove(proof.id)
                            .then(() => this.hidePopup(menu));
                        }}
                        iconSrc="img/content/proof/icons/delete.svg"
                        label={
                          <Fragment>
                            <Translation
                              value={isClicked
                                ? 'dashboard.proof.options.collection.remove-from-collection.active'
                                : 'dashboard.proof.options.collection.remove-from-collection'
                              }
                            />
                              &nbsp;
                            {isClicked && <Ellipsis />}
                          </Fragment>
                        }
                        confirmLabel={<Translation value="dashboard.proof.options.collection.remove-from-collection.confirm" />}
                      />
                      <Option
                        label={<Translation value="dashboard.proof.options.collection.manage-collection" />}
                        onClick={(event) => {
                          event.stopPropagation();
                          footerOptions.collection.onManageOpen(proof.groupId);
                          return this.hidePopup(menu);
                        }}
                      />
                    </Fragment>
                  }
                </Fragment>
              )}
            >
              <li
                role="presentation"
                className={styles.ProofTile__footer__options__collection}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  maxWidth={150}
                  up
                  center
                  disabled={isPopupMenuOpened}
                  title={<Translation value="dashboard.proof.options.collection" />}
                >
                  <div>
                    <InlineSVG src="img/content/proof/icons/collection_icon.svg" />
                  </div>
                </Tooltip>
              </li>
            </PopupMenu>
          }
          {canManage &&
            <Tooltip
              maxWidth={150}
              up
              center
              title={<Translation value={`dashboard.proof.options.manage.${proofType}`} />}
            >
              <li
                role="presentation"
                className={styles.ProofTile__footer__options__manage}
                onClick={(event) => {
                  event.preventDefault();
                  footerOptions.manage.onClick(proof);
                }}
              >
                <InlineSVG src="img/interface/settings.svg" />
              </li>
            </Tooltip>
          }
          {canPrint &&
            <PopupMenu
              up
              center
              onShow={() => this.updateHover(true)}
              onHide={() => this.updateHover(false)}
              options={menu => (
                <Fragment>
                  <Option
                    label={<Translation value="print-options.option.title" />}
                    disabled
                  />
                  <Option
                    label={<Translation value="print-options.option.new" />}
                    onClick={() => {
                      footerOptions.print.onPrint(proof);
                      return this.hidePopup(menu);
                    }}
                  />

                  <Option
                    label={<Translation value="print-options.option.legacy" />}
                    onClick={() => {
                      footerOptions.print.onPrintLegacy(proof, printType);
                      this.hidePopup(menu);
                    }}
                  />

                </Fragment>
              )}
            >
              <li
                role="presentation"
                onClick={event => event.preventDefault()}
                className={styles.ProofTile__footer__options__print}
              >
                <Tooltip
                  maxWidth={150}
                  up
                  center
                  title={<Translation value="dashboard.proof.options.print" />}
                  disabled={isPopupMenuOpened}
                >
                  <div>
                    <InlineSVG src="img/interface/print.svg" />
                  </div>
                </Tooltip>
              </li>
            </PopupMenu>
          }
          {canDownload &&
            <Tooltip
              maxWidth={150}
              up
              center
              title={<Translation value="proof.utilities.download.tooltip" />}
            >
              <li
                role="presentation"
                className={classname(
                  styles.ProofTile__footer__options__download,
                  { [styles.ProofTile__footer__options__downloading]: proof.$$downloadProgress }
                )}
                onClick={(event) => {
                  event.preventDefault();
                  footerOptions.download.onClick(proof);
                }}
              >
                {proof.$$downloadProgress &&
                  <Fragment>
                    <ProgressCircle
                      size={20}
                      width={3}
                      value={Math.max(2, proof.$$downloadProgress)}
                    />
                    <InlineSVG src="img/interface/close.svg" />
                  </Fragment>
                }
                {!proof.$$downloadProgress &&
                  <div>
                    <InlineSVG src="img/interface/download.svg" />
                  </div>
                }
              </li>
            </Tooltip>
          }
          {footerOptions.lock.enabled && proof.isLocked &&
            <Tooltip
              title={<Translation value="dashboard.proof.options.locked" />}
              up
              center
            >
              <li className={styles.ProofTile__footer__options__lock}>
                <InlineSVG src="img/interface/padlock.svg" />
              </li>
            </Tooltip>
          }
        </ul>
      </div>
    );
  }
}

const DueDateMessage = ({ dueDate }) => {
  const now = moment();
  const dueDateMomentObject = moment(dueDate);
  const daysFromNow = dueDateMomentObject.diff(now, 'days');
  if (dueDateMomentObject.isSame(now, 'day')) {
    return <Translation value="proof.due-date-message.due-today-at-time" params={{ time: <FormatDate date={dueDate} formatTo={dueDateMomentObject.format('mm') === '00' ? 'h a' : 'h:mm a'} /> }} />;
  } else if (daysFromNow > 7) {
    const yearDue = dueDateMomentObject.isSame(now, 'year') ? '' : ' YYYY';
    return <Translation value="proof.due-date-message.due-on-date" params={{ date: <FormatDate date={dueDate} formatTo={'MMM Do' + yearDue} /> }} />;
  } else {
    return <Translation value="proof.due-date-message.due-from-now" params={{ fromNow: <FromNow date={dueDate} /> }} />;
  }
};

export default TileFooter;
