/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useUserFeatures } from '../../../hooks/useUserFeatures';

export default function getFallbackIsPublic() {
  const [, userFeatures] = useUserFeatures();
  if (!userFeatures) {
    return false;
  }
  return userFeatures.hasPublicProofs
    ? userFeatures.defaultProofIsPublic
    : false;
}
