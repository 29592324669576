/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './IndeterminateLoaderOverlay.scss';

const IndeterminateLoaderOverlay = ({ loading, children, className, onClick, width, height }) => (
  <div
    className={classname(css.IndeterminateLoaderOverlay, className)}
    onClick={onClick}
    style={{ width, height }}
  >
    <div className={classname({ [css.IndeterminateLoaderOverlay__loading]: loading })} />
    {children}
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  IndeterminateLoaderOverlay.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
  };
}

export default IndeterminateLoaderOverlay;
