/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import AgreeStar from '../Icon/Agree';
import AgreeStarOutline from '../Icon/AgreeOutline';
import AgreeValentines from '../Icon/AgreeValentines';
import AgreeSnowflake from '../Icon/AgreeSnowflake';
import AgreeValentinesOutline from '../Icon/AgreeValentinesOutline';
import AgreeClover from '../Icon/AgreeClover';
import AgreeWomen from '../Icon/AgreeWomen';
import AgreeWomenOutline from '../Icon/AgreeWomenOutline';
import AgreePumpkin from '../Icon/AgreePumpkin';
import AgreeEarthDay from '../Icon/AgreeEarthDay';
import AgreeEarthDayOutline from '../Icon/AgreeEarthDayOutline';
import AgreePrideMonthRainbow from '../Icon/AgreePrideMonthRainbow';
import AgreePrideMonth from '../Icon/AgreePrideMonth';
import AgreeNewYearBlack from '../Icon/AgreeNewYearBlack';
import AgreeNewYearGreen from '../Icon/AgreeNewYearGreen';
import AgreeEaster from '../Icon/AgreeEaster';
import AgreeEasterOutline from '../Icon/AgreeEasterOutline';
import AgreeCoronation from '../Icon/AgreeCoronation';
import { IS_VALENTINES_DAY, IS_CHRISTMAS, IS_ST_PATRICKS_DAY, IS_INTERNATIONAL_WOMENS_DAY, IS_HALLOWEEN, IS_EARTH_DAY, IS_PRIDE_MONTH, IS_NEW_YEAR, IS_EASTER, IS_CORONATION_DAY } from '../../../util/special-days';

/* eslint-disable no-nested-ternary, no-multi-spaces, indent, quote-props */

const DefaultAgreeComponent = AgreeStarOutline;

const AgreeComponent = (
    IS_VALENTINES_DAY ?           AgreeValentinesOutline
  : IS_CHRISTMAS ?                AgreeSnowflake
  : IS_ST_PATRICKS_DAY ?          AgreeClover
  : IS_INTERNATIONAL_WOMENS_DAY ? AgreeWomenOutline
  : IS_HALLOWEEN ?                AgreePumpkin
  : IS_EARTH_DAY ?                AgreeEarthDayOutline
  : IS_PRIDE_MONTH ?              AgreePrideMonth
  : IS_NEW_YEAR ?                 AgreeNewYearBlack
  : IS_EASTER ?                   AgreeEasterOutline
  : IS_CORONATION_DAY ?           AgreeCoronation
  :                               DefaultAgreeComponent
);

const DefaultAgreeComponentActive = AgreeStar;

const AgreeComponentActive = (
    IS_VALENTINES_DAY ?           AgreeValentines
  : IS_CHRISTMAS ?                AgreeSnowflake
  : IS_ST_PATRICKS_DAY ?          AgreeClover
  : IS_INTERNATIONAL_WOMENS_DAY ? AgreeWomen
  : IS_HALLOWEEN ?                AgreePumpkin
  : IS_EARTH_DAY ?                AgreeEarthDay
  : IS_PRIDE_MONTH ?              AgreePrideMonthRainbow
  : IS_NEW_YEAR ?                 AgreeNewYearGreen
  : IS_EASTER ?                   AgreeEaster
  : IS_CORONATION_DAY ?           AgreeCoronation
  :                               DefaultAgreeComponentActive
);

const killjoys = { // MD5 hash user_uuid list
  'de632c5e83f5ef2f3739b7ccce8a81c3': IS_PRIDE_MONTH, // conversation id: 12878400082414
  'b2ceeae7bf4678f4c17e6215fa2c1057': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '9d177f09e69523578522477b6737fdd4': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'e8fb0804401cce5b0a5dd69952ad888d': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'c1a3258426bb53835665716a60be9361': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'c2e57e93591508ae9f44d4ba65a7dfb2': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'b454edd4848d5a885368e431bf3d6924': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '51991ad3bbe019f62ba48cb88f644c5d': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '9178f2146cdd886ebd7f51461945739e': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '0f827f130f75f6eade882a4697007c59': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'da66c56dcbdb26409360f2df2ccb4e04': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '256b069e76b68037ae94340303dbcf46': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '8806094a17a6b23573b12dad2f32e377': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '8c0e52d8c5646d5f04cd1d7792b2724c': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '482a9070d824bb3e95dfb35f26da2cb1': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'd037ed08835050ee6af65b5e52434318': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'c24601cfb21ab78db9cc3f235cf0ed91': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'f0a55970a409705bcc65d551d39b0f0d': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '23baba5e9885780bbd853789b3144a78': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '7e43fce950272e4a74f5fad2c136800a': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'b8f2cf9b22ac7224292877fa012662dc': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'ede4a72378caa7835ee9e6032ed40214': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '9e0e7b5e1dff1c787f7bcd25c7766e3c': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '79bdcea1e892d98fab974142efa89993': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '19f7f64bea911f39ad2fe839d35d099d': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '877bdd954de8d42f57a0408ba91462ca': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'a58fa86f08611491d44aa06de082067d': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '94708e2f969d4e3cf13ed480d7283fed': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '42c77f086aa8e1fb313953fe50562fde': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '4f3107eb0fab25b28dc2d3db3f5eff28': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '9bda4b77814d7e29a95f8650f531bed5': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '8d9ac08c230ca6c19d999f99e71833d0': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'b50a0aee26a0be0fe7f50df273263ec0': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '744072aa4d8c702a04c25f047828baaa': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '220d2b4626a8aa7a961772224cd42131': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'aa4f024fd5a2067fa341f5722a6acf91': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '6d2625ae30d58bf99837e80d6565c855': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'd8d7f30463fcdd634b9bb134b713b427': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '32a4dbbdd0a8892fd7565d7a11f29f81': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '2d7d73a67d2c6fc847c89e8a5ec31c81': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'b643284f5c2a8f7c665b141d6079c149': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '968bf00270f1f0955b0824229ca066be': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'c049ce10469258b327892ea662cd6b7a': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '5916d75a8028ca5dda7413322fb820b3': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '6159c8db004368d58c722da050e35811': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '698bfa740c7a2f83c479b1a66f5aa566': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'be2169a3e47f30c48d932d2f9c08f39f': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'baa7a688b0bccd434633dabdc1bc0662': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '827917f12553297e893198c8688ea7b7': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '65310c3f03f1406a041c6889a67d1ba6': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '6eb13ffc4b63f25bc261883a8234a9ab': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '923ef0e73fa33f46071446a7f5ab28b1': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '063ecbce69914c1d0c6fdd0fe4ac00bd': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'd02d90fb839b2ace8d60eab5283b589b': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '9267de93541a2b02923309129e5a4961': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'a2a1a186b0487b84917975609f3cba5d': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '415ab5d652cc22cc24cb756e52ab5915': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'e13d72f64477761cfa5434013e56d385': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '885a7bbeba2aa2596ae474f6d6b9bd33': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '14a8aeabd23f1bf3a553b04a4469e226': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '7447649ff3738d61ef0127f8132a68aa': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '49d0ae5c3f82e36feabc99e1a607d94a': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '0da0d8fe1640b2dc7f7c959eb96374db': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'c341b149a12190981ac32bdde2f35eb4': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '0888eae5daa512359e0fd003429d71f0': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'a29d2ef394d9bbbfa2e087a1f60df1d0': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'b7edf75b0fdb690f60c3fdd1be6d8c11': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '73b0fdae5734af2e1b0c6520060fc840': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '4caf69fe9c6bfdd0055c555086c2f800': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'a00e2fbb00611060f51c3abd658a55b0': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'cf3cc17945ff55fa68de6cb9c9bba205': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '5e6fb8fd1806b03a472a8b662bdf0f06': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'dfb708b92a567cd9844bc328df74a72d': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'f72e7acf80663855c16a550c2e6654d4': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '267c64c8eb4a70dd047ac1b5f2859c1a': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '967d74a84c9fa83937bd871a69ce5829': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '580a22e85101b25d0ca3444a52ea4da3': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '9b37a1a7d1418f3106da886b80cfcf19': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '25aee6ca23c47306b367b2b7e5cb92f6': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'f9b5c20aec979353871b9e5c5bd04138': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '948418242194211f013774444dc6fbb8': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '1f9f3358e64f16c2c78e24e3d767fbf0': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '5af9a27e5b892c47666f77c51b53dc0c': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '95ce44c7ba51e4244a7bcc8c971e885b': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '9756e17a4e20857efd9afe55c20664ad': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '2293cec41c120e99e7dc0d61717ee496': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'f61f7ff5455847a2dde8ca1e6092904f': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '472243a7c3c304da8c0625c913623a7b': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '6d9d24a3c8f2fe7cf2ded859499fd78b': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'e319c7e70529c58ca9eb43175f56795d': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '2474d36b6bf98125f686ee7ca28c9f91': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'fca87973f2d0670bfc88b586d6b8cd68': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '229eb9e14d00621c7385d46e62bb2fd2': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '5c86ad8d861b84889c31cf367b75d235': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'bdee2dd8eed8c66b5c722599962595c4': IS_PRIDE_MONTH, // conversation id: 12878400176512
  'ee535cba47142f2f55262f1a27c9e044': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '9e79fd0e3a69fc2d4e98110e60d7bd8f': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '8c296b1ad8f57b87a055a1c232d6f118': IS_PRIDE_MONTH, // conversation id: 12878400176512
  '32f5b6a408da092975ab16c4c4fe57a6': IS_PRIDE_MONTH, // conversation id: 12878400303077
  '78b63aea5e20eafd4bf654c1ee173aa4': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '02af944279db0531c9d34e57bb5d8be8': IS_PRIDE_MONTH, // conversation id: 12878400304560
  'b3a811cf6ca891e2817711174006f384': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '0d56cf13a557fddcb136a7f1d1c26118': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '1e2d67ce6744d8978552482cf5ba6cb1': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '6b0f380f37b0221247cf2398f4ac7054': IS_PRIDE_MONTH, // conversation id: 12878400304560
  'bc2bd743b86f6fa7a7fe12a7c2a30dcb': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '37b6ed5b433859cf3afa1701d7b64e6f': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '666ed52b75ebb71497c840315c7e9f2b': IS_PRIDE_MONTH, // conversation id: 12878400304560
  'f9417cb7813b78b11eb5ce804332d236': IS_PRIDE_MONTH, // conversation id: 12878400304560
  'de3ad4f8ea070dd491e8130e8d236d6b': IS_PRIDE_MONTH, // conversation id: 12878400304560
  'b750bdbfc49ebf7ad76d4adcfb8f8a6b': IS_PRIDE_MONTH, // conversation id: 12878400304560
  'ff621acb9a7caba617256e7a75b4f18f': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '3a3d1ef2476291bf25e50b014e2b33a8': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '837f6612e4496ca5496c67cf6c43c50c': IS_PRIDE_MONTH, // conversation id: 12878400304560
  '78a57c12cde09edfcc10965ef07ea43e': IS_PRIDE_MONTH,
};

function Agree(props) {
  let Component = props.active ? AgreeComponentActive : AgreeComponent;

  const userHash = document.documentElement.id.slice(1);
  if (killjoys[userHash]) {
    Component = props.active ? DefaultAgreeComponentActive : DefaultAgreeComponent;
  }

  return <Component {...props} />;
}

Agree.propTypes = {
  active: PropTypes.bool,
};

// Used for the comment pane header icons
Agree.iconProps = AgreeComponent.iconProps;

export default Agree;
