/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import DiscardButtonOnHover from '../DiscardButtonOnHover';
import Tooltip, { onlyWhenTruncated } from '../Tooltip/Tooltip';

function Tag({ value, onClick, imported }) {
  return (
    <Tooltip
      up
      center
      title={value}
      maxWidth={false}
      onBeforeOpen={onlyWhenTruncated('.Tag')}
    >
      <div>
        <DiscardButtonOnHover onDiscard={onClick}>
          <div className={classname({ 'Tag--imported': imported })}>
            <div className="Tag">
              {value}
            </div>
          </div>
        </DiscardButtonOnHover>
      </div>
    </Tooltip>
  );
}

export default Tag;
