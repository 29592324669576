/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { Button } from '../Button';
import VideoWithControls from '../VideoWithControls';
import ButtonGroup from '../ButtonGroup';
import SteppedProgressBar from '../SteppedProgressBar';
import css from './WalkthroughStep.scss';

export const WalkthroughStep = ({ videoSrc, posterSrc, heading, description, buttonProps, progressBarProps }) => (
  <div className={css.WalkthroughStep}>
    {progressBarProps && (
      <div className={css.WalkthroughStep__progress}>
        <SteppedProgressBar
          theme="subtle"
          size="small"
          {...progressBarProps}
        />
      </div>
    )}
    {videoSrc && (
      <VideoWithControls
        src={videoSrc}
        posterSrc={posterSrc}
        style={{ borderRadius: 5 }}
      />
    )}
    <div className={css.WalkthroughStep__heading}>{heading}</div>
    <div className={css.WalkthroughStep__description}>{description}</div>
    <ButtonGroup align="right">
      <Button
        variant="text"
        autoWidth
        {...buttonProps}
      />
    </ButtonGroup>
  </div>
);

export default WalkthroughStep;
