/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Visible from '@pageproof/visible';
import { sdk } from '../../util/sdk';
import { ProofTile } from '../../components/InfoPane';
import DragSourceWrapper from '../DragDrop/DragSourceWrapper';
import styles from './DashboardProofs.scss';
import { sortObjectsByKey } from '../../util/sort';

const sorts = {
  'comment-count': sortObjectsByKey('commentCount'),
  'create-date': sortObjectsByKey('createdDate'),
  'due-date': sortObjectsByKey('dueDate'),
  name: sortObjectsByKey('name'),
  status: sortObjectsByKey('status'),
  'version-number': sortObjectsByKey('version'),
};

class DashboardProofTilesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proofs: this.getSortedProofs(props),
    };
    this.session = sdk.session;
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.allProofs).length !== Object.keys(this.props.allProofs).length ||
      nextProps.proofIds.join(',') !== this.props.proofIds.join(',') ||
      nextProps.allProofs !== this.props.allProofs ||
      nextProps.orderBy !== this.props.orderBy ||
      nextProps.allProofs !== this.props.allProofs ||
      nextProps.search !== this.props.search ||
      this.isDateFiltersChanged(nextProps) ||
      this.isProofChanged(nextProps) ||
      nextProps.reverseOrder !== this.props.reverseOrder) {
      this.setState({ proofs: this.getSortedProofs(nextProps) });
    }
  }

  // eslint-disable-next-line
  getSortedProofs(props) {
    const proofs = [];
    const finalProofs = [];
    const proofObjects = props.allProofs;

    props.proofIds.forEach((proofId) => {
      if (proofObjects[proofId]) {
        proofObjects[proofId].version = parseInt(proofObjects[proofId].version, 10);
        proofs.push(proofObjects[proofId]);
      }
    });

    if (props.orderBy) {
      const sortFn = sorts[props.orderBy];
      if (sortFn) {
        proofs.sort(sortFn);
      }
    }

    proofs.forEach((proof) => {
      const searchText = props.search && props.search.text && props.search.text.toLowerCase();
      if (searchText &&
        proof.name.toLowerCase().indexOf(searchText) === -1 &&
        proof.tags.every(tag => tag.toLowerCase().indexOf(searchText) === -1)) {
        return;
      }

      if (props.search && props.search.filters) {
        const filterArr = props.search.filters.split(',');
        if (!filterArr.map(Number).includes(proof.status)) {
          return;
        }
      }

      if (props.dateFilters.length) {
        const isProofHaveDate = this.isProofHaveDates(proof, props.dateFilters);
        if (!isProofHaveDate) {
          return;
        }
      }

      if (props.reverseOrder) {
        finalProofs.unshift(proof);
      } else {
        finalProofs.push(proof);
      }
    });

    if (props.didSearch) {
      props.didSearch(finalProofs);
    }
    return finalProofs;
  }

  isProofHaveDates = (proof, dateFilters) => dateFilters.some((dateFilter) => {
    switch (dateFilter.type) {
    case 'creation date':
      return (
        proof.createdDate &&
        proof.createdDate > dateFilter.startDate &&
        proof.createdDate < dateFilter.endDate
      );
    case 'approved date':
      return (
        proof.approvedDate &&
        proof.approvedDate > dateFilter.startDate &&
        proof.approvedDate < dateFilter.endDate
      );
    case 'due date':
      return (proof.dueDate &&
        proof.dueDate > dateFilter.startDate &&
        proof.dueDate < dateFilter.endDate
      );
    default:
      return false;
    }
  });

  isProofChanged = props => props.proofIds.some((proofId) => {
    if (props.allProofs[proofId] && this.props.allProofs[proofId] && props.allProofs[proofId] !== this.props.allProofs[proofId]) {
      const newProof = props.allProofs[proofId];
      const oldProof = this.props.allProofs[proofId];
      return (newProof.role !== oldProof.role);
    }
    return false;
  });

  isDateFiltersChanged = (nextProps) => {
    if (!!nextProps.dateFilters !== !!this.props.dateFilters ||
      nextProps.dateFilters.length !== this.props.dateFilters.length) {
      return true;
    } else if (!!nextProps.dateFilters === !!this.props.dateFilters &&
      nextProps.dateFilters && nextProps.dateFilters.length) {
      return nextProps.dateFilters && nextProps.dateFilters.length && nextProps.dateFilters.some(nextDateFilter => (
        this.props.dateFilters.some((dateFilter) => {
          if (dateFilter.type === nextDateFilter.type &&
            (dateFilter.startDate !== nextDateFilter.startDate ||
            dateFilter.endDate !== nextDateFilter.endDate)) {
            return true;
          }
          return false;
        })
      ));
    }
    return false;
  }

  shouldLoadThumbnail = (proof, userId) => {
    const shouldLoad = this.props.from === 'team-dashboard' ? proof.isAdminOnProof : !proof.canUserUploadNewVersion(userId);
    return (proof.hasThumbnail() && !proof.hasFileError() && shouldLoad);
  };

  isProofDraggable = proof => (
    proof.isOwner(this.session.userId) ||
    proof.isUserAdminOfProofTeam(this.session.user)
  );

  render() {
    const {
      proofIds,
      allProofs,
      allThumbnails,
      limit,
      onOpenProofInfo,
      userId,
      onDeferVisibility,
      from,
      view,
      manageItem,
      onAllowReupload,
      selectableProofIds,
      ...props
    } = this.props;
    const {
      proofs,
    } = this.state;

    const isFromDashboard = from === 'dashboard';

    return (
      <div className={styles.DashboardProofs}>
        {proofs.length > 0 && proofs.slice(0, limit === -1 ? proofs.length : limit).map(proof => (
          <Visible
            onVisibilityChange={() => {
              if (this.shouldLoadThumbnail(proof, userId)) {
                onDeferVisibility(proof);
              }
            }}
            untilVisible
            key={proof.id}
          >
            {() => (
              <div
                className={classname(
                  styles.DashboardProofs__proofs,
                  styles[`DashboardProofs__proofs--${view}`],
                )}
                key={proof.id}
              >
                <DragSourceWrapper
                  data={proof}
                  type="proof"
                  isDraggable={this.isProofDraggable(proof)}
                >
                  <ProofTile
                    footerOptions={props.footerOptions}
                    onOpenProofInfo={onOpenProofInfo}
                    proofData={proof}
                    url={allThumbnails[proof.fileId]}
                    uploadProofOptions={props.uploadProofOptions}
                    returnUrl={props.returnUrl}
                    hasUploader={isFromDashboard && proof.canUserUploadNewVersion(userId)}
                    userId={userId}
                    from={from}
                    manageItem={manageItem}
                    onSelectProof={() => this.props.onSelectProof(proof)}
                    view={view}
                    onAllowReupload={() => onAllowReupload(proof)}
                    isSelectableForBulkAction={selectableProofIds.includes(proof.id)}
                    onRefreshCurrentTab={props.onRefreshCurrentTab}
                  />
                </DragSourceWrapper>
              </div>
            )}
          </Visible>
        ))}
      </div>
    );
  }
}

DashboardProofTilesContainer.defaultProps = {
  from: 'dashboard',
  view: 'tile',
};

if (process.env.NODE_ENV !== 'production') {
  DashboardProofTilesContainer.propTypes = {
    allProofs: PropTypes.objectOf(PropTypes.any).isRequired,
    allThumbnails: PropTypes.objectOf(PropTypes.string).isRequired,
    proofIds: PropTypes.arrayOf(PropTypes.string),
    limit: PropTypes.number.isRequired,
    onOpenProofInfo: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    onDeferVisibility: PropTypes.func.isRequired,
    from: PropTypes.string,
    view: PropTypes.string,
    search: PropTypes.objectOf(PropTypes.any),
    onAllowReupload: PropTypes.func,
    didSearch: PropTypes.func,
  };
}

export default DashboardProofTilesContainer;
