/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState } from 'react';
import Input from '../components/Input';
import { openModal } from '../util/modal';
import ButtonGroup from '../components/ButtonGroup';
import Button from '../components/Button/Button';
import { Ellipsis } from '../components/Text';
import createWorkflow from '../components/ProofSetup/utils/finalize/createWorkflow';
import Translation from '../components/Text/Translation';

const SaveWorkflowTemplate = ({ workflow, owners, onCancel, onSubmit }) => {
  const [name, setName] = useState(workflow.name ? `${workflow.name} copy` : '');
  const [isSaving, setSaving] = useState(false);

  const save = (event) => {
    event.preventDefault();
    setSaving(true);
    createWorkflow(workflow, { name, owners })
      .then(workflowTemplate => onSubmit(workflowTemplate))
      .catch(onCancel);
  };

  return (
    <form onSubmit={save}>
      <h3>
        <Translation value="proof.setup.workflow.chooser.save-new-workflow-template.title" />
      </h3>
      <p>
        <Translation value="proof.setup.workflow.chooser.save-new-workflow-template.message" />
      </p>
      <Input
        value={name}
        onChange={newName => setName(newName)}
        style={{ width: '100%' }}
        autoFocus
      />
      <br />
      <br />
      <br />
      <ButtonGroup>
        <Button
          type="button"
          variant="text"
          label={<Translation value="button.cancel" />}
          onClick={onCancel}
        />
        <Button
          type="submit"
          disabled={isSaving || !name.trim()}
          label={isSaving
            ? (
              <Fragment>
                <Translation value="button.save.active" />
                <Ellipsis />
              </Fragment>
            )
            : <Translation value="proof.setup.workflow.chooser.save-new-workflow-template.save" />
          }
        />
      </ButtonGroup>
    </form>
  );
};

// eslint-disable-next-line import/prefer-default-export
export function saveWorkflowTemplate(workflow, owners) {
  return new Promise((resolve, reject) => {
    const { destroy: close } = openModal(
      <SaveWorkflowTemplate
        workflow={workflow}
        owners={owners}
        onCancel={() => close()}
        onSubmit={(workflowTemplate) => {
          resolve(workflowTemplate);
          close();
        }}
      />,
      () => reject(new Error('User dismissed the prompt workflow template name dialog.')),
    );
  });
}
