let PSDJSPromise = null;

function loadPSDJS() {
  if (!PSDJSPromise) {
    PSDJSPromise = new Promise((resolve, reject) => {
      const element = document.createElement('script');
      element.onload = () => {
        const PSDJSLib = window.psdjs;
        if (PSDJSLib) {
          resolve(PSDJSLib.exports);
        } else {
          reject(new Error('Failed to load PSD.js (script load did not populate global context with library).'));
        }
      };
      element.onerror = () => {
        reject(new Error('Failed to load PSD.JS (network error).'));
      };
      element.async = true;
      element.src = '/static/psdjs-3.2.0/psd.min.js';
      document.head.appendChild(element);
    });
  }
  return PSDJSPromise;
}

export default function getPSDJS() {
  return loadPSDJS();
}
