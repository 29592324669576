/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import Form from '../../components/Form';
import {Heading} from '../../components/Page';
import {Button} from '../../components/Button';
import {Translation} from '../../components/Text';
import {InlineSpinner} from '../../components/Spinner';


function clearIndexedDB(name) {
  return () => {
    const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
    return indexedDB.deleteDatabase(name);
  };
}

function clearLocalStorage() {
  return () => window.Storage.prototype.clear.apply(window.localStorage);
}

function clearSessionStorage() {
  return () => window.Storage.prototype.clear.apply(window.sessionStorage);
}

function clearCookies() {
  return () => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  };
}

function attempt(name, fn) {
  return new Promise((resolve, reject) => {
    try {
      Promise.resolve(fn())
        .then(resolve, (err) => {
          if (err) {
            err.attemptName = name; // eslint-disable-line no-param-reassign
          }
          reject(err);
        });
    } catch (err) {
      if (err) {
        err.attemptName = name;
      }
      reject(err);
    }
  });
}

class ResetLocalStorage extends Component {
  state = {
    clearing: false,
    error: null,
  };

  clear = () => {
    this.setState({clearing: true});
    setTimeout(() => {
      Promise.all([
        attempt('indexeddb', clearIndexedDB('pageproof')),
        attempt('sdk-cache', clearIndexedDB('@pageproof/sdk/adapters/IndexedDBCacheAdapter')),
        attempt('localStorage', clearLocalStorage()),
        attempt('sessionStorage', clearSessionStorage()),
        attempt('cookies', clearCookies()),
      ]).then(() => {
        window.location.href = '/login';
      }).catch((err) => {
        this.setState({
          clearing: false,
          error: err,
        });
      });
    });
  }

  render() {
    const {
      clearing,
      error,
    } = this.state;
    return (
      <Form>
        <Heading
          title={<Translation value="local-storage.reset.title" />}
          description={<Translation value="local-storage.reset.description" />}
        />
        {error &&
          <div>
            <Translation value="local-storage.reset.error" />
            <br />
            <pre>
              {window.btoa(JSON.stringify(
                error &&
                {
                  attempt: error.attemptName,
                  message: error.message,
                  stack: error.stack || error.stacktrace || error.trace,
                }
              ))}
            </pre>
            <br />
            <br />
            <br />
          </div>}
        <div style={{textAlign: 'center'}}>
          <Button
            label={
              clearing
                ? <InlineSpinner />
                : <span>{error ? <Translation value="local-storage.reset.try" /> : <Translation value="local-storage.reset.lets" />}</span>
            }
            disabled={clearing}
            onClick={this.clear}
          />
        </div>
      </Form>
    );
  }
}

export default ResetLocalStorage;
