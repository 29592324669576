/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import DeviceWrapper from '../DeviceWrapper';
import resize from '../../util/resize';

class AutoDeviceWrapper extends Component {
  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    this.element = findDOMNode(this);
    this.listener = resize(this.updateDimensions);
    this.updateDimensions();
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.focusMode !== this.props.focusMode) {
      this.updateDimensions();
    }
  }

  componentWillUnmount() {
    this.listener();
  }

  updateDimensions = () => {
    const { width, height } = this.element.getBoundingClientRect();
    this.setState({ width, height });
  }

  render() {
    return (
      <div className="AutoDeviceWrapper">
        <DeviceWrapper
          {...this.props}
          {...this.state}
        />
      </div>
    );
  }
}

export default AutoDeviceWrapper;
