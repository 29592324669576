/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { EditWarningWrapper } from '../EditWarningWrapper';
import Switch from '../../../components/Switch';

export function BooleanInput({ onConfigurationOptionInput, option, isUpdating }) {
  return (
    <EditWarningWrapper
      value={(
        <Switch
          value={option.value}
          disabled
          onChange={() => { }}
        />
      )}
      warningMessage={option.warningMessage}
    >
      <Switch
        value={option.value}
        onChange={() => {
          if (!isUpdating) {
            onConfigurationOptionInput(option.key, !option.value);
          }
        }}
      />
    </EditWarningWrapper>
  );
}

if (process.env.NODE_ENV !== 'production') {
  BooleanInput.propTypes = {
    onConfigurationOptionInput: PropTypes.func.isRequired,
    option: PropTypes.objectOf(PropTypes.any).isRequired,
    isUpdating: PropTypes.bool,
  };
}

export default BooleanInput;
