/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translation from '../../components/Text/Translation';
import { Integration } from '../../components/Integrations';
import { Tabs, Tab } from '../../components/Tabs';
import ZapierEmbed from './ZapierEmbed';
import IntegrationApps from '../../components/IntegrationApps';
import IntegrationSectionHeading from '../../components/IntegrationApps/IntegrationSectionHeading';
import getIntegrationFileUrl from '../../util/integration-service-static-files';

class IntegrationsContainer extends Component {
  state = {
    selectedTab: 'apps',
  };

  render() {
    const {
      integrations,
      updateProps,
      connectToZapier,
      updateIntegration,
    } = this.props;
    const zapierIntegration = integrations.filter(int => int.type === 'Zapier')[0];
    const integrationProps = {
      Slack: {
        tooltip: <Translation value="domain-admin.integrations.slack.tooltip" />,
        logo: getIntegrationFileUrl('logo-horizontal', 'slack'),
        logoDark: getIntegrationFileUrl('logo-horizontal', 'slack', ['dark']),
        learnHowLink: 'https://my.slack.com/services/new/incoming-webhook/',
        inputs: [
          {
            type: 'connectString',
            placeholderTranslationKey: 'domain-admin.integrations.webhook',
          },
        ],
      },
      MSTeams: {
        tooltip: <Translation value="domain-admin.integrations.msteams.tooltip" />,
        logo: getIntegrationFileUrl('logo-horizontal', 'ms_teams'),
        logoDark: getIntegrationFileUrl('logo-horizontal', 'ms_teams', ['dark']),
        learnHowLink: 'http://help.pageproof.com/articles/3094556-pageproof-microsoft-teams-integration',
        inputs: [
          {
            type: 'connectString',
            placeholderTranslationKey: 'domain-admin.integrations.webhook',
          },
        ],
      },
      Basecamp: {
        tooltip: <Translation value="domain-admin.integrations.basecamp.tooltip" />,
        logo: '/img/interface/3rd-party/basecamp.png',
        logoDark: '/img/interface/3rd-party/basecamp-dark.png',
        learnHowLink: 'https://help.pageproof.com/integrations/pageproof-basecamp-integration/',
        inputs: [
          {
            type: 'connectString',
            placeholderTranslationKey: 'domain-admin.integrations.chatbot',
          },
        ],
      },
      Zapier: {
        tooltip: <Translation value="domain-admin.integrations.zapier.tooltip" />,
        logo: '/img/interface/3rd-party/zapier-logo_light.svg',
        logoDark: '/img/interface/3rd-party/zapier-logo_dark.svg',
        learnHowLink: 'https://help.pageproof.com/integrations/setting-up-zapier-and-pageproof/',
        inputs: [
          {
            type: '',
            placeholderTranslationKey: '',
          }
        ],
      },
    };
    function areIntegrationsOn(array, value) {
      return array.some(arrayValue => arrayValue[value]);
    }

    function onBlur(event) {
      updateIntegration(event.target.name);
    }

    function toggleIntegration(value, name) {
      updateProps(name, value, 'enabled');
      updateIntegration(name);
    }

    return (
      <div className="Integrations">
        <h1>{areIntegrationsOn(integrations, 'enabled') ? <Translation value="domain-admin.integrations.team" /> : <Translation value="domain-admin.integrations.setup" />}</h1>
        <p className="Integrations__description"><Translation value="domain-admin.integrations.description" /></p>
        <br />
        <br />
        <br />
        <br />
        <Tabs selected={this.state.selectedTab}>
          <Tab
            id="apps"
            name={<Translation value="domain-admin.apps" />}
            onClick={() => this.setState({ selectedTab: 'apps' })}
          >
            <br />
            <IntegrationApps type="team" />
          </Tab>
          <Tab
            id="zapier"
            name={<Translation value="domain-admin.integrations.zapier-and-webhooks" />}
            onClick={() => this.setState({ selectedTab: 'zapier' })}
          >
            <br />
            <IntegrationSectionHeading
              title={<Translation value="integration.zapier" />}
            />
            <div className="Integrations__zapier-container">
              <Integration
                name={zapierIntegration.type}
                tooltip={integrationProps[zapierIntegration.type].tooltip}
                logo={integrationProps[zapierIntegration.type].logo}
                logoDark={integrationProps[zapierIntegration.type].logoDark}
                enabled={zapierIntegration.enabled}
                connectionString={zapierIntegration.connectString}
                onToggle={value => toggleIntegration(value, zapierIntegration.type)}
                onChange={value => updateProps(zapierIntegration.type, value, 'connectString')}
                onBlur={onBlur}
                connectToZapier={connectToZapier}
                learnHowLink={integrationProps[zapierIntegration.type].learnHowLink}
                inputs={integrationProps[zapierIntegration.type].inputs}
              />
              <div className="Integrations__subheading"><Translation value="widget-zapier-heading" /></div>
              <ZapierEmbed />
            </div>
            <br />
            <IntegrationSectionHeading
              title={<Translation value="integration.webhooks" />}
              message={<Translation value="integration.webhooks.description" />}
            />
            {integrations.filter(int => int.type !== 'Zapier').map((integration, index) => ( // All but Zapier, which is in another tab.
              <Integration
                key={index}
                name={integration.type}
                tooltip={integrationProps[integration.type].tooltip}
                logo={integrationProps[integration.type].logo}
                logoDark={integrationProps[integration.type].logoDark}
                enabled={integration.enabled}
                connectionString={integration.connectString}
                onToggle={value => toggleIntegration(value, integration.type)}
                onChange={value => updateProps(integration.type, value, 'connectString')}
                onBlur={onBlur}
                connectToZapier={connectToZapier}
                learnHowLink={integrationProps[integration.type].learnHowLink}
                inputs={integrationProps[integration.type].inputs}
              />
            ))}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  IntegrationsContainer.propTypes = {
    integrations: PropTypes.array, // eslint-disable-line
    updateProps: PropTypes.func,
    updateIntegration: PropTypes.func,
    connectToZapier: PropTypes.func,
  };
}

export default IntegrationsContainer;
