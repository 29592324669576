/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Button from './Button';
import Link from '../Link';

function LinkButton(props) {
  return (
    <Button
      component={Link}
      {...props}
    />
  );
}

export default LinkButton;
