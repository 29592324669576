/* eslint-disable consistent-return */

import React, { useMemo } from 'react';
import { EncryptedImage } from './EncryptedImage';

export const EncryptedHighPreview = ({ images, proofId, pageNumber, fallback = null, imageProps = {} }) => {
  const page = useMemo(
    () => images.images.pages.find(imagePage => imagePage.number === pageNumber),
    [images, pageNumber],
  );

  return (
    <EncryptedImage
      cacheKey={`preview proof:${proofId} page:${pageNumber} quality:high`}
      url={page.high.url}
      envelope={images.envelope.envelope}
      imageProps={imageProps}
      fallback={fallback}
    />
  );
};
