/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
* Unauthorized copying of this file, via any medium is strictly prohibited.
* Proprietary and confidential.
*/
import React from 'react';
import IconButton from '../Button/IconButton';
import Popover from '../Popover';
import Tooltip from '../Tooltip/Tooltip';
import { Translation } from '../Text';
import css from './ProofDownloadButton.scss';
import ProofDownloadPopover from '../ProofDownloadPopover';
import ProofDownloadButtonForMobileMenu from './ProofDownloadButtonForMobileMenu';

const ProofDownloadButton = props => (
  <Popover
    up
    center
    content={<ProofDownloadPopover {...props} />}
    variant="light"
    arrow
    offset={props.isMobile ? 15 : 0}
  >
    {popover => (
      props.isMobile
        ? (
          <div>
            <ProofDownloadButtonForMobileMenu onClick={popover.toggle} />
          </div>
        )
        : (
          <Tooltip
            up
            center
            title={<Translation value="proof.utilities.download.tooltip" />}
            maxWidth={270}
            disabled={popover.isVisible}
          >
            <IconButton
              ariaLabel="Download proof"
              src="img/interface/download.svg"
              className={css.ProofDownloadButton}
              onClick={popover.toggle}
            />
          </Tooltip>
        )
    )}
  </Popover>
);

export default ProofDownloadButton;
