/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { getMembers, addOrSetMember, removeMember } from './members';

export function getProofOwners(workflowId) {
  return getMembers(workflowId, 'WorkflowCopyToProof');
}

export function addProofOwner(workflowId, email) {
  return addOrSetMember(workflowId, email, 'WorkflowCopyToProof');
}

export function removeProofOwner(memberId) {
  return removeMember(memberId, 'WorkflowCopyToProof');
}
