/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { useEffect, useRef } from 'react';
import confetti from 'canvas-confetti';
import { IS_VALENTINES_DAY, IS_CHRISTMAS, IS_ST_PATRICKS_DAY, IS_HALLOWEEN, IS_FOURTH_OF_JULY, IS_PRIDE_MONTH, IS_INTERNATIONAL_WOMENS_DAY, IS_EARTH_DAY, IS_NEW_YEAR, IS_CHINESE_NEW_YEAR, IS_EASTER, IS_CORONATION_DAY } from '../../util/special-days';

const DEFAULT_OPTIONS = {
  particleCount: 60,
  spread: 110,
  zIndex: 1201, // Dialog pop up have currently 1200 of z-index, so to appear confetti on top of that
  ticks: 100,
  gravity: 0.7,
  startVelocity: 16,
  disableForReducedMotion: true,
};

function getDefaultColors() {
  switch (true) {
  case IS_VALENTINES_DAY:
    return ['#FEBBC4', '#FCF1F7', '#F79CAA', '#E92B5E', '#B51F3D', '#7B1228', '#FFFFFF'];
  case IS_HALLOWEEN:
    return ['#F36A1F', '#F3861F', '#FE8125', '#000000', '#FFFFFF'];
  case IS_INTERNATIONAL_WOMENS_DAY:
    return ['#5A137D', '#FFFFFF', '#FFE76C'];
  case IS_CHRISTMAS:
    return ['#FF0000', '#00FF00'];
  case IS_ST_PATRICKS_DAY:
    return ['#FFFFFF', '#138B3B', '#0D5B11', '#187C19', '#69B41E', '#8DC71E', '#B8D53D'];
  case IS_FOURTH_OF_JULY:
    return ['#3B3B6D', '#47518E', '#ffffff', '#B32134', '#D4273E'];
  case IS_PRIDE_MONTH:
    return ['#E64A39', '#E97439', '#EDD157', '#65ED99', '#5F8BE9', '#6F1BC6', '#000000', '#FFFFFF'];
  case IS_EARTH_DAY:
    return ['#0000FF', '#00FF00', '#FFFFFF', '#81CCF1', '#C6E3CD'];
  case IS_NEW_YEAR:
    return ['#FE8125', '#E51C23', '#5A137D', '#C6E3CD', '#7ED681', '#138B3B', '#81CCF1', '#2176AE'];
  case IS_CHINESE_NEW_YEAR:
    return ['#FFD700', '#C0C0C0', '#FAFAFA'];
  case IS_EASTER:
    return ['#E884C5', '#9EB0FF', '#FFFAB8', '#B4E8CB'];
  case IS_CORONATION_DAY:
    return ['#002F6C', '#C10016', '#F2F2F2'];
  default:
    return ['#138b3b', '#138b3b', '#138b3b', '#9E9E9E', '#0a461e', '#42a262', '#C6E3CD'];
  }
}

const Confetti = ({
  colors,
}) => {
  const confettiElemRef = useRef(null);

  useEffect(() => {
    const box = confettiElemRef.current.getBoundingClientRect();

    confetti({
      ...DEFAULT_OPTIONS,
      colors: colors || getDefaultColors(),
      origin: {
        x: box.left / window.innerWidth,
        y: box.top / window.innerHeight,
      },
    });
  }, []);

  return <span ref={confettiElemRef} />;
};

export default Confetti;
