/* eslint-disable */
// source: https://github.com/tkh44/shallow-compare/blob/4cf40c158a62e56eee2dc3e4460bbda6fa97d2e3/src/index.js

function shallowDiffers (a, b) {
  for (let i in a) if (!(i in b)) return true
  for (let i in b) if (a[i] !== b[i]) return true
  return false
}

export default shallowDiffers;
