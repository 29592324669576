import React from 'react';
import {withTimers} from '../../hoc/Timers';

class Delay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: !this.props.ms,
    };
  }

  componentDidMount() {
    if (this.props.ms) {
      this.props.setTimeout(() => {
        this.setState({
          complete: true,
        });
      }, this.props.ms);
    }
  }

  render() {
    if (typeof this.props.children === 'function') {
      return this.props.children(this.state.complete);
    }
    if (!this.state.complete) {
      return null;
    }
    return this.props.children;
  }
}

export default withTimers(Delay);
