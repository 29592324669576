/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, Component } from 'react';
import AuditSummaryPane from '../../components/AuditSummaryPane';
import AuditIcon from '../../components/AuditIcon';
import { sdk } from '../../util/sdk';
import Translation from '../../components/Text/Translation';
import Tooltip from '../../components/Tooltip';

class AuditSummaryPaneContainer extends Component {
  state = {
    isOpen: false,
    isLoading: true,
    summary: [],
    selectedEvent: null,
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.proofId !== nextProps && this.state.isOpen) {
      this.loadAuditSummary(nextProps.proofId);
    }
  }

  loadAuditSummary(proofId) {
    this.setState({
      isLoading: true,
    });
    sdk.proofs.audit.summary(proofId)
      .then((summary) => {
        this.setState({
          isLoading: false,
          summary,
        });
      });
  }

  open() {
    this.setState({ isOpen: true });
    this.loadAuditSummary(this.props.proofId);
  }

  close(closeParentPane = false) {
    this.setState({ isOpen: false }, () => {
      if (closeParentPane) {
        this.props.onClose();
      }
    });
  }

  render() {
    const {
      proofAuditFeature,
      proofManageMode,
      ...props
    } = this.props;
    const disabled = !proofAuditFeature;
    return (
      <Fragment>
        <AuditSummaryPane
          enableDownload={proofAuditFeature === 2}
          {...props}
          {...this.state}
          onSelectEvent={selectedEvent => this.setState({selectedEvent})}
          onClose={closeParentPane => this.close(closeParentPane)}
        />
        <Tooltip
          title={disabled
            ? <Translation value="audit-summary.disabled.tooltip" />
            : <Translation value="audit-summary.tooltip" />}
          down
          center
          maxWidth={150}
        >
          {tooltip => (
            <AuditIcon
              active
              onClick={() => { this.open(); tooltip.hide(); }}
              activeColor={proofManageMode && '#fff'}
              activeHoverColor={proofManageMode && '#ffffff99'}
              style={{
                padding: 2,
              }}
              disabled={disabled}
            />)}
        </Tooltip>
      </Fragment>
    );
  }
}

export default AuditSummaryPaneContainer;
