/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import env from '../../../shared/env';

const pageLoadTime = Date.now();

// This should be set as x.5 days so that the reload will usually happen on first proof view of the day
const DEFAULT_TIME_BEFORE_RELOAD_ON_NAVIGATION_IN_MS = 561600000; // 6.5 days

export const shouldReloadOnNavigation = () => (Date.now() - pageLoadTime) > (Number(env.time_before_reload_on_navigation_in_ms) || DEFAULT_TIME_BEFORE_RELOAD_ON_NAVIGATION_IN_MS);
