/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/prop-types */
/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import moment from 'moment';

import Translation from '../../components/Text/Translation';
import { ProfileDropdown } from '../../components/Profile';

import FormControl from '../../components/FormControl';
import DatePicker from '../../components/DatePicker';
import Input from '../../components/Input';
import { Button } from '../../components/Button';
import { getFormattedDate } from '../../util/date-time-utils';
import ButtonGroup from '../../components/ButtonGroup';
import styles from './Reporting.scss';
import Checkbox from '../../components/Checkbox';
import Suggestions from '../../components/Suggestions';
import Alert from '../../components/Alert';

class ReportingContainer extends Component {
  state = {
    report: null,
    buttonText: Translation.text('reporting.generating'),
    resultMessages: [],
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.requestFinished === false && nextProps.requestFinished === true) {
      const messages = [];

      if (nextProps.failed) {
        messages.push({
          severity: nextProps.messageSeverity || 'negative',
          key: nextProps.messageTranslationKey || 'reporting.failure',
          params: nextProps.messageTranslationParams || {},
        });
      } else {
        messages.push({
          severity: 'info',
          key: 'reporting.success',
        });
        if (nextProps.messageTranslationKey) {
          messages.push({
            severity: nextProps.messageSeverity || 'warning',
            key: nextProps.messageTranslationKey,
            params: nextProps.messageTranslationParams || {},
          });
        }
      }

      this.setState(previousState => ({
        resultMessages: messages,
        report: nextProps.failed ? previousState.report : null,
      }));
    }
  }

  dropDownOptions = (reportConfigurations) => {
    const config = reportConfigurations.map(c => ({ value: c.key, label: c.name }));
    return config;
  }

  formattedDate = (date) => {
    if (!date) {
      return '';
    }
    return moment(date).format('Do MMMM YYYY');
  }

  parseDateInput = (dateString) => {
    if (dateString === '') return '';
    const parsedDateString = getFormattedDate(dateString);
    return this.formattedDate(moment(parsedDateString, 'h:mma, Do MMMM YYYY'));
  }

  dateFromDateString = (dateString) => {
    if (dateString == null || dateString === '') { return null; }
    return moment(dateString, 'Do MMMM YYYY').toDate();
  }

  generateClicked = () => {
    this.setState({ resultMessages: [] });
    if (this.validate()) {
      // eslint-disable-next-line react/prop-types
      const config = this.props.reportConfigurations.filter(c => c.key === this.state.report)[0];
      const options = {};
      config.fields.forEach((field) => {
        if (field.type === 'Date') {
          options[field.key] = this.dateFromDateString(this.valueState(this.state.report, field.key));
        } else {
          const fieldValue = this.valueState(this.state.report, field.key);
          options[field.key] = fieldValue !== null ? fieldValue : field.default;
        }
      });
      this.props.generateReport(this.state.report, options);
    }
  }

  updateValueState = (report, fieldKey, fieldValue) => {
    this.setState(state => ({
      [report]: {
        ...(state[report] || {}),
        [fieldKey]: {
          ...((state[report] || {})[fieldKey] || {}),
          value: fieldValue,
        },
      },
    }));
  }

  updateErrorState = (report, fieldKey, fieldError) => {
    this.setState(state => ({
      [report]: {
        ...(state[report] || {}),
        [fieldKey]: {
          ...((state[report] || {})[fieldKey] || {}),
          error: fieldError,
        },
      },
    }));
  }

  valueState = (report, fieldKey) => {
    try {
      return this.state[report][fieldKey].value;
    } catch (err) {
      return null;
    }
  }

  errorState = (report, fieldKey) => {
    try {
      return this.state[report][fieldKey].error;
    } catch (err) {
      return null;
    }
  }

  validate = () => {
    let valid = true;
    const config = this.props.reportConfigurations.filter(c => c.key === this.state.report)[0];
    config.fields.forEach((field) => {
      if (field.required && !this.valueState(this.state.report, field.key)) {
        this.updateErrorState(this.state.report, field.key, <Translation value="reporting.missing-field" />);
        valid = false;
      } else if (!field.isRequired && field.counterRequiredFor &&
        !this.valueState(this.state.report, field.key) &&
        this.valueState(this.state.report, field.counterRequiredFor[0])) {
        this.updateErrorState(this.state.report, field.key, <Translation value="reporting.missing-field" />);
        valid = false;
      } else if (field.type === 'Email' && !window.validateEmail(this.valueState(this.state.report, field.key))) {
        this.updateErrorState(this.state.report, field.key, (
          <Translation
            value="reporting.invalid-email"
            params={{ email: this.valueState(this.state.report, field.key) }}
          />
        ));
        valid = false;
      } else {
        this.updateErrorState(this.state.report, field.key, null);
      }
    });

    return valid;
  }

  CheckBoxControl = (props) => {
    const { report } = this.state;
    const value = this.valueState(report, props.key);
    return (
      <FormControl
        key={props.key}
        label={<Translation value={props.label} />}
      >
        <div className={styles.Reporting__checkbox}>
          <Checkbox
            selected={value !== null ? value : props.default}
            onChange={(bool) => {
              this.updateValueState(report, props.key, bool);
            }}
          />
        </div>
      </FormControl>
    );
  }

  DateFormControl(props) {
    const { report } = this.state;
    const value = this.valueState(report, props.key);

    return <FormControl
      key={props.key}
      label={<Translation value={props.label} />}
      validation={this.errorState(report, props.key)}
      onDismissValidation={() => this.updateErrorState(report, props.key, null)}
    >
      <DatePicker
        onChange={date => this.updateValueState(report, props.key, this.formattedDate(date))}
      />
      <Input
        type="text"
        placeholder={props.required ? Translation.text('reporting.required') : ''}
        value={value || ''}
        onBlur={() => {
          this.updateValueState(report, props.key, this.parseDateInput(this.valueState(report, props.key)));
        }}
        onChange={date => this.updateValueState(report, props.key, date)}
      />

    </FormControl>;
  }

  EmailInputControl = (props) => {
    const { report } = this.state;
    const value = this.valueState(report, props.key);

    return <FormControl
      key={props.key}
      label={<Translation value={props.label} />}
      validation={this.errorState(report, props.key)}
      onDismissValidation={() => this.updateErrorState(report, props.key, null)}
    >
      <Suggestions
        exclusions={[value]}
        onSelect={newValue => this.updateValueState(report, props.key, newValue)}
      >
        <Input
          type="text"
          placeholder={props.required ? Translation.text('reporting.required') : ''}
          value={value || ''}
          onChange={newValue => this.updateValueState(report, props.key, newValue)}
        />
      </Suggestions>
    </FormControl>;
  }

  render() {
    if (this.props.reportConfigurations === null) {
      return (<div />);
    }

    let UIFields = <div />;
    if (this.state.report) {
      const config = this.props.reportConfigurations.filter(c => c.key === this.state.report)[0];
      UIFields = config.fields.map((f) => {
        switch (f.type) {
        case 'Checkbox':
          return this.CheckBoxControl(f);
        case 'Email':
          return this.EmailInputControl(f);
        case 'Date':
        default:
          return this.DateFormControl(f);
        }
      });
    }

    return (
      <div className={styles.Reporting}>
        <h1>{<Translation value="reporting.intro" />}</h1>
        <div className={styles.Reporting__messages}>
          <Alert variant="info"><div><Translation value="reporting.description" /></div></Alert>
        </div>
        <div className={styles.Reporting__subheading}>{<Translation value="reporting.choose" />}</div>

        <ProfileDropdown
          label={<Translation value="reporting.report" />}
          options={this.dropDownOptions(this.props.reportConfigurations)}
          placeholder={<Translation value="reporting.choose" />}
          onClick={reportChosen => this.setState({ report: reportChosen, resultMessages: [] })}
          selected={this.state.report}
        />
        {UIFields}

        <br />
        {this.state.report &&
          <ButtonGroup align="center">
            <Button
              variant="primary"
              label={this.props.requestFinished ? <Translation value="reporting.generate" /> : <Translation value="reporting.generating" />}
              className={styles.GenerateReportButton}
              onClick={() => this.generateClicked()}
            />
          </ButtonGroup>
        }
        <br />
        <div className={styles.Reporting__messages}>
          {this.state.resultMessages.map((message, index) => (
            <Alert
              key={index.toString()}
              variant={message.severity || 'info'}
            >
              <div>
                <Translation
                  value={message.key}
                  params={message.params || {}}
                />
              </div>
            </Alert>
          ))}
        </div>

      </div>
    );
  }
}

export default ReportingContainer;
