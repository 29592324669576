import React from 'react';
import MUITimePicker from 'material-ui/TimePicker';
import { useText } from '../Text';

const TimePicker = (props) => {
  const text = useText(); // Material UI v0 is using `unstable_renderSubtreeIntoContainer` which have an issue(https://github.com/facebook/react/issues/16721) that does not transfer context, so not able to pass in a Translation component here.

  return (
    <MUITimePicker
      okLabel={text('button.ok')}
      cancelLabel={text('button.cancel')}
      {...props}
    />
  );
};

if (process.env.NODE_ENV !== 'production') {
  TimePicker.propTypes = {
    ...MUITimePicker.propTypes,
  };
}

export default TimePicker;
