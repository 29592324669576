/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from './createIcon';

export default createIcon({
  displayName: 'Cross',
  src: '/img/interface/menu-close-icon.svg',
  activeColor: '#777',
  activeHoverColor: '#a1a1a1',
});
