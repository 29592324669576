/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, Fragment } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import { InlineSVG } from '@jmshal/react-inline-svg';
import css from './Folder.scss';
import Tooltip, { onlyWhenTruncated } from '../Tooltip/Tooltip';

const Folder = ({ title, children, color }) => {
  const [showFolder, setShowFolder] = useState(false);

  return (
    <Fragment>
      <Tooltip
        title={title}
        up
        center
        onBeforeOpen={onlyWhenTruncated(`.${css.Folder__header__title}`)}
      >
        <div
          className={classname(css.Folder__header, {
            [css['Folder__header--active']]: showFolder,
            [css[`Folder__header--${color}`]]: color,
          })}
          onClick={() => setShowFolder(!showFolder)}
        >
          <InlineSVG
            src="/img/interface/solid-arrow.svg"
            className={css.Folder__header__arrow}
          />
          <div className={css.Folder__header__title}>
            {title}
          </div>
        </div>
      </Tooltip>
      {showFolder && (
        <div className={css.Folder__content}>
          {children}
        </div>
      )}
    </Fragment>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Folder.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
  };
}

export default Folder;
