/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {isValidElement} from 'react';

const isSeparator = (item, seperatorType) => (
  item && isValidElement(item) && item.type === seperatorType
);

function filterSeparators(array, seperatorType) {
  const filtered = [];
  let previousSeparator = false;
  for (let i = 0; i < array.length; i += 1) {
    const item = array[i];
    if (!item) {
      continue; // eslint-disable-line
    }
    const currentSeparator = isSeparator(item, seperatorType);
    if (
      (currentSeparator && (i === 0 || filtered.length === 0)) ||
      (previousSeparator && currentSeparator)
    ) {
      previousSeparator = currentSeparator;
      continue; // eslint-disable-line
    }
    previousSeparator = currentSeparator;
    filtered.push(item);
  }
  if (previousSeparator) {
    filtered.pop();
  }
  return filtered;
}

export default filterSeparators;
