import linkifyIt from 'linkify-it';
import tlds from 'tlds'; // eslint-disable-line

const linkify = linkifyIt();
linkify.tlds(tlds).set({ fuzzyEmail: false });

const linkStrategy = function linkStrategy(contentBlock, callback) {
  const links = linkify.match(contentBlock.get('text'));
  if (typeof links !== 'undefined' && links !== null) {
    for (let i = 0; i < links.length; i += 1) {
      callback(links[i].index, links[i].lastIndex);
    }
  }
};

export {
  linkStrategy,
};
