/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import {Spinner, InlineSpinner} from '../Spinner';
import {Button} from '../Button';
import Translation from '../Text/Translation';
import {Muted} from '../Text';

// eslint-disable-next-line
class OAuthAuthorize extends Component {
  renderDetails() {
    const {
      name,
      website,
      logo,
      permissions,
    } = this.props.details;
    return (
      <div className="OAuthAuthorizeDetails">
        <div className="OAuthAuthorizeDetails__connection">
          <img
            className="OAuthAuthorizeDetails__3rd-party-logo"
            src={logo}
            alt={name}
          />
          <img
            className="OAuthAuthorizeDetails__pageproof-logo"
            src="img/logo/pageproof-logo_2x.png"
            alt="Logo"
          />
          <InlineSVG
            className="OAuthAuthorizeDetails__tick"
            src="img/interface/oauth/tick.svg"
          />
        </div>
        <h2 className="OAuthAuthorizeDetails__name">
          <a href={website} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
          {' '}
          <Translation value="authorisation.permissions" />
        </h2>
        <ul className="OAuthAuthorizeDetails__permissions">
          {permissions.map(({scope, description}) => (
            <li key={scope}>
              {description}
            </li>
          ))}
        </ul>
        <Button
          variant="primary"
          label={
            this.props.authorizing
              ? <InlineSpinner />
              : <Translation value="authorisation.allow" />
          }
          disabled={this.props.waiting || this.props.authorizing}
          onClick={this.props.onAuthorize}
        />
        <br />
        <br />
        <Muted>
          <Translation value="authorisation.third-parties" />
        </Muted>
      </div>
    );
  }

  render() {
    const {loading} = this.props;
    return (
      <div className="OAuthAuthorize">
        {loading
          ? <Spinner />
          : this.renderDetails()}
      </div>
    );
  }
}

if (process.env.NODE_ENV !== 'production') {
  OAuthAuthorize.propTypes = {
    loading: PropTypes.bool.isRequired,
    waiting: PropTypes.bool.isRequired,
    authorizing: PropTypes.bool.isRequired,
    onAuthorize: PropTypes.func.isRequired,
  };
}

export default OAuthAuthorize;
