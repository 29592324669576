/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { openModal } from '../util/modal';
import { sdk } from '../util/sdk';
import DuplicateWorkflowTemplateModal from '../containers/DuplicateWorkflowTemplateModal';

const processDuplicateWorkflowTemplate = (workflowId, workflowTitle, includeSharedUsersAndOwnedByTeam) => {
  const { appService, $location } = window.__pageproof_bridge__;
  const duplicateWorkflowOptions = includeSharedUsersAndOwnedByTeam
    ? { includeOwnedByTeam: true, includeSharedUsers: true }
    : {};
  appService.showTranslatedLoaderMessage('loader-message.copying-workflow');

  sdk.workflows.duplicate(workflowId, duplicateWorkflowOptions).then((duplicatedWorkflow) => {
    sdk.workflows.update(duplicatedWorkflow.id, { name: `${workflowTitle} copy` }).then(() => {
      appService.hideLoader();
      $location.path(`/workflows/templates/${duplicatedWorkflow.id}`);
    });
  });
};

export function duplicateWorkflowTemplate(workflowId, workflowTitle) {
  const { destroy: close } = openModal(
    <DuplicateWorkflowTemplateModal
      onDuplicate={(includeSharedUsersAndOwnedByTeam) => {
        processDuplicateWorkflowTemplate(workflowId, workflowTitle, includeSharedUsersAndOwnedByTeam);
        close();
      }}
    />
  );
}
