/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { getUserFeatures } from '../../../hooks/useUserFeatures';

export default function getFallbackCommentVisibility() {
  const { hasCommentVisibility, defaultCommentVisibility } = getUserFeatures(false);

  return hasCommentVisibility
    ? defaultCommentVisibility
    : null;
}
