/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Option, OptionList } from '../PopupMenu';
import SideBySide from '../Transitions/SideBySide';
import css from './SubOptionList.scss';
import SubLabel from './SubLabel';

function SubOptionList({
  label,
  options,
  optionObjects,
  children,
}) {
  const [changing, setChanging] = useState(false);
  return (
    <SideBySide
      reverse={!changing}
      id={changing}
    >
      {changing
        ? (
          <OptionList
            naked
            className={css.SubOptionList}
          >
            <Option
              label={
                <div className={css.SubOptionList__label}>
                  <span>&#x276e;&nbsp;&nbsp;</span>
                  <span className={css.SubOptionList__label__name}>
                    {label}
                  </span>
                </div>
              }
              onClick={() => setChanging(false)}
            />
            <Fragment>
              {options.map(option => (
                cloneElement(children, {
                  key: option,
                  label: option,
                  options: optionObjects ? optionObjects[option] : [],
                  className: optionObjects ? '' : css.SubOptionList__option,
                })
              ))}
            </Fragment>

          </OptionList>
        ) : (
          <SubLabel
            label={label}
            subOptionCount={options.length}
            onClick={() => setChanging(true)}
          />
        )
      }
    </SideBySide>
  );
}

if (process.env.NODE_ENV !== 'production') {
  SubOptionList.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
  };
}

export default SubOptionList;
