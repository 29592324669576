/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import css from './ImportedCommentAvatar.scss';

const ImportedCommentAvatar = ({ avatarSize, active }) => (
  <div
    className={classname(
      css.ImportedCommentAvatar,
      {
        [css['ImportedCommentAvatar--small']]: avatarSize < 30,
        [css['ImportedCommentAvatar--active']]: active,
      }
    )}
    style={{
      width: avatarSize,
      height: avatarSize,
    }}
  >
    PDF
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  ImportedCommentAvatar.propTypes = {
    avatarSize: PropTypes.number,
  };
}

export default ImportedCommentAvatar;
