/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { WorkflowUserRole } from '@pageproof/sdk/lib/enum';
import { getUserFeatures } from '../../../hooks/useUserFeatures';
import { structuredCloneWithFallback } from './structuredCloneWithFallback';

export const getWorkflowWithAvailableRoles = (rawWorkflow, parameters) => {
  if (!rawWorkflow) {
    return rawWorkflow;
  }

  const workflow = structuredCloneWithFallback(rawWorkflow);

  const { proof, attachNewVersion } = parameters || {};

  if (!workflow.availableRoles) {
    workflow.availableRoles = (
      (proof && proof.availableWorkflowRoles) ||
      (attachNewVersion && attachNewVersion.availableWorkflowRoles) ||
      (!proof && !attachNewVersion && getUserFeatures(false).availableWorkflowRoles) ||
      [WorkflowUserRole.REVIEWER, WorkflowUserRole.MANDATORY, WorkflowUserRole.GATEKEEPER, WorkflowUserRole.APPROVER]
    );
  }

  if (workflow.steps) {
    workflow.steps.forEach((step) => {
      step.users.forEach((user) => {
        if (!workflow.availableRoles.includes(user.role)) {
          // eslint-disable-next-line no-param-reassign
          user.role = WorkflowUserRole.REVIEWER;
        }
      });
    });
  }

  return workflow;
};
