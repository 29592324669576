/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import css from './PasswordIndicatorTickIcon.scss';

const PasswordIndicatorTickIcon = () => (
  <InlineSVG
    src="/img/interface/tick.svg"
    className={css.PasswordIndicatorTickIcon}
  />
);

export default PasswordIndicatorTickIcon;
