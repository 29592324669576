/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './Wrapper.scss';
import { useCommentSpacing } from '../CommentSpacing';

function Wrapper({ children, noShadow = false }) {
  const spacing = useCommentSpacing();

  return (
    <div
      className={classname(css.Wrapper, css[`Wrapper--${spacing}-spacing`], {
        [css['Wrapper--no-shadow']]: noShadow,
      })}
    >
      {children}
    </div>
  );
}

export default Wrapper;
