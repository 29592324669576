/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PromptExtension from '../../components/PromptExtension';
import {getPromptDismissed, setPromptDismissed} from '../../util/local-storage-utils';
import {calculateExpiration} from '../../util/date-time-utils';

const WEEK_IN_SECONDS = 604800;

const calculateDismission = () => {
  const dismissedObject = getPromptDismissed();
  if (dismissedObject && dismissedObject.dismissed) {
    const isExpired = calculateExpiration(WEEK_IN_SECONDS, dismissedObject.timestamp);
    if (isExpired) {
      setPromptDismissed(false);
    }
    return !isExpired;
  } else {
    return false;
  }
};

const PromptExtensionContainer = ({supportedBrowser, ...props}) => {
  const isExtensionPromptDismissed = calculateDismission();
  return !isExtensionPromptDismissed && supportedBrowser && <PromptExtension {...props} />;
};

export default PromptExtensionContainer;
