/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './EllipsisText.scss';

function Ellipsis() {
  return (
    <div className={css.Ellipsis}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
}

export default Ellipsis;
