/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Option, Separator } from '../../PopupMenu';
import AdvancedSubOptionList from '../AdvancedSubOptionList';
import Translation from '../../Text/Translation';
import css from './LinkCheckResults.scss';
import LinkSummaryCounts from './LinkSummaryCounts';
import LinkCheckResultRow from './LinkCheckResultRow';

const LinkCheckResults = ({ data, hasRechecked, onRecheck }) => {
  const subOptions = data ? data.results.map(result => (
    <AdvancedSubOptionList
      label={
        <div className={css.LinkCheckResults__container}>
          <LinkSummaryCounts
            ok={result.summary.ok}
            warning={result.summary.warning}
            error={result.summary.error}
          />
          <span className={css.LinkCheckResults__right}>{result.path}</span>
        </div>
      }
      key={result.path}
    >
      {
        result.details.map(detail => (
          <Option
            label={<LinkCheckResultRow {...detail} />}
            readOnly
            className={css.LinkCheckResults__url}
            key={detail.url}
          />))
      }
    </AdvancedSubOptionList>
  )) : [];


  const recheckSubOptions = (
    <AdvancedSubOptionList
      label={<Translation value="proof.page.info.link-check.last-checked" />}
    >
      <Option
        label={moment(data.lastRan).format('LLLL')}
        readOnly
        disabled
        className={css.LinkCheckResults__url}
      />
      <Option
        label={<Translation value={hasRechecked ? 'proof.page.info.link-check.recheck-done' : 'proof.page.info.link-check.recheck'} />}
        readOnly
        className={css.LinkCheckResults__url}
        onClick={onRecheck}
      />
    </AdvancedSubOptionList>
  );

  return (
    <Fragment>
      <Separator />
      <Option
        label={<Translation value="proof.page.info.link-check.header" />}
        disabled
      />
      {subOptions.length > 0 &&
        subOptions
      }
      {recheckSubOptions}
    </Fragment>
  );
};


if (process.env.NODE_ENV === 'development') {
  LinkCheckResults.propTypes = {
    data: PropTypes.shape({
      lastRan: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      summary: PropTypes.string.isRequired,
      results: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string.isRequired,
        rating: PropTypes.string.isRequired,
        summary: PropTypes.shape({
          total: PropTypes.number.isRequired,
          ok: PropTypes.number.isRequired,
          error: PropTypes.number.isRequired,
          warning: PropTypes.number.isRequired,
        }).isRequired,
        details: PropTypes.arrayOf(PropTypes.shape({
          url: PropTypes.string.isRequired,
          status: PropTypes.number.isRequired,
          secure: PropTypes.bool.isRequired,
          rating: PropTypes.string.isRequired,
          detail: PropTypes.string.isRequired,
        })).isRequired,
      })).isRequired,
    }).isRequired,
    hasRechecked: PropTypes.bool.isRequired,
    onRecheck: PropTypes.func.isRequired,
  };
}


export default LinkCheckResults;
