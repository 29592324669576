/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import css from './SteppedProgressBar.scss';

function SteppedProgressBar({ numberOfSteps, currentStep, theme, size, onClickStep }) {
  const Element = onClickStep ? 'button' : 'div';

  return (
    <div className={classname(css.SteppedProgressBar, css[`SteppedProgressBar--${theme}`], css[`SteppedProgressBar--${size}`])}>
      {[...Array(numberOfSteps)].map((_, i) => (
        <Fragment key={`progress-bar-step-${i + 1}`}>
          {i !== 0 && (
            <div
              className={classname(css.SteppedProgressBar__line,
                {
                  [css['SteppedProgressBar__line--reached']]: i < currentStep,
                })}
            />
          )}
          <Element
            onClick={() => onClickStep(i + 1)}
            className={classname(css.SteppedProgressBar__step,
              {
                [css['SteppedProgressBar__step--reached']]: i < currentStep,
                [css['SteppedProgressBar__step--active']]: i === currentStep - 1,
              })}
          >
            {i + 1}
          </Element>
        </Fragment>
      ))}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  SteppedProgressBar.propTypes = {
    numberOfSteps: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    theme: PropTypes.oneOf([
      'subtle',
    ]),
    size: PropTypes.oneOf([
      'small',
    ]),
    onClickStep: PropTypes.func,
  };
}

export default SteppedProgressBar;
