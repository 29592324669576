import { useRef, useEffect } from 'react';

export function ModalServiceBridge({ componentName, isModalVisible, onClose, ...componentProps }) {
  const modalRef = useRef();

  useEffect(() => {
    if (modalRef.current) {
      if (!isModalVisible) {
        modalRef.current.destroy();
      } else {
        modalRef.current.component.props = componentProps;
        if (!window.__pageproof_bridge__.$rootScope.$$phase) {
          window.__pageproof_bridge__.$rootScope.$apply();
        }
      }
    } else if (isModalVisible) {
      const onDestroy = () => {
        modalRef.current = null;
        if (onClose) { onClose(); }
      };
      modalRef.current = window.__pageproof_bridge__.modalService.createWithComponent(componentName, componentProps, onDestroy);
    }
  });

  return null;
}

export default ModalServiceBridge;
