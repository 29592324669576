/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { Enum } from '@pageproof/sdk';
import { hasUsersThatCanBeNudged } from '../features';

function getProofLink(proof) {
  const type = proof.type === 2 ? 'brief' : 'proof';
  const category = proof.fileCategory || 'static';
  return `/${type}/${category}/${proof.id}`;
}

function canSelectForBulkAction(proof, user, actionType, from) {
  const userId = user.id;
  const isProofLockedForAdmin = from === 'team-dashboard' && !proof.isAdminOnProof;
  const isTeamAdmin = from === 'team-dashboard' && proof.isUserAdminOfProofTeam(user);

  switch (actionType) {
  case 'add-editor':
    return (
      (
        proof.type === Enum.ProofType.PROOF &&
        (
          proof.isOwner(userId) ||
          isTeamAdmin
        ) &&
        (
          proof.status === Enum.ProofStatus.TODOS_REQUESTED ||
          proof.status === Enum.ProofStatus.AWAITING_NEW_VERSION
        )
      ) ||
      (
        proof.type === Enum.ProofType.BRIEF &&
        (
          proof.isRecipient(userId) ||
          isTeamAdmin
        ) &&
        (
          proof.status === Enum.ProofStatus.PROOFING ||
          proof.status === Enum.ProofStatus.AWAITING_NEW_VERSION
        )
      )
    );
  case 'add-owner':
    return (
      proof.type === Enum.ProofType.PROOF &&
      (
        proof.isOwner(userId) ||
        isTeamAdmin
      )
    );
  case 'add-workflow':
    return (
      proof.type === Enum.ProofType.PROOF &&
      (
        proof.isOwner(userId) ||
        isTeamAdmin
      ) &&
      proof.getState() === Enum.ProofState.PROOFERS_NEEDED
    );
  case 'add-reviewer':
    return (
      proof.type === Enum.ProofType.PROOF &&
      (
        proof.isOwner(userId) ||
        isTeamAdmin
      ) &&
      proof.status > Enum.ProofStatus.NEW &&
      proof.workflowId
    );
  case 'due-date':
    return (
      (
        proof.isOwner(userId) ||
        isTeamAdmin
      ) &&
      (
        proof.status === Enum.ProofStatus.NEW ||
        proof.status === Enum.ProofStatus.PROOFING ||
        proof.status === Enum.ProofStatus.FINAL_APPROVING
      )
    );
  case 'replace-approver':
    return (
      proof.hasWorkflow() &&
      (
        proof.isOwner(userId) ||
        isTeamAdmin
      ) &&
      (
        proof.status === Enum.ProofStatus.NEW ||
        proof.status === Enum.ProofStatus.PROOFING ||
        proof.status === Enum.ProofStatus.FINAL_APPROVING
      )
    );
  case 'compare':
    return (
      !isProofLockedForAdmin &&
      proof.fileCategory !== Enum.FileCategory.WEB &&
      proof.fileCategory !== Enum.FileCategory.AUDIO &&
      !proof.isProcessing() &&
      !proof.hasFileError()
    );
  case 'set-status-as-awaiting-new-version':
    return (
      proof.type === Enum.ProofType.PROOF &&
      (
        proof.isOwner(userId) ||
        isTeamAdmin
      ) &&
      [
        Enum.ProofStatus.NEW,
        Enum.ProofStatus.PROOFING,
        Enum.ProofStatus.FINAL_APPROVING,
        Enum.ProofStatus.TODOS_REQUESTED,
      ].includes(proof.status)
    );
  case 'archive':
    return (
      (
        isTeamAdmin ||
        proof.isOwner(userId) ||
        proof.isRecipient(userId)
      ) &&
      proof.status > Enum.ProofStatus.NEW &&
      proof.status !== Enum.ProofStatus.ARCHIVED
    );
  case 'delete':
    return (
      isTeamAdmin ||
      proof.isOwner(userId) ||
      proof.isRecipient(userId)
    );
  case 'nudge':
    return (
      proof.type === Enum.ProofType.PROOF &&
      (
        proof.isOwner(userId) ||
        isTeamAdmin
      ) &&
      [Enum.ProofStatus.PROOFING, Enum.ProofStatus.FINAL_APPROVING].includes(proof.status) &&
      hasUsersThatCanBeNudged(proof.decisionSummary, userId)
    );
  case 'message-to-reviewers':
    return (
      (
        isTeamAdmin ||
        proof.isOwner(userId)
      ) && (
        proof.status === Enum.ProofStatus.NEW ||
        proof.status === Enum.ProofStatus.PROOFING ||
        proof.status === Enum.ProofStatus.FINAL_APPROVING
      )
    );
  default:
    return false;
  }
}

export {
  getProofLink,
  canSelectForBulkAction,
};
