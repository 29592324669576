import React from 'react';
import Reveal from '../../components/Reveal';
import ConfirmBox from '../../components/ConfirmBox';
import css from './CollectionManageConfirmBox.scss';

function CollectionManageConfirmBox({
  id,
  show,
  buttons,
}) {
  return (
    <div className={css.CollectionManageConfirmBox}>
      <Reveal
        duration={500}
        visible={show}
        align="top"
      >
        <ConfirmBox
          className={css.CollectionManageConfirmBox__box}
          heading={`collection.manage.confirm.heading.${id}`}
          message={`collection.manage.confirm.message.${id}`}
          buttons={buttons}
        />
      </Reveal>

    </div>
  );
}

export default CollectionManageConfirmBox;
