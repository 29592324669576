/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './Overlay.scss';

function Overlay({
  visible,
  style,
  ...props
}) {
  return (
    <div
      {...props}
      className={css.Overlay}
      style={{
        pointerEvents: visible ? '' : 'none',
        opacity: visible ? 1 : 0,
        ...(style || {}),
      }}
    />
  );
}

export default Overlay;
