/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Spinner} from '../../components/Spinner';
import {
  Avatar,
  EmailAvatar,
} from '../../components/Avatar';

const avatarCache = {};

class UserAvatarContainer extends Component {
  constructor(props) {
    super(props);
    const cached = avatarCache[props.id];
    this.state = (cached && !cached.then)
      ? {
        loading: false,
        avatar: cached,
      }
      : {
        loading: true,
        avatar: null,
      };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    if (this.props.showEmail) {
      this.getEmail(this.props.id);
    }

    if (this.state.loading) {
      this.loadAvatar(this.props);
    } else {
      // already loaded from cache
    }
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.loadAvatar(nextProps);
      this.getEmail(nextProps.id);
    }
  }

  getEmail(id) {
    window.__pageproof_bridge__.
      userRepositoryService
      .get(id)
      .then((data) => {
        this.setState({
          email: data.email,
        });
      });
  }

  loadAvatar({id}) {
    const promise = (avatarCache[id] ||
      (avatarCache[id] = window.__pageproof_bridge__.
        avatarRepositoryService
        .get({userId: id}))
    );
    if (!promise.then) {
      this.setState({
        loading: false,
        avatar: promise,
      });
    } else {
      this.setState({
        loading: true,
      });
      promise
        .then((data) => {
          const avatar = {
            url: data.image,
            initials: data.initials,
            hasRegistered: data.isRegistered,
          };
          avatarCache[id] = avatar;
          this.setState({
            loading: false,
            avatar,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            avatar: {
              initials: '?',
            },
          });
        });
    }
  }

  render() {
    const {
      size, id, showEmail, ...props
    } = this.props;
    const {loading, avatar, email} = this.state;
    const avatarComponent = showEmail ? (
      <EmailAvatar
        size={size}
        email={email}
        {...avatar}
        {...props}
      />
    ) : (
      <Avatar
        size={size}
        {...avatar}
        {...props}
      />
    );
    return (
      loading
        ? <Spinner size={size} />
        : avatarComponent
    );
  }
}

UserAvatarContainer.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.number,
  showEmail: PropTypes.bool,
};

UserAvatarContainer.defaultProps = {
  size: 60,
  showEmail: false,
};

export default UserAvatarContainer;
