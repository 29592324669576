/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import NotVisibleOverlay from '../../NotVisibleOverlay';
import css from './CollapsedReplies.scss';

function CollapsedReplies({
  replyCount,
  onShow,
}) {
  return (
    <div className={css.CollapsedReplies}>
      <NotVisibleOverlay
        label="comment.show-all-replies"
        replyCount={replyCount}
        onClick={onShow}
      />
    </div>
  );
}

export default CollapsedReplies;
