/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {createTransitionComponent} from './factory';
import slideUp from './SlideUp.scss';

const styles = {
  enter: slideUp.Enter,
  enterActive: slideUp['Enter--active'],
  leave: slideUp.Leave,
  leaveActive: slideUp['Leave--active'],
};

export default createTransitionComponent(styles);
