/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Children, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Date from '../Date';
import NextAvatar from '../../../containers/NextAvatar';
import ImportedCommentAvatar from '../../ImportedCommentAvatar';
import css from './Author.scss';
import { useCommentSpacing } from '../CommentSpacing';
import Verbose from '../../Date/Verbose/Verbose';
import Tooltip from '../../Tooltip';

function Author({
  id,
  email,
  isPdfImport,
  name,
  additional,
  compact,
  onOpenProfile,
  date,
  wrapAdditionalText,
}) {
  const spacing = useCommentSpacing();

  const avatarSize = useMemo(() => {
    if (spacing === 'condensed') {
      return 24;
    } else if (compact) {
      return 35;
    } else {
      return 40;
    }
  }, [spacing, compact]);

  return (
    <div
      className={classname(css.Author, css[`Author--${spacing}-spacing`])}
    >
      <div className={css.Avatar}>
        <div
          className={css.Avatar__container}
          onClick={onOpenProfile}
        >
          {isPdfImport
            ? (
              <Tooltip
                down
                right
                title={(
                  <Fragment>
                    <div>{name}</div>
                    <div>
                      Imported on&nbsp;
                      <Verbose date={date} />
                    </div>
                  </Fragment>
                )}
                disabled={spacing !== 'condensed'}
              >
                <div><ImportedCommentAvatar avatarSize={avatarSize} /></div>
              </Tooltip>
            )
            : (
              <NextAvatar
                id={id}
                size={avatarSize}
                showNameAndEmail={spacing === 'condensed'}
                additionalText={<Verbose date={date} />}
              />
            )
          }
        </div>
      </div>
      <div className={css.Details}>
        {!(spacing === 'condensed') && (
          <div className={css.Email}>
            {name || email}
          </div>
        )}
        {additional &&
          <div className={css.Additional}>
            {Children.toArray(additional).filter(Boolean).map((segment, index) => (
              <div
                className={classname(css.Additional__segment, { [css['Additional__segment--forced-wrap']]: wrapAdditionalText })}
                key={index}
              >
                {segment}
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
}

Author.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isPdfImport: PropTypes.bool,
  name: PropTypes.string,
  compact: PropTypes.bool,
  additional: PropTypes.node,
  onOpenProfile: PropTypes.func,
  date: PropTypes.instanceOf(Date),
};

export default Author;
