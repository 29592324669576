/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect, useState } from 'react';

export function useScrollTo({ proofCtrl, virtualListRef }) {
  const [deferredScroll, setDeferredScroll] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    proofCtrl.commentsPane = {
      scrollToComment(commentId) {
        // comments.getCommentById (with true passed as the second argument) will return a reply's parent comment object
        // if it's a reply, but can also take in a parent comment ID too. So this handy function really easily allows us
        // to support scrolling to both parent comments and replies.
        const scrollComment = proofCtrl.comments.getCommentById(commentId, true);
        if (scrollComment) {
          return scrollTo('comment' + scrollComment.id, 'start');
        }
        return Promise.reject(new Error(`Failed to scroll to comment - comment "${commentId}" not found.`));
      },
      scrollToCreateComment() {
        return scrollTo('new', 'start');
      },
      scrollToPage(pageNumber) {
        return scrollTo('page' + pageNumber, 'start');
      },
      scrollToTop() {
        scrollTo();
      },
    };
    proofCtrl.commentsPaneDidMount();
  }, [proofCtrl, virtualListRef.current]);

  function scrollTo(key, align) {
    if (!virtualListRef.current) {
      setDeferredScroll({ key, align });
      return Promise.resolve();
    }

    if (key) {
      return virtualListRef.current.scrollToKey(key, align);
    } else {
      return virtualListRef.current.scrollToTop();
    }
  }

  return [deferredScroll, setDeferredScroll];
}
