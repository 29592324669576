/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useState } from 'react';
import classname from 'classname';
import css from './ChecklistTemplateDropdown.scss';
import { sdk } from '../../../util/sdk';
import Dropdown, {
  Option as DropdownOption,
  Separator as DropdownSeparator,
  OptionLabel as DropdownOptionLabel,
} from '../../Dropdown';
import { Search } from '../../Search';
import { Translation } from '../../Text';


export const ChecklistTemplateDropdown = ({ currentChecklist, onSelect, previousVersionChecklist }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [checklistTemplates, setChecklistTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    sdk.checklistTemplates.list({ states: ['active'] }).then((checklistTemplatesResult) => {
      setChecklistTemplates(checklistTemplatesResult);
      setIsLoading(false);
    });
  }, []);


  const filteredChecklistTemplates = searchTerm
    ? checklistTemplates.filter(({ name }) => name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
    : checklistTemplates;

  const noTemplatesMessage = (() => {
    if (isLoading || filteredChecklistTemplates.length) {
      return '';
    }

    if (searchTerm && searchTerm.length) {
      return sdk.session.user.isAdmin
        ? <Translation value="proof.setup.checklist.dropdown.no-templates-match.admin" />
        : <Translation value="proof.setup.checklist.dropdown.no-templates-match" />;
    }

    return sdk.session.user.isAdmin
      ? <Translation value="proof.setup.checklist.dropdown.no-templates.admin" />
      : <Translation value="proof.setup.checklist.dropdown.no-templates" />;
  })();

  const currentChecklistTemplateId = currentChecklist && currentChecklist.templateId;
  const previousVersionChecklistTemplateId = previousVersionChecklist && previousVersionChecklist.templateId;

  return (
    <Dropdown
      selected={(isLoading || !currentChecklistTemplateId)
        ? undefined
        : currentChecklistTemplateId
      }
      disabled
      wrap
      classNames={{ options: css.ChecklistTemplatesDropdown__options }}
      placeholder={
        <DropdownOption
          label={
            <DropdownOptionLabel
              title={currentChecklist ? currentChecklist.name : <Translation value="proof.setup.checklist.dropdown.add-checklist" />}
            />
          }
        />
      }
    >
      {currentChecklist && (
        <DropdownOption
          value={-2}
          classNames={css.ChecklistTemplatesDropdown__options__option}
          label={
            <DropdownOptionLabel
              title={<Translation value="proof.setup.checklist.dropdown.no-checklist" />}
              description={<Translation value="proof.setup.checklist.dropdown.no-checklist.description" />}
            />
          }
          onClick={() => onSelect(null)}
        />
      )}
      {previousVersionChecklist && (
        <DropdownOption
          value={-1}
          classNames={css.ChecklistTemplatesDropdown__options__option}
          label={
            <DropdownOptionLabel
              title={<Translation value="proof.setup.checklist.dropdown.current-checklist" />}
              description={<Translation value="proof.setup.checklist.dropdown.current-checklist.description" />}
            />
          }
          selected={currentChecklistTemplateId === previousVersionChecklistTemplateId}
          onClick={() => onSelect({
            id: previousVersionChecklist.templateId,
            name: previousVersionChecklist.name,
            defaultShouldOpenOnProofLoad: previousVersionChecklist.shouldOpenOnProofLoad,
            defaultIsRequiredForFinalApproval: previousVersionChecklist.isRequiredForFinalApproval,
          })}
        />
      )}
      {(currentChecklist || previousVersionChecklist) && (
        <DropdownSeparator fullWidth />
      )}
      {checklistTemplates.length > 1 && (
        <div className={css.ChecklistTemplatesDropdown__searchHolder}>
          <Search
            searchTerm={searchTerm}
            onSearch={setSearchTerm}
            onClearSearch={() => setSearchTerm('')}
            theme="grey"
            simple
            autoFocus
            placeholder="proof.setup.checklist.dropdown.search.placeholder"
          />
        </div>
      )}
      {noTemplatesMessage && (
        <div className={classname(css.ChecklistTemplatesDropdown__noTemplates, css.ChecklistTemplatesDropdown__options__option)}>
          {noTemplatesMessage}
        </div>
      )}
      {filteredChecklistTemplates.map((checklistTemplate) => {
        const { id, name, defaultShouldOpenOnProofLoad, defaultIsRequiredForFinalApproval } = checklistTemplate;
        return (
          <DropdownOption
            key={id}
            classNames={css.ChecklistTemplatesDropdown__options__option}
            label={name}
            value={id}
            selected={currentChecklistTemplateId === id}
            onClick={() => {
              onSelect({
                id,
                name,
                defaultShouldOpenOnProofLoad,
                defaultIsRequiredForFinalApproval,
              });
            }}
          />
        );
      })}
    </Dropdown>
  );
};
