/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useRef, useState, useImperativeHandle, createRef } from 'react';

export const ChildrenPassthrough = ({ modalRef, children }) => {
  const [child, setChild] = useState(() => children);

  useImperativeHandle(modalRef, () => ({
    render(newChild) {
      setChild(newChild);
    },
  }), []);

  return child;
};

export function openModal(children, _onDestroy, isDismissable) {
  const { modalService } = window.__pageproof_bridge__;
  const modalRef = createRef();

  const props = {
    modalRef,
    children,
  };
  const modal = modalService.createWithComponent('ModalChildrenPassthrough', props, _onDestroy, isDismissable);

  const api = {
    render(newChildren) {
      modalRef.current.render(newChildren);
    },
    destroy() {
      modal.destroy();
    },
  };

  return api;
}

export function useModal() {
  const modalRef = useRef(null);

  function open(children) {
    if (modalRef.current) {
      modalRef.current.render(children);
    } else {
      modalRef.current = openModal(children, () => {
        modalRef.current = null;
      });
    }
  }
  function close() {
    if (modalRef.current) {
      modalRef.current.destroy();
    }
  }

  return {
    open,
    render: open,
    close,
  };
}
