/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import css from './LinkSummaryDot.scss';
import Tooltip from '../../Tooltip';

function LinkSummaryDot({ status, detail, hidePopover, count, showCount }) {
  const className = classname(
    css.LinkSummaryDot,
    css['LinkSummaryDot--' + status.toLowerCase()],
    { [css['LinkSummaryDot--large']]: showCount && count !== undefined },
  );

  return (
    <Tooltip
      right
      center
      up
      arrow
      offset={15}
      disabled={hidePopover}
      variant="light"
      title={detail || status}
    >
      <div className={className}>
        {(showCount && count) &&
          count
        }
      </div>
    </Tooltip>
  );
}

if (process.env.NODE_ENV === 'development') {
  LinkSummaryDot.propTypes = {
    status: PropTypes.string.isRequired,
    detail: PropTypes.string,
    hidePopover: PropTypes.bool,
    count: PropTypes.number,
    showCount: PropTypes.bool,
  };
}


export default LinkSummaryDot;
