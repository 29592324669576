/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 * */
import { useUserPreferences } from './useUserPreferences';
import { getUserColorPreference } from '../util/user-color-preference';

// It returns {normal: #999999, dark: #bbbbbb, light: #ffffff } format for mark icons
const useUserPreferenceColor = (type) => {
  const [, userPreferences] = useUserPreferences();
  const userPreferencesColors = getUserColorPreference(userPreferences);
  return userPreferencesColors[type];
};

export default useUserPreferenceColor;
