/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useRef } from 'react';
import { useForceRender } from './useForceRender';

export const useMediaQuery = (query) => {
  const ref = useRef();
  const forceRender = useForceRender();

  if (!ref.current || ref.current.query !== query) {
    const matchMedia = window.matchMedia(query);

    ref.current = {
      query,
      matchMedia,
      matches: matchMedia.matches,
    };

    matchMedia.onchange = (event) => {
      if (ref.current.matchMedia === matchMedia && ref.current.matches !== event.matches) {
        ref.current.matches = event.matches;
        forceRender();
      }
    };
  }

  return ref.current.matches;
};
