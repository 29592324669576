/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import { Translation } from '../Text';
import getColorBasedOnAverageColor from './getColorBasedOnAverageColor';

const Thumbnail = ({
  onClick,
  overlay,
  hasFileError,
  hasUploader,
  url,
  title,
  commentCount,
  isTodos,
  variant,
  progressPercent,
  status,
}) => {
  const [commentCountColor, setCommentCountColor] = useState(null);
  const { isDarkMode } = window.__pageproof_ctrl__.appCtrl;
  const handleImageOnload = ({ currentTarget }) => {
    if (isDarkMode && !isTodos && commentCount > 0) {
      setCommentCountColor(getColorBasedOnAverageColor(currentTarget));
    }
  };
  return (
    <div
      onClick={onClick}
      className={classname(`app__proof-thumbnail app__proof-thumbnail--${variant} app__proof-thumbnail--${overlay} app__proof-thumbnail--${status}`)}
    >
      <div
        className={classname('app__proof-thumbnail__image', {
          'app__proof-thumbnail__image__uploader': (hasFileError || hasUploader),
        })}
      >
        {!hasFileError && !hasUploader && url &&
          <img
            src={url}
            alt={title}
            onLoad={handleImageOnload}
          />
        }
      </div>
      <div className="app__proof-thumbnail__overlay">
        {(commentCount > 0 || isTodos) &&
          <div
            className={`app__proof-thumbnail__overlay__comments app__proof-thumbnail__overlay__comments--${variant}`}
            style={commentCountColor ? { color: commentCountColor } : {}}
          >
            <div className="app__proof-thumbnail__overlay__comments__count">
              {commentCount}
            </div>
            {isTodos && (
              <div className="app__proof-thumbnail__overlay__comments__label">
                <Translation value={'dashboard.proof.' + (commentCount > 1 ? 'todos' : 'todo')} />
              </div>
            )}
            {!isTodos && (
              <div className="app__proof-thumbnail__overlay__comments__label">
                <Translation value={'dashboard.proof.' + (commentCount > 1 ? 'comments' : 'comment')} />
              </div>
            )}
          </div>
        }
      </div>
      {progressPercent && progressPercent !== -1 &&
        <div className="dashboard__proof__progress">
          <div
            className="dashboard__proof__progress__bar"
            style={{ width: progressPercent * 100 + '%' }}
          >
            <span className="dashboard__proof__progress__bar__label">
              {(progressPercent * 100).toFixed(0)}
              %
            </span>
          </div>
        </div>
      }
    </div>
  );
};

Thumbnail.defaultProps = {
  variant: 'small',
  hasUploader: false,
  hasFileError: false,
  isTodos: false,
  progressPercent: false,
};

if (process.env.NODE_ENV !== 'production') {
  Thumbnail.propTypes = {
    onClick: PropTypes.func,
    overlay: PropTypes.string,
    hasFileError: PropTypes.bool,
    url: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.string,
    ]),
    title: PropTypes.string,
    commentCount: PropTypes.number,
    variant: PropTypes.string,
    hasUploader: PropTypes.bool,
    isTodos: PropTypes.bool,
    progressPercent: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    status: PropTypes.string,
  };
}

export default Thumbnail;
