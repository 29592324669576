const HASHTAG_REGEX = /#[1-9]\d*\b/g;

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  while ((matchArr = regex.exec(text)) !== null) { //eslint-disable-line
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const hashtagStrategy = function hashtagStrategy(contentBlock, callback) {
  findWithRegex(HASHTAG_REGEX, contentBlock, callback);
};

export {
  hashtagStrategy,
};
