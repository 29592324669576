/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import classname from 'classname';
import CollapsedReplies from '../CollapsedReplies';
import CommentSpacing from '../CommentSpacing';
import Reveal from '../../Reveal';
import MessagePillWithAutoClick from '../MessagePillWithAutoClick';
import css from './Replies.scss';
import { KeepVirtualListItemMounted } from '../../../containers/CommentsPane/components/KeepVirtualListItemMounted';

const MAX_COUNT = 3;
const MIN_HEIGHT = 220;
const MIN_HEIGHT_CONDENSED = 150;

class Replies extends Component {
  state = {
    visible: this.props.replyCount <= MAX_COUNT,
    manuallyExpanded: false,
  };

  render() {
    const {
      replyCount,
      haveNoParentComment,
      onRemoveNoParentComment,
      children,
    } = this.props;
    const { visible } = this.state;

    return (
      <CommentSpacing.Consumer>
        {spacing => (
          <div
            className={classname(css.Replies, {
              [css['Replies--visible']]: !haveNoParentComment && visible,
            })}
          >
            {this.state.manuallyExpanded && <KeepVirtualListItemMounted />}
            <div className={classname(css.Overlay)}>
              <CollapsedReplies
                replyCount={replyCount}
                onShow={() => this.setState({ visible: true, manuallyExpanded: true })}
              />
            </div>
            {haveNoParentComment &&
              <div className={css.Overlay}>
                <MessagePillWithAutoClick
                  label="comment.no-parent-comment-found"
                  onClick={() => onRemoveNoParentComment()}
                />
              </div>
            }
            <Reveal
              align="top"
              minHeight={spacing === 'condensed' ? MIN_HEIGHT_CONDENSED : MIN_HEIGHT}
              visible={visible}
              duration={Math.min(replyCount * 150, 2500)}
            >
              {({pending}) => {
                const length = (pending || visible) ? replyCount : MAX_COUNT;
                const elements = new Array(length);
                for (let i = 0; i < length; i += 1) {
                  elements[i] = children(i);
                }
                return elements;
              }}
            </Reveal>
          </div>
        )}
      </CommentSpacing.Consumer>
    );
  }
}

export default Replies;
