/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import Translation from '../Text/Translation';
import css from './ThirdPartyCookiesModalMessage.scss';

const ThirdPartyCookiesModalMessage = () => {
  const browser = window.__pageproof_bridge__.browserService.name;

  return (
    <Fragment>
      <div className={css.ThirdPartyCookiesModalMessage__title}>
        <Translation value="proof.dialog.third-party-cookies.title" />
      </div>
      <div className={css.ThirdPartyCookiesModalMessage__message}>
        <ul>
          <li>
            <Translation value="proof.dialog.third-party-cookies.message.allow-cookies" />
          </li>
          {['chrome', 'edge'].indexOf(browser) === -1 &&
            <li>
              <Translation value="proof.dialog.third-party-cookies.message.alternative-browsers" />
            </li>
          }
        </ul>
      </div>
    </Fragment>
  );
};

export default ThirdPartyCookiesModalMessage;
