/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import OtpInputOriginal from 'react-otp-input';
import css from './OtpInputField.scss';

// OtpInput doesn't support removing whitespace when a user pastes
class OtpInput extends OtpInputOriginal {
  _handleOnPaste = this.handleOnPaste;

  handleOnPaste = (event) => {
    const originalClipboardValue = event.clipboardData.getData('text/plain');
    // Calling setData on the original clipboardData doesn't change the data at all
    // eslint-disable-next-line no-param-reassign
    event.clipboardData = new window.DataTransfer();
    // Remove whitespace as new line characters are included when copying from the activation email.
    event.clipboardData.setData('text/plain', originalClipboardValue.replace(/\s/g, ''));

    return this._handleOnPaste(event);
  }
}

const OtpInputField = ({ value, onChange, numInputs, isDisabled, autoFocus, className }) => (
  <OtpInput
    value={value}
    onChange={onChange}
    numInputs={numInputs}
    containerStyle={classname(css.OtpInputField__container, className)}
    inputStyle={classname(css.OtpInputField__input, 'Input--unified')}
    separator={<span className={css.OtpInputField__separator} />}
    disabledStyle={css.OtpInputField__disabled}
    isDisabled={isDisabled}
    shouldAutoFocus={autoFocus}
  />
);

OtpInputField.defaultProps = {
  numInputs: 6,
  autoFocus: false,
};

if (process.env.NODE_ENV !== 'production') {
  OtpInputField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    numInputs: PropTypes.number,
    isDisabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
  };
}

export default OtpInputField;
