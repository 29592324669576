/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import {Component} from 'react';

class TransitioningVisibility extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
    };
  }

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== this.props.visible) {
      this.visibilityDidChange(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  visibilityDidChange({visible, duration}) {
    clearTimeout(this.timeout);

    // Only cause an update to occur if the state we want to be in isn't the current state - this
    // happens if the visibility prop changes quicker than the duration.
    if (visible !== this.state.visible) {
      this.timeout = setTimeout(() => {
        this.setState({visible});
      }, visible ? 0 : duration);
    }
  }

  render() {
    return this.props.children(this.state.visible);
  }
}

export default TransitioningVisibility;
