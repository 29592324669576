/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { makeUnstableBackendRequest } from '../../../util/unstable-backend-request';
import { getUserId } from './getUserId';
import { addMember, removeMember } from './members';

export function getSharedWith(workflowId) {
  return makeUnstableBackendRequest({
    method: 'GET',
    path: `/api/workflows/share/${workflowId}`,
  }).then(body => ({
    users: body.SharedUsers.map(permission => ({
      id: permission.User.UserId,
      email: permission.User.Email,
      permissions: {
        canEdit: permission.IsTemplateCoowner === 1,
      },
      _memberId: permission.MemberId,
    })),
  }));
}

export function addSharedWith(workflowId, email) {
  return getUserId(email).then(userId => (
    makeUnstableBackendRequest({
      method: 'POST',
      path: '/api/workflows/share/add',
      body: {
        WorkflowId: workflowId,
        UserId: userId,
      },
    }).then(() => ({
      userId,
    }))
  ));
}

export function removeSharedWith(workflowId, userId) {
  return makeUnstableBackendRequest({
    method: 'POST',
    path: '/api/workflows/share/delete',
    body: {
      WorkflowId: workflowId,
      UserId: userId,
    },
  });
}

export function enableSharedWithCanEdit(workflowId, userId) {
  return addMember(workflowId, userId, 'WorkflowTemplateCoowner');
}

export function disableSharedWithCanEdit(memberId) {
  return removeMember(memberId, 'WorkflowTemplateCoowner');
}
