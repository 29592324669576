/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const date = new Date();
const year = date.getFullYear();
const day = date.getDate();
const month = date.getMonth() + 1;

export const IS_VALENTINES_DAY = (day === 14 && month === 2) || window.localStorage.getItem('DEBUG_IS_VALENTINES_DAY') === 'true';

export const IS_HALLOWEEN = (day === 31 && month === 10) || window.localStorage.getItem('DEBUG_IS_HALLOWEEN') === 'true';

export const IS_CHRISTMAS = (month === 12 && day >= 1 && day <= 25) || window.localStorage.getItem('DEBUG_IS_CHRISTMAS') === 'true';

export const IS_ST_PATRICKS_DAY = (month === 3 && day === 17) || window.localStorage.getItem('DEBUG_IS_ST_PATRICKS_DAY') === 'true';

export const IS_INTERNATIONAL_WOMENS_DAY = (month === 3 && day === 8) || window.localStorage.getItem('DEBUG_IS_INTERNATIONAL_WOMENS_DAY') === 'true';

export const IS_CHRISTCHURCH_NATIONAL_REMEMBRANCE_DAY = (month === 3 && day === 29 && year === 2019) || window.localStorage.getItem('DEBUG_IS_CHRISTCHURCH_NATIONAL_REMEMBRANCE_DAY') === 'true';

export const IS_PRIDE_MONTH = (month === 6) || window.localStorage.getItem('DEBUG_IS_PRIDE_MONTH') === 'true';

export const IS_MAORI_LANGUAGE_WEEK = (month === 9 && year === 2020 && (day >= 14 && day <= 20)) || window.localStorage.getItem('DEBUG_IS_MAORI_LANGUAGE_WEEK') === 'true';

export const IS_FOURTH_OF_JULY = (month === 7 && (day >= 4 && day <= 10)) || window.localStorage.getItem('DEBUG_IS_FOURTH_OF_JULY') === 'true';

export const IS_EARTH_DAY = (month === 4 && day === 22) || window.localStorage.getItem('DEBUG_IS_EARTH_DAY') === 'true';

export const IS_NEW_YEAR = ((month === 12 && day === 31) || (month === 1 && day <= 2)) || window.localStorage.getItem('DEBUG_IS_NEW_YEAR') === 'true';

export const IS_CHINESE_NEW_YEAR = (month === 2 && (day >= 10 && day <= 24)) || window.localStorage.getItem('DEBUG_IS_CHINESE_NEW_YEAR') === 'true';

export const IS_EASTER = ((month === 3 && day >= 29 && year === 2024) || (month === 4 && day <= 1 && year === 2024)) || window.localStorage.getItem('DEBUG_IS_EASTER') === 'true';

export const IS_CORONATION_DAY = (day === 6 && month === 5 && year === 2023) || window.localStorage.getItem('DEBUG_IS_CORONATION_DAY') === 'true';
