/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {PureComponent} from 'react';

// The following regex matches against all known origins we serve proxied websites through.
// legacy: https://proxy-web-url-89dce6a446a69d6b9bdc01ac75251e4c322bcdff.static.pageproof.com
// current: https://proxy-web-url-89dce6a446a69d6b9bdc01ac75251e4c322bcdff.websites.pageproofusercontent.com
// dev: https://proxy-web-url-89dce6a446a69d6b9bdc01ac75251e4c322bcdff.web-proxy-service.dev.k8s.pageproof.app
const PROXIED_WEBSITES_PATTERN = /^https?:\/\/(.*)\.(static\.pageproof\.com|websites\.pageproofusercontent\.com|web-proxy-service\.(\w+)\.k8s\.pageproof\.app)$/;

class Page extends PureComponent {
  render() {
    const FAKE = 'https://does-not-exist';
    const url = new URL(this.props.url, FAKE);
    let str = '';
    if (this.props.hovered) {
      if (url.origin !== FAKE && !url.origin.match(PROXIED_WEBSITES_PATTERN)) {
        str += url.origin;
      }
    }
    str += url.pathname;
    if (this.props.hovered) {
      str += url.search + url.hash;
    }
    return <span style={{ wordBreak: 'break-all' }}>{str}</span>;
  }
}

export default Page;
