/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import DateRangePicker from '../DateRangePicker';
import Popover from '../Popover';
import FilterLabel from './FilterLabel';

function DateFilterLabel({
  label,
  showCalender,
  dateFilters,
  onClick,
  onChangeActiveFilter,
}) {
  const { startDate, endDate } = dateFilters;
  return (
    <Popover
      backdrop={false}
      right
      middle
      arrow
      visible={showCalender}
      offset={50}
      content={popover => (
        <DateRangePicker
          onChange={(start, end) => {
            onClick(start, end);
            popover.hide();
          }}
          onCancel={() => {
            onClick(null, null);
            popover.hide();
          }}
          selectedStartDate={startDate}
          selectedEndDate={endDate}
        />
      )}
      variant="light"
    >
      <div
        role="presentation"
        onClick={onChangeActiveFilter}
      >
        <FilterLabel
          label={label}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </Popover>
  );
}

export default DateFilterLabel;
