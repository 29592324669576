/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../Icon/createIcon';

export default createIcon({
  displayName: 'ExportIcon',
  src: 'img/icons/export.svg',
  activeColor: '#777',
  activeHoverColor: '#a1a1a1',
});
