/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import ProofInfoInlineTooltip from '../../components/ProofInfoInlineTooltip';
import {Translation} from '../../components/Text';

function ProofInfoInlineTooltipContainer({
  desc,
  params,
}) {
  return (
    <ProofInfoInlineTooltip>
      <Translation
        value={desc}
        params={params}
      />
    </ProofInfoInlineTooltip>
  );
}

export default ProofInfoInlineTooltipContainer;
