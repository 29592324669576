/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { Fragment } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import ConfirmOption from './ConfirmOption';
import css from './ConfirmActionOption.scss';

function ConfirmActionOption({
  onClick,
  iconSrc,
  label,
  confirmLabel,
}) {
  return (
    <ConfirmOption
      label={label}
      confirmLabel={
        <Fragment>
          {iconSrc &&
            <InlineSVG
              src={iconSrc}
              className={css.ActionIcon}
            />
          }
          {confirmLabel}
        </Fragment>
      }
      onClick={onClick}
    />
  );
}

export default ConfirmActionOption;
