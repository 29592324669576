/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';

function Popover({
  content,
  children,
  ...props
}) {
  return (
    <Tooltip
      backdrop
      {...props}
      popover
      title={content}
    >
      {children}
    </Tooltip>
  );
}

Popover.defaultProps = {
  delay: 0,
  offset: 0,
  arrow: false,
};

const dynamicNode = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.node,
]);

Popover.propTypes = {
  content: dynamicNode.isRequired,
  children: dynamicNode.isRequired,
  top: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  bottom: PropTypes.bool,
  delay: PropTypes.number,
  offset: PropTypes.number,
  backdrop: PropTypes.bool,
  arrow: PropTypes.bool,
};

export default Popover;
