/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import Translation from '../Text/Translation';

function LinkTab({
  selected,
  variant,
  tab,
}) {
  return (
    <div
      className={classname(`VerticalTab VerticalTab--${variant} VerticalTab--${variant}__link`, {
        'VerticalTab--selected': selected,
      })}
    >
      <a
        href={tab.href}
        rel="noopener noreferrer"
        target="_blank"
        className="VerticalTab__link"
      >
        <Translation value={tab.label} />
      </a>
      <InlineSVG
        className="VerticalTab__arrow"
        src="/img/interface/arrow-down.svg"
      />
    </div>
  );
}

LinkTab.defaultProps = {
  variant: 'grey',
};

if (process.env.NODE_ENV !== 'production') {
  LinkTab.propTypes = {
    tab: PropTypes.objectOf(PropTypes.any).isRequired,
    selected: PropTypes.bool.isRequired,
    variant: PropTypes.string,
  };
}

export default LinkTab;
