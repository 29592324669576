/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useRef, useMemo } from 'react';
import { Option, PopupMenu } from '../../components/PopupMenu';
import Translation from '../../components/Text/Translation';
import Tooltip from '../../components/Tooltip';
import Truncate from '../../components/Truncate';
import css from './BarcodeScannerOverlay.scss';
import { convertMultipleDimensions } from '../../util/conversionUtils';

const BarcodeScannerOverlay = (props) => {
  const {
    isEnabled,
    isHighlighting,
    canCreateComment,
    image,
    barcodes,
    onCreateComment,
    isPdfFile,
    proofOriginalDimensions,
  } = props;

  if (!isEnabled || !image || !barcodes) {
    return null;
  }

  const SVG_SCALE = 0.1;

  const getAspectRatio = useMemo(() => proofOriginalDimensions.width / convertMultipleDimensions(image.width, proofOriginalDimensions.measurementUnit), [proofOriginalDimensions.width, image.width, proofOriginalDimensions.measurementUnit]);

  const convertBarcodeDimensions = useMemo(() => (dimension) => {
    if (isPdfFile) {
      const finalDimension = dimension * getAspectRatio;
      return convertMultipleDimensions(finalDimension, proofOriginalDimensions.measurementUnit);
    }
    return dimension;
  }, [isPdfFile, getAspectRatio, proofOriginalDimensions.measurementUnit]);

  const calculateDiagonal = useMemo(() => (barcodeWidth, barcodeHeight) => {
    const widthValue = convertBarcodeDimensions(parseFloat(barcodeWidth));
    const heightValue = convertBarcodeDimensions(parseFloat(barcodeHeight));

    if (widthValue && heightValue) {
      const diagonalValue = Math.sqrt((widthValue ** 2) + (heightValue ** 2));
      return diagonalValue.toFixed(3);
    }

    return 0;
  }, [convertBarcodeDimensions]);

  const copyAllToClipboard = useMemo(() => (barcode) => {
    if (barcode) {
      const width = convertBarcodeDimensions(barcode.boundingBox.width);
      const height = convertBarcodeDimensions(barcode.boundingBox.height);
      const diagonal = calculateDiagonal(barcode.boundingBox.width, barcode.boundingBox.height);
      const text = `${barcode.format.replace(/_/g, ' ')}: ${barcode.rawValue}\n` +
                  `W: ${width.toFixed(3)} ${proofOriginalDimensions.measurementUnit}\n` +
                  `H: ${height.toFixed(3)} ${proofOriginalDimensions.measurementUnit}\n` +
                  `Diagonal: ${diagonal} ${proofOriginalDimensions.measurementUnit}\n`;
      window.navigator.clipboard.writeText(text);
    }
  }, [convertBarcodeDimensions, calculateDiagonal, proofOriginalDimensions.measurementUnit]);

  const divRefs = useRef([]);

  return (
    <div>
      {isHighlighting && (
        <svg
          viewBox={`0 0 ${image.width * SVG_SCALE} ${image.height * SVG_SCALE}`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <defs>
            <mask id="mask" x="0" y="0" width={image.width * SVG_SCALE} height={image.height * SVG_SCALE}>
              <rect x="0" y="0" width={image.width * SVG_SCALE} height={image.height * SVG_SCALE} fill="#fff" />
              {barcodes.map((barcode, index) => (
                <rect
                  key={index}
                  x={barcode.boundingBox.x * SVG_SCALE}
                  y={barcode.boundingBox.y * SVG_SCALE}
                  width={barcode.boundingBox.width * SVG_SCALE}
                  height={barcode.boundingBox.height * SVG_SCALE}
                />
              ))}
            </mask>
          </defs>
          <rect
            x="0"
            y="0"
            width={image.width * SVG_SCALE}
            height={image.height * SVG_SCALE}
            mask="url(#mask)"
            fillOpacity="0.7"
          />
        </svg>
      )}
      {barcodes.map((barcode, index) => {
        let url = null;
        if (barcode.format === 'qr_code') {
          try {
            url = new URL(barcode.rawValue);
          } catch (err) {
            // ignore
          }
        }
        return (
          <PopupMenu
            key={`${barcode.rawValue}-${index}`}
            options={() => (
              <Fragment>
                <Option
                  label={<Translation value="barcode.overlay.copy-to-clipboard" />}
                  onClick={() => {
                    window.navigator.clipboard.writeText(barcode.rawValue);
                  }}
                />
                <Option
                  label={<Translation value="barcode.overlay.copy-all-to-clipboard" />}
                  onClick={() => copyAllToClipboard(barcode)}
                />
                {!!url && (
                  <Option
                    label={<Translation value="barcode.overlay.open-in-new-tab" />}
                    onClick={() => {
                      window.open(url.href, '_blank');
                    }}
                  />
                )}
                <Option
                  label={<Translation value="barcode.overlay.create-comment" />}
                  onClick={() => {
                    if (canCreateComment()) {
                      onCreateComment(barcode);
                    }
                  }}
                  disabled={!canCreateComment()}
                />
              </Fragment>
            )}
          >
            {popup => (
              <div
                id={`BarcodeDiv-${index}`}
                key={index}
                ref={el => (divRefs.current[index] = el)} // Assign ref to each div
                style={{
                  pointerEvents: 'all',
                  position: 'absolute',
                  top: (barcode.boundingBox.y / image.height) * 100 + '%',
                  left: (barcode.boundingBox.x / image.width) * 100 + '%',
                  width: (barcode.boundingBox.width / image.width) * 100 + '%',
                  height: (barcode.boundingBox.height / image.height) * 100 + '%',
                }}
              >
                <Tooltip
                  title={
                    <Fragment>
                      <div className={css.flexRow}>
                        <div className={css.whiteSpaceNoWrap}>
                          {barcode.format.replace(/_/g, ' ')}
                          :
                        </div>
                        <div className={css.fontMonospace}>
                          <Truncate maxWidth={300}>{barcode.rawValue}</Truncate>
                        </div>
                      </div>

                      <div className={`${css.flexRow} ${css.fontCircular}`}>
                        <div className={css.whiteSpaceNoWrap}>W:</div>
                        <div>
                          <Truncate maxWidth={300}>{`${convertBarcodeDimensions(barcode.boundingBox.width).toFixed(3)} ${proofOriginalDimensions.measurementUnit}`}</Truncate>
                        </div>
                        <div className={css.whiteSpaceNoWrap}>H:</div>
                        <div>
                          <Truncate maxWidth={300}>{`${convertBarcodeDimensions(barcode.boundingBox.height).toFixed(3)} ${proofOriginalDimensions.measurementUnit}`}</Truncate>
                        </div>
                      </div>

                      <div className={`${css.flexRow} ${css.fontCircular}`}>
                        <div className={css.whiteSpaceNoWrap}>Diagonal:</div>
                        <div>
                          <Truncate maxWidth={300}>{`${calculateDiagonal(barcode.boundingBox.width, barcode.boundingBox.height)} ${proofOriginalDimensions.measurementUnit}`}</Truncate>
                        </div>
                      </div>

                      <div className={css.opacity70}>
                        <Translation value="barcode.overlay.more-options.tooltip" />
                      </div>
                    </Fragment>
                  }
                  up
                  center
                  disabled={popup.isVisible}
                >
                  <div
                    key={index}
                    style={{
                      position: 'absolute',
                      inset: 0,
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </PopupMenu>
        );
      })}
    </div>
  );
};

export default BarcodeScannerOverlay;
