/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import { EditWarningWrapper } from '../EditWarningWrapper';

export function TextInput({ onConfigurationOptionInput, option, isUpdating }) {
  const [value, setValue] = useState(option.value);

  return (
    <EditWarningWrapper
      value={option.value}
      warningMessage={option.warningMessage}
    >
      <Input
        nativeAutoFocus={!!option.warningMessage}
        placeholder={option.placeholder}
        value={value}
        onChange={setValue}
        variant="compact"
        onBlur={() => onConfigurationOptionInput(option.key, value)}
        disabled={isUpdating}
      />
    </EditWarningWrapper>
  );
}

if (process.env.NODE_ENV !== 'production') {
  TextInput.propTypes = {
    onConfigurationOptionInput: PropTypes.func.isRequired,
    option: PropTypes.objectOf(PropTypes.any).isRequired,
    isUpdating: PropTypes.bool,
  };
}

export default TextInput;
