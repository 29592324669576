/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import ProfileAccess from './ProfileAccess';
import ProfileTeam from './ProfileTeam';
import ProfileInfo from './ProfileInfo';
import ProfileUserInvitation from './ProfileUserInvitation';
import DomainAdminNavigationPanel from './DomainAdminNavigationPanel';
import DomainAdminNavigationDropdown from './DomainAdminNavigationDropdown';

export {
  ProfileAccess,
  ProfileTeam,
  ProfileInfo,
  ProfileUserInvitation,
  DomainAdminNavigationPanel,
  DomainAdminNavigationDropdown,
};
