/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import css from './NoComments.scss';
import Translation from '../Text/Translation';

function NoComments({
  filter,
  noCommentsOnPages,
}) {
  const { name, args } = filter;
  // eslint-disable-next-line no-nested-ternary
  const messageKey = name
    ? noCommentsOnPages
      ? name + '.on-pages'
      : name
    : filter;
  return (
    <React.Fragment>
      <div className={classname(css.NoComments, { [css['NoComments--on-pages']]: noCommentsOnPages })}>
        <p>
          <Translation
            value={`no-comments.${messageKey || 'comments'}.message`}
            params={args ? { inputText: args[0] } : {}}
          />
        </p>
      </div>
    </React.Fragment>
  );
}

export default NoComments;
