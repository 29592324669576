/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Children, cloneElement, useMemo} from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import TabButton from './TabButton';
import { TabsContext } from './TabsContext';

/**
 * Returns the Tab element of the selected tab. If no
 *
 * @param {string|*} id The selected tab identifier
 * @param {Array<Tab>} tabs The available tabs
 * @returns {Tab}
 */
function getSelectedTab(id, tabs) {
  let selected = tabs[0];
  tabs.some((tab) => {
    if (tab && tab.props && tab.props.id === id) {
      selected = tab;
      return true;
    }
    return false;
  });
  return selected || null;
}

function Tabs({
  selected,
  children,
}) {
  const selectedTab = getSelectedTab(selected, children);
  const contextValue = useMemo(() => ({
    selectedTab,
  }), [selectedTab]);
  return (
    <TabsContext.Provider value={contextValue}>
      <div className="Tabs">
        <div
          className="Tabs__navigation"
          role="tablist"
        >
          {Children.map(children, tab => (
            tab && (
              <TabButton
                id={tab.props.id}
                selected={selectedTab === tab}
                onClick={tab.props.onClick}
              >
                {tab.props.name}
              </TabButton>
            )
          ))}
        </div>
        <CSSTransitionGroup
          className="Tabs__content"
          component="div"
          transitionName="Tab--transition"
          transitionEnterTimeout={400}
          transitionLeaveTimeout={400}
        >
          {cloneElement(selectedTab, {
            key: selectedTab.props.id,
            selected: true,
          })}
        </CSSTransitionGroup>
      </div>
    </TabsContext.Provider>
  );
}

Tabs.propTypes = {
  selected: PropTypes.string.isRequired,
};

export default Tabs;
