/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import Checkbox from '../Checkbox';
import { Button } from '../Button';
import ButtonGroup from '../ButtonGroup';
import Translation from '../Text/Translation';
import Link from '../Comment/Link';
import { HasFeature } from '../FeatureFlags/HasFeature';
import css from './InstallExtensionPromptModal.scss';

const DIALOG_TYPES = {
  'url-on-desktop': {
    heading: 'proof.dialog.install-extension.url-on-desktop.heading',
    message: 'proof.dialog.install-extension.url-on-desktop.message',
    canClose: true,
  },
  'url-on-mobile': {
    heading: 'proof.dialog.install-extension.url-on-mobile.heading',
    message: 'proof.dialog.install-extension.url-on-mobile.message',
    canClose: true,
  },
  'zip-on-desktop': {
    heading: 'proof.dialog.install-extension.zip-on-desktop.heading',
    message: 'proof.dialog.install-extension.zip-on-desktop.message',
    canContinueWithoutExtension: true,
  },
};

function installExtension() {
  const browser = window.__pageproof_bridge__.browserService.name;
  const envKey = browser + '_ext_url';
  switch (browser) {
  case 'chrome':
  case 'firefox':
  case 'edge':
  case 'safari': {
    window.open(window.env(envKey), '_blank');
    break;
  }
  default: {
    throw new Error('Unknown/unsupported browser ' + browser + ' attempted to install browser extension.');
  }
  }
}

function getDialogType(fileType) {
  const { browserService } = window.__pageproof_bridge__;
  switch (fileType) {
  case 'external-Web':
    if (browserService.is('desktop')) {
      return DIALOG_TYPES['url-on-desktop'];
    } else {
      return DIALOG_TYPES['url-on-mobile'];
    }
  case 'zip-Web':
    return DIALOG_TYPES['zip-on-desktop'];
  default:
    return null;
  }
}

const InstallExtensionPromptModal = ({
  fileType,
  url,
  canInstall,
  onContinue,
  onClose,
}) => {
  const [isSkipped, setIsSkipped] = useState(false);
  const { message, heading, canClose, canContinueWithoutExtension } = getDialogType(fileType) || {};

  return (
    <div className={css.InstallExtensionPromptModal}>
      <div className={css.InstallExtensionPromptModal__heading}>
        <Translation value={heading} />
      </div>
      <div className={css.InstallExtensionPromptModal__message}>
        <Translation
          value={message}
          params={{
            url: (
              <Link url={url}>
                <Translation value="proof.dialog.install-extension.link.click-here" />
              </Link>
            ),
          }}
        />
      </div>
      <ButtonGroup>
        {canClose &&
          <HasFeature flag="dashboard">
            <Button
              variant="outline"
              label={<Translation value="button.close" />}
              onClick={onClose}
            />
          </HasFeature>
        }
        {canContinueWithoutExtension &&
          <Button
            variant="outline"
            label={<Translation value="button.continue-without" />}
            onClick={() => onContinue(isSkipped)}
          />
        }
        {canInstall &&
          <Button
            label={<Translation value="button.install" />}
            onClick={installExtension}
          />
        }
      </ButtonGroup>
      {canContinueWithoutExtension &&
        <div className={css.InstallExtensionPromptModal__checkbox}>
          <Checkbox
            selected={isSkipped}
            onChange={setIsSkipped}
          />
          <span className={css['InstallExtensionPromptModal__checkbox--message']}>
            <Translation value="proof.dialog.message.dont-show" />
          </span>
        </div>
      }
    </div>
  );
};

export default InstallExtensionPromptModal;
