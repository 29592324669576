/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'AgreeWomen',
  src: 'img/icons/agree-valentines.svg',
  activeColor: '#60498b',
  activeHoverColor: '#442e6d',
});
