import React, {Component} from 'react';
import {InlineSVG} from '@jmshal/react-inline-svg';
import {sdk} from '../../util/sdk';
import Popover from '../../components/Popover';
import {Option, OptionList} from '../../components/PopupMenu';
import UnstyledButton from '../../components/Button/UnstyledButton';
import ProgressCircle from '../../components/ProgressCircle';
import Truncate from '../../components/Truncate';
import css from './UploadActivityTray.scss';

const registry = [];
const callbacks = [];
let _ids = 0;

const scheduleUpdate = window.debounce(() => {
  const allDone = registry.every(({progress}) => progress === 100);
  if (allDone) registry.length = 0;
  callbacks.forEach(cb => cb());
}, 100);

const interceptFile = (file) => {
  if (file.assignedType !== 'attachment') {
    return file;
  }
  const id = _ids;
  _ids += 1;
  const record = {
    id,
    name: file.name,
    progress: 0,
  };
  registry.splice(0, 0, record);
  return {
    ...file,
    onProgress: (percent) => {
      record.progress = percent;
      scheduleUpdate();

      if (file.onProgress) {
        return file.onProgress(percent);
      }

      return undefined;
    },
  };
};

/* eslint-disable no-param-reassign */
const _upload = sdk.files.upload.bind(sdk.files);
sdk.files.upload = (fileOrFiles, options) => {
  if (Array.isArray(fileOrFiles)) {
    fileOrFiles = fileOrFiles.map(interceptFile);
  } else {
    fileOrFiles = interceptFile(fileOrFiles);
  }
  scheduleUpdate();
  return _upload(fileOrFiles, options);
};
/* eslint-enable no-param-reassign */

class UploadActivityTray extends Component {
  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillMount() {
    callbacks.push(this.update);
  }

  componentWillUnmount() {
    callbacks.splice(callbacks.indexOf(this.update), 1);
  }

  update = () => this.forceUpdate(); // easier than updating state with some number or something

  render() {
    if (!registry.length) {
      return null;
    }
    return (
      <Popover
        content={
          <div>
            <OptionList
              style={{
                overflowY: 'auto',
                maxHeight: 400,
              }}
            >
              {registry.map(({id, name, progress}) => (
                <Option
                  key={id}
                  label={
                    <Truncate maxWidth={360}>{name}</Truncate>
                  }
                  checked
                  checkedIcon={
                    progress !== 100
                      ? (
                        <div style={{fontSize: 0}}>
                          <ProgressCircle
                            size={20}
                            width={3}
                            color="white"
                            trackColor="rgba(255, 255, 255, .2)"
                            value={progress}
                          />
                        </div>
                      )
                      : undefined
                  }
                />
              ))}
            </OptionList>
          </div>
        }
        down
        center
        arrow
        offset={15}
      >
        <UnstyledButton
          ariaLabel="Upload activity"
          className={css.UploadActivityTray}
        >
          <InlineSVG
            src="img/icons/uploader.svg"
            width="28"
            height="26"
            fill="#138b3b"
          />
        </UnstyledButton>
      </Popover>
    );
  }
}

export default UploadActivityTray;
