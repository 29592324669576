/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */


const isBrowserTimezoneInCountry = countryCode => import('moment-timezone').then((momentTZ) => {
  const timeZoneUS = momentTZ.default.tz.zonesForCountry(countryCode);
  const userTimeZone = momentTZ.default.tz.guess();
  return timeZoneUS.includes(userTimeZone);
});

export default isBrowserTimezoneInCountry;
