/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import CustomTool from '../CustomTool';
import LocalStorage from '../LocalStorage';

function safeParseJSON(str, defaultValue) {
  try {
    return JSON.parse(str);
  } catch (err) {
    console.log('Failed to safely parse pen tool order preference from local storage.');
    console.error(err);
    return defaultValue;
  }
}
function getPenModes() {
  const isMobile = window.__pageproof_bridge__.browserService.is('mobile');

  const penModes = {
    pin: {
      id: 'pin',
      icon: 'img/icons/pen.svg?v=2',
      tooltip: !isMobile && 'proof.tools.pin',
      walkthroughHook: 'red-pen-tool',
    },
    text: {
      id: 'text',
      icon: 'img/icons/pen-text-selection.svg?v=2',
      tooltip: !isMobile && 'proof.tools.text',
    },
    draw: {
      id: 'draw',
      icon: 'img/icons/pen-freehand.svg?v=2',
      tooltip: !isMobile && 'proof.tools.draw',
    },
    general: {
      id: 'general',
      icon: '/img/icons/sticky-note.svg',
      tooltip: !isMobile && 'proof.tools.general',
    },
  };
  return penModes;
}

export default function PenTool({
  mode,
  modes = [],
  selected: isSelected,
  onChangeMode,
  direction,
  constraints,
  size,
  spacing,
}) {
  return (
    <LocalStorage.Value
      name="pageproof.app.pen.order-modes"
      defaultValue="[]"
    >
      {({ value: orderJSON, update: updateOrder }) => (
        <CustomTool
          direction={direction}
          value={mode}
          selected={isSelected}
          order={safeParseJSON(orderJSON, [])}
          options={modes.map(id => getPenModes()[id])}
          spacing={spacing}
          padding={20}
          constraints={constraints}
          size={size}
          onChange={({ value, selected, order }) => {
            if (order.includes('general')) {
              order.splice(order.indexOf('general'), 1);
            }
            updateOrder(JSON.stringify(order));
            onChangeMode(value, selected);
          }}
        />
      )}
    </LocalStorage.Value>
  );
}
