/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import Textarea from '../Textarea';

const ProofInfoTitleInput = ({ ...props }) => (
  <Textarea
    {...props}
    onKeyDown={(event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
      }
    }}
  />
);

export default ProofInfoTitleInput;
