/* eslint-disable jsx-a11y/no-autofocus */

// TODO this should probably be split up into a bunch of smaller files - components, hooks, utils, constants etc...

import React, { Fragment, useState, useEffect } from 'react';
import { useLocalStorage } from '../LocalStorage';

const STORAGE_KEY = 'pageproof.flags';

function parseNumber({ min = -Infinity, max = Infinity } = {}) {
  return function (value) {
    if ((+value).toString() === String(value)) {
      return Math.max(min, Math.min(max, +value));
    }
    throw new Error('Invalid number.');
  };
}

const VALID_FLAGS = [
  {
    id: 'commentPaneSteps',
    name: 'Comment Pane Steps',
    description: 'The stopping points when resizing the comment pane. Also allows the user to double-click on the resize handle to quickly cycle through these steps.',
    defaultValue: [500, 420],
    type: ['array', parseNumber()],
  },
  {
    id: 'commentPaneSpacing',
    name: 'Comment Pane Spacing',
    description: 'The spacing of the comment pane - allows for viewing more comments at once.',
    defaultValue: null,
    type: ['options', [null, 'spacious', 'condensed']],
  },
];

const defaultFlagValues = {};
VALID_FLAGS.forEach(({ id, defaultValue }) => {
  defaultFlagValues[id] = defaultValue;
});

function useFlag(id) {
  const [flags] = useLocalStorage(STORAGE_KEY, () => ({}));
  return typeof flags[id] !== 'undefined'
    ? flags[id]
    : defaultFlagValues[id];
}

function UseFlag({ id, children }) {
  const value = useFlag(id);
  return children(value);
}

function ArrayItemFlagEditor({ value, autoFocus, onChange, onRemove }) {
  const [error, setError] = useState(null);
  const [temporaryValue, setTemporaryValue] = useState(() => value);

  useEffect(() => {
    setTemporaryValue(value);
    setError(null);
  }, [value]);

  return (
    <li>
      <input
        value={temporaryValue}
        onChange={(event) => {
          setTemporaryValue(event.target.value);
        }}
        onBlur={() => {
          try {
            onChange(temporaryValue);
            setError(null);
          } catch (err) {
            setError(err);
          }
        }}
        autoFocus={autoFocus}
      />
      {' '}
      <button onClick={onRemove}>
        x
      </button>
      {' '}
      {error && (
        <span style={{ color: 'red' }}>
          {error.message}
        </span>
      )}
    </li>
  );
}

function ArrayFlagEditor({ type, value, onChange }) {
  const [isAddingNewValue, setIsAddingNewValue] = useState(false);

  return (
    <ul style={{ color: '#444' }}>
      {value.map((item, index) => (
        <ArrayItemFlagEditor
          key={index}
          value={item}
          onChange={(itemValue) => {
            const newValue = [...value];
            newValue[index] = type(itemValue);
            onChange(newValue);
          }}
          onRemove={() => {
            const newValue = [...value];
            newValue.splice(index, 1);
            onChange(newValue);
          }}
        />
      ))}
      {isAddingNewValue
        ? (
          <ArrayItemFlagEditor
            autoFocus
            onChange={(itemValue) => {
              const parsedTemporaryValue = type(itemValue);
              const newValue = [...value, parsedTemporaryValue];
              onChange(newValue);
              setIsAddingNewValue(false);
            }}
            onRemove={() => {
              setIsAddingNewValue(false);
            }}
          />
        )
        : (
          <button onClick={() => setIsAddingNewValue(true)}>
            Add
          </button>
        )
      }
    </ul>
  );
}

function OptionsEditor({ options, value, onChange }) {
  return (
    <ul style={{ color: '#444' }}>
      {options.map(option => (
        <li key={option}>
          <label className="unstyled">
            <input
              type="radio"
              checked={option === value}
              onChange={() => {
                onChange(option);
              }}
            />
            {' '}
            {String(option)}
          </label>
        </li>
      ))}
    </ul>
  );
}

function Flag({ name, description, defaultValue, type, value, onChange, onReset }) {
  const [isVisible, setIsVisible] = useState(!!value);

  const editor = (() => {
    const [typeId, ...typeArgs] = type;
    switch (typeId) {
    case 'array': {
      return (
        <ArrayFlagEditor
          type={typeArgs[0]}
          value={value || defaultValue}
          onChange={onChange}
        />
      );
    }
    case 'options': {
      return (
        <OptionsEditor
          options={typeArgs[0]}
          value={value || defaultValue}
          onChange={onChange}
        />
      );
    }
    default: {
      return (
        <div>Unknown</div>
      );
    }
    }
  })();

  return (
    <div>
      <h3
        style={{ cursor: 'pointer' }}
        onClick={() => setIsVisible(!isVisible)}
      >
        {name}
        {' '}
        {typeof value !== 'undefined' && (
          <button
            style={{ fontSize: 14 }}
            onClick={(event) => {
              event.stopPropagation();
              onReset();
            }}
          >
            Reset
          </button>
        )}
      </h3>
      {isVisible && (
        <Fragment>
          <p style={{ fontSize: 16 }}>{description}</p>
          {editor}
        </Fragment>
      )}
    </div>
  );
}

function Flags() {
  const [flags, setFlags] = useLocalStorage(STORAGE_KEY, () => ({}));

  return (
    <div style={{ padding: 100 }}>
      <h1>Experimental Flags</h1>
      <p style={{ fontSize: 16 }}>
        Hi, this page is an internal page used to play with experimental features/configurations within PageProof.
        <br />
        If you don’t know what you’re doing here - it’s worth slowly backing away...
      </p>
      <br />
      <br />
      {VALID_FLAGS.map((flag, index) => (
        <Fragment>
          <Flag
            key={flag.id}
            {...flag}
            value={flags[flag.id]}
            onChange={(value) => {
              setFlags({
                ...flags,
                [flag.id]: value,
              });
            }}
            onReset={() => {
              const newFlags = { ...flags };
              delete newFlags[flag.id];
              setFlags(newFlags);
            }}
          />
          {index + 1 !== VALID_FLAGS.length && (
            <hr />
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default Flags;
export { useFlag, UseFlag as Flag, defaultFlagValues };
