/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../Button';
import css from './TextButtonTool.scss';

const TextButtonTool = ({ label, onClick, disabled }) => (
  <Button
    variant="unstyled"
    className={css.TextButtonTool}
    label={label}
    disabled={disabled}
    onClick={onClick}
    autoWidth
  />
);

if (process.env.NODE_ENV !== 'production') {
  TextButtonTool.propTypes = {
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };
}

export default TextButtonTool;
