/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export const HasFeature = ({ flag, children }) => {
  const featureFlags = useFeatureFlags();

  if (featureFlags[flag] === true) {
    return children;
  }

  return null;
};
