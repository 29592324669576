/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ProfileInfo,
  ProfileAccess,
  ProfileTeam,
  ProfileUserInvitation,
} from '../../components/DomainAdmin';
import ProfileAvatar from '../ProfileAvatar';

function DomainAdminProfileContainer({
  self,
  user,
  lastLoggedIn,
  isPendingInvitedUser,
  onAddAvatar,
  onResetAvatar,
  onUpdateName,
  onToggleDomainAdmin,
  onToggleUserEnabled,
  onRemoveUserFromTeam,
  onResendInvitation,
  onCancelInvitation,
}) {
  return (
    <div className="DomainAdminProfile">
      <ProfileAvatar
        userId={user.UserId}
        onAddAvatar={onAddAvatar}
        onResetAvatar={onResetAvatar}
        isReadOnly={isPendingInvitedUser}
      />
      <ProfileInfo
        onUpdateName={onUpdateName}
        self={self}
        user={user}
        lastLoggedIn={lastLoggedIn}
        isReadOnly={isPendingInvitedUser}
        showLastLoggedInDate={!(user.IsRegistered && isPendingInvitedUser)}
      />
      {(!self && !isPendingInvitedUser) && (
        <div className="DomainAdminProfile__access">
          {user.IsRegistered &&
            <ProfileAccess
              user={user}
              onToggleDomainAdmin={onToggleDomainAdmin}
              onToggleUserEnabled={onToggleUserEnabled}
            />
          }
          <ProfileTeam
            user={user}
            onRemoveUserFromTeam={onRemoveUserFromTeam}
          />
        </div>
      )}
      {isPendingInvitedUser && (
        <ProfileUserInvitation
          user={user}
          onResendInvitation={onResendInvitation}
          onCancelInvitation={onCancelInvitation}
        />
      )}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  DomainAdminProfileContainer.propTypes = {
    self: PropTypes.bool.isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    lastLoggedIn: PropTypes.string,
    onAddAvatar: PropTypes.func.isRequired,
    onResetAvatar: PropTypes.func.isRequired,
    onUpdateName: PropTypes.func.isRequired,
    onToggleDomainAdmin: PropTypes.func.isRequired,
    onToggleUserEnabled: PropTypes.func.isRequired,
    onRemoveUserFromTeam: PropTypes.func.isRequired,
  };
}

export default DomainAdminProfileContainer;
