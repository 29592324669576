/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useEffect, useState } from 'react';
import css from './DueDateAndReminders.scss';
import { INITIAL_PROOF_DUE } from '../../../util/constants';
import Input from '../../Input';
import ReminderContainer from '../../../containers/ReminderPopUp';
import Translation from '../../Text/Translation';
import DateTimePickerIconButton from '../../DateTimePickerIconButton';
import { parseDateRoundedToNearestQuarter, formatDate } from '../../../util/date-time-utils';
import InputOptions from './InputOptions';
import getFallbackDueDateAndTime from '../utils/getFallbackDueDateAndTime';

const getDate = (dueDate) => {
  if (dueDate instanceof Date) return dueDate;
  // eslint-disable-next-line no-empty
  try { if (new Date(dueDate).toISOString() === dueDate) return new Date(dueDate); } catch (err) { }
  return parseDateRoundedToNearestQuarter(dueDate || INITIAL_PROOF_DUE);
};

const getTimeLeft = (dueDate) => {
  const formattedDate = formatDate(getDate(dueDate));
  return window.generalfunctions_parseDueDateInReminderHours(formattedDate);
};

const DueDateAndReminders = ({
  dueDate = parseDateRoundedToNearestQuarter(INITIAL_PROOF_DUE),
  reminders,
  hasRemindersFeature = true,
  onDueDateChange,
  onRemindersChange,
  previousVersionDueDate,
  userPreferences,
}) => {
  const [inputValue, setInputValue] = useState(() => formatDate(getDate(dueDate)));
  // This is being kept in state so that the checkmark stays next to the 'use default' option
  const [defaultDueDate, setDefaultDueDate] = useState(() => getFallbackDueDateAndTime(userPreferences));

  useEffect(() => {
    setInputValue(formatDate(getDate(dueDate)));
  }, [dueDate]);

  const onChooseDate = (value) => {
    const newDueDate = getDate(value);
    const formattedNewDueDate = formatDate(newDueDate);
    setInputValue(formattedNewDueDate);
    if (formattedNewDueDate !== formatDate(dueDate)) {
      onDueDateChange(newDueDate);
    }
  };

  return (
    <div className={css.DueDateAndReminders}>
      <div className={css.DueDateAndReminders__icons}>
        <div className={css.DueDateAndReminders__datePicker}>
          <DateTimePickerIconButton
            onChange={onChooseDate}
          />
        </div>
        {hasRemindersFeature && (
          <div className={css.DueDateAndReminders__reminder}>
            <ReminderContainer
              variant="proof"
              isOwner
              inCreateProofProccess
              proof={{ reminders }}
              timeLeft={getTimeLeft(dueDate)}
              passReminderValue={onRemindersChange}
            />
          </div>
        )}
        {previousVersionDueDate && (
          <div className={css.DueDateAndReminders__options}>
            <InputOptions
              options={
                <Fragment>
                  <InputOptions.Option
                    label={<Translation value="proof.setup.due-date.use-default" />}
                    checked={dueDate === defaultDueDate}
                    onClick={() => {
                      const updatedDueDate = getFallbackDueDateAndTime(userPreferences);
                      setDefaultDueDate(updatedDueDate);
                      onChooseDate(updatedDueDate);
                    }}
                  />
                  <InputOptions.Option
                    label={<Translation value="proof.setup.due-date.use-previous-version" />}
                    onClick={() => onChooseDate(previousVersionDueDate)}
                    checked={dueDate === previousVersionDueDate}
                  />
                </Fragment>
              }
            />
          </div>
        )}
      </div>
      <Input
        variant="compact"
        type="text"
        value={inputValue}
        placeholder={Translation.text('proof.setup.date.placeholder')}
        onBlur={() => onChooseDate(inputValue)}
        onChange={setInputValue}
      />
    </div>
  );
};

export default DueDateAndReminders;
