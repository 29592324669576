/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Attachment from '../Attachment';
import css from './Attachments.scss';

function Attachments({
  attachments,
}) {
  if (!attachments.length) {
    return null;
  }
  return (
    <div className={css.Attachments}>
      {attachments.map((attachment, index) => (
        <div
          key={attachment.id || index}
          className={css.Attachment}
        >
          <Attachment
            {...attachment}
          />
        </div>
      ))}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Attachments.propTypes = {
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        canDelete: PropTypes.bool,
        canDownload: PropTypes.bool,
        canView: PropTypes.bool,
        id: PropTypes.string,
        isProcessing: PropTypes.bool,
        isUploading: PropTypes.bool,
        name: PropTypes.string,
        onDelete: PropTypes.func,
        onDownload: PropTypes.func,
        onView: PropTypes.func,
      }),
    ),
  };
}

export default Attachments;
