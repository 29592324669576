/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import createIcon from '../../Icon/createIcon';

export default createIcon({
  displayName: 'RestoreMetadata',
  src: 'img/icons/material/symbols/preview-18px.svg',
  activeColor: '#555',
  activeHoverColor: '#333',
  defaultSize: 21,
});
