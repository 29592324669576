/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
// This function was made by referencing 'https://codepen.io/influxweb/pen/LpoXba'
// Original author: Matt Zimmermann (matt@influxwebdesign.com)

export default function getColorBasedOnAverageColor(img) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext && canvas.getContext('2d');
  const height = canvas.height = img.naturalHeight || img.offsetHeight || img.height;
  const width = canvas.width = img.naturalWidth || img.offsetWidth || img.width;
  const rgb = {r: 51, g: 51, b: 51}; // Hex code: #333333
  const pixelInterval = 5;
  const targetAreaRatio = {start: 0.4, end: 0.8}; // Based on center of the Image. 0 ~ 1
  const colorBoundaryValue = 127; // rgb: 0 ~ 255

  context.drawImage(img, 0, 0);

  const data = context.getImageData(0, 0, width, height).data;
  const startPixel = data.length * targetAreaRatio.start;
  const endPixel = data.length * targetAreaRatio.end;
  let count = 0;

  for (let i = startPixel; i < endPixel; i += pixelInterval * 4) {
    count += 1;
    rgb.r += data[i];
    rgb.g += data[i + 1];
    rgb.b += data[i + 2];
  }

  rgb.r = Math.floor(rgb.r / count);
  rgb.g = Math.floor(rgb.g / count);
  rgb.b = Math.floor(rgb.b / count);

  return (rgb.r + rgb.g + rgb.b) / 3 > colorBoundaryValue
    ? 'rgb(51,51,51)'
    : 'rgb(245, 245, 245)';
}
