/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import moment from 'moment';
import { Enum, util } from '@pageproof/sdk';
import Translation from '../Text/Translation';
import getTimecodeWithHyphen from '../../util/get-timecode-with-hyphen';

const { STATIC, VIDEO, AUDIO, WEB } = Enum.FileCategory;

const COMMENT_FIELDS = {
  [STATIC]: ['Number', 'Page', 'Status', 'Date', 'Commented by', 'Source file', 'Type', 'Comment', 'Attachment', 'Agrees', 'Is private'],
  [VIDEO]: ['Number', 'Timecode', 'Status', 'Date', 'Commented by', 'Type', 'Comment', 'Attachment', 'Agrees', 'Is private'],
  [AUDIO]: ['Number', 'Timecode', 'Status', 'Date', 'Commented by', 'Type', 'Comment', 'Attachment', 'Agrees', 'Is private'],
  [WEB]: ['Number', 'Page', 'Device', 'Browser', 'Status', 'Date', 'Commented by', 'Type', 'Comment', 'Attachment', 'Agrees', 'Is private'],
};

const getPage = (comment, fileCategory) => {
  const isWeb = fileCategory === WEB;
  if ((isWeb && !comment.metadata.page) || comment.isReply) {
    return '';
  }

  return isWeb ? comment.metadata.page.path : comment.pageNumber;
};

const getDevice = (comment) => {
  if (!comment.metadata.device || comment.isReply) {
    return '';
  }

  const { id, width, height } = comment.metadata.device;
  return id
    ? `${Translation.text(`comment.metadata.device.${id}`)} (${width} px x ${height} px)`
    : `${width} px x ${height} px`;
};

const getBrowser = (comment) => {
  if (!comment.metadata.browser || comment.isReply) {
    return '';
  }

  const { name, version, os } = comment.metadata.browser;
  return `${Translation.text(`comment.metadata.browser.${name}`)} ${version} (${Translation.text(`comment.metadata.os.${os}`)})`;
};

const getTimeCodes = (comment, fileCategory, framesPerSecond) => {
  const { pins, isReply } = comment;
  const isVideo = fileCategory === VIDEO;

  if ([VIDEO, AUDIO].indexOf(fileCategory) === -1 || isReply) {
    return '';
  }

  if (isVideo &&
      pins.length === 0 &&
      !!comment.metadata.mediaTime
  ) {
    const fakePin = { time: comment.metadata.mediaTime.time, duration: comment.metadata.mediaTime.duration };
    return getTimecodeWithHyphen(fakePin, isVideo, framesPerSecond);
  }

  return [...pins].sort((pin1, pin2) => pin1.time - pin2.time).map(pin => getTimecodeWithHyphen(pin, isVideo, framesPerSecond)).join(', ');
};

const getCommentStatus = (isDone, isTodo) => {
  if (isDone) {
    return Translation.text('comments-header.download.done');
  } else if (isTodo) {
    return Translation.text('comments-header.download.todo');
  } else {
    return '';
  }
};

const getCommentContent = (content, decryptedContent) => {
  if (!(content || decryptedContent)) {
    return '';
  }

  const tokens = window.generalfunctions_parseCommentText(content || decryptedContent).tokens;
  return util.comments.text(tokens);
};

const getCommentNumber = (comment, parentComment) => {
  if (comment.isReply) {
    return getCommentNumber(parentComment);
  }

  if (comment.isPrivate) {
    return '';
  }

  return comment.number;
};

const getCommentedBy = (comment) => {
  const { author } = (comment.sourceMetadata && comment.sourceMetadata.pdfImport) || {};
  return author ? `(PDF) ${author}` : comment.ownerEmail;
};

const getSourceFileName = (comment) => {
  const { fileName } = (comment.sourceMetadata && comment.sourceMetadata.pdfImport) || {};
  return fileName || '';
};

const getCommentFieldValue = (comment, fileCategory, parentComment, framesPerSecond) => ({
  Number: getCommentNumber(comment, parentComment),
  Page: getPage(comment, fileCategory),
  Device: getDevice(comment),
  Browser: getBrowser(comment),
  Timecode: getTimeCodes(comment, fileCategory, framesPerSecond),
  Status: getCommentStatus(comment.isDone, comment.isTodo),
  Date: moment(comment.createdAt).format('LLLL'),
  'Commented by': getCommentedBy(comment),
  Type: Translation.text(comment.isReply ? 'comments-header.download.reply' : 'comments-header.download.comment'),
  Comment: getCommentContent(comment.comment, comment.decryptedComment),
  Attachment: comment.attachments.map(({ name }) => name).join(',\n'),
  Agrees: comment.agrees.length || '',
  'Is private': comment.isPrivate ? 'Yes' : 'No',
  'Source file': getSourceFileName(comment),
});

export default function getDownloadRowData(comment, fileCategory, parentComment, excludedColumns = [], framesPerSecond) {
  const fieldValues = getCommentFieldValue(comment, fileCategory, parentComment, framesPerSecond);
  return COMMENT_FIELDS[fileCategory].filter(column => !excludedColumns.includes(column)).reduce((rowData, field) => ({ ...rowData, [field]: fieldValues[field] }), {});
}
