/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import CollapsibleSearch from './CollapsibleSearch';
import Search from './Search';

export {
  Search,
  CollapsibleSearch,
};
