export default function firstNotNullOrUndefined(...values) {
  let value;
  for (let i = 0; i < values.length; i += 1) {
    if (values[i] !== null && values[i] !== undefined) {
      value = values[i];
      break;
    }
  }
  return value;
}
