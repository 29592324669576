/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
let pantone;

const basicColors = {
  cyan: '#35aff0',
  magenta: '#eb1d8c',
  yellow: '#fbf200',
  black: '#000000',
};

export const getHexFromColorName = (name, callback) => {
  if (!name) {
    return null;
  }

  const id = name.trim().toLowerCase().replace(/\s+/g, '-');

  if (basicColors[id]) {
    return basicColors[id];
  }

  if (id.startsWith('pantone-')) {
    if (!pantone) {
      pantone = import('./pantone').then((mod) => {
        pantone = mod.default;
        return mod.default;
      });
    }

    const handlePantone = (pantoneColors) => {
      const pantoneId = id.substring('pantone-'.length);
      const match = pantoneColors.find(pantoneColor => pantoneColor.pantone === pantoneId);
      if (match) {
        return match.hex;
      }
      return null;
    };

    if (typeof pantone.then === 'function') {
      pantone.then((pantoneColors) => {
        const hex = handlePantone(pantoneColors);
        if (hex) callback(hex);
      });
    } else {
      return handlePantone(pantone);
    }
  }

  return null;
};

window.getHexFromColorName = getHexFromColorName;
