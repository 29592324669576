/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import styles from './WorkflowTemplateDownload.scss';
import Tooltip from '../Tooltip';
import Translation from '../Text/Translation';
import IconButton from '../Button/IconButton';
import ProgressCircle from '../ProgressCircle';
import fakeProgress from '../ProofSetup/utils/fakeProgress';

const WorkflowTemplateDownload = ({ onDownload }) => {
  const [progress, setProgress] = useState(0);

  return (
    <Tooltip
      title={<Translation value="workflow.dashboard.download.enabled" />}
      down
      center
      offset={15}
    >
      {tooltip => (
        <div className={classname(styles.WorkflowTemplateDownload, {
          [styles['WorkflowTemplateDownload--progress']]: progress,
        })}
        >
          {progress
            ? (
              <ProgressCircle
                size={20}
                width={3}
                value={Math.max(1, progress)}
              />
            )
            : (
              <IconButton
                src="img/interface/download.svg"
                className={styles.WorkflowTemplateDownload}
                onClick={() => {
                  tooltip.hide();
                  fakeProgress(onDownload(), 150, 95, (percent) => {
                    setProgress(percent);
                  }).then(() => setProgress(0));
                }}
              />
            )
          }
        </div>
      )}
    </Tooltip>
  );
};

if (process.env.NODE_ENV !== 'production') {
  WorkflowTemplateDownload.propTypes = {
    onDownload: PropTypes.func.isRequired,
  };
}

export default WorkflowTemplateDownload;
