/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classname from 'classname';
import Media from 'react-media';
import {
  Translation,
  Validation,
} from '../../components/Text';
import { getFormattedDate } from '../../util/date-time-utils';
import Textarea from '../../components/Textarea';
import AddressBookControl from '../../components/AddressBookControl';
import Button from '../../components/Button/Button';
import UserAvatarContainer from '../UserAvatar';
import getSDKInstance from '../../util/sdk';
import {
  PopupMenu,
  Option,
} from '../../components/PopupMenu';
import {
  Thumbnail,
  UserHeading,
} from '../../components/InfoPane';
import Tags from '../Tags';
import FormControl from '../../components/FormControl';
import DateTimePickerIconButton from '../../components/DateTimePickerIconButton';
import requestProofSetup from '../../components/ProofSetup/utils/requestProofSetup';
import ProofTileFileDropper from '../../components/ProofSetup/components/ProofTileFileDropper';

class BriefInfoContainer extends Component {
  bridge = window.__pageproof_bridge__;

  client = getSDKInstance();

  constructor(props) {
    super(props);

    this.state = {
      showTags: false,
      showRecipientField: false,
      brief: props.brief,
      invalidType: {},
    };

    this.getEmail(this.state.brief.recipient, 'recipientEmail');
    this.getEmail(this.state.brief.ownerId, 'ownerEmail');
  }

  setInput = (name, value) => {
    const {
      brief,
    } = this.state;
    brief[name] = value;
    this.setState({ brief });
  };

  setValidation = (key, value, fieldValue) => {
    this.setInput(key, fieldValue);

    const { invalidType } = this.state;
    invalidType[key] = (!value) ? 'invalid' : false;
    this.setState({ invalidType });
  };

  getEmail(id, field) {
    return (id &&
      window.__pageproof_bridge__.
        userRepositoryService
        .get(id)
        .then((data) => {
          this.setInput(field, data.email);
        })
    );
  }

  replaceRecipient = () => {
    const {
      brief,
    } = this.state;

    this.updateMeta('recipient', brief.newRecipientEmail)
      .then((returnData) => {
        this.setInput('recipient', returnData.recipient);
        this.setInput('newRecipient', '');
      });
    this.toggleFieldDisplay('showRecipientField');
  };

  toggleFieldDisplay = (field) => {
    this.setState(state => ({
      [field]: !state[field],
    }));
  };

  toggleTags = () => {
    this.setState(state => ({
      showTags: !state.showTags,
    }));
  };

  formatDateTime = () => {
    const {
      brief,
    } = this.state;
    const { dueDate } = brief;
    const formattedFromMoment = this.formatFromMoment(dueDate);
    const formattedDate = getFormattedDate(formattedFromMoment);
    const parsedDate = this.bridge.dateService.parse(formattedDate);

    this.setInput('dueDate', formattedDate);
    this.updateMeta('dueDate', parsedDate);
  };

  formatFromMoment = dueDate => ((typeof dueDate === 'object') ? dueDate.format('h:mma, Do MMMM YYYY') : dueDate);

  deleteTag = (tag) => {
    this.client.briefs.deleteTag(this.props.brief.id, tag);
  };

  gotoUrl = () => {
    this.props.closeInfoPane();

    const { brief } = this.state;
    requestProofSetup(
      {
        parameters: {
          proofType: 'brief',
          updateProof: {
            id: brief.id,
          },
        },
        initialize: {
          proof: {
            id: brief.id,
            name: brief.title,
            tags: brief.tags,
            integrationReferences: brief.integrationReferences,
            dueDate: brief.dueDate,
            file: {
              type: 'local',
              id: brief.file.id,
              name: brief.file.name,
              size: brief.file.size,
              status: 'ok',
            },
          },
        },
      },
      () => {
        this.bridge.$location.url('/create');
      }
    );
  };

  updateMeta(name, newValue) {
    const {
      brief,
    } = this.state;

    const value = newValue || brief[name];
    const key = (name === 'description') ? 'messageToRecipient' : name;
    const data = {
      briefId: brief.id,
      data: {
        [key]: value,
      },
    };

    return this.client.briefs.update(data);
  }

  hasInvalidType(key) {
    const {
      invalidType,
      brief,
    } = this.state;
    return brief[key] !== '' && invalidType[key];
  }

  render() {
    const {
      thumbUrl,
      openBrief,
      readOnly,
      overlay,
      userId,
      showUserDetails,
      showFileErrorMessage,
      closeInfoPane,
    } = this.props;

    const {
      showTags,
      showRecipientField,
      brief,
    } = this.state;

    const isSetupNeeded = brief.status === 0 && !showFileErrorMessage;
    const dueDate = this.formatFromMoment(brief.dueDate);
    const showFileDropper = ((brief.hasUploader || brief.allowReupload) && brief.status !== 60) || brief.hasFileError;

    return (
      <Media query="(max-width: 750px)">
        {matches => (
          <div className="BriefInfoContainer">
            {isSetupNeeded &&
              <div className="BriefInfoContainer__server-msg">
                <h3><Translation value="brief-info.setup-needed.header" /></h3>
                <p><Translation value="brief-info.setup-needed.message" /></p>
                <Button
                  label={<Translation value="button.lets-go" />}
                  onClick={() => this.gotoUrl()}
                  variant="primary"
                />
              </div>
            }
            {showFileErrorMessage &&
              <div className="BriefInfoContainer__server-msg">
                <h3><Translation value="proof-info.workflow.try-again.header" /></h3>
                <p><Translation value="proof-info.workflow.try-again.message" /></p>
                <Button
                  label={<Translation value="button.ok-try-again" />}
                  onClick={() => closeInfoPane()}
                  variant="primary"
                />
              </div>
            }
            {!isSetupNeeded &&
              <div>
                <div className="BriefInfoContainer__meta">
                  <div className="BriefInfoContainer__title">
                    <Textarea
                      readOnly={readOnly}
                      type="text"
                      value={brief.title}
                      onBlur={() => !readOnly && this.updateMeta('title')}
                      onChange={title => this.setInput('title', title)}
                    />
                  </div>
                  <div className="BriefInfoContainer__status">
                    <Translation value={`brief-info.status-text.${brief.$$state}`} />
                  </div>
                  <div className="BriefInfoContainer__thumbnail">
                    <div>
                      {showFileDropper
                        ? <ProofTileFileDropper
                          proof={brief}
                          variant="info-pane"
                        />
                        : <Thumbnail
                          onClick={openBrief}
                          overlay={overlay}
                          hasFileError={brief.hasFileError}
                          url={thumbUrl}
                          title={brief.title}
                          commentCount={brief.commentCount - (brief.privateCount || 0)}
                        />
                      }
                      <div
                        className={classname('BriefInfoContainer__thumbnail__extension', {
                          'BriefInfoContainer__thumbnail__extension--with-file-dropper': showFileDropper,
                        })}
                      >
                        {brief.extension}
                      </div>
                    </div>
                    <div className="BriefInfoContainer__thumbnail__info">
                      <div className="BriefInfoContainer__thumbnail__info__item">
                        <Translation value="brief-info.meta.info.brief" />
                      </div>
                      <div className="BriefInfoContainer__thumbnail__info__item">
                        <Translation
                          value={`brief-info.meta.info.brief.${brief.pages.length > 1 ? 'many-pages' : 'one-page'}`}
                          params={{ pages: brief.pages.length }}
                        />
                      </div>
                      <fieldset className={classname('BriefInfoContainer__thumbnail__date', {
                        'BriefInfoContainer__thumbnail__date--overdue': brief.isOverdue,
                      })}
                      >
                        <div className="BriefInfoContainer__thumbnail__date__datetimepickerHolder">
                          {!readOnly &&
                            <DateTimePickerIconButton
                              size={17}
                              onChange={(date) => { this.setInput('dueDate', moment(date).format('h:mma, Do MMMM YYYY')); this.formatDateTime(); }}
                            />
                          }
                        </div>
                        <Textarea
                          readOnly={readOnly}
                          value={dueDate}
                          placeholder={Translation.text('brief-info.deadline')}
                          onBlur={() => !readOnly && this.formatDateTime()}
                          onChange={date => this.setInput('dueDate', date)}
                          variant="small"
                        />
                      </fieldset>
                      <button
                        type="button"
                        className="BriefInfoContainer__thumbnail__tags-button"
                        onClick={this.toggleTags}
                      >
                        <Translation value="proof-info.show-tags" />
                      </button>
                    </div>
                  </div>
                  {showTags && (
                    <div className="BriefInfoContainer__tags">
                      <Tags
                        tags={brief.tags}
                        readOnly={readOnly}
                        onChange={tags => this.setInput('tags', tags)}
                        onBlur={() => this.updateMeta('tags')}
                        onRemove={tag => this.deleteTag(tag)}
                      />
                    </div>
                  )}
                </div>
                <div className="BriefInfoContainer__users">
                  <div className="BriefInfoContainer__users__section">
                    <UserHeading value="brief-info.owner.title" />
                    <UserAvatarContainer
                      size={matches ? 40 : 60}
                      id={brief.ownerId}
                      showEmail
                      onClick={() => showUserDetails(brief.ownerId)}
                    />
                  </div>
                  {brief.recipient &&
                    <div className="BriefInfoContainer__users__section">
                      <UserHeading value="brief-info.sent-to.title" />
                      {brief.ownerId === userId && !readOnly
                        ? (
                          <PopupMenu
                            up
                            center
                            arrow
                            hover
                            options={(
                              <Fragment>
                                <Option
                                  label={<Translation value="brief-info.popover.replace" />}
                                  onClick={() => this.toggleFieldDisplay('showRecipientField')}
                                />
                              </Fragment>
                            )}
                          >
                            <div className="BriefInfoContainer__users__avatar">
                              <UserAvatarContainer
                                size={matches ? 40 : 60}
                                id={brief.recipient}
                                showEmail
                                onClick={() => showUserDetails(brief.recipient)}
                              />
                            </div>
                          </PopupMenu>
                        )
                        : (
                          <div className="BriefInfoContainer__users__avatar">
                            <UserAvatarContainer
                              size={matches ? 40 : 60}
                              id={brief.recipient}
                              showEmail
                              onClick={() => showUserDetails(brief.recipient)}
                            />
                          </div>
                        )
                      }
                      {!readOnly && showRecipientField && (
                        <FormControl label={<Translation value="label.email" />}>
                          <AddressBookControl
                            showError={false}
                            autoFocus
                            value={brief.newRecipientEmail || ''}
                            onChange={value => this.setValidation('newRecipientEmail', true, value)}
                            setValidation={isValid => this.setValidation('newRecipientEmail', isValid, brief.newRecipientEmail)}
                          />
                          {this.hasInvalidType('newRecipientEmail') &&
                            <div className="BriefInfoContainer__users__error">
                              <Validation>
                                <Translation value={`brief-info.message.error.${this.hasInvalidType('newRecipientEmail')}`} />
                              </Validation>
                            </div>
                          }
                          {!this.hasInvalidType('newRecipientEmail') && (
                            <div className="BriefInfoContainer__users__button-add">
                              <Button
                                label={<Translation value="brief-info.button.add" />}
                                onClick={() => !this.hasInvalidType('newRecipientEmail') && this.replaceRecipient()}
                                variant="text"
                              />
                            </div>
                          )}
                        </FormControl>
                      )}
                    </div>
                  }

                  {(!readOnly || (readOnly && brief.description)) && (
                    <fieldset>
                      <p className="BriefInfoContainer__message-heading">
                        <Translation value="brief-info.message-to-recipient.heading" />
                      </p>
                      <Textarea
                        readOnly={readOnly}
                        placeholder={Translation.text('brief-info.message-recipient')}
                        type="text"
                        allowAutoGrow={false}
                        value={brief.description}
                        onBlur={() => !readOnly && this.updateMeta('description')}
                        onChange={description => !readOnly && this.setInput('description', description)}
                      />
                    </fieldset>
                  )}
                </div>
              </div>
            }
          </div>
        )}
      </Media>
    );
  }
}

BriefInfoContainer.defaultProps = {
  readOnly: true,
  overlay: 'blue',
};

if (process.env.NODE_ENV !== 'production') {
  BriefInfoContainer.propTypes = {
    brief: PropTypes.objectOf(PropTypes.any).isRequired,
    closeInfoPane: PropTypes.func.isRequired,
    openBrief: PropTypes.func.isRequired,
    thumbUrl: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.any),
      PropTypes.string,
    ]).isRequired,
    readOnly: PropTypes.bool,
    overlay: PropTypes.string,
    userId: PropTypes.string.isRequired,
    showUserDetails: PropTypes.func,
    showFileErrorMessage: PropTypes.bool,
  };
}

export default BriefInfoContainer;
