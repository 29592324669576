/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import css from './Edited.scss';
import {Translation} from '../../Text';
import Tooltip from '../../Tooltip';
import Verbose from '../../Date/Verbose';
import UserEmail from '../../ModalMessages/components/UserEmail';

function Edited({
  date,
  userId,
}) {
  return (
    <Tooltip
      title={<Translation
        value="comment.edited.tooltip"
        params={{
          email: <UserEmail id={userId} />,
          date: <Verbose date={date.toDate()} />,
        }}
      />}
    >
      <div className={css.Edited}>
        <Translation value="comment.edited" />
      </div>
    </Tooltip>
  );
}

export default Edited;
