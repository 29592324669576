/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useState, useEffect } from 'react';

export const useHighPrecisionCurrentTime = (api) => {
  const { isPlaying, currentTime: throttledCurrentTime, getCurrentTime } = api;
  const [currentTime, setCurrentTime] = useState(throttledCurrentTime);

  useEffect(() => {
    if (throttledCurrentTime !== currentTime) {
      setCurrentTime(throttledCurrentTime);
    }
  }, [throttledCurrentTime]);

  useEffect(() => {
    if (!isPlaying) {
      return;
    }
    let raf = window.requestAnimationFrame(function next() {
      setCurrentTime(getCurrentTime());
      raf = window.requestAnimationFrame(next);
    });
    // eslint-disable-next-line consistent-return
    return () => {
      window.cancelAnimationFrame(raf);
    };
  }, [isPlaying]);

  return [currentTime, setCurrentTime];
};
