/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrintOptions from '../../components/ModalMessages/PrintOptions';
import env from '../../../../shared/env';

class PrintOptionsContainer extends Component {
  static propTypes = {
    proofId: PropTypes.string,
    proofType: PropTypes.number,
    onFinished: PropTypes.func,
    onCancel: PropTypes.func,
  };

  state = {
    isLoading: true,
    versions: null,
  };

  componentDidMount() {
    this.bridge = window.__pageproof_bridge__;
    this.bridge.printService.getPrintProofOptions(this.props.proofId).then(({ data }) => {
      this.setState({ versions: data, isLoading: false });
    });
  }

  onGenerate(options) {
    this.bridge.printService.printProof(this.props.proofId, options, this.state.versions).then(() => {
      if (this.props.onFinished) {
        this.props.onFinished();
      }
    });
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  getDefaultPageSize = () => {
    const { country } = env;
    const isUSish = ['GB', 'AU', 'NZ'].indexOf(country) === -1;
    return isUSish ? 'LETTER' : 'A4';
  }

  render() {
    const { versions, isLoading } = this.state;

    return <PrintOptions
      versions={versions}
      isLoading={isLoading}
      proofId={this.props.proofId}
      proofType={this.props.proofType}
      onGenerate={options => this.onGenerate(options)}
      onCancel={() => this.onCancel()}
      defaultPageSize={this.getDefaultPageSize()}
    />;
  }
}

export default PrintOptionsContainer;
