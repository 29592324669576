/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Mask from '../Mask';
import { useForceRender } from '../../hooks/useForceRender';

const Isolate = ({
  style,
  target,
  onMouseEnter,
  onClick,
  renderSides,
  padding,
  ...props,
}) => {
  const forceRender = useForceRender();

  useEffect(() => {
    if (target.current) {
      forceRender();
    }
  }, []);

  return target.current && (
    <Mask
      {...props}
      target={target.current}
      render={({ top, left, width, height }) => (
        <Fragment>
          {[
            (renderSides.includes('top') && {
              top: 0,
              left: 0,
              right: 0,
              height: top - padding,
            }),
            (renderSides.includes('bottom') && {
              top: top + height,
              left: 0,
              right: 0,
              bottom: 0 - padding,
            }),
            (renderSides.includes('left') && {
              top: 0,
              left: 0,
              bottom: 0,
              width: left - padding,
            }),
            (renderSides.includes('right') && {
              top: 0,
              left: left + width + padding,
              right: 0,
              bottom: 0,
            }),
          ].filter(Boolean).map((position, index) => (
            <div
              key={index}
              style={{
                zIndex: 10000,
                ...(style || {}),
                ...position,
                position: 'absolute',
              }}
              onMouseEnter={onMouseEnter}
              onClick={onClick}
            />
          ))}
        </Fragment>
      )}
    />
  );
};

Isolate.defaultProps = {
  padding: 0,
  renderSides: ['top', 'bottom', 'left', 'right'],
};

Isolate.propTypes = {
  target: PropTypes.shape({
    current: PropTypes.node,
  }).isRequired,
  renderSides: PropTypes.arrayOf(PropTypes.string),
  onMouseEnter: PropTypes.func,
  onClick: PropTypes.func,
  padding: PropTypes.number,
  style: PropTypes.objectOf(
    PropTypes.number,
    PropTypes.string
  ),
};

export default Isolate;
