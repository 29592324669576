/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const element = document.createElement('div');
document.body.appendChild(element);

Object.assign(element.style, {
  position: 'absolute',
  top: 0,
  left: 0,
  visibility: 'hidden',
  opacity: 0,
  width: 0,
  height: 0,
  overflow: 'hidden',
  pointerEvents: 'none',
});

export default element;
