/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import Avatar from './Avatar';
import styles from './EmailAvatar.scss';

const EmailAvatar = ({email, onHoverOnly, ...props}) => (
  <span
    className={classname(styles.EmailAvatar, {
      [styles.EmailAvatar__show]: !onHoverOnly,
    })}
  >
    <Avatar {...props} />
    {email &&
      <div className={styles.EmailAvatar__email}>
        {email}
      </div>
    }
  </span>
);

if (process.env.NODE_ENV !== 'production') {
  EmailAvatar.propTypes = {
    email: PropTypes.string,
    onHoverOnly: PropTypes.bool,
  };
}

export default EmailAvatar;
