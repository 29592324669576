/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useEffect, useRef, useState, Fragment } from 'react';
import Reveal from '../Reveal';
import { Option } from '../PopupMenu';

// Loading the SDK file inline results in the entire page breaking when embedded if third party cookies are disabled (e.g. monday.com views)
const getSdk = () => import('../../util/sdk');

const loadCustomElementScript = (referenceType) => {
  let scriptName;
  switch (referenceType) {
  case 'trelloCard':
    scriptName = 'card';
    break;
  case 'trelloBoard':
    scriptName = 'board-tile';
    break;
  default:
    break;
  }

  const scriptElId = `trello-component-script-${scriptName}`;

  if (!document.getElementById(scriptElId)) {
    const scriptEl = document.createElement('script');
    scriptEl.id = scriptElId;
    scriptEl.crossOrigin = 'anonymous';
    scriptEl.src = `https://p.trellocdn.com/${scriptName}${(window.customElements ? '' : '-polyfilled')}.min.js`;
    document.head.appendChild(scriptEl);
  }
};


const TrelloElementWrapper = ({ type, info, updateHeight }) => {
  const trelloEl = useRef();

  useEffect(() => {
    // Set the info (either card or board) on the custom element provided by Trello
    // https://developer.atlassian.com/cloud/trello/guides/components/card-components/#rendering-cards-in-react
    trelloEl.current[type] = info;
  }, [info]);

  const setRef = (el) => {
    if (el) {
      trelloEl.current = el;

      trelloEl.current[type] = info;
      updateHeight(trelloEl.current.offsetHeight || 200); // use auto as a fallback
    }
  };

  switch (type) {
  case 'card':
    return <trello-card ref={setRef} />;
  case 'board':
    return (
      <trello-board-tile
        ref={setRef}
        footer={false}
      />
    );
  default:
    return null;
  }
};


const getTrelloDisplaySettings = (referenceType) => {
  switch (referenceType) {
  case 'trelloCard':
    return {
      type: 'card',
      minWidth: 248, // Minimum width before Trello component overflows
    };
  case 'trelloBoard':
    return {
      type: 'board',
      minWidth: 272, // Minimum width before Trello component overflows
    };
  default:
    return {};
  }
};

const TrelloReferenceDisplay = ({ referenceId, referenceType }) => {
  const [info, setInfo] = useState();
  const [height, setHeight] = useState();

  useEffect(() => {
    loadCustomElementScript(referenceType);

    getSdk().then(({ sdk }) => (
      sdk.proofs.integrationReferences.loadReferenceAdditionalInformation(referenceType, referenceId)
        .then((result) => {
          if (result) {
            setInfo(result);
          }
        })
    )).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }, []);

  const { type, minWidth } = getTrelloDisplaySettings(referenceType);

  return (
    <Fragment>
      <div style={{ minWidth, padding: height ? '6px 12px' : '0px 12px' }}>
        <Reveal
          duration={Math.max(200, height * 3)}
          visible={!!height}
          align="top"
          preRender
        >
          {!info
            ? null
            : (
              <TrelloElementWrapper
                type={type}
                info={info}
                updateHeight={setHeight}
              />
            )}
        </Reveal>
      </div>
      {referenceType === 'trelloCard' && info && info.boardName && (
        <Option
          disabled
          label={`Board: ${info.boardName}`}
        />
      )}
    </Fragment>
  );
};

export default TrelloReferenceDisplay;
