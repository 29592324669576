/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import css from './BlockMessage';

const BlockMessage = ({ message }) => (
  <div className={css.BlockMessage}>
    {message}
  </div>
);

if (process.env.NODE_ENV !== 'production') {
  BlockMessage.propTypes = {
    message: PropTypes.node,
  };
}

export default BlockMessage;
